import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SVG from 'react-inlinesvg';
import classNames from "classnames";
import { connect } from "react-redux";
import config from "../../../config.json";
import { setBrandAction } from "../../store";
import Arrow from '../../resources/icons/arrow.svg';
import WelcomePage from "./Steps/WelcomePage";
import SelectBrandPage from "./Steps/SelectBrandPage";
import AboutYouPage from "./Steps/AboutYouPage";
import DinPage from "./Steps/DinPage";
import AccountTypePage from "./Steps/AccountTypePage";
import AlmostDonePage from "./Steps/AlmostDonePage";
import ConfirmPage from "./Steps/ConfirmPage";
import CreatePasswordPage from "./Steps/CreatePasswordPage";
import s from './style.scss';
import HelpInfo from "../../components/Shared/PopUps/HelpInfo";
import StatusDisplay from "../../components/StatusDisplay";
import { signInAsyncAction, signUpAsyncAction, signUpForExistingUserAsyncAction } from "../../store/auth/actions";
import { useNavigate } from "react-router";
import { usePrevious } from "../../hooks";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Typography from "../../components/Shared/Typography";
const signUpSteps = config.signUp_steps.filter(step => step.show);
const SignUp = ({ userBrand, brands, existAzureUser, userPhoneNumber, userPassword, requestSignInStatus, requestSignUpStatus, signIn, signUp, signUpForExistingUser }) => {
    const { t } = useTranslation();
    const prevSignUp = usePrevious(requestSignUpStatus);
    useEffect(() => {
        existAzureUser && userPhoneNumber && signUpSteps.shift();
        existAzureUser && userPassword && signUpSteps.pop();
        setCurrentStep(signUpSteps[0].name);
    }, [existAzureUser]);
    const [step, setStep] = useState(1);
    const navigate = useNavigate();
    useEffect(() => {
        existAzureUser && prevSignUp !== "success" && requestSignUpStatus === "success" && console.log("signIn") == null && signIn(userPhoneNumber, userPassword, '');
    }, [requestSignUpStatus]);
    useEffect(() => {
        existAzureUser && prevSignUp === "success" && requestSignInStatus === "success" && console.log("nav") == null && navigate('/');
    }, [requestSignInStatus]);
    useEffect(() => {
        setCurrentStep(signUpSteps[step - 1].name);
    }, [step]);
    const [currentStep, setCurrentStep] = useState("Welcome");
    const prevStep = () => {
        step > 1 && setStep((i) => --i);
    };
    const nextStep = () => countOfSteps > step && setStep((i) => ++i);
    const [scheduleCall, setScheduleCall] = useState(false);
    const [additionalSteps, setAdditionalSteps] = useState(false);
    const [infoPopUp, showInfoPopUp] = useState(false);
    const countOfSteps = signUpSteps.length;
    const stepBack = (previusStep) => {
        return step > 1 && React.createElement("div", { className: s.back, onClick: previusStep },
            React.createElement(SVG, { src: Arrow, className: s.arrow }),
            t("back"));
    };
    const renderStepHeader = (backAction) => React.createElement("div", { className: s.header },
        React.createElement("div", { className: s.back, onClick: backAction },
            React.createElement(SVG, { src: Arrow, className: s.arrow }),
            t("back")),
        React.createElement("img", { className: s.logo, src: brands && userBrand && brands.entities[userBrand]?.base64Logo }),
        React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `${step}/${countOfSteps}`));
    const registrationAction = (password) => {
        existAzureUser && signUpForExistingUser(userPassword) || signUp(password || userPassword);
    };
    const renderSingUpStep = () => {
        switch (currentStep) {
            case "Welcome": return React.createElement("div", { className: classNames(s.form) },
                React.createElement(WelcomePage, { onSumbit: () => setStep(2) }));
            case "Din": return React.createElement("div", { className: classNames(s.form) },
                React.createElement(DinPage, { onSumbit: nextStep, step: step }));
            case "SelectBrand": return React.createElement("div", { className: classNames(s.form) },
                React.createElement(SelectBrandPage, { onSumbit: nextStep, previusStep: prevStep, step: step, countOfSteps: countOfSteps }));
            case "AboutYou": return React.createElement("div", { className: classNames(s.form0) },
                React.createElement(AboutYouPage, { existAzureUser: existAzureUser, onSumbit: nextStep, prevStep: prevStep }));
            case "AccountType": return React.createElement(AccountTypePage, { onSumbit: nextStep, header: renderStepHeader, previusStep: prevStep });
            case "AlmostDone": return React.createElement("div", { className: classNames(s.form0) },
                React.createElement(AlmostDonePage, { onSumbit: nextStep, brandName: userBrand, prevStep: prevStep }));
            // case "HealthInformation": return <div className={classNames(s.form, { [s.withPartners]: config.applicationType == "Amgen" })}>
            //     <HealthInformation onSumbit={nextStep} brandName={brands.entities[userBrand]?.drugBrandCode} />
            // </div>;
            case "ConfirmPhone": return React.createElement("div", { className: classNames(s.form0) },
                React.createElement(ConfirmPage, { registrationAction: signUpSteps[countOfSteps - 1].name === "ConfirmPhone" && registrationAction || undefined, onSumbit: () => nextStep(), prevStep: prevStep }));
            case "CreatePassword": return React.createElement("div", { className: classNames(s.form0) },
                React.createElement(CreatePasswordPage, { registrationAction: registrationAction, callSchedudleCall: () => { }, callAdditionalSteps: () => navigate('/'), prevStep: prevStep }));
            default: return;
        }
    };
    return React.createElement("div", null,
        React.createElement(StatusDisplay, null),
        React.createElement(Banner, null),
        React.createElement("div", { className: s.register },
            React.createElement(HelpInfo, { isOpen: infoPopUp, setIsOpen: showInfoPopUp }),
            React.createElement(Header, null),
            (signUpSteps[step - 1].name !== "SelectBrand"
                && signUpSteps[step - 1].name !== "AccountType") && React.createElement("div", { className: s.header },
                React.createElement("img", { className: s.logo, src: brands && userBrand && brands.entities[userBrand]?.base64Logo }),
                React.createElement("div", { className: s.pages },
                    React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' },
                        `${step}/${countOfSteps}`,
                        " "))),
            renderSingUpStep()));
};
const mapStateToProps = (state) => {
    return {
        userPhoneNumber: state.auth.phoneNumber,
        userBrand: state.auth.brandId,
        brands: state.brands,
        userPassword: state.auth.password,
        requestSignInStatus: state.auth.requestSignInStatus,
        requestSignUpStatus: state.auth.requestSignUpStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setBrand: (brandId) => dispatch(setBrandAction(brandId)),
        signIn: (phoneNumber, password, twoFactorCode) => dispatch(signInAsyncAction({ identifier: phoneNumber, password, signInType: "PhoneNumber", twoFactorCode })),
        signUp: (pass) => dispatch(signUpAsyncAction(pass)),
        signUpForExistingUser: (pass) => dispatch(signUpForExistingUserAsyncAction(pass)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
