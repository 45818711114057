import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import EditIcon from '@mui/icons-material/Edit';
import { visuallyHidden } from '@mui/utils';
import { Button, FormControl, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import { deletePrescriberAsyncAction, getAllPrescriberInfoAsyncAction } from '../../store/prescriber/Sagas';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeletePopUp from '../../components/Shared/Info/DeletePopUp';
import PrescriberFilter from './PrescriberFilter';
import LoadingPopUp from '../../components/Shared/LoadingPopUp';
import prescribercss from "./PrescriberStyle.scss";
import golbalcss from "../styles.scss";
import globaltableStyle from "../tableStyle.scss";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { t } from 'i18next';
function createData(Name, brandCode, BrandIdentifier, Note, action) {
    return {
        Name,
        brandCode,
        BrandIdentifier,
        Note,
        action
    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: true,
        label: 'name',
    },
    {
        id: 'brandCode',
        numeric: true,
        disablePadding: false,
        label: 'drugBrandCode',
    },
    {
        id: 'BrandIdentifier',
        numeric: true,
        disablePadding: false,
        label: 'brandidentifier',
    },
    {
        id: 'Note',
        numeric: true,
        disablePadding: false,
        label: 'note',
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: ' ',
    }
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, null, headCells.map((headCell) => {
            if (headCell.id === 'action') {
                return (React.createElement(TableCell, { key: headCell.id, align: "right", padding: headCell.disablePadding ? 'none' : 'normal' }, t(headCell.label) || headCell.label));
            }
            return (React.createElement(TableCell, { key: headCell.id, align: headCell.numeric ? 'left' : 'left', padding: headCell.disablePadding ? 'none' : 'normal', sortDirection: orderBy === headCell.id ? order : false },
                React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: createSortHandler(headCell.id) },
                    t(headCell.label) || headCell.label,
                    orderBy === headCell.id ? (React.createElement(Box, { component: "span", sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)));
        }))));
}
function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const navigate = useNavigate();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [holdDataFilter, setholdDataFilter] = useState();
    const [Name, setName] = useState("");
    const handleIconClick = () => {
        setPopupOpen(true);
    };
    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const setFields = (prescriberFilterfieldsObj) => {
        setholdDataFilter(prescriberFilterfieldsObj);
        props.setField?.(prescriberFilterfieldsObj);
    };
    const handleSearchInput = (e) => {
        props.setSearch?.(e.target.value);
    };
    function newPrescriber() {
        navigate('/prescriber/create');
    }
    return (React.createElement(Toolbar, { className: golbalcss.TitleTopBar, sx: {
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
        } },
        numSelected > 0 ? (React.createElement(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "subtitle1", component: "div" },
            numSelected,
            " selected")) : (React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" }, t("prescriber"))),
        React.createElement("button", { className: prescribercss.btnPrimaryButton, onClick: newPrescriber },
            "+",
            t("newPrescriber")),
        React.createElement(FormControl, { sx: { width: '80ch' } },
            React.createElement(OutlinedInput, { className: golbalcss.searchInput, onChange: (e) => handleSearchInput(e), placeholder: t("search") })),
        numSelected > 0 ? (React.createElement(Tooltip, { title: t('delete') },
            React.createElement(IconButton, null,
                React.createElement(DeleteIcon, null)))) : (React.createElement(Tooltip, { className: golbalcss.FilterIconSearch, title: t('filterlist'), onClick: handleIconClick },
            React.createElement(IconButton, null,
                React.createElement(FilterAltIcon, null)))),
        isPopupOpen && React.createElement(PrescriberFilter, { onClose: handleClosePopup, setFields: setFields, holdDataFilter: holdDataFilter })));
}
const PrescriberTable = ({ getAllPresciberInfo, getAllPrescribersDataState, deletePrescriber, requestgetAllPrescriberStatus, requestDeletePrescriberStatus, }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('Name');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const [holdPrescriberId, setholdPrescriberId] = useState("");
    const [deleteData, setDeleteData] = useState();
    const [drugBrandCode, setDrugBrandCode] = useState([]);
    const [name, setName] = useState("");
    const [note, setNote] = useState("");
    const [brandIdentifier, setBrandIdentifier] = useState("");
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        getAllPresciberInfo({
            page, rowsPerPage, order, orderBy, name, drugBrandCode, brandIdentifier
        });
    }, [page, rowsPerPage, orderBy, order, name, drugBrandCode, brandIdentifier]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = getAllPrescribersDataState?.resultItems?.map((n) => n.prescriber);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        var holdData = event.target.value;
        setRowsPerPage(holdData);
        setPage(0);
    };
    const handleDeleteInfo = (prescriberId) => {
        deletePrescriber(prescriberId);
    };
    var prescriberObjectLocalStorage = {
        page: 0,
        rowsPerPage: 5,
        order: 'asc',
        orderBy: 'Name',
        name: '',
        drugBrandCode: [],
        brandIdentifier: '',
    };
    //hold data and set localstorage onclick modify button
    const holdForLocalStorageFun = (page, rowsPerPage, order, orderBy, Name, drugBrandCode, Note, BrandIdentifier) => {
        prescriberObjectLocalStorage = {
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            name: Name,
            drugBrandCode: drugBrandCode,
            brandIdentifier: BrandIdentifier,
        };
        localStorage.setItem('prescriberObjectLocalStorage', JSON.stringify(prescriberObjectLocalStorage));
    };
    //relaod page call get all api
    useEffect(() => {
        // Get data from localStorage
        const storedData = localStorage.getItem('prescriberObjectLocalStorage');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const { page, rowsPerPage, order, orderBy, name, drugBrandCode, note, brandIdentifier } = parsedData;
            //set State
            setPage(page);
            setRowsPerPage(rowsPerPage);
            setOrder(order);
            setOrderBy(orderBy);
            setName(name);
            setDrugBrandCode(drugBrandCode);
            setNote(note);
            setBrandIdentifier(brandIdentifier);
            getAllPresciberInfo({
                page, rowsPerPage, order, orderBy, name, drugBrandCode, brandIdentifier
            });
        }
    }, []);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Check if the page is being reloaded
            if (event.currentTarget.performance.navigation.type === 1) {
                localStorage.removeItem('prescriberObjectLocalStorage');
                prescriberObjectLocalStorage = {
                    page: 0,
                    rowsPerPage: 5,
                    order: 'asc',
                    orderBy: 'Name',
                    name: '',
                    drugBrandCode: [],
                    brandIdentifier: '',
                };
                getAllPresciberInfo(prescriberObjectLocalStorage);
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(() => {
        if (requestDeletePrescriberStatus === "success") {
            setDisplayInfo(false);
            getAllPresciberInfo({
                page, rowsPerPage, order, orderBy, name, drugBrandCode, brandIdentifier
            });
        }
    }, [requestDeletePrescriberStatus]);
    const handleDelete = (row, prescriberId) => {
        setholdPrescriberId(prescriberId);
        setDeleteData(row);
        setDisplayInfo(true);
    };
    const setField = (prescriberFilterfieldsObj) => {
        setNote('');
        setName(prescriberFilterfieldsObj?.name ?? "");
        setBrandIdentifier(prescriberFilterfieldsObj?.brandIdentifier ?? "");
        setDrugBrandCode(prescriberFilterfieldsObj?.drugBrandCode ?? []);
    };
    const setSearch = (searchValue) => {
        // setSearchValue(searchValue);
        setName(searchValue);
    };
    return (React.createElement(Box, { sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { className: globaltableStyle.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement(EnhancedTableToolbar, { numSelected: selected.length, setField: setField, setSearch: setSearch }),
            React.createElement(TableContainer, { className: globaltableStyle.borderTopElement },
                React.createElement(Table, { sx: { minWidth: 750 }, "aria-labelledby": "tableTitle", size: dense ? 'small' : 'medium', className: globaltableStyle.tableCustomDesign },
                    React.createElement(EnhancedTableHead, { numSelected: selected.length, order: order, orderBy: orderBy, onSelectAllClick: handleSelectAllClick, onRequestSort: handleRequestSort, rowCount: getAllPrescribersDataState?.resultItems?.length }),
                    getAllPrescribersDataState?.resultItems?.length === 0 ? (React.createElement(TableBody, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 8, align: "center" }, t('noRecordFound'))))) : (React.createElement(TableBody, null, getAllPrescribersDataState?.resultItems.map((row, index) => {
                        return (React.createElement(TableRow, { key: row.prescriberId },
                            React.createElement(TableCell, { sx: { width: '40ch' }, align: "left" }, row?.name),
                            React.createElement(TableCell, { sx: { width: '20ch' }, align: "left" }, row?.drugBrandCodes == "" ? "-" : row?.drugBrandCodes.join(", ")),
                            React.createElement(TableCell, { sx: { width: '20ch' }, align: "left" }, row?.brandIdentifier == "" ? "-" : row?.brandIdentifier),
                            React.createElement(TableCell, { align: "left" }, row?.note == "" ? "-" : row?.notes),
                            React.createElement(TableCell, { sx: { width: '17ch' }, align: "right" },
                                React.createElement(Link, { to: `/prescriber/${row.prescriberId}`, style: { textDecoration: 'none', } },
                                    React.createElement(Button, { variant: "contained", className: prescribercss.iconbutton, onClick: () => holdForLocalStorageFun(page, rowsPerPage, order, orderBy, name, drugBrandCode, note, brandIdentifier) },
                                        " ",
                                        React.createElement(EditIcon, null),
                                        " ")),
                                React.createElement(Button, { className: prescribercss.iconbuttonDelete, variant: "contained", onClick: () => handleDelete(row, row.prescriberId) },
                                    " ",
                                    React.createElement(DeleteIcon, null),
                                    " "))));
                    }))))),
            React.createElement(TablePagination, { className: globaltableStyle.tableCustomPagination, rowsPerPageOptions: [5, 10, 25], component: "div", count: getAllPrescribersDataState?.rowCount || 0, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelRowsPerPage: t('itemsPerPage') })),
        React.createElement(DeletePopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false), onClick: () => handleDeleteInfo(holdPrescriberId), header: t("deletePermanently") },
            React.createElement(Box, { className: golbalcss.deletePopupText, sx: { width: '100%' } },
                React.createElement(Grid, { container: true, rowSpacing: 1, columnSpacing: { xs: 1, sm: 2, md: 3 } },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("span", null,
                            t("prescriber"),
                            ":")),
                    React.createElement(Grid, { item: true, xs: 6 }, deleteData?.name),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("span", null,
                            t("drugBrandCode"),
                            ":")),
                    React.createElement(Grid, { item: true, xs: 6 }, deleteData?.drugBrandCodes),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("span", null,
                            t("brandidentifier"),
                            ":")),
                    React.createElement(Grid, { item: true, xs: 6 }, deleteData?.brandIdentifier),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement("span", null, "Note:")),
                    React.createElement(Grid, { item: true, xs: 6 }, deleteData?.notes)))),
        requestgetAllPrescriberStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestDeletePrescriberStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        getAllPrescribersDataState: state.prescriber.getAllPrescribers,
        requestgetAllPrescriberStatus: state.prescriber.requestgetAllPrescriberStatus,
        requestDeletePrescriberStatus: state.prescriber.requestDeletePrescriberStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getAllPresciberInfo: (data) => dispatch(getAllPrescriberInfoAsyncAction(data)),
        deletePrescriber: (presciberId) => dispatch(deletePrescriberAsyncAction({ presciberId })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriberTable);
