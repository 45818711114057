import React, { useState } from "react";
import GeneralSettings from "./SubPages/GeneralSettings";
import ChangePassword from "./SubPages/ChangePassword";
import { Box } from "../../components";
const Settings = () => {
    const [page, setPage] = useState(1);
    const renderSettings = () => {
        switch (page) {
            case 1:
                return (React.createElement(GeneralSettings, { setPage: setPage }));
            case 2:
                return (React.createElement(ChangePassword, { setPage: setPage }));
            default:
                return null;
        }
    };
    return React.createElement(Box, { fullWidth: true }, renderSettings());
};
export default Settings;
