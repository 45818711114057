import React from 'react';
import Info from '../../../../resources/icons/info';
import Warning from '../../../../resources/icons/warning';
import s from './style.scss';
import classNames from 'classnames';
import colors from '../../../../resources/colors';
const Notification = ({ style, type = "info", onClick, children }) => {
    const iconByType = () => {
        switch (type) {
            case 'info': return React.createElement(Info, { color: colors.notification.infoIconColor, style: s.icon });
            case 'warning': return React.createElement(Warning, { color: colors.notification.warningIconColor, style: s.icon });
            case 'error': return React.createElement(Warning, { color: colors.notification.errorIconColor, style: s.icon });
            default: return undefined;
        }
    };
    const backgroundColor = () => {
        switch (type) {
            case 'info': return colors.notification.infoBackground;
            case 'warning': return colors.notification.warningBackground;
            case 'error': return colors.notification.errorIconColor;
            default: return undefined;
        }
    };
    return (React.createElement("div", { "data-testid": "notificationWrapper", className: classNames(s.notification, style, { [s.pointer]: onClick }), onClick: onClick, style: { backgroundColor: backgroundColor() } },
        React.createElement("div", { "data-testid": "icon", className: s.notificationIcon }, iconByType()),
        React.createElement("div", { "data-testid": "notification", className: s.content }, children)));
};
export default Notification;
