import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getBrandByIdSelector } from "../../store";
import classNames from "classnames";
import Button from "../../components/Shared/Controls/Button";
import s from "./style.scss";
import { Link } from "@material-ui/core";
import smimg from "../../../src/resources/images/alarm.png";
import { Button as CustomButton, } from "../../components/Shared/Controls";
import { deleteInfusionClinicBrandAsyncAction, getPatientInfusionClinicAsyncAction } from "../../store/InfusionClinic/Sagas";
import { useLocation, useNavigate } from "react-router-dom";
import DeletePopUp from "../../components/Shared/Info/DeletePopUp";
import LoadingPopUp from "../../components/Shared/LoadingPopUp";
// @ts-ignore
import { t } from "i18next";
const InfusionClinicList = ({ patientId, getInfusionClinicAction, getInfusionClinicData, deleteInfusionClinicBrandAction, requestGetInfusionClinicStatus, requestDeleteInfusionClinicBrandStatus }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const brand = useSelector(getBrandByIdSelector);
    useEffect(() => {
        getInfusionClinicAction({
            patientId
        });
    }, []);
    useEffect(() => {
        if (getInfusionClinicData) {
        }
    }, [getInfusionClinicData]);
    const OpenMapPage = () => {
        navigate('/infusion-Clinic/add-infusion-clinic');
    };
    const deleteInfusionClinic = () => {
        setDisplayInfo(true);
    };
    const getGoogleMapsLink = () => {
        if (getInfusionClinicData?.[0]?.latitude && getInfusionClinicData?.[0]?.longitude) {
            let link = `https://www.google.com/maps/search/${getInfusionClinicData?.[0]?.latitude}, ${getInfusionClinicData?.[0]?.longitude}/@${getInfusionClinicData?.[0]?.latitude},${getInfusionClinicData?.[0]?.longitude},10z`;
            return link;
        }
        let link = `https://www.google.com/maps/search/${getInfusionClinicData?.[0]?.address}`;
        if (!!getInfusionClinicData?.[0]?.city) {
            link += ` ${getInfusionClinicData?.[0]?.city}`;
        }
        if (!!getInfusionClinicData?.[0]?.country) {
            link += ` ${getInfusionClinicData?.[0]?.country}`;
        }
        if (!!getInfusionClinicData?.[0]?.postal) {
            link += ` ${getInfusionClinicData?.[0]?.postal}`;
        }
        return link;
    };
    const handleDeleteInfo = (Id) => {
        deleteInfusionClinicBrandAction(Id);
    };
    useEffect(() => {
        if (requestDeleteInfusionClinicBrandStatus === "success") {
            setDisplayInfo(false);
            getInfusionClinicAction({ patientId });
        }
    }, [requestDeleteInfusionClinicBrandStatus]);
    const updateInfusionClinic = () => {
        navigate('/infusion-Clinic/add-infusion-clinic');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(s.pageBody, s.profileWrapper, s.scrollWrapper, s.w100) },
            React.createElement("h3", null, t("infusionClinic")),
            React.createElement("div", { className: classNames(s.remindersPageWrapper) }, getInfusionClinicData?.length > 0 ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(s.pharmacyAddboxAll) },
                        React.createElement("div", { className: classNames(s.pharmacyAddbox) },
                            React.createElement("img", { src: brand.logoLink, className: s.brandLogo }),
                            React.createElement("h3", null, getInfusionClinicData?.[0]?.name),
                            React.createElement("ul", null,
                                React.createElement("li", null, getInfusionClinicData?.[0]?.address)),
                            React.createElement("div", { className: classNames(s.GetDirectionsbox) },
                                React.createElement("div", { className: classNames(s.GetDirectionsboxtext) },
                                    React.createElement(Link, { href: getGoogleMapsLink(), target: "_blank", className: s.GetDirectionsboxlink }, t("viewOnMap"))),
                                React.createElement("div", { className: classNames(s.GetDirectionsboxImg) },
                                    React.createElement("img", { src: smimg, className: "img-fluid" })))),
                        React.createElement("div", { className: s.buttonTwopage },
                            React.createElement(CustomButton, { text: t("delete"), onClick: deleteInfusionClinic }),
                            React.createElement(CustomButton, { style: s.mb0_button, primary: true, text: t("change"), onClick: updateInfusionClinic })))) :
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(s.pharmacybox) },
                        React.createElement("img", { src: brand.logoLink, className: s.brandLogo }),
                        React.createElement("h2", null, "You have no Infusion Clinic yet"),
                        React.createElement(Button, { style: s.button, primary: true, iosTextOverflow: true, text: "Add Infusion Clinic", onClick: OpenMapPage }))))),
        React.createElement(DeletePopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false), onClick: () => handleDeleteInfo(getInfusionClinicData?.[0]?.patientBrandInfusionClinicId), header: t("deletePermanently") }),
        requestGetInfusionClinicStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestDeleteInfusionClinicBrandStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId ?? "",
        getInfusionClinicData: state.InfusionClinic.getInfusionClinicData,
        requestGetInfusionClinicStatus: state.InfusionClinic.requestGetInfusionClinicStatus,
        requestDeleteInfusionClinicBrandStatus: state.InfusionClinic.requestDeleteInfusionClinicBrandStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getInfusionClinicAction: (data) => dispatch(getPatientInfusionClinicAsyncAction(data)),
        deleteInfusionClinicBrandAction: (Id) => dispatch(deleteInfusionClinicBrandAsyncAction(Id)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(InfusionClinicList);
