import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { getBrandByIdSelector } from "../../store";
import classNames from "classnames";
import Button from "../../components/Shared/Controls/Button";
import s from "./style.scss";
import { Button as CustomButton, } from "../../components/Shared/Controls";
import { useLocation, useNavigate } from "react-router-dom";
import DeletePopUp from "../../components/Shared/Info/DeletePopUp";
import LoadingPopUp from "../../components/Shared/LoadingPopUp";
// @ts-ignore
import { t } from "i18next";
import { deletePatientBrandPrescriberAsyncAction, getPatientBrandPrescriberAsyncAction } from "../../store/PatientBrandPrescriber/Sagas";
const PrescriberList = ({ patientId, getPrescriberAction, getPrescriberData, deletePrescriberBrandAction, requestGetPrescriberStatus, requestDeletePrescriberBrandStatus }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const brand = useSelector(getBrandByIdSelector);
    useEffect(() => {
        getPrescriberAction({
            patientId
        });
    }, []);
    useEffect(() => {
        console.log(getPrescriberData);
        if (getPrescriberData) {
        }
    }, [getPrescriberData]);
    const OpenMapPage = () => {
        navigate('/prescriber/add-manual');
    };
    const deletePrescriber = () => {
        setDisplayInfo(true);
    };
    const getGoogleMapsLink = () => {
        if (getPrescriberData?.latitude && getPrescriberData?.longitude) {
            let link = `https://www.google.com/maps/search/${getPrescriberData?.latitude}, ${getPrescriberData?.longitude}/@${getPrescriberData?.latitude},${getPrescriberData?.longitude},10z`;
            return link;
        }
        let link = `https://www.google.com/maps/search/${getPrescriberData?.address}`;
        if (!!getPrescriberData?.city) {
            link += ` ${getPrescriberData?.city}`;
        }
        if (!!getPrescriberData?.country) {
            link += ` ${getPrescriberData?.country}`;
        }
        if (!!getPrescriberData?.postal) {
            link += ` ${getPrescriberData?.postal}`;
        }
        return link;
    };
    const handleDeleteInfo = (Id) => {
        deletePrescriberBrandAction(Id);
    };
    useEffect(() => {
        if (requestDeletePrescriberBrandStatus === "success") {
            setDisplayInfo(false);
            getPrescriberAction({ patientId });
        }
    }, [requestDeletePrescriberBrandStatus]);
    const updatePrescriber = () => {
        navigate('/prescriber/add-manual');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(s.pageBody, s.profileWrapper, s.scrollWrapper, s.w100) },
            React.createElement("h3", null, t("prescriber")),
            React.createElement("div", { className: classNames(s.remindersPageWrapper) }, !!getPrescriberData ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(s.pharmacyAddboxAll) },
                        React.createElement("div", { className: classNames(s.pharmacyAddbox) },
                            React.createElement("img", { src: brand.logoLink, className: s.brandLogo }),
                            React.createElement("h3", null, getPrescriberData?.name),
                            React.createElement("ul", null,
                                React.createElement("li", null, getPrescriberData?.address))),
                        React.createElement("div", { className: s.buttonTwopage },
                            React.createElement(CustomButton, { text: t("delete"), onClick: deletePrescriber }),
                            React.createElement(CustomButton, { style: s.mb0_button, primary: true, text: t("change"), onClick: updatePrescriber })))) :
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(s.pharmacybox) },
                        React.createElement("img", { src: brand.logoLink, className: s.brandLogo }),
                        React.createElement("h2", null, "You have no Prescriber yet"),
                        React.createElement(Button, { style: s.button, primary: true, iosTextOverflow: true, text: "Add Prescriber", onClick: OpenMapPage }))))),
        React.createElement(DeletePopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false), onClick: () => handleDeleteInfo(getPrescriberData?.patientBrandPrescriberId), header: t("deletePermanently") }),
        requestGetPrescriberStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestDeletePrescriberBrandStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId ?? "",
        getPrescriberData: state.PatientBrandPrescriber.getPatientBrandPrescriberData,
        requestGetPrescriberStatus: state.PatientBrandPrescriber.requestGetPatientBrandPrescriberStatus,
        requestDeletePrescriberBrandStatus: state.PatientBrandPrescriber.requestDeletePatientBrandPrescriberInfoStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPrescriberAction: (data) => dispatch(getPatientBrandPrescriberAsyncAction(data)),
        deletePrescriberBrandAction: (Id) => dispatch(deletePatientBrandPrescriberAsyncAction({ prescriberId: Id })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriberList);
