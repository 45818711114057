import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "../../../../components";
import Typography from "../../../../components/Shared/Typography";
import images from "../../../../resources/images";
import s from './style.scss';
import Done from "../../../../resources/icons/done.svg";
import Info from "../../../../resources/icons/info-icon.svg";
import colors from "../../../../resources/colors";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import moment from "moment/moment";
const HomeUserCard = ({ patient, addressProgress, healthCardProgress, insuranceProgress }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.profileInfo },
        React.createElement("div", { className: s.userCard },
            React.createElement(Box, null,
                React.createElement(Typography, { type: 'header_1', color: 'neutralDark', bold: true },
                    patient?.firstName,
                    ", ",
                    patient?.lastName),
                React.createElement(Button, { variant: "contained", className: s.iconbutton, onClick: () => navigate("/profile") },
                    React.createElement(EditIcon, null)),
                React.createElement("br", null),
                React.createElement(Typography, { type: 'header_2', color: 'neutralDark' },
                    patient?.gender,
                    ", ",
                    !!patient && !!patient.birthDate && moment(patient.birthDate).format("MMM DD, YYYY"))),
            React.createElement("div", { className: s.statusTiles },
                React.createElement("div", { className: s.headerInfo },
                    React.createElement("span", null),
                    React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, t("completeYourProfile"))),
                React.createElement(Box, { f1: true, row: true },
                    React.createElement(Box, { className: s.image },
                        React.createElement("img", { src: images.UserCard, style: { paddingTop: 22, width: 160, height: 160 } })),
                    React.createElement(Box, { f1: true, justifyEnd: true, gap: 12, className: s.itemWrapper },
                        React.createElement("div", { className: classNames(s.progressItem, { [s.done]: !addressProgress }), onClick: () => navigate("/personal-data") },
                            React.createElement("div", { className: s.progressButton }, addressProgress && React.createElement(SVG, { src: Info }) || React.createElement(SVG, { src: Done })),
                            React.createElement("div", { className: s.margin24 },
                                React.createElement(Typography, { type: 'subtitle_3', color: 'neutralDark', bold: true }, t("contactInfo"))),
                            addressProgress && React.createElement("div", { className: s.addButon }, healthCardProgress && React.createElement(Typography, { type: 'button', color: 'primary', bold: true }, "Add"))),
                        React.createElement("div", { className: classNames(s.progressItem, { [s.done]: !insuranceProgress }), onClick: () => navigate("/insurances") },
                            React.createElement("div", { className: s.progressButton, style: {
                                    borderColor: insuranceProgress && colors.secondary
                                } }, insuranceProgress && React.createElement(SVG, { src: Info }) || React.createElement(SVG, { src: Done })),
                            React.createElement("div", { className: s.margin24 },
                                React.createElement(Typography, { type: 'subtitle_3', color: 'neutralDark', bold: true }, t("insurance"))),
                            insuranceProgress && React.createElement("div", { className: s.addButon }, healthCardProgress && React.createElement(Typography, { type: 'button', color: 'primary', bold: true }, "Add"))),
                        React.createElement("div", { className: classNames(s.progressItem, { [s.done]: !healthCardProgress }), onClick: () => navigate("/health-card") },
                            React.createElement("div", { className: s.progressButton }, healthCardProgress && React.createElement(SVG, { src: Info }) || React.createElement(SVG, { src: Done })),
                            React.createElement("div", { className: s.margin24 },
                                React.createElement(Typography, { type: 'subtitle_3', color: 'neutralDark', bold: true }, t("healthCard"))),
                            healthCardProgress &&
                                React.createElement("div", { className: s.addButon }, healthCardProgress && React.createElement(Typography, { type: 'button', color: 'primary', bold: true }, "Add")))))))));
};
export default HomeUserCard;
