import { createSlice, createEntityAdapter, } from "@reduxjs/toolkit";
import { showErrorAction, hideErrorAction, startRequestAction } from "../utils";
export const SLICE_NAME = "CHAT";
export const chatAdapter = createEntityAdapter({
    selectId: (chat) => chat.conversationMessageId
});
const { selectAll } = chatAdapter.getSelectors();
export const ChatSelectors = chatAdapter.getSelectors();
const initialState = {
    currentPage: 0,
    pageCount: 0,
    pageSize: 20,
    requestStatus: "idle",
    requestError: null,
    requestSendMessageStatus: "idle",
    requestSendMessageError: null,
    hasNewMessages: false,
};
const chatSlice = createSlice({
    name: SLICE_NAME,
    initialState: chatAdapter.getInitialState(initialState),
    reducers: {
        successRequestAction: (state, action) => {
            state.currentPage = action.payload.meta.currentPage;
            state.pageCount = action.payload.meta.pageCount;
            state.pageSize = action.payload.meta.pageSize;
            // ChatAdapter.upsertMany(state, action.payload);
            if (action.payload.meta.currentPage === 1) {
                chatAdapter.removeAll(state);
                chatAdapter.setMany(state, action.payload.messages);
            }
            else
                chatAdapter.addMany(state, action.payload.messages);
            state.requestStatus = "success";
            state.requestError = null;
        },
        startRequestAction,
        showErrorAction,
        hideChatErrorAction: hideErrorAction,
        resetChatAction: (state) => {
            state = chatAdapter.getInitialState(initialState);
        },
        startSendMessageAction: (state, action) => {
            chatAdapter.setAll(state, [{
                    ...action.payload,
                    yours: true,
                    status: "pending"
                }, ...selectAll(state)]);
            state.requestSendMessageStatus = "pending";
            state.requestSendMessageError = null;
        },
        successSendMessageAction: (state, action) => {
            chatAdapter.upsertOne(state, {
                ...action.payload,
                yours: true,
                status: "success"
            });
            state.requestSendMessageStatus = "success";
            state.requestSendMessageError = null;
        },
        markErrorSendMessageAction: (state, action) => {
            chatAdapter.upsertOne(state, {
                ...action.payload,
                yours: true,
                status: "failure"
            });
            state.requestSendMessageStatus = "success";
            state.requestSendMessageError = null;
        },
        addIncomingMessageAction: (state, action) => {
            chatAdapter.setAll(state, [{
                    ...action.payload
                }, ...selectAll(state)]);
        },
        showErrorSendMessageAction: (state, action) => {
            state.requestSendMessageStatus = "failure";
            state.requestSendMessageError = action.payload;
        },
        hideErrorSendMessageAction: (state) => {
            state.requestSendMessageStatus = "idle";
            state.requestSendMessageError = null;
        },
        setChatHasNewMessagesAction: (state, action) => {
            state.hasNewMessages = action.payload;
        },
        resetRequestChatStatus: (state) => {
            state.requestStatus = "idle";
            state.requestError = null;
        },
    },
});
export default chatSlice;
