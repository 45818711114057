import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import slice, { SLICE_NAME } from "./Slice";
import { handleApiError } from "../utils";
import { PatientEducationRepository } from "../repository";
import { userInfoSelector } from "../account";
export const getPatientEducationAsyncAction = createAction(`${SLICE_NAME}/getPatientEducationAsyncAction`);
export function* getPatientEducationSaga(action) {
    const { startGetPatientEducationRequestAction, successGetPatientEducationRequestAction, showGetPatientEducationErrorAction } = slice.actions;
    const { drugBrandCode, patientId } = yield select(userInfoSelector);
    try {
        yield put(startGetPatientEducationRequestAction());
        const payload = {
            pageNumber: 1,
            pageSize: 99,
            patientId: action.payload.patientId,
            drugBrandCode: drugBrandCode,
            mimeType: action.payload.mimeType,
            cultureCode: action.payload.cultureCode,
            title: action.payload.title,
            description: action.payload.description,
            tags: action.payload.tags,
            orderByAttribute: "",
            orderByDescending: false
        };
        const result = yield call(PatientEducationRepository.getEducationLibrary, payload);
        const education = result.data.value;
        yield put(successGetPatientEducationRequestAction(education));
    }
    catch (error) {
        yield handleApiError(error, showGetPatientEducationErrorAction);
    }
}
export const markEducationLibraryPostAsReadAsyncAction = createAction(`${SLICE_NAME}/markEducationLibraryPostAsReadAsyncAction`);
export function* markAsReadEducationLibraryPostSaga(action) {
    const { startMarkAsReadRequestAction, successMarkAsReadRequestAction, showMarkAsReadErrorAction } = slice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startMarkAsReadRequestAction());
        yield call(PatientEducationRepository.markAsRead, {
            patientId,
            educationId: action.payload.educationId,
            itemMimeType: action.payload.itemMimeType
        });
        yield put(successMarkAsReadRequestAction(action.payload.educationId));
    }
    catch (error) {
        yield handleApiError(error, showMarkAsReadErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getPatientEducationAsyncAction, getPatientEducationSaga);
    yield takeLatest(markEducationLibraryPostAsReadAsyncAction, markAsReadEducationLibraryPostSaga);
}
