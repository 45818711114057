import ApiManager from "../api/ApiManager";
const version = "1.2";
const CHAT = `/v${version}/patient/chat/messages`;
class ChatRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new ChatRepository();
        }
        return this.instance;
    }
    static getChatMessages = (payload) => {
        return ApiManager.getInstance().get(CHAT, {
            PatientId: payload.patientId,
            PageNumber: payload.pageNumber,
            PageSize: payload.pageSize
        });
    };
    static sendMessage = (data) => {
        return ApiManager.getInstance().post(CHAT, data);
    };
}
export default ChatRepository;
