import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { getAnalytics, logEvent } from "@firebase/analytics";
import Button from "../../Shared/Controls/Button";
import { useTranslation } from "react-i18next";
import { filesByTypesSelector, hideRequestInsurancesUploadErrorAction } from "../../../store";
import usePrevious from "../../../hooks/usePrevious";
import { useInput } from "../../../hooks";
import { getInsuranceAsyncAction, updatePatientFilesAllTypesAsyncAction } from "../../../store/patientFiles/Actions";
import ImageModal from "../../Shared/ImageModal";
import PlusIcon from '../../../resources/icons/plus_circle';
import colors from "../../../resources/colors";
import s from "./style.scss";
import Box from "../../Shared/Box";
import si from "../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import Typography from "../../Shared/Typography";
import AddInsuranceInputs from "../../AddInsurenceInputs";
const useInsurance = (insuranceType) => {
    const [frontImage, setFrontImage] = useState(undefined);
    const [backImage, setBackImage] = useState(undefined);
    const [nameOfInsurer, setNameOfInsurer, errorNameOfInsurer, setErrorNameOfInsurer] = useInput();
    const [carrier, setCarrier, errorCarrier, setErrorCarrier] = useInput();
    const [contract, setContract, errorContract, setErrorContract] = useInput();
    const [certificate, setCertificate, errorCertificate, setErrorCertificate] = useInput();
    const getInsuranceProperty = (userInsurance, fileType) => {
        return (userInsurance.filter(el => el.filesType === fileType)[0] || undefined);
    };
    const hasImagesChanged = (userInsurance) => {
        return frontImage !== getInsuranceProperty(userInsurance, `${insuranceType}Insurance`)
            || backImage !== getInsuranceProperty(userInsurance, `${insuranceType}InsuranceBack`);
    };
    const hasFrontImageChanged = (userInsurance) => {
        return frontImage !== getInsuranceProperty(userInsurance, `${insuranceType}Insurance`);
    };
    const hasBackImageChanged = (userInsurance) => {
        return backImage !== getInsuranceProperty(userInsurance, `${insuranceType}InsuranceBack`);
    };
    const hasEmptyInsuranceInfo = (userInsurance) => {
        return userInsurance.length > 0 && !nameOfInsurer && !backImage && !frontImage;
    };
    const hasTextValueChanged = (userInsurance) => {
        const textValue = userInsurance[0]?.textValue;
        return nameOfInsurer !== (textValue?.insurerName || "")
            || carrier !== (textValue?.carrierNumber || "")
            || contract !== (textValue?.groupPolicyContractNumber || "")
            || certificate !== (textValue?.certificateNumber || "")
            || primaryChoice !== textValue?.primaryCardHolder;
    };
    const hasAllEmptyValues = () => {
        return !nameOfInsurer
            && !carrier
            && !contract
            && !certificate;
    };
    const [primaryChoice, setPrimaryChoice] = useState(undefined);
    const ableToSave = (userInsurance) => {
        const imageDataHasChanged = hasImagesChanged(userInsurance);
        if (imageDataHasChanged) {
            return true;
        }
        const textValueHasChanged = hasTextValueChanged(userInsurance);
        if (textValueHasChanged && !hasAllEmptyValues()) { // then check required text value fields
            if (!nameOfInsurer || nameOfInsurer.trim() === '') {
                return false;
            }
        }
        const isInsuranceInfoEmpty = hasEmptyInsuranceInfo(userInsurance);
        return textValueHasChanged; // || isInsuranceInfoEmpty;
    };
    const hasSomething = frontImage || backImage || nameOfInsurer || carrier || contract || certificate;
    const resetInsurence = () => {
        setFrontImage(undefined);
        setBackImage(undefined);
        setNameOfInsurer("");
        setCarrier("");
        setContract("");
        setCertificate("");
        setPrimaryChoice(undefined);
    };
    return {
        frontImage, setFrontImage,
        backImage, setBackImage,
        nameOfInsurer, setNameOfInsurer,
        carrier, setCarrier,
        contract, setContract,
        certificate, setCertificate,
        primaryChoice, setPrimaryChoice,
        ableToSave, hasSomething, hasTextValueChanged,
        resetInsurence, hasFrontImageChanged, hasBackImageChanged
    };
};
const Insurances = ({ requestGetFilesStatus, requestUpdateFilesStatus, requestInsurancesUpdateStatus, requestGetPreviewPhotoStatus, primaryInsurance: pInsurance, secondaryInsurance: sInsurance, patientId, hasSidebar, updateFiles, getFiles, setHaveChanges, skipAction, children, isNewSignUp, prevStep, hideRequestInsurancesUpload }) => {
    const { t } = useTranslation();
    useEffect(() => {
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: "Insurances",
            firebase_screen_class: "Insurances"
        });
    }, []);
    const refRequestUpdate = usePrevious(requestInsurancesUpdateStatus);
    const primaryInsurance = useInsurance("Primary");
    const secondaryInsurance = useInsurance("Secondary");
    const [idsForDelete, setIdsForDelete] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    const [isSecondaryInsurance, setSecondaryInsurance] = useState(false);
    const saveDisabled = !primaryInsurance.ableToSave(pInsurance) && !secondaryInsurance.ableToSave(sInsurance);
    const [skipStep, setSkipStep] = useState();
    useEffect(() => {
        setHaveChanges(!saveDisabled);
    }, [saveDisabled]);
    useEffect(() => {
        refRequestUpdate !== undefined && requestInsurancesUpdateStatus === "success" && hideRequestInsurancesUpload() && skipAction && skipAction(true);
    }, [requestInsurancesUpdateStatus]);
    useEffect(() => {
        if (pInsurance[0] && pInsurance[0].textValue) {
            primaryInsurance.setNameOfInsurer(pInsurance[0].textValue.insurerName);
            primaryInsurance.setCarrier(pInsurance[0].textValue.carrierNumber || "");
            primaryInsurance.setCertificate(pInsurance[0].textValue.certificateNumber || "");
            primaryInsurance.setContract(pInsurance[0].textValue.groupPolicyContractNumber || "");
            primaryInsurance.setPrimaryChoice(pInsurance[0].textValue.primaryCardHolder);
        }
        let front = pInsurance.find(el => el.filesType === "PrimaryInsurance");
        let back = pInsurance.find(el => el.filesType === "PrimaryInsuranceBack");
        primaryInsurance.setFrontImage(front || undefined);
        primaryInsurance.setBackImage(back || undefined);
    }, [pInsurance]);
    useEffect(() => {
        if (sInsurance[0] && sInsurance[0].textValue) {
            secondaryInsurance.setNameOfInsurer(sInsurance[0].textValue.insurerName);
            secondaryInsurance.setCarrier(sInsurance[0].textValue.carrierNumber || "");
            secondaryInsurance.setCertificate(sInsurance[0].textValue.certificateNumber || "");
            secondaryInsurance.setContract(sInsurance[0].textValue.groupPolicyContractNumber || "");
            secondaryInsurance.setPrimaryChoice(sInsurance[0].textValue.primaryCardHolder);
        }
        // resetImages(secondaryInsurance);
        // } else {
        let front = sInsurance.find(el => el.filesType === "SecondaryInsurance");
        let back = sInsurance.find(el => el.filesType === "SecondaryInsuranceBack");
        secondaryInsurance.setFrontImage(front || undefined);
        secondaryInsurance.setBackImage(back || undefined);
        // resetInputs(secondaryInsurance);
        // }
        setSecondaryInsurance(sInsurance[0]?.id ? true : false);
    }, [sInsurance]);
    useEffect(() => {
        setIdsForDelete([]);
    }, [pInsurance, sInsurance]);
    useEffect(() => {
        getFiles();
    }, [patientId]);
    const resetImages = (insurance) => {
        insurance.setFrontImage(undefined);
        insurance.setBackImage(undefined);
    };
    const resetInputs = (insurance) => {
        insurance.setNameOfInsurer("");
        insurance.setCarrier("");
        insurance.setCertificate("");
        insurance.setContract("");
        insurance.setPrimaryChoice(undefined);
    };
    const resetInsurance = (insurance) => {
        resetImages(insurance);
        resetInputs(insurance);
    };
    const onSave = () => {
        const data = [];
        if (primaryInsurance.hasFrontImageChanged(pInsurance) && !!primaryInsurance.frontImage)
            data.push(primaryInsurance.frontImage);
        if (primaryInsurance.hasBackImageChanged(pInsurance) && !!primaryInsurance.backImage)
            data.push(primaryInsurance.backImage);
        if (secondaryInsurance.hasFrontImageChanged(sInsurance) && !!secondaryInsurance.frontImage)
            data.push(secondaryInsurance.frontImage);
        if (secondaryInsurance.hasBackImageChanged(sInsurance) && !!secondaryInsurance.backImage)
            data.push(secondaryInsurance.backImage);
        const filteredData = data.filter(item => item?.filesType); //.filter(el => [...pInsurance, ...sInsurance].find(file => file.id === el.id));
        const deleted = idsForDelete;
        const isUpdatePrimary = primaryInsurance.hasTextValueChanged(pInsurance);
        const isUpdateSecondary = isSecondaryInsurance && secondaryInsurance.hasTextValueChanged(sInsurance);
        updateFiles({
            textValue: isUpdatePrimary ? {
                patientFileId: pInsurance[0]?.id,
                insurerName: primaryInsurance.nameOfInsurer,
                carrierNumber: primaryInsurance.carrier || undefined,
                groupPolicyContractNumber: primaryInsurance.contract || undefined,
                certificateNumber: primaryInsurance.certificate || undefined,
                primaryCardHolder: primaryInsurance.primaryChoice,
                fileType: "PrimaryInsurance",
                idsForDelete: []
            } : undefined,
            secondaryTextValue: isUpdateSecondary ? {
                patientFileId: sInsurance[0]?.id,
                insurerName: secondaryInsurance.nameOfInsurer,
                carrierNumber: secondaryInsurance.carrier || undefined,
                groupPolicyContractNumber: secondaryInsurance.contract || undefined,
                certificateNumber: secondaryInsurance.certificate || undefined,
                primaryCardHolder: secondaryInsurance.primaryChoice,
                fileType: "SecondaryInsurance",
                idsForDelete: []
            } : undefined,
            fileValue: deleted.length > 0 || filteredData.length > 0 ? {
                deleted: deleted,
                added: {
                    files: filteredData.filter((item) => item.filesType === "PrimaryInsurance" || item.filesType === "PrimaryInsuranceBack"),
                    secondaryFiles: filteredData.filter((item) => item.filesType === "SecondaryInsurance" || item.filesType === "SecondaryInsuranceBack")
                },
                fileType: "PrimaryInsurance",
                additionalFileType: "SecondaryInsurance"
            } : undefined,
            screenName: "Insurance"
        });
    };
    const removeExistFile = (id, deleteType) => {
        setIdsForDelete((ids) => [...ids, { id, deleteType: deleteType ?? "ImageOnly" }]);
    };
    const storeStepsToCompleteData = localStorage.getItem('storeStepsToCompleteData');
    useEffect(() => {
        const stepsToCompleteData = JSON.parse(storeStepsToCompleteData);
        if (stepsToCompleteData.length > 0) {
            const DataSkipStep = stepsToCompleteData;
            for (let data of DataSkipStep) {
                if (data?.step == "AddInsurer" && data?.mandatory == true) {
                    setSkipStep(true);
                }
                else {
                    setSkipStep(false);
                }
            }
        }
    }, [storeStepsToCompleteData]);
    const renderActions = !!skipAction
        ? React.createElement("div", { className: classNames(s.actions, { [s.hasSidebar]: hasSidebar }) },
            React.createElement(Box, { column: true, fullWidth: true },
                React.createElement(Button, { style: s.button, noborder: true, skip: true, text: t("skip"), onClick: skipAction }),
                React.createElement(Box, { f1: true, row: true, gap: 16 },
                    React.createElement(Button, { text: t("back"), onClick: prevStep }),
                    React.createElement(Button, { style: s.button, disabled: saveDisabled, primary: true, iosTextOverflow: true, text: t("saveAndContinue"), onClick: () => {
                            onSave();
                        } }))))
        : React.createElement("div", { className: s.btn_textCenter },
            React.createElement(Button, { style: s.updateButton, disabled: saveDisabled, primary: true, text: t("update"), onClick: onSave }));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(s.contentWrapper, { [s.hasSidebar]: hasSidebar }) },
            React.createElement("div", { className: classNames(isNewSignUp ? si.scrollWrapperfrom : null) },
                React.createElement("div", { className: classNames(isNewSignUp ? si.formstep : null, isNewSignUp ? si.formWrapper : null) },
                    isNewSignUp ? React.createElement(React.Fragment, null,
                        React.createElement("div", { className: s.header },
                            React.createElement("div", { className: s.pages },
                                React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `8/15`)))) : null,
                    isNewSignUp ? React.createElement(React.Fragment, null,
                        React.createElement("div", { className: (si.stepToptext) },
                            React.createElement("h4", null, t("addInsurer")),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null, t("uploadInsurerMsg"))))) : null,
                    React.createElement("div", { className: s.pageWrapper },
                        React.createElement("div", { className: classNames(s.fullLine) }, children),
                        React.createElement(AddInsuranceInputs, { fullLine: s.fullLine, cardStyle: s.card, hasSidebar: hasSidebar, title: t("primaryInsurance"), fileType: "PrimaryInsurance", frontImage: primaryInsurance.frontImage, setFrontImage: primaryInsurance.setFrontImage, backImage: primaryInsurance.backImage, setBackImage: primaryInsurance.setBackImage, nameOfInsurer: primaryInsurance.nameOfInsurer, setNameOfInsurer: primaryInsurance.setNameOfInsurer, carrier: primaryInsurance.carrier, setCarrier: primaryInsurance.setCarrier, contract: primaryInsurance.contract, setContract: primaryInsurance.setContract, certificate: primaryInsurance.certificate, setCertificate: primaryInsurance.setCertificate, primaryChoice: primaryInsurance.primaryChoice, setPrimaryChoice: primaryInsurance.setPrimaryChoice, removeExistFile: removeExistFile, setModalIsOpen: setModalIsOpen, setModalFile: setModalFile, clearAllFields: () => {
                                pInsurance.forEach(insurance => removeExistFile(insurance.id, "Full"));
                                primaryInsurance.resetInsurence();
                            }, haveChanges: primaryInsurance.hasSomething }),
                        isSecondaryInsurance ?
                            React.createElement(React.Fragment, null,
                                React.createElement(AddInsuranceInputs, { fullLine: s.fullLine, cardStyle: s.card, hasSidebar: hasSidebar, title: t("secondaryInsurance"), fileType: "SecondaryInsurance", frontImage: secondaryInsurance.frontImage, setFrontImage: secondaryInsurance.setFrontImage, backImage: secondaryInsurance.backImage, setBackImage: secondaryInsurance.setBackImage, nameOfInsurer: secondaryInsurance.nameOfInsurer, setNameOfInsurer: secondaryInsurance.setNameOfInsurer, carrier: secondaryInsurance.carrier, setCarrier: secondaryInsurance.setCarrier, contract: secondaryInsurance.contract, setContract: secondaryInsurance.setContract, certificate: secondaryInsurance.certificate, setCertificate: secondaryInsurance.setCertificate, primaryChoice: secondaryInsurance.primaryChoice, setPrimaryChoice: secondaryInsurance.setPrimaryChoice, removeExistFile: removeExistFile, setModalIsOpen: setModalIsOpen, setModalFile: setModalFile, clearAllFields: () => {
                                        sInsurance.forEach(insurance => removeExistFile(insurance.id, "Full"));
                                        secondaryInsurance.resetInsurence();
                                        setSecondaryInsurance(false);
                                    }, isNewSignUp: isNewSignUp }))
                            : React.createElement("div", { className: classNames(s.addInsuranceTextButton, s.fullLine), onClick: () => setSecondaryInsurance(true) },
                                React.createElement(PlusIcon, { color: colors.icons.primary, className: s.plusIcon }),
                                React.createElement("div", { style: {
                                        color: colors.icons.primary
                                    } }, t("addSecondaryInsurance"))),
                        React.createElement("div", { className: classNames(s.w100) }, renderActions),
                        React.createElement(ImageModal, { modalIsOpen: modalIsOpen, deleteFile: () => modalFile?.removeImage(modalFile?.image), file: modalFile?.image, toggleModal: () => setModalIsOpen(false) }))))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        requestGetFilesStatus: state.patientFiles.requestGetInsurancesFilesStatus,
        requestUpdateFilesStatus: state.patientFiles.requestUpdateFilesStatus,
        requestInsurancesUpdateStatus: state.patientFiles.requestInsurancesUpdateStatus,
        primaryInsurance: filesByTypesSelector(state, ["PrimaryInsurance", "PrimaryInsuranceBack"]),
        secondaryInsurance: filesByTypesSelector(state, ["SecondaryInsurance", "SecondaryInsuranceBack"]),
        requestGetPreviewPhotoStatus: state.patientFiles.requestGetPreviewPhotoStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getFiles: () => dispatch(getInsuranceAsyncAction()),
        updateFiles: (payload) => dispatch(updatePatientFilesAllTypesAsyncAction(payload)),
        hideRequestInsurancesUpload: () => dispatch(hideRequestInsurancesUploadErrorAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Insurances);
