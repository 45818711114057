import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import PageBody from "../../../../../components/Shared/PageBody";
import { useInput } from "../../../../../hooks";
import s from "./style.scss";
import { Button, InputText } from "../../../../../components/Shared/Controls";
import { doFormat } from "../../../../../utils";
import { Notification, PopUp } from "../../../../../components/Shared/Info";
import OK from "../../../../../resources/icons/circle-ok.svg";
import { connect } from "react-redux";
import { changePhoneNumberAsyncAction, changePhoneRequestAsyncAction, } from "../../../../../store/auth/actions";
import { resetRequestChangePhoneAction, resetRequestChangePhoneCheckAction, setUserPhoneAction, } from "../../../../../store";
import style from "../../../../Settings/style.scss";
const VerifyPhone = ({ setPage, changePhone, userPhone, changePhoneStatus, resendCode, password, changePhoneError, resendStatus, resetRequestSmsCodeStatus, renderPaggination, resendError, resetChangePhoneStatus, updatePhone, backAction, }) => {
    const [verificationCode, setVerificationCode, errorVerificationCode, setErrorVerificationCode,] = useInput();
    const { t } = useTranslation();
    const [phoneNumber] = useInput(userPhone);
    const [sent, setSent] = useState(false);
    const [resent, setResent] = useState(false);
    const [disableResend, setDisableResend] = useState(false);
    const onSubmit = () => {
        changePhone(verificationCode, phoneNumber);
    };
    const onResend = () => {
        resetRequestSmsCodeStatus();
        setResent(false);
        resendCode(phoneNumber, password);
        setDisableResend(true);
        setTimeout(() => setDisableResend(false), 30000);
    };
    useEffect(() => {
        resetRequestSmsCodeStatus();
    }, []);
    useEffect(() => {
        if (changePhoneStatus === "success") {
            setSent(true);
            updatePhone(phoneNumber);
        }
    }, [changePhoneStatus]);
    useEffect(() => {
        resendStatus === "success" && setResent(true);
    }, [resendStatus]);
    return (React.createElement(PageBody, { backAction: backAction, title: t("changePhoneNumber") },
        React.createElement("div", { className: s.scrollWrapper },
            renderPaggination,
            sent && (React.createElement(PopUp, { isOpen: sent, allowClose: true, toggle: () => setPage(1) },
                React.createElement("div", { className: s.popup },
                    React.createElement("div", null,
                        React.createElement(SVG, { src: OK, className: s.popup__header_icon })),
                    React.createElement("div", { className: s.popup__content },
                        React.createElement("div", null,
                            React.createElement("strong", null, t("cellChange"))),
                        React.createElement("div", null,
                            React.createElement("strong", null, t("cellChangeDescriptions").replace("SUPPORT_NUMBER", doFormat(phoneNumber, "1 *** *** ****"))))),
                    React.createElement("div", { className: style.popup__controls },
                        React.createElement(Button, { text: t("backToProfile"), onClick: () => {
                                window.location.href = "/personal-data";
                            } }),
                        React.createElement(Button, { primary: true, text: t("backToHome"), onClick: () => {
                                window.location.href = "/";
                            } }))))),
            React.createElement("div", { className: s.pageWrapper },
                React.createElement("div", null,
                    React.createElement("p", { className: s.description }, t("codeHasBeenSent")),
                    React.createElement(Notification, null,
                        React.createElement("div", { className: s.numberNotif },
                            React.createElement("div", null, doFormat(phoneNumber, "+1 *** *** ****")))),
                    React.createElement(InputText, { name: "smsCode", placeholder: t("smsCode"), value: verificationCode, setValue: setVerificationCode, error: errorVerificationCode, setError: setErrorVerificationCode, length: 4, isNumber: true }),
                    React.createElement(Button, { disabled: false, primary: false, text: t("resend"), onClick: onResend }),
                    resent && disableResend && React.createElement("div", null, t("resendMessage")))),
            React.createElement(Button, { style: s.button, disabled: !verificationCode ||
                    verificationCode.length < 4 ||
                    errorVerificationCode, primary: true, text: t("continue"), onClick: onSubmit }))));
};
const mapStateToProps = (state) => {
    return {
        userPhone: state.auth.phoneNumber,
        changePhoneStatus: state.auth.requestChangePhoneStatus,
        changePhoneError: state.auth.requestChangePhoneError,
        resendStatus: state.auth.requestChangePhoneCheckStatus,
        resendError: state.auth.requestChangePhoneCheckError,
        password: state.auth.password,
        requestSmsCodeError: state.auth.requestSmsCodeError,
        requestSmsCodeStatus: state.auth.requestSmsCodeStatus,
        requestValidateCodeError: state.auth.requestValidateCodeError,
        requestValidateCodeStatus: state.auth.requestValidateCodeStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        changePhone: (code, phoneNumber) => dispatch(changePhoneNumberAsyncAction({ phoneNumber, code })),
        resendCode: (phoneNumber, password) => dispatch(changePhoneRequestAsyncAction({ phoneNumber, password })),
        resetRequestSmsCodeStatus: () => dispatch(resetRequestChangePhoneCheckAction()),
        resetChangePhoneStatus: () => dispatch(resetRequestChangePhoneAction()),
        updatePhone: (phoneNumber) => dispatch(setUserPhoneAction(phoneNumber)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhone);
