import React from 'react';
import s from './style.scss';
import { PopUp } from '..';
import { Button } from '../../Controls';
import { useTranslation } from 'react-i18next';
const InfoPopUp = ({ style, isOpen, children, togglePopUp }) => {
    const { t } = useTranslation();
    return (React.createElement(PopUp, { style: s.popup, contentStyle: s.popUpContent, isOpen: isOpen, toggle: togglePopUp },
        React.createElement("div", { className: s.popup__content }, children),
        React.createElement("div", { className: s.popup__controls },
            React.createElement(Button, { style: s.button, text: t("understood"), onClick: togglePopUp }))));
};
export default InfoPopUp;
