import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { PopUp } from "../../../../components/Shared/Info";
import { useInput, usePrevious } from "../../../../hooks";
import { Button, InputText, } from "../../../../components/Shared/Controls";
import Close from "../../../../resources/icons/close.svg";
import s from "./style.scss";
import InputDate from "../../../../components/Shared/Controls/Inputs/InputDate";
import moment from "moment";
import CheckboxDropdown from "../../../../components/Shared/Controls/CheckboxDropdown";
import Dropdown from "../../../../components/Shared/Controls/Inputs/Dropdown";
import { timelineLabels } from "../../../../utils";
import { addReminderAsyncAction, editReminderAsyncAction } from "../../../../store/medicalReminders";
import colors from "../../../../resources/colors";
const AddEditReminder = ({ requestEditReminderStatus, requestAddReminderStatus, patientId, editingReminder: reminder, setEditingReminder, isOpen, addReminder, editReminder, onSuccess, togglePopup, }) => {
    const { t } = useTranslation();
    const prevAdd = usePrevious(requestAddReminderStatus);
    const prevUpdate = usePrevious(requestEditReminderStatus);
    useEffect(() => {
        if ((requestEditReminderStatus === "success" && prevUpdate !== "success") || (requestAddReminderStatus === "success" && prevAdd !== "success")) {
            !reminder && title && onSuccess();
            toggle();
        }
    }, [requestEditReminderStatus, requestAddReminderStatus]);
    const REPEAT = [
        {
            key: "EveryDay",
            displayName: t("EveryDay")
        },
        {
            key: "SpecificDaysOfTheWeek",
            displayName: t("SpecificDaysOfTheWeek")
        },
        {
            key: "SingleReminder",
            displayName: t("SingleReminder")
        },
        {
            key: "RepeatEveryXDays",
            displayName: t("RepeatEveryXDays")
        },
    ];
    const DAYS_OF_THE_WEEK = [
        {
            key: "Monday",
            displayName: t("monday"),
            isChecked: false
        },
        {
            key: "Tuesday",
            displayName: t("tuesday"),
            isChecked: false
        },
        {
            key: "Wednesday",
            displayName: t("wednesday"),
            isChecked: false
        },
        {
            key: "Thursday",
            displayName: t("thursday"),
            isChecked: false
        },
        {
            key: "Friday",
            displayName: t("friday"),
            isChecked: false
        },
        {
            key: "Saturday",
            displayName: t("saturday"),
            isChecked: false
        },
        {
            key: "Sunday",
            displayName: t("sunday"),
            isChecked: false
        },
    ];
    const REMINDER_SUB_TYPES = [
        {
            key: "General",
            displayName: t("general"),
            isChecked: false
        },
        {
            key: "Medication",
            displayName: t("medication"),
            isChecked: false
        },
        {
            key: "HcpAppointment",
            displayName: t("hcpAppointment"),
            isChecked: false
        },
        {
            key: "PrescriptionRefill",
            displayName: t("prescriptionRefill"),
            isChecked: false
        },
        {
            key: "LabAppointment",
            displayName: t("labAppointment"),
            isChecked: false
        }
    ];
    const [title, setTitle, errorTitle, setErrorTitle] = useInput(reminder?.title || "");
    const [dayCount, setDayCount, errorDayCount, setErrorDayCount] = useInput(reminder?.title || null);
    const [startDate, setStartDate, errorStartDate, setErrorStartDate] = useInput(reminder?.startDate || "");
    const [endDate, setEndDate, errorEndDate, setErrorEndDate] = useInput(reminder?.endDate || "");
    const [daysOfTheWeek, setDaysOfTheWeek] = useState(DAYS_OF_THE_WEEK.map(el => {
        if (!reminder?.daysOfTheWeek)
            return el;
        if (reminder?.daysOfTheWeek?.includes(el.key))
            return { ...el, isChecked: true };
        else
            return el;
    }));
    const [reminderSubTypes, setReminderSubTypes] = useState(REMINDER_SUB_TYPES.map(el => {
        if (!reminder?.reminderSubType)
            return el;
        if (reminder?.reminderSubType == el.key)
            return { ...el, isChecked: true };
        else
            return el;
    }));
    const [repeat, setRepeat] = useState(reminder?.repeatType || "");
    const [reminderType, setReminderType] = useState(reminder?.reminderType || "");
    const [reminderSubType, setReminderSubType] = useState(reminder?.reminderSubType || "");
    const maxDate = moment().add(5, "years").format("YYYY-MM-DD");
    const minDate = moment().format("YYYY-MM-DD");
    const rangeOfTimes = timelineLabels('00:00', 30, 'minutes', localStorage.getItem("i18nextLng") || "en");
    const RANGE_OF_TIMES = rangeOfTimes;
    const [timeOfReceiptSingle, setTimeOfReceiptSingle] = useState("");
    const [timeOfReceipt, setTimeOfReceipt] = useState(RANGE_OF_TIMES);
    const sumbitIsEnabled = title && startDate && repeat && reminderType && !errorTitle && reminderSubType
        && (repeat !== "SingleReminder" ? endDate : true)
        && (repeat === "SpecificDaysOfTheWeek" ? daysOfTheWeek.find(el => el.isChecked) : true)
        && (repeat === "RepeatEveryXDays" ? dayCount && !errorDayCount && dayCount > 0 : true)
        && (repeat !== "SingleReminder" && timeOfReceipt.find(el => el.isChecked) || timeOfReceiptSingle);
    useEffect(() => {
        repeat === "SingleReminder" && setTimeOfReceiptSingle(timeOfReceipt.find(el => el.isChecked)?.key || "");
    }, [repeat]);
    const reset = () => {
        setTitle("");
        setStartDate("");
        setEndDate("");
        setDaysOfTheWeek(DAYS_OF_THE_WEEK);
        setReminderSubTypes(REMINDER_SUB_TYPES);
        setRepeat("");
        setDayCount("");
        setReminderType("");
        setReminderSubType("");
        setTimeOfReceiptSingle("");
        setTimeOfReceipt(RANGE_OF_TIMES);
    };
    useEffect(() => {
        if (reminder) {
            setTitle(reminder.title);
            setStartDate(moment(reminder.startDate));
            setEndDate(moment(reminder.endDate));
            setReminderType(reminder.reminderType);
            setReminderSubType(reminder.reminderSubType);
            setRepeat(reminder.repeatType);
            setDayCount(reminder.repeatIntervalInDays);
            setDaysOfTheWeek(DAYS_OF_THE_WEEK.map(el => {
                if (reminder?.daysOfTheWeek?.includes(el.key))
                    return { ...el, isChecked: true };
                else
                    return el;
            }));
            setTimeOfReceiptSingle(reminder.medicationTimes[0]);
            setTimeOfReceipt(RANGE_OF_TIMES.map(el => {
                if (reminder?.medicationTimes?.includes(el.key))
                    return { ...el, isChecked: true };
                else
                    return el;
            }));
        }
    }, [reminder]);
    const addReminderAction = () => {
        if (!reminder)
            addReminder({
                patientId: patientId || "",
                isActive: true,
                title: title,
                reminderSubType: reminderSubType,
                reminderType: reminderType,
                repeatType: repeat,
                startDate: startDate,
                endDate: repeat === "SingleReminder" ? startDate : endDate,
                medicationTimes: repeat !== "SingleReminder" ? timeOfReceipt.filter(el => el.isChecked).map(el => el.key) : [timeOfReceiptSingle],
                daysOfTheWeek: repeat === "SpecificDaysOfTheWeek" && daysOfTheWeek.filter(el => el.isChecked).map(el => el.key) || undefined,
                repeatIntervalInDays: repeat === "RepeatEveryXDays" ? dayCount : null
            });
        else
            editReminder({
                id: reminder?.id,
                patientId: patientId || "",
                isActive: reminder?.isActive || false,
                title: title,
                reminderSubType: reminderSubType,
                reminderType: reminderType,
                repeatType: repeat,
                startDate: startDate,
                endDate: repeat === "SingleReminder" ? startDate : endDate,
                medicationTimes: repeat !== "SingleReminder" ? timeOfReceipt.filter(el => el.isChecked).map(el => el.key) : [timeOfReceiptSingle],
                daysOfTheWeek: repeat === "SpecificDaysOfTheWeek" && daysOfTheWeek.filter(el => el.isChecked).map(el => el.key) || undefined,
                repeatIntervalInDays: repeat === "RepeatEveryXDays" ? dayCount : null
            });
    };
    const toggle = () => {
        reset();
        isOpen && togglePopup();
        reminder && setEditingReminder(null);
    };
    return (React.createElement(PopUp, { style: s.reminderPopup, isOpen: isOpen || reminder !== null, toggle: toggle },
        React.createElement("div", { className: classNames(s.reminderPopupHeader) },
            React.createElement("h4", null, reminder ? t("editReminder") : t("addReminder")),
            React.createElement(SVG, { src: Close, onClick: toggle })),
        React.createElement("div", { className: s.scrollWrapper },
            React.createElement(InputText, { name: "title", label: t("enterNameOfMedication"), pattern: "^.{0,35}$", value: title, setValue: setTitle, error: title && errorTitle, setError: setErrorTitle }),
            React.createElement(Dropdown, { data: reminderSubTypes, setValue: setReminderSubType, value: reminderSubType, label: t("reminderSubType") }),
            repeat !== "SingleReminder" ?
                React.createElement(CheckboxDropdown, { styleList: s.checkbox_list, items: timeOfReceipt, setItems: setTimeOfReceipt, label: t("setTime") })
                :
                    React.createElement(Dropdown, { data: RANGE_OF_TIMES, setValue: setTimeOfReceiptSingle, value: timeOfReceiptSingle, label: t("setTime") }),
            React.createElement(Dropdown, { data: REPEAT, value: repeat, setValue: setRepeat, label: t("repeat") }),
            repeat === "SpecificDaysOfTheWeek" &&
                React.createElement(CheckboxDropdown, { styleList: s.checkbox_list, items: daysOfTheWeek, setItems: setDaysOfTheWeek, label: t("selectDaysOfWeek") }),
            repeat === "RepeatEveryXDays" &&
                React.createElement(InputText, { name: "Day Interval", label: t("dayInterval"), pattern: "^[0-9]$", value: dayCount, setValue: setDayCount, error: dayCount && errorDayCount, setError: setErrorDayCount }),
            repeat !== "SingleReminder" ?
                React.createElement("div", { className: s.dateInputs },
                    React.createElement(InputDate, { name: "startDate", label: t("startDate"), value: startDate, setValue: setStartDate, minDate: minDate, maxDate: endDate || maxDate }),
                    React.createElement(InputDate, { name: "endDate", label: t("endDate"), value: endDate, setValue: setEndDate, minDate: startDate || minDate, maxDate: maxDate }))
                :
                    React.createElement(InputDate, { name: "startDate", label: t("Date"), value: startDate, setValue: setStartDate, minDate: minDate, maxDate: repeat === "SingleReminder" ? maxDate : endDate || maxDate }),
            React.createElement("div", null, t("reminderType")),
            React.createElement("div", { className: s.cases, style: {
                    "--checkbox-active-color": colors.controlsGeneralColor
                } },
                React.createElement("div", { className: s.case },
                    React.createElement("input", { className: s.checkbox, type: "radio", checked: reminderType === "Sms", onChange: () => { setReminderType("Sms"); } }),
                    React.createElement("span", { className: s.checkmark }),
                    React.createElement("div", { className: s.agree }, t("textMessage"))),
                React.createElement("div", { className: s.case },
                    React.createElement("input", { className: s.checkbox, type: "radio", checked: reminderType === "Email", onChange: () => { setReminderType("Email"); } }),
                    React.createElement("span", { className: s.checkmark }),
                    React.createElement("div", { className: s.agree }, t("email")))),
            React.createElement("div", { className: s.buttons },
                React.createElement(Button, { style: s.button, text: t("cancel"), onClick: toggle }),
                React.createElement(Button, { style: s.button, text: reminder && t("save") || t("addReminder"), primary: true, onClick: addReminderAction, disabled: !sumbitIsEnabled })))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        requestEditReminderStatus: state.reminders.requestEditReminderStatus,
        requestAddReminderStatus: state.reminders.requestAddReminderStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        addReminder: (reminder) => dispatch(addReminderAsyncAction(reminder)),
        editReminder: (reminder) => dispatch(editReminderAsyncAction(reminder)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditReminder);
