import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import colors from "../../../../../resources/colors";
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import smimg from "../../../../../../src/resources/images/alarm.png";
import { Grid, Link } from "@material-ui/core";
import { useInput } from "../../../../../hooks";
import { Button as CustomButton, InputText, } from "../../../../../components/Shared/Controls";
import { getPatientAddressesAsyncAction } from "../../../../../store";
import { connect } from "react-redux";
import { PostInfusionClinicInfoAsyncAction, getPatientInfusionClinicAsyncAction, PutInfusionClinicInfoAsyncAction, getInfusionClinicsAsyncAction } from "../../../../../store/InfusionClinic/Sagas";
import LoadingPopUp from "../../../../../components/Shared/LoadingPopUp";
import Typography from "../../../../../components/Shared/Typography";
import MapContainerMultiple from "../../../../../components/MapContainerMultiple";
import { resetPostInfusionClinicInfoAction, resetPutInfusionClinicInfoAction } from "../../../../../store/InfusionClinic";
import { useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
const AddInfusionClinic = ({ address, setStep, previousStep, getInfusionClinicsAsyncAction, infusionClinicsData, requestGetInfusionClinicStatus, isNewSignUp, requestGetOnlyInfusionClinicStatus, postInfusionClinicAction, requestPostInfusionClinicStatus, resetPostInfusionClinicAction, patientId, drugBrand, getPatientInfusionClinicAsyncAction, patientInfusionClinicData, putInfusionClinicAction, requestPutInfusionClinicStatus, resetPutInfusionClinicAction, isDashboard, getPatientAddresses }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentTab, setCurrentTab] = useState({
        key: "searchByYourAddress",
    });
    const [currentLocation, setCurrentLocation] = useState(null);
    const [haveChanges, setHaveChanges] = useState(false);
    const [clinicName, setClinicName, errorClinicName, setErrorClinicName] = useInput();
    const [clinicAddress, setClinicAddress, errorClinicAddress, setErrorClinicAddress] = useInput();
    const [addressLocation, setAddressLocation] = useState();
    const [skipStep, setSkipStep] = useState();
    const [youAreSureModal, setYouAreSureModal] = useState({ show: false });
    const [selectedData, setSelectedData] = useState(null);
    const [tmpLocationArray, setTemLocationArray] = useState([]);
    const clickOnTab = (key) => {
        !haveChanges
            ? setCurrentTab({ key })
            : setYouAreSureModal({ show: true, key: key });
    };
    const tabs = [
        {
            key: "searchByYourAddress",
            title: t("searchByYourAddress"),
        },
        {
            key: "searchByName",
            title: t("searchByName"),
        },
    ];
    useEffect(() => {
        if (currentTab.key == "searchByName") {
            setClinicAddress("");
            setAddressLocation(null);
        }
        if (currentTab.key == "searchByYourAddress") {
            setClinicName("");
        }
        setSelectedData(null);
    }, [currentTab]);
    useEffect(() => {
        if (requestPutInfusionClinicStatus === "success" && isDashboard) {
            resetPutInfusionClinicAction();
            navigate('/infusion-Clinic');
        }
        if (requestPutInfusionClinicStatus === "success" && isNewSignUp) {
            resetPutInfusionClinicAction();
            setStep?.(true);
        }
    }, [requestPutInfusionClinicStatus]);
    const storeStepsToCompleteData = localStorage.getItem('storeStepsToCompleteData');
    useEffect(() => {
        const stepsToCompleteData = JSON.parse(storeStepsToCompleteData);
        if (stepsToCompleteData.length > 0) {
            const DataSkipStep = stepsToCompleteData;
            for (let data of DataSkipStep) {
                if (data?.step == "InfusionClinic" && data?.mandatory == true) {
                    setSkipStep(true);
                }
                else {
                    setSkipStep(false);
                }
            }
        }
    }, [storeStepsToCompleteData]);
    const getClinicsByUserLocation = () => {
        // if (currentLocation) {
        //     getInfusionClinicsAsyncAction({ name: '', address: '',
        //         longitude: currentLocation.lng,
        //         latitude: currentLocation.lat
        //     });
        // } 
        if (address) {
            if (address.postalCode) {
                setClinicAddress('');
                getClinicsByGeocode();
            }
            else if (address.street) {
                setClinicAddress(address?.street);
                getInfusionClinicsAsyncAction({ name: '', address: address?.street });
            }
            else {
                setClinicAddress('');
                getInfusionClinicsAsyncAction({ name: '', address: '',
                    longitude: -79.3831843,
                    latitude: 43.653226
                });
            }
        }
        else {
            setClinicAddress('');
            getInfusionClinicsAsyncAction({ name: '', address: '',
                longitude: -79.3831843,
                latitude: 43.653226
            });
        }
    };
    useEffect(() => {
        if (!isLoaded || requestGetInfusionClinicStatus !== "success")
            return;
        if (patientInfusionClinicData?.length > 0 && patientInfusionClinicData?.[0]?.name && currentTab.key == "searchByName") {
            setClinicName(patientInfusionClinicData[0].name);
            getInfusionClinicsAsyncAction({ name: patientInfusionClinicData[0].name, address: '' });
        }
        else {
            getClinicsByUserLocation();
        }
    }, [patientInfusionClinicData, currentTab.key, isLoaded, address]);
    const Submit = () => {
        if (patientInfusionClinicData?.length > 0) {
            const data = {
                id: patientInfusionClinicData?.[0]?.patientBrandInfusionClinicId,
                name: selectedData?.name,
                address: selectedData?.address,
                city: selectedData?.city,
                country: selectedData?.country,
                province: selectedData?.province,
                phone: selectedData?.phone,
                longitude: selectedData?.longitude,
                latitude: selectedData?.latitude,
                brandIdentifier: selectedData?.brandIdentifier,
                note: selectedData?.note
            };
            putInfusionClinicAction(data);
        }
        else {
            const data = {
                name: selectedData?.name,
                address: selectedData?.address,
                city: selectedData?.city,
                country: selectedData?.country,
                province: selectedData?.province,
                phone: selectedData?.phone,
                longitude: selectedData?.longitude,
                latitude: selectedData?.latitude,
                brandIdentifier: selectedData?.brandIdentifier,
                note: selectedData?.note
            };
            postInfusionClinicAction(data);
        }
    };
    useEffect(() => {
        if (infusionClinicsData) {
            const tmpLocation = [];
            for (var i = 0; i < infusionClinicsData?.length; i++) {
                tmpLocation.push({
                    id: infusionClinicsData[i].infusionClinicId,
                    lat: infusionClinicsData[i].latitude,
                    lng: infusionClinicsData[i].longitude,
                });
            }
            if (patientInfusionClinicData.length > 0 && !infusionClinicsData?.some(p => p.brandIdentifier == patientInfusionClinicData?.[0]?.brandIdentifier)
                && !!patientInfusionClinicData[0].latitude && !!patientInfusionClinicData[0].longitude) {
                tmpLocation.push({
                    id: patientInfusionClinicData[0].id,
                    lat: patientInfusionClinicData[0].latitude,
                    lng: patientInfusionClinicData[0].longitude,
                });
            }
            setTemLocationArray(tmpLocation);
        }
    }, [infusionClinicsData]);
    useEffect(() => {
        getPatientAddresses();
        getPatientInfusionClinicAsyncAction({
            patientId
        });
    }, []);
    const getClinicsByGeocode = () => {
        const geocoder = new google.maps.Geocoder();
        console.log("Geocoding: " + address?.postalCode);
        geocoder.geocode({ address: address?.postalCode }, function (results, status) {
            console.log(status, results);
            if (status === "OK" && results != null) {
                getInfusionClinicsAsyncAction({ name: '', address: '',
                    longitude: results[0].geometry.location.lng(),
                    latitude: results[0].geometry.location.lat()
                });
            }
            else {
                getInfusionClinicsAsyncAction({ address: address?.street });
                console.log("Geocoding was not successful for the following reason: " + status);
            }
        });
    };
    useEffect(() => {
        if (clinicAddress == '' && clinicName == '') {
            setSelectedData(null);
        }
    }, [clinicName, clinicAddress]);
    const searchFunction = () => {
        if (clinicName != '') {
            setSelectedData(null);
            getInfusionClinicsAsyncAction({ name: clinicName, address: '' });
        }
        else if (addressLocation != null) {
            setSelectedData(null);
            getInfusionClinicsAsyncAction({ name: '', address: '', latitude: addressLocation.lat, longitude: addressLocation.lng });
        }
    };
    const setSelectedDataFun = (data) => {
        setSelectedData(data);
    };
    const handlePinSelected = (location) => {
        let lat = location.latLng?.lat();
        let long = location.latLng?.lng();
        let clinic = infusionClinicsData?.find(c => c.latitude == lat && c.longitude == long);
        setSelectedData(clinic ?? null);
    };
    useEffect(() => {
        if (requestPostInfusionClinicStatus === "success" && isNewSignUp) {
            resetPostInfusionClinicAction();
            setStep?.(true);
        }
        if (requestPostInfusionClinicStatus === "success" && isDashboard) {
            resetPostInfusionClinicAction();
            navigate('/infusion-Clinic');
        }
    }, [requestPostInfusionClinicStatus]);
    const NavigateFunction = () => {
        if (isDashboard) {
            navigate('/infusion-Clinic');
        }
    };
    useEffect(() => {
        if (patientInfusionClinicData?.length > 0 && isDashboard) {
            setSelectedData(patientInfusionClinicData?.[0]);
        }
    }, [patientInfusionClinicData]);
    const getGoogleMapsLink = (item) => {
        if (item?.latitude && item?.longitude) {
            let link = `https://www.google.com/maps/search/${item?.latitude}, ${item?.longitude}/@${item?.latitude},${item?.longitude},10z`;
            return link;
        }
        let link = `https://www.google.com/maps/search/${item?.address}`;
        if (!!item?.city) {
            link += ` ${item?.city}`;
        }
        if (!!item?.country) {
            link += ` ${item?.country}`;
        }
        if (!!item?.postal) {
            link += ` ${item?.postal}`;
        }
        return link;
    };
    const renderTab = () => {
        switch (currentTab.key) {
            case "searchByYourAddress":
                return React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(si.contentInput) },
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("clinicAddress")),
                            React.createElement("div", { className: classNames(s.formGroupsearch) },
                                React.createElement(GooglePlacesAutocomplete, { apiKey: "AIzaSyC10P0ckGNI2GUddp7kNz03ZqFE67ODacs", selectProps: {
                                        value: clinicAddress,
                                        isClearable: true,
                                        controlShouldRenderValue: true,
                                        onChange: (place) => {
                                            setClinicAddress(place);
                                            if (!!place) {
                                                geocodeByAddress(place.label)
                                                    .then((res) => getLatLng(res[0]))
                                                    .then((location) => {
                                                    console.log('Successfully got latitude and longitude', location);
                                                    setAddressLocation(location);
                                                });
                                            }
                                            else {
                                                getClinicsByUserLocation();
                                            }
                                        },
                                        styles: {
                                            control: (props) => ({
                                                ...props,
                                                backgroundColor: colors.primaryshades3,
                                                height: 56,
                                                border: "1px solid #e0e1e2",
                                                marginTop: 8
                                            })
                                        }
                                    } }),
                                React.createElement(CustomButton, { primary: true, onClick: () => { searchFunction(); }, text: t("search") })))));
            case "searchByName":
                return React.createElement(React.Fragment, null,
                    React.createElement("div", { className: classNames(si.contentInput) },
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("clinicName")),
                            React.createElement("div", { className: classNames(s.formGroupsearch) },
                                React.createElement(InputText, { name: "clinicName", pattern: '^.{0,35}$', value: clinicName, setValue: setClinicName, error: clinicName && errorClinicName, setError: setErrorClinicName, tabIndex: 1, onCancel: getClinicsByUserLocation }),
                                React.createElement(CustomButton, { onClick: () => { searchFunction(); }, style: s.mt0_button, primary: true, text: t("search") })))));
            default:
                return React.createElement("div", null);
        }
    };
    return (React.createElement("div", { className: classNames(si.contentWrapper) },
        React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
            React.createElement("div", { className: classNames(si.formWrapper, s.hiddenscroll, s.pb20) },
                React.createElement("div", { className: classNames(si.formstepbar) },
                    isNewSignUp ? React.createElement(React.Fragment, null,
                        React.createElement("div", { className: s.header },
                            React.createElement("div", { className: s.pages },
                                React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `11/15`)))) : null,
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: (si.stepToptext) },
                            patientInfusionClinicData?.length > 0 ? React.createElement(React.Fragment, null,
                                React.createElement("h4", null, t("updateInfusionClinic"))) : React.createElement(React.Fragment, null,
                                React.createElement("h4", null, t("addInfusionClinic"))),
                            React.createElement("p", null, t("pleaseEnterYourSearch")))),
                    React.createElement("div", { className: s.navBar }, tabs.map((tab) => (React.createElement("div", { key: tab.key, className: classNames({ [s.active]: tab.key === currentTab.key }), style: {
                            borderColor: colors.tabBorderColor
                        }, title: tab.title, onClick: () => clickOnTab(tab.key) }, tab.title)))),
                    React.createElement("div", null,
                        React.createElement(Grid, { container: true, spacing: 2 },
                            React.createElement(Grid, { item: true, xs: 6 },
                                React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: classNames(s.mt15) },
                                        React.createElement("div", null, renderTab()),
                                        !!address && React.createElement("div", null,
                                            t("searchingOn"),
                                            ": ",
                                            !!clinicAddress ? clinicAddress.label : !!clinicName ? clinicName : address.postalCode ?? address.street),
                                        React.createElement("div", { className: classNames(s.ClinicList) },
                                            patientInfusionClinicData?.length > 0
                                                && infusionClinicsData?.every((p) => {
                                                    console.log(p.brandIdentifier, patientInfusionClinicData?.[0].brandIdentifier);
                                                    return p.brandIdentifier != patientInfusionClinicData?.[0].brandIdentifier;
                                                })
                                                &&
                                                    React.createElement("ul", null,
                                                        React.createElement("li", { key: "solo", onClick: () => setSelectedDataFun(patientInfusionClinicData[0]), className: selectedData == patientInfusionClinicData[0] ? s.addressActive : null },
                                                            React.createElement("h3", null, t("current")),
                                                            React.createElement("h3", null,
                                                                t("name"),
                                                                ": ",
                                                                patientInfusionClinicData[0]?.name),
                                                            React.createElement("p", { className: si.m0 },
                                                                " ",
                                                                t("address"),
                                                                ": ",
                                                                patientInfusionClinicData[0]?.address,
                                                                " ",
                                                                patientInfusionClinicData[0]?.city,
                                                                !!patientInfusionClinicData[0]?.province && ", " + patientInfusionClinicData[0]?.province,
                                                                !!patientInfusionClinicData[0]?.postal && ", " + patientInfusionClinicData[0]?.postal),
                                                            React.createElement("p", { className: si.m0 },
                                                                " ",
                                                                t("phone"),
                                                                ": ",
                                                                patientInfusionClinicData[0]?.phone,
                                                                " "),
                                                            React.createElement("div", { className: classNames(s.GetDirectionsbox) },
                                                                React.createElement("div", { className: classNames(s.GetDirectionsboxtext) },
                                                                    React.createElement(Link, { href: getGoogleMapsLink(patientInfusionClinicData[0]), target: "_blank", className: si.GetDirectionsboxlink },
                                                                        " ",
                                                                        t("getdirections"),
                                                                        " ")),
                                                                React.createElement("div", { className: classNames(s.GetDirectionsboxImg) },
                                                                    React.createElement("img", { src: smimg, className: "img-fluid" }))))),
                                            (infusionClinicsData?.length ?? 0 > 0) ? React.createElement(React.Fragment, null,
                                                React.createElement("ul", null, infusionClinicsData?.map((item, index) => (React.createElement("li", { key: index, onClick: () => setSelectedDataFun(item), className: selectedData?.name === item?.name ? s.addressActive : null },
                                                    item.isPreferred
                                                        && React.createElement("p", null, t("preferred")),
                                                    React.createElement("h3", null,
                                                        t("name"),
                                                        ": ",
                                                        item?.name),
                                                    React.createElement("p", { className: si.m0 },
                                                        t("address"),
                                                        ": ",
                                                        item?.address,
                                                        " ",
                                                        item?.city,
                                                        !!item?.province && ", " + item?.province,
                                                        !!item?.postal && ", " + item?.postal),
                                                    React.createElement("p", { className: si.m0 },
                                                        t("phone"),
                                                        ": ",
                                                        item?.phone ?? "N/A"),
                                                    React.createElement("div", { className: classNames(s.GetDirectionsbox) },
                                                        React.createElement("div", { className: classNames(s.GetDirectionsboxtext) },
                                                            React.createElement(Link, { href: getGoogleMapsLink(item), target: "_blank", className: si.GetDirectionsboxlink }, t("getdirections"))),
                                                        React.createElement("div", { className: classNames(s.GetDirectionsboxImg) },
                                                            React.createElement("img", { src: smimg, className: "img-fluid" })))))))) : React.createElement("h3", null, "No records found")),
                                        !skipStep && isNewSignUp ?
                                            React.createElement("div", { className: classNames(si.textCenter, si.mt20) },
                                                React.createElement("h3", { className: si.linktwo, onClick: () => setStep?.(true) },
                                                    " ",
                                                    t("Skipthisstep"),
                                                    " ")) : null,
                                        React.createElement("div", { className: si.buttonTwopage },
                                            React.createElement(CustomButton, { text: t("back"), onClick: isNewSignUp ? previousStep : NavigateFunction }),
                                            React.createElement(CustomButton, { style: s.mb0_button, primary: true, disabled: selectedData == null || selectedData?.brandIdentifier == patientInfusionClinicData?.[0]?.brandIdentifier, text: t("SaveProceed"), onClick: Submit }))))),
                            React.createElement(Grid, { item: true, xs: 6 },
                                React.createElement("div", { style: { backgroundColor: "gray", height: "60vh" } },
                                    React.createElement(MapContainerMultiple, { locations: tmpLocationArray, address: address?.postalCode, setIsLoaded: setIsLoaded, handlePinSelected: handlePinSelected, selectedLocation: !!selectedData && !!selectedData.latitude ? { lat: selectedData.latitude, lng: selectedData.longitude } : null, setCurrentLocation: setCurrentLocation })))))))),
        requestGetInfusionClinicStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestGetOnlyInfusionClinicStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestPostInfusionClinicStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestPutInfusionClinicStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId ?? "",
        address: state.patientAddress.setAddressData,
        infusionClinicsData: state.InfusionClinic.getOnlyInfusionClinicData,
        requestGetInfusionClinicStatus: state.InfusionClinic.requestGetInfusionClinicStatus,
        requestGetOnlyInfusionClinicStatus: state.InfusionClinic.requestGetOnlyInfusionClinicStatus,
        requestPostInfusionClinicStatus: state.InfusionClinic.requestPostInfusionClinicStatus,
        patientInfusionClinicData: state.InfusionClinic.getInfusionClinicData,
        requestPutInfusionClinicStatus: state.InfusionClinic.requestPutInfusionClinicStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getInfusionClinicsAsyncAction: (data) => dispatch(getInfusionClinicsAsyncAction(data)),
        getPatientInfusionClinicAsyncAction: (data) => dispatch(getPatientInfusionClinicAsyncAction(data)),
        getPatientAddresses: () => dispatch(getPatientAddressesAsyncAction()),
        postInfusionClinicAction: (data) => dispatch(PostInfusionClinicInfoAsyncAction(data)),
        resetPostInfusionClinicAction: () => dispatch(resetPostInfusionClinicInfoAction()),
        putInfusionClinicAction: (data) => dispatch(PutInfusionClinicInfoAsyncAction(data)),
        resetPutInfusionClinicAction: () => dispatch(resetPutInfusionClinicInfoAction())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddInfusionClinic);
