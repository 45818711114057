import config from "../../../config.json";
export const phoneNumberSelector = (state) => state.auth.phoneNumber;
export const signUpPayloadSelector = (state, password) => {
    return {
        getStartedStepDto: {
            phoneNumber: state.auth.phoneNumber
        },
        userDetailsStepDto: {
            firstName: state.auth.user.firstName,
            lastName: state.auth.user.lastName,
            birthDate: state.auth.user.dateOfBirth,
            drugBrandCode: state.auth.brandId ?? config.brand,
            email: state.auth.user.email
        },
        patientDetailsStepDto: state.auth.accountType === "someone"
            ? {
                firstName: state.auth.patient.firstName,
                lastName: state.auth.patient.lastName,
                birthDate: state.auth.patient.dateOfBirth,
                relationship: state.auth.patient.relationship
            }
            : null,
        almostDoneStepDto: {
            province: state.auth.provinceId,
            gender: state.auth.additionalInfo.gender,
            prescriberName: state.auth.additionalInfo.doctorsName,
            din: state.auth.additionalInfo.din,
            medicalConditions: state.auth.additionalInfo.medicalConditions?.map((condition) => {
                return { ...condition, medicalConditionId: condition.medicalConditionId, drugBrand: state.auth.brandId ?? config.brand };
            }),
        },
        passwordStepDto: {
            password: password,
            passwordConfirmation: password,
            policyAccepted: true,
            verificationCode: state.auth.verificationCode
        },
        applicationType: config.applicationType
    };
};
export const CompleteRegistrationPayloadSelector = (state) => {
    return {
        userDetailsStepDto: {
            firstName: state.auth.user.firstName,
            lastName: state.auth.user.lastName,
            birthDate: state.auth.user.dateOfBirth,
            drugBrandCode: state.auth.brandId,
            email: state.auth.user.email
        },
        patientDetailsStepDto: state.auth.accountType === "someone"
            ? {
                firstName: state.auth.patient.firstName,
                lastName: state.auth.patient.lastName,
                birthDate: state.auth.patient.dateOfBirth,
                relationship: state.auth.patient.relationship
            }
            : null,
        almostDoneStepDto: {
            province: state.auth.provinceId,
            gender: state.auth.additionalInfo.gender,
            prescriberName: state.auth.additionalInfo.doctorsName,
            din: state.auth.additionalInfo.din,
            medicalConditions: state.auth.additionalInfo.medicalConditions?.map((condition) => {
                return { ...condition, medicalConditionId: condition.medicalConditionId, drugBrand: state.auth.brandId ?? config.brand };
            }),
        },
        phoneNumber: state.auth.phoneNumber,
        verificationCode: state.auth.verificationCode,
        applicationType: config.applicationType,
    };
};
export const passwordSelector = (state) => {
    return state.auth.password;
};
export const memberInfoSelector = (state, payload) => {
    return {
        firstName: state.auth.patient.firstName,
        lastName: state.auth.patient.lastName,
        birthDate: state.auth.patient.dateOfBirth,
        relationship: state.auth.patient.relationship,
        province: state.auth.provinceId,
        gender: payload?.gender,
        drugBrandCode: state.account.member.drugBrandCode,
        din: payload?.din || "",
        medicalConditions: payload?.medicalConditions.map((condition) => {
            return { ...condition, medicalConditionId: condition.medicalConditionId, drugBrand: state.account.member.drugBrandCode };
        }),
        authUserId: payload?.azureUserId
    };
};
