// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__tag--vjCYk{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;background:#ffffff;color:#2f5285;height:28px;border:1px solid #e0e1e2;padding:8px 12px;font-weight:600;font-size:16px;-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:4px;cursor:pointer;outline:none}.style__tag--vjCYk:not(:last-of-type){margin-right:12px;margin-bottom:12px}.style__active--f5tt_{background:#2f5285;color:#ffffff;border:1px solid #000000}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/Controls/Tag/style.scss"],"names":[],"mappings":"AAAA,mBACI,mBAAa,CAAb,mBAAa,CAAb,YAAa,CACb,uBAAuB,CAAvB,oBAAuB,CAAvB,sBAAuB,CACvB,2BAAsB,CAAtB,4BAAsB,CAAtB,yBAAsB,CAAtB,qBAAsB,CACtB,yBAAkB,CAAlB,sBAAkB,CAAlB,iBAAkB,CAClB,kBAAmB,CACnB,aAAc,CACd,WAAY,CACZ,wBAAyB,CACzB,gBAAiB,CACjB,eAAgB,CAChB,cAAe,CAEf,6BAAsB,CAAtB,qBAAsB,CACtB,iBAAkB,CAMlB,cAAe,CACf,YAAa,CArBjB,sCAiBQ,iBAAkB,CAClB,kBAAmB,CACtB,sBAMD,kBAAmB,CACnB,aAAc,CACd,wBAAyB","sourcesContent":[".tag {\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    width: fit-content;\r\n    background: #ffffff;\r\n    color: #2f5285;\r\n    height: 28px;\r\n    border: 1px solid #e0e1e2;\r\n    padding: 8px 12px;\r\n    font-weight: 600;\r\n    font-size: 16px;\r\n\r\n    box-sizing: border-box;\r\n    border-radius: 4px;\r\n\r\n    &:not(:last-of-type) {\r\n        margin-right: 12px;\r\n        margin-bottom: 12px;\r\n    }\r\n    cursor: pointer;\r\n    outline: none;\r\n}\r\n\r\n.active {\r\n    background: #2f5285;\r\n    color: #ffffff;\r\n    border: 1px solid #000000;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "style__tag--vjCYk",
	"active": "style__active--f5tt_"
};
export default ___CSS_LOADER_EXPORT___;
