import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { useInput } from "../../../../../hooks";
import { Button, InputText, } from "../../../../../components/Shared/Controls";
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import { useNavigate } from "react-router-dom";
import { createPatientBrandPrescriberInfoAsyncAction, getPatientBrandPrescriberAsyncAction, updatePatientBrandPrescriberInfoAsyncAction } from "../../../../../store/PatientBrandPrescriber/Sagas";
import LoadingPopUp from "../../../../../components/Shared/LoadingPopUp";
import { resetCreatePatientBrandPrescriberInfoAsyncAction, resetUpdatePatientBrandPrescriberInfoAsyncAction } from "../../../../../store/PatientBrandPrescriber";
import Typography from "../../../../../components/Shared/Typography";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import colors from "../../../../../resources/colors";
const initialAddress = {
    postalCode: "",
    city: "",
    street: "",
    province: "",
    addressType: "home",
    isPrimary: false,
};
const AddPrescriberManual = ({ isNewSignUp, setStep, prevStep, createPatientBrandPrescriber, getPatientBrandPrescriber, getPatientBrandPrescriberData, InviteLoginStoredData, requestGetPatientBrandPrescriberStatus, requestPatientBrandPrescriberPostStatus, resetCreatePrescriber, isUserDashboard, patientId, updatePatientBrandPrescriber, requestUpdatePatientBrandPrescriberInfoStatus, resetUpdatePrescriber, userCurrentDrugBrandCode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [city, setCity, errorCity, setErrorCity] = useInput(initialAddress.city);
    const [province, setProvince, errorProvince, setErrorProvince] = useInput(initialAddress.province);
    const [AddressLineOne, setAddressLineOne, errorAddressLineOne, setErrorAddressLineOne] = useInput();
    const [prescriberFirstName, setPrescriberFirstName, errorPrescriberFirstName, setErrorPrescriberFirstName] = useInput();
    // const [prescriberLastName, setPrescriberLastName, errorPrescriberLastName, setErrorPrescriberLastName] = useInput();
    const [phone, setPhone] = React.useState('');
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput();
    const [skipStep, setSkipStep] = useState();
    const handlePhoneInputChange = (value, info) => {
        setPhone(value);
        setPhoneNumber(info.nationalNumber);
        setErrorPhoneNumber(!matchIsValidTel(value));
    };
    const allFieldsValid = () => city && AddressLineOne && !errorAddressLineOne
        && city && !errorCity
        && province && !errorProvince
        && prescriberFirstName && !errorPrescriberFirstName
        && phoneNumber && !errorPhoneNumber;
    const hasChange = () => getPatientBrandPrescriberData == null
        || getPatientBrandPrescriberData.name != prescriberFirstName
        || getPatientBrandPrescriberData.phone != phoneNumber
        || getPatientBrandPrescriberData.address != AddressLineOne
        || getPatientBrandPrescriberData.city != city
        || getPatientBrandPrescriberData.province != province;
    useEffect(() => {
        if (getPatientBrandPrescriberData) {
            setPrescriberFirstName(getPatientBrandPrescriberData?.name);
            setPhoneNumber(getPatientBrandPrescriberData?.phone);
            setPhone("+1" + getPatientBrandPrescriberData?.phone);
            setAddressLineOne(getPatientBrandPrescriberData?.address);
            setCity(getPatientBrandPrescriberData?.city);
            setProvince(getPatientBrandPrescriberData?.province);
        }
    }, [getPatientBrandPrescriberData]);
    useEffect(() => {
        getPatientBrandPrescriber({ patientId });
    }, []);
    const storeStepsToCompleteData = localStorage.getItem('storeStepsToCompleteData');
    useEffect(() => {
        const stepsToCompleteData = JSON.parse(storeStepsToCompleteData);
        if (stepsToCompleteData.length > 0 && !isUserDashboard) {
            const DataSteps = stepsToCompleteData;
            for (let data of DataSteps) {
                if (data?.step === "Prescriber" && data?.mandatory === true) {
                    setSkipStep(true);
                }
                else {
                    setSkipStep(false);
                }
            }
        }
    }, [storeStepsToCompleteData]);
    useEffect(() => {
        if (requestPatientBrandPrescriberPostStatus === "success") {
            if (isUserDashboard) {
                getPatientBrandPrescriber({ patientId });
                navigate("/prescriber");
            }
            else {
                setStep?.(true);
            }
            resetCreatePrescriber();
        }
    }, [requestPatientBrandPrescriberPostStatus]);
    useEffect(() => {
        if (requestUpdatePatientBrandPrescriberInfoStatus === "success") {
            if (isUserDashboard) {
                getPatientBrandPrescriber({ patientId });
                navigate("/prescriber");
            }
            else {
                setStep?.(true);
            }
            resetUpdatePrescriber();
        }
    }, [requestUpdatePatientBrandPrescriberInfoStatus]);
    const Submit = () => {
        if (!!getPatientBrandPrescriberData) {
            const data = {
                id: getPatientBrandPrescriberData.patientBrandPrescriberId,
                name: prescriberFirstName,
                address: AddressLineOne,
                city: city,
                postal: "",
                province: province,
                country: "CA",
                phone: phoneNumber,
                note: ""
            };
            updatePatientBrandPrescriber(data);
        }
        else {
            const data = {
                patientId: patientId,
                drugBrand: userCurrentDrugBrandCode ?? InviteLoginStoredData?.data?.value?.selectedPatientInfo?.currentDrugBrandCode,
                name: prescriberFirstName,
                address: AddressLineOne,
                city: city,
                postal: "",
                province: province,
                country: "CA",
                phone: phoneNumber,
                note: ""
            };
            createPatientBrandPrescriber(data);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(si.contentWrapper) },
            React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
                React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                    React.createElement("div", { className: classNames(si.formstepbar) },
                        isNewSignUp ? React.createElement(React.Fragment, null,
                            React.createElement("div", { className: s.header },
                                React.createElement("div", { className: s.pages },
                                    React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `10/15`)))) : null,
                        React.createElement("div", { className: (si.stepToptext) },
                            getPatientBrandPrescriberData != null && getPatientBrandPrescriberData != undefined ? React.createElement(React.Fragment, null,
                                React.createElement("h4", null, t("updatePrescriber"))) : React.createElement(React.Fragment, null,
                                React.createElement("h4", null, t("addPrescriber"))),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null, t("pleaseProvideInfo")))),
                        React.createElement("div", { className: classNames(si.contentInput) },
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, t("prescriberName")),
                                React.createElement(InputText, { name: "prescriberFirstName", 
                                    //whenever API gives correct response then this code useful for validations(this all page commented code)
                                    // errorMessage={t('errorIncorrectLastName')}
                                    pattern: '^.{0,35}$', value: prescriberFirstName, setValue: setPrescriberFirstName, error: !!prescriberFirstName && errorPrescriberFirstName, setError: setErrorPrescriberFirstName, tabIndex: 1 })),
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, t("AddressLineOne")),
                                React.createElement(InputText, { name: "AddressLineOne", pattern: "^.{0,35}$", value: AddressLineOne, setValue: setAddressLineOne, error: !!AddressLineOne && errorAddressLineOne, setError: setErrorAddressLineOne, tabIndex: 3 })),
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, t("prescriberCity")),
                                React.createElement(InputText, { name: "city", pattern: "^.{0,35}$", value: city, setValue: setCity, error: city && errorCity, setError: setErrorCity, tabIndex: 4 })),
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, t("prescriberProvince")),
                                React.createElement(InputText, { name: "province", pattern: "^.{0,50}$", value: province, setValue: setProvince, error: province && errorProvince, setError: setErrorProvince, tabIndex: 5 })),
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, t("prescriberPhoneNumber")),
                                React.createElement(MuiTelInput, { value: phone, onChange: handlePhoneInputChange, error: !!errorPhoneNumber, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 6 })),
                            (isNewSignUp && !skipStep) ? React.createElement("div", { className: classNames(si.textCenter, si.mt20) },
                                React.createElement("h4", { onClick: () => setStep?.(true), className: si.linktwo },
                                    " ",
                                    t("Skipthisstep"),
                                    " ")) : null),
                        React.createElement("div", { className: si.buttonTwopage },
                            React.createElement(Button, { text: t("back"), onClick: prevStep }),
                            React.createElement(Button, { style: s.mb0_button, disabled: !allFieldsValid() || !hasChange(), primary: true, text: t("SaveProceed"), onClick: Submit })))))),
        requestGetPatientBrandPrescriberStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestPatientBrandPrescriberPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        InviteLoginStoredData: state.auth.InviteLoginStoredData,
        getPatientBrandPrescriberData: state.PatientBrandPrescriber.getPatientBrandPrescriberData,
        userCurrentDrugBrandCode: state.account.user.drugBrandCode,
        requestGetPatientBrandPrescriberStatus: state.PatientBrandPrescriber.requestGetPatientBrandPrescriberStatus,
        requestPatientBrandPrescriberPostStatus: state.PatientBrandPrescriber.requestPatientBrandPrescriberPostStatus,
        requestUpdatePatientBrandPrescriberInfoStatus: state.PatientBrandPrescriber.requestUpdatePatientBrandPrescriberInfoStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        createPatientBrandPrescriber: (prescriberInfo) => dispatch(createPatientBrandPrescriberInfoAsyncAction(prescriberInfo)),
        getPatientBrandPrescriber: (data) => dispatch(getPatientBrandPrescriberAsyncAction(data)),
        resetCreatePrescriber: () => dispatch(resetCreatePatientBrandPrescriberInfoAsyncAction()),
        resetUpdatePrescriber: () => dispatch(resetUpdatePatientBrandPrescriberInfoAsyncAction()),
        updatePatientBrandPrescriber: (prescriberInfo) => dispatch(updatePatientBrandPrescriberInfoAsyncAction(prescriberInfo)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPrescriberManual);
