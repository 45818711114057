import React, { useEffect, useState } from "react";
import { PopUp } from "../../components/Shared/Info";
import { Button, InputText } from "../../components/Shared/Controls";
import SVG from "react-inlinesvg";
import Close from "../../resources/icons/close.svg";
import { connect } from "react-redux";
import { getDrugBrandCodesDropdownAsyncAction } from "../../store/prescriber/Sagas";
import CheckboxDropdown from "../../components/Shared/Controls/CheckboxDropdown";
import golbalcss from "../styles.scss";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
const PrescriberFilter = ({ getDrugBrandCodesDropdown, drugBrandCodesDropdown, onClose, setFields, holdDataFilter }) => {
    const { t } = useTranslation();
    const [Name, setName] = useState("");
    const [Note, setNote] = useState("");
    const [BrandIdentifier, setBrandIdentifier] = useState("");
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode,
                isChecked: false
            };
        })
        : [];
    const [items, setItems] = useState(DrugBrandCode);
    const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
    const handleSetItems = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.displayName);
        setSelectedDisplayNames(selectedNames);
        setItems(newItems);
    };
    const FilterFunction = () => {
        const prescriberFilterfieldsObj = {
            name: Name,
            note: Note,
            drugBrandCode: selectedDisplayNames,
            brandIdentifier: BrandIdentifier,
        };
        setFields?.(prescriberFilterfieldsObj);
        onClose();
    };
    useEffect(() => {
        setName(holdDataFilter?.name || "");
        setBrandIdentifier(holdDataFilter?.brandIdentifier || "");
        setNote(holdDataFilter?.note || "");
        const selectedDrugBrandCodes = holdDataFilter?.drugBrandCode || [];
        const updatedItems = items.map((item) => ({
            ...item,
            isChecked: selectedDrugBrandCodes.includes(item.displayName),
        }));
        handleSetItems(updatedItems);
        getDrugBrandCodesDropdown();
    }, [onClose]);
    const defaultSelectedItems = holdDataFilter?.drugBrandCode?.map((item) => ({
        key: item,
        displayName: item,
        isChecked: true
    }));
    return (React.createElement(PopUp, { isOpen: true },
        React.createElement("div", { className: classNames(golbalcss.popUmodalContent, golbalcss.popUpmodalSm) },
            React.createElement("div", { className: classNames(golbalcss.popUpmodalheader) },
                React.createElement("h4", null, t("filter")),
                React.createElement(SVG, { src: Close, onClick: onClose })),
            React.createElement("div", { className: golbalcss.modalBody },
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("name")),
                    React.createElement(InputText, { name: "Name", value: Name, setValue: setName, maxLength: 100, required: false })),
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("drugbrandcodes")),
                    React.createElement(CheckboxDropdown, { items: items, setItems: handleSetItems, defaultSelectedItems: defaultSelectedItems })),
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("brandidentifier")),
                    React.createElement(InputText, { name: "BrandIdentifier", value: BrandIdentifier, setValue: setBrandIdentifier, required: false })),
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("note")),
                    React.createElement(InputText, { name: "Note", value: Note, setValue: setNote, required: false }))),
            React.createElement("div", { className: golbalcss.modalFooter },
                React.createElement(Button, { text: t("cancel"), onClick: onClose }),
                React.createElement(Button, { primary: true, text: t("filter"), 
                    // disabled={Name=="" && Note=="" && BrandIdentifier=="" && selectedDisplayNames.length === 0} 
                    onClick: FilterFunction })))));
};
const mapStateToProps = (state) => {
    return {
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriberFilter);
