import ApiManager from "../api/ApiManager";
const version = "1.2";
const REQUEST_CODE = `/v${version}/forgot-password/request`;
const VALIDATE_CODE = `/v${version}/forgot-password/validate-verification-code`;
const RESET_PASSWORD = `/v${version}/forgot-password/reset-password`;
class ForgotPasswordRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new ForgotPasswordRepository();
        }
        return this.instance;
    }
    static requestCode = (phoneNumber) => {
        return ApiManager.getInstance().get(REQUEST_CODE, {
            PhoneNumber: phoneNumber,
        });
    };
    static validateCode = (phoneNumber, code) => {
        return ApiManager.getInstance().post(VALIDATE_CODE, {
            phoneNumber,
            code
        });
    };
    static resetPassword = (phoneNumber, code, newPassword, newPasswordConfirmation) => {
        return ApiManager.getInstance().post(RESET_PASSWORD, {
            phoneNumber,
            code,
            newPassword,
            newPasswordConfirmation
        });
    };
}
export default ForgotPasswordRepository;
