import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { allProvincesSelector, setAddionInfoAction, setProvinceAction, resetRequestAddMemberAction, enrollByOwnerAsyncAction, resetRequestEnrollByOwnerAction, } from "../../../../store";
import { getProvincesAsyncAction } from "../../../../store/province/Sagas";
import { Button, InputText } from "../../../../components/Shared/Controls";
import Picker from "../../../../components/Shared/Controls/Picker";
import useInput from "../../../../hooks/useInput";
import PageBody from "../../../../components/Shared/PageBody";
import { addMemberAsyncAction } from "../../../../store/auth/actions";
import { PopUp } from "../../../../components/Shared/Info";
import OK from "../../../../resources/icons/circle-ok.svg";
import { usePrevious } from "../../../../hooks";
import ProvinceDropdown from "../../../../components/Shared/Controls/Inputs/ProvinceDropdown";
import { allConditionsSelector, getMedicalConditionsAsyncAction } from "../../../../store/medicalConditions";
import DinInfoContent from "../../../../components/Shared/PopUps/DinInfoContent";
import config from "../../../../../config.json";
import CheckboxList from "../../../../components/Shared/Controls/CheckboxList";
import s from "./style.scss";
import style from "../../style.scss";
import { toPascalCase } from "../../../../store/utils";
const showDIN = config.signUp_steps.find(el => el.name === "Din")?.show;
const AlmostDonePage = ({ userProvince, userGender, userDoctorsName, userMedicalCondition, brandName, getProvinces, getMedicalConditionals, setAdditionalInfo, setProvince, onSubmit, addMember, addMemberStatus, addMemberError, resetStatus, backAction, caregiver, enrollOwner, requestEnrollByOwnerStatus, requestEnrollByOwnerError, medicalConditionsRequestStatus, medicalConditionsRequestError, patientAzureIdImpersonateMode, }) => {
    const { t } = useTranslation();
    const prevAddMemberStatus = usePrevious(addMemberStatus);
    const provinces = useSelector(allProvincesSelector);
    const conditions = useSelector(allConditionsSelector);
    var medicalConditionData = [];
    if (!!conditions) {
        medicalConditionData = conditions?.map((condition) => {
            return {
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: false
            };
        });
    }
    const [gender, setGender] = useInput(userGender);
    const [province, setProvinceLocal] = useInput(userProvince);
    const [dinNumber, setDinNumber, errorDinNumber, setErrorDinNumber] = useInput();
    const [medicalConditions, setMedicalConditions] = useInput(medicalConditionData);
    const [sent, setSent] = useState(false);
    const [additionalSteps, setAdditionalSteps] = useState(false);
    useEffect(() => {
        getProvinces();
        getMedicalConditionals(brandName);
    }, [brandName]);
    useEffect(() => {
        conditions && setMedicalConditions(conditions?.map((condition) => {
            return {
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: userMedicalCondition && userMedicalCondition.find((el) => el.medicalConditionId === condition.medicalConditionId)
                    && true
                    || false
            };
        }));
    }, [conditions]);
    const GENDERS = [
        { value: "Male", title: t("male") },
        { value: "Female", title: t("female") },
        { value: "Other", title: t("other") },
    ];
    var medConditions = [];
    if (!!medicalConditions) {
        medConditions = medicalConditions?.filter((el) => el.isChecked).map((condition) => {
            return {
                medicalConditionId: condition.key,
                medicalConditionDescription: condition.displayName
            };
        });
    }
    const allFieldsValid = () => !errorDinNumber && medConditions.length > 0 && gender && province && (showDIN ? dinNumber.length === 8 : true) && medConditions;
    const onChangeGender = (value) => {
        setGender(value);
    };
    const submit = () => {
        const LoginAPIResponse = localStorage.getItem('LoginAPIResponse');
        const data = JSON.parse(LoginAPIResponse);
        setAdditionalInfo({
            gender: gender,
        });
        setProvince(province);
        caregiver
            ? enrollOwner({
                gender,
                province,
                din: dinNumber,
                medicalConditions: medConditions
            })
            : addMember({
                gender,
                din: dinNumber,
                medicalConditions: medConditions,
                azureUserId: !!patientAzureIdImpersonateMode ? patientAzureIdImpersonateMode : data?.data?.value?.azureUserInfo?.azureUserId
            });
    };
    useEffect(() => {
        prevAddMemberStatus !== undefined && addMemberStatus === "success" && setSent(true);
    }, [addMemberStatus]);
    return (React.createElement(PageBody, { backAction: backAction, title: t("almostDone") },
        React.createElement("div", { className: classNames(style.scrollWrapper, style.bottomBlock) },
            React.createElement("div", { className: classNames(s.textWrapper, s.topText) }, t("weNeedMoreInfoAboutMember")),
            React.createElement("div", { className: s.contentWrapper },
                React.createElement(ProvinceDropdown, { data: provinces, value: province, setValue: setProvinceLocal, label: t("selectYourProvince") }),
                React.createElement("div", { className: s.picker },
                    React.createElement("div", { className: s.inputTitle }, t("gender")),
                    React.createElement(Picker, { onChange: onChangeGender, value: gender, data: GENDERS, columns: 3 })),
                React.createElement("div", { className: s.prescriber },
                    React.createElement(CheckboxList, { items: medicalConditions, setItems: setMedicalConditions, placeholder: t("healthConditions"), description: t("pleaseSelectTheConditions").replace("BRAND_NAME", toPascalCase(brandName)) })),
                showDIN &&
                    React.createElement("div", null,
                        React.createElement(InputText, { name: "dinNumber", label: t("din").replace("BRAND_NAME", ""), pattern: '^.{8}$', descriptionJSX: React.createElement(DinInfoContent, null), errorMessage: t("pleaseEnterValidDIN"), value: dinNumber, setValue: setDinNumber, error: dinNumber && (errorDinNumber || dinNumber.length !== 8), setError: setErrorDinNumber, isNumber: true, length: 8, maxLength: 8 }))),
            React.createElement(Button, { style: classNames(s.mb0_button, style.button), disabled: !allFieldsValid(), primary: true, text: caregiver ? t("finish") : t("saveAndProceed"), onClick: submit }),
            sent && (React.createElement(PopUp, { isOpen: sent },
                React.createElement("div", { className: s.popup },
                    React.createElement("div", null,
                        React.createElement(SVG, { src: OK, className: s.popup__header_icon })),
                    React.createElement("div", { className: s.popup__content },
                        React.createElement("div", null, t("newPatientAdded")),
                        React.createElement("div", null, t("addAdditionalInfoLater"))),
                    React.createElement("div", { className: s.popup__controls },
                        React.createElement(Button, { text: t("backToHome"), onClick: () => (window.location.href = "/") }),
                        React.createElement(Button, { primary: true, text: t("continue"), onClick: onSubmit }))))))));
};
const mapStateToProps = (state) => {
    return {
        userProvince: state.auth.provinceId,
        userGender: state.auth.additionalInfo.gender,
        userDoctorsName: state.auth.additionalInfo.doctorsName,
        userMedicalCondition: state.auth.additionalInfo.medicalConditions,
        brandName: state.account.member.drugBrandCode,
        addMemberStatus: state.auth.requestAddMemberStatus,
        addMemberError: state.auth.requestAddMemberError,
        requestEnrollByOwnerStatus: state.account.requestEnrollByOwnerStatus,
        requestEnrollByOwnerError: state.account.requestEnrollByOwnerError,
        medicalConditionsRequestStatus: state.medicalConditions.requestStatus,
        medicalConditionsRequestError: state.medicalConditions.requestError,
        patientAzureIdImpersonateMode: state.account.patientAzureIdImpersonateMode,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getProvinces: () => {
            dispatch(getProvincesAsyncAction());
        },
        getMedicalConditionals: (drugBrandCode) => {
            dispatch(getMedicalConditionsAsyncAction(drugBrandCode));
        },
        setProvince: (province) => dispatch(setProvinceAction(province)),
        setAdditionalInfo: (info) => dispatch(setAddionInfoAction(info)),
        addMember: ({ gender, din, medicalConditions, azureUserId }) => {
            dispatch(addMemberAsyncAction({
                gender,
                din,
                medicalConditions,
                azureUserId
            }));
        },
        resetStatus: () => {
            dispatch(resetRequestAddMemberAction());
            dispatch(resetRequestEnrollByOwnerAction());
        },
        enrollOwner: ({ gender, province, din, medicalConditions }) => dispatch(enrollByOwnerAsyncAction({ gender, province, din, medicalConditions })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AlmostDonePage);
