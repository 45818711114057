import ApiManager from "../api/ApiManager";
const version = "1.2";
const Pharmacy_INFO_PUT = `/v${version}/Patient/Brand/Pharmacy`;
const GET_PATIENT_PHARMACY = `/v${version}/Patient/Brand/Pharmacy`;
const Pharmacy_INFO_POST = `/v${version}/Patient/Brand/Pharmacy`;
const PHARMACY = `/v${version}/Pharmacy`;
const DELETE_PHARMACY = `/v${version}/Patient/Brand/Pharmacy`;
const PHARMACIES = `/v${version}/Pharmacy`;
class PharmacyRepository {
    constructor() { }
    //put request
    static putPharmacy = (data) => {
        return ApiManager.getInstance().put(Pharmacy_INFO_PUT, data);
    };
    //get request
    static getPatientPharmacyInfo = (payload) => {
        let filters = "";
        if (!!payload.patientId) {
            filters += `&patientId=${payload.patientId}`;
        }
        if (!!payload.id) {
            filters += `&id=${payload.id}`;
        }
        return ApiManager.getInstance().get(`${GET_PATIENT_PHARMACY}?${filters}`);
    };
    //post request
    static postPharmacyInfo = (data) => {
        return ApiManager.getInstance().post(Pharmacy_INFO_POST, data);
    };
    //get only pharmacy
    static getPharmaciesInfo = (action) => {
        let searchItems = '';
        let orderCopy = false;
        if (action?.order == 'asc') {
            orderCopy = false;
        }
        else {
            orderCopy = true;
        }
        if (action?.address != '' && action?.address != undefined) {
            searchItems += `&Address=${action?.address}`;
        }
        if (action?.name != '' && action?.name != undefined) {
            searchItems += `&Name=${action?.name}`;
        }
        if (action?.orderBy != '' && action?.orderBy != undefined) {
            searchItems += `&OrderByAttribute=${action?.orderBy}`;
        }
        if (orderCopy != null && orderCopy != undefined) {
            searchItems += `&OrderByDescending=${orderCopy}`;
        }
        if (!!action?.page) {
            searchItems += `&PageNumber=${action?.page + 1}`;
        }
        if (!!action?.rowsPerPage) {
            searchItems += `&PageSize=${action?.rowsPerPage}`;
        }
        if (action?.phone != '' && action?.phone != undefined) {
            searchItems += `&Phone=${action?.phone}`;
        }
        if (action?.drugBrandCode && Array.isArray(action?.drugBrandCode) && action?.drugBrandCode.length > 0) {
            action.drugBrandCode.map((code, index) => {
                searchItems += `&DrugBrandCode[${index}]=${code}`;
            });
        }
        if (action?.brandIdentifier != '' && action?.brandIdentifier != undefined) {
            searchItems += `&BrandIdentifier=${action?.brandIdentifier}`;
        }
        if (!!action?.latitude && !!action?.longitude) {
            searchItems += `&Geography.Distance=${action?.distance ?? 2500}&Geography.CurrentLongitude=${action?.longitude}&Geography.CurrentLatitude=${action?.latitude}`;
        }
        return ApiManager.getInstance().get(`${PHARMACY}?${searchItems}`);
    };
    static deletePharmacy = (Id) => {
        return ApiManager.getInstance().delete(`${DELETE_PHARMACY}?pharmacyId=${Id}`);
    };
    static deletePharmacies = (Id) => {
        return ApiManager.getInstance().delete(`${PHARMACIES}?pharmacyId=${Id}`);
    };
    //post request
    static postPharmacies = (data) => {
        return ApiManager.getInstance().post(PHARMACIES, data);
    };
    //put request
    static updatePharmaciesInfo = (Pharmacies) => {
        return ApiManager.getInstance().put(PHARMACIES, {
            ...Pharmacies
        });
    };
    //get request
    static getSingleDataOfPharmacyInfo = (pharmacyId) => {
        return ApiManager.getInstance().get(PHARMACIES, {
            Id: pharmacyId
        });
    };
}
export default PharmacyRepository;
