import React from "react";
import { Link } from "react-router-dom";
import { Box } from "../../../../components";
import Typography from "../../../../components/Shared/Typography";
import colors from "../../../../resources/colors";
import Arrow from "../../../../resources/icons/arrow-right";
import s from './style.scss';
const HomeProfileCard = (props) => {
    return (React.createElement(Box, { f1: true, className: s.profileCardBox },
        React.createElement("div", { className: s.profileCardWrapper },
            React.createElement(Box, { f1: true, column: true, pv: 32, ph: 32 },
                React.createElement(Typography, { type: 'header_2', color: 'neutralDark', bold: true }, props.title),
                React.createElement(Typography, { type: 'paragraph', color: 'neutralGray' }, props.description),
                React.createElement(Link, { to: props.link, className: s.profileCardButton },
                    React.createElement("div", { className: s.iconWrapper },
                        React.createElement("div", { style: { backgroundColor: colors.primary } },
                            React.createElement(Arrow, { color: colors.white, style: s.redirectButton }))))),
            React.createElement("img", { src: props.image, width: 190, className: s.profileCardImage }))));
};
export default HomeProfileCard;
