import { call, put, takeLatest } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import PatientBrandPrescriberSlice, { SLICE_NAME } from "./slice";
import PatientBrandPrescriberRepository from "../repository/PatientBrandPrescriberRepository";
import { handleApiError } from "../utils";
export const createPatientBrandPrescriberInfoAsyncAction = createAction(`${SLICE_NAME}/createPatientBrandPrescriberInfoAsyncAction`);
export function* createPatientBrandPrescriberInfoSaga(action) {
    const { startCreatePatientBrandPrescriberInfoAsyncAction, succesCreatePatientBrandPrescriberInfoAsyncAction, showcreatePatientBrandPrescriberInfoErrorAction } = PatientBrandPrescriberSlice.actions;
    try {
        yield put(startCreatePatientBrandPrescriberInfoAsyncAction());
        yield call(PatientBrandPrescriberRepository.postPatientBrandPrescriber, action.payload);
        yield put(succesCreatePatientBrandPrescriberInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreatePatientBrandPrescriberInfoErrorAction);
    }
}
export const getPatientBrandPrescriberAsyncAction = createAction(`${SLICE_NAME}/getPatientBrandPrescriberAsyncAction`);
export function* getPatientBrandPrescriberSaga(action) {
    const { startRequestGetPatientBrandPrescriberAction, successRequestGetPatientBrandPrescriberAction, showRequesGetPatientBrandPrescriberErrorAction, } = PatientBrandPrescriberSlice.actions;
    try {
        yield put(startRequestGetPatientBrandPrescriberAction());
        let result = yield call(PatientBrandPrescriberRepository.getPatientBrandPrescriberyInfo, action.payload);
        yield put(successRequestGetPatientBrandPrescriberAction(result?.data?.value?.resultItems[0]));
    }
    catch (error) {
        yield handleApiError(error, showRequesGetPatientBrandPrescriberErrorAction);
    }
}
export const updatePatientBrandPrescriberInfoAsyncAction = createAction(`${SLICE_NAME}/updatePatientBrandPrescriberInfoAsyncAction`);
export function* updatePatientBrandPrescriberInfoSaga(action) {
    const { startRequestUpdatePatientBrandPrescriberInfoAction, successRequestUpdatePatientBrandPrescriberInfoAction, showRequestUpdatePatientBrandPrescriberInfoErrorAction, } = PatientBrandPrescriberSlice.actions;
    try {
        yield put(startRequestUpdatePatientBrandPrescriberInfoAction());
        const result = yield call(PatientBrandPrescriberRepository.updatePatientBrandPrescriberInfo, action.payload);
        yield put(successRequestUpdatePatientBrandPrescriberInfoAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdatePatientBrandPrescriberInfoErrorAction);
    }
}
export const deletePatientBrandPrescriberAsyncAction = createAction(`${SLICE_NAME}/deletedPatientBrandPrescriberAsyncAction`);
export function* deletePatientBrandPrescriberSaga(action) {
    const { startRequestDeletePatientBrandPrescriberInfoAction, successRequestDeletePatientBrandPrescriberInfoAction, showRequestDeletePatientBrandPrescriberInfoErrorAction, } = PatientBrandPrescriberSlice.actions;
    try {
        yield put(startRequestDeletePatientBrandPrescriberInfoAction());
        yield call(PatientBrandPrescriberRepository.deletePatientBrandPrescriber, action.payload.prescriberId);
        yield put(successRequestDeletePatientBrandPrescriberInfoAction(action.payload.prescriberId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeletePatientBrandPrescriberInfoErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(createPatientBrandPrescriberInfoAsyncAction, createPatientBrandPrescriberInfoSaga);
    yield takeLatest(getPatientBrandPrescriberAsyncAction, getPatientBrandPrescriberSaga);
    yield takeLatest(updatePatientBrandPrescriberInfoAsyncAction, updatePatientBrandPrescriberInfoSaga);
    yield takeLatest(deletePatientBrandPrescriberAsyncAction, deletePatientBrandPrescriberSaga);
}
