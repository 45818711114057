import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
export const SLICE_NAME = "CONDITIONS";
export const conditionsAdapter = createEntityAdapter({
    selectId: (condition) => condition.medicalConditionId,
    sortComparer: false
});
export const conditionsSelectors = conditionsAdapter.getSelectors();
const initialState = {
    MedicalConditions: undefined,
    requestMedicalConditionPostStatus: "idle",
    requestMedicalConditionPostError: null,
    requestUpdateMedicalConditionInfoStatus: "idle",
    requestUpdateMedicalConditionInfoError: null,
    requestDeleteMedicalConditionStatus: "idle",
    requestDeleteMedicalConditionError: null,
    requestStatus: "idle",
    requestError: null,
    requestmedicalConditionInfoStatus: "idle",
    requestmedicalConditionInfoError: null,
    getmedicalConditionInfo: undefined,
    getAllmedicalConditions: undefined,
    requestgetAllmedicalConditionStatus: "idle",
    requestgetAllmedicalConditionError: null,
};
const ConditionsSlice = createSlice({
    name: SLICE_NAME,
    initialState: conditionsAdapter.getInitialState(initialState),
    reducers: {
        changeStateMedicalConditionPostStatus: (state, action) => {
            state.requestMedicalConditionPostStatus = action.payload;
        },
        successRequestAction: (state, action) => {
            conditionsAdapter.setAll(state, action.payload);
            state.requestStatus = "success";
            state.MedicalConditions = action.payload;
            state.requestError = null;
        },
        startRequestAction: (state) => {
            state.requestStatus = "pending";
            state.requestError = null;
        },
        showErrorAction: (state, action) => {
            state.requestStatus = "failure";
            state.requestError = action.payload;
        },
        hideErrorAction: (state) => {
            state.requestStatus = "idle";
            state.requestError = null;
        },
        resetConditionsSliceAction: (state) => {
            state = conditionsAdapter.getInitialState(initialState);
        },
        //post api call resp
        startCreateMedicalConditionsInfoAsyncAction: (state) => {
            state.requestMedicalConditionPostStatus = "pending";
            state.requestMedicalConditionPostError = null;
        },
        succesCreateMedicalConditionsInfoAsyncAction: (state, action) => {
            state.requestMedicalConditionPostStatus = "success";
            state.requestMedicalConditionPostError = null;
        },
        showcreateMedicalConditionsInfoErrorAction: (state, action) => {
            state.requestMedicalConditionPostStatus = "failure";
            state.requestMedicalConditionPostError = action.payload;
        },
        //update api
        startRequestUpdateMedicalConditionInfoAction: (state) => {
            state.requestUpdateMedicalConditionInfoStatus = "pending";
            state.requestUpdateMedicalConditionInfoError = null;
        },
        successRequestUpdateMedicalConditionInfoAction: (state, action) => {
            state.requestUpdateMedicalConditionInfoStatus = "success";
            state.requestUpdateMedicalConditionInfoError = null;
        },
        showRequestUpdateMedicalConditionInfoErrorAction: (state, action) => {
            state.requestUpdateMedicalConditionInfoStatus = "failure";
            state.requestUpdateMedicalConditionInfoError = action.payload;
        },
        //delete
        startRequestDeleteMedicalConditionAction: (state) => {
            state.requestDeleteMedicalConditionStatus = "pending";
            state.requestDeleteMedicalConditionError = null;
        },
        deleteMedicalConditionAction: (state, action) => {
            state.requestDeleteMedicalConditionStatus = "success";
            state.requestDeleteMedicalConditionError = null;
        },
        showRequestDeleteMedicalConditionErrorAction: (state, action) => {
            state.requestDeleteMedicalConditionStatus = "failure";
            state.requestDeleteMedicalConditionError = action.payload;
        },
        //get one data api
        startRequestmedicalConditionOneDataInfoAction: (state) => {
            state.requestmedicalConditionInfoStatus = "pending";
            state.requestmedicalConditionInfoError = null;
        },
        successRequesmedicalConditionOneDataInfoAction: (state, action) => {
            state.requestmedicalConditionInfoStatus = "success";
            state.requestmedicalConditionInfoError = null;
            state.getmedicalConditionInfo = action.payload;
        },
        showRequesmedicalConditionOneDataInfoErrorAction: (state, action) => {
            state.requestmedicalConditionInfoStatus = "failure";
            state.requestmedicalConditionInfoError = action.payload;
        },
        //getAll
        startRequestgetAllmedicalConditionAction: (state) => {
            state.requestgetAllmedicalConditionStatus = "pending";
            state.requestgetAllmedicalConditionError = null;
        },
        successRequestgetAllmedicalConditionAction: (state, action) => {
            state.requestgetAllmedicalConditionStatus = "success";
            state.requestgetAllmedicalConditionError = null;
            state.getAllmedicalConditions = action.payload;
        },
        showRequestgetAllmedicalConditionErrorAction: (state, action) => {
            state.requestgetAllmedicalConditionStatus = "failure";
            state.requestgetAllmedicalConditionError = action.payload;
        },
    }
});
export default ConditionsSlice;
