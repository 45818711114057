import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "InfusionClinic";
const initialState = {
    requestGetInfusionClinicStatus: "idle",
    requestGetInfusionClinicError: null,
    getInfusionClinicData: undefined,
    requestGetOnlyInfusionClinicStatus: "idle",
    requestGetOnlyInfusionClinicError: null,
    getOnlyInfusionClinicData: null,
    requestPostInfusionClinicStatus: "idle",
    requestPostInfusionClinicError: null,
    requestPutInfusionClinicStatus: "idle",
    requestPutInfusionClinicError: null,
    requestDeleteInfusionClinicBrandStatus: "idle",
    requestDeleteInfusionClinicBrandError: null,
    requestSingleDataOfInfusionClinicInfoStatus: "idle",
    requestSingleDataOfInfusionClinicInfoError: null,
    getSingleDataOfInfusionClinicInfo: null,
    requestUpdateInfusionClinicInfoStatus: "idle",
    requestUpdateInfusionClinicInfoError: null,
    InfusionClinic: undefined,
    requestInfusionClinicPostStatus: "idle",
    requestInfusionClinicPostError: null,
    requestDeleteInfusionClinicStatus: "idle",
    requestDeleteInfusionClinicError: null,
    storeAllDataOfInfusionClinic: undefined
};
const InfusionClinicSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        //get patient Infusion Clinic
        startRequestGetInfusionClinicAction: (state) => {
            state.requestGetInfusionClinicStatus = "pending";
            state.requestGetInfusionClinicError = null;
        },
        successRequestGetInfusionClinicAction: (state, action) => {
            state.requestGetInfusionClinicStatus = "success";
            state.requestGetInfusionClinicError = null;
            state.getInfusionClinicData = action.payload;
        },
        showRequesGetInfusionClinicErrorAction: (state, action) => {
            state.requestGetInfusionClinicStatus = "failure";
            state.requestGetInfusionClinicError = action.payload;
        },
        storeAllDataOfInfusionClinicAction: (state, action) => {
            state.storeAllDataOfInfusionClinic = action.payload?.data?.value;
        },
        //get api only Infusion Clinic
        startRequestGetOnlyInfusionClinicAction: (state) => {
            state.requestGetOnlyInfusionClinicStatus = "pending";
            state.requestGetOnlyInfusionClinicError = null;
        },
        successRequestGetOnlyInfusionClinicAction: (state, action) => {
            state.requestGetOnlyInfusionClinicStatus = "success";
            state.requestGetOnlyInfusionClinicError = null;
            state.getOnlyInfusionClinicData = action.payload;
        },
        showRequesGetOnlyInfusionClinicErrorAction: (state, action) => {
            state.requestGetOnlyInfusionClinicStatus = "failure";
            state.requestGetOnlyInfusionClinicError = action.payload;
        },
        //post
        startPostInfusionClinicInfoAsyncAction: (state) => {
            state.requestPostInfusionClinicStatus = "pending";
            state.requestPostInfusionClinicError = null;
        },
        succesPostInfusionClinicInfoAsyncAction: (state, action) => {
            state.requestPostInfusionClinicStatus = "success";
            state.requestPostInfusionClinicError = null;
        },
        showPostInfusionClinicInfoErrorAction: (state, action) => {
            state.requestPostInfusionClinicStatus = "failure";
            state.requestPostInfusionClinicError = action.payload;
        },
        resetPostInfusionClinicInfoAction: (state) => {
            state.requestPostInfusionClinicStatus = "idle";
        },
        //put api call resp
        startPutInfusionClinicInfoAsyncAction: (state) => {
            state.requestPutInfusionClinicStatus = "pending";
            state.requestPutInfusionClinicError = null;
        },
        succesPutInfusionClinicInfoAsyncAction: (state, action) => {
            state.requestPutInfusionClinicStatus = "success";
            state.requestPutInfusionClinicError = null;
        },
        showPutInfusionClinicInfoErrorAction: (state, action) => {
            state.requestPutInfusionClinicStatus = "failure";
            state.requestPutInfusionClinicError = action.payload;
        },
        resetPutInfusionClinicInfoAction: (state) => {
            state.requestPutInfusionClinicStatus = "idle";
        },
        //delete
        startRequestDeleteInfusionClinicBrandAction: (state) => {
            state.requestDeleteInfusionClinicBrandStatus = "pending";
            state.requestDeleteInfusionClinicBrandError = null;
        },
        deleteInfusionClinicBrandAction: (state, action) => {
            state.requestDeleteInfusionClinicBrandStatus = "success";
            state.requestDeleteInfusionClinicBrandError = null;
        },
        showRequestDeleteInfusionClinicBrandErrorAction: (state, action) => {
            state.requestDeleteInfusionClinicBrandStatus = "failure";
            state.requestDeleteInfusionClinicBrandError = action.payload;
        },
        //get one data of InfusionClinic
        startRequestSingleDataOfInfusionClinicInfoAction: (state) => {
            state.requestSingleDataOfInfusionClinicInfoStatus = "pending";
            state.requestSingleDataOfInfusionClinicInfoError = null;
        },
        successRequesSingleDataOfInfusionClinicInfoAction: (state, action) => {
            state.requestSingleDataOfInfusionClinicInfoStatus = "success";
            state.requestSingleDataOfInfusionClinicInfoError = null;
            state.getSingleDataOfInfusionClinicInfo = action.payload;
        },
        showRequesSingleDataOfInfusionClinicInfoErrorAction: (state, action) => {
            state.requestSingleDataOfInfusionClinicInfoStatus = "failure";
            state.requestSingleDataOfInfusionClinicInfoError = action.payload;
        },
        //update api
        startRequestUpdateInfusionClinicInfoAction: (state) => {
            state.requestUpdateInfusionClinicInfoStatus = "pending";
            state.requestUpdateInfusionClinicInfoError = null;
        },
        successRequestUpdateInfusionClinicInfoAction: (state, action) => {
            state.requestUpdateInfusionClinicInfoStatus = "success";
            state.requestUpdateInfusionClinicInfoError = null;
            state.InfusionClinic = action.payload;
        },
        showRequestUpdateInfusionClinicInfoErrorAction: (state, action) => {
            state.requestUpdateInfusionClinicInfoStatus = "failure";
            state.requestUpdateInfusionClinicInfoError = action.payload;
        },
        //post api call resp
        startCreateInfusionClinicInfoAsyncAction: (state) => {
            state.requestInfusionClinicPostStatus = "pending";
            state.requestInfusionClinicPostError = null;
        },
        successCreateInfusionClinicInfoAsyncAction: (state, action) => {
            state.requestInfusionClinicPostStatus = "success";
            state.requestInfusionClinicPostError = null;
        },
        showcreateInfusionClinicInfoErrorAction: (state, action) => {
            state.requestInfusionClinicPostStatus = "failure";
            state.requestInfusionClinicPostError = action.payload;
        },
        //delete
        startRequestDeleteInfusionClinicAction: (state) => {
            state.requestDeleteInfusionClinicStatus = "pending";
            state.requestDeleteInfusionClinicError = null;
        },
        deleteInfusionClinicAction: (state, action) => {
            state.requestDeleteInfusionClinicStatus = "success";
            state.requestDeleteInfusionClinicError = null;
        },
        showRequestDeleteInfusionClinicErrorAction: (state, action) => {
            state.requestDeleteInfusionClinicStatus = "failure";
            state.requestDeleteInfusionClinicError = action.payload;
        },
    },
});
export default InfusionClinicSlice;
