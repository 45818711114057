import ApiManager from "../api/ApiManager";
const version = "1.2";
const GET_ALL_PATIENT_INFO = `/v${version}/Patient`;
class PatientRepository {
    constructor() { }
    //get all patient request
    static getAllPatientInfo = (page, rowsPerPage, order, orderBy, LastName, FirstName, DateOfBirth, Province, Phone, Email, status) => {
        let orderCopy = false;
        let filterItems = '';
        if (order == 'asc') {
            orderCopy = false;
        }
        else {
            orderCopy = true;
        }
        if (LastName) {
            filterItems += `&LastName=${LastName}`;
        }
        if (FirstName) {
            filterItems += `&FirstName=${FirstName}`;
        }
        if (DateOfBirth) {
            filterItems += `&BirthDate=${DateOfBirth}`;
        }
        if (Province) {
            filterItems += `&Province=${Province}`;
        }
        if (Phone) {
            filterItems += `&Phone=${Phone}`;
        }
        if (Email) {
            filterItems += `&Email=${Email}`;
        }
        return ApiManager.getInstance().get(`${GET_ALL_PATIENT_INFO}?OrderByAttribute=${orderBy}&OrderByDescending=${orderCopy}&PageNumber=${page + 1}&PageSize=${rowsPerPage}${filterItems} ${status == "All" ? '' : `&PatientStatus=${status}`}`);
    };
}
export default PatientRepository;
