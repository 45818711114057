import React, { useState, useEffect, } from 'react';
import ApiManager from "../../store/api/ApiManager";
import { jwtDecode } from "jwt-decode";
import { PopUp } from "../Shared/Info";
import classNames from "classnames";
import golbalcss from "../../Pages/styles.scss";
import { Button } from "../Shared/Controls";
import { useTranslation } from "react-i18next";
import { refreshTokenAsyncAction } from "../../store/auth/actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
const SessionTimeout = (properties) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        const intervalId = setInterval(() => {
            const token = ApiManager.getInstance().getToken();
            if (!token)
                return;
            try {
                const decodedToken = jwtDecode(token);
                if (decodedToken.exp == null)
                    return;
                const currentTime = Date.now().valueOf() / 1000;
                const warningBuffer = 5;
                if (currentTime > decodedToken.exp - (warningBuffer * 60)) { // if token expires within 5min
                    setShowModal(true);
                }
                if (currentTime > decodedToken.exp) { // if 5min are up
                    setShowModal(false);
                    navigate('/logout');
                }
            }
            catch (error) {
                console.log(error);
            }
        }, 60000); // checks every minute
        // Cleanup function in case component unmounts
        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        if (properties.requestRefreshTokenStatus == "success") {
            setShowModal(false);
        }
    }, [properties.requestRefreshTokenStatus]);
    const handleRefresh = () => {
        properties.refreshToken();
    };
    const handleLogout = () => {
        navigate('/logout');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PopUp, { isOpen: showModal },
            React.createElement("div", { className: classNames(golbalcss.popUmodalContent, golbalcss.popUpmodalSm) },
                React.createElement("div", { className: classNames(golbalcss.popUpmodalheader) },
                    React.createElement("h4", null, t("sessionTimout"))),
                React.createElement("div", { className: classNames(golbalcss.popUmodalContent) },
                    React.createElement("h4", null, t("sessionTimoutContent"))),
                React.createElement("div", { className: golbalcss.modalFooter },
                    React.createElement(Button, { text: t("logout"), onClick: handleLogout }),
                    React.createElement(Button, { primary: true, text: t("continue"), onClick: handleRefresh }))))));
};
const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.auth.phoneNumber,
        requestRefreshTokenStatus: state.auth.requestRefreshTokenStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        refreshToken: () => dispatch(refreshTokenAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SessionTimeout);
