import React, { useEffect } from "react";

import { initializeApp } from "firebase/app";
import config from "../config.json";

import colors from "./resources/colors";
import s from "./App.scss";
import Router from "./components/Router";
import { getMessaging as getMessagingForeground, onMessage } from "firebase/messaging";
import {FilesType, getNotificationsAsyncAction, UpdateFilesPayload} from "./store";
import {getPatientFilesAsyncAction, updatePatientFilesAsyncAction} from "./store/patientFiles/Actions";
import {connect} from "react-redux";
// import { getMessaging as getMessagingBackground, onBackgroundMessage} from "firebase/messaging/sw";
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', 
  function () {
      navigator.serviceWorker.register('/firebase-messaging-sw.js').then(
        function (registration) {
          console.log('ServiceWorker registration successful with scope: ', registration.scope);
          registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array("BBYUcIE3TEJRbp3PMl9DITTZ12HfIvAeXNq1Q1YStruP9OuHGcwBlMNKkUUDoEo2O7jVygxjuyX5vkoPesj12cg")
          }).catch((err) => { 
            return console.log('[Web Push] Registration Error:', err) 
          });
          console.log('[Web Push] Registered');
        }
      )
    },
    function (err) {
      console.log('ServiceWorker registration failed: ', err);
    });
}

const App = () => {
  const styles = {
    "--border-separator-color": colors.patientDocuments.dividerLineColor,
    "--devider-text-color": colors.patientDocuments.dividerTextColor,
    "--card-border-color": colors.cardBacklight.borderColor,
    "--card-box-shadow-color": colors.cardBacklight.shadowColor,
    "--scrollbar-thumb": colors.controlsGeneralColor,
    "--sidebar-mobile-active-color": colors.icons.sidebar.active,
    "--link-color": colors.linkColor,
    "--general-controls-color": colors.controlsGeneralColor,
  };
  
  const firebaseApp = initializeApp(config.firebase);

  return (
    <div className={s.app} style={styles}>
      <Router />
    </div>
  );
};

export default App;
