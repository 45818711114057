import { brandsSelectors } from "../brands/Slice";
export const getBrandByIdSelector = (state) => {
    return (state.account.patientBrands.length === 0 ?
        state.account.user :
        state.account.patientBrands.find(el => el.drugBrandCode === state.account.user?.drugBrandCode));
};
export const userInfoSelector = (state) => {
    return state.account.user;
};
export const userNameSelector = (state) => {
    const id = state.account.user.patientId;
    const patient = state.account.patients.find((p) => p.id === id);
    return patient ? patient.firstName : "";
};
export const getCurrentUserSelector = (state) => state.account.patients.find((item) => item.id === state.account.user.patientId);
export const getUsersDataSelector = (state) => {
    return state.account.patients.map((item) => {
        return {
            id: item.id,
            value: item.firstName + " " + item.lastName,
        };
    });
};
export const getBrandsDataSelector = (state) => {
    return state.account.drugBrandsCodes.map((item) => {
        return {
            id: item,
            value: item,
        };
    });
};
export const getMemberInfoSelector = (state) => {
    return {
        patientId: state.account.member.patientId,
        drugBrandCode: state.account.member.drugBrandCode,
        province: state.auth.provinceId,
    };
};
export const memberBrandSelector = (state) => brandsSelectors.selectById(state.brands, state.account.member.drugBrandCode);
