import React from "react";
const Box = (props) => {
    let style = {};
    if (props.width) {
        style = { maxWidth: `${props.width}px`, ...style };
    }
    if (props.height) {
        style = { maxHeight: `${props.height}px`, ...style };
    }
    if (props.fullWidth) {
        style = { width: '100%', ...style };
    }
    if (props.wrap) {
        style = { flexWrap: 'wrap', ...style };
    }
    if (props.wrapReverse) {
        style = { flexWrap: 'wrap-reverse', ...style };
    }
    if (props.mt) {
        style = { marginTop: `${props.mt}px`, ...style };
    }
    if (props.mb) {
        style = { marginBottom: `${props.mb}px`, ...style };
    }
    if (props.mr) {
        style = { marginRight: `${props.mr}px`, ...style };
    }
    if (props.ml) {
        style = { marginLeft: `${props.ml}px`, ...style };
    }
    if (props.f1) {
        style = { display: 'flex', flex: 1, ...style };
    }
    if (props.f2) {
        style = { display: 'flex', flex: 2, ...style };
    }
    if (props.row) {
        style = { flexDirection: 'row', ...style };
    }
    if (props.column) {
        style = { flexDirection: 'column', ...style };
    }
    if (props.justifyBetween) {
        style = { justifyContent: 'space-between', ...style };
    }
    if (props.justifyCenter) {
        style = { justifyContent: 'center', ...style };
    }
    if (props.justifyEnd) {
        style = { justifyContent: 'flex-end', ...style };
    }
    if (props.alignCenter) {
        style = { alignItems: 'center', ...style };
    }
    if (props.pr) {
        style = { paddingRight: `${props.pr}px`, ...style };
    }
    if (props.pl) {
        style = { paddingLeft: `${props.pl}px`, ...style };
    }
    if (props.ph) {
        style = { paddingLeft: `${props.ph}px`, paddingRight: `${props.ph}px`, ...style };
    }
    if (props.pv) {
        style = { paddingTop: `${props.pv}px`, paddingBottom: `${props.pv}px`, ...style };
    }
    if (props.gap) {
        style = { gap: `${props.gap}px`, ...style };
    }
    return (React.createElement("div", { className: props.className, style: style }, props.children));
};
export default Box;
