import React, { useEffect, useState } from "react";
import { getAnalytics, logEvent } from "@firebase/analytics";
import InputText from "../../Shared/Controls/Inputs/InputText";
import Button from "../../Shared/Controls/Button";
import { useTranslation } from "react-i18next";
import { filesByTypeSelector, hideRequestHealthCardUploadErrorAction } from "../../../store";
import { useInput, usePrevious } from "../../../hooks";
import classNames from "classnames";
import AddImageCard from "../../Shared/AddImageCard";
import ImageCard from "../../Shared/ImageCard";
import { connect } from "react-redux";
import { getPatientFilesAsyncAction, updatePatientFilesAllTypesAsyncAction } from "../../../store/patientFiles/Actions";
import ImageModal from "../../Shared/ImageModal";
import si from "../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import Box from "../../Shared/Box";
import Typography from "../../Shared/Typography";
const HealthCard = ({ requestGetFilesStatus, requestUpdateFilesStatus, requestHealthCardUpdateStatus, requestGetPreviewPhotoStatus, healthCards, patientId, hasSidebar, updateFiles, getFiles, setHaveChanges, skipAction, children, isNewSignUp, prevStep, requestInsurancesUpdateStatus, hideRequestHealthCardUploadAction }) => {
    const { t } = useTranslation();
    useEffect(() => {
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: "HealthCard",
            firebase_screen_class: "HealthCard"
        });
    }, []);
    const refRequestUpdate = usePrevious(requestHealthCardUpdateStatus);
    const [preLoadedFrontImage, setPreLoadedFrontImage] = useState(null);
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [healthCardNumber, setHealthCardNumber, errorHealthCardNumber, setErrorHealthCardNumber] = useInput();
    const [patientFileId, setPatientFileId] = useState(undefined);
    const [filesToRemove, setFilesToRemove] = useState([]);
    const [skipStep, setSkipStep] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    // const saveDisabled = (
    //   patientFileId && healthCardNumber === (healthCards[0]?.textValue?.healthCardNumber || "") && !frontImage && !backImage
    // ) || (
    //   !patientFileId 
    //   && (
    //     (
    //       frontImage === (userPhotos.front || null)
    //       && backImage === (userPhotos.back || null) 
    //       && !healthCardNumber
    //       && !errorHealthCardNumber
    //     )
    //     || (
    //       !healthCardNumber 
    //       && !errorHealthCardNumber
    //       && !frontImage 
    //       && !backImage 
    //       && !(userPhotos.back || userPhotos.front)
    //     )
    //   )
    // );
    const allFieldsValid = () => !errorHealthCardNumber && healthCardNumber.length >= 10 && healthCardNumber.length <= 12
        // || (frontImage && backImage);
        || !!frontImage;
    const saveDisabled = !allFieldsValid() || ((healthCardNumber.length == 0 || healthCardNumber == healthCards[0]?.textValue?.healthCardNumber) && frontImage === preLoadedFrontImage);
    useEffect(() => {
        setHaveChanges(!saveDisabled);
    }, [saveDisabled]);
    useEffect(() => {
        if (requestGetFilesStatus === "success" && healthCards[0]) {
            let front = healthCards[0];
            // let back = healthCards.find((el: FileView) => el.description === "BACK");
            // back ? setBackImage(back) : setBackImage(null);
            if (healthCards[0]?.textValue?.healthCardNumber) {
                setHealthCardNumber(healthCards[0].textValue?.healthCardNumber);
                setPatientFileId(healthCards[0].id);
            }
            else if (front.uri) {
                setPreLoadedFrontImage(front);
                setFrontImage(front);
                setHealthCardNumber('');
                setPatientFileId(undefined);
            }
            setFilesToRemove([]);
        }
        if (healthCards.length === 0) {
            setFrontImage(null);
            setBackImage(null);
            setPatientFileId(undefined);
            setHealthCardNumber("");
        }
    }, [healthCards]);
    useEffect(() => {
        if (patientId) {
            getFiles("HealthCard");
        }
    }, [patientId]);
    const storeStepsToCompleteData = localStorage.getItem('storeStepsToCompleteData');
    useEffect(() => {
        const stepsToCompleteData = JSON.parse(storeStepsToCompleteData);
        if (stepsToCompleteData.length > 0 && isNewSignUp) {
            const DataSkipStep = stepsToCompleteData;
            for (let data of DataSkipStep) {
                if (data?.step == "AddHealthCard" && data?.mandatory == true) {
                    setSkipStep(true);
                }
                else {
                    setSkipStep(false);
                }
            }
        }
    }, [storeStepsToCompleteData]);
    useEffect(() => {
        if (!isNewSignUp) {
            refRequestUpdate !== undefined && requestHealthCardUpdateStatus === "success" && skipAction && skipAction(true);
        }
        if (requestHealthCardUpdateStatus === "success" && isNewSignUp) {
            skipAction?.(true);
            hideRequestHealthCardUploadAction();
        }
    }, [requestHealthCardUpdateStatus]);
    const removeFile = (side) => {
        switch (side) {
            case "FRONT": setFrontImage(null);
            case "BACK": setBackImage(null);
            default: return;
        }
    };
    const validateHealthCardNumber = (errorMessage) => {
        setErrorHealthCardNumber(errorMessage);
    };
    const removeExistFile = (id) => {
        setFilesToRemove((ids) => [...ids, { id: id, deleteType: "Full" }]);
    };
    const imageCard = (side, fileType, image, setImage) => {
        function removeImage(img) {
            removeExistFile(img.parentFileId || img.id);
            setImage(null);
        }
        return React.createElement("div", { className: s.cardCell },
            React.createElement("div", { className: classNames(s.subheading_2, s.mb16, si.fontWeightMedium) }, t(side.includes('FRONT')
                ? "front" : "backCard")),
            image ? React.createElement(ImageCard, { key: image.name, style: s.card, url: image.uri, extension: image.extension, name: image.name, removeFile: () => {
                    removeImage(image);
                }, onClick: () => {
                    setModalFile({ image, removeImage });
                    setModalIsOpen(true);
                } }) :
                React.createElement(AddImageCard, { style: s.card, shortTitle: t("addImage") + ` (${side.includes('FRONT') ? t("front") : t("backCard")})`, side: side.includes('FRONT') ? t("front") : t("backCard"), addFile: (name, uri, blob, userFiles) => {
                        userFiles ? setImage({
                            name: userFiles[0].name,
                            extension: userFiles[0].extension,
                            uri: userFiles[0].uri,
                            filesType: fileType,
                            description: side
                        }) :
                            setImage({
                                name: name,
                                extension: "." + name.split('.').pop(),
                                uri: uri,
                                filesType: fileType,
                                description: side
                            });
                    } }));
    };
    const onSave = () => {
        let files = [!healthCards.includes(frontImage) && frontImage, !healthCards.includes(backImage) && backImage].filter((el) => el);
        let filesToRemoveIds = filesToRemove.filter(el => el);
        if (healthCardNumber == healthCards[0]?.textValue?.healthCardNumber && isNewSignUp) {
            skipAction?.(true);
        }
        else {
            updateFiles({
                textValue: healthCardNumber ? {
                    patientFileId: healthCards[0]?.textValue ? healthCards[0]?.id : null,
                    healthCardNumber,
                    fileType: "HealthCard",
                    idsForDelete: filesToRemoveIds
                } : undefined,
                fileValue: !healthCardNumber ? {
                    deleted: patientFileId ? [...filesToRemoveIds, { id: patientFileId, deleteType: "Full" }] : filesToRemoveIds,
                    added: {
                        files: files
                    },
                    fileType: "HealthCard"
                } : undefined,
                screenName: "HealthCard"
            });
        }
    };
    const renderActions = skipAction
        ? React.createElement("div", { className: classNames(s.actions, { [s.hasSidebar]: hasSidebar }) },
            React.createElement(Box, { column: true, fullWidth: true },
                React.createElement(Button, { style: s.button, noborder: true, skip: true, text: t("skip"), onClick: skipAction }),
                React.createElement(Box, { f1: true, row: true, gap: 16 },
                    React.createElement(Button, { text: t("back"), onClick: prevStep }),
                    React.createElement(Button, { style: s.button, disabled: saveDisabled, primary: true, iosTextOverflow: true, text: !skipAction && t("update") || t("saveAndContinue"), onClick: () => {
                            onSave();
                        } }))))
        : React.createElement(Box, { f1: true, row: true, justifyCenter: true, alignCenter: true },
            React.createElement(Button, { disabled: saveDisabled, style: s.updateButton, primary: true, text: t("update"), onClick: onSave }));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(isNewSignUp ? si.contentWrapper : null) },
            React.createElement("div", { className: classNames(isNewSignUp ? si.scrollWrapperfrom : null) },
                React.createElement("div", { className: classNames(isNewSignUp ? si.formstep : null, isNewSignUp ? si.formWrapper : null) },
                    React.createElement("div", { className: classNames(isNewSignUp ? si.formstepbar : null, isNewSignUp ? null : s.scrollWrapper) },
                        isNewSignUp ? React.createElement(React.Fragment, null,
                            React.createElement("div", { className: s.header },
                                React.createElement("div", { className: s.pages },
                                    React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `9/15`)))) : null,
                        React.createElement("div", { className: classNames({ [s.hasSidebar]: hasSidebar }) },
                            React.createElement("div", { className: classNames(isNewSignUp ? null : null) },
                                React.createElement("div", { className: classNames(s.fullLine, s.mb12) }, children),
                                !(healthCardNumber) &&
                                    React.createElement("div", { className: s.cards }, imageCard("FRONT", "HealthCard", frontImage, setFrontImage)),
                                !(frontImage || backImage || healthCardNumber) &&
                                    React.createElement("div", { className: classNames(s.fullLine, s.separator) },
                                        React.createElement("div", { className: classNames(s.subheading_3, s.or) }, t("or"))),
                                !(frontImage || backImage) && React.createElement("div", { className: classNames(s.contentInput, isNewSignUp ? null : s.formOneLineCenter) },
                                    React.createElement("div", { className: classNames(si.formGroup) },
                                        React.createElement("label", null, t("healthCardNumber")),
                                        React.createElement(InputText, { style: s.input, styleGroup: classNames({ [s.fullLine]: !hasSidebar }), name: "healthCardNumber", value: healthCardNumber, setValue: setHealthCardNumber, 
                                            // pattern={'^\\d{4}-?\\d{3}-?\\d{3}(?: ?[a-zA-Z]{1,2})?$'}
                                            pattern: '^\\d{10}([a-zA-Z]{1,2})?$', errorMessage: t("healthCardInvalid"), error: errorHealthCardNumber, setError: validateHealthCardNumber, length: 12 }))),
                                React.createElement("div", { className: s.dBlock }, renderActions),
                                React.createElement(ImageModal, { modalIsOpen: modalIsOpen, deleteFile: () => modalFile?.removeImage(modalFile?.image), file: modalFile?.image, toggleModal: () => setModalIsOpen(false) }))))))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        healthCards: filesByTypeSelector(state, "HealthCard"),
        requestGetFilesStatus: state.patientFiles.requestGetFilesStatus,
        requestUpdateFilesStatus: state.patientFiles.requestUpdateFilesStatus,
        requestHealthCardUpdateStatus: state.patientFiles.requestHealthCardUpdateStatus,
        requestGetPreviewPhotoStatus: state.patientFiles.requestGetPreviewPhotoStatus,
        requestInsurancesUpdateStatus: state.patientFiles.requestInsurancesUpdateStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getFiles: (photoType) => dispatch(getPatientFilesAsyncAction({ type: photoType })),
        updateFiles: (payload) => dispatch(updatePatientFilesAllTypesAsyncAction(payload)),
        hideRequestHealthCardUploadAction: () => dispatch(hideRequestHealthCardUploadErrorAction())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HealthCard);
