import React from "react";
import classNames from 'classnames';
import moment from "moment";
import CustomDatePicker from "../../CustomDatePicker";
import Calendar from '../../../../../resources/icons/calendar.svg';
import SVG from 'react-inlinesvg';
import s from './style.scss';
import Typography from "../../../Typography";
const InputDate = ({ style, name, value, setValue, error, setError, minDate, maxDate, placeholder, label, pattern, errorMessage, tabIndex }) => {
    return (React.createElement("div", { className: s.mt0 },
        label && React.createElement(Typography, { type: "label", color: "neutralGray", bold: true }, label),
        React.createElement("div", { className: s.inputGroup },
            React.createElement(CustomDatePicker, { style: classNames(s.inputCustom, s.datePickerShift, style), date: value, setDate: setValue, minDate: minDate, maxDate: maxDate, tabIndex: tabIndex }),
            React.createElement("div", { className: s.dateValue }, value && moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY')),
            React.createElement("div", { className: s.calendar }, !value && React.createElement(SVG, { src: Calendar, className: s.calendar })),
            placeholder &&
                React.createElement("div", { className: classNames(s.floatingLabel, { [s.floatingLabelActive]: value }) }, placeholder))));
};
export default InputDate;
