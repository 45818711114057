import _ from "lodash";
import moment from "moment";
export const mapReminders = (data) => {
    return data.map((item) => {
        return {
            id: item.id,
            isActive: item.isActive,
            patientId: item.patientId,
            title: item.title,
            reminderSubType: item.reminderSubType,
            reminderType: item.reminderType,
            repeatType: item.repeatType,
            startDate: moment(item.startDate).add(moment().utcOffset() / 60, "hours").format(),
            endDate: moment(item.endDate).add(moment().utcOffset() / 60, "hours").format(),
            medicationTimes: item.medicationTimes,
            daysOfTheWeek: item.daysOfTheWeek || undefined,
            repeatIntervalInDays: item.repeatIntervalInDays,
            created: item.created
        };
    });
};
export const mapMedicalRemindersDate = (times, start, end) => {
    const minTime = _.min(times.map((i) => moment(i, "hh:mm A").format()));
    const minHours = moment(minTime).get("hours");
    const minMinutes = moment(minTime).get("minutes");
    const newStartDate = moment(start)
        .set("hour", minHours)
        .set("minute", minMinutes);
    const maxTime = _.max(times.map((i) => moment(i, "hh:mm A").format()));
    const maxHours = moment(maxTime).get("hours");
    const maxMinutes = moment(maxTime).get("minutes");
    const newEndDate = moment(end)
        .set("hours", maxHours)
        .set("minutes", maxMinutes);
    return {
        startDate: newStartDate,
        endDate: newEndDate
    };
};
