import React, { useState, useEffect } from "react";
import PageBody from "../../../../../components/Shared/PageBody";
import style from "../../../style.scss";
import s from "./style.scss";
import { useInput } from "../../../../../hooks";
import { Button, InputPassword, } from "../../../../../components/Shared/Controls";
import { Notification, PopUp } from "../../../../../components/Shared/Info";
import SVG from "react-inlinesvg";
import OK from "../../../../../resources/icons/circle-ok.svg";
import { connect } from "react-redux";
import { changePasswordAsyncAction } from "../../../../../store/auth/actions";
import { resetRequestChangePasswordAction, } from "../../../../../store";
import ErrorPopUp from "../../../../../components/Shared/Info/ErrorPopUp";
import useErrorPopUp from "../../../../../hooks/useErrorPopUp";
import { useTranslation } from "react-i18next";
const CreatePassword = ({ setPage, changePassword, passwordStatus, passwordError, renderPaggination, resetPasswordStatus, backAction, }) => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword, errorNewPassword, setErrorNewPassword] = useInput();
    const [confirmPassword, setConfirmPassword, errorConfirmPassword, setErrorConfirmPassword,] = useInput();
    const [sent, setSent] = useState(false);
    const [isOpen, showPopUp, errorContent, setErrorContent, togglePopUp] = useErrorPopUp();
    const onSubmit = () => {
        if (newPassword !== confirmPassword) {
            setErrorContent(t("passwordsDoNotMatch"));
            showPopUp(true);
            return;
        }
        changePassword(newPassword);
    };
    useEffect(() => {
        passwordStatus === "success" && setSent(true);
    }, [passwordStatus]);
    useEffect(() => {
        isOpen === false && resetPasswordStatus();
    }, [isOpen]);
    return (React.createElement(PageBody, { backAction: backAction, title: t("createYourPassword") },
        React.createElement("div", { className: style.scrollWrapper },
            renderPaggination,
            React.createElement(ErrorPopUp, { isOpen: isOpen, popUpContent: errorContent, togglePopUp: togglePopUp }),
            sent && (React.createElement(PopUp, { isOpen: sent },
                React.createElement("div", { className: style.popup },
                    React.createElement("div", null,
                        React.createElement(SVG, { src: OK, className: style.popup__header_icon })),
                    React.createElement("div", { className: style.popup__content },
                        React.createElement("div", null, t("successfullyChangedPassword")),
                        React.createElement("div", null)),
                    React.createElement("div", { className: style.popup__controls },
                        React.createElement(Button, { text: t("backToSettings"), onClick: () => setPage(1) }),
                        React.createElement(Button, { primary: true, text: t("backToHome"), onClick: () => (window.location.href = "/") }))))),
            React.createElement("p", { className: style.confirmMessage }, t("makePasswordLonger")),
            React.createElement("div", { className: style.pageWrapper },
                React.createElement("div", { className: s.inputsWrapper },
                    React.createElement(InputPassword, { name: "newPassword", placeholder: t("enterNewPassword"), pattern: {
                            rule: "(?=.*[0-9])(?=.*[!@#$%^&*()_+=-])(?=.*[A-Za-z])[A-Za-z0-9!@#$%^&*()_+=-]{8,}",
                            listOnConditions: [
                                "(?=.*[a-z])[a-z]",
                                "(?=.*[A-Z])[A-Z]",
                                "(?=.*[0-9])[0-9]",
                                "(?=.*[!@#$%^&*()_+=-])[!@#$%^&*()_+=-]",
                            ],
                            countOfConditions: 3,
                        }, setError: setErrorNewPassword, errorMessage: t("requirementsToPassword"), password: newPassword, setPassword: setNewPassword, error: newPassword && errorNewPassword }),
                    React.createElement(InputPassword, { name: "confirmNewPassword", placeholder: t("confirmPassword"), pattern: {
                            rule: "(?=.*[0-9])(?=.*[!@#$%^&*()_+=-])(?=.*[A-Za-z])[A-Za-z0-9!@#$%^&*()_+=-]{8,}",
                            listOnConditions: [
                                "(?=.*[a-z])[a-z]",
                                "(?=.*[A-Z])[A-Z]",
                                "(?=.*[0-9])[0-9]",
                                "(?=.*[!@#$%^&*()_+=-])[!@#$%^&*()_+=-]",
                            ],
                            countOfConditions: 3,
                        }, setError: setErrorConfirmPassword, errorMessage: t("confirmPasswordError"), password: confirmPassword, setPassword: setConfirmPassword, error: confirmPassword && errorConfirmPassword }),
                    React.createElement(Notification, null,
                        React.createElement("div", { className: s.notification },
                            React.createElement("div", null, t("requirementsToPassword")))))),
            React.createElement(Button, { style: style.button, disabled: !newPassword ||
                    errorNewPassword ||
                    !confirmPassword ||
                    errorConfirmPassword ||
                    newPassword !== confirmPassword, primary: true, text: t("confirmAndProceed"), onClick: onSubmit }))));
};
const mapStateToProps = (state) => {
    return {
        passwordStatus: state.auth.requestChangePasswordStatus,
        passwordError: state.auth.requestChangePasswordError,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        changePassword: (newPassword) => dispatch(changePasswordAsyncAction(newPassword)),
        resetPasswordStatus: () => dispatch(resetRequestChangePasswordAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
