import React, { useEffect, useRef, useState } from "react";
import { Button, InputText } from "../../components/Shared/Controls";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getDrugBrandCodesDropdownAsyncAction } from "../../store/prescriber/Sagas";
import CheckboxDropdown from "../../components/Shared/Controls/CheckboxDropdown";
import LoadingPopUp from "../../components/Shared/LoadingPopUp";
import SuccessPopUp from '../../components/Shared/Info/SuccessPopUp';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import pharmaciescss from "./PharmaciesStyle.scss";
import golbalcss from "../styles.scss";
import InputTextMultiLine from "../../components/Shared/Controls/Inputs/InputTextMultiLine";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Grid from '@mui/material/Grid';
import { createPharmaciesInfoAsyncAction, getSingleDataOfPharmacyInfoAsyncAction, updatePharmaciesInfoAsyncAction } from "../../store/pharmacy/Sagas";
import { useInput } from "../../hooks";
import MapContainerMultiple from "../../components/MapContainerMultiple";
import ScriptLoaded from "../../components/ScriptLoaded";
import { StandaloneSearchBox } from "@react-google-maps/api";
import colors from "../../resources/colors";
import s from "../../components/Shared/Controls/Inputs/InputText/style.scss";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
const Pharmacies = ({ createPharmacies, updatePharmacies, getPharmaciesInfo, getDrugBrandCodesDropdown, drugBrandCodesDropdown, getSingleDataOfPharmacyInfo, requestUpdatePharmaciesInfoStatus, requestPharmaciesPostStatus, requestSingleDataOfPharmacyInfoStatus, requestDrugBrandCodesDropdownStatus }) => {
    const { t } = useTranslation();
    const [openSavePopup, setOpenSavePopup] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [Name, setName] = useState("");
    const [Note, setNote] = useState("");
    const [BrandIdentifier, setBrandIdentifier] = useState("");
    const [successPopupIsOpen, setSuccessPopupIsOpen] = useState(false);
    const [city, setCity] = useState("");
    const [country, setcountry] = useState("CA");
    const [postalCode, setPostalCode] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState('');
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput();
    const [location, setLocation] = useState("");
    const [longitude, setLongitude, errorLongitude, setErrorLongitude] = useInput();
    const [latitude, setLatitude, errorLatitude, setErrorLatitude] = useInput();
    const [tmpLocationArray, setTemLocationArray] = useState([]);
    const handlePhoneInputChange = (value, info) => {
        setPhone(value);
        setPhoneNumber(info.nationalNumber);
        setErrorPhoneNumber(!matchIsValidTel(value));
    };
    const toggleSavePopup = () => setOpenSavePopup((open) => !open);
    const [isSubmit, setisSubmit] = useState(false);
    useEffect(() => {
        getDrugBrandCodesDropdown();
        requestPharmaciesPostStatus = "idle";
        requestUpdatePharmaciesInfoStatus = "idle";
    }, []);
    useEffect(() => {
        if (id) {
            setName("");
            setNote("");
            setBrandIdentifier("");
            getPharmaciesInfo(id);
        }
    }, [id]);
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode,
                isChecked: false
            };
        })
        : [];
    const [items, setItems] = useState(DrugBrandCode);
    const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
    const handleSetItems = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.displayName);
        setSelectedDisplayNames(selectedNames);
        setItems(newItems);
    };
    const countries = [
        {
            key: "CA",
            displayName: "Canada",
            isChecked: true
        }
    ];
    const handleSetCountries = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.key);
        setcountry(selectedNames[0]);
    };
    useEffect(() => {
        if (id) {
            if (getSingleDataOfPharmacyInfo != null) {
                const PharmaciesData = getSingleDataOfPharmacyInfo[0];
                setName(PharmaciesData.name || "");
                setNote(PharmaciesData.note || "");
                setAddress(PharmaciesData.address || "");
                setcountry(PharmaciesData.country || "");
                setCity(PharmaciesData.city || "");
                setPhone(!!PharmaciesData.phone ? "+1" + PharmaciesData.phone : "");
                setPhoneNumber(PharmaciesData.phone || "");
                setLongitude(PharmaciesData.longitude || "");
                setLatitude(PharmaciesData.latitude || "");
                setBrandIdentifier(PharmaciesData.brandIdentifier || "");
                // Set the drugBrandCodes in CheckboxDropdown
                const selectedDrugBrandCodes = PharmaciesData.drugBrandCodes || [];
                const updatedItems = items.map((item) => ({
                    ...item,
                    isChecked: selectedDrugBrandCodes.includes(item.displayName),
                }));
                handleSetItems(updatedItems);
            }
        }
        else {
            setName("");
            setNote("");
            setBrandIdentifier("");
        }
    }, [getSingleDataOfPharmacyInfo]);
    const onsubmitData = () => {
        setisSubmit(true);
        if (Name != "" && address != "" && city != "" && postalCode != "" && country != "" && phone != "" && longitude != null && longitude != undefined && latitude != null && latitude != undefined && selectedDisplayNames.length > 0) {
            if (id) {
                updatePharmacies({
                    Id: id,
                    name: Name,
                    drugBrandCodes: selectedDisplayNames,
                    address: address,
                    city: city,
                    postal: postalCode,
                    country: country,
                    province: null,
                    phone: phoneNumber,
                    note: Note,
                    brandIdentifier: BrandIdentifier,
                    longitude: longitude,
                    latitude: latitude
                });
                setisSubmit(false);
            }
            else {
                createPharmacies({
                    name: Name,
                    drugBrandCodes: selectedDisplayNames,
                    address: address,
                    city: city,
                    postal: postalCode,
                    country: country,
                    province: null,
                    phone: phoneNumber,
                    note: Note,
                    brandIdentifier: BrandIdentifier,
                    longitude: longitude,
                    latitude: latitude
                });
                setisSubmit(false);
            }
        }
    };
    const onFormSubmit = (event) => {
        event.preventDefault();
        onsubmitData();
    };
    useEffect(() => {
        if (requestPharmaciesPostStatus === "success") {
            setSuccessPopupIsOpen(true);
            setName("");
            setNote("");
            setBrandIdentifier("");
            requestPharmaciesPostStatus = "idle";
        }
    }, [requestPharmaciesPostStatus]);
    useEffect(() => {
        if (requestUpdatePharmaciesInfoStatus === "success") {
            requestUpdatePharmaciesInfoStatus = "idle";
            setSuccessPopupIsOpen(true);
        }
    }, [requestUpdatePharmaciesInfoStatus]);
    const handleArrowBack = () => {
        navigate('/pharmacies');
    };
    useEffect(() => {
        if (!!latitude && !!longitude) {
            const locationItem = [
                {
                    id: null,
                    lat: Number(latitude),
                    lng: Number(longitude)
                }
            ];
            setLocation("");
            setTemLocationArray(locationItem);
        }
    }, [latitude, longitude]);
    const searchBoxRef = useRef(null);
    const onLoad = (ref) => {
        if (ref) {
            searchBoxRef.current = ref;
        }
    };
    const onPlacesChanged = () => {
        if (!searchBoxRef.current)
            return;
        let places = searchBoxRef.current.getPlaces();
        let locations = [];
        for (let i = 0; i < places.length || i < 1; i++) {
            locations.push({
                id: null,
                lat: places[i].geometry.location.lat(),
                lng: places[i].geometry.location.lng()
            });
            setLatitude(places[i].geometry.location.lat());
            setLongitude(places[i].geometry.location.lng());
        }
        setLocation("");
        setTemLocationArray(locations);
    };
    return (React.createElement(Box, { className: golbalcss.MuiPaperAllSectionBox, sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { className: pharmaciescss.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement("div", { className: pharmaciescss.prescriberModifyanEntry },
                id ? React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: pharmaciescss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("pharmacies"),
                    " ",
                    React.createElement("span", { className: pharmaciescss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("modifyanentry"),
                    " ") : React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: pharmaciescss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("Pharmacies"),
                    " ",
                    React.createElement("span", { className: pharmaciescss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("createanentry"),
                    " "),
                React.createElement("div", { className: pharmaciescss.pagebodyBox },
                    React.createElement("div", { className: pharmaciescss.scrollWrapper },
                        React.createElement(React.Fragment, null,
                            React.createElement("form", { onSubmit: onFormSubmit },
                                React.createElement(Box, { sx: { width: '100%' } },
                                    React.createElement(Grid, { container: true, rowSpacing: 1, columnSpacing: { xs: 1, sm: 2, md: 3 } },
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("pharmaname"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "Name", value: Name, setValue: setName, maxLength: 100, validationMessage: t("pleaseEnterName"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("brandcodes"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(CheckboxDropdown, { items: items, setItems: handleSetItems, required: true, isSubmited: isSubmit })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null, t("brandidentifier")),
                                                React.createElement(InputText, { name: "BrandIdentifier", value: BrandIdentifier, setValue: setBrandIdentifier, validationMessage: t("pleaseenterBrandIdentifier"), isSubmitted: isSubmit, required: false })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("note"),
                                                    " ",
                                                    React.createElement("span", null, "(Max 256 characters)")),
                                                React.createElement(InputTextMultiLine, { name: "Note", maxLength: 256, value: Note, setValue: setNote, columns: 6, height: 156 }))),
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("address"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "address", value: address, setValue: setAddress, maxLength: 100, validationMessage: t("pleaseEnterAddress"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("city"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "city", value: city, setValue: setCity, maxLength: 100, validationMessage: t("pleaseEnterCity"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("postalCode"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "postalCode", value: postalCode, setValue: setPostalCode, maxLength: 6, validationMessage: t("pleaseEnterPostalCode"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("country"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(CheckboxDropdown, { items: countries, setItems: handleSetCountries, required: true, isSubmited: isSubmit })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("phone"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(MuiTelInput, { name: "phone", value: phone, onChange: handlePhoneInputChange, error: errorPhoneNumber, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 9 }))),
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("location"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement("p", null, t("searchForalocation")),
                                                React.createElement(ScriptLoaded, null,
                                                    React.createElement(StandaloneSearchBox, { onLoad: onLoad, onPlacesChanged: onPlacesChanged },
                                                        React.createElement("input", { type: "text", placeholder: t('searchLocation'), style: { backgroundColor: colors.primaryshades3 }, className: classNames(s.input) })))),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("div", { className: classNames(pharmaciescss.orbg) },
                                                    React.createElement("p", null,
                                                        "------- ",
                                                        t('OR'),
                                                        " -------"))),
                                            React.createElement("p", null, t("enterThemManually")),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("longitude"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "longitude", value: longitude, setValue: setLongitude, maxLength: 10, validationMessage: t("pleaseenterlongitude"), isSubmitted: isSubmit, required: true, errorMessage: t("errorLongitudeIncorrectFormat"), error: errorLongitude, setError: setErrorLongitude, isLongitude: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("latitude"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "latitude", value: latitude, setValue: setLatitude, maxLength: 9, validationMessage: t("pleaseenterlatitude"), isSubmitted: isSubmit, required: true, errorMessage: t("errorLatitudeIncorrectFormat"), error: errorLatitude, setError: setErrorLatitude, isLatitude: true }))),
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("div", { className: classNames(pharmaciescss.map) },
                                                    React.createElement(MapContainerMultiple, { locations: tmpLocationArray, setIsLoaded: setIsLoaded, setCurrentLocation: setCurrentLocation }))),
                                            React.createElement("div", { className: pharmaciescss.buttonTwoTop },
                                                React.createElement("div", { className: pharmaciescss.buttonTwolist },
                                                    React.createElement(Button, { text: t("cancel"), onClick: () => handleArrowBack() }),
                                                    React.createElement(Button, { primary: true, text: t("savechanges"), type: "submit" }))))))),
                            React.createElement(SuccessPopUp, { isOpen: successPopupIsOpen, displayMessage: "Your changes have been saved.", header: "success!", name: "Pharmacies", togglePopUp: () => { setSuccessPopupIsOpen(false); handleArrowBack(); }, onClose: () => { setSuccessPopupIsOpen(false); handleArrowBack(); } }),
                            requestUpdatePharmaciesInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestPharmaciesPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestSingleDataOfPharmacyInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null)))))));
};
const mapStateToProps = (state) => {
    return {
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown,
        getSingleDataOfPharmacyInfo: state.pharmacy.getSingleDataOfPharmacyInfo,
        requestUpdatePharmaciesInfoStatus: state.pharmacy.requestUpdatePharmaciesInfoStatus,
        requestPharmaciesPostStatus: state.pharmacy.requestPharmaciesPostStatus,
        requestSingleDataOfPharmacyInfoStatus: state.pharmacy.requestSingleDataOfPharmacyInfoStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        createPharmacies: (pharmaciesInfo) => dispatch(createPharmaciesInfoAsyncAction(pharmaciesInfo)),
        getPharmaciesInfo: (Id) => dispatch(getSingleDataOfPharmacyInfoAsyncAction(Id)),
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
        updatePharmacies: (pharmaciesInfo) => dispatch(updatePharmaciesInfoAsyncAction(pharmaciesInfo)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Pharmacies);
