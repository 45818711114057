import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { logOutAsyncAction } from "../../store/auth/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
const Logout = (properties) => {
    const navigate = useNavigate();
    localStorage.removeItem("persist:root");
    Cookies.remove("token");
    localStorage.removeItem('savedMinmeType');
    localStorage.removeItem('LoginAPIResponse');
    localStorage.removeItem('LengthStepsToComplete');
    localStorage.removeItem('storeStepsToCompleteData');
    properties.logout();
    useEffect(() => {
        navigate("/");
    }, []);
    // window.location.reload();
    return null;
};
function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logOutAsyncAction()),
    };
}
export default connect(null, mapDispatchToProps)(Logout);
