import { createSlice, createEntityAdapter, } from "@reduxjs/toolkit";
import { showErrorAction, hideErrorAction, startRequestAction } from "../utils";
export const SLICE_NAME = "NOTIFICATIONS";
export const notificationsAdapter = createEntityAdapter({
    selectId: (notification) => notification.id,
});
export const notificationsSelectors = notificationsAdapter.getSelectors();
const initialState = {
    requestStatus: "idle",
    requestError: null,
    requestMarkViewedStatus: "idle",
    requestMarkViewedError: null,
    isNewNotification: false,
};
const notificationSlice = createSlice({
    name: SLICE_NAME,
    initialState: notificationsAdapter.getInitialState(initialState),
    reducers: {
        successRequestAction: (state, action) => {
            // notificationsAdapter.upsertMany(state, action.payload);
            notificationsAdapter.setAll(state, action.payload);
            state.requestStatus = "success";
            state.requestError = null;
        },
        startRequestAction,
        showErrorAction,
        hideNotificationsErrorAction: hideErrorAction,
        resetNotificationSliceAction: (state) => {
            state = notificationsAdapter.getInitialState(initialState);
        },
        startRequestMarkViewedAction: (state) => {
            state.requestMarkViewedStatus = "pending";
            state.requestMarkViewedError = null;
        },
        successRequestMarkViewedAction: (state, action) => {
            notificationsAdapter.upsertMany(state, action.payload);
            state.requestMarkViewedStatus = "success";
            state.requestMarkViewedError = null;
        },
        showErrorMarkViewedAction: (state, action) => {
            state.requestMarkViewedStatus = "failure";
            state.requestMarkViewedError = action.payload;
        },
        hideErrorMarkViewedAction: (state) => {
            state.requestMarkViewedStatus = "idle";
            state.requestMarkViewedError = null;
        },
        setNotificationStateAction: (state, action) => {
            state.isNewNotification = action.payload;
        },
        resetRequesNotificationsStatus: (state) => {
            state.requestStatus = "idle";
            state.requestError = null;
        },
    },
});
export default notificationSlice;
