import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { brandsBySearchKeySelector, resetRequestScheduleCall, } from "../../../../../../store";
import { usePrevious } from "../../../../../../hooks";
import { PopUp } from "../../../../../../components/Shared/Info";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import s from "./style.scss";
const countPage = 5;
const SelectBrandPage = ({ isOpen, togglePopUp, requestAssignToBrandStatus, requestScheduleCallStatus, resetScheduleCall, }) => {
    const { t } = useTranslation();
    const prev = usePrevious(requestScheduleCallStatus);
    const [page, setPage] = useState(1);
    const nextPage = () => countPage + 1 > page && setPage((i) => ++i);
    const toggle = () => {
        togglePopUp();
        setPage(1);
    };
    const renderPages = () => {
        switch (page) {
            case 1:
                return React.createElement(Step1, { nextPage: nextPage, page: page });
            case 2:
                return React.createElement(Step2, { nextPage: nextPage, page: page });
            case 3:
                return React.createElement(Step3, { nextPage: toggle, page: page });
            // case 4:
            //   return <Step4 nextPage={nextPage} page={page} />;
            // case 5:
            //   return <Step5 page={page} finishAction={toggle} />;
            default:
                return null;
        }
    };
    useEffect(() => {
        if (prev !== undefined && requestScheduleCallStatus === "success") {
            togglePopUp();
            setPage(1);
            resetScheduleCall();
        }
    }, [requestScheduleCallStatus]);
    return (React.createElement(PopUp, { style: s.addBrandPopup, isOpen: isOpen, toggle: toggle, allowClose: true },
        React.createElement("h2", { className: s.popupHeader }, t("addBrand")),
        renderPages()));
};
const mapStateToProps = (state) => {
    return {
        userId: state.account.user.patientId,
        brands: brandsBySearchKeySelector(state, state.brands.searchValue),
        userBrands: state.account.patientBrands,
        requestAssignToBrandStatus: state.account.requestAssignToBrandStatus,
        requestAssignToBrandError: state.account.requestAssignToBrandError,
        requestScheduleCallStatus: state.patientFiles.requestScheduleCallStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        resetScheduleCall: () => dispatch(resetRequestScheduleCall()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectBrandPage);
