import React from 'react';
import classNames from 'classnames';
import s from './style.scss';
const Button = ({ style, jsStyle, type = "button", disabled = false, primary = false, primarySuccess = false, noborder = false, iosTextOverflow, text, onClick, skip = false, deleted = false, name }) => {
    return (React.createElement("button", { name: name || "default-button-component", "data-testid": "button", tabIndex: 3, type: type, disabled: disabled, className: classNames(s.button, style, { [s.primary]: primary, [s.primarySuccess]: primarySuccess, [s.deleted]: deleted, [s.disabled]: disabled, [s.iosFontSize]: iosTextOverflow, [s.noborder]: noborder }), onClick: onClick }, text)
    // if (disabled) {
    //     backgroundColor = colors.buttonDisabledBackgroundColor;
    //     color = colors.buttonDisabledTextColor;
    //     borderWidth = 2;
    // } else if (primary) {
    //     backgroundColor = colors.buttonPrimaryBackgroundColor;
    //     color = colors.buttonPrimaryTextColor;
    //     borderColor = colors.buttonPrimaryBackgroundColor;
    //     borderWidth = 2;
    // } else if (deleted) {
    //     backgroundColor = colors.deleteRed;
    //     color = colors.buttonBackgroundColor;
    //     borderWidth = 2;
    // } else {
    //     backgroundColor = colors.buttonBackgroundColor;
    //     color = colors.buttonTextColor;
    //     borderWidth = 2;
    // }
    );
};
export default Button;
