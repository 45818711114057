import React, { useRef, useState } from "react";
import classNames from 'classnames';
import Chevron from '../../../../../resources/icons/chevron_down.svg';
import SVG from 'react-inlinesvg';
import s from './style.scss';
import { useOnClickOutside } from "../../../../../hooks";
import Typography from "../../../Typography";
import colors from "../../../../../resources/colors";
const ProvinceDropdown = ({ style, data, value, setValue, placeholder, label, required, validationMessage, isSubmitted, tabIndex }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);
    const dropdownRef = useRef(null);
    useOnClickOutside(dropdownRef, () => {
        setIsOpen(false);
    });
    const selectedProvince = data.find(el => el.key === value);
    return (React.createElement("div", null,
        label && React.createElement(Typography, { type: "label", color: "neutralGray", bold: true }, label),
        React.createElement("div", { className: s.inputGroup, "data-testid": "dropdown", ref: dropdownRef, onClick: toggling },
            React.createElement("div", { className: classNames(s.input, style), style: { backgroundColor: colors.primaryshades3 } },
                React.createElement("div", { "data-testid": "dropdownValue", className: s.value }, selectedProvince && selectedProvince.displayName)),
            isOpen && (React.createElement("div", { "data-testid": "dropdownList", tabIndex: tabIndex, className: classNames(s.dropdown_list) }, data.map(({ key, displayName }) => (React.createElement("div", { key: key, className: s.dropdown_list_item, onClick: () => {
                    setValue(key);
                    toggling();
                } }, displayName))))),
            React.createElement("div", { "data-testid": "placeholder", className: classNames(s.floatingLabel, { [s.valid]: value }) }, placeholder),
            isSubmitted && required && value == undefined && React.createElement("span", { "data-testid": "error-test", className: s.error }, validationMessage),
            React.createElement(SVG, { src: Chevron, className: classNames(s.chevron, { [s.chevron_active]: isOpen }) }))));
};
export default ProvinceDropdown;
