import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { showErrorAction, hideErrorAction, startRequestAction } from "../utils";
export const SLICE_NAME = "BRANDS";
export const brandsAdapter = createEntityAdapter({
    selectId: (brand) => brand.drugBrandCode
});
export const brandsSelectors = brandsAdapter.getSelectors();
const initialState = {
    requestGetBrandsStatus: "idle",
    requestGetBrandsError: null,
    requestGetTestBrandsStatus: "idle",
    requestGetTestBrandsError: null,
    requestSearchBrandsStatus: "idle",
    requestSearchBrandsError: null,
    requestStatus: "idle",
    requestError: null,
    searchValue: "",
    allowCaregiver: true,
    isTestBrands: false
};
const brandSlice = createSlice({
    name: SLICE_NAME,
    initialState: brandsAdapter.getInitialState(initialState),
    reducers: {
        startGetBrandsRequestAction: (state) => {
            state.requestGetBrandsStatus = "pending";
            state.requestGetBrandsError = null;
            state.isTestBrands = false;
        },
        hideGetBrandsErrorAction: (state) => {
            state.requestGetBrandsStatus = "idle";
            state.requestGetBrandsError = null;
        },
        showGetBrandsErrorAction: (state, action) => {
            state.requestGetTestBrandsStatus = "failure";
            state.requestGetTestBrandsError = action.payload;
        },
        startGetTestBrandsRequestAction: (state) => {
            state.requestGetTestBrandsStatus = "pending";
            state.requestGetTestBrandsError = null;
            state.isTestBrands = true;
        },
        hideGetTestBrandsErrorAction: (state) => {
            state.requestGetTestBrandsStatus = "idle";
            state.requestGetTestBrandsError = null;
        },
        showGetTestBrandsErrorAction: (state, action) => {
            state.requestGetTestBrandsStatus = "failure";
            state.requestGetTestBrandsError = action.payload;
        },
        setBrandsAction: (state, action) => {
            brandsAdapter.setAll(state, action.payload);
            state.requestGetBrandsStatus = "success";
            state.requestGetBrandsError = null;
        },
        startSearchBrandsRequestAction: (state) => {
            state.requestSearchBrandsStatus = "pending";
            state.requestSearchBrandsError = null;
        },
        showSearchBrandsErrorAction: (state, action) => {
            state.requestSearchBrandsStatus = "failure";
            state.requestSearchBrandsError = action.payload;
        },
        setSearchValueAction: (state, action) => {
            state.searchValue = action.payload;
        },
        startRequestAction,
        showErrorAction,
        hideGetBrandSettingsErrorAction: hideErrorAction,
        successRequestAction: (state, action) => {
            state.allowCaregiver = action.payload;
            state.requestError = null;
            state.requestStatus = "success";
        },
        resetBrandsSliceAction: (state) => {
            state = brandsAdapter.getInitialState(initialState);
        }
    }
});
export default brandSlice;
