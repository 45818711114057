import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { getAnalytics, logEvent } from "@firebase/analytics";
import PageBody from "../../components/Shared/PageBody";
import EducationTab from "./components/EducationTab";
import Typography from '@mui/material/Typography';
import { Button, FormControl, OutlinedInput } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
;
import { connect } from "react-redux";
import Paper from '@mui/material/Paper';
import s from "./style.scss";
import golbalcss from "../../Pages/styles.scss";
import { Box } from "../../components";
import EducationDetails from "./components/EducationDetails";
import EducationFilter from "./components/EducationDetails/EducationFilter";
const EducationPage = ({ backAction, requestStatus, isAdmin, error }) => {
    const { t } = useTranslation();
    useEffect(() => {
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: "EducationLibrary",
            firebase_screen_class: "EducationLibrary"
        });
    }, []);
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState(null);
    const [currentTab, setCurrentTab] = useState("videos");
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [educationFilterObj, seteducationFilterObj] = useState();
    const [searchData, setSearchData] = useState("");
    const [holdDataFilter, setholdDataFilter] = useState();
    // localStorage.setItem('savedMinmeType', 'WEBM');
    useEffect(() => {
        localStorage.setItem('savedMimeType', 'Webm');
    }, []);
    const saveMinmeTypeForCurrentTab = localStorage.getItem('savedMinmeType');
    useEffect(() => {
        if (saveMinmeTypeForCurrentTab == 'Web') {
            setCurrentTab('online-resources');
        }
        else if (saveMinmeTypeForCurrentTab == 'Webm') {
            setCurrentTab('videos');
        }
        else if (saveMinmeTypeForCurrentTab == 'Pdf') {
            setCurrentTab('articles');
        }
        else {
            //setCurrentTab('videos');
        }
    }, [saveMinmeTypeForCurrentTab]);
    const tabs = [
        {
            key: "videos",
            title: t("videosLibrary"),
        },
        {
            key: "articles",
            title: t("articlesLibrary"),
        },
        {
            key: "online-resources",
            title: t("onlineResources"),
        },
    ];
    const renderTab = () => {
        switch (currentTab) {
            case "videos":
                return React.createElement(EducationTab, { setEducationItem: setSelectedItem, type: "Webm", educationFilterObj: educationFilterObj, searchData: searchData });
            case "articles":
                return React.createElement(EducationTab, { setEducationItem: setSelectedItem, type: "Pdf", educationFilterObj: educationFilterObj, searchData: searchData });
            case "online-resources":
                return React.createElement(EducationTab, { setEducationItem: setSelectedItem, type: "Web", educationFilterObj: educationFilterObj, searchData: searchData });
            default:
                return React.createElement("div", null);
        }
    };
    const MenuItem = ({ text, selected }) => {
        const isActive = selected === currentTab;
        const handleClick = () => {
            if (!isActive) {
                setCurrentTab(selected);
                localStorage.removeItem('savedMinmeType');
            }
        };
        return (React.createElement("div", { className: classNames(s.tab, {
                [s.active]: isActive,
            }), onClick: handleClick }, text));
    };
    const menuItems = tabs.map(({ key, title }) => (React.createElement(MenuItem, { text: title, key: key, selected: key })));
    function newEducation() {
        navigate('/education/create');
    }
    const handleIconClick = () => {
        setPopupOpen(true);
    };
    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const setFields = (EducationFilterfieldsObj) => {
        setholdDataFilter(EducationFilterfieldsObj);
        seteducationFilterObj(EducationFilterfieldsObj);
    };
    const handleSearchInput = (e) => {
        setSearchData(e.target.value);
    };
    const onSelect = (key) => key && setCurrentTab(`${key}`);
    return !selectedItem ? (React.createElement(Box, { className: classNames(golbalcss.MuiPaperAllSectionBox), fullWidth: true },
        React.createElement(Paper, { className: golbalcss.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement("div", { className: classNames(golbalcss.sectionHeader) },
                React.createElement(Typography, { variant: "h1", className: 'pageTitle', component: "h1" }, t("education")),
                React.createElement("div", { className: classNames(golbalcss.headerRight) },
                    isAdmin == true ? React.createElement(React.Fragment, null,
                        React.createElement(Button, { onClick: newEducation, className: classNames(s.btnPrimaryButtonAdd) },
                            "+",
                            t("newEducation"))) : null,
                    React.createElement(FormControl, { className: classNames(golbalcss.searchwidth) },
                        React.createElement(OutlinedInput, { className: golbalcss.searchInput, onChange: (e) => handleSearchInput(e), placeholder: t("search") })),
                    React.createElement(Tooltip, { className: golbalcss.FilterIconSearch, title: t('filterlist') },
                        React.createElement(IconButton, { onClick: handleIconClick },
                            React.createElement(FilterAltIcon, null))),
                    isPopupOpen && React.createElement(EducationFilter, { onClose: handleClosePopup, holdDataFilter: holdDataFilter, setFields: setFields }))),
            React.createElement("div", { className: s.navWrapper },
                React.createElement("div", { className: s.navBar },
                    " ",
                    menuItems,
                    " ")),
            React.createElement("div", { className: classNames(golbalcss.bgGreen) },
                React.createElement(PageBody, { withTabs: true, style: classNames(s.pageBody) }, renderTab()))))) : selectedItem.itemMimeType === "Webm" ? (React.createElement(EducationDetails, { setEducationItem: setSelectedItem, backAction: () => setSelectedItem(null), item: selectedItem })) : selectedItem.itemMimeType === "Pdf" ? (React.createElement(EducationDetails, { setEducationItem: setSelectedItem, backAction: () => setSelectedItem(null), item: selectedItem })) : selectedItem.itemMimeType === "Web" ? (React.createElement(EducationDetails, { setEducationItem: setSelectedItem, backAction: () => setSelectedItem(null), item: selectedItem })) : null;
};
const mapStateToProps = (state) => {
    return {
        requestStatus: state.patientEducation.requestStatus,
        error: state.patientEducation.error,
        isAdmin: state.account.isAdmin,
    };
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(EducationPage);
