import React from 'react';
import SVG from 'react-inlinesvg';
import classNames from 'classnames';
import Close from '../../../resources/icons/closeWhite.svg';
import Pdf from '../../../resources/icons/pdf.svg';
import s from './style.scss';
const CloseIcon = ({ onClick }) => {
    return React.createElement("div", { className: classNames(s.removeButton), onClick: onClick },
        React.createElement(SVG, { className: s.closeIcon, src: Close }));
};
const ImageCard = ({ style, url, name, removeFile, onClick, extension }) => {
    return React.createElement("div", { className: classNames(s.card, style) },
        React.createElement(CloseIcon, { onClick: removeFile }),
        React.createElement("div", { className: s.content, onClick: onClick }, extension?.toUpperCase() == '.PDF'
            ? React.createElement(React.Fragment, null,
                " ",
                React.createElement(SVG, { src: Pdf }),
                " ",
                React.createElement("p", null, name),
                " ")
            : React.createElement("img", { className: s.img, src: url })),
        React.createElement("div", null));
};
export default ImageCard;
