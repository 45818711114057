import React, { useState } from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { Button } from "../../components/Shared/Controls";
import PageBody from "../../components/Shared/PageBody";
import CaregiverImg from "../../resources/images/caregiver.svg";
import { getRegistrationInfoAsyncAction, getCurrentUserSelector, } from "../../store";
import AddMember from "../AddMember";
import s from "./style.scss";
import { useTranslation } from "react-i18next";
import { Box } from "../../components";
const Caregiver = ({ patients, currentUser, getRegistrationInfo }) => {
    const { t } = useTranslation();
    const [enroll, setEnroll] = useState(false);
    return (React.createElement(React.Fragment, null, enroll ? (React.createElement(AddMember, { caregiver: () => setEnroll(false) })) : (React.createElement(Box, { fullWidth: true },
        React.createElement(PageBody, { noTitle: true, style: s.pageBody },
            React.createElement("div", { className: s.scrollWrapper },
                React.createElement("div", { className: classNames(s.caregiver) },
                    React.createElement(SVG, { src: CaregiverImg, className: s.caregiverImg }),
                    React.createElement("h1", null, `${t("hello")} ${currentUser?.firstName}!`),
                    React.createElement("p", null, t("shortIntroTextForOwner")),
                    React.createElement("div", { className: s.buttonsWrapper },
                        React.createElement(Button, { style: s.button, primary: true, text: t("enrollYourself"), onClick: () => setEnroll(true) }),
                        React.createElement(Button, { onClick: () => {
                                getRegistrationInfo({
                                    member: {
                                        patientId: patients.filter((patient) => patient.id !== currentUser?.id)[0].id,
                                        drugBrandCode: "",
                                    },
                                    isRegistrationFlow: false,
                                });
                            }, text: t("switchBack") })))))))));
};
const mapStateToProps = (state) => {
    return {
        patients: state.account.patients,
        currentUser: getCurrentUserSelector(state),
        requestRegistrationInfoStatus: state.account.requestRegistrationInfoStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getRegistrationInfo: (payload) => dispatch(getRegistrationInfoAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Caregiver);
