import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { Button, InputText } from "../../../components/Shared/Controls";
import { useInput } from "../../../hooks";
import { requestSmsCodeForResetPassAsyncAction, resetRequestSmsCodeForResetPassStatusAction, resetRequestValidateCodeForResetPassStatusAction, validateSmsCodeForResetPassAsyncAction, } from "../../../store";
import s from "../style.scss";
import si from "../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import moment from "moment";
import { createInviteChangePhoneNumberRequestInfoAsyncAction, createInvitePhoneNumberVarificationInfoAsyncAction } from "../../../store/Invite/Sagas";
import { resetphoneNumberVarificationAction, setVarificationCode } from "../../../store/Invite";
import LoadingPopUp from "../../../components/Shared/LoadingPopUp";
const Step2 = ({ header, setStep, validateCode, userPhone, requestSmsCodeError, requestSmsCodeStatus, validateSmsError, validateSmsStatus, requestSmsCode, resetRequestSmsCodeStatus, resetValidateCodeStatus, previousStep, isNewSignUp, setInfo, PhoneNumberVarificationAction, resetphoneNumberVarificationStatus, requestInvitePhoneNumberVarificationPostStatus, InviteChangePhoneNumberRequestInfoAsyncAction, requestInviteChangePhoneNumberPostStatus, setVarificationCodeAction, varificationCodeState }) => {
    const { t } = useTranslation();
    const [verificationCode, setVerificationCode, errorVerificationCode, setErrorVerificationCode,] = useInput(varificationCodeState?.data?.verificationCode);
    const [resent, setResent] = useState(false);
    const [disableResend, setDisableResend] = useState(false);
    const sendCode = () => {
        if (isNewSignUp) {
            const data = {
                accountId: setInfo.accountId,
                inviteCode: setInfo.inviteCode,
                lastName: setInfo.lastName,
                birthdate: moment(setInfo.birthdate).format('YYYY-MM-DD'),
                phoneNumber: setInfo.phoneNumber,
                validationCode: verificationCode
            };
            PhoneNumberVarificationAction(data);
        }
        else {
            resetValidateCodeStatus();
            validateCode(verificationCode);
        }
    };
    const resendCode = () => {
        if (isNewSignUp) {
            const data = {
                accountId: setInfo.accountId,
                inviteCode: setInfo.inviteCode,
                lastName: setInfo.lastName,
                birthdate: moment(setInfo.birthdate).format('YYYY-MM-DD'),
                phoneNumber: setInfo.phoneNumber
            };
            InviteChangePhoneNumberRequestInfoAsyncAction(data);
        }
        else {
            resetRequestSmsCodeStatus();
            setResent(false);
            if (userPhone) {
                requestSmsCode(userPhone);
                setDisableResend(true);
                setTimeout(() => setDisableResend(false), 30000);
            }
        }
    };
    useEffect(() => {
        if (requestInvitePhoneNumberVarificationPostStatus == "success" && isNewSignUp) {
            const holdData = {
                verificationCode: verificationCode
            };
            setVarificationCodeAction(holdData);
            resetphoneNumberVarificationStatus();
            setStep(4);
        }
    }, [requestInvitePhoneNumberVarificationPostStatus]);
    useEffect(() => {
        if (isNewSignUp) {
            resetRequestSmsCodeStatus();
            const holdData = {
                verificationCode: ''
            };
            setVarificationCodeAction(holdData);
        }
    }, []);
    useEffect(() => {
        if (validateSmsStatus === "success") {
            resetRequestSmsCodeStatus();
            resetValidateCodeStatus();
            if (isNewSignUp) {
                setStep(4);
            }
            else {
                setStep(3);
            }
        }
    }, [validateSmsStatus]);
    useEffect(() => {
        requestSmsCodeStatus === "success" && setResent(true);
    }, [requestSmsCodeStatus]);
    return (React.createElement(React.Fragment, null,
        header,
        React.createElement("div", { className: classNames(isNewSignUp ? si.contentWrapper : null) },
            React.createElement("div", { className: classNames(isNewSignUp ? si.scrollWrapperfrom : null) },
                React.createElement("div", { className: classNames(isNewSignUp ? si.formstep : s.form, isNewSignUp ? si.formWrapper : si.formWrapper) },
                    React.createElement("div", { className: classNames(isNewSignUp ? si.formstepbar : s.contentWrapper, isNewSignUp ? null : s.scrollWrapper) },
                        React.createElement("div", { className: (si.stepToptext) },
                            isNewSignUp ? React.createElement("h4", null, t("inviteHeader")) : React.createElement("h1", null, t("resetPassword")),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null, isNewSignUp ? t("inviteSubHeader") : t("enterSmsCodeLabel")))),
                        React.createElement("div", { className: s.contentInput },
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, isNewSignUp ? t("verificationCode") : t("smsCode")),
                                React.createElement(InputText, { name: "smsCode", value: verificationCode, setValue: setVerificationCode, error: errorVerificationCode, setError: setErrorVerificationCode, length: 4, isNumber: true, tabIndex: 1, onFocus: true })),
                            React.createElement("div", { className: si.buttonTwopage },
                                React.createElement(Button, { disabled: disableResend, primary: false, text: t("resend"), onClick: resendCode })),
                            resent && disableResend && React.createElement("div", null, t("resendMessage")),
                            React.createElement("div", { className: si.buttonTwopage },
                                React.createElement(Button, { text: t("back"), onClick: previousStep }),
                                React.createElement(Button, { style: s.mb0_button, disabled: !verificationCode ||
                                        errorVerificationCode ||
                                        verificationCode.length < 4, primary: true, text: t("continue"), onClick: sendCode }))))))),
        requestInvitePhoneNumberVarificationPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestInviteChangePhoneNumberPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        requestSmsCodeError: state.forgotPassword.requestSmsCodeForResetPassError,
        requestSmsCodeStatus: state.forgotPassword.requestSmsCodeForResetPassStatus,
        validateSmsError: state.forgotPassword.requestValidateSmsCodeError,
        validateSmsStatus: state.forgotPassword.requestValidateSmsCodeStatus,
        userPhone: state.forgotPassword.phoneNumber,
        setInfo: state.invite.setInfo,
        varification: state.invite.responseOfInvitePhoneNumberVarification,
        requestInvitePhoneNumberVarificationPostStatus: state.invite.requestInvitePhoneNumberVarificationPostStatus,
        requestInviteChangePhoneNumberPostStatus: state.invite.requestInviteChangePhoneNumberPostStatus,
        varificationCodeState: state.invite.varificationCode
    };
};
function mapDispatchToProps(dispatch) {
    return {
        validateCode: (code) => dispatch(validateSmsCodeForResetPassAsyncAction(code)),
        requestSmsCode: (phoneNumber) => dispatch(requestSmsCodeForResetPassAsyncAction(phoneNumber)),
        resetRequestSmsCodeStatus: () => dispatch(resetRequestSmsCodeForResetPassStatusAction()),
        resetValidateCodeStatus: () => dispatch(resetRequestValidateCodeForResetPassStatusAction()),
        PhoneNumberVarificationAction: (data) => dispatch(createInvitePhoneNumberVarificationInfoAsyncAction({ data })),
        resetphoneNumberVarificationStatus: () => dispatch(resetphoneNumberVarificationAction()),
        InviteChangePhoneNumberRequestInfoAsyncAction: (data) => dispatch(createInviteChangePhoneNumberRequestInfoAsyncAction({ data })),
        setVarificationCodeAction: (data) => dispatch(setVarificationCode({ data })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Step2);
