import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "ACCOUNT";
const initialState = {
    user: {
        drugBrandCode: "",
        patientId: "",
        phoneNumber: "",
        email: "",
        province: "",
        password: undefined,
        impersonatedAccountId: ""
    },
    patient: {
        firstName: "",
        lastName: "",
        gender: undefined,
        birthDate: ""
    },
    member: {
        patientId: "",
        drugBrandCode: "",
    },
    healthInformation: {
        din: "",
        medicalConditions: [],
        drugBrandCode: ""
    },
    patientBrands: [],
    patients: [],
    drugBrandsCodes: [],
    brandPhoneNumber: "",
    brandEmail: "",
    registrationUnfinished: false,
    currentProgramWebsite: null,
    currentDrugBrandPrescriberName: undefined,
    currentProgramLogo: undefined,
    requestHomeStatus: "idle",
    requestHomeError: null,
    requestRegistrationInfoStatus: "idle",
    requestRegistrationInfoError: null,
    requestSettingsInfoStatus: "idle",
    requestSettingsInfoError: null,
    requestPatientInfoStatus: "idle",
    requestPatientInfoError: null,
    requestUpdatePatientInfoStatus: "idle",
    requestUpdatePatientInfoError: null,
    requestPatientBrandsStatus: "idle",
    requestPatientBrandsError: null,
    requestAssignToBrandStatus: "idle",
    requestAssignToBrandError: null,
    requestUpdateEmailStatus: "idle",
    requestUpdateEmailError: null,
    requestUpdateBrandEnrollmentStatus: "idle",
    requestUpdateBrandEnrollmentError: null,
    requestEnrollByOwnerStatus: "idle",
    requestEnrollByOwnerError: null,
    requestPatientHealthInformationStatus: "idle",
    requestPatientHealthInformationError: null,
    requestUpdateHealthInformationStatus: "idle",
    requestUpdateHealthInformationError: null,
    isAdmin: null,
    selectedpatient: null,
    setPatientData: undefined,
    requestSinglePatientInfoStatus: "idle",
    requestSinglePatientInfoError: null,
    singlePatient: undefined,
    exitingPatientId: undefined,
    exitingImpersonatedAccountId: undefined,
    ExistingphoneNumber: undefined,
    ExistingfirstName: undefined,
    ExistinglastName: undefined,
    existingEmailImersonate: undefined,
    InviteLoginStoredData: undefined,
    LengthStepsToComplete: null,
    allDataImpersonate: undefined,
    adminAzureIdImpersonateMode: "",
    patientAzureIdImpersonateMode: ""
};
const accountSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        setUserAction: (state, action) => {
            state.user = {
                ...state.user,
                ...action.payload.user,
            };
            state.drugBrandsCodes = action.payload.drugBrandsCodes;
            state.brandPhoneNumber = action.payload.brandPhoneNumber;
            state.brandEmail = action.payload.brandEmail;
            state.registrationUnfinished = action.payload.registrationUnfinished;
            state.currentProgramLogo = action.payload.currentProgramLogo;
            state.currentProgramWebsite = action.payload.currentProgramWebsite;
            state.currentDrugBrandPrescriberName =
                action.payload.currentDrugBrandPrescriberName;
            state.InviteLoginStoredData = action.payload.user;
        },
        setUserPhoneAction: (state, action) => {
            state.user.phoneNumber = action.payload;
        },
        startRequestHomeAction: (state) => {
            state.requestHomeStatus = "pending";
            state.requestHomeError = null;
        },
        successRequestHomeAction: (state, action) => {
            state.requestHomeStatus = "success";
            state.requestHomeError = null;
            state.patients = action.payload;
        },
        isAdminAction: (state, action) => {
            state.isAdmin = action.payload; //home api response 
        },
        setisAdminStatus: (state, action) => {
            state.isAdmin = action.payload;
        },
        checkAdminStatus: (state) => {
            if (state.selectedpatient?.patientId && state.isAdmin == true) {
                state.isAdmin = false;
            }
        },
        setPatientData: (state, action) => {
            state.selectedpatient = action.payload;
        },
        showErrorRequestHomeAction: (state, action) => {
            state.requestHomeStatus = "failure";
            state.requestHomeError = action.payload;
        },
        startRequestRegistrationInfoAction: (state) => {
            state.requestRegistrationInfoStatus = "pending";
            state.requestRegistrationInfoError = null;
        },
        successRequestRegistrationInfoAction: (state) => {
            state.requestRegistrationInfoStatus = "success";
            state.requestRegistrationInfoError = null;
        },
        showRequestRegistrationInfoErrorAction: (state, action) => {
            state.requestRegistrationInfoStatus = "failure";
            state.requestRegistrationInfoError = action.payload;
        },
        resetRequestRegistrationInfoAction: (state) => {
            state.requestRegistrationInfoStatus = "idle";
            state.requestRegistrationInfoError = null;
        },
        startRequestSettingsInfoAction: (state) => {
            state.requestSettingsInfoStatus = "pending";
            state.requestSettingsInfoError = null;
        },
        successRequestSettingsInfoAction: (state, action) => {
            state.requestSettingsInfoStatus = "success";
            state.requestSettingsInfoError = null;
            state.user.email = action.payload;
        },
        showRequestSettingsInfoErrorAction: (state, action) => {
            state.requestSettingsInfoStatus = "failure";
            state.requestSettingsInfoError = action.payload;
        },
        resetRequestSettingsInfoAction: (state) => {
            state.requestSettingsInfoStatus = "idle";
            state.requestSettingsInfoError = null;
        },
        startRequestPatientInfoAction: (state) => {
            state.requestPatientInfoStatus = "pending";
            state.requestPatientInfoError = null;
        },
        successRequestPatientInfoAction: (state, action) => {
            state.requestPatientInfoStatus = "success";
            state.requestPatientInfoError = null;
            state.patient = action.payload;
        },
        showRequestPatientInfoErrorAction: (state, action) => {
            state.requestPatientInfoStatus = "failure";
            state.requestPatientInfoError = action.payload;
        },
        //Single record
        startRequestSinglePatientInfoAction: (state) => {
            state.requestSinglePatientInfoStatus = "pending";
            state.requestSinglePatientInfoError = null;
        },
        successRequestSinglePatientInfoAction: (state, action) => {
            state.requestSinglePatientInfoStatus = "success";
            state.requestSinglePatientInfoError = null;
            state.singlePatient = action.payload;
        },
        showRequestSinglePatientInfoErrorAction: (state, action) => {
            state.requestSinglePatientInfoStatus = "failure";
            state.requestSinglePatientInfoError = action.payload;
        },
        resetRequestPatientInfoAction: (state) => {
            state.requestPatientInfoStatus = "idle";
            state.requestPatientInfoError = null;
        },
        startRequestUpdatePatientInfoAction: (state) => {
            state.requestUpdatePatientInfoStatus = "pending";
            state.requestUpdatePatientInfoError = null;
        },
        successRequestUpdatePatientInfoAction: (state, action) => {
            state.requestUpdatePatientInfoStatus = "success";
            state.requestUpdatePatientInfoError = null;
            state.patient = action.payload;
        },
        showRequestUpdatePatientInfoErrorAction: (state, action) => {
            state.requestUpdatePatientInfoStatus = "failure";
            state.requestUpdatePatientInfoError = action.payload;
        },
        resetRequestUpdatePatientInfoAction: (state) => {
            state.requestUpdatePatientInfoStatus = "idle";
            state.requestUpdatePatientInfoError = null;
        },
        setMemberDrugBrandCodeAction: (state, action) => {
            state.member.drugBrandCode = action.payload;
        },
        resetMemberDrugBrandCodeAction: (state) => {
            state.member.drugBrandCode = "";
        },
        setMemberIdAction: (state, action) => {
            state.member.patientId = action.payload;
        },
        startRequestPatientBrandsAction: (state) => {
            state.requestPatientBrandsStatus = "pending";
            state.requestPatientBrandsError = null;
        },
        successRequestPatientBrandsAction: (state, action) => {
            state.requestPatientBrandsStatus = "success";
            state.requestPatientBrandsError = null;
            state.patientBrands = action.payload;
        },
        showRequestPatientBrandsErrorAction: (state, action) => {
            state.requestPatientBrandsStatus = "failure";
            state.requestPatientBrandsError = action.payload;
        },
        resetRequestPatientBrandsAction: (state) => {
            state.requestPatientBrandsStatus = "idle";
            state.requestPatientBrandsError = null;
        },
        startRequestAssignToBrandAction: (state) => {
            state.requestAssignToBrandStatus = "pending";
            state.requestAssignToBrandError = null;
        },
        successRequestAssignToBrandAction: (state) => {
            state.requestAssignToBrandStatus = "success";
            state.requestAssignToBrandError = null;
        },
        showRequestAssignToBrandErrorAction: (state, action) => {
            state.requestAssignToBrandStatus = "failure";
            state.requestAssignToBrandError = action.payload;
        },
        resetRequestAssignToBrandAction: (state) => {
            state.requestAssignToBrandStatus = "idle";
            state.requestAssignToBrandError = null;
        },
        startRequestEnrollByOwnerAction: (state) => {
            state.requestEnrollByOwnerStatus = "pending";
            state.requestEnrollByOwnerError = null;
        },
        successRequestEnrollByOwnerAction: (state) => {
            state.requestEnrollByOwnerStatus = "success";
            state.requestEnrollByOwnerError = null;
        },
        showRequestEnrollByOwnerErrorAction: (state, action) => {
            state.requestEnrollByOwnerStatus = "failure";
            state.requestEnrollByOwnerError = action.payload;
        },
        resetRequestEnrollByOwnerAction: (state) => {
            state.requestEnrollByOwnerStatus = "idle";
            state.requestEnrollByOwnerError = null;
        },
        setUserDrugBrandCodeAction: (state, action) => {
            state.user.drugBrandCode = action.payload;
        },
        startRequestUpdateEmailAction: (state) => {
            state.requestUpdateEmailStatus = "pending";
            state.requestUpdateEmailError = null;
        },
        successRequestUpdateEmailAction: (state, action) => {
            state.requestUpdateEmailStatus = "success";
            state.requestUpdateEmailError = null;
            state.user.email = action.payload;
        },
        showRequestUpdateEmailErrorAction: (state, action) => {
            state.requestUpdateEmailStatus = "failure";
            state.requestUpdateEmailError = action.payload;
        },
        resetRequestUpdateEmailAction: (state) => {
            state.requestUpdateEmailStatus = "idle";
            state.requestUpdateEmailError = null;
        },
        startRequestUpdateBrandEnrollment: (state) => {
            state.requestUpdateBrandEnrollmentStatus = "pending";
            state.requestUpdateBrandEnrollmentError = null;
        },
        successRequestUpdateBrandEnrollment: (state, action) => {
            state.requestUpdateBrandEnrollmentStatus = "success";
            state.requestUpdateBrandEnrollmentError = null;
        },
        errorRequestUpdateBrandEnrollment: (state, action) => {
            state.requestUpdateBrandEnrollmentStatus = "failure";
            state.requestUpdateBrandEnrollmentError = action.payload;
        },
        resetUpdateBrandEnrollmentAction: (state) => {
            state.requestUpdateBrandEnrollmentStatus = "idle";
            state.requestUpdateBrandEnrollmentError = null;
        },
        startRequestPatientHealthInformation: (state) => {
            state.requestPatientHealthInformationStatus = "pending";
            state.requestPatientHealthInformationError = null;
        },
        successRequestPatientHealthInformation: (state, action) => {
            state.requestPatientHealthInformationStatus = "success";
            state.requestPatientHealthInformationError = null;
            state.healthInformation = action.payload;
        },
        errorRequestPatientHealthInformation: (state, action) => {
            state.requestPatientHealthInformationStatus = "failure";
            state.requestPatientHealthInformationError = action.payload;
        },
        resetRequestPatientHealthInformation: (state) => {
            state.requestPatientHealthInformationStatus = "idle";
            state.requestPatientHealthInformationError = null;
        },
        startRequestUpdateHealthInformationAction: (state) => {
            state.requestUpdateHealthInformationStatus = "pending";
            state.requestUpdateHealthInformationError = null;
        },
        successRequestUpdateHealthInformationAction: (state, action) => {
            state.requestUpdateHealthInformationStatus = "success";
            state.requestUpdateHealthInformationError = null;
            state.healthInformation = action.payload;
        },
        showRequestUpdateHealthInformationErrorAction: (state, action) => {
            state.requestUpdateHealthInformationStatus = "failure";
            state.requestUpdateHealthInformationError = action.payload;
        },
        resetRequestUpdateHealthInformationAction: (state) => {
            state.requestUpdateHealthInformationStatus = "idle";
            state.requestUpdateHealthInformationError = null;
        },
        resetAllStatuses: (state) => {
            state.requestHomeStatus = "idle";
            state.requestHomeError = null;
            state.requestRegistrationInfoStatus = "idle";
            state.requestRegistrationInfoError = null;
            state.requestSettingsInfoStatus = "idle";
            state.requestSettingsInfoError = null;
            state.requestPatientInfoStatus = "idle";
            state.requestPatientInfoError = null;
            state.requestUpdatePatientInfoStatus = "idle";
            state.requestUpdatePatientInfoError = null;
            state.requestPatientBrandsStatus = "idle";
            state.requestPatientBrandsError = null;
            state.requestAssignToBrandStatus = "idle";
            state.requestAssignToBrandError = null;
            state.requestUpdateEmailStatus = "idle";
            state.requestUpdateEmailError = null;
            state.requestUpdateBrandEnrollmentStatus = "idle";
            state.requestUpdateBrandEnrollmentError = null;
            state.requestEnrollByOwnerStatus = "idle";
            state.requestEnrollByOwnerError = null;
            state.requestPatientHealthInformationStatus = "idle";
            state.requestPatientHealthInformationError = null;
            state.requestUpdateHealthInformationStatus = "idle";
            state.requestUpdateHealthInformationError = null;
        },
        setPatientIdForImpersonate: (state, action) => {
            state.patients[0].id = action.payload;
            state.user.patientId = action.payload;
        },
        setExistingImpersonate: (state, action) => {
            state.exitingPatientId = action.payload;
        },
        setImpersonatedAccountId: (state, action) => {
            state.user.impersonatedAccountId = action.payload;
        },
        setExistingImpersonatedAccountId: (state, action) => {
            state.exitingImpersonatedAccountId = action.payload;
        },
        setImpersonateInfo: (state, action) => {
            state.user.phoneNumber = action.payload.phoneNumber;
            state.user.drugBrandCode = action.payload.drugBrandCode;
            state.patients[0].firstName = action.payload.firstName;
            state.patients[0].lastName = action.payload.lastName;
            state.existingEmailImersonate = action.payload.email;
        },
        setExistingImpersonateInfo: (state, action) => {
            state.ExistingphoneNumber = action.payload.phoneNumber;
            state.ExistingfirstName = action.payload.firstName;
            state.ExistinglastName = action.payload.lastName;
            state.user.email = action.payload.email;
        },
        resetStoreDataForInviteSignupAction: (state) => {
            state.InviteLoginStoredData = null;
        },
        setAlldataImpersonate: (state, action) => {
            state.allDataImpersonate = action.payload;
        },
        setAdminAzureIdImpersonateAction: (state, action) => {
            state.adminAzureIdImpersonateMode = action.payload;
        },
        setPaientAzureIdImpersonateAction: (state, action) => {
            state.patientAzureIdImpersonateMode = action.payload;
        }
    },
});
export default accountSlice;
