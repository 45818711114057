import { useEffect, useState } from "react";
const useErrorPopUp = (error) => {
    const [content, setContent] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const togglePopUp = () => setIsOpen(s => !s);
    const showPopUp = () => setIsOpen(true);
    const hidePopUp = () => setIsOpen(false);
    useEffect(() => {
        if (error !== undefined) {
            !error && hidePopUp();
            setContent(error?.message || "");
            error?.message && showPopUp();
        }
    }, [error]);
    return [isOpen, setIsOpen, content, setContent, togglePopUp, showPopUp, hidePopUp];
};
export default useErrorPopUp;
