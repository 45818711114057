import { createSelector } from "reselect";
import { brandsSelectors } from "./Slice";
export const brandsBySearchKeySelector = (state, searchValue) => createSelector(brandsSelectors.selectAll, (brands) => {
    return brands.filter((e) => e.drugBrandCode.toLowerCase().includes(searchValue.toLowerCase()));
})(state.brands);
export const authBrandByIdSelector = (state) => brandsSelectors.selectById(state.brands, state.auth.brandId);
export const userBrandByIdSelector = (state) => {
    const brand = brandsSelectors.selectById(state.brands, state.account.user.drugBrandCode);
    if (brand)
        return brand;
    else {
        const brand = state.account.patientBrands.find(brand => brand.drugBrandCode === state.account.user.drugBrandCode);
        return brand && {
            drugBrandCode: brand?.drugBrandCode,
            base64Logo: brand?.logoLink
        };
    }
};
