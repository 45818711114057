import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { Button } from "../../../../../components/Shared/Controls";
import { useInput } from "../../../../../hooks";
import s from "../style.scss";
import moment from "moment";
import { createInviteChangePhoneNumberRequestInfoAsyncAction } from "../../../../../store/Invite/Sagas";
import { setMobileNumber, setResponseOfInviteChangePhoneNumberFalse } from "../../../../../store/Invite";
import LoadingPopUp from "../../../../../components/Shared/LoadingPopUp";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import colors from "../../../../../resources/colors";
const InvitationTwoFactorEnrollment = ({ header, setStep, previousStep, setInfo, InviteChangePhoneNumberRequestInfoAsyncAction, resetInviteChangePhoneNumberStatus, requestInviteChangePhoneNumberPostStatus, mobileNumberState, setMobileNumberAction, responseOfInvitePhoneNumber }) => {
    const { t } = useTranslation();
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput(mobileNumberState?.data?.phoneNumber);
    const [nationalNumber, setNationalNumber] = React.useState('');
    const allFieldsValid = phoneNumber && !errorPhoneNumber;
    const onChangePhoneNumber = (phoneNum, info) => {
        setPhoneNumber(phoneNum);
        setNationalNumber(info.nationalNumber ?? '');
        setErrorPhoneNumber(!matchIsValidTel(phoneNum));
    };
    useEffect(() => {
        if (responseOfInvitePhoneNumber && !mobileNumberState?.data?.phoneNumber) {
            const newPhoneNumber = responseOfInvitePhoneNumber.slice(1);
            setPhoneNumber(newPhoneNumber);
            setNationalNumber(newPhoneNumber);
        }
        else {
            setPhoneNumber(mobileNumberState?.data?.phoneNumber);
            setNationalNumber(mobileNumberState?.data?.phoneNumber);
        }
    }, [responseOfInvitePhoneNumber]);
    function onSubmit() {
        const data = {
            accountId: setInfo.accountId,
            inviteCode: setInfo.inviteCode,
            lastName: setInfo.lastName,
            birthdate: moment(setInfo.birthdate).format('YYYY-MM-DD'),
            phoneNumber: nationalNumber
        };
        const holdData = {
            phoneNumber: nationalNumber
        };
        setMobileNumberAction(holdData);
        InviteChangePhoneNumberRequestInfoAsyncAction(data);
    }
    useEffect(() => {
        if (requestInviteChangePhoneNumberPostStatus === "success") {
            const holdData = {
                phoneNumber: phoneNumber
            };
            setMobileNumberAction(holdData);
            resetInviteChangePhoneNumberStatus();
            setStep(3);
        }
    }, [requestInviteChangePhoneNumberPostStatus]);
    useEffect(() => {
        const holdData = {
            phoneNumber: ''
        };
        setMobileNumberAction(holdData);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(s.contentWrapperBelowHeader) },
            React.createElement("div", { className: classNames(s.scrollWrapperfrom) },
                React.createElement("div", { className: classNames(s.formstep, s.formWrapper) },
                    React.createElement("div", { className: classNames(s.formstepbar) },
                        React.createElement("div", { className: (s.stepToptext) },
                            header,
                            React.createElement("h4", null, t("inviteHeader")),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null, t("mobileNumberSubHeader")))),
                        React.createElement("div", { className: s.contentInput },
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null, t("phoneNumber")),
                                React.createElement(MuiTelInput, { value: phoneNumber, onChange: onChangePhoneNumber, error: !!errorPhoneNumber, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 1 })),
                            React.createElement("div", { className: classNames(s.textCenter, s.mt20) },
                                React.createElement("h3", { onClick: () => setStep(4), className: s.linktwo },
                                    " ",
                                    t("Skipthisstep"),
                                    " ")),
                            React.createElement("div", { className: s.buttonTwopage },
                                React.createElement(Button, { text: t("back"), onClick: previousStep }),
                                React.createElement(Button, { style: s.mb0_button, disabled: !allFieldsValid, primary: true, text: t("continue"), onClick: onSubmit }))))))),
        requestInviteChangePhoneNumberPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        setInfo: state.invite.setInfo,
        responseOfInviteChangePhoneNumber: state.invite.responseOfInviteChangePhoneNumber,
        requestInviteChangePhoneNumberPostStatus: state.invite.requestInviteChangePhoneNumberPostStatus,
        mobileNumberState: state.invite.mobileNumber,
        responseOfInvitePhoneNumber: state.invite.responseOfInvitePhoneNumber
    };
};
function mapDispatchToProps(dispatch) {
    return {
        InviteChangePhoneNumberRequestInfoAsyncAction: (data) => dispatch(createInviteChangePhoneNumberRequestInfoAsyncAction({ data })),
        resetInviteChangePhoneNumberStatus: () => dispatch(setResponseOfInviteChangePhoneNumberFalse()),
        setMobileNumberAction: (data) => dispatch(setMobileNumber({ data })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(InvitationTwoFactorEnrollment);
