import Cookies from "js-cookie";
import { put } from "redux-saga/effects";
import config from "../../config.json";
import { showUserErrorAction } from "./error";
var programPhoneNumber = '';
switch (config.applicationType) {
    case "Amgen":
    default:
        programPhoneNumber = '1-888-304-2034';
        break;
}
export const toPascalCase = (string) => {
    return `${string}`
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(new RegExp(/\s+(.)(\w*)/, 'g'), ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
        .replace(new RegExp(/\w/), s => s.toUpperCase());
};
export const showErrorAction = (state, action) => {
    state.requestError = action.payload;
    state.requestStatus = "failure";
};
export const hideErrorAction = (state) => {
    state.requestError = null;
    state.requestStatus = "idle";
};
export const startRequestAction = (state) => {
    state.requestStatus = "pending";
};
export function* handleApiError(error, actionCreator, showUserError = true) {
    console.log(error);
    if (error.statusCode > 400 && error.statusCode < 500) {
        if (error.statusCode === 401 && Cookies.get("token")) {
            Cookies.remove("token");
            window.location.href = "/logout";
        }
        if (showUserError)
            yield put(showUserErrorAction({
                code: error.statusCode,
                message: error.data.messages && error.data.messages.join(`\n`)
                    || localStorage.getItem("i18nextLng") === "en"
                        && "Username or password is incorrect. If don't remember your password please click forgot password."
                    || "L'identifiant ou le mot de passe est incorrect. Si vous ne vous souvenez pas de votre mot de passe, veuillez cliquer sur mot de passe oublié"
            }));
        yield put(actionCreator({
            code: error.statusCode,
            message: error.data.messages && error.data.messages.join(`\n`)
                || localStorage.getItem("i18nextLng") === "en"
                    && `Oops, something went wrong. Please try again or call ${programPhoneNumber} for help. (015)`
                || `Oops, quelque chose a mal fonctionné. Veuillez appeler le ${programPhoneNumber} pour obtenir de l'aide. (015)`
        }));
    }
    else if (error.statusCode === undefined) {
        if (error) {
            if (showUserError)
                yield put(showUserErrorAction({
                    code: error.statusCode || "undefined",
                    message: localStorage.getItem("i18nextLng") === "en"
                        && `Oops, an unexpected error has occured. Please call ${programPhoneNumber} for help. (015)` || "Problème de connexion internet"
                }));
            yield put(actionCreator({
                code: error.statusCode || "undefined",
                message: localStorage.getItem("i18nextLng") === "en"
                    && `Oops, something went wrong. Please wait and try again. If the issue persists, please call ${programPhoneNumber} for help. (015)`
                    || `Oops, quelque chose a mal fonctionné. Veuillez appeler le ${programPhoneNumber} pour obtenir de l'aide. (015)`
            }));
        }
    }
    else {
        if (showUserError)
            yield put(showUserErrorAction({
                code: error.statusCode,
                message: error.data.messages && error.data.messages.join(`\n`)
                    || localStorage.getItem("i18nextLng") === "en"
                        && `Oops, something went wrong. Please wait and try again. If the issue persists, please call ${programPhoneNumber} for help. (015)`
                    || `Oops, quelque chose a mal fonctionné. Veuillez appeler le ${programPhoneNumber} pour obtenir de l'aide. (015)`
            }));
        yield put(actionCreator({
            code: error.statusCode,
            message: error.data.messages && error.data.messages.join(`\n`)
                || localStorage.getItem("i18nextLng") === "en"
                    && `Oops, something went wrong. Please wait and try again. If the issue persists, please call ${programPhoneNumber} for help. (015)`
                || `Oops, quelque chose a mal fonctionné. Veuillez appeler le ${programPhoneNumber} pour obtenir de l'aide. (015)`
        }));
    }
}
Object.defineProperty(String.prototype, 'capitalize', {
    value: function () {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});
