import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "LANGUAGE";
const initialState = {
    language: "en-CA",
    requestStatus: "idle",
    requestStatusError: null
};
const languageSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.language = action.payload;
        },
        startRequestLanguageChange: (state) => {
            state.requestStatusError = null;
            state.requestStatus = "pending";
        },
        requestLanguageChange: (state) => {
            state.requestStatusError = null;
            state.requestStatus = "success";
        },
        requestLanguageError: (state, action) => {
            state.requestStatusError = action.payload;
            state.requestStatus = "failure";
        }
    }
});
export default languageSlice;
