import React, { useState } from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageBody from "../../../../components/Shared/PageBody";
import SearchInput from "../../../../components/Shared/Controls/Inputs/SearchInput";
import { brandsBySearchKeySelector, setMemberDrugBrandCodeAction, } from "../../../../store";
import BrandCard from "../../../../components/Shared/BrandCard";
import Button from "../../../../components/Shared/Controls/Button";
import LoaderIcon from "../../../../resources/icons/icon-loader.svg";
import useBrandSearch from "../../../../hooks/useBrandSearch";
import s from "./style.scss";
const SelectBrand = ({ userBrand, brands, isTestBrands, setBrand, onSubmit, backAction, }) => {
    const { t } = useTranslation();
    const [selectedBrand, selectBrand] = useState(userBrand || "");
    const search = useBrandSearch(isTestBrands);
    const onSelect = () => {
        selectedBrand && setBrand(selectedBrand);
        onSubmit && onSubmit();
    };
    const searchBrandRender = () => {
        return (React.createElement("div", { className: classNames(s.searchForm) },
            React.createElement(SearchInput, { style: classNames(s.search, s.brandSearch), value: search.searchTerm, setValue: search.setSearchTerm })));
    };
    return (React.createElement(PageBody, { backAction: backAction, title: t("selectBrand") },
        React.createElement(React.Fragment, null,
            searchBrandRender(),
            React.createElement("div", { className: s.scrollWrapper },
                React.createElement("div", { className: classNames(s.brandList, s.flexBrandList) }, brands && brands[0] ? (brands.map((el) => (React.createElement(BrandCard, { key: el.drugBrandCode, active: selectedBrand === el.drugBrandCode, url: el.logoLink, onClick: () => selectedBrand != el.drugBrandCode
                        ? selectBrand(el.drugBrandCode)
                        : selectBrand("") })))) : (React.createElement("div", { className: s.loading }, search.searchTerm ? (React.createElement("div", { className: s.subheading_1 }, t("noResultsFound"))) : (React.createElement(SVG, { src: LoaderIcon, className: s.loaderIcon }))))),
                React.createElement("div", { className: s.clearfix })),
            React.createElement(Button, { style: classNames(s.mb0_button, s.button), disabled: !selectedBrand, primary: true, text: t("select"), onClick: onSelect }))));
};
const mapStateToProps = (state) => {
    return {
        userBrand: state.auth.brandId,
        brands: brandsBySearchKeySelector(state, state.brands.searchValue),
        isTestBrands: state.brands.isTestBrands,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setBrand: (brandId) => dispatch(setMemberDrugBrandCodeAction(brandId)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectBrand);
