import { put, select, call, takeLatest } from "redux-saga/effects";
import { AccountRepository } from "../repository";
import navigationSlice from "./Slice";
import { handleApiError } from "../utils";
import { userInfoSelector } from "../account";
import { mapProfileConfigs } from "./Mapper";
import { getProfileTabsAsyncAction } from "./actions";
import config from '../../../config.json';
export function* getProfileTabsSaga(action) {
    const { startRequestProfileTabsAction, successRequestProfileTabsAction, showRequestProfileTabsErrorAction, } = navigationSlice.actions;
    const { drugBrandCode, province } = yield select(userInfoSelector);
    try {
        yield put(startRequestProfileTabsAction());
        const result = yield call(AccountRepository.getProfileTabs, {
            drugBrandCode: action.payload || drugBrandCode,
            province,
        });
        let data = yield mapProfileConfigs(result.data);
        data.isAvailableFA = config.navigation.profileTabs[3].show;
        data.profileTabs = data.profileTabs.filter((profileTab, index) => {
            return config.navigation.profileTabs
                .some(tabConfig => {
                return tabConfig.key === profileTab.key && tabConfig.show;
            });
        });
        yield put(successRequestProfileTabsAction(data));
    }
    catch (error) {
        yield handleApiError(error, showRequestProfileTabsErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getProfileTabsAsyncAction, getProfileTabsSaga);
}
