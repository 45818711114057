import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import config from "../config.json";
import translationEN from "./resources/locales/Amgen/en/translation.json";
import translationFR from "./resources/locales/Amgen/fr/translation.json";
import i18next from "i18next";
const fallbackLng = ["en"];
const availableLanguages = ["en", "fr"];
const resources = {
    en: {
        translation: translationEN
    },
    fr: {
        translation: translationFR
    }
};
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources,
    fallbackLng,
    returnNull: false,
    detection: {
    // checkWhitelist: true
    },
    debug: false,
    // whitelist: availableLanguages,
    interpolation: {
        escapeValue: false
    },
    react: {
        bindI18nStore: 'added'
    }
}, async () => {
    const app = config.applicationType;
    const translationEN = await import(`./resources/locales/${app}/en/translation.json`);
    const translationFR = await import(`./resources/locales/${app}/fr/translation.json`);
    i18next.addResources("en", "translation", translationEN);
    i18next.addResources("fr", "translation", translationFR);
});
export default i18n;
