import moment from "moment";
import config from "../../config.json";
export const doFormat = (x, pattern) => {
    // x = x.split('').shift().join('');
    var strippedValue = x.replace(/[^0-9]/g, "");
    var chars = strippedValue.split("");
    var count = 0;
    var formatted = "";
    for (var i = 0; i < pattern.length; i++) {
        const c = pattern[i];
        if (chars[count]) {
            if (/\*/.test(c)) {
                formatted += chars[count];
                count++;
            }
            else {
                formatted += c;
            }
        }
    }
    return formatted;
};
export const dataURItoBlob = (dataURI) => {
    if (!dataURI)
        return null;
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
    else
        byteString = unescape(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
};
export const timelineLabels = (desiredStartTime, interval, period, locale) => {
    const periodsInADay = moment.duration(1, 'day').as(period);
    const timeLabels = [];
    const startTimeMoment = moment(desiredStartTime, 'hh:mm');
    for (let i = 0; i < periodsInADay; i += interval) {
        startTimeMoment.add(i === 0 ? 0 : interval, period);
        if (locale == "en") {
            timeLabels.push({
                key: startTimeMoment.format('hh:mm A'),
                displayName: startTimeMoment.format('hh:mm A'),
                isChecked: false
            });
        }
        else {
            var minutes = startTimeMoment.format('mm');
            if (minutes == "00") {
                timeLabels.push({
                    key: startTimeMoment.format('hh:mm A'),
                    displayName: startTimeMoment.format('HH') + ' h',
                    isChecked: false
                });
            }
            else {
                timeLabels.push({
                    key: startTimeMoment.format('hh:mm A'),
                    displayName: startTimeMoment.format('HH') + ' h ' + minutes,
                    isChecked: false
                });
            }
        }
    }
    return timeLabels;
};
export const ToLocalTimeZone = (date) => {
    if (date === null)
        return '';
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date((typeof date === "string" ? new Date(date) : date)
        .toLocaleString("en-US", { timeZone: timeZone }));
};
export const getCopyright = (copyright) => copyright
    .replace("COMPANY_NAME", config.company_name)
    .replace("YEAR", new Date().getFullYear().toString());
export const ParseErrorCode = (message) => {
    const regExp = /\(([^)]+)\)/;
    let code = null;
    if (message) {
        const matches = regExp.exec(message);
        code = matches && matches[1];
    }
    return { message, code };
};
export const stringIsNullOrWhitespace = (text) => {
    return text === null || text === undefined || text.trim().length === 0;
};
export default { doFormat, dataURItoBlob, timelineLabels, getCopyright, ParseErrorCode };
