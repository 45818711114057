import React, { useState } from "react";
import style from "../../style.scss";
import CreatePassword from "./CreatePassword";
import VerifyPassword from "./VerifyPassword";
const ChangePassword = ({ setPage }) => {
    const [step, setStep] = useState(1);
    const renderStepHeader = () => (React.createElement("div", { className: style.header },
        React.createElement("div", { className: style.pages }, `${step}/${2}`)));
    const renderStep = () => {
        switch (step) {
            case 1:
                return (React.createElement(VerifyPassword, { setStep: setStep, renderPaggination: renderStepHeader(), backAction: () => setPage(1) }));
            case 2:
                return (React.createElement(CreatePassword, { setPage: setPage, renderPaggination: renderStepHeader(), backAction: () => setStep(1) }));
            default:
                return null;
        }
    };
    return React.createElement("div", null, renderStep());
};
export default ChangePassword;
