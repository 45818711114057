import ApiManager from "../api/ApiManager";
const version = "1.2";
const PatientBrandPrescriber_API = `/v${version}/Patient/Brand/Prescriber`;
class PatientBrandPrescriberRepository {
    constructor() { }
    //post request
    static postPatientBrandPrescriber = (data) => {
        return ApiManager.getInstance().post(PatientBrandPrescriber_API, data);
    };
    //get request
    static getPatientBrandPrescriberyInfo = (payload) => {
        let filters = "";
        if (!!payload.patientId) {
            filters += `&patientId=${payload.patientId}`;
        }
        if (!!payload.id) {
            filters += `&id=${payload.id}`;
        }
        return ApiManager.getInstance().get(`${PatientBrandPrescriber_API}?${filters}`);
    };
    //put request
    static updatePatientBrandPrescriberInfo = (PatientBrandPrescriber) => {
        return ApiManager.getInstance().put(PatientBrandPrescriber_API, PatientBrandPrescriber);
    };
    static deletePatientBrandPrescriber = (presciberId) => {
        return ApiManager.getInstance().delete(`${PatientBrandPrescriber_API}?prescriberId=${presciberId}`);
    };
}
export default PatientBrandPrescriberRepository;
