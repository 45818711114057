import React, { useRef, useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import s from "./style.scss";
import style from "../../style.scss";
import User from "../../../../../../../resources/icons/user.svg";
import Arrow from "../../../../../../../resources/icons/chevron_down.svg";
import AddMember from "../../../../../../../resources/icons/add-member.svg";
import Settings from "../../../../../../../resources/icons/settings.svg";
import LogOut from "../../../../../../../resources/icons/log-out.svg";
import File from "../../../../../../../resources/icons/file.svg";
import { getHomeAsyncAction, getRegistrationInfoAsyncAction, resetRequestRegistrationInfoAction, } from "../../../../../../../store";
import { getCurrentUserSelector } from "../../../../../../../store/account/selectors";
import { useOnClickOutside, usePrevious } from "../../../../../../../hooks";
import { useTranslation } from "react-i18next";
import TermsOfUse from "../../../../../PopUps/TermsOfUse";
import Accessibility from "../../../../../PopUps/Accessibility";
import StatusDisplay from "../../../../../../StatusDisplay";
import { getAnalytics, setUserId } from "@firebase/analytics";
import config from "../../../../../../../../config.json";
import Typography from "../../../../../Typography";
import Box from "../../../../../Box";
import PrivacyPolicy from "../../../../../PopUps/PrivacyPolicy";
const UserMenu = ({ getHome, patientId, patients, currentUser, registrationUnfinished, getRegistrationInfo, requestRegistrationInfoStatus, requestHomeStatus, isAdmin, patientsId, userpatientsId }) => {
    const { t } = useTranslation();
    const analytics = getAnalytics();
    const prevStatus = usePrevious(requestRegistrationInfoStatus);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRef = useRef(null);
    const switcherRef = useRef(null);
    const legalRef = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [openLegalMenu, setOpenLegalMenu] = useState(false);
    const [openUserSwitcher, setOpenUserSwitcher] = useState(false);
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const LoginAPIResponse = localStorage.getItem('LoginAPIResponse');
    const UserData = JSON.parse(LoginAPIResponse);
    useEffect(() => {
        patientId && setUserId(analytics, patientId);
    }, [patientId]);
    useOnClickOutside(userRef, () => {
        if (!openUserSwitcher && !openLegalMenu) {
            setOpenMenu(false);
            openMobileMenu && setOpenMobileMenu(false);
        }
    });
    useOnClickOutside(switcherRef, () => {
        setOpenUserSwitcher(false);
    });
    useOnClickOutside(legalRef, () => {
        setOpenLegalMenu(false);
    });
    useEffect(() => {
        getHome();
    }, []);
    useEffect(() => {
        registrationUnfinished && document.location.pathname !== "/" && navigate("");
    }, [registrationUnfinished]);
    useEffect(() => {
        if (requestRegistrationInfoStatus === "success") {
            dispatch(resetRequestRegistrationInfoAction());
            setOpenMenu(false);
        }
    }, [requestRegistrationInfoStatus]);
    const renderUserMenuOpen = () => {
        return (React.createElement("div", { "data-testid": "userMenuOpened", ref: userRef, className: classNames(s.userMenu, style.openHeaderMenuWrapper) },
            React.createElement("span", null, t("member")),
            React.createElement("div", { onClick: () => config.addMemberFlow && setOpenUserSwitcher(true), className: classNames(s.userMenuSelect, {
                    [style.linkDiabled]: openUserSwitcher,
                }), "data-testid": "userSwitcherOpen" },
                React.createElement("p", null, currentUser && `${currentUser.firstName} ${currentUser.lastName}`),
                config.addMemberFlow && React.createElement(SVG, { src: Arrow, className: classNames(s.switchUserArrow, {
                        [s.switchUserArrowActive]: openUserSwitcher,
                    }) })),
            React.createElement("div", { className: s.line }),
            renderMenuItems(),
            openUserSwitcher && renderUserSwitcher()));
    };
    const renderMenuItems = () => {
        return (React.createElement(React.Fragment, null,
            config.addMemberFlow &&
                React.createElement("div", { key: AddMember }, isAdmin ? null : React.createElement(React.Fragment, null,
                    React.createElement("a", { "data-test-id": "patientSelectorMenu", onClick: () => { window.location.href = "/add-member"; }, className: classNames({
                            [style.linkDiabled]: openUserSwitcher,
                        }) },
                        React.createElement(SVG, { src: AddMember }),
                        " ",
                        t("addMembers")))),
            React.createElement("div", { key: Settings },
                React.createElement("a", { "data-test-id": "settings", onClick: () => { window.location.href = "/settings"; }, className: classNames({
                        [style.linkDiabled]: openUserSwitcher,
                    }) },
                    React.createElement(SVG, { src: Settings }),
                    " ",
                    t("settings"))),
            React.createElement("div", { className: s.legalDocumentsWrapper },
                React.createElement("div", { key: "LegalDocuments", className: s.legalDocumentsLink },
                    React.createElement("a", { "data-test-id": "legalDocuments" },
                        React.createElement(SVG, { src: File }),
                        " ",
                        t("legalDocuments"))),
                React.createElement("div", { className: s.legalDocumentsMenu },
                    React.createElement("div", { key: "termOfUse" },
                        React.createElement("a", { "data-test-id": "termsOfUse", onClick: () => setTermsOfUseIsOpen(true) },
                            React.createElement(SVG, { src: File }),
                            " ",
                            t("termOfUse"))),
                    React.createElement("div", { key: "privacyPolicy" },
                        React.createElement("a", { "data-test-id": "privacyPolicy", onClick: () => setPrivacyPolicyIsOpen(true) },
                            React.createElement(SVG, { src: File }),
                            " ",
                            t("privacyPolicy"))),
                    React.createElement("div", { key: "accessibility" },
                        React.createElement("a", { "data-test-id": "accessibility", onClick: () => setAccessibilityIsOpen(true) },
                            React.createElement(SVG, { src: File }),
                            " ",
                            t("accessibility"))))),
            React.createElement("div", { className: s.line }),
            React.createElement("div", { key: LogOut },
                React.createElement("a", { id: "logOut", onClick: () => { window.location.href = "/logout"; }, className: classNames({
                        [style.linkDiabled]: openUserSwitcher,
                    }) },
                    React.createElement(SVG, { src: LogOut }),
                    " ",
                    t("logOut")))));
    };
    const [termsOfUseIsOpen, setTermsOfUseIsOpen] = useState(false);
    const [privacyPolicyIsOpen, setPrivacyPolicyIsOpen] = useState(false);
    const [accessibilityIsOpen, setAccessibilityIsOpen] = useState(false);
    const renderUserSwitcher = () => {
        return (React.createElement("div", { "data-testid": "userSwitcherWrapper", ref: switcherRef, id: "userSwitcher", className: classNames(s.userSwitcher, style.openHeaderMenuWrapper) },
            React.createElement("span", { className: s.switcherTitle }, t("viewingAs")),
            React.createElement("ul", null, patients &&
                patients.map((patient) => (React.createElement("li", { key: patient.id, onClick: () => {
                        getRegistrationInfo({
                            member: {
                                patientId: patient.id,
                                drugBrandCode: "",
                            },
                            isRegistrationFlow: false,
                        });
                        setOpenUserSwitcher(false);
                    } }, `${patient.firstName} ${patient.lastName}`))))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StatusDisplay, null),
        React.createElement(TermsOfUse, { isOpen: termsOfUseIsOpen, setIsOpen: setTermsOfUseIsOpen }),
        React.createElement(PrivacyPolicy, { isOpen: privacyPolicyIsOpen, setIsOpen: setPrivacyPolicyIsOpen }),
        React.createElement(Accessibility, { isOpen: accessibilityIsOpen, setIsOpen: setAccessibilityIsOpen }),
        React.createElement("div", { onClick: () => setOpenMenu((prev) => !prev), className: classNames(style.headerItem, {
                [style.linkDiabled]: openMenu,
            }), "data-test-id": "userMenuWrapper" },
            React.createElement(Box, { f1: true, row: true, gap: 12 },
                React.createElement(SVG, { src: User, className: style.menuIcon }),
                React.createElement(Typography, { type: "label", color: 'neutralDark', bold: true }, isAdmin == true ? UserData?.data?.value?.azureUserInfo?.name : currentUser && `${currentUser.firstName} ${currentUser.lastName}`),
                React.createElement(SVG, { src: Arrow, className: classNames(s.userArrow, {
                        [s.userArrowActive]: openMenu,
                    }) }))),
        openMenu && renderUserMenuOpen(),
        React.createElement("div", { onClick: () => setOpenMobileMenu(true), className: classNames(style.headerItem, style.headerItemMobile, {
                [style.open]: openMobileMenu,
                [style.linkDiabled]: openMobileMenu,
            }) },
            React.createElement(SVG, { src: User, className: `${style.pageHeaderIcon}` })),
        openMobileMenu && (React.createElement("div", { className: classNames(style.mobileSideMenuWrapper) }, renderUserMenuOpen()))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        patients: state.account.patients,
        currentUser: getCurrentUserSelector(state),
        requestRegistrationInfoStatus: state.account.requestRegistrationInfoStatus,
        requestHomeStatus: state.account.requestHomeStatus,
        registrationUnfinished: state.account.registrationUnfinished,
        isAdmin: state.account.isAdmin,
        patientsId: state.account.patients,
        userpatientsId: state.account.user.patientId,
        azureUserInfo: state.auth.azureUserInfo
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getHome: () => dispatch(getHomeAsyncAction()),
        getRegistrationInfo: (payload) => dispatch(getRegistrationInfoAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
