import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { getAnalytics, logEvent } from "@firebase/analytics";
import Button from "../../Shared/Controls/Button";
import { filesByTypeSelector } from "../../../store";
import AddImageCard from "../../Shared/AddImageCard";
import ImageModal from "../../Shared/ImageModal";
import ImageCard from "../../Shared/ImageCard";
import { getPatientFilesAsyncAction, updatePatientFilesAsyncAction } from "../../../store/patientFiles/Actions";
import { usePrevious } from "../../../hooks";
import s from "./style.scss";
import Box from "../../Shared/Box";
const Receipts = ({ requestGetFilesStatus, requestUpdateFilesStatus, requestGetPreviewPhotoStatus, files: prescriptionFiles, brand, patientId, hasSidebar, updateFiles, getFiles, setHaveChanges, skipAction, prevStep, children }) => {
    const { t } = useTranslation();
    useEffect(() => {
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: "Receipts",
            firebase_screen_class: "Receipts"
        });
    }, []);
    const refRequestUpdate = usePrevious(requestUpdateFilesStatus);
    const fileType = "Receipt";
    const [files, setFiles] = useState([]);
    const [filesToRemove, setFilesToRemove] = useState([]);
    useEffect(() => {
        getFiles(fileType);
    }, [patientId, brand]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalFile, setModalFile] = useState(null);
    const saveDisabled = !(files.length > 0 || filesToRemove.length > 0);
    useEffect(() => {
        setHaveChanges(!saveDisabled);
    }, [saveDisabled, files, filesToRemove]);
    useEffect(() => {
        if (requestGetFilesStatus === "success") {
            setFiles([]);
            setFilesToRemove([]);
        }
    }, [requestGetFilesStatus]);
    const addFile = (name, uri, blob, userFiles) => {
        userFiles && setFiles([...files, ...userFiles.map((el) => {
                return {
                    name: el.name,
                    extension: el.extension,
                    uri: el.uri,
                    filesType: fileType,
                    type: el.uri.split(';')[0].replace('data:', ''),
                    sizeType: el.sizeType
                };
            })]);
        setFiles([...files, {
                name: name,
                extension: "." + name.split('.').pop(),
                uri: uri,
                filesType: fileType,
                type: uri.split(';')[0].replace('data:', ''),
                sizeType: "FullSize"
            }]);
    };
    const removeExistFile = (id) => {
        setFilesToRemove([...filesToRemove, { id: id, deleteType: "Full" }]);
    };
    const removeFile = (file) => setFiles(files.filter(el => el !== file));
    const update = () => {
        updateFiles({
            deleted: filesToRemove,
            added: {
                files: files
            },
            fileType: fileType
        });
    };
    useEffect(() => {
        refRequestUpdate !== undefined && requestUpdateFilesStatus === "success" && skipAction && skipAction(true);
    }, [requestUpdateFilesStatus]);
    const renderActions = skipAction ? React.createElement("div", { className: classNames(s.actions, { [s.hasSidebar]: hasSidebar }) },
        React.createElement(Box, { column: true, fullWidth: true },
            React.createElement(Button, { style: s.button, noborder: true, skip: true, text: t("skip"), onClick: skipAction }),
            React.createElement(Box, { f1: true, row: true, gap: 16 },
                React.createElement(Button, { text: t("back"), onClick: prevStep }),
                React.createElement(Button, { style: s.button, disabled: saveDisabled, primary: true, iosTextOverflow: true, text: t("saveAndProceed"), onClick: () => {
                        update();
                        // skipAction && skipAction();
                    } })))) :
        React.createElement(Button, { style: s.updateButton, disabled: saveDisabled, primary: true, text: t("update"), onClick: update });
    return React.createElement(React.Fragment, null,
        React.createElement("div", { id: "receiptsScroll", className: classNames(s.scrollWrapper, { [s.hasSidebar]: hasSidebar }) },
            React.createElement("div", { className: s.pageWrapper },
                React.createElement("div", { className: classNames(s.fullLine, s.mb12) }, children),
                React.createElement(AddImageCard, { style: s.card, shortTitle: !files[0] ? t("addImage") : t("addAnotherImage"), addFile: addFile }),
                prescriptionFiles.filter(el => filesToRemove.filter(f => f.id == el.parentFileId || f.id == el.id).length == 0).map(file => file
                    && React.createElement(ImageCard, { key: file.id, style: s.card, url: file.uri, removeFile: () => removeExistFile(file.parentFileId || file.id), extension: file.extension, name: file.name, onClick: () => {
                            setModalFile(file);
                            setModalIsOpen(true);
                        } })),
                files.map(file => file
                    && React.createElement(ImageCard, { key: file.uri, style: s.card, url: file.uri, extension: file.extension, name: file.name, removeFile: () => removeFile(file), onClick: () => {
                            setModalFile(file);
                            setModalIsOpen(true);
                        } })),
                React.createElement(ImageModal, { modalIsOpen: modalIsOpen, file: modalFile, toggleModal: () => setModalIsOpen(false), deleteFile: () => {
                        modalFile?.id && removeExistFile(modalFile?.parentFileId || modalFile?.id) || removeFile(modalFile);
                    } })),
            React.createElement(Box, { f1: true, justifyCenter: true, alignCenter: true, mt: 16 }, renderActions)));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        brand: state.account.user.drugBrandCode,
        requestGetFilesStatus: state.patientFiles.requestGetFilesStatus,
        requestUpdateFilesStatus: state.patientFiles.requestUpdateFilesStatus,
        requestGetPreviewPhotoStatus: state.patientFiles.requestGetPreviewPhotoStatus,
        files: filesByTypeSelector(state, "Receipt")
    };
};
function mapDispatchToProps(dispatch) {
    return {
        updateFiles: (payload) => dispatch(updatePatientFilesAsyncAction(payload)),
        getFiles: (photoType) => dispatch(getPatientFilesAsyncAction({ type: photoType })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Receipts);
