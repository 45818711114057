import _ from "lodash";
export const mapAddresses = (data) => {
    return data.map((item) => {
        return {
            id: item.id,
            street: _.isNil(item.street) ? "" : item.street,
            streetLine2: _.isNil(item.streetLine2) ? "" : item.streetLine2,
            city: _.isNil(item.city) ? "" : item.city,
            provinceKey: item.province.key,
            provinceName: item.province.displayName,
            postalCode: _.isNil(item.zipCode) ? "" : item.zipCode,
            isPrimary: item.isDefault,
            type: mapAddressType(item.type),
        };
    });
};
const mapAddressType = (rawType) => {
    if (rawType === "Home") {
        return "home";
    }
    else if (rawType === "Work") {
        return "work";
    }
    return "other";
};
export const mapAddressTypeToParamAddressType = (type) => {
    if (type === "home") {
        return "Home";
    }
    else if (type === "work") {
        return "Work";
    }
    return "Other";
};
