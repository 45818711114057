import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../utils";
import { userInfoSelector } from "../account/selectors";
import addressSlice, { SLICE_NAME } from "./Slice";
import { mapMedicalRemindersDate, mapReminders } from "./Mapper";
import MedicalRemindersRepository from "../repository/MedicalRemindersRepository";
import moment from "moment";
export const getRemindersAsyncAction = createAction(`${SLICE_NAME}/getRemindersAsyncAction`);
export const addReminderAsyncAction = createAction(`${SLICE_NAME}/addReminderAsyncAction`);
export const editReminderAsyncAction = createAction(`${SLICE_NAME}/editReminderAsyncAction`);
export const deleteReminderAsyncAction = createAction(`${SLICE_NAME}/deleteReminderAsyncAction`);
export function* getRemindersSaga() {
    const { startRequestGetRemindersAction, setRemindersAction, showRequestGetRemindersErrorAction, } = addressSlice.actions;
    try {
        yield put(startRequestGetRemindersAction());
        const currentUser = yield select(userInfoSelector);
        const response = yield call(MedicalRemindersRepository.getReminders, currentUser.patientId || "");
        const mapped = yield mapReminders(response.data.value);
        yield put(setRemindersAction(mapped));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetRemindersErrorAction);
    }
}
export function* addReminderSaga(action) {
    const { startRequestAddReminderAction, addReminderAction, showRequestAddReminderErrorAction, } = addressSlice.actions;
    try {
        yield put(startRequestAddReminderAction());
        const currentUser = yield select(userInfoSelector);
        const actionPayload = action.payload;
        const { startDate, endDate } = yield mapMedicalRemindersDate(action.payload.medicationTimes, action.payload.startDate, action.payload.endDate);
        const payload = {
            patientId: actionPayload.patientId,
            title: actionPayload.title,
            reminderSubType: actionPayload.reminderSubType,
            reminderType: actionPayload.reminderType,
            repeatType: actionPayload.repeatType,
            startDate: startDate,
            endDate: endDate,
            medicationTimes: actionPayload.medicationTimes,
            daysOfTheWeek: actionPayload.daysOfTheWeek,
            repeatIntervalInDays: actionPayload.repeatIntervalInDays,
            offsetTime: moment().utcOffset() / 60
        };
        const response = yield call(MedicalRemindersRepository.addReminder, payload);
        const id = response.data.value;
        yield put(addReminderAction({
            id,
            isActive: actionPayload.isActive,
            patientId: actionPayload.patientId,
            title: actionPayload.title,
            reminderSubType: actionPayload.reminderSubType,
            reminderType: actionPayload.reminderType,
            repeatType: actionPayload.repeatType,
            startDate: actionPayload.startDate,
            endDate: actionPayload.endDate,
            medicationTimes: actionPayload.medicationTimes,
            daysOfTheWeek: actionPayload.daysOfTheWeek,
            offsetTime: moment().utcOffset() / 60
        }));
        yield getRemindersSaga();
    }
    catch (error) {
        yield handleApiError(error, showRequestAddReminderErrorAction);
    }
}
export function* editReminderSaga(action) {
    const { startRequestEditReminderAction, editReminderAction, showRequestEditReminderErrorAction, } = addressSlice.actions;
    const actionPayload = action.payload;
    try {
        if (actionPayload.id) {
            yield put(startRequestEditReminderAction());
            yield call(MedicalRemindersRepository.editReminder, {
                ...actionPayload,
                offsetTime: moment().utcOffset() / 60
            });
            const { startDate, endDate } = yield mapMedicalRemindersDate(actionPayload.medicationTimes, actionPayload.startDate, actionPayload.endDate);
            yield put(editReminderAction({
                id: actionPayload.id,
                isActive: actionPayload.isActive,
                patientId: actionPayload.patientId,
                title: actionPayload.title,
                reminderSubType: actionPayload.reminderSubType,
                reminderType: actionPayload.reminderType,
                repeatType: actionPayload.repeatType,
                startDate: startDate,
                endDate: endDate,
                medicationTimes: actionPayload.medicationTimes,
                daysOfTheWeek: actionPayload.daysOfTheWeek
            }));
            yield getRemindersSaga();
        }
    }
    catch (error) {
        yield handleApiError(error, showRequestEditReminderErrorAction);
    }
}
export function* deleteReminderSaga(action) {
    const { startRequestDeleteReminderAction, deleteReminderAction, showRequestDeleteReminderErrorAction, } = addressSlice.actions;
    try {
        yield put(startRequestDeleteReminderAction());
        yield call(MedicalRemindersRepository.deleteReminder, action.payload.reminderId);
        yield put(deleteReminderAction(action.payload.reminderId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeleteReminderErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getRemindersAsyncAction, getRemindersSaga);
    yield takeLatest(addReminderAsyncAction, addReminderSaga);
    yield takeLatest(editReminderAsyncAction, editReminderSaga);
    yield takeLatest(deleteReminderAsyncAction, deleteReminderSaga);
}
