import React, { useState, useEffect } from "react";
import { getAnalytics, logEvent } from "@firebase/analytics";
import { useNavigate } from "react-router-dom";
import s from "./style.scss";
import ItemPreview from "../../../EducationPage/components/ItemPreview";
import ProvinceDropdown from "../../../../components/Shared/Controls/Inputs/ProvinceDropdown";
import classNames from "classnames";
import Tag from "../../../../components/Shared/Controls/Tag";
import { setDeleteEducationDataStatus, setPostEducationDataStatus, setUpdateEducationInfoStatus } from "../../../../store/education";
import { connect, useDispatch, useSelector } from "react-redux";
import { patientEducationByMimeTypeSelector, markEducationLibraryPostAsReadAsyncAction } from "../../../../store/patientEducation";
import { useTranslation } from "react-i18next";
import Buttonstyle from "../../../../../src/components/Shared/Controls/Button";
import { Document, Page, pdfjs } from "react-pdf";
import InputTextMultiLine from "../../../../components/Shared/Controls/Inputs/InputTextMultiLine";
import Grid from '@mui/material/Grid';
import { InputText } from "../../../../components/Shared/Controls";
import ReactPlayer from 'react-player';
import { Box } from "../../../../components";
import Paper from '@mui/material/Paper';
import { createEducationInfoAsyncAction, deleteEducationDataAsyncAction, getTagsAsyncAction, updateEducationInfoAsyncAction } from "../../../../store/education/Sagas";
import CheckboxDropdown from "../../../../components/Shared/Controls/CheckboxDropdown";
import { getMedicalConditionsAsyncAction } from "../../../../store/medicalConditions/Sagas";
import SubHeadingwithback from "../../../../components/SubHeadingwithback";
import MultiSelectionDropdown from "../../../../components/Shared/Controls/MultiSelectionDropdown/MultiSelectionDropdown";
import DeletePopUp from "../../../../components/Shared/Info/DeletePopUp";
import LoadingPopUp from "../../../../components/Shared/LoadingPopUp";
import SuccessPopUp from "../../../../components/Shared/Info/SuccessPopUp";
import { getDrugBrandCodesDropdownAsyncAction } from "../../../../store/prescriber/Sagas";
import ValueDropdown from "../../../../components/Shared/Controls/Inputs/ValueDropdown";
import colors from "../../../../resources/colors";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
async function generateThumbnail(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    reject(new Error('Failed to get canvas context.'));
                    return;
                }
                const thumbnailWidth = 300;
                const thumbnailHeight = 161;
                ctx.drawImage(image, 0, 0, thumbnailWidth, thumbnailHeight);
                const thumbnailDataUrl = canvas.toDataURL();
                resolve(thumbnailDataUrl);
            };
            if (event.target) {
                image.src = event.target.result;
            }
            else {
                reject(new Error('Failed to load the image.'));
            }
        };
        reader.readAsDataURL(file);
    });
}
const staticData = [
    { key: 'Webm', displayName: 'Webm' },
    { key: 'Pdf', displayName: 'Pdf' },
    { key: 'Web', displayName: 'Web' },
];
const LANGUAGES = [
    {
        key: "EnCa",
        display: "English",
    },
    {
        key: "FrCa",
        display: "French"
    }
];
const EducationDetails = ({ item, backAction, setEducationItem, markAsRead, isAdmin, updateEducation, getMedicalConditions, medicalConditionsData, deleteEducationData, requestDeleteEducationDataStatus, requestUpdateEducationInfoStatus, createEducation, requestEducationPostStatus, getTags, Tags, drugBrandCodesDropdown, getDrugBrandCodesDropdown }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const videos = useSelector(patientEducationByMimeTypeSelector("Webm"));
    const pdf = useSelector(patientEducationByMimeTypeSelector("Pdf"));
    const Web = useSelector(patientEducationByMimeTypeSelector("Web"));
    const [url, setUrl] = useState("");
    const [selectedValue, setSelectedValue] = useState(item?.itemMimeType);
    const [selectedValueDrugBrandCode, setSelectedValueDrugBrandCode] = useState(item?.drugBrandCode);
    const [selectedLanguage, setSelectedLanguage] = useState({ key: "EnCa", display: "Default" });
    const [description, SetDescription] = useState("");
    const [thumbnailUrl, setThumbnailUrl] = useState(undefined);
    const [selectedFileName, setSelectedFileName] = useState('No file selected');
    const [numPages, setNumPages] = useState(null);
    const [title, setTitle] = useState("");
    const [dynamicItem, setdynamicItem] = useState();
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const [selectedOptionsTags, setSelectedOptionsTags] = React.useState([]);
    const [holdEducationId, setholdEducationId] = useState("");
    const [successPopupIsOpen, setSuccessPopupIsOpen] = useState(false);
    const [isSubmit, setisSubmit] = useState(false);
    const [isLoadedOnce, setisLoadedOnce] = useState(false);
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode
            };
        })
        : [];
    //medical condition
    const MedicalCondition = Array.isArray(medicalConditionsData?.resultItems)
        ? medicalConditionsData?.resultItems.map((item) => {
            return {
                key: item?.medicalConditionId,
                displayName: item?.medicalConditionDescription
            };
        })
        : [];
    useEffect(() => {
        const MedicalConditions = Array.isArray(medicalConditionsData?.resultItems)
            ? medicalConditionsData?.resultItems.map((item) => {
                return {
                    key: item?.medicalConditionId,
                    displayName: item?.medicalConditionDescription
                };
            })
            : [];
        setMedicalConditionOptions(MedicalConditions);
        // console.log(MedicalConditions, MedicalConditionOptions, isLoadedOnce);
        // after setting data from backend, update with selected values 
        // if (!isLoadedOnce) {
        const selectedMedicalCondition = item?.medicalConditions || [];
        const updatedItemsMedicalCondition = MedicalConditions.map((item) => ({
            ...item,
            isChecked: selectedMedicalCondition.some((c) => c.medicalConditionId == item?.key),
        }));
        // console.log(updatedItemsMedicalCondition);
        setSelectedMedicalConditions(updatedItemsMedicalCondition);
        setisLoadedOnce(true);
        // }
    }, [medicalConditionsData]);
    const [MedicalConditionOptions, setMedicalConditionOptions] = useState(MedicalCondition);
    const [selectedDisplayNamesMedicalCondition, setSelectedDisplayNamesMedicalCondition] = useState([]);
    const [selectedKeyMedicalCondition, setSelectedKeyMedicalCondition] = useState([]);
    const handleFileSelect = async (event) => {
        const file = event.target.files?.[0];
        if (!file)
            return;
        try {
            const thumbnailDataUrl = await generateThumbnail(file);
            setThumbnailUrl(thumbnailDataUrl);
            setSelectedFileName(file.name);
        }
        catch (error) {
            console.error('Error generating thumbnail:', error);
        }
    };
    useEffect(() => {
        logEvent(getAnalytics(), "video_view", {
            educationItemId: item?.educationId
        });
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: "VideosLibrary",
            firebase_screen_class: "EducationLibrary",
            educationItemId: item?.educationId
        });
    }, []);
    useEffect(() => {
        if (item &&
            !item?.isItemViewed) {
            markAsRead({
                educationId: item?.educationId,
                itemMimeType: item?.itemMimeType
            });
        }
        getDrugBrandCodesDropdown();
        //getTags("AVSOLA");
    }, []);
    useEffect(() => {
        if (selectedValueDrugBrandCode) {
            getMedicalConditions(selectedValueDrugBrandCode);
            getTags(selectedValueDrugBrandCode);
            setSelectedOptionsTags([]);
        }
    }, [selectedValueDrugBrandCode]);
    useEffect(() => {
        if (item?.drugBrandCode) {
            getMedicalConditions(item?.drugBrandCode);
            getTags(item?.drugBrandCode);
        }
    }, [item?.drugBrandCode]);
    const setSelectedMedicalConditions = (newItems) => {
        const checkedItems = newItems.filter((item) => item?.isChecked);
        const selectedNames = checkedItems.map((item) => item?.displayName);
        const selectedkey = checkedItems.map((item) => item?.key);
        setSelectedKeyMedicalCondition(selectedkey);
        setSelectedDisplayNamesMedicalCondition(selectedNames);
        setMedicalConditionOptions(newItems);
    };
    const setItemState = (item) => {
        setUrl(item?.url || "");
        setTitle(item?.title || "");
        SetDescription(item?.description);
        setSelectedFileName(item?.thumbnailUrl);
        setThumbnailUrl(item?.thumbnailUrl);
        setSelectedOptionsTags(item?.tags || []);
        console.log(LANGUAGES, item?.cultureCode, LANGUAGES?.find(l => l.key == item?.cultureCode));
        setSelectedLanguage(LANGUAGES?.find(l => l.key == item?.cultureCode));
        //hold Medical Condition
        const selectedMedicalCondition = item?.medicalConditions || [];
        const updatedItemsMedicalCondition = MedicalConditionOptions.map((item) => ({
            ...item,
            isChecked: selectedMedicalCondition.some((c) => c.medicalConditionId == item?.key),
        }));
        setSelectedMedicalConditions(updatedItemsMedicalCondition);
    };
    useEffect(() => {
        if (item) {
            setItemState(item);
        }
    }, [item]);
    useEffect(() => {
        const dynamicItemobj = {
            medicalConditions: selectedDisplayNamesMedicalCondition,
            educationId: item?.educationId,
            url: url,
            itemMimeType: item?.itemMimeType,
            title: title,
            description: description,
            cultureCode: item?.cultureCode,
            thumbnailUrl: thumbnailUrl,
            showInProgramBucket: item?.showInProgramBucket,
            isItemViewed: item?.isItemViewed,
            tags: selectedOptionsTags,
            relativeItems: item?.relativeItems,
            drugBrandCode: selectedValueDrugBrandCode
        };
        setdynamicItem(dynamicItemobj);
    }, [title, description, selectedOptionsTags, thumbnailUrl, selectedValueDrugBrandCode, selectedDisplayNamesMedicalCondition]);
    useEffect(() => {
        if (selectedValue && item?.itemMimeType != selectedValue) {
            setUrl("");
        }
        if (item?.itemMimeType == selectedValue) {
            setUrl(item?.url);
        }
    }, [selectedValue]);
    function Submit() {
        setisSubmit(true);
        if (url != "" && title != "" && selectedKeyMedicalCondition.length !== 0 && selectedValue != undefined && selectedValueDrugBrandCode != undefined) {
            const selectedObject = staticData.find(item => item?.key === selectedValue);
            if (item?.educationId != null) {
                updateEducation({
                    id: item?.educationId,
                    url: url,
                    mimeType: selectedObject.displayName ? selectedObject.displayName : item?.itemMimeType,
                    title: title,
                    description: description ? description : "",
                    drugBrandCode: selectedValueDrugBrandCode,
                    cultureCode: selectedLanguage.key,
                    thumbnailUrl: thumbnailUrl ? thumbnailUrl : "https://t3.ftcdn.net/jpg/04/34/72/82/240_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg",
                    showInBrandBucket: true,
                    tags: selectedOptionsTags ? selectedOptionsTags : [],
                    medicalConditionIds: selectedKeyMedicalCondition //
                });
                setisSubmit(false);
            }
            else {
                createEducation({
                    url: url,
                    mimeType: selectedObject.displayName ? selectedObject.displayName : item?.itemMimeType,
                    title: title,
                    description: description ? description : "",
                    drugBrandCode: selectedValueDrugBrandCode,
                    cultureCode: selectedLanguage.key,
                    thumbnailUrl: thumbnailUrl ? thumbnailUrl : "https://t3.ftcdn.net/jpg/04/34/72/82/240_F_434728286_OWQQvAFoXZLdGHlObozsolNeuSxhpr84.jpg",
                    showInBrandBucket: true,
                    tags: selectedOptionsTags ? selectedOptionsTags : [],
                    medicalConditionIds: selectedKeyMedicalCondition //
                });
                setisSubmit(false);
            }
        }
    }
    useEffect(() => {
        if (requestDeleteEducationDataStatus === "success") {
            dispatch(setDeleteEducationDataStatus("idle"));
            setDisplayInfo(false);
            backAction();
        }
        if (requestEducationPostStatus === "success") {
            setSuccessPopupIsOpen(true);
            dispatch(setPostEducationDataStatus("idle"));
        }
        if (requestUpdateEducationInfoStatus === "success") {
            setSuccessPopupIsOpen(true);
            dispatch(setUpdateEducationInfoStatus("idle"));
        }
    }, [requestDeleteEducationDataStatus, requestUpdateEducationInfoStatus, requestEducationPostStatus]);
    const handleArrowBack = () => {
        if (item?.educationId != null) {
            backAction();
        }
        else {
            isAdmin
                ? navigate('/education/admin')
                : navigate('/education');
        }
    };
    const handleDeleteInfo = (educationId) => {
        deleteEducationData(educationId);
    };
    const handleDelete = (Id) => {
        setholdEducationId(Id);
        setDisplayInfo(true);
    };
    const handleClick = (item) => {
        window.open(item, '_blank');
    };
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    const onDocumentLoadError = (error) => {
        console.log(error);
    };
    const renderPages = () => {
        let arr = Array.from(Array(numPages).keys());
        return React.createElement(React.Fragment, null, arr.map((el, id) => {
            return React.createElement(Page, { key: id, className: s.page, pageNumber: id + 1 });
        }));
    };
    const handleClosePopup = () => {
        setSuccessPopupIsOpen(false);
    };
    return (React.createElement(Box, { className: classNames(s.MuiPaperAllSectionBox) },
        React.createElement(SubHeadingwithback, { item: title, backAction: handleArrowBack }),
        React.createElement(Paper, { className: classNames(s.MuiPaperAllSection, s.pageBody, s.scrollWrapper, s.videoAllWrapper) },
            React.createElement("div", null,
                React.createElement("div", { className: s.videoWrapper },
                    React.createElement(Grid, { container: true, spacing: 2 },
                        React.createElement(Grid, { item: true, xs: 12, md: 9 },
                            React.createElement("div", { className: s.iframe_container },
                                (item?.itemMimeType == "Webm" && selectedValue == "Webm") || selectedValue == "Webm" ? React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: classNames(s.videoiframe) },
                                        React.createElement(ReactPlayer, { className: s.iframe_containerinner, url: url ? url : (item?.itemMimeType == "Webm" && selectedValue == "Webm") ? item?.url : "", controls: true, width: "100%", height: "auto" }))) : null,
                                (item?.itemMimeType == "Pdf" && selectedValue == "Pdf") || selectedValue == "Pdf" ? React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: s.articles_container },
                                        React.createElement("div", { className: s.articles_inner },
                                            React.createElement(Document, { file: url ? url : (item?.itemMimeType == "Pdf" && selectedValue == "Pdf") ? item?.url : "", onLoadSuccess: onDocumentLoadSuccess, onLoadError: onDocumentLoadError, error: "Failed to load PDF file. It is possible we do not have access to view this resource. \n \n" +
                                                    "If you own this resource, contact your technical team to update your CORS policy." }, renderPages())))) : null,
                                (item?.itemMimeType == "Web" && selectedValue == "Web") || selectedValue == "Web" ? React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: classNames(s.onlineResourcesBox) },
                                        React.createElement("h2", null, "To see the resource click on below link. "),
                                        React.createElement(Buttonstyle, { onClick: () => handleClick(url ? url : (item?.itemMimeType == "Web" && selectedValue == "Web") ? item?.url : ""), text: "View", primary: true }))) : null),
                            React.createElement("div", { className: s.videoDetailsWrapper },
                                React.createElement("div", { className: classNames(s.tags, s.titleFrom) },
                                    isAdmin != true ? React.createElement(React.Fragment, null,
                                        React.createElement("h3", null,
                                            t("title"),
                                            " ")) : null,
                                    React.createElement("div", { style: { marginBottom: "10px" } },
                                        React.createElement("div", { className: classNames(s.formGroup, s.formGroupinput) }, isAdmin == true ?
                                            React.createElement(React.Fragment, null,
                                                React.createElement("label", null,
                                                    t("title"),
                                                    " ",
                                                    React.createElement("span", { className: s.errortext }, "*")),
                                                React.createElement(InputText, { name: "title", value: title, setValue: setTitle, maxLength: 900, validationMessage: t("pleaseEnterTitle"), isSubmitted: isSubmit, required: true }),
                                                " ") : React.createElement("p", null, title)))),
                                React.createElement("div", { className: classNames(s.tags, s.descriptiontext) },
                                    isAdmin != true ? React.createElement(React.Fragment, null,
                                        React.createElement("h3", null, t("description"))) : null,
                                    isAdmin == true ?
                                        React.createElement(InputTextMultiLine, { label: t("description"), name: "description", value: description, setValue: SetDescription, columns: 6, height: 110 }) :
                                        React.createElement("p", { dangerouslySetInnerHTML: { __html: description.replace(/\r?\n/g, '<br />') } })),
                                isAdmin == true ?
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: classNames(s.formGroup) },
                                            React.createElement("label", null,
                                                t("conditions"),
                                                " ",
                                                React.createElement("span", { className: s.errortext }, "*")),
                                            React.createElement(CheckboxDropdown, { items: MedicalConditionOptions, setItems: setSelectedMedicalConditions, required: true, isSubmited: isSubmit }))) : null,
                                React.createElement("div", { className: classNames(s.formGroup) },
                                    isAdmin != true ? React.createElement(React.Fragment, null,
                                        React.createElement("h3", null, t("tags"))) : null,
                                    isAdmin == true ? React.createElement(React.Fragment, null,
                                        " ",
                                        React.createElement("label", null, t("tags"))) : null,
                                    isAdmin == true ? null : React.createElement(React.Fragment, null,
                                        " ",
                                        React.createElement("div", { className: classNames(s.tadlist) },
                                            " ",
                                            item?.tags?.map((tag) => (React.createElement(Tag, { key: tag, style: s.tag, text: tag }))),
                                            " "),
                                        " "),
                                    isAdmin == true ? React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: classNames(s.multiSelectionTags) },
                                            React.createElement(MultiSelectionDropdown, { options: Tags?.value, selectedOptions: selectedOptionsTags, setSelectedOptions: setSelectedOptionsTags, placeholder: t("tags") }))) : null))),
                        React.createElement(Grid, { item: true, xs: 12, md: 3, className: classNames(s.pl30, s.pt30) }, isAdmin == true ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null,
                                    t("mimetypevalue"),
                                    " ",
                                    React.createElement("span", { className: s.errortext }, "*")),
                                React.createElement(ProvinceDropdown, { data: staticData, value: selectedValue, setValue: setSelectedValue, validationMessage: t("pleaseSelectMimeType"), required: true, isSubmitted: isSubmit })),
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null,
                                    t("urlword"),
                                    " ",
                                    React.createElement("span", { className: s.errortext }, "*")),
                                React.createElement(InputText, { name: "url", value: url, setValue: setUrl, maxLength: 900, validationMessage: t("pleaseEnterURL"), isSubmitted: isSubmit, required: true })),
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null, t("thumbnail")),
                                React.createElement("div", { className: classNames(s.fileuploadinput), "data-text": t("chooseFile") },
                                    React.createElement("input", { className: classNames(s.formControl, s.fileuploadfield), type: "file", id: "images", accept: "image/*", onChange: handleFileSelect }))),
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null,
                                    t("drugbrandcodes"),
                                    " ",
                                    React.createElement("span", { className: s.errortext }, "*")),
                                React.createElement(ProvinceDropdown, { data: DrugBrandCode, value: selectedValueDrugBrandCode, setValue: setSelectedValueDrugBrandCode, validationMessage: t("selectDrugBrandcode"), required: true, isSubmitted: isSubmit })),
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null,
                                    t("language"),
                                    " ",
                                    React.createElement("span", { className: s.errortext }, "*")),
                                React.createElement(ValueDropdown, { "data-testid": "picker-test", color: colors.neutralDark, 
                                    // style={classNames(componentStyles.input)} 
                                    data: LANGUAGES, value: selectedLanguage, setValue: setSelectedLanguage })),
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null, t("preview")),
                                React.createElement(ItemPreview, { type: "Webm", item: dynamicItem }),
                                React.createElement("br", null)),
                            React.createElement("div", { className: classNames(s.twobutton) },
                                React.createElement(Buttonstyle, { text: item?.educationId != null ? t('update') : t('save'), onClick: () => { Submit(); }, primarySuccess: true }),
                                item?.educationId != null ? React.createElement(React.Fragment, null,
                                    " ",
                                    React.createElement(Buttonstyle, { text: t('delete'), deleted: true, onClick: () => handleDelete(item?.educationId) })) : null))) :
                            React.createElement(React.Fragment, null, item?.itemMimeType == "Webm" ? React.createElement(React.Fragment, null, item?.relativeItems &&
                                item?.relativeItems.length > 0 ? (React.createElement("div", { className: s.relatedVideosadmin },
                                React.createElement("h3", null, t("relatedVideos")),
                                React.createElement("div", { className: classNames(s.relatedVideos) }, item?.relativeItems.map((id) => {
                                    let item = videos.find((el) => el.educationId == id);
                                    return (React.createElement(ItemPreview, { type: "Webm", item: item, onClick: () => setEducationItem(item) }));
                                })))) : React.createElement("h3", null, "Data not found")) : item?.itemMimeType == "Pdf" ?
                                React.createElement(React.Fragment, null, item?.relativeItems &&
                                    item?.relativeItems.length > 0 ? (React.createElement("div", { className: s.relatedVideos },
                                    React.createElement("h3", null, "Related articles"),
                                    React.createElement("div", { className: classNames(s.relatedVideos) }, item?.relativeItems.map((id) => {
                                        let item = pdf.find((el) => el.educationId == id);
                                        return (React.createElement(ItemPreview, { type: "Pdf", item: item, onClick: () => setEducationItem(item) }));
                                    })))) : React.createElement("h3", null, "Data not found")) : null)))))),
        React.createElement(DeletePopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false), onClick: () => handleDeleteInfo(holdEducationId), header: t("deletePermanently") },
            React.createElement(Box, { width: 100 },
                React.createElement(Grid, { container: true, rowSpacing: 1, columnSpacing: { xs: 1, sm: 2, md: 3 } }))),
        requestDeleteEducationDataStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestEducationPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestUpdateEducationInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        React.createElement(SuccessPopUp, { onClose: handleClosePopup, isOpen: successPopupIsOpen, displayMessage: "Your changes have been saved.", header: "success!", name: "Education", togglePopUp: () => { setSuccessPopupIsOpen(false); handleArrowBack(); } })));
};
const mapStateToProps = (state) => {
    return {
        isAdmin: state.account.isAdmin,
        medicalConditionsData: state.medicalConditions.MedicalConditions,
        requestDeleteEducationDataStatus: state.education.requestDeleteEducationDataStatus,
        requestUpdateEducationInfoStatus: state.education.requestUpdateEducationInfoStatus,
        requestEducationPostStatus: state.education.requestEducationPostStatus,
        Tags: state.education.Tags,
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        markAsRead: (payload) => dispatch(markEducationLibraryPostAsReadAsyncAction(payload)),
        updateEducation: (payload) => dispatch(updateEducationInfoAsyncAction(payload)),
        getMedicalConditions: (payload) => dispatch(getMedicalConditionsAsyncAction(payload)),
        deleteEducationData: (educationId) => dispatch(deleteEducationDataAsyncAction({ educationId })),
        createEducation: (Education) => dispatch(createEducationInfoAsyncAction(Education)),
        getTags: (payload) => dispatch(getTagsAsyncAction(payload)),
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EducationDetails);
