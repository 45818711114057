import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SVG from 'react-inlinesvg';
import classNames from "classnames";
import { userBrandByIdSelector } from "../../../store";
import { getFinancialAssistanceAsyncAction, getFinancialAssistanceConfigAsyncAction, sendFinancialAssistanceAsyncAction } from "../../../store/patientFiles/Actions";
import CardFA from "../../../components/Shared/Controls/CardFA";
import Close from '../../../resources/icons/close.svg';
import ApplyNow from '../../../resources/icons/apply-now.svg';
import SpeakExpert from '../../../resources/icons/speak-expert.svg';
import LoaderIcon from '../../../resources/icons/icon-loader.svg';
import { Button, InputText } from "../../../components/Shared/Controls";
import { Notification, PopUp } from "../../../components/Shared/Info";
import AdditionalStepsWrapper from "../AdditionalStepsWrapper";
import PageBody from "../../../components/Shared/PageBody";
import { useInput, usePrevious } from "../../../hooks";
import s from './style.scss';
const FinansialAssistance = ({ info, requestGetStatus, requestSendStatus, requestGetFinancialConfigStatus, brand, brandPhoneNumber, config, mandatory = false, getFinancialAssistance, sendFinancialAssistance, skipAction, backAction, getConfig, hasSidebar, hasTitle }) => {
    const { t } = useTranslation();
    const prevRequest = usePrevious(requestGetStatus);
    const prevPost = usePrevious(requestSendStatus);
    const [step, setStep] = useState(1);
    const [showPopUp, setPopUp] = useState(false);
    const togglePopUp = () => setPopUp(s => !s);
    const nextStep = () => step <= 2 && setStep(step => step + 1);
    const prevStep = () => {
        step > 1 && !info.certificate ? setStep(step => step - 1) : backAction();
    };
    useEffect(() => {
        if (step === 2 && !config.askDependents && !config.askHouse) {
            sendFinancialAssistance({
                householdIncome: 0,
                dependentsNumber: 0
            });
            setStep(3);
        }
    }, [step]);
    useEffect(() => {
        getConfig({
            isMember: false
        });
    }, []);
    const [grossAnnualIncome, setGrossAnnualIncome, errorGrossAnnualIncome, setErrorGrossAnnualIncome] = useInput();
    const [numberOfDependents, setNumberOfDependents, errorNumberOfDependents, setErrorNumberOfDependents] = useInput();
    const submitButtonIsDisabled = !grossAnnualIncome || !numberOfDependents || errorGrossAnnualIncome
        || errorNumberOfDependents || numberOfDependents > 15 || grossAnnualIncome > 10000000;
    useEffect(() => {
        (prevPost !== undefined && requestSendStatus === "success" || requestSendStatus === "failure") && getFinancialAssistance();
    }, [requestSendStatus]);
    useEffect(() => {
        prevRequest !== undefined && info.status.key && requestGetStatus === "success" && setStep(3);
    }, [requestGetStatus]);
    const renderInfoPopUp = React.createElement(PopUp, { style: s.popup, isOpen: showPopUp, toggle: togglePopUp },
        React.createElement("div", { className: s.popup__content },
            React.createElement("div", null,
                t("ifYouHaveConcernsAboutPaying"),
                " ",
                brandPhoneNumber)),
        React.createElement("div", { className: s.popup__controls },
            React.createElement(Button, { style: s.button, text: t("close"), onClick: togglePopUp })),
        React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: togglePopUp }));
    const renderContent = () => {
        switch (step) {
            case 1: return React.createElement("div", { className: s.contentWrapper },
                hasTitle && React.createElement("div", { className: classNames(s.subheading_1, s.title) }, t("financialAssistance")),
                React.createElement("div", { className: s.blockInfo },
                    React.createElement("div", { className: s.textWrapper },
                        t("youMayBeEligible"),
                        " ",
                        brand?.drugBrandCode,
                        "\u00AE ",
                        config.electronic && t("tapApplyNow")),
                    React.createElement("div", { className: s.textWrapper }, t("ifYouHaveQuestions"))),
                React.createElement("div", { className: s.cardList },
                    config.electronic && (React.createElement(CardFA, { onClick: nextStep },
                        React.createElement(SVG, { src: ApplyNow, className: s.applyImg }),
                        React.createElement("div", { className: s.subheading_2 }, t("applyNow")))),
                    React.createElement(CardFA, { onClick: togglePopUp },
                        React.createElement(SVG, { src: SpeakExpert, className: s.speakExportImg }),
                        React.createElement("div", { className: s.subheading_2 }, t("speakToAnExpert")))),
                renderInfoPopUp);
            case 2: return React.createElement("div", { className: classNames(s.contentWrapper, { [s.pageWrapper]: hasSidebar }) },
                hasTitle && React.createElement("div", { className: classNames(s.subheading_1, s.title, s.fullLine) }, t("financialAssistance")),
                React.createElement("div", { className: classNames(s.paragraph_2, s.mb24, s.fullLine) }, t("pleaseCompleteInfo")),
                config.askHouse &&
                    React.createElement(InputText, { style: s.input, name: "annualIncome", placeholder: t("enterGross"), errorMessage: t("errorHouseholdIncome"), description: t("tooltipTitleForFA"), value: grossAnnualIncome, setValue: setGrossAnnualIncome, error: grossAnnualIncome > 10000000 ? "error" : undefined, setError: setErrorGrossAnnualIncome, isNumber: true }),
                config.askDependents &&
                    React.createElement(InputText, { name: "numberOfDependents", placeholder: t("enterDependents"), value: numberOfDependents, errorMessage: t("errorDependentsNumber"), setValue: setNumberOfDependents, error: numberOfDependents > 15 ? "error" : undefined, setError: setErrorNumberOfDependents, isNumber: true }));
            case 3: return (requestGetStatus !== "success" ? React.createElement("div", { className: s.contentWrapper },
                hasTitle && React.createElement("div", { className: classNames(s.subheading_1, s.title) }, t("financialAssistance")),
                React.createElement("div", { className: s.loadingNotify },
                    React.createElement(SVG, { src: LoaderIcon, className: s.loaderIcon }),
                    React.createElement("div", null, t("weAreCheckingInfo").split('\n').map(el => React.createElement("div", null, el)))))
                : React.createElement(React.Fragment, null, info.status.key === "Ready" || info.status.key === "Pending" ?
                    React.createElement("div", { className: s.contentWrapper },
                        React.createElement("div", { className: classNames(s.subheading_1, s.title) }, t("financialAssistance")),
                        React.createElement("div", { className: classNames(s.paragraph_2, s.mb12) }, t("youHaveBeenAproved")),
                        React.createElement("div", { className: classNames(s.paragraph_2, s.mb12) }, t("provideCopayCard")),
                        React.createElement("div", { className: classNames(s.paragraph_2, s.mb12) }, t("youCanAccessYourCopay")),
                        React.createElement("div", { className: s.faInfoPanel },
                            React.createElement("div", { className: s.faInfoPanel_header },
                                React.createElement("img", { src: brand?.base64Logo }),
                                React.createElement("img", { src: "https://memberweb.nexgenrx.com/img/logo_en.svg" })),
                            React.createElement("div", { className: s.faInfoPanel_content },
                                React.createElement("div", { className: s.faInfoPanel_content_cell },
                                    React.createElement("div", { className: s.faInfoPanel_content_cell_title }, t("carrier")),
                                    React.createElement("div", null, info.carrier)),
                                React.createElement("div", { className: s.faInfoPanel_content_cell },
                                    React.createElement("div", { className: s.faInfoPanel_content_cell_title }, t("group")),
                                    React.createElement("div", null, info.group)),
                                React.createElement("div", { className: s.faInfoPanel_content_cell },
                                    React.createElement("div", { className: s.faInfoPanel_content_cell_title }, t("relationship")),
                                    React.createElement("div", null, info.relationship.displayName)),
                                React.createElement("div", { className: s.faInfoPanel_content_cell },
                                    React.createElement("div", { className: s.faInfoPanel_content_cell_title }, t("name")),
                                    React.createElement("div", null, info.patientName)),
                                React.createElement("div", { className: s.faInfoPanel_content_cell },
                                    React.createElement("div", { className: s.faInfoPanel_content_cell_title }, t("patientCode")),
                                    React.createElement("div", null, info.patientCode)),
                                React.createElement("div", { className: s.faInfoPanel_content_cell },
                                    React.createElement("div", { className: s.faInfoPanel_content_cell_title }, t("certificate")),
                                    React.createElement("div", null, info.certificate)))))
                    :
                        React.createElement("div", null, t("receivedFinancialAssistance"))));
            default: return null;
        }
    };
    const renderActions = () => {
        switch (step) {
            case 1: return hasSidebar ? React.createElement(Button, { style: s.button, text: t("finishEnrollment"), primary: true, onClick: skipAction }) : React.createElement(Button, { style: s.button, text: t("skip"), onClick: skipAction });
            case 2: return React.createElement(React.Fragment, null,
                React.createElement(Button, { style: s.button, text: t("back"), onClick: prevStep }),
                React.createElement(Button, { style: s.button, disabled: submitButtonIsDisabled, text: t("submit"), onClick: submitFA, primary: true }));
            case 3: return requestGetStatus !== "success" ? (React.createElement(React.Fragment, null,
                React.createElement(Notification, { type: 'info', onClick: nextStep },
                    React.createElement("div", { className: s.paragraph_2 }, t("ifYouHaveConcernsAboutPaying")))))
                : (React.createElement("div", { className: s.faActions },
                    React.createElement(Button, { style: s.button, text: t("finish"), primary: true, onClick: skipAction })));
            default: null;
        }
    };
    const submitFA = () => {
        if (grossAnnualIncome && numberOfDependents) {
            sendFinancialAssistance({
                householdIncome: +grossAnnualIncome,
                dependentsNumber: +numberOfDependents
            });
            setStep(3);
        }
    };
    return !hasSidebar ? React.createElement(AdditionalStepsWrapper, { backAction: step === 1 ? backAction : prevStep },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames(s.scrollWrapper, { [s.is]: hasSidebar }) }, renderContent()),
            React.createElement("div", { className: classNames(s.faActions, { [s.hasSidebar]: hasSidebar }) }, renderActions()))) :
        React.createElement(PageBody, { title: t("financialAssistance"), backAction: step === 1 ? backAction : prevStep },
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: classNames(s.scrollWrapper, { [s.is]: hasSidebar }) }, renderContent()),
                React.createElement("div", { className: classNames(s.faActions, { [s.hasSidebar]: hasSidebar }) }, renderActions())));
};
const mapStateToProps = (state) => {
    return {
        brand: userBrandByIdSelector(state),
        requestGetStatus: state.patientFiles.requestGetFinancialAssistanceStatus,
        requestSendStatus: state.patientFiles.requestSendFinancialAssistanceStatus,
        requestGetFinancialConfigStatus: state.patientFiles.requestGetFinancialConfigStatus,
        requestGetFinancialConfigError: state.patientFiles.requestGetFinancialConfigError,
        config: state.patientFiles.financialAssistanceConfig,
        info: state.patientFiles.financialAssistanceInfo,
        brandPhoneNumber: state.account.brandPhoneNumber
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getFinancialAssistance: () => {
            dispatch(getFinancialAssistanceAsyncAction({ isMember: false }));
        },
        sendFinancialAssistance: (payload) => dispatch(sendFinancialAssistanceAsyncAction(payload)),
        getConfig: (payload) => dispatch(getFinancialAssistanceConfigAsyncAction(payload))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(FinansialAssistance);
