import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "PatientBrandPrescriber";
const initialState = {
    requestPatientBrandPrescriberPostStatus: "idle",
    requestPatientBrandPrescriberPostError: null,
    requestGetPatientBrandPrescriberStatus: "idle",
    requestGetPatientBrandPrescriberError: null,
    getPatientBrandPrescriberData: undefined,
    requestUpdatePatientBrandPrescriberInfoStatus: "idle",
    requestUpdatePatientBrandPrescriberInfoError: null,
    requestDeletePatientBrandPrescriberInfoStatus: "idle",
    requestDeletePatientBrandPrescriberInfoError: null,
    PatientBrandPrescriber: undefined,
};
const PatientBrandPrescriberSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        //post api call resp
        startCreatePatientBrandPrescriberInfoAsyncAction: (state) => {
            state.requestPatientBrandPrescriberPostStatus = "pending";
            state.requestPatientBrandPrescriberPostError = null;
        },
        succesCreatePatientBrandPrescriberInfoAsyncAction: (state, action) => {
            state.requestPatientBrandPrescriberPostStatus = "success";
            state.requestPatientBrandPrescriberPostError = null;
        },
        showcreatePatientBrandPrescriberInfoErrorAction: (state, action) => {
            state.requestPatientBrandPrescriberPostStatus = "failure";
            state.requestPatientBrandPrescriberPostError = action.payload;
        },
        resetCreatePatientBrandPrescriberInfoAsyncAction: (state) => {
            state.requestPatientBrandPrescriberPostStatus = "idle";
        },
        //get api 
        startRequestGetPatientBrandPrescriberAction: (state) => {
            state.requestGetPatientBrandPrescriberStatus = "pending";
            state.requestGetPatientBrandPrescriberError = null;
        },
        successRequestGetPatientBrandPrescriberAction: (state, action) => {
            state.requestGetPatientBrandPrescriberStatus = "success";
            state.requestGetPatientBrandPrescriberError = null;
            state.getPatientBrandPrescriberData = action.payload;
        },
        showRequesGetPatientBrandPrescriberErrorAction: (state, action) => {
            state.requestGetPatientBrandPrescriberStatus = "failure";
            state.requestGetPatientBrandPrescriberError = action.payload;
        },
        //update api
        startRequestUpdatePatientBrandPrescriberInfoAction: (state) => {
            state.requestUpdatePatientBrandPrescriberInfoStatus = "pending";
            state.requestUpdatePatientBrandPrescriberInfoError = null;
        },
        successRequestUpdatePatientBrandPrescriberInfoAction: (state, action) => {
            state.requestUpdatePatientBrandPrescriberInfoStatus = "success";
            state.requestUpdatePatientBrandPrescriberInfoError = null;
            state.PatientBrandPrescriber = action.payload;
        },
        showRequestUpdatePatientBrandPrescriberInfoErrorAction: (state, action) => {
            state.requestUpdatePatientBrandPrescriberInfoStatus = "failure";
            state.requestUpdatePatientBrandPrescriberInfoError = action.payload;
        },
        resetUpdatePatientBrandPrescriberInfoAsyncAction: (state) => {
            state.requestUpdatePatientBrandPrescriberInfoStatus = "idle";
        },
        //delete api
        startRequestDeletePatientBrandPrescriberInfoAction: (state) => {
            state.requestDeletePatientBrandPrescriberInfoStatus = "pending";
            state.requestDeletePatientBrandPrescriberInfoError = null;
        },
        successRequestDeletePatientBrandPrescriberInfoAction: (state, action) => {
            state.requestDeletePatientBrandPrescriberInfoStatus = "success";
            state.requestDeletePatientBrandPrescriberInfoError = null;
            state.PatientBrandPrescriber = null;
        },
        showRequestDeletePatientBrandPrescriberInfoErrorAction: (state, action) => {
            state.requestDeletePatientBrandPrescriberInfoStatus = "failure";
            state.requestDeletePatientBrandPrescriberInfoError = action.payload;
        },
        resetDeletePatientBrandPrescriberInfoAsyncAction: (state) => {
            state.requestDeletePatientBrandPrescriberInfoStatus = "idle";
        },
    },
});
export default PatientBrandPrescriberSlice;
