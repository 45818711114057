import config from "../../config.json";
const designSystem = config.design_system;
const colorsSystem = designSystem.colors;
export default {
    white: "#fff",
    black: "#000",
    primary: colorsSystem.primary,
    primaryshades0: colorsSystem.primaryshades0,
    primaryshades1: colorsSystem.primaryshades1,
    primaryshades2: colorsSystem.primaryshades2,
    primaryshades3: colorsSystem.primaryshades3,
    secondary: colorsSystem.secondary,
    secondaryshades0: colorsSystem.secondaryshades0,
    secondaryshades1: colorsSystem.secondaryshades1,
    secondaryshades2: colorsSystem.secondaryshades2,
    secondaryshades3: colorsSystem.secondaryshades3,
    neutralDark: colorsSystem.neutralDark,
    neutralGray: colorsSystem.neutralGray,
    neutralMedium: colorsSystem.neutralMedium,
    neutralLight: colorsSystem.neutralLight,
    deleteRed: colorsSystem.red1,
    inputPlaceholder: colorsSystem[designSystem.input.placeholderColor],
    inputFocusColorBorder: colorsSystem[designSystem.input.focusBorderColor],
    inputFocusShadowBorder: colorsSystem[designSystem.input.focusShadowColor],
    controlsGeneralColor: colorsSystem[designSystem.controls.color],
    tabBorderColor: colorsSystem[designSystem.controls.tabBorder],
    buttonBackgroundColor: colorsSystem[designSystem.button.default.backgroundColor],
    buttonTextColor: colorsSystem[designSystem.button.default.textColor],
    buttonPrimaryBackgroundColor: colorsSystem[designSystem.button.primary.backgroundColor],
    buttonPrimaryTextColor: colorsSystem[designSystem.button.primary.textColor],
    buttonDisabledBackgroundColor: colorsSystem[designSystem.button.disable.backgroundColor],
    buttonDisabledTextColor: colorsSystem[designSystem.button.disable.textColor],
    buttonSkip: colorsSystem[designSystem.button.skip],
    notification: {
        infoIconColor: colorsSystem[designSystem.notification.infoIconColor],
        infoBackground: colorsSystem[designSystem.notification.infoBackground],
        warningIconColor: colorsSystem[designSystem.notification.warningIconColor],
        warningBackground: colorsSystem[designSystem.notification.warningBackground],
        errorIconColor: colorsSystem[designSystem.notification.errorIconColor],
        errorBackground: colorsSystem[designSystem.notification.errorBackground],
    },
    cardBacklight: {
        borderColor: colorsSystem[designSystem.cardBacklight.borderColor],
        shadowColor: colorsSystem[designSystem.cardBacklight.shadowColor],
    },
    addressBorderColor: colorsSystem[designSystem.address.borderColor],
    addressShadowColor: colorsSystem[designSystem.address.shadowColor],
    addressTextColor: colorsSystem[designSystem.address.textColor],
    addressTextColorActive: colorsSystem[designSystem.address.textColorActive],
    patientDocuments: {
        backgroundColor: colorsSystem[designSystem.patientDocuments.backgroundColor],
        borderColor: colorsSystem[designSystem.patientDocuments.borderColor],
        textcolor: colorsSystem[designSystem.patientDocuments.textcolor],
        insideBorderColor: colorsSystem[designSystem.patientDocuments.insideBorderColor],
        dividerLineColor: colorsSystem[designSystem.patientDocuments.dividerLineColor],
        dividerTextColor: colorsSystem[designSystem.patientDocuments.dividerTextColor],
    },
    education: {
        sectorTitleColor: colorsSystem[designSystem.education.sectorTitleColor],
    },
    chat: {
        backgroundMessageColor: colorsSystem[designSystem.chat.backgroundMessageColor],
        supportIconGradient: {
            from: colorsSystem[designSystem.chat.supportIconGradient.from],
            to: colorsSystem[designSystem.chat.supportIconGradient.to],
        },
        userIcon: {
            backgroundColor: colorsSystem[designSystem.chat.userIcon.backgroundColor],
            figureColor: colorsSystem[designSystem.chat.userIcon.figureColor],
        }
    },
    backgroundColorWithSidebar: colorsSystem[designSystem.background.color],
    backgroundGradient: {
        from: colorsSystem[designSystem.background.backgroundColorGradient.from],
        to: colorsSystem[designSystem.background.backgroundColorGradient.to]
    },
    checkMarkColor: colorsSystem[designSystem.background.checkMark.color] || designSystem.background.checkMark.color,
    icons: {
        primary: colorsSystem[designSystem.icons.primary],
        sidebar: {
            primary: colorsSystem[designSystem.icons.sidebar.primary],
            active: colorsSystem[designSystem.icons.sidebar.active]
        },
        background: colorsSystem[designSystem.icons.background],
        neutral: colorsSystem[designSystem.icons.neutral]
    },
    linkColor: colorsSystem[designSystem.linkColor]
};
