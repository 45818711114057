import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import InviteSlice, { SLICE_NAME } from "./Slice";
import { handleApiError } from "../utils";
import InviteRepository from "../repository/InviteRepository";
import { userInfoSelector } from "../account";
export const getCallPreferencesAsyncAction = createAction(`${SLICE_NAME}/getCallPreferencesAsyncAction`);
export function* getCallPreferencesSaga() {
    const { setCallPreferencesAction, showGetCallPreferencesErrorAction } = InviteSlice.actions;
    try {
        const { impersonatedAccountId } = yield select(userInfoSelector);
        const response = yield call(InviteRepository.getCallPreferences, impersonatedAccountId);
        yield put(setCallPreferencesAction(response.data.value));
    }
    catch (error) {
        yield handleApiError(error, showGetCallPreferencesErrorAction);
    }
}
export const createInviteValidatesInfoAsyncAction = createAction(`${SLICE_NAME}/createInviteValidatesInfoAsyncAction`);
export function* createInviteValidatesInfoSaga(action) {
    const { startCreateInviteValidateInfoAsyncAction, succesCreateInviteValidateInfoAsyncAction, showcreateInviteValidateInfoErrorAction, successResponseInviteValidate } = InviteSlice.actions;
    try {
        yield put(startCreateInviteValidateInfoAsyncAction());
        const result = yield call(InviteRepository.postInviteValidate, action.payload.data);
        yield put(successResponseInviteValidate(result));
        yield put(succesCreateInviteValidateInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateInviteValidateInfoErrorAction);
    }
}
export const createInviteChangePhoneNumberRequestInfoAsyncAction = createAction(`${SLICE_NAME}/createInviteChangePhoneNumberRequestInfoAsyncAction`);
export function* createInviteChangePhoneNumberRequestInfoSaga(action) {
    const { startCreateInviteChangePhoneNumberRequestInfoAsyncAction, succesCreateInviteChangePhoneNumberRequestInfoAsyncAction, showcreateInviteChangePhoneNumberRequestInfoErrorAction } = InviteSlice.actions;
    try {
        yield put(startCreateInviteChangePhoneNumberRequestInfoAsyncAction());
        yield call(InviteRepository.postInviteChangePhoneNumberRequest, action.payload.data);
        yield put(succesCreateInviteChangePhoneNumberRequestInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateInviteChangePhoneNumberRequestInfoErrorAction);
    }
}
export const createInvitePhoneNumberVarificationInfoAsyncAction = createAction(`${SLICE_NAME}/createInvitePhoneNumberVarificationInfoAsyncAction`);
export function* createInvitePhoneNumberVarificationInfoSaga(action) {
    const { startCreateInvitePhoneNumberVarificationInfoAsyncAction, succesCreateInvitePhoneNumberVarificationInfoAsyncAction, showcreateInvitePhoneNumberVarificationInfoErrorAction } = InviteSlice.actions;
    try {
        yield put(startCreateInvitePhoneNumberVarificationInfoAsyncAction());
        let response = yield call(InviteRepository.postInvitePhoneNumberVarification, action.payload.data);
        yield put(succesCreateInvitePhoneNumberVarificationInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateInvitePhoneNumberVarificationInfoErrorAction);
    }
}
export const createInviteChangePasswordInfoAsyncAction = createAction(`${SLICE_NAME}/createInviteChangePasswordInfoAsyncAction`);
export function* createInviteChangePasswordInfoSaga(action) {
    const { startCreateInviteChangePasswordInfoAsyncAction, succesCreateInviteChangePasswordInfoAsyncAction, showcreateInviteChangePasswordInfoErrorAction } = InviteSlice.actions;
    try {
        yield put(startCreateInviteChangePasswordInfoAsyncAction());
        let response = yield call(InviteRepository.postInviteChangePassword, action.payload.data);
        yield put(succesCreateInviteChangePasswordInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateInviteChangePasswordInfoErrorAction);
    }
}
export const createCallPreferenceAsyncAction = createAction(`${SLICE_NAME}/createCallPreferenceAsyncAction`);
export function* createCallPreferenceSaga(action) {
    const { startCreateCallPreferenceAsyncAction, succesCreateCallPreferenceAsyncAction, showcreateCallPreferenceErrorAction } = InviteSlice.actions;
    try {
        yield put(startCreateCallPreferenceAsyncAction());
        yield call(InviteRepository.postCallPreference, action.payload);
        yield put(succesCreateCallPreferenceAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateCallPreferenceErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getCallPreferencesAsyncAction, getCallPreferencesSaga);
    yield takeLatest(createInviteValidatesInfoAsyncAction, createInviteValidatesInfoSaga);
    yield takeLatest(createInviteChangePhoneNumberRequestInfoAsyncAction, createInviteChangePhoneNumberRequestInfoSaga);
    yield takeLatest(createInvitePhoneNumberVarificationInfoAsyncAction, createInvitePhoneNumberVarificationInfoSaga);
    yield takeLatest(createInviteChangePasswordInfoAsyncAction, createInviteChangePasswordInfoSaga);
    yield takeLatest(createCallPreferenceAsyncAction, createCallPreferenceSaga);
}
