import _ from "lodash";
import ApiManager from "../api/ApiManager";
import config from "../../../config.json";
const version = "1.2";
const PATIENT_FILES = `/v${version}/patient-files`;
const SCHEDULE_CALL = `/v${version}/call-schedule`;
const AVAILABLE_CALL_TIMES = `/v${version}/call-schedule/available-times`;
const FINANCIAL_ASSISTANCE_CONFIG = `/v${version}/financial-assistance/config`;
const FINANCIAL_ASSISTANCE = `/v${version}/patient/financial-assistance`;
const PREVIEW_PHOTO = `/v${version}/patient-files/preview`;
const PATIENT_FILES_TEXT = `/v${version}/patient-files/text-value`;
class PatientFilesRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new PatientFilesRepository();
        }
        return this.instance;
    }
    static uploadFiles = (payload) => {
        return ApiManager.getInstance().createUploadFileChannel(PATIENT_FILES, payload);
    };
    static getAvailableTimes = (utcOffset) => {
        return ApiManager.getInstance().get(AVAILABLE_CALL_TIMES, {
            UtcOffset: utcOffset
        });
    };
    static scheduleCall = (payload) => {
        return ApiManager.getInstance().post(SCHEDULE_CALL, { ...payload, applicationType: config.applicationType });
    };
    static getPatientFiles = (payload) => {
        if (_.isNull(payload.drugBrandCode)) {
            return ApiManager.getInstance().get(PATIENT_FILES, {
                PatientId: payload.patientId,
                PhotosType: payload.photosType,
                PhotosSizeType: "RectangleThumbnail"
            });
        }
        else {
            return ApiManager.getInstance().get(PATIENT_FILES, {
                PatientId: payload.patientId,
                PhotosType: payload.photosType,
                DrugBrandCode: payload.drugBrandCode,
                PhotosSizeType: "RectangleThumbnail"
            });
        }
    };
    static deletePatientFiles = (id, type) => {
        return ApiManager.getInstance().delete(PATIENT_FILES, { id: id, deleteType: type });
    };
    static getFinancialAssistanceConfig = (payload) => {
        return ApiManager.getInstance().get(FINANCIAL_ASSISTANCE_CONFIG, {
            DrugBrandCode: payload.drugBrandCode,
            Province: payload.province,
        });
    };
    static getFinancialAssistance = (payload) => {
        return ApiManager.getInstance().get(FINANCIAL_ASSISTANCE, {
            PatientId: payload.patientId,
            DrugBrandCode: payload.drugBrandCode
        });
    };
    static sendFinancialAssistance = (payload) => {
        return ApiManager.getInstance().post(FINANCIAL_ASSISTANCE, payload);
    };
    static getPreviewPhoto = (id) => {
        return ApiManager.getInstance().get(PREVIEW_PHOTO, { FileId: id });
    };
    static setPatientFilesByText = (payload) => {
        return ApiManager.getInstance().post(PATIENT_FILES_TEXT, payload);
    };
}
export default PatientFilesRepository;
