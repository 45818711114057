import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
export const SLICE_NAME = "EDUCATION";
export const educationAdapter = createEntityAdapter({
    selectId: (library) => library.educationId
});
const initialState = {
    requestGetEducationItemsStatus: "idle",
    requestUpdateEducationInfoStatus: "idle",
    requestEducationPostStatus: "idle",
    requestEducationPostError: null,
    requestUpdateEducationInfoError: null,
    requestGetEducationItemsError: null,
    requestDeleteEducationDataStatus: "idle",
    requestDeleteEducationDataError: null,
    Education: undefined,
    requestStatus: "idle",
    requestError: null,
    uniqueTags: [],
    Tags: [],
    items: []
};
export const educationSelectors = educationAdapter.getSelectors();
const educationSlice = createSlice({
    name: SLICE_NAME,
    initialState: educationAdapter.getInitialState(initialState),
    reducers: {
        setDeleteEducationDataStatus: (state, action) => {
            state.requestDeleteEducationDataStatus = action.payload;
        },
        setUpdateEducationInfoStatus: (state, action) => {
            state.requestUpdateEducationInfoStatus = action.payload;
        },
        setPostEducationDataStatus: (state, action) => {
            state.requestEducationPostStatus = action.payload;
        },
        startGetEducationItemsAction: (state) => {
            state.requestGetEducationItemsStatus = "pending";
            state.requestGetEducationItemsError = null;
        },
        successGetEducationItemsAction: (state) => {
            state.requestGetEducationItemsStatus = "success";
            state.requestGetEducationItemsError = null;
        },
        showGetEducationItemsErrorAction: (state, action) => {
            state.requestGetEducationItemsStatus = "failure";
            state.requestGetEducationItemsError = action.payload;
        },
        //create
        startCreateEducationInfoAsyncAction: (state) => {
            state.requestEducationPostStatus = "pending";
            state.requestEducationPostError = null;
        },
        succesCreateEducationInfoAsyncAction: (state, action) => {
            localStorage.setItem('savedMinmeType', action.payload.mimeType);
            state.requestEducationPostStatus = "success";
            state.requestEducationPostError = null;
        },
        showcreateEducationInfoErrorAction: (state, action) => {
            state.requestEducationPostStatus = "failure";
            state.requestEducationPostError = action.payload;
        },
        //update
        startRequestUpdateEducationInfoAction: (state) => {
            state.requestUpdateEducationInfoStatus = "pending";
            state.requestUpdateEducationInfoError = null;
        },
        successRequestUpdateEducationInfoAction: (state, action) => {
            localStorage.setItem('savedMinmeType', action.payload.mimeType);
            state.requestUpdateEducationInfoStatus = "success";
            state.requestUpdateEducationInfoError = null;
            state.Education = action.payload;
        },
        showRequestUpdateEducationInfoErrorAction: (state, action) => {
            state.requestUpdateEducationInfoStatus = "failure";
            state.requestUpdateEducationInfoError = action.payload;
        },
        //delete
        startRequestDeleteEducationDataAction: (state) => {
            state.requestDeleteEducationDataStatus = "pending";
            state.requestDeleteEducationDataError = null;
        },
        deleteEducationDataAction: (state, action) => {
            state.requestDeleteEducationDataStatus = "success";
            state.requestDeleteEducationDataError = null;
        },
        showRequestDeleteEducationDataErrorAction: (state, action) => {
            state.requestDeleteEducationDataStatus = "failure";
            state.requestDeleteEducationDataError = action.payload;
        },
        //tags
        successTagsRequestAction: (state, action) => {
            state.requestStatus = "success";
            state.Tags = action.payload;
            state.requestError = null;
        },
        startTagsRequestAction: (state) => {
            state.requestStatus = "pending";
            state.requestError = null;
        },
        showTagsErrorAction: (state, action) => {
            state.requestStatus = "failure";
            state.requestError = action.payload;
        },
        ///
        setEducationItemsAction: (state, action) => {
            state.items = action.payload;
        },
        setTagsAction: (state, action) => {
            state.uniqueTags = action.payload;
        },
    }
});
export default educationSlice;
