import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { setAccountTypeAction } from "../../../../store";
import { Button } from "../../../../components/Shared/Controls";
import AccountTypeCard from "../../../../components/Shared/AccountTypeCard";
import PatientPage from "../PatientPage";
import s from './style.scss';
import images from "../../../../resources/images";
import Typography from "../../../../components/Shared/Typography";
import { Box } from "../../../../components";
const AccountTypePage = ({ accountType, patientInfo, previusStep, header, setAccountType, onSumbit }) => {
    const { t } = useTranslation();
    const [selectedTypeOfAccount, setTypeOfAccount] = useState(accountType);
    const [someoneElsePage, showSomeoneElsePage] = useState(patientInfo.relationship || false);
    const onClickHandler = () => {
        if (selectedTypeOfAccount === "myself") {
            onSumbit && onSumbit();
            setAccountType("myself");
        }
        if (selectedTypeOfAccount === "someone") {
            showSomeoneElsePage(true);
            setAccountType("someone");
        }
    };
    return someoneElsePage ? React.createElement(PatientPage, { onSumbit: onSumbit, header: header, previusStep: () => showSomeoneElsePage(false) }) : (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.form },
            React.createElement("div", { className: s.contentWrapper },
                React.createElement("div", { className: s.aboutStep },
                    React.createElement(Typography, { type: "paragraph", color: 'neutralDark' }, t("youCreatingAccountFor"))),
                React.createElement("div", { className: s.cardList },
                    React.createElement(AccountTypeCard, { typeIcon: React.createElement("img", { src: images.MyselfIcon, className: s.devices }), text: t("myself"), active: selectedTypeOfAccount === "myself", onClick: () => setTypeOfAccount("myself") }),
                    React.createElement(AccountTypeCard, { typeIcon: React.createElement("img", { src: images.SomeoneIcon, className: s.devices }), text: t("someoneElse"), active: selectedTypeOfAccount === "someone", onClick: () => setTypeOfAccount("someone") })),
                React.createElement(Box, { f1: true, row: true, gap: 16 },
                    React.createElement(Button, { text: t("back"), onClick: previusStep }),
                    React.createElement(Button, { disabled: !selectedTypeOfAccount, primary: true, text: t("saveAndProceed"), onClick: onClickHandler }))))));
};
const mapStateToProps = (state) => {
    return {
        accountType: state.auth.accountType,
        patientInfo: state.auth.patient
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setAccountType: (accountType) => dispatch(setAccountTypeAction(accountType))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountTypePage);
