import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import SignIn from "../../Pages/SingIn";
import SignUp from "../../Pages/SignUp";
import Settings from "../../Pages/Settings";
import AddMember from "../../Pages/AddMember";
import HomePage from "../../Pages/HomePage";
import Profile from "../../Pages/Profile";
import ResetPassword from "../../Pages/ResetPassword";
import { SideBarRoute } from "./SideBarRouter";
import MedicalDocuments from "../../Pages/MedicalDocuments";
import HealthInformationPage from "../../Pages/HealthInformationPage";
import PersonalData from "../../Pages/PersonalData";
import AdditionalSteps from "../../Pages/AdditionalSteps";
import SchedulePhoneCallPage from "../../Pages/SchedulePhoneCallPage";
import PrescriptionsPage from "../../Pages/PrescriptionsPage";
import ReceiptsPage from "../../Pages/ReceiptsPage";
import HealthCardPage from "../../Pages/HealthCardPage";
import InsurancesPage from "../../Pages/InsurancesPage";
import FinancialAssistancePage from "../../Pages/FinansialAssistancePage";
import HelpChat from "../../Pages/HelpChat";
import Notifications from "../../Pages/Notifications";
import EducationPage from "../../Pages/EducationPage";
import MedicationReminders from "../../Pages/MedicationReminders";
import Sidebar from "../Sidebar";
import s from "../../App.scss";
import PageContainer from "../Shared/PageContainer";
import Logout from "../../Pages/Logout";
import PrescriberAdmin from "../../Pages/PrescriberAdmin";
import PrescriberAdminTable from "../../Pages/PrescriberAdmin/PrescriberTable";
import PatientsAdminList from "../../Pages/Patients";
import { isAdminAction, resetLengthStepsToComplete, setDataOnReloadPage, setImpersonateInfo, setImpersonatedAccountId, setLengthStepsToComplete, setPatientIdForImpersonate } from "../../store";
import { getRegistrationInfoAsyncAction, setPatientdataAction, updateIsAdminAction } from "../../store/account/Sagas";
import { connect } from "react-redux";
import MedicalConditions from "../../Pages/MedicalConditions";
import MedicalConditionsTable from "../../Pages/MedicalConditions/MedicalConditionsTable";
import InvitationEmail from "../../Pages/SignUp/Steps/InvitationEmail";
import PharmacyList from "../../Pages/PharmacyList";
import InfusionClinic from "../../Pages/InfusionClinicList";
import AddInfusionClinic from "../../Pages/SignUp/Steps/InvitationEmail/Components/AddInfusionClinic";
import AddPharmacyList from "../../Pages/SignUp/Steps/InvitationEmail/Components/AddPharmacy";
import AddPharmacy from "../../Pages/SignUp/Steps/InvitationEmail/Components/AddPharmacyManual";
import SelectPharmacy from "../../Pages/SignUp/Steps/InvitationEmail/Components/SelectPharmacy";
import PharmacyAdmin from "../../Pages/PharmacyAdmin";
import PharmacyAdminTable from "../../Pages/PharmacyAdmin/PharmaciesTable";
import ComingSoon from "../../Pages/ComingSoon";
import AddPrescriberManual from "../../Pages/SignUp/Steps/InvitationEmail/Components/AddPrescriberManual";
import InfusionClinicAdmin from "../../Pages/InfusionClinicAdmin";
import InfusionClinicAdminTable from "../../Pages/InfusionClinicAdmin/InfusionClinicAdminsideTable";
import NotAuthorized from "../../Pages/NotAuthorized";
import NotFound from "../../Pages/NotFound";
import EducationDetails from "../../Pages/EducationPage/components/EducationDetails";
import AddedPharmacy from "../../Pages/SignUp/Steps/InvitationEmail/Components/AddedPharmacy";
import PrescriberList from "../../Pages/PrescriberList";
import CallPreferencesPage from "../../Pages/CallPreferencesPage";
import Support from "../../Pages/Support";
import InviteRedirect from "../../Pages/InviteRedirect";
const Router = ({ isAdmin, updateStateIsAdmin, setPatientdataFun, patientData, backAction, LengthStepsToComplete, storeDataForAction, setLengthStepsToCompleteAction, existingEmailImersonate, phoneNumberImpersonate, lastnameImpersonate, setImpersonatedAccountId, firstnameImpersonate, exitingPatientId, exitingImpersonatedAccountId, setImpersonateInfoAction, getRegistrationInfo, InviteLoginStoredData, resetLengthStepsToCompleteAction, isAdminAuth, isAdminAction, adminBrandCode }) => {
    const navigate = useNavigate();
    const LoginAPIResponse = localStorage.getItem('LoginAPIResponse');
    //chrome back button
    useEffect(() => {
        const handleBrowserBack = () => {
            if (location.pathname === "/patients") {
                setPatientIdForImpersonate(exitingPatientId);
                setImpersonatedAccountId(exitingImpersonatedAccountId);
                const data = {
                    firstName: firstnameImpersonate,
                    lastName: lastnameImpersonate,
                    phoneNumber: phoneNumberImpersonate,
                    email: existingEmailImersonate
                };
                setImpersonateInfoAction(data);
                getRegistrationInfo({
                    member: {
                        patientId: exitingPatientId,
                        //TODO: for admin not required invite brand code we need patient or user brand code
                        drugBrandCode: adminBrandCode,
                    },
                    isRegistrationFlow: false,
                });
                navigate('/patients');
                setPatientdataFun({
                    patientId: null,
                    firstName: null,
                    lastName: null
                });
                updateStateIsAdmin(true);
            }
        };
        window.onpopstate = handleBrowserBack;
        return () => {
            window.onpopstate = null;
        };
    }, []);
    useEffect(() => {
        if (patientData?.patientId != null) {
            updateStateIsAdmin(false);
        }
    }, [updateStateIsAdmin]);
    useEffect(() => {
        isAdminAction(isAdminAuth);
        if (isAdminAuth == true) {
            resetLengthStepsToCompleteAction();
            navigate('/patients');
        }
    }, [isAdminAuth]);
    useEffect(() => {
        if (LoginAPIResponse) {
            const IsLengthStepsToComplete = localStorage.getItem("LengthStepsToComplete");
            // if(isAdmin){
            //   resetLengthStepsToCompleteAction();
            //   navigate('/');
            // }
            // else{
            setLengthStepsToCompleteAction(IsLengthStepsToComplete);
            if (IsLengthStepsToComplete == 'false') {
                setLengthStepsToCompleteAction(false);
            }
            else {
                setLengthStepsToCompleteAction(true);
            }
            // }
            const data = JSON.parse(LoginAPIResponse);
            storeDataForAction(data);
        }
    }, []);
    const holdDataForReload = localStorage.getItem('holdDataForReload');
    useEffect(() => {
        const handleBrowserNext = (event) => {
            if (window.location.pathname === '/profile') {
                const localstorageholdDataForReload = JSON.parse(holdDataForReload);
                updateStateIsAdmin(false);
                setPatientdataFun({
                    patientId: localstorageholdDataForReload?.patientId
                });
            }
        };
        window.addEventListener('popstate', handleBrowserNext);
        return () => {
            window.removeEventListener('popstate', handleBrowserNext);
        };
    }, [window.location]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/signin", element: React.createElement(SignIn, null) }),
            React.createElement(Route, { path: "/", element: Cookies.get("token") ? (LengthStepsToComplete === true
                    ? React.createElement(AdditionalSteps, null)
                    : React.createElement(PageContainer, null,
                        React.createElement("div", { className: s.sideBarRouteWrapper },
                            React.createElement(Sidebar, null),
                            React.createElement(HomePage, null)))) : (React.createElement(SignIn, null)) }),
            React.createElement(Route, { path: "/signup", element: React.createElement(SignUp, { existAzureUser: false }) }),
            React.createElement(Route, { path: "/additional", element: React.createElement(AdditionalSteps, null) }),
            React.createElement(Route, { path: "/schedule-phone-call", element: React.createElement(SchedulePhoneCallPage, null) }),
            React.createElement(Route, { path: "/support", element: React.createElement(Support, null) }),
            React.createElement(Route, { path: "/appinvite", element: React.createElement(InviteRedirect, null) }),
            React.createElement(Route, { path: "/", element: React.createElement(SideBarRoute, { path: undefined, component: undefined }) },
                isAdmin == true ? (React.createElement(React.Fragment, null,
                    React.createElement(Route, { path: "/brands", element: React.createElement(ComingSoon, null) }),
                    React.createElement(Route, { path: "/prescribers/create", element: React.createElement(PrescriberAdmin, null) }),
                    React.createElement(Route, { path: "/prescribers", element: React.createElement(PrescriberAdminTable, null) }),
                    React.createElement(Route, { path: "/prescriber/:id", element: React.createElement(PrescriberAdmin, null) }),
                    React.createElement(Route, { path: "/patients", element: React.createElement(PatientsAdminList, null) }),
                    React.createElement(Route, { path: "/settings", element: React.createElement(Settings, null) }),
                    React.createElement(Route, { path: "/education/admin", element: React.createElement(EducationPage, null) }),
                    React.createElement(Route, { path: "/education/create", element: React.createElement(EducationDetails, null) }),
                    React.createElement(Route, { path: "/medicalConditions/create", element: React.createElement(MedicalConditions, null) }),
                    React.createElement(Route, { path: "/medicalConditions", element: React.createElement(MedicalConditionsTable, null) }),
                    React.createElement(Route, { path: "/medicalConditions/:id", element: React.createElement(MedicalConditions, null) }),
                    React.createElement(Route, { path: "/pharmacies/create", element: React.createElement(PharmacyAdmin, null) }),
                    React.createElement(Route, { path: "/pharmacies", element: React.createElement(PharmacyAdminTable, null) }),
                    React.createElement(Route, { path: "/pharmacies/:id", element: React.createElement(PharmacyAdmin, null) }),
                    React.createElement(Route, { path: "/webhooks", element: React.createElement(ComingSoon, null) }),
                    React.createElement(Route, { path: "/apikeys", element: React.createElement(ComingSoon, null) }),
                    React.createElement(Route, { path: "/infusionclinic/create", element: React.createElement(InfusionClinicAdmin, null) }),
                    React.createElement(Route, { path: "/infusionclinic", element: React.createElement(InfusionClinicAdminTable, null) }),
                    React.createElement(Route, { path: "/infusionclinic/:id", element: React.createElement(InfusionClinicAdmin, null) }))) :
                    React.createElement(React.Fragment, null,
                        React.createElement(Route, { path: "/brands", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/prescribers/create", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/prescribers", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/prescribers/:id", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/patients", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/education/create", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/medicalConditions/create", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/medicalConditions", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/medicalConditions/:id", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/pharmacies/create", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/pharmacies", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/pharmacies/:id", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/webhooks", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/apikeys", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/infusionclinic/create", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/infusionclinic", element: React.createElement(NotAuthorized, null) }),
                        React.createElement(Route, { path: "/infusionclinic/:id", element: React.createElement(NotAuthorized, null) })),
                React.createElement(Route, { path: "/financial-assistance", element: React.createElement(FinancialAssistancePage, null) }),
                React.createElement(Route, { path: "/personal-data", element: React.createElement(PersonalData, null) }),
                React.createElement(Route, { path: "/communication-preferences", element: React.createElement(CallPreferencesPage, null) }),
                React.createElement(Route, { path: "/education", element: React.createElement(EducationPage, null) }),
                React.createElement(Route, { path: "/settings", element: React.createElement(Settings, null) }),
                React.createElement(Route, { path: "/medication-reminders", element: React.createElement(MedicationReminders, null) }),
                React.createElement(Route, { path: "/add-member", element: React.createElement(AddMember, null) }),
                React.createElement(Route, { path: "/profile", element: React.createElement(Profile, null) }),
                React.createElement(Route, { path: "/prescriptions", element: React.createElement(PrescriptionsPage, null) }),
                React.createElement(Route, { path: "/receipts", element: React.createElement(ReceiptsPage, null) }),
                React.createElement(Route, { path: "/health-card", element: React.createElement(HealthCardPage, null) }),
                React.createElement(Route, { path: "/insurances", element: React.createElement(InsurancesPage, null) }),
                React.createElement(Route, { path: "/medical-documents", element: React.createElement(MedicalDocuments, null) }),
                React.createElement(Route, { path: "/prescriber", element: React.createElement(PrescriberList, null) }),
                React.createElement(Route, { path: "/prescriber/add-manual", element: React.createElement(AddPrescriberManual, { prevStep: () => navigate("/prescriber"), isUserDashboard: true }) }),
                React.createElement(Route, { path: "/health-information", element: React.createElement(HealthInformationPage, null) }),
                React.createElement(Route, { path: "/help-chat", element: React.createElement(HelpChat, null) }),
                React.createElement(Route, { path: "/pharmacy", element: React.createElement(PharmacyList, null) }),
                React.createElement(Route, { path: "/infusion-Clinic", element: React.createElement(InfusionClinic, null) }),
                React.createElement(Route, { path: "/infusion-Clinic/add-infusion-clinic", element: React.createElement(AddInfusionClinic, { isDashboard: true }) }),
                React.createElement(Route, { path: "/pharmacy/add-pharmacy", element: React.createElement(AddPharmacyList, { isDashboard: true }) }),
                React.createElement(Route, { path: "/pharmacy/add-pharmacy/add-my-own-pharmacy", element: React.createElement(AddPharmacy, { isDashboard: true }) }),
                React.createElement(Route, { path: "/pharmacy/select-pharmacy", element: React.createElement(SelectPharmacy, { isDashboard: true }) }),
                React.createElement(Route, { path: "/pharmacy/added-pharmacy", element: React.createElement(AddedPharmacy, { isNewSignUp: false, setStep: () => { navigate('/pharmacy'); }, previousStep: () => { navigate('/pharmacy/select-pharmacy'); } }) })),
            window.innerWidth <= 1024 && (React.createElement(Route, { path: "/notifications", element: React.createElement(Notifications, { hasUnViewed: false }) })),
            React.createElement(Route, { path: "/logout", element: React.createElement(Logout, null) }),
            React.createElement(Route, { path: "/reset-password", element: React.createElement(ResetPassword, null) }),
            React.createElement(Route, { path: "/invite/:accountid/:code", element: React.createElement(InvitationEmail, null) }),
            React.createElement(Route, { path: "/*", element: React.createElement(NotFound, null) }))));
};
const mapStateToProps = (state) => {
    return {
        isAdmin: state.account.isAdmin,
        patientData: state.account.selectedpatient,
        InviteLoginStoredData: state.auth.InviteLoginStoredData,
        LengthStepsToComplete: state.auth.LengthStepsToComplete,
        firstnameImpersonate: state.account.ExistingfirstName,
        lastnameImpersonate: state.account.ExistinglastName,
        phoneNumberImpersonate: state.account.user.phoneNumber,
        existingEmailImersonate: state.account.existingEmailImersonate,
        exitingPatientId: state.account.exitingPatientId,
        exitingImpersonatedAccountId: state.account.exitingImpersonatedAccountId,
        isAdminAuth: state.auth.isAdmin,
        adminBrandCode: state.auth.adminBrandCode
    };
};
function mapDispatchToProps(dispatch) {
    return {
        storeDataForAction: (data) => dispatch(setDataOnReloadPage(data)),
        setLengthStepsToCompleteAction: (data) => dispatch(setLengthStepsToComplete(data)),
        resetLengthStepsToCompleteAction: () => dispatch(resetLengthStepsToComplete()),
        isAdminAction: (data) => dispatch(isAdminAction(data)),
        setPatientdataFun: (data) => dispatch(setPatientdataAction({
            data
        })),
        updateStateIsAdmin: (isAdmin) => dispatch(updateIsAdminAction(isAdmin)),
        setPatientIdForImpersonate: (data) => dispatch(setPatientIdForImpersonate(data)),
        setImpersonatedAccountId: (data) => dispatch(setImpersonatedAccountId(data)),
        setImpersonateInfoAction: (data) => dispatch(setImpersonateInfo(data)),
        getRegistrationInfo: (payload) => dispatch(getRegistrationInfoAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Router);
