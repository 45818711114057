import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { BrandsRepository } from "../repository";
import { handleApiError } from "../utils";
import brandSlice from "./Slice";
import { mapBrands, mapBrandSettingsResponse } from "./Mapper";
import navigationSlice from "../navigation/Slice";
import { doSearchAction, getBrandsAsyncAction, getBrandSettingsAsyncAction, getTestBrandsAsyncAction } from ".";
export function* doSearchSaga(action) {
    yield put(brandSlice.actions.setSearchValueAction(action.payload));
}
export function* getBrandsSaga(action) {
    const { startGetBrandsRequestAction, showGetBrandsErrorAction, setBrandsAction } = brandSlice.actions;
    try {
        yield put(startGetBrandsRequestAction());
        const result = yield call(BrandsRepository.getBrands);
        const brands = yield mapBrands(result.data);
        yield put(setBrandsAction(brands));
    }
    catch (error) {
        yield handleApiError(error, showGetBrandsErrorAction);
    }
}
export function* getTestBrandsSaga(action) {
    const { startGetTestBrandsRequestAction, showGetTestBrandsErrorAction, setBrandsAction } = brandSlice.actions;
    try {
        yield put(startGetTestBrandsRequestAction());
        const result = yield call(BrandsRepository.getBrands, true);
        const brands = yield mapBrands(result.data);
        yield put(setBrandsAction(brands));
    }
    catch (error) {
        yield handleApiError(error, showGetTestBrandsErrorAction);
    }
}
export function* getBrandSettingsSaga(action) {
    const { startRequestAction, showErrorAction, successRequestAction } = brandSlice.actions;
    const { setStepsAction } = navigationSlice.actions;
    try {
        yield put(startRequestAction());
        const result = yield call(BrandsRepository.getBrandSettings, action.payload);
        const mapped = yield mapBrandSettingsResponse(result);
        yield put(successRequestAction(mapped.allowCaregiver));
        yield put(setStepsAction({
            steps: mapped.steps,
            isRegistrationFlow: true
        }));
    }
    catch (error) {
        yield handleApiError(error, showErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getBrandsAsyncAction, getBrandsSaga);
    yield throttle(1000, doSearchAction, doSearchSaga);
    yield takeLatest(getBrandSettingsAsyncAction, getBrandSettingsSaga);
    yield takeLatest(getTestBrandsAsyncAction, getTestBrandsSaga);
}
