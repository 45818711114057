import React, { useState } from "react";
import classNames from 'classnames';
import Chevron from '../../../../../resources/icons/chevron_down';
import s from './style.scss';
import colors from "../../../../../resources/colors";
import componentStyles from "../../../../../Components/Shared/Controls/Inputs/styles.scss";
const ValueDropdown = ({ style, listStyle, color, data, value, setValue, uppercase }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggling = () => setIsOpen(!isOpen);
    return (React.createElement("div", { "data-testid": "dropdown", style: { backgroundColor: colors.primaryshades3 }, className: classNames(componentStyles.input, s.dropdown), onClick: toggling },
        React.createElement("div", { className: classNames(s.select, style) },
            React.createElement("div", { "data-testid": "dropdownValue", className: s.value, style: { paddingTop: 16, color: color } }, value?.display || value),
            React.createElement(Chevron, { color: color, style: classNames(s.chevron, { [s.chevron_active]: isOpen }) })),
        isOpen && (React.createElement("div", { "data-testid": "dropdownList", className: classNames(s.dropdown_list, listStyle) }, data.map((value) => (React.createElement("div", { key: value.key || value, className: s.dropdown_list_item, onClick: () => {
                setValue(value);
                toggling();
            } }, !value.key ? (uppercase ? `${value}`.toUpperCase() : value) : value.display)))))));
};
export default ValueDropdown;
