import React, { useEffect } from "react";
import { Box } from "../../../../components";
import Typography from "../../../../components/Shared/Typography";
import Alarm from "../../../../resources/images/alarm.png";
import s from "./style.scss";
import { Link } from "react-router-dom";
// @ts-ignore
import { t } from "i18next";
import { connect } from "react-redux";
import { allMedicalRemindersSelector, getRemindersAsyncAction } from "../../../../store/medicalReminders";
import colors from "../../../../resources/colors";
import moment from "moment";
import classNames from "classnames";
const HomeReminders = ({ patientReminders, getReminders }) => {
    useEffect(() => {
        getReminders();
    }, []);
    const computeNextOccurrence = (item) => {
        // Extract information from item
        const { medicationTimes, repeatType, daysOfTheWeek, startDate, endDate, repeatIntervalInDays } = item;
        // Convert dates and times to moment objects
        const medicationMomentTime = moment(medicationTimes[0], 'hh:mm A');
        const startMomentDate = moment(startDate);
        const endMomentDate = moment(endDate);
        const now = moment();
        // Make a copy of start date
        let nextOccurrenceMoment = startMomentDate.clone();
        // Adjust the time component of nextOccurrenceMoment to match medicationTimes[0]
        // to consider cases where start time is less than medication time
        nextOccurrenceMoment.hour(medicationMomentTime.hour()).minute(medicationMomentTime.minute());
        const medicationDates = medicationTimes.map(time => moment(time, "hh:mm A"));
        const nextTimeToday = medicationDates.find(time => time.isAfter(now));
        const firstTime = moment(medicationTimes[0], 'HH:mm');
        // If startDate is in the past, calculate next occurrence based on repeatType
        if (now.isAfter(nextOccurrenceMoment)) {
            switch (repeatType) {
                case "SingleReminder":
                    return "N/A";
                case "EveryDay":
                    if (!!nextTimeToday) {
                        nextOccurrenceMoment = moment().hour(nextTimeToday.hours()).minute(nextTimeToday.minutes());
                    }
                    else {
                        nextOccurrenceMoment = moment().startOf('day').add(1, 'day').add({ hours: medicationDates[0].hours(), minutes: medicationDates[0].minutes() });
                    }
                    break;
                case "RepeatEveryXDays":
                    if (!repeatIntervalInDays)
                        return "Next occurrence calculation error";
                    const daysPassed = now.diff(startMomentDate, 'days');
                    const nextIntervalDay = now.clone().add(daysPassed % repeatIntervalInDays, 'day');
                    nextOccurrenceMoment = nextIntervalDay.clone().hour(firstTime.get('hour')).minute(firstTime.get('minute'));
                    if (nextOccurrenceMoment.isSame(now, 'day') && !!nextTimeToday) {
                        nextOccurrenceMoment = nextIntervalDay.clone().hour(nextTimeToday.get('hour')).minute(nextTimeToday.get('minute'));
                    }
                    else if (nextOccurrenceMoment.isSame(now, 'day')) {
                        const nextNextIntervalDay = nextIntervalDay.clone().add(repeatIntervalInDays, 'day');
                        nextOccurrenceMoment = nextNextIntervalDay.hour(firstTime.hour()).minute(firstTime.minute());
                    }
                    break;
                case "SpecificDaysOfTheWeek":
                    if (!daysOfTheWeek)
                        return "Next occurrence calculation error.";
                    const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    const currentDayIndex = now.day() === 0 ? 6 : now.day() - 1;
                    const daysOfWeekIndexes = daysOfTheWeek.map(day => WEEKDAYS.indexOf(day));
                    const nextDayIndex = daysOfWeekIndexes.find(index => index >= currentDayIndex);
                    if (nextDayIndex === undefined || (nextDayIndex == currentDayIndex && nextTimeToday == null)) {
                        nextOccurrenceMoment = now.clone().startOf('day').add(daysOfWeekIndexes[0] + 7 - currentDayIndex, 'days').hour(firstTime.get('hour')).minute(firstTime.get('minute'));
                    }
                    else if (nextDayIndex == currentDayIndex && nextTimeToday != null) {
                        nextOccurrenceMoment = now.clone().startOf('day').hour(nextTimeToday.get('hour')).minute(nextTimeToday.get('minute'));
                    }
                    else {
                        nextOccurrenceMoment = now.clone().startOf('day').add(nextDayIndex - currentDayIndex, 'days').hour(firstTime.get('hour')).minute(firstTime.get('minute'));
                    }
                    break;
            }
        }
        // If endDate is in the past, or nextOccurrenceMoment is in the past, there is N/A
        if (now.isAfter(endMomentDate.endOf('day')) || nextOccurrenceMoment.isAfter(endMomentDate.endOf('day'))) {
            return "N/A";
        }
        if (nextOccurrenceMoment) {
            // nextOccurrenceMoment.hour(medicationMomentTime.hour());
            // nextOccurrenceMoment.minute(medicationMomentTime.minute());
            if (now.isSame(nextOccurrenceMoment, 'day')) {
                return "Today @ " + nextOccurrenceMoment.format('hh:mm A');
            }
            else {
                return nextOccurrenceMoment.format('MMM/DD @ hh:mm A');
            }
        }
        else {
            return "N/A";
        }
    };
    patientReminders = patientReminders.filter(item => computeNextOccurrence(item) != "N/A");
    return (React.createElement("div", { className: s.reminders },
        React.createElement("div", { className: classNames([s.container, s.scrollWrapper]) },
            React.createElement(Box, { f1: true, column: true, mt: 32, ph: 32, gap: 12 },
                React.createElement(Box, { f1: true, row: true, justifyBetween: true, alignCenter: true, wrap: true },
                    React.createElement(Typography, { type: 'header_1', color: 'neutralDark', bold: true },
                        patientReminders.length,
                        " ",
                        t("reminders")),
                    React.createElement(Link, { to: '/medication-reminders' },
                        React.createElement(Typography, { type: 'header_4', color: 'primary', bold: true }, t("manageReminders")))),
                patientReminders.length == 0 &&
                    React.createElement(Box, { f1: true, row: true, gap: 24 },
                        React.createElement("img", { className: s.image, src: Alarm }),
                        React.createElement(Typography, { type: 'subtitle_1', color: 'neutralDark', bold: true }, t("youHaveNoRemindersYet"))),
                patientReminders.map(item => {
                    let nextOccurence = computeNextOccurrence(item);
                    if (nextOccurence == "N/A")
                        return null;
                    return React.createElement(Box, { key: item.id, f1: true, row: true, gap: 12 },
                        React.createElement("span", { className: s.timeWrapper, style: { backgroundColor: colors.primary, padding: "5px", borderRadius: "4px" } },
                            React.createElement(Typography, { type: 'subtitle_1', color: 'white' }, nextOccurence)),
                        React.createElement(Typography, { type: 'subtitle_1', color: 'neutralDark', bold: true }, item.title));
                })))));
};
const mapStateToProps = (state) => {
    return {
        patientReminders: allMedicalRemindersSelector(state)
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getReminders: () => dispatch(getRemindersAsyncAction())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeReminders);
