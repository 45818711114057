import React from 'react';
import s from './style.scss';
import { useTranslation } from 'react-i18next';
const Switch = ({ style, isSwitched, disabled, onSwitch }) => {
    const { t } = useTranslation();
    return (React.createElement("label", { className: s.switch, onClick: onSwitch, id: "button-12" },
        React.createElement("input", { type: "checkbox", className: s.checkbox, disabled: disabled, checked: isSwitched, onChange: onSwitch }),
        React.createElement("div", { className: s.slider, onClick: onSwitch, id: "button-12" })));
};
export default Switch;
