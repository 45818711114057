import React from "react";
import s from './style.scss';
import colors from '../../../resources/colors';
const Typography = (props) => {
    let styles = `${s[props.type]}`;
    if (props.bold) {
        styles += ` ${s.bold}`;
    }
    if (props.color) {
        styles += ` `;
    }
    return (React.createElement("span", { className: styles, style: {
            color: props.color && colors[props.color],
            ...props.style
        } }, props.children));
};
export default Typography;
