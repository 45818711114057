import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "PHARMACY";
const initialState = {
    requestPharmacyPostStatus: "idle",
    requestPharmacyPostError: null,
    requestGetPharmacyStatus: "idle",
    requestGetPharmacyError: null,
    getPharmacyData: undefined,
    requestPharmacyPostDataStatus: "idle",
    requestPharmacyPostDataError: null,
    requestGetOnlyPharmacyStatus: "idle",
    requestGetOnlyPharmacyError: null,
    getOnlyPharmacyData: null,
    requestDeletePharmacyStatus: "idle",
    requestDeletePharmacyError: null,
    requestPutPharmacyStatus: "idle",
    requestPutPharmacyError: null,
    requestDeletePharmaciesStatus: "idle",
    requestDeletePharmaciesError: null,
    storeAllDataOfPharmacy: null,
    requestPharmaciesPostStatus: "idle",
    requestPharmaciesPostError: null,
    requestUpdatePharmaciesInfoStatus: "idle",
    requestUpdatePharmaciesInfoError: null,
    Pharmacies: undefined,
    requestSingleDataOfPharmacyInfoStatus: "idle",
    requestSingleDataOfPharmacyInfoError: null,
    getSingleDataOfPharmacyInfo: undefined,
};
const PharmacySlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        //put api call resp
        startCreatePharmacyInfoAsyncAction: (state) => {
            state.requestPharmacyPostStatus = "pending";
            state.requestPharmacyPostError = null;
        },
        successCreatePharmacyInfoAsyncAction: (state, action) => {
            state.requestPharmacyPostStatus = "success";
            state.requestPharmacyPostError = null;
        },
        showCreatePharmacyInfoErrorAction: (state, action) => {
            state.requestPharmacyPostStatus = "failure";
            state.requestPharmacyPostError = action.payload;
        },
        resetCreatePharmacyInfoAction: (state) => {
            state.requestPharmacyPostStatus = "idle";
        },
        //get api pharmacy
        startRequestGetPharmacyAction: (state) => {
            state.requestGetPharmacyStatus = "pending";
            state.requestGetPharmacyError = null;
        },
        successRequestGetPharmacyAction: (state, action) => {
            state.requestGetPharmacyStatus = "success";
            state.requestGetPharmacyError = null;
            state.getPharmacyData = action.payload;
        },
        showRequesGetPharmacyErrorAction: (state, action) => {
            state.requestGetPharmacyStatus = "failure";
            state.requestGetPharmacyError = action.payload;
        },
        //post
        startPostPharmacyInfoAsyncAction: (state) => {
            state.requestPharmacyPostDataStatus = "pending";
            state.requestPharmacyPostDataError = null;
        },
        succesPostPharmacyInfoAsyncAction: (state, action) => {
            state.requestPharmacyPostDataStatus = "success";
            state.requestPharmacyPostDataError = null;
        },
        showPostPharmacyInfoErrorAction: (state, action) => {
            state.requestPharmacyPostDataStatus = "failure";
            state.requestPharmacyPostDataError = action.payload;
        },
        resetPostPharmacyInfoAction: (state) => {
            state.requestPharmacyPostDataStatus = "idle";
        },
        //get api only pharmacy
        startRequestGetOnlyPharmacyAction: (state) => {
            state.requestGetOnlyPharmacyStatus = "pending";
            state.requestGetOnlyPharmacyError = null;
        },
        successRequestGetOnlyPharmacyAction: (state, action) => {
            state.requestGetOnlyPharmacyStatus = "success";
            state.requestGetOnlyPharmacyError = null;
            state.getOnlyPharmacyData = action.payload;
        },
        showRequesGetOnlyPharmacyErrorAction: (state, action) => {
            state.requestGetOnlyPharmacyStatus = "failure";
            state.requestGetOnlyPharmacyError = action.payload;
        },
        storeAllDataOfPharmacyAction: (state, action) => {
            state.storeAllDataOfPharmacy = action.payload?.data?.value;
        },
        //delete
        startRequestDeletePharmacyAction: (state) => {
            state.requestDeletePharmacyStatus = "pending";
            state.requestDeletePharmacyError = null;
        },
        deletePharmacyAction: (state, action) => {
            state.requestDeletePharmacyStatus = "success";
            state.requestDeletePharmacyError = null;
        },
        showRequestDeletePharmacyErrorAction: (state, action) => {
            state.requestDeletePharmacyStatus = "failure";
            state.requestDeletePharmacyError = action.payload;
        },
        //put api call resp
        startPutPharmacyInfoAsyncAction: (state) => {
            state.requestPutPharmacyStatus = "pending";
            state.requestPutPharmacyError = null;
        },
        successPutPharmacyInfoAsyncAction: (state, action) => {
            state.requestPutPharmacyStatus = "success";
            state.requestPutPharmacyError = null;
        },
        showPutPharmacyInfoErrorAction: (state, action) => {
            state.requestPutPharmacyStatus = "failure";
            state.requestPutPharmacyError = action.payload;
        },
        resetPutPharmacyInfoAction: (state) => {
            state.requestPutPharmacyStatus = "idle";
        },
        //delete
        startRequestDeletePharmaciesAction: (state) => {
            state.requestDeletePharmaciesStatus = "pending";
            state.requestDeletePharmaciesError = null;
        },
        deletePharmaciesAction: (state, action) => {
            state.requestDeletePharmaciesStatus = "success";
            state.requestDeletePharmaciesError = null;
        },
        showRequestDeletePharmaciesErrorAction: (state, action) => {
            state.requestDeletePharmaciesStatus = "failure";
            state.requestDeletePharmaciesError = action.payload;
        },
        //post api call resp
        startCreatePharmaciesInfoAsyncAction: (state) => {
            state.requestPharmaciesPostStatus = "pending";
            state.requestPharmaciesPostError = null;
        },
        successCreatePharmaciesInfoAsyncAction: (state, action) => {
            state.requestPharmaciesPostStatus = "success";
            state.requestPharmaciesPostError = null;
        },
        showcreatePharmaciesInfoErrorAction: (state, action) => {
            state.requestPharmaciesPostStatus = "failure";
            state.requestPharmaciesPostError = action.payload;
        },
        //update api
        startRequestUpdatePharmaciesInfoAction: (state) => {
            state.requestUpdatePharmaciesInfoStatus = "pending";
            state.requestUpdatePharmaciesInfoError = null;
        },
        successRequestUpdatePharmaciesInfoAction: (state, action) => {
            state.requestUpdatePharmaciesInfoStatus = "success";
            state.requestUpdatePharmaciesInfoError = null;
            state.Pharmacies = action.payload;
        },
        showRequestUpdatePharmaciesInfoErrorAction: (state, action) => {
            state.requestUpdatePharmaciesInfoStatus = "failure";
            state.requestUpdatePharmaciesInfoError = action.payload;
        },
        //get one data of Pharmacies
        startRequestSingleDataOfPharmacyInfoAction: (state) => {
            state.requestSingleDataOfPharmacyInfoStatus = "pending";
            state.requestSingleDataOfPharmacyInfoError = null;
        },
        successRequesSingleDataOfPharmacyInfoAction: (state, action) => {
            state.requestSingleDataOfPharmacyInfoStatus = "success";
            state.requestSingleDataOfPharmacyInfoError = null;
            state.getSingleDataOfPharmacyInfo = action.payload;
        },
        showRequesSingleDataOfPharmacyInfoErrorAction: (state, action) => {
            state.requestSingleDataOfPharmacyInfoStatus = "failure";
            state.requestSingleDataOfPharmacyInfoError = action.payload;
        },
    },
});
export default PharmacySlice;
