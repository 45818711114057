import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "PRESCRIBER";
const initialState = {
    requestPrescriberPostStatus: "idle",
    requestPrescriberPostError: null,
    requestPrescriberInfoStatus: "idle",
    requestPrescriberInfoError: null,
    requestDrugBrandCodesDropdownStatus: "idle",
    requestDrugBrandCodesDropdownError: null,
    requestUpdatePrescriberInfoStatus: "idle",
    requestUpdatePrescriberInfoError: null,
    requestgetAllPrescriberStatus: "idle",
    requestgetAllPrescriberError: null,
    getAllPrescribers: null,
    requestDeletePrescriberStatus: "idle",
    requestDeletePrescriberError: null,
    getPrescriberInfo: null,
    prescriber: {
        DrugBrandCodes: [],
        Name: "",
        Note: "",
        BrandIdentifier: ""
    },
    DrugBrandCodesDropdown: {
        drugBrandCode: "",
        logoLink: "",
    }
};
const presciberSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        //post api call resp
        startCreatePrescriberInfoAsyncAction: (state) => {
            state.requestPrescriberPostStatus = "pending";
            state.requestPrescriberPostError = null;
        },
        succesCreatePrescriberInfoAsyncAction: (state, action) => {
            state.requestPrescriberPostStatus = "success";
            // state.getAllPrescribers.addOne(state, action.payload);
            state.requestPrescriberPostError = null;
        },
        showcreatePrescriberInfoErrorAction: (state, action) => {
            state.requestPrescriberPostStatus = "failure";
            state.requestPrescriberPostError = action.payload;
        },
        //get api
        startRequestPrescriberInfoAction: (state) => {
            state.requestPrescriberInfoStatus = "pending";
            state.requestPrescriberInfoError = null;
        },
        successRequesPrescriberInfoAction: (state, action) => {
            state.requestPrescriberInfoStatus = "success";
            state.requestPrescriberInfoError = null;
            state.getPrescriberInfo = action.payload;
        },
        showRequesPrescriberInfoErrorAction: (state, action) => {
            state.requestPrescriberInfoStatus = "failure";
            state.requestPrescriberInfoError = action.payload;
        },
        //get api DrugBrand dropdown
        startRequestDrugBrandCodesDropdownAction: (state) => {
            state.requestDrugBrandCodesDropdownStatus = "pending";
            state.requestDrugBrandCodesDropdownError = null;
        },
        successRequestDrugBrandCodesDropdownAction: (state, action) => {
            state.requestDrugBrandCodesDropdownStatus = "success";
            state.requestDrugBrandCodesDropdownError = null;
            state.DrugBrandCodesDropdown = action.payload;
        },
        showRequesDrugBrandCodesDropdownErrorAction: (state, action) => {
            state.requestDrugBrandCodesDropdownStatus = "failure";
            state.requestDrugBrandCodesDropdownError = action.payload;
        },
        //update api
        startRequestUpdatePrescriberInfoAction: (state) => {
            state.requestUpdatePrescriberInfoStatus = "pending";
            state.requestUpdatePrescriberInfoError = null;
        },
        successRequestUpdatePrescriberInfoAction: (state, action) => {
            state.requestUpdatePrescriberInfoStatus = "success";
            state.requestUpdatePrescriberInfoError = null;
            state.prescriber = action.payload;
        },
        showRequestUpdatePrescriberInfoErrorAction: (state, action) => {
            state.requestUpdatePrescriberInfoStatus = "failure";
            state.requestUpdatePrescriberInfoError = action.payload;
        },
        //getAll
        startRequestgetAllPrescriberAction: (state) => {
            state.requestgetAllPrescriberStatus = "pending";
            state.requestgetAllPrescriberError = null;
        },
        successRequestgetAllPrescriberAction: (state, action) => {
            state.requestgetAllPrescriberStatus = "success";
            state.requestgetAllPrescriberError = null;
            state.getAllPrescribers = action.payload;
        },
        showRequestgetAllPrescriberErrorAction: (state, action) => {
            state.requestgetAllPrescriberStatus = "failure";
            state.requestgetAllPrescriberError = action.payload;
        },
        //delete
        startRequestDeletePrescriberAction: (state) => {
            state.requestDeletePrescriberStatus = "pending";
            state.requestDeletePrescriberError = null;
        },
        deletePrescriberAction: (state, action) => {
            // reminderAdapter.removeOne(state, action.payload);
            state.requestDeletePrescriberStatus = "success";
            state.requestDeletePrescriberError = null;
        },
        showRequestDeletePrescriberErrorAction: (state, action) => {
            state.requestDeletePrescriberStatus = "failure";
            state.requestDeletePrescriberError = action.payload;
        },
    },
});
export default presciberSlice;
