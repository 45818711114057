import { call, put, select, takeLatest } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import InfusionClinic, { SLICE_NAME } from "./slice";
import { handleApiError } from "../utils";
import { mapInfusionClinicInfo, mapSingleDataOfInfusionClinicPersonalInfo } from "./Mapper";
import InfusionClinicRepository from "../repository/InfusionClinicRepository";
import { userInfoSelector } from "../account";
//get patient InfusionClinic
export const getPatientInfusionClinicAsyncAction = createAction(`${SLICE_NAME}/getPatientInfusionClinicAsyncAction`);
export function* getPatientInfusionClinicSaga(action) {
    const { startRequestGetInfusionClinicAction, successRequestGetInfusionClinicAction, showRequesGetInfusionClinicErrorAction, } = InfusionClinic.actions;
    try {
        yield put(startRequestGetInfusionClinicAction());
        let result = yield call(InfusionClinicRepository.getPatientInfusionClinicInfo, action.payload);
        const ResponseData = yield mapInfusionClinicInfo(result);
        yield put(successRequestGetInfusionClinicAction(ResponseData));
    }
    catch (error) {
        yield handleApiError(error, showRequesGetInfusionClinicErrorAction);
    }
}
//get only InfusionClinic
export const getInfusionClinicsAsyncAction = createAction(`${SLICE_NAME}/getInfusionClinicsAsyncAction`);
export function* getInfusionClinicsSaga(action) {
    const { startRequestGetOnlyInfusionClinicAction, successRequestGetOnlyInfusionClinicAction, showRequesGetOnlyInfusionClinicErrorAction, storeAllDataOfInfusionClinicAction } = InfusionClinic.actions;
    try {
        yield put(startRequestGetOnlyInfusionClinicAction());
        let result = yield call(InfusionClinicRepository.getInfusionClinicInfo, action.payload);
        const ResponseData = yield mapInfusionClinicInfo(result);
        yield put(successRequestGetOnlyInfusionClinicAction(ResponseData));
        yield put(storeAllDataOfInfusionClinicAction(result));
    }
    catch (error) {
        yield handleApiError(error, showRequesGetOnlyInfusionClinicErrorAction);
    }
}
//post
export const PostInfusionClinicInfoAsyncAction = createAction(`${SLICE_NAME}/PostInfusionClinicInfoAsyncAction`);
export function* PostInfusionClinicInfoSaga(action) {
    const { startPostInfusionClinicInfoAsyncAction, succesPostInfusionClinicInfoAsyncAction, showPostInfusionClinicInfoErrorAction } = InfusionClinic.actions;
    try {
        yield put(startPostInfusionClinicInfoAsyncAction());
        const { drugBrandCode, patientId } = yield select(userInfoSelector);
        const data = {
            patientId: patientId,
            drugBrand: drugBrandCode,
            name: action.payload.name,
            address: action.payload.address,
            city: action.payload.city,
            country: action.payload.country,
            province: action.payload.province,
            phone: action.payload.phone,
            latitude: action.payload.latitude,
            longitude: action.payload.longitude,
            brandIdentifier: action.payload.brandIdentifier,
            note: action.payload.note
        };
        const result = yield call(InfusionClinicRepository.postInfusionClinicInfo, data);
        yield put(succesPostInfusionClinicInfoAsyncAction(data));
    }
    catch (error) {
        yield handleApiError(error, showPostInfusionClinicInfoErrorAction);
    }
}
//put
export const PutInfusionClinicInfoAsyncAction = createAction(`${SLICE_NAME}/PutInfusionClinicInfoAsyncAction`);
export function* PutInfusionClinicInfoSaga(action) {
    const { startPutInfusionClinicInfoAsyncAction, succesPutInfusionClinicInfoAsyncAction, showPutInfusionClinicInfoErrorAction } = InfusionClinic.actions;
    try {
        const data = {
            id: action.payload.id,
            name: action.payload.name,
            address: action.payload.address,
            city: action.payload.city,
            country: action.payload.country,
            province: action.payload.province,
            phone: action.payload.phone,
            brandIdentifier: action.payload.brandIdentifier,
            note: action.payload.note,
            postal: action.payload.postal,
            latitude: action.payload.latitude,
            longitude: action.payload.longitude
        };
        yield put(startPutInfusionClinicInfoAsyncAction());
        yield call(InfusionClinicRepository.putInfusionClinicInfo, data);
        yield put(succesPutInfusionClinicInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showPutInfusionClinicInfoErrorAction);
    }
}
//delete
export const deleteInfusionClinicBrandAsyncAction = createAction(`${SLICE_NAME}/deleteInfusionClinicBrandAsyncAction`);
export function* deleteInfusionClinicBrandSaga(action) {
    const { startRequestDeleteInfusionClinicBrandAction, deleteInfusionClinicBrandAction, showRequestDeleteInfusionClinicBrandErrorAction, } = InfusionClinic.actions;
    try {
        yield put(startRequestDeleteInfusionClinicBrandAction());
        yield call(InfusionClinicRepository.deleteInfusionClinicBrand, action.payload);
        yield put(deleteInfusionClinicBrandAction(action.payload.InfusionClinicId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeleteInfusionClinicBrandErrorAction);
    }
}
//only get one InfusionClinic
export const getSingleDataOfInfusionClinicInfoAsyncAction = createAction(`${SLICE_NAME}/getSingleDataOfInfusionClinicInfoAsyncAction`);
export function* getSingleDataOfInfusionClinicInfoSaga(action) {
    const { startRequestSingleDataOfInfusionClinicInfoAction, successRequesSingleDataOfInfusionClinicInfoAction, showRequesSingleDataOfInfusionClinicInfoErrorAction, } = InfusionClinic.actions;
    try {
        yield put(startRequestSingleDataOfInfusionClinicInfoAction());
        let result = yield call(InfusionClinicRepository.getSingleDataOfInfusionClinicInfo, action.payload);
        const SingleDataOfInfusionClinic = yield mapSingleDataOfInfusionClinicPersonalInfo(result.data);
        yield put(successRequesSingleDataOfInfusionClinicInfoAction(SingleDataOfInfusionClinic[0]));
    }
    catch (error) {
        yield handleApiError(error, showRequesSingleDataOfInfusionClinicInfoErrorAction);
    }
}
//update InfusionClinic
export const updateInfusionClinicInfoAsyncAction = createAction(`${SLICE_NAME}/updatePatientInfoAsyncAction`);
export function* updateInfusionClinicInfoSaga(action) {
    const { startRequestUpdateInfusionClinicInfoAction, successRequestUpdateInfusionClinicInfoAction, showRequestUpdateInfusionClinicInfoErrorAction, } = InfusionClinic.actions;
    try {
        yield put(startRequestUpdateInfusionClinicInfoAction());
        yield call(InfusionClinicRepository.updateInfusionClinicInfo, action.payload);
        yield put(successRequestUpdateInfusionClinicInfoAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdateInfusionClinicInfoErrorAction);
    }
}
//post
export const createInfusionClinicInfoAsyncAction = createAction(`${SLICE_NAME}/createInfusionClinicInfoAsyncAction`);
export function* createInfusionClinicInfoSaga(action) {
    const { startCreateInfusionClinicInfoAsyncAction, successCreateInfusionClinicInfoAsyncAction, showcreateInfusionClinicInfoErrorAction } = InfusionClinic.actions;
    try {
        yield put(startCreateInfusionClinicInfoAsyncAction());
        yield call(InfusionClinicRepository.postInfusionClinic, action.payload);
        yield put(successCreateInfusionClinicInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateInfusionClinicInfoErrorAction);
    }
}
//delete
export const deleteInfusionClinicAsyncAction = createAction(`${SLICE_NAME}/deleteInfusionClinicAsyncAction`);
export function* deleteInfusionClinicSaga(action) {
    const { startRequestDeleteInfusionClinicAction, deleteInfusionClinicAction, showRequestDeleteInfusionClinicErrorAction, } = InfusionClinic.actions;
    try {
        yield put(startRequestDeleteInfusionClinicAction());
        yield call(InfusionClinicRepository.deleteInfusionClinic, action.payload);
        yield put(deleteInfusionClinicAction(action.payload.PharmacyId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeleteInfusionClinicErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getPatientInfusionClinicAsyncAction, getPatientInfusionClinicSaga);
    yield takeLatest(PutInfusionClinicInfoAsyncAction, PutInfusionClinicInfoSaga);
    yield takeLatest(PostInfusionClinicInfoAsyncAction, PostInfusionClinicInfoSaga);
    yield takeLatest(getInfusionClinicsAsyncAction, getInfusionClinicsSaga);
    yield takeLatest(deleteInfusionClinicBrandAsyncAction, deleteInfusionClinicBrandSaga);
    yield takeLatest(getSingleDataOfInfusionClinicInfoAsyncAction, getSingleDataOfInfusionClinicInfoSaga);
    yield takeLatest(updateInfusionClinicInfoAsyncAction, updateInfusionClinicInfoSaga);
    yield takeLatest(createInfusionClinicInfoAsyncAction, createInfusionClinicInfoSaga);
    yield takeLatest(deleteInfusionClinicAsyncAction, deleteInfusionClinicSaga);
}
