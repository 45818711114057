import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import { useInput } from "../../../../../hooks";
import { Button, InputText, } from "../../../../../components/Shared/Controls";
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import ProvinceDropdown from "../../../../../components/Shared/Controls/Inputs/ProvinceDropdown";
import { allProvincesSelector, editPatientAddressAsyncAction, getPatientAddressesAsyncAction, getProvincesAsyncAction, resetRequestAddAddressStatusAction, resetRequestEditAddressStatusAction } from "../../../../../store";
import LoadingPopUp from "../../../../../components/Shared/LoadingPopUp";
import { holdDataAddressAction } from "../../../../../store/Invite";
import Typography from "../../../../../components/Shared/Typography";
const AddressNewSignUp = ({ isNewSignUp, setStep, prevStep, editAddress, holdAddressId, InviteLoginStoredData, getProvinces, requestEditAddressStatus, requestAddAddressStatus, resetRequestAddAddressStatus, resetRequestEditAddressStatus, holdDataAddress, getPatientInfo, setAddressData }) => {
    const { t } = useTranslation();
    const provinces = useSelector(allProvincesSelector);
    const [postalCode, setPostalCode, errorPostalCode, setErrorPostalCode] = useInput();
    const [city, setCity, errorCity, setErrorCity] = useInput();
    const [AddressLineOne, setAddressLineOne, errorAddressLineOne, setErrorAddressLineOne] = useInput();
    const [AddressLineTwo, setAddressLineTwo, errorAddressLineTwo, setErrorAddressLineTwo] = useInput();
    const [province, setProvinceLocal] = useInput();
    const allFieldsValid = () => postalCode && !errorPostalCode
        && city && !errorCity
        && AddressLineOne && !errorAddressLineOne
        && (!AddressLineTwo || AddressLineTwo && !errorAddressLineTwo)
        && province;
    const onPostalCodeChanged = (text) => {
        const formatted = text
            .trim()
            .replace(/\s/g, "")
            .replace(/(.{3})/g, "$1 ")
            .trim();
        if (formatted.length == 7) {
            setErrorPostalCode(false);
        }
        else {
            setErrorPostalCode(true);
        }
        setPostalCode(formatted.toUpperCase());
    };
    useEffect(() => {
        getProvinces();
        getPatientInfo();
    }, []);
    useEffect(() => {
        if (setAddressData) {
            setPostalCode(setAddressData?.postalCode);
            setProvinceLocal(setAddressData?.provinceKey);
            setAddressLineOne(setAddressData?.street);
            setAddressLineTwo(setAddressData?.streetLine2);
            setCity(setAddressData?.city);
        }
    }, [setAddressData]);
    const Submit = () => {
        if (setAddressData?.postalCode == postalCode
            && setAddressData?.provinceKey == province
            && setAddressData?.street == AddressLineOne
            && setAddressData?.streetLine2 == AddressLineTwo
            && setAddressData?.city == city) {
            setStep(true);
        }
        else {
            if (setAddressData?.id) {
                editAddress({
                    street: AddressLineOne,
                    streetLine2: AddressLineTwo,
                    city: city,
                    provinceKey: province,
                    postalCode: postalCode,
                    isPrimary: true,
                    type: "Invite",
                    id: setAddressData.id
                });
            }
        }
    };
    useEffect(() => {
        if ((requestEditAddressStatus === "success" || requestAddAddressStatus === "success") && isNewSignUp) {
            const holdData = {
                postalCode: postalCode,
                city: city,
                AddressLineOne: AddressLineOne,
                AddressLineTwo: AddressLineTwo,
                province: province
            };
            holdDataAddress(holdData);
            if (requestEditAddressStatus === "success") {
                resetRequestEditAddressStatus();
            }
            if (requestAddAddressStatus === "success") {
                resetRequestAddAddressStatus();
            }
            setStep(true);
        }
    }, [requestEditAddressStatus, requestAddAddressStatus]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(si.contentWrapper) },
            React.createElement("div", { className: classNames(isNewSignUp ? si.scrollWrapperfrom : null) },
                React.createElement("div", { className: classNames(isNewSignUp ? si.formstep : null, isNewSignUp ? si.formWrapper : null) },
                    React.createElement("div", { className: classNames(si.formstepbar) },
                        isNewSignUp ? React.createElement(React.Fragment, null,
                            React.createElement("div", { className: s.header },
                                React.createElement("div", { className: s.pages },
                                    React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `7/15`)))) : null,
                        React.createElement("div", { className: (si.stepToptext) },
                            React.createElement("h4", null, t("address")),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null, t("weNeedabit")))),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("AddressLineOne")),
                            React.createElement(InputText, { name: "AddressLineOne", pattern: "^.{0,35}$", value: AddressLineOne, setValue: setAddressLineOne, error: AddressLineOne && errorAddressLineOne, setError: setErrorAddressLineOne, tabIndex: 1, onFocus: true })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("AddressLineTwo")),
                            React.createElement(InputText, { name: "AddressLineTwo", pattern: "^.{0,35}$", value: AddressLineTwo, setValue: setAddressLineTwo, error: AddressLineTwo && errorAddressLineTwo, setError: setErrorAddressLineTwo, tabIndex: 2 })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("city")),
                            React.createElement(InputText, { name: "city", pattern: "^.{0,35}$", value: city, setValue: setCity, error: city && errorCity, setError: setErrorCity, tabIndex: 3 })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("selectYourProvince")),
                            React.createElement(ProvinceDropdown, { data: provinces, value: province, setValue: setProvinceLocal, tabIndex: 4 })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("postalCode")),
                            React.createElement(InputText, { name: "postal_code", errorMessage: t("postalCodeError"), pattern: "null|^[A-Z]\\d[A-Z][ ]\\d[A-Z]\\d$", value: postalCode, setValue: onPostalCodeChanged, error: postalCode && errorPostalCode, setError: setErrorPostalCode, tabIndex: 5 })),
                        React.createElement("div", { className: si.buttonTwopage },
                            React.createElement(Button, { text: t("back"), onClick: prevStep }),
                            React.createElement(Button, { style: s.mb0_button, primary: true, disabled: !allFieldsValid(), text: t("SaveProceed"), onClick: Submit })))))),
        requestEditAddressStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestAddAddressStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        holdAddressId: state.patientAddress.holdAddressId,
        InviteLoginStoredData: state.auth.InviteLoginStoredData,
        requestAddAddressStatus: state.patientAddress.requestAddAddressStatus,
        requestEditAddressStatus: state.patientAddress.requestEditAddressStatus,
        holdaddressState: state.invite.holdAddressData,
        setAddressData: state.patientAddress.setAddressData
    };
};
function mapDispatchToProps(dispatch) {
    return {
        editAddress: (address) => dispatch(editPatientAddressAsyncAction(address)),
        getProvinces: () => {
            dispatch(getProvincesAsyncAction());
        },
        resetRequestAddAddressStatus: () => dispatch(resetRequestAddAddressStatusAction()),
        resetRequestEditAddressStatus: () => dispatch(resetRequestEditAddressStatusAction()),
        holdDataAddress: (data) => dispatch(holdDataAddressAction({ data })),
        getPatientInfo: () => dispatch(getPatientAddressesAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddressNewSignUp);
