import React from 'react';
import { PopUp } from '../../Info';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './style.scss';
import ReactHtmlParser from "react-html-parser";
const PrivacyPolicy = ({ style, isOpen, setIsOpen }) => {
    const { t } = useTranslation();
    return (React.createElement(PopUp, { style: s.policy, contentStyle: s.policyContent, isOpen: isOpen, allowClose: true, toggle: () => setIsOpen(false) },
        React.createElement("div", { className: s.policy__header },
            React.createElement("div", { className: s.headline_2 }, t("privacyPolicy"))),
        React.createElement("div", { className: classNames(s.policy__content, s.scrollWrapper) },
            React.createElement("div", { className: s.intend }, ReactHtmlParser(t('privacyPolicyContent'))))));
};
export default PrivacyPolicy;
