import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import InfoPopUp from "../../../Info/InfoPopUp";
import Close from "../../../../../resources/icons/close.svg";
import Edit from "../../../../../resources/icons/edit";
import Save from "../../../../../resources/icons/done";
import Info from '../../../../../resources/icons/info';
import colors from "../../../../../resources/colors";
import s from "./style.scss";
import Typography from "../../../Typography";
const InputText = ({ style, styleGroup, name, value, setValue, placeholder, description, descriptionJSX, pattern, errorMessage, onChangeText, onValidation, error, setError, label, length, maxLength = 35, isNumber, isDecimalNumber, isLatitude, isLongitude, clearCross = true, children, withLink = false, onLinkClick, disabled, save, onSave, autoComplete, onCancel, tabIndex, notEveryPattern, required, isSubmitted, validationMessage, onFocus }) => {
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const [nameError, setNameError] = useState("");
    const [isTouched, setIsTouched] = useState(false);
    const handleValidation = (value) => {
        if (isLatitude) {
            const condition = new RegExp(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/, "");
            return condition.test(value);
        }
        if (isLongitude) {
            const condition = new RegExp(/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/, "");
            return condition.test(value);
        }
        if (!pattern)
            return true;
        // string pattern, one validation rule
        if (typeof pattern === "string") {
            const condition = new RegExp(pattern, "g");
            return condition.test(value.trim());
        }
        // array patterns, multiple validation rules
        if (typeof pattern === "object") {
            const conditions = pattern.map((rule) => new RegExp(rule, "g"));
            return !notEveryPattern ? conditions
                .map((condition) => condition.test(value))
                .every((e) => e) :
                conditions
                    .find((condition) => condition.test(value));
        }
    };
    const onChange = (value) => {
        if (value == "") {
            setIsTouched(true);
        }
        else {
            setIsTouched(false);
        }
        if (isNumber) {
            if (isDecimalNumber) {
                value = value.replace(/[0-9.]/g, "");
            }
            else {
                value = value.replace(/[^0-9]/g, "");
            }
        }
        if (isLatitude || isLongitude) {
            value = value.replace(/[^0-9-.]/g, "");
        }
        if (length && value.length > length)
            value = value.slice(0, length);
        setValue(value);
        const isValid = handleValidation(value);
        if (setError)
            value && !isValid ? setError(errorMessage) : setError("");
        onValidation && onValidation(isValid);
    };
    const clear = () => {
        setValue("");
        setError && setError("");
        onCancel?.();
    };
    const inputRef = useRef(null);
    useEffect(() => {
        if (onFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [onFocus]);
    return (React.createElement("div", null,
        label && React.createElement(Typography, { type: "label", color: "neutralGray", bold: true },
            label,
            required && React.createElement("span", { style: { color: "red" } }, "*")),
        React.createElement("div", { "data-testid": "component-test", className: classNames(styleGroup, s.inputGroup), style: {
                "--icon-hover-color": colors.controlsGeneralColor,
                "--input-focus-border-color": colors.inputFocusColorBorder,
                "--input-focus-shadow-color": colors.inputFocusShadowBorder,
            } },
            React.createElement("input", { tabIndex: tabIndex, ref: inputRef, "data-testid": "input-test", style: { backgroundColor: colors.primaryshades3 }, className: classNames(s.input, style, {
                    [s.errorInput]: error, [s.withPlaceholder]: placeholder,
                }), type: "text", name: name, value: value, disabled: disabled, autoComplete: autoComplete || "off", maxLength: maxLength, onChange: (e) => {
                    onChange(e.target.value);
                    onChangeText && onChangeText(e);
                } }),
            (isSubmitted && required && (value == "" || value == undefined)) && React.createElement("span", { "data-testid": "error-test", className: s.error }, validationMessage),
            placeholder && React.createElement("div", { "data-testid": "placeholder-test", className: classNames(s.floatingLabel) }, placeholder),
            error && errorMessage && React.createElement("span", { "data-testid": "error-test", className: s.error }, errorMessage),
            clearCross && React.createElement(SVG, { "data-testid": "clear-button", src: Close, className: s.close, onClick: clear }),
            withLink && React.createElement("a", { className: s.link, onClick: onLinkClick },
                React.createElement(Edit, { color: colors.controlsGeneralColor })),
            (description || descriptionJSX) && React.createElement(Info, { "data-testid": "info-test", color: colors.icons.primary, style: classNames(s.link, s.info), onClick: () => setDisplayInfo(true) }),
            save && (React.createElement(React.Fragment, null,
                !error && React.createElement("a", { className: classNames(s.link, s.save), onClick: () => !error && value && onSave() },
                    React.createElement(Save, { color: colors.controlsGeneralColor })),
                React.createElement(SVG, { src: Close, className: classNames(s.link, s.closeIcon), onClick: onCancel }))),
            React.createElement(InfoPopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false) }, descriptionJSX && descriptionJSX || React.createElement("div", null, description)),
            children)));
};
export default InputText;
