import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../utils";
import notificationSlice, { SLICE_NAME } from "./Slice";
import { allNotificationsSelector } from "./selectors";
import { mapNotifications } from "./Mapper";
import { userInfoSelector } from "../account/selectors";
import NotificationsRepository from "../repository/NotificationsRepository";
export const getNotificationsAsyncAction = createAction(`${SLICE_NAME}/getNotificationsAsyncAction`);
export function* getNotificationsSaga(action) {
    const { startRequestAction, showErrorAction, successRequestAction } = notificationSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestAction());
        const result = yield call(NotificationsRepository.getNotifications, {
            ...action.payload,
            patientId,
        });
        const notifications = yield mapNotifications(result.data);
        yield put(successRequestAction(notifications));
    }
    catch (error) {
        yield handleApiError(error, showErrorAction);
    }
}
export const markViewedNotificationsAsyncAction = createAction(`${SLICE_NAME}/markViewedNotificationsAsyncAction`);
export function* markViewedNotificationsSaga() {
    const { startRequestMarkViewedAction, successRequestMarkViewedAction, showErrorMarkViewedAction, } = notificationSlice.actions;
    const notifications = yield select(allNotificationsSelector);
    const ids = yield notifications
        .filter((item) => !item.viewed)
        .map((item) => item.id);
    try {
        yield put(startRequestMarkViewedAction());
        if (ids.length) {
            yield call(NotificationsRepository.markViewed, ids);
        }
        const updatedNotifications = yield notifications.map((item) => {
            return {
                ...item,
                viewed: true,
            };
        });
        yield put(successRequestMarkViewedAction(updatedNotifications));
    }
    catch (error) {
        yield handleApiError(error, showErrorMarkViewedAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getNotificationsAsyncAction, getNotificationsSaga);
    yield takeLatest(markViewedNotificationsAsyncAction, markViewedNotificationsSaga);
}
