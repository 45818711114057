import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAnalytics, logEvent } from "@firebase/analytics";
import PageBody from "../../components/Shared/PageBody";
import ChatBody from "./components/ChatBody";
import ChatFooter from "./components/ChatFooter";
import ChatHeader from "./components/ChatHeader";
import s from "./style.scss";
const HelpChat = () => {
    const { t } = useTranslation();
    const [isDuringProgramHours, setIsDuringProgramHours] = useState(false);
    useEffect(() => {
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: "HelpChat",
            firebase_screen_class: "HelpChat"
        });
        const hours = moment().tz("America/New_York").hours();
        const minutes = moment().tz("America/New_York").minutes();
        if (hours >= 9 && hours <= 17) {
            if (hours === 17 && minutes > 0) {
                setIsDuringProgramHours(false);
            }
            else {
                setIsDuringProgramHours(true);
            }
        }
    }, []);
    return (React.createElement(PageBody, { style: s.pageBody, title: t("programChat") },
        React.createElement("div", null,
            React.createElement(ChatHeader, null),
            React.createElement("div", { className: s.divider }),
            React.createElement("div", { className: s.text_banner },
                !isDuringProgramHours
                    ? t("autoMessageOutsideProgramHours")
                    : t("autoMessageDuringProgramHours"),
                React.createElement("br", null),
                React.createElement("br", null),
                t("call911")),
            React.createElement("div", { className: s.divider }),
            React.createElement(ChatBody, null),
            React.createElement(ChatFooter, { disabled: !isDuringProgramHours }))));
};
export default HelpChat;
