import React, { useEffect } from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getPreviewPhotoAsyncAction } from '../../../store/patientFiles/Actions';
import Close from '../../../resources/icons/close.svg';
import SVG from 'react-inlinesvg';
import s from './style.scss';
const ImageModal = ({ style, file, modalIsOpen, previewLink, requestGetPreviewPhotoStatus, toggleModal, deleteFile, getPreviewImage }) => {
    useEffect(() => {
        (file?.parentFileId || file?.id) && getPreviewImage(file?.parentFileId || file?.id);
    }, [file?.parentFileId]);
    return (React.createElement(ReactModal, { className: classNames(s.modal, style), overlayClassName: s.overlay, isOpen: modalIsOpen, onRequestClose: toggleModal, shouldCloseOnOverlayClick: true, ariaHideApp: false },
        React.createElement("div", { className: s.content }, requestGetPreviewPhotoStatus !== "pending" && React.createElement(React.Fragment, null,
            React.createElement("img", { className: s.img, src: (previewLink !== "" && file?.id) && previewLink || file?.uri }),
            React.createElement(SVG, { className: s.closeIcon, src: Close, onClick: toggleModal })))));
};
const mapStateToProps = (state) => {
    return {
        previewLink: state.patientFiles.previewLink,
        requestGetPreviewPhotoStatus: state.patientFiles.requestGetPreviewPhotoStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPreviewImage: (id) => {
            dispatch(getPreviewPhotoAsyncAction(id));
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ImageModal);
