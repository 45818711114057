import React from 'react';
import classNames from 'classnames';
import colors from '../../../../resources/colors';
import s from './style.scss';
const Picker = ({ onChange, style, data, value, columns, titleStyle }) => {
    return (React.createElement("div", { "data-testid": "picker-test", className: s.picker }, data.map((item) => {
        return (React.createElement("div", { className: classNames(s.card, {
                [s.active]: item.value === value,
                [s.columns2]: columns === 2,
                [s.columns3]: columns === 3
            }), style: {
                backgroundColor: item.value === value && colors.controlsGeneralColor
            }, onClick: () => onChange(item.value), key: item.value },
            React.createElement("div", { className: s.pickTitle }, item.title)));
    })));
};
export default Picker;
