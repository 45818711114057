import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { getFinancialAssistanceConfigAsyncAction } from "../../store/patientFiles/Actions";
import { allPatientAddressesSelector, checkAvailabilityOfFeature, getPatientAddressesAsyncAction, getPatientInfoAsyncAction, getRegistrationInfoAsyncAction, userBrandByIdSelector } from "../../store";
import Caregiver from "../Caregiver";
import { Button } from "../../components/Shared/Controls";
import images from "../../resources/images";
import s from "./style.scss";
import { Box } from "../../components";
import Typography from "../../components/Shared/Typography";
import HomeProfileCard from "./components/HomeProfileCard";
import HomeReminders from "./components/HomeReminders";
import HomeUserCard from "./components/HomeUserCard";
import { PopUp } from "../../components/Shared/Info";
import Close from "../../resources/icons/close.svg";
import useInput from "../../hooks/useInput";
const HomePage = ({ checkFeature, isAvailableFA, steps, addresses, brand, registrationUnfinished, programLogo, programUrl, programPhone, programEmail, patient, patientId, config: faConfig, getRegistrationInfo, getPatientInfo, getPatientAddresses, getConfig }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [contactUsOpen, setContactUsOpen] = useInput(false);
    useEffect(() => {
        !registrationUnfinished && getConfig({
            isMember: false
        });
        getPatientInfo();
        getPatientAddresses();
        patientId && getRegistrationInfo({
            member: {
                patientId,
                drugBrandCode: brand?.drugBrandCode || "",
            },
            isRegistrationFlow: false,
        });
    }, [patientId]);
    const navigate = useNavigate();
    const address = addresses.find(el => el.isPrimary);
    const faProgress = faConfig.electronic && steps.find(el => el.step === "FinancialAssistance");
    const addressProgress = !address?.city || !address?.street;
    const insuranceProgress = steps.find(el => el.step === "AddInsurer");
    const healthCardProgress = steps.find(el => el.step === "AddHealthCard");
    const profileCards = [
        {
            image: images.Education,
            title: t("patientEducation"),
            description: t("educationHomePageDescription"),
            link: "/education",
            show: true,
        },
        {
            image: images.FinancialAssistance,
            title: !faProgress ? t("financialAssistance") : t("applyFA"),
            description: `${t("receiveUp")} ${brand?.drugBrandCode}${t("receiveUpPrescription")}`,
            link: "/financial-assistance",
            show: checkFeature("Financial Assistance") && isAvailableFA
        },
        {
            image: images.Medicine,
            title: t("managePrescriptions"),
            description: t("savePhotoPrescriptions"),
            link: "/prescriptions",
            show: checkFeature("Prescriptions"),
        },
        {
            image: images.Receipts,
            title: t("uploadReceipts"),
            description: t("takePictureOfPrescription"),
            link: "/receipts",
            show: checkFeature("Receipts")
        },
        {
            image: images.Receipts,
            title: t("uploadDocuments"),
            description: t("uploadInsuranceDocuments"),
            link: "/medical-documents",
            show: checkFeature("Prescriber")
        },
        {
            image: images.Prescriptions,
            title: t("prescriber"),
            description: t("setPrescriber"),
            link: "/prescriber",
            show: checkFeature("Prescriber")
        },
        {
            image: images.Pharmacy,
            title: t("pharmacy"),
            description: t("setPharmacy"),
            link: "/pharmacy",
            show: checkFeature("Pharmacy")
        },
        {
            image: images.Pharmacy,
            title: t("infusionClinic"),
            description: t("setInfusionClinic"),
            link: "/infusion-clinic",
            show: checkFeature("InfusionClinic")
        }
    ];
    const renderContactUsModal = (React.createElement(PopUp, { isOpen: contactUsOpen },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2 }, t("ContactUs"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", { className: s.headline_3 }, t('weAreHereToHelp')),
            React.createElement("div", null,
                t('hoursOfOperation'),
                React.createElement("br", null),
                t('phoneNumber'),
                ": ",
                programPhone ? React.createElement("a", { href: "tel:" + programPhone }, programPhone) : "N/A",
                React.createElement("br", null),
                t('email'),
                ": ",
                programEmail ? React.createElement("a", { href: "mailto:" + programEmail }, programEmail) : "N/A")),
        React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: () => setContactUsOpen(false) })));
    return registrationUnfinished && React.createElement(Caregiver, null) || (React.createElement("div", { className: classNames(s.homePageWrapper, s.scrollWrapper) },
        React.createElement("div", { className: s.welcomeCard },
            React.createElement("img", { className: s.welcomeImage, src: images.WelcomeBG }),
            React.createElement("div", { className: s.welcomeContent },
                React.createElement("div", { className: s.welcomeTextWrapper },
                    React.createElement(Box, { f1: true, column: true, justifyCenter: true, gap: 12, width: 431 },
                        React.createElement(Typography, { type: 'header_1', color: 'neutralDark', bold: true },
                            t("welcome"),
                            ", ",
                            patient?.firstName),
                        React.createElement("div", { className: s.welcomeText },
                            React.createElement("p", null,
                                React.createElement(Typography, { type: 'header_3', color: 'neutralDark' },
                                    "Amgen Entrust ",
                                    t("patientServices"),
                                    ":")),
                            React.createElement(Typography, { type: 'header_3', color: 'neutralDark' }, t("homeMaintitle")))),
                    React.createElement("img", { className: s.welcomeCardBrand, src: brand?.base64Logo })))),
        React.createElement(Box, { f1: true, row: true, mb: 30, gap: 20, wrap: true },
            React.createElement(HomeReminders, null),
            React.createElement(HomeUserCard, { patient: patient, addressProgress: addressProgress, healthCardProgress: healthCardProgress, insuranceProgress: insuranceProgress })),
        React.createElement(Box, { f1: true, row: true, mb: 30, gap: 20, wrap: true }, profileCards.map((item, index) => item.show && React.createElement(HomeProfileCard, { key: `${item.title}-${index}`, title: item.title, description: item.description, link: item.link, image: item.image }))),
        renderContactUsModal,
        React.createElement(Box, { f1: true, row: true, gap: 20, mb: 30, wrapReverse: true },
            React.createElement("div", { className: s.callWidget },
                React.createElement("div", { className: s.callWidgetBox },
                    React.createElement(Typography, { type: 'header_1', color: 'neutralDark', bold: true }, t("haveanyquestions")),
                    React.createElement(Typography, { type: 'subtitle_1', color: 'neutralDark' }, t("askThemDirectlyToOurExpert")),
                    React.createElement("div", { className: s.callButtonBox },
                        React.createElement("div", { style: { width: '200px', height: '48px' } },
                            React.createElement(Button, { primary: true, text: t("ContactUs"), onClick: () => { setContactUsOpen(true); } }))))),
            React.createElement("img", { className: s.questionImageBig, src: images.QuestionBG }))));
};
const mapStateToProps = (state) => {
    return {
        checkFeature: (key) => checkAvailabilityOfFeature(state, key),
        isAvailableFA: state.navigation.availableFA,
        steps: state.navigation.steps,
        brand: userBrandByIdSelector(state),
        registrationUnfinished: state.account.registrationUnfinished,
        patient: state.account.patient,
        patientId: state.account.user.patientId,
        programLogo: state.account.currentProgramLogo,
        programUrl: state.account.currentProgramWebsite,
        programPhone: state.account.brandPhoneNumber,
        programEmail: state.account.brandEmail,
        addresses: allPatientAddressesSelector(state),
        config: state.patientFiles.financialAssistanceConfig,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getConfig: (payload) => dispatch(getFinancialAssistanceConfigAsyncAction(payload)),
        getPatientAddresses: () => dispatch(getPatientAddressesAsyncAction()),
        getPatientInfo: () => dispatch(getPatientInfoAsyncAction()),
        getRegistrationInfo: (payload) => dispatch(getRegistrationInfoAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
