import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button, InputText } from "../../../../components/Shared/Controls";
import { getPatientHealthInformationAsyncAction, updateHealthInformationAsyncAction } from "../../../../store/account/Sagas";
import { resetRequestPatientHealthInformation, resetRequestUpdateHealthInformationAction } from "../../../../store";
import { useInput } from "../../../../hooks";
import { allConditionsSelector, getMedicalConditionsAsyncAction } from "../../../../store/medicalConditions";
import DinInfoContent from "../../../../components/Shared/PopUps/DinInfoContent";
import CheckboxList from "../../../../components/Shared/Controls/CheckboxList";
import config from '../../../../../config.json';
import s from "./style.scss";
import { toPascalCase } from "../../../../store/utils";
import LoadingPopUp from "../../../../components/Shared/LoadingPopUp";
export const getCheckedConditionsView = (array) => {
    if (!!array) {
        return array?.filter((el) => el?.isChecked).map((condition) => {
            return {
                medicalConditionId: condition.key,
                // medicalConditionDescription: condition.displayName
            };
        });
    }
    else {
        return [];
    }
};
const showDIN = config.signUp_steps.find(el => el.name === "Din")?.show;
const HealthInformation = ({ children, brandName, setHaveChanges, patientId, healthInformation, drugBrandCode, updateHealthInformation, resetStatuses, medicalConditionsRequestStatus, getHealthInformation, getMedicalConditionals, requestPatientHealthInformationStatus, requestPatientHealthInformationError, requestUpdateHealthInformationStatus }) => {
    const { t, i18n } = useTranslation();
    const conditions = useSelector(allConditionsSelector);
    const [dinNumber, setDinNumber, errorDinNumber, setErrorDinNumber] = useInput(healthInformation?.din);
    const [medicalConditions, setMedicalConditions] = useInput([]);
    useEffect(() => {
        setDinNumber(healthInformation?.din || "");
        conditions && setMedicalConditions(conditions.map((condition) => {
            return {
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: healthInformation?.medicalConditions && healthInformation?.medicalConditions.find((el) => el.medicalConditionId === condition.medicalConditionId)
                    && true
                    || false
            };
        }));
    }, [healthInformation]);
    const checkedConditions = getCheckedConditionsView(medicalConditions);
    const valuesIsValid = (showDIN ? dinNumber : true) && (showDIN ? dinNumber.length === 8 : true)
        && checkedConditions && checkedConditions.length > 0;
    const hasChanges = () => {
        if (checkedConditions.length != healthInformation?.medicalConditions.length) {
            return true;
        }
        const sortedCheckedConditions = [...checkedConditions]
            .sort((a, b) => a.medicalConditionId.localeCompare(b.medicalConditionId));
        const sortedMedicalConditions = [...healthInformation.medicalConditions]
            .sort((a, b) => a.medicalConditionId.localeCompare(b.medicalConditionId));
        for (let i = 0; i < sortedCheckedConditions.length; i++) {
            if (sortedCheckedConditions[i].medicalConditionId !== sortedMedicalConditions[i].medicalConditionId) {
                return true;
            }
        }
        return false;
    };
    useEffect(() => {
        resetStatuses();
        if (drugBrandCode !== undefined)
            getMedicalConditionals(drugBrandCode);
    }, [drugBrandCode]);
    useEffect(() => {
        medicalConditionsRequestStatus == "success" && conditions && getHealthInformation();
    }, [patientId, drugBrandCode, medicalConditionsRequestStatus]);
    useEffect(() => {
        conditions && healthInformation && healthInformation.medicalConditions && setMedicalConditions(conditions.map((condition) => {
            return {
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: healthInformation.medicalConditions.find((el) => el.medicalConditionId === condition.medicalConditionId)
                    ? true
                    : false
            };
        }));
    }, [conditions, healthInformation]);
    useEffect(() => {
        setHaveChanges((haveChanges) => hasChanges() && valuesIsValid);
    }, [valuesIsValid, hasChanges]);
    const update = () => {
        const newHealthInformation = {
            patientId: patientId,
            din: dinNumber,
            medicalConditions: checkedConditions
        };
        hasChanges() && updateHealthInformation(newHealthInformation);
    };
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(LoadingPopUp, { isOpen: requestPatientHealthInformationStatus === "pending" || requestUpdateHealthInformationStatus === "pending" || medicalConditionsRequestStatus === "pending" }),
        React.createElement("div", { className: s.scrollWrapper },
            React.createElement("div", { className: classNames(s.contentWrapper) },
                showDIN && React.createElement("div", null,
                    React.createElement(InputText, { name: "dinNumber", label: t("din").replace("BRAND_NAME", ""), pattern: '^.{8}$', descriptionJSX: React.createElement(DinInfoContent, null), value: dinNumber, setValue: setDinNumber, error: dinNumber && (errorDinNumber || dinNumber.length !== 8), setError: setErrorDinNumber, errorMessage: t("pleaseEnterValidDIN"), isNumber: true, length: 8, maxLength: 8 })),
                React.createElement("div", null,
                    React.createElement(CheckboxList, { items: medicalConditions, setItems: setMedicalConditions, placeholder: t("healthConditions"), description: t("pleaseSelectTheConditions").replace("BRAND_NAME", toPascalCase(brandName)), informationPopUp: true })))),
        React.createElement("div", { className: s.actions },
            React.createElement(Button, { disabled: !hasChanges() || !valuesIsValid, primary: true, text: t("update"), onClick: update }))));
};
const mapStateToProps = (state) => {
    return {
        brandName: state.account.user?.drugBrandCode,
        patientId: state.account.user.patientId,
        drugBrandCode: state.account.user?.drugBrandCode,
        healthInformation: state.account.healthInformation,
        medicalConditionsRequestStatus: state.medicalConditions.requestStatus,
        medicalConditionsRequestError: state.medicalConditions.requestError,
        requestPatientHealthInformationStatus: state.account.requestPatientHealthInformationStatus,
        requestPatientHealthInformationError: state.account.requestPatientHealthInformationError,
        requestUpdateHealthInformationStatus: state.account.requestUpdateHealthInformationStatus,
        requestUpdateHealthInformationError: state.account.requestUpdateHealthInformationError,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getMedicalConditionals: (drugBrandCode) => {
            dispatch(getMedicalConditionsAsyncAction(drugBrandCode));
        },
        getHealthInformation: () => dispatch(getPatientHealthInformationAsyncAction()),
        updateHealthInformation: (healthInformation) => dispatch(updateHealthInformationAsyncAction(healthInformation)),
        resetStatuses: () => {
            dispatch(resetRequestUpdateHealthInformationAction());
            dispatch(resetRequestPatientHealthInformation());
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HealthInformation);
