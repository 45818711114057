import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../utils";
import { userInfoSelector } from "../account/selectors";
import addressSlice, { SLICE_NAME } from "./Slice";
import { mapAddresses, mapAddressTypeToParamAddressType } from "./Mapper";
import { getHomeSaga, getRegistrationInfoSaga } from "../account/Sagas";
import PatientAddressRepository from "../repository/PatientAddressRepository";
export const getPatientAddressesAsyncAction = createAction(`${SLICE_NAME}/getPatientAddressesAsyncAction`);
export const addPatientAddressAsyncAction = createAction(`${SLICE_NAME}/addPatientAddressAsyncAction`);
export const editPatientAddressAsyncAction = createAction(`${SLICE_NAME}/editPatientAddressAsyncAction`);
export const deletePatientAddressAsyncAction = createAction(`${SLICE_NAME}/deletePatientAddressAsyncAction`);
export const addProvinceToAddressAsyncAction = createAction(`${SLICE_NAME}/addProvinceToAddressAsyncAction`);
export function* getPatientAddressesSaga() {
    const { startRequestGetAddressesAction, setAddressesAction, showRequestGetAddressesErrorAction, } = addressSlice.actions;
    try {
        yield put(startRequestGetAddressesAction());
        const currentUser = yield select(userInfoSelector);
        const response = yield call(PatientAddressRepository.getAddresses, currentUser.patientId || "");
        const mapped = yield mapAddresses(response.data.value);
        yield put(setAddressesAction(mapped));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetAddressesErrorAction);
    }
}
export function* addPatientAddressSaga(action) {
    const { startRequestAddAddressAction, addAddressAction, showRequestAddAddressErrorAction, holdAddressIdAction } = addressSlice.actions;
    try {
        yield put(startRequestAddAddressAction());
        const currentUser = yield select(userInfoSelector);
        const actionPayload = action.payload;
        const payload = {
            street: actionPayload.street,
            streetLine2: actionPayload.streetLine2,
            city: actionPayload.city,
            province: actionPayload.provinceKey,
            zipCode: actionPayload.postalCode || null,
            isDefault: actionPayload.isPrimary,
            type: mapAddressTypeToParamAddressType(actionPayload.type),
            patientId: currentUser.patientId,
        };
        const response = yield call(PatientAddressRepository.addAddress, payload);
        const id = response.data.value;
        yield put(holdAddressIdAction(response.data.value));
        yield put(addAddressAction({
            id,
            street: actionPayload.street,
            streetLine2: actionPayload.streetLine2,
            city: actionPayload.city,
            provinceKey: actionPayload.provinceKey,
            postalCode: actionPayload.postalCode,
            isPrimary: actionPayload.isPrimary,
            type: actionPayload.type,
        }));
        yield getPatientAddressesSaga();
    }
    catch (error) {
        yield handleApiError(error, showRequestAddAddressErrorAction);
    }
}
export function* editPatientAddressSaga(action) {
    const { startRequestEditAddressAction, editAddressAction, showRequestEditAddressErrorAction, } = addressSlice.actions;
    const actionPayload = action.payload;
    try {
        if (actionPayload.id) {
            yield put(startRequestEditAddressAction());
            const payload = {
                street: actionPayload.street,
                streetLine2: actionPayload.streetLine2,
                city: actionPayload.city,
                province: actionPayload.provinceKey,
                zipCode: actionPayload.postalCode || null,
                isDefault: actionPayload.isPrimary,
                type: mapAddressTypeToParamAddressType(actionPayload.type),
                addressId: actionPayload.id,
            };
            yield call(PatientAddressRepository.editAddress, payload);
            yield put(editAddressAction({
                id: actionPayload.id,
                street: actionPayload.street,
                streetLine2: actionPayload.streetLine2,
                city: actionPayload.city,
                provinceKey: actionPayload.provinceKey,
                postalCode: actionPayload.postalCode,
                isPrimary: actionPayload.isPrimary,
                type: actionPayload.type,
            }));
            yield getPatientAddressesSaga();
        }
    }
    catch (error) {
        yield handleApiError(error, showRequestEditAddressErrorAction);
    }
}
export function* deletePatientAddressSaga(action) {
    const { startRequestDeleteAddressAction, deleteAddressAction, showRequestDeleteAddressErrorAction, } = addressSlice.actions;
    try {
        yield put(startRequestDeleteAddressAction());
        yield call(PatientAddressRepository.deleteAddress, action.payload.addressId);
        yield put(deleteAddressAction(action.payload.addressId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeleteAddressErrorAction);
    }
}
export function* addProvinceToAddressSaga(action) {
    const { startRequestAddProvinceAction, successRequestAddProvinceAction, showRequestAddProvinceErrorAction, } = addressSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestAddProvinceAction());
        yield call(PatientAddressRepository.addAddress, {
            province: action.payload,
            isDefault: true,
            patientId,
        });
        yield getHomeSaga();
        yield getRegistrationInfoSaga({
            payload: {
                member: undefined,
                isRegistrationFlow: false,
            },
            type: "ACCOUNT/getRegistrationInfoAsyncAction",
        });
        yield put(successRequestAddProvinceAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestAddProvinceErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getPatientAddressesAsyncAction, getPatientAddressesSaga);
    yield takeLatest(addPatientAddressAsyncAction, addPatientAddressSaga);
    yield takeLatest(editPatientAddressAsyncAction, editPatientAddressSaga);
    yield takeLatest(deletePatientAddressAsyncAction, deletePatientAddressSaga);
    yield takeLatest(addProvinceToAddressAsyncAction, addProvinceToAddressSaga);
}
