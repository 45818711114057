import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import EditIcon from '@mui/icons-material/Edit';
import { visuallyHidden } from '@mui/utils';
import { Button, FormControl, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeletePopUp from '../../components/Shared/Info/DeletePopUp';
import MedicalConditionsFilter from './MedicalConditionsFilter';
import LoadingPopUp from '../../components/Shared/LoadingPopUp';
import medicalConditioncss from "./MedicalConditionsStyle.scss";
import golbalcss from "../styles.scss";
import globaltableStyle from "../tableStyle.scss";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { t } from 'i18next';
import { deleteMedicalConditionAsyncAction, getAllmedicalConditionInfoAsyncAction } from '../../store/medicalConditions/Sagas';
function createData(brandCode, Description, action) {
    return {
        brandCode,
        Description,
        action
    };
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const headCells = [
    {
        id: 'brandCode',
        numeric: true,
        disablePadding: false,
        label: 'drugBrandCode',
    },
    {
        id: 'Description',
        numeric: true,
        disablePadding: false,
        label: 'description',
    },
    {
        id: 'action',
        numeric: true,
        disablePadding: false,
        label: ' ',
    }
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, null, headCells.map((headCell) => {
            if (headCell.id === 'brandCode') {
                return (React.createElement(TableCell, { key: Math.random(), align: "center", padding: headCell.disablePadding ? 'none' : 'normal' }, t(headCell.label) || headCell.label));
            }
            return (React.createElement(TableCell, { key: headCell.id, align: headCell.numeric ? 'center' : 'center', padding: headCell.disablePadding ? 'none' : 'normal', sortDirection: orderBy === headCell.id ? order : false },
                React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: createSortHandler(headCell.id) },
                    t(headCell.label) || headCell.label,
                    orderBy === headCell.id ? (React.createElement(Box, { component: "span", sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)));
        }))));
}
function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const navigate = useNavigate();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [holdDataFilter, setholdDataFilter] = useState();
    const handleIconClick = () => {
        setPopupOpen(true);
    };
    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const setFields = (medicalConditionFilterfieldsObj) => {
        setholdDataFilter(medicalConditionFilterfieldsObj);
        props.setField?.(medicalConditionFilterfieldsObj);
    };
    const handleSearchInput = (e) => {
        props.setSearch?.(e.target.value);
    };
    function newmedicalCondition() {
        navigate('/medicalConditions/create');
    }
    return (React.createElement(Toolbar, { className: golbalcss.TitleTopBar, sx: {
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
                bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
        } },
        numSelected > 0 ? (React.createElement(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "subtitle1", component: "div" },
            numSelected,
            " selected")) : (React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" }, t("medicalConditions"))),
        React.createElement("button", { className: medicalConditioncss.btnPrimaryButton, onClick: newmedicalCondition },
            "+ ",
            t("medicalConditions")),
        React.createElement(FormControl, { sx: { width: '80ch' } },
            React.createElement(OutlinedInput, { className: golbalcss.searchInput, onChange: (e) => handleSearchInput(e), placeholder: t("search") })),
        numSelected > 0 ? (React.createElement(Tooltip, { title: t('delete') },
            React.createElement(IconButton, null,
                React.createElement(DeleteIcon, null)))) : (React.createElement(Tooltip, { className: golbalcss.FilterIconSearch, title: t('filterlist') },
            React.createElement(IconButton, { onClick: handleIconClick },
                React.createElement(FilterAltIcon, null)))),
        isPopupOpen && React.createElement(MedicalConditionsFilter, { onClose: handleClosePopup, setFields: setFields, holdDataFilter: holdDataFilter })));
}
const MedicalConditionsTable = ({ deleteMedicalCondition, requestgetAllmedicalConditionStatus, requestDeleteMedicalConditionStatus, getAllmedicalConditions, getAllMedicalConditionsInfo }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('brandCode');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const [holdmedicalConditionId, setholdmedicalConditionId] = useState("");
    const [deleteData, setDeleteData] = useState();
    const [DrugBrandCode, setDrugBrandCode] = useState();
    const [Description, setDescription] = useState("");
    useEffect(() => {
        getAllMedicalConditionsInfo(page, rowsPerPage, order, orderBy, DrugBrandCode, Description);
    }, [page, rowsPerPage, orderBy, order, DrugBrandCode, Description]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = getAllmedicalConditions?.resultItems?.map((n) => n.medicalCondition);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        var holdData = event.target.value;
        setRowsPerPage(holdData);
        setPage(0);
    };
    const handleDeleteInfo = (medicalConditionId) => {
        deleteMedicalCondition(medicalConditionId);
    };
    var medicalConditionObjectLocalStorage = {
        page: 0,
        rowsPerPage: 5,
        order: 'asc',
        orderBy: 'Name',
        DrugBrandCode: '',
        Description: '',
    };
    //hold data and set localstorage onclick modify button
    const holdForLocalStorageFun = (page, rowsPerPage, order, orderBy, DrugBrandCode, Description) => {
        medicalConditionObjectLocalStorage = {
            page: page,
            rowsPerPage: rowsPerPage,
            order: order,
            orderBy: orderBy,
            DrugBrandCode: DrugBrandCode,
            Description: Description
        };
        localStorage.setItem('medicalConditionObjectLocalStorage', JSON.stringify(medicalConditionObjectLocalStorage));
    };
    //relaod page call get all api
    useEffect(() => {
        // Get data from localStorage
        const storedData = localStorage.getItem('medicalConditionObjectLocalStorage');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const { page, rowsPerPage, order, orderBy, DrugBrandCode, Description } = parsedData;
            setPage(page);
            setRowsPerPage(rowsPerPage);
            setOrder(order);
            setOrderBy(orderBy);
            setDrugBrandCode(DrugBrandCode);
            setDescription(Description);
            getAllMedicalConditionsInfo(page, rowsPerPage, order, orderBy, DrugBrandCode, Description);
        }
        // getAllMedicalConditionsInfo(page, rowsPerPage, order, orderBy, "AVSOLA")
    }, []);
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Check if the page is being reloaded
            if (event.currentTarget.performance.navigation.type === 1) {
                localStorage.removeItem('medicalConditionObjectLocalStorage');
                medicalConditionObjectLocalStorage = {
                    page: 0,
                    rowsPerPage: 5,
                    order: 'asc',
                    orderBy: 'Name',
                    DrugBrandCode: '',
                    Description: '',
                };
                getAllMedicalConditionsInfo(medicalConditionObjectLocalStorage.page, medicalConditionObjectLocalStorage.rowsPerPage, medicalConditionObjectLocalStorage.order, medicalConditionObjectLocalStorage.orderBy, medicalConditionObjectLocalStorage.DrugBrandCode, medicalConditionObjectLocalStorage.Description);
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    useEffect(() => {
        if (requestDeleteMedicalConditionStatus === "success") {
            setDisplayInfo(false);
            getAllMedicalConditionsInfo(page, rowsPerPage, order, orderBy, DrugBrandCode, Description);
        }
    }, [requestDeleteMedicalConditionStatus]);
    const handleDelete = (row, medicalConditionId) => {
        setholdmedicalConditionId(medicalConditionId);
        setDeleteData(row);
        setDisplayInfo(true);
    };
    const setField = (medicalConditionFilterfieldsObj) => {
        setDescription(medicalConditionFilterfieldsObj?.Description);
        setDrugBrandCode(medicalConditionFilterfieldsObj?.drugBrandCode);
    };
    const setSearch = (searchValue) => {
        setDescription(searchValue);
    };
    return (React.createElement(Box, { sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { className: globaltableStyle.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement(EnhancedTableToolbar, { numSelected: selected.length, setField: setField, setSearch: setSearch }),
            React.createElement(TableContainer, { className: globaltableStyle.borderTopElement },
                React.createElement(Table, { sx: { minWidth: 750 }, "aria-labelledby": "tableTitle", size: dense ? 'small' : 'medium', className: globaltableStyle.tableCustomDesign },
                    React.createElement(EnhancedTableHead, { numSelected: selected.length, order: order, orderBy: orderBy, onSelectAllClick: handleSelectAllClick, onRequestSort: handleRequestSort, rowCount: getAllmedicalConditions?.resultItems?.length }),
                    getAllmedicalConditions?.resultItems?.length === 0 ? (React.createElement(TableBody, null,
                        React.createElement(TableRow, { key: "no-records" },
                            React.createElement(TableCell, { colSpan: 8, align: "center" }, t('noRecordFound'))))) : (React.createElement(TableBody, null, getAllmedicalConditions?.resultItems?.map((row, index) => {
                        return (React.createElement(TableRow, { key: row.medicalConditionId || index },
                            React.createElement(TableCell, { sx: { width: '20ch' }, align: "center" }, row?.drugBrandCodes == "" ? "-" : row?.drugBrandCodes.join(", ")),
                            React.createElement(TableCell, { align: "center" }, row?.medicalConditionDescription == "" ? "-" : row?.medicalConditionDescription),
                            React.createElement(TableCell, { sx: { width: '17ch' }, align: "right" },
                                React.createElement(Link, { to: `/medicalConditions/${row.medicalConditionId}`, style: { textDecoration: 'none', } },
                                    React.createElement(Button, { variant: "contained", className: medicalConditioncss.iconbutton, onClick: () => holdForLocalStorageFun(page, rowsPerPage, order, orderBy, DrugBrandCode, Description) },
                                        " ",
                                        React.createElement(EditIcon, null),
                                        " ")),
                                React.createElement(Button, { className: medicalConditioncss.iconbuttonDelete, variant: "contained", onClick: () => handleDelete(row, row.medicalConditionId) },
                                    " ",
                                    React.createElement(DeleteIcon, null),
                                    " "))));
                    }))))),
            React.createElement(TablePagination, { className: globaltableStyle.tableCustomPagination, rowsPerPageOptions: [5, 10, 25], component: "div", count: getAllmedicalConditions?.rowCount || 0, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelRowsPerPage: t('itemsPerPage') })),
        React.createElement(DeletePopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false), onClick: () => handleDeleteInfo(holdmedicalConditionId), header: t("deletePermanently") },
            React.createElement(Box, { className: golbalcss.deletePopupText, sx: { width: '100%' } },
                React.createElement(Grid, { container: true, rowSpacing: 1, columnSpacing: { xs: 1, sm: 2, md: 3 } }))),
        requestgetAllmedicalConditionStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestDeleteMedicalConditionStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        getAllmedicalConditions: state.medicalConditions.getAllmedicalConditions,
        requestgetAllmedicalConditionStatus: state.medicalConditions.requestgetAllmedicalConditionStatus,
        requestDeleteMedicalConditionStatus: state.medicalConditions.requestDeleteMedicalConditionStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getAllMedicalConditionsInfo: (page, rowPerPage, order, orderBy, DrugBrandCode, Description) => dispatch(getAllmedicalConditionInfoAsyncAction({ page, rowPerPage, order, orderBy, DrugBrandCode, Description })),
        deleteMedicalCondition: (medicalConditionId) => dispatch(deleteMedicalConditionAsyncAction({ medicalConditionId })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MedicalConditionsTable);
