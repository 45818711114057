import React from 'react';
import { PopUp } from '../../Info';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './style.scss';
const Accessibility = ({ style, isOpen, setIsOpen }) => {
    const { t } = useTranslation();
    return (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: isOpen, allowClose: true, toggle: () => setIsOpen(false) },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2 }, t("Accessibility"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", { className: s.intend }, t("accessibilityContent")))));
};
export default Accessibility;
