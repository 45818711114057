import React from "react";
import { Navigate, Route, Outlet } from "react-router-dom";
import Sidebar from "../Sidebar";
import s from "../../App.scss";
import Cookies from "js-cookie";
import PageContainer from "../Shared/PageContainer";
import SessionTimeout from "../SessionTimeout/SessionTimeout";

export const SideBarRoute = ({ path, component: Element }) => {
  return Cookies.get("token") ? (
    <PageContainer>
      <div className={s.sideBarRouteWrapper}>
        <Sidebar />
        <Outlet />
      </div>
      <SessionTimeout></SessionTimeout>
    </PageContainer>
  ) : (
    <Navigate to="/" />
  );
  // return !Cookies.get("token") ? (
  //   <Navigate to="/" />
  // ) : (
  //   <Route
  //     render={(props) => {
  //       return (
  //         <PageContainer>
  //           <div className={s.sideBarRouteWrapper}>
  //             <Sidebar />
  //             <Element {...props} />
  //           </div>
  //         </PageContainer>
  //       );
  //     }}
  //   />
  // );
};
