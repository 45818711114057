import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { useInput } from "../../../../../hooks";
import { Button, InputText, } from "../../../../../components/Shared/Controls";
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import { putPatientPharmacyAsyncAction, postPatientPharmacyAsyncAction, getPatientPharmacyAsyncAction } from "../../../../../store/pharmacy/Sagas";
import LoadingPopUp from "../../../../../components/Shared/LoadingPopUp";
import { resetCreatePharmacyInfoAction, resetPutPharmacyInfoAction } from "../../../../../store/pharmacy";
import Typography from "../../../../../components/Shared/Typography";
import { useNavigate } from "react-router";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import colors from "../../../../../resources/colors";
const initialAddress = {
    postalCode: "",
    city: "",
    street: "",
    province: "",
    addressType: "home",
    isPrimary: false,
};
const AddPharmacyManual = ({ patientId, isNewSignUp, setStep, previousStep, createPharmacy, getPatientPharmacyData, getPatientPharmacyAction, requestGetPharmacyStatus, requestPharmacyPostStatus, resetCreatePharmacyAction, PutPharmacyInfoAsyncAction, requestPutPharmacyStatus, resetPutPharmacyAction, isDashboard }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [city, setCity, errorCity, setErrorCity] = useInput();
    const [AddressLineOne, setAddressLineOne, errorAddressLineOne, setErrorAddressLineOne] = useInput();
    const [pharmacyName, setPharmacyName, errorPharmacyName, setErrorPharmacyName] = useInput();
    const [phone, setPhone] = React.useState('');
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput();
    const handlePhoneInputChange = (value, info) => {
        setPhone(value);
        setPhoneNumber(info.nationalNumber);
        setErrorPhoneNumber(!matchIsValidTel(value));
    };
    useEffect(() => {
        if (getPatientPharmacyData) {
            setPharmacyName(getPatientPharmacyData?.[0]?.name);
            setPhoneNumber(getPatientPharmacyData?.[0]?.phone);
            setPhone("+1" + getPatientPharmacyData?.[0]?.phone);
            setAddressLineOne(getPatientPharmacyData?.[0]?.address);
            setCity(getPatientPharmacyData?.[0]?.city);
        }
    }, [getPatientPharmacyData]);
    const allFieldsValid = () => city && AddressLineOne &&
        pharmacyName && phoneNumber && !errorPhoneNumber;
    useEffect(() => {
        getPatientPharmacyAction({ patientId });
    }, []);
    useEffect(() => {
        if (requestPharmacyPostStatus == "success" && isNewSignUp) {
            resetCreatePharmacyAction();
            setStep(true);
        }
        if (requestPharmacyPostStatus == "success" && isDashboard) {
            resetCreatePharmacyAction();
            navigate("/pharmacy");
        }
    }, [requestPharmacyPostStatus]);
    useEffect(() => {
        if (requestPutPharmacyStatus == "success" && isNewSignUp) {
            resetPutPharmacyAction();
            setStep(true);
        }
        if (requestPutPharmacyStatus == "success" && isDashboard) {
            resetPutPharmacyAction();
            navigate("/pharmacy");
        }
    }, [requestPutPharmacyStatus]);
    const Submit = () => {
        if (getPatientPharmacyData?.length > 0 && isDashboard) {
            const data = {
                id: getPatientPharmacyData?.[0]?.patientBrandPharmacyId,
                name: pharmacyName,
                address: AddressLineOne,
                city: city,
                phone: phoneNumber,
                brandIdentifier: "Manual"
            };
            PutPharmacyInfoAsyncAction(data);
        }
        else {
            createPharmacy({
                name: pharmacyName,
                address: AddressLineOne,
                city: city,
                postal: "",
                country: "CA",
                phone: phoneNumber,
                note: "",
                brandIdentifier: "Manual",
            });
        }
    };
    const NavigateFunction = () => {
        if (isDashboard) {
            navigate('/pharmacy');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(si.contentWrapper) },
            React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
                React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                    React.createElement("div", { className: classNames(si.formstepbar) },
                        React.createElement("div", { className: (si.stepToptext) },
                            React.createElement("div", { className: s.pages }, isNewSignUp ? React.createElement(React.Fragment, null,
                                React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `14/15`)) : null),
                            getPatientPharmacyData?.length > 0 ? React.createElement(React.Fragment, null,
                                React.createElement("h4", null, t("updatePharmacy"))) : React.createElement(React.Fragment, null,
                                React.createElement("h4", null, t("addPharmacy"))),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null, t("provideYourPharmacy"))))),
                    React.createElement("div", { className: classNames(si.contentInput) },
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null,
                                t("pharmacyName"),
                                " *"),
                            React.createElement(InputText, { name: "pharmacyName", pattern: '^.{0,35}$', value: pharmacyName, setValue: setPharmacyName, error: pharmacyName && errorPharmacyName, setError: setErrorPharmacyName, tabIndex: 1, onFocus: true })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("AddressLineOne")),
                            React.createElement(InputText, { name: "AddressLineOne", pattern: "^.{0,35}$", value: AddressLineOne, setValue: setAddressLineOne, error: AddressLineOne && errorAddressLineOne, setError: setErrorAddressLineOne, tabIndex: 2 })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("pharmacyCity")),
                            React.createElement(InputText, { name: "city", pattern: "^.{0,35}$", value: city, setValue: setCity, error: city && errorCity, setError: setErrorCity, tabIndex: 3 })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("pharmacyPhoneNumber")),
                            React.createElement(MuiTelInput, { value: phone, onChange: handlePhoneInputChange, error: errorPhoneNumber, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 4 }))),
                    React.createElement("div", { className: si.buttonTwopage },
                        React.createElement(Button, { text: t("back"), onClick: isNewSignUp ? previousStep : NavigateFunction }),
                        React.createElement(Button, { style: s.mb0_button, primary: true, disabled: !allFieldsValid(), text: t("SaveProceed"), onClick: Submit }))))),
        requestGetPharmacyStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestPharmacyPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
        requestPutPharmacyStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId ?? "",
        getPatientPharmacyData: state.pharmacy.getPharmacyData,
        requestGetPharmacyStatus: state.pharmacy.requestGetPharmacyStatus,
        requestPharmacyPostStatus: state.pharmacy.requestPharmacyPostStatus,
        requestPutPharmacyStatus: state.pharmacy.requestPutPharmacyStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        createPharmacy: (PharmacyInfo) => dispatch(postPatientPharmacyAsyncAction(PharmacyInfo)),
        getPatientPharmacyAction: (data) => dispatch(getPatientPharmacyAsyncAction(data)),
        resetCreatePharmacyAction: () => dispatch(resetCreatePharmacyInfoAction()),
        PutPharmacyInfoAsyncAction: (data) => dispatch(putPatientPharmacyAsyncAction(data)),
        resetPutPharmacyAction: () => dispatch(resetPutPharmacyInfoAction())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPharmacyManual);
