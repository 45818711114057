import { useRef, useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import React from "react";
const libraries = ['places'];
const MapContainerMultiple = ({ locations, setMapRef, setIsLoaded, address, handlePinSelected, selectedLocation, setCurrentLocation }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBxlswj6IWjzgiuqoG7IxoMWn8kXWU54vY",
        libraries
    });
    useEffect(() => {
        setIsLoaded(isLoaded);
    }, [isLoaded]);
    const mapRef = useRef(null);
    const [currentMapLocation, setCurrentMapLocation] = useState({ lat: 43.653226, lng: -79.3831843 });
    const onLoad = (ref) => {
        if (ref) {
            mapRef.current = ref;
            if (setMapRef != null)
                setMapRef(ref);
        }
    };
    const setAddressCenter = () => {
        if (!!address) {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: address }, function (results, status) {
                if (status === "OK" && results != null) {
                    setCurrentMapLocation({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    });
                }
                else {
                    console.log("Geocoding was not successful for the following reason: " + status);
                }
            });
        }
    };
    useEffect(() => {
        if (!isLoaded)
            return;
        if (!!address) {
            setAddressCenter();
        }
        else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const newLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
                setCurrentMapLocation(newLocation);
                setCurrentLocation(newLocation);
            }, (error) => {
                console.log(error);
                setAddressCenter();
            });
        }
        else {
            console.log("Geolocation not supported");
        }
    }, [isLoaded, setMapRef, address]); // execute effect whenever isLoaded or setMapRef changes
    const handlePinClick = (event) => {
        console.log("You clicked on: " + event);
        if (handlePinSelected)
            handlePinSelected(event);
    };
    if (!isLoaded)
        return React.createElement("div", null, "Loading...");
    return (React.createElement(GoogleMap, { zoom: 12, center: (selectedLocation ?? locations?.[0]) ?? currentMapLocation, mapContainerClassName: "map-container", onLoad: onLoad, mapContainerStyle: { width: "100%", height: "100%" } }, locations?.map((location) => (React.createElement(Marker, { onClick: handlePinClick, key: location.id, position: { lat: location.lat, lng: location.lng } })))));
};
export default MapContainerMultiple;
