import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { userBrandByIdSelector } from "../../store";
import Sign from '../../resources/images/sign.svg';
import Arrow from '../../resources/icons/arrow.svg';
import StatusDisplay from "../../components/StatusDisplay";
import CheckMark from "../../resources/icons/checkMark";
import colors from "../../resources/colors";
import Header from "../components/Header";
import SVG from 'react-inlinesvg';
import s from './style.scss';
import Banner from "../components/Banner";
const AdditionalStepsWrapper = ({ backAction, brand, children }) => {
    const { t } = useTranslation();
    const renderSigns = () => React.createElement(React.Fragment, null,
        React.createElement(SVG, { src: Sign, className: s.signIconN7 }),
        React.createElement(SVG, { src: Sign, className: s.signIconN8 }),
        React.createElement(SVG, { src: Sign, className: s.signIconN9 }),
        React.createElement(SVG, { src: Sign, className: s.signIconN10 }));
    const stepBack = (previusStep) => {
        return React.createElement("div", { className: s.back, onClick: previusStep },
            React.createElement(SVG, { src: Arrow, className: s.arrow }),
            t("back"));
    };
    const renderCheckMark = () => React.createElement("div", { className: s.checkMarkWrapper },
        React.createElement(CheckMark, { color1: colors.checkMarkColor, color2: "#FFC800", style: s.checkMark }));
    return React.createElement("div", null,
        React.createElement(Banner, null),
        React.createElement(StatusDisplay, null),
        React.createElement("div", { className: s.register },
            React.createElement(Header, null),
            React.createElement("div", { className: s.scrollWrapperfrom },
                React.createElement("div", { className: s.formstep },
                    React.createElement("div", { className: s.formWrapper }, children)))));
};
const mapStateToProps = (state) => {
    return {
        brand: userBrandByIdSelector(state),
    };
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalStepsWrapper);
