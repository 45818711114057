import { put, call, take, select, all, takeLatest } from "redux-saga/effects";
import _, { groupBy } from "lodash";
import { PatientFilesRepository } from "../repository";
import { handleApiError } from "../utils";
import patientFilesSlice, { SLICE_NAME } from "./Slice";
import { getMemberInfoSelector, userInfoSelector } from "../account";
import { mapFilesPayload, mapScheduleCallTimes, mapFinancialAssistanceConfig, mapFinancialAssistanceInfo, mapPreviewLink } from "./Mapper";
import moment from "moment";
import { memberInfoSelector } from "../auth/selectors";
import { getAvailableTimesAsyncAction, getFinancialAssistanceAsyncAction, getPatientFilesAsyncAction, getInsuranceAsyncAction, sendPatientFilesAsyncAction, updatePatientFilesAllTypesAsyncAction, updatePatientFilesAsyncAction, sendFinancialAssistanceAsyncAction, scheduleCallAsyncAction, getPreviewPhotoAsyncAction, getFinancialAssistanceConfigAsyncAction } from "./Actions";
import { dataURItoBlob } from "../../utils";
import config from "../../../config.json";
export function* sendPatientFilesSaga(action) {
    const { startRequestAddFilesAction, successRequestAddFilesAction, showRequestErrorAction } = patientFilesSlice.actions;
    const { drugBrandCode, patientId } = action.payload.isMember
        ? yield select(getMemberInfoSelector)
        : yield select(userInfoSelector);
    try {
        yield put(startRequestAddFilesAction());
        if (action.payload.files && action.payload.files.length) {
            yield handleUploadFile(action.payload.files, action.payload.files[0].filesType === "Prescription" ||
                action.payload.files[0].filesType === "Receipt"
                ? drugBrandCode
                : null, patientId, undefined);
        }
        if (action.payload.secondaryFiles && action.payload.secondaryFiles.length) {
            yield handleUploadFile(action.payload.secondaryFiles, null, patientId, undefined);
        }
        yield put(successRequestAddFilesAction());
    }
    catch (error) {
        yield put(showRequestErrorAction({
            code: 400,
            message: "Upload failed"
        }));
    }
}
function* handleUploadFile(files, drugBrandCode, patientId, textValue) {
    let formData = new FormData();
    files.forEach((item, index) => {
        let blob = dataURItoBlob(item.uri);
        if (blob == null)
            return;
        formData.append(`Files[][${index}]`, blob, index + item.name);
        if (item.description) {
            formData.append(`FileNameDescription[${index}]`, item.description);
        }
    });
    if (!!files[0].id)
        formData.append("PatientFileId", files[0].id);
    formData.append("FilesType", files[0].filesType);
    formData.append("PatientId", patientId);
    if (drugBrandCode) {
        formData.append("DrugBrandCode", drugBrandCode);
    }
    if (textValue) {
        if (files[0].filesType === "HealthCard") {
            if (textValue.healthCardNumber)
                formData.append("TextValue.HealthCardNumber", textValue.healthCardNumber);
        }
        else {
            if (textValue.insurerName)
                formData.append("TextValue.InsurerName", textValue.insurerName);
            if (textValue.carrierNumber)
                formData.append("TextValue.CarrierNumber", textValue.carrierNumber);
            if (textValue.groupPolicyContractNumber)
                formData.append("TextValue.GroupPolicyContractNumber", textValue.groupPolicyContractNumber);
            if (textValue.certificateNumber)
                formData.append("TextValue.CertificateNumber", textValue.certificateNumber);
            if (textValue.primaryCardHolder != null)
                formData.append("TextValue.PrimaryCardHolder", textValue.primaryCardHolder + "");
        }
    }
    let channel = yield call(PatientFilesRepository.uploadFiles, formData);
    while (true) {
        let { err } = yield take(channel);
        if (err) {
            throw new Error();
        }
    }
}
export function* getAvailableTimesSaga() {
    const { startRequestGetTimesAction, successRequestGetTimesAction, showRequestGetTimesErrorAction } = patientFilesSlice.actions;
    try {
        yield put(startRequestGetTimesAction());
        const utcOffset = moment().add(1, "minutes").utcOffset() / 60;
        const result = yield call(PatientFilesRepository.getAvailableTimes, utcOffset);
        const data = yield mapScheduleCallTimes(result.data);
        yield put(successRequestGetTimesAction(data));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetTimesErrorAction);
    }
}
export function* scheduleCallSaga(action) {
    const { startRequestScheduleCallAction, successRequestScheduleCallAction, showRequestScheduleCallErrorAction } = patientFilesSlice.actions;
    const { drugBrandCode } = yield select(userInfoSelector);
    try {
        yield put(startRequestScheduleCallAction());
        yield call(PatientFilesRepository.scheduleCall, {
            userLocalDateTime: action.payload.userLocalDateTime.replace("Z", ""),
            utcOffset: moment().utcOffset() / 60,
            drugBrandCode
        });
        yield put(successRequestScheduleCallAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestScheduleCallErrorAction);
    }
}
export function* getPatientFilesSaga(action) {
    const { startRequestGetFilesAction, successRequestGetFilesAction, showRequestGetFilesErrorAction } = patientFilesSlice.actions;
    const { drugBrandCode, patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestGetFilesAction());
        const result = yield call(PatientFilesRepository.getPatientFiles, {
            patientId,
            photosType: action.payload.type,
            drugBrandCode: action.payload.type === "Prescription" ||
                action.payload.type === "Receipt"
                ? drugBrandCode
                : null
        });
        const files = yield mapFilesPayload(result.data, action.payload.type);
        let additionalFiles = [];
        if (action.payload.additionalType) {
            const additionalResult = yield call(PatientFilesRepository.getPatientFiles, {
                patientId,
                photosType: action.payload.additionalType,
                drugBrandCode: action.payload.additionalType === 'Prescription' ||
                    action.payload.additionalType === "Receipt"
                    ? drugBrandCode
                    : null
            });
            additionalFiles = yield mapFilesPayload(additionalResult.data, action.payload.additionalType);
        }
        yield put(successRequestGetFilesAction([...files, ...additionalFiles]));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetFilesErrorAction);
    }
}
export function* deletePatientFilesSaga(payload) {
    const { startRequestDeleteFilesAction, successRequestDeleteFilesAction, showRequestDeleteFilesErrorAction } = patientFilesSlice.actions;
    try {
        yield put(startRequestDeleteFilesAction());
        const data = [];
        for (let i = 0; i < payload.length; i++) {
            data.push(call(PatientFilesRepository.deletePatientFiles, payload[i].id, payload[i].deleteType));
        }
        yield all(data);
        yield put(successRequestDeleteFilesAction(payload.map(p => p.id)));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeleteFilesErrorAction);
    }
}
export function* updatePatientFilesSaga(action) {
    const { startRequestUpdateFilesAction, successRequestUpdateFilesAction, showRequestUpdateFilesErrorAction } = patientFilesSlice.actions;
    try {
        yield put(startRequestUpdateFilesAction());
        yield deletePatientFilesSaga(action.payload.deleted);
        yield sendPatientFilesSaga({
            payload: action.payload.added,
            type: `${SLICE_NAME}/sendPatientFilesAsyncAction`
        });
        yield getPatientFilesSaga({
            payload: {
                type: action.payload.fileType,
                additionalType: action.payload.additionalFileType
            },
            type: `${SLICE_NAME}/getPatientFilesAsyncAction`
        });
        yield put(successRequestUpdateFilesAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdateFilesErrorAction);
    }
}
// use only for Insurance & Health Card
export function* updatePatientFilesAllTypesSaga(action) {
    const { startRequestHealthCardUploadAction, startRequestInsurancesUploadAction, successRequestHealthCardUploadAction, successRequestInsurancesUploadAction, showRequestHealthCardUploadErrorAction, showRequestInsurancesUploadErrorAction, updatePatientFiles } = patientFilesSlice.actions;
    try {
        let files = [];
        let insurances = [];
        if (action.payload.screenName === "HealthCard") {
            yield put(startRequestHealthCardUploadAction());
        }
        else if (action.payload.screenName === "Insurance") {
            yield put(startRequestInsurancesUploadAction());
        }
        if (action.payload.fileValue) {
            const { deleted, added, fileType } = action.payload.fileValue;
            const { patientId } = yield select(added.isMember ? getMemberInfoSelector : userInfoSelector);
            if (deleted && deleted.length) {
                yield deletePatientFiles(deleted);
            }
            if ((added && added.files.length) ||
                (added && added.secondaryFiles?.length)) {
                yield sendPatientFiles(added, action.payload.textValue, action.payload.secondaryTextValue);
            }
        }
        if (action.payload.textValue
            && (action.payload.fileValue?.added.files.filter(f => f.filesType == action.payload.textValue?.fileType).length ?? 0) == 0
            && (action.payload.fileValue?.deleted.filter(f => f.id == action.payload.textValue?.patientFileId).length ?? 0) == 0) {
            const { isMember, idsForDelete, fileType, patientFileId, healthCardNumber, insurerName, carrierNumber, groupPolicyContractNumber, certificateNumber, primaryCardHolder } = action.payload.textValue;
            const { patientId } = isMember
                ? yield select(getMemberInfoSelector)
                : yield select(userInfoSelector);
            // if fileView is available -> files was deleted before
            if (idsForDelete && idsForDelete.length && !action.payload.fileValue) {
                yield deletePatientFiles(idsForDelete);
            }
            if (fileType) {
                const payload = {
                    patientFileId: patientFileId || null,
                    patientId,
                    fileType: fileType,
                    textValue: fileType === "HealthCard" ?
                        {
                            healthCardNumber: healthCardNumber
                        } : {
                        insurerName: insurerName,
                        carrierNumber: carrierNumber || null,
                        groupPolicyContractNumber: groupPolicyContractNumber || null,
                        certificateNumber: certificateNumber || null,
                        primaryCardHolder: _.isNil(primaryCardHolder) ? null : primaryCardHolder
                    }
                };
                yield call(PatientFilesRepository.setPatientFilesByText, payload);
            }
        }
        if (action.payload.secondaryTextValue
            && (action.payload.fileValue?.added.secondaryFiles?.filter(f => f.filesType == action.payload.secondaryTextValue?.fileType).length ?? 0) == 0) {
            const { isMember, idsForDelete, fileType, patientFileId, healthCardNumber, insurerName, carrierNumber, groupPolicyContractNumber, certificateNumber, primaryCardHolder } = action.payload.secondaryTextValue;
            const { patientId } = yield select(isMember
                ? getMemberInfoSelector : userInfoSelector);
            if (idsForDelete && idsForDelete.length
                && !_.isEqual(action.payload.textValue?.idsForDelete, idsForDelete)
                && !action.payload.fileValue) {
                yield deletePatientFiles(idsForDelete);
            }
            if (fileType) {
                const payload = {
                    patientFileId: patientFileId || null,
                    patientId,
                    fileType: fileType,
                    textValue: fileType === "HealthCard" ?
                        {
                            healthCardNumber: healthCardNumber
                        } : {
                        insurerName: insurerName,
                        carrierNumber: carrierNumber || null,
                        groupPolicyContractNumber: groupPolicyContractNumber || null,
                        certificateNumber: certificateNumber || null,
                        primaryCardHolder: _.isNil(primaryCardHolder) ? null : primaryCardHolder
                    }
                };
                yield call(PatientFilesRepository.setPatientFilesByText, payload);
            }
        }
        let isMember = false;
        let fileType = "HealthCard";
        if (action.payload.fileValue) {
            isMember = action.payload.fileValue.added?.isMember ?? false;
            fileType = action.payload.fileValue?.fileType;
        }
        else {
            isMember = action.payload.textValue?.isMember ?? false;
            fileType = action.payload.textValue?.fileType;
        }
        const { patientId } = yield select(isMember ?
            getMemberInfoSelector : userInfoSelector);
        if (action.payload.screenName === "Insurance") {
            insurances = yield getInsurances(patientId);
        }
        else {
            files = yield getPatientFiles(fileType ?? "HealthCard");
        }
        yield put(updatePatientFiles({
            deleted: [
                ...action.payload.textValue?.idsForDelete || [],
                ...action.payload.fileValue?.deleted || [],
                ...action.payload.secondaryTextValue?.idsForDelete || [],
                ...action.payload.deleteFilesIds || []
            ],
            files: [...files, ...insurances]
        }));
        if (action.payload.screenName === "HealthCard") {
            yield put(successRequestHealthCardUploadAction());
        }
        else if (action.payload.screenName === "Insurance") {
            yield put(successRequestInsurancesUploadAction());
        }
    }
    catch (error) {
        if (action.payload.screenName === "HealthCard") {
            yield handleApiError(error, showRequestHealthCardUploadErrorAction);
        }
        else if (action.payload.screenName === "Insurance") {
            yield handleApiError(error, showRequestInsurancesUploadErrorAction);
        }
    }
}
export function* deletePatientFiles(payload) {
    const data = [];
    const ids = payload.filter((value, index, self) => self.indexOf(value) === index);
    for (let i = 0; i < ids.length; i++) {
        data.push(call(PatientFilesRepository.deletePatientFiles, ids[i].id, ids[i].deleteType));
    }
    yield all(data);
}
export function* getInsurances(patientId) {
    const primaryInsurance = yield call(PatientFilesRepository.getPatientFiles, {
        patientId: patientId,
        photosType: "PrimaryInsurance",
        drugBrandCode: null
    });
    const primaryFiles = yield mapFilesPayload(primaryInsurance.data, "PrimaryInsurance");
    const primaryInsuranceBack = yield call(PatientFilesRepository.getPatientFiles, {
        patientId: patientId,
        photosType: "PrimaryInsuranceBack",
        drugBrandCode: null
    });
    const primaryFilesBack = yield mapFilesPayload(primaryInsuranceBack.data, "PrimaryInsuranceBack");
    const HealthCardInsurance = yield call(PatientFilesRepository.getPatientFiles, {
        patientId: patientId,
        photosType: "HealthCard",
        drugBrandCode: null
    });
    const healthCardFiles = yield mapFilesPayload(HealthCardInsurance.data, "HealthCard");
    const secondaryInsurance = yield call(PatientFilesRepository.getPatientFiles, {
        patientId,
        photosType: "SecondaryInsurance",
        drugBrandCode: null
    });
    const secondaryFiles = yield mapFilesPayload(secondaryInsurance.data, "SecondaryInsurance");
    const secondaryInsuranceBack = yield call(PatientFilesRepository.getPatientFiles, {
        patientId,
        photosType: "SecondaryInsuranceBack",
        drugBrandCode: null
    });
    const secondaryFilesBack = yield mapFilesPayload(secondaryInsuranceBack.data, "SecondaryInsuranceBack");
    return [...primaryFiles, ...primaryFilesBack, ...secondaryFiles, ...secondaryFilesBack,
        ...healthCardFiles
    ];
}
export function* getInsuranceSaga() {
    const { startRequestGetInsurancesFilesAction, successRequestGetInsurancesFilesAction, showRequestGetInsurancesFilesErrorAction } = patientFilesSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestGetInsurancesFilesAction());
        const insurances = yield getInsurances(patientId);
        yield put(successRequestGetInsurancesFilesAction(insurances));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetInsurancesFilesErrorAction);
    }
}
export function* getFinancialAssistanceConfigSaga(action) {
    const { startRequestGetFinancialConfigAction, successRequestGetFinancialConfigAction, showRequestGetFinancialConfigErrorAction } = patientFilesSlice.actions;
    const { drugBrandCode, province } = yield select(action.payload.isMember ? memberInfoSelector : userInfoSelector);
    try {
        yield put(startRequestGetFinancialConfigAction());
        const result = yield call(PatientFilesRepository.getFinancialAssistanceConfig, {
            drugBrandCode,
            province
        });
        const config = yield mapFinancialAssistanceConfig(result.data);
        yield put(successRequestGetFinancialConfigAction(config));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetFinancialConfigErrorAction);
    }
}
export function* sendFinancialAssistanceSaga(action) {
    const { startRequestSendFinancialAssistanceAction, successRequestSendFinancialAssistanceAction, showRequestSendFinancialAssistanceErrorAction } = patientFilesSlice.actions;
    const { drugBrandCode, patientId, province } = yield select(action.payload.isMember ? getMemberInfoSelector : userInfoSelector);
    try {
        yield put(startRequestSendFinancialAssistanceAction());
        yield call(PatientFilesRepository.sendFinancialAssistance, {
            householdIncome: action.payload.householdIncome,
            dependentsNumber: action.payload.dependentsNumber,
            patientId,
            drugBrandCode,
            province,
            applicationType: config.applicationType,
        });
        yield put(successRequestSendFinancialAssistanceAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestSendFinancialAssistanceErrorAction, false);
    }
}
export function* getFinancialAssistanceSaga(action) {
    const { startRequestGetFinancialAssistanceAction, successRequestGetFinancialAssistanceAction, showRequestGetFinancialAssistanceErrorAction } = patientFilesSlice.actions;
    const { patientId, drugBrandCode } = yield select(action.payload.isMember ? getMemberInfoSelector : userInfoSelector);
    try {
        yield put(startRequestGetFinancialAssistanceAction());
        //mock
        const result = yield call(PatientFilesRepository.getFinancialAssistance, {
            patientId,
            drugBrandCode
        });
        const data = yield mapFinancialAssistanceInfo(result.data);
        yield put(successRequestGetFinancialAssistanceAction(data));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetFinancialAssistanceErrorAction);
    }
}
export function* getPreviewPhotoSaga(action) {
    const { startRequestGetPreviewAction, successRequestGetPreviewAction, showRequestGetPreviewErrorAction } = patientFilesSlice.actions;
    try {
        yield put(startRequestGetPreviewAction());
        const result = yield call(PatientFilesRepository.getPreviewPhoto, action.payload);
        const link = yield mapPreviewLink(result.data);
        yield put(successRequestGetPreviewAction(link));
    }
    catch (error) {
        yield handleApiError(error, showRequestGetPreviewErrorAction);
    }
}
export function* sendPatientFiles(payload, textValue, secondaryTextValue) {
    const { drugBrandCode, patientId } = payload.isMember
        ? yield select(getMemberInfoSelector)
        : yield select(userInfoSelector);
    if (payload.files && payload.files.length) {
        const groups = groupBy(payload.files, 'filesType');
        for (const group in groups) {
            yield handleUploadFile(groups[group], groups[group][0].filesType === "Prescription" || groups[group][0].filesType === "Receipt"
                ? drugBrandCode : null, patientId, groups[group][0].filesType == textValue?.fileType ? textValue : undefined);
        }
    }
    if (payload.secondaryFiles && payload.secondaryFiles.length) {
        const groups = groupBy(payload.secondaryFiles, 'filesType');
        for (const group in groups) {
            let textVal = groups[group][0].filesType == secondaryTextValue?.fileType ? secondaryTextValue : undefined;
            yield handleUploadFile(groups[group], null, patientId, textVal);
        }
    }
}
export function* getPatientFiles(payload) {
    const { drugBrandCode, patientId } = yield select(userInfoSelector);
    const result = yield call(PatientFilesRepository.getPatientFiles, {
        patientId,
        photosType: payload,
        drugBrandCode: payload === "Prescription" || payload === "Receipt"
            ? drugBrandCode
            : null
    });
    const files = yield mapFilesPayload(result.data, payload);
    return files;
}
export default function* rootSaga() {
    yield takeLatest(getFinancialAssistanceAsyncAction, getFinancialAssistanceSaga);
    yield takeLatest(getAvailableTimesAsyncAction, getAvailableTimesSaga);
    yield takeLatest(scheduleCallAsyncAction, scheduleCallSaga);
    yield takeLatest(sendPatientFilesAsyncAction, sendPatientFilesSaga);
    yield takeLatest(updatePatientFilesAsyncAction, updatePatientFilesSaga);
    yield takeLatest(getPatientFilesAsyncAction, getPatientFilesSaga);
    yield takeLatest(updatePatientFilesAllTypesAsyncAction, updatePatientFilesAllTypesSaga);
    yield takeLatest(getInsuranceAsyncAction, getInsuranceSaga);
    yield takeLatest(sendFinancialAssistanceAsyncAction, sendFinancialAssistanceSaga);
    yield takeLatest(getPreviewPhotoAsyncAction, getPreviewPhotoSaga);
    yield takeLatest(getFinancialAssistanceConfigAsyncAction, getFinancialAssistanceConfigSaga);
}
