// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__wrapper--V7mXg{width:16px;height:16px;margin:2px;background:#EAF0F9;border:1px solid #CBDAEF;border-radius:2px}.style__checkbox--zsWMu{width:16px;height:16px;border:0px;position:relative;top:2px;left:0px}.style__checked--ZWIYH{width:16px;height:16px;border:1px solid transparent;background:#0063C3;border-radius:2px}.style__checkmark--x0Fhr{position:relative;bottom:5px;left:4px}.style__unchecked--cpo3L{display:none}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/Controls/Checkbox/style.scss"],"names":[],"mappings":"AAAA,uBACI,UAAW,CACX,WAAY,CACZ,UAAW,CACX,kBAAmB,CACnB,wBAAyB,CACzB,iBAAkB,CACrB,wBAGG,UAAW,CACX,WAAY,CACZ,UAAW,CACX,iBAAkB,CAClB,OAAQ,CACR,QAAS,CACZ,uBAGG,UAAW,CACX,WAAY,CACZ,4BAA6B,CAE7B,kBAAmB,CACnB,iBAAkB,CACrB,yBAGG,iBAAkB,CAClB,UAAW,CACX,QAAS,CACZ,yBAGG,YAAa","sourcesContent":[".wrapper {\r\n    width: 16px;\r\n    height: 16px;\r\n    margin: 2px;\r\n    background: #EAF0F9;\r\n    border: 1px solid #CBDAEF;\r\n    border-radius: 2px;\r\n}\r\n\r\n.checkbox {\r\n    width: 16px;\r\n    height: 16px;\r\n    border: 0px;\r\n    position: relative;\r\n    top: 2px;\r\n    left: 0px;\r\n}\r\n\r\n.checked {\r\n    width: 16px;\r\n    height: 16px;\r\n    border: 1px solid transparent;\r\n\r\n    background: #0063C3;\r\n    border-radius: 2px;\r\n}\r\n\r\n.checkmark {\r\n    position: relative;\r\n    bottom: 5px;\r\n    left: 4px;\r\n}\r\n\r\n.unchecked {\r\n    display: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style__wrapper--V7mXg",
	"checkbox": "style__checkbox--zsWMu",
	"checked": "style__checked--ZWIYH",
	"checkmark": "style__checkmark--x0Fhr",
	"unchecked": "style__unchecked--cpo3L"
};
export default ___CSS_LOADER_EXPORT___;
