import { createAction } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Slice";
export const sendPatientFilesAsyncAction = createAction(`${SLICE_NAME}/sendPatientFilesAsyncAction`);
export const getAvailableTimesAsyncAction = createAction(`${SLICE_NAME}/getAvailableTimesAsyncAction`);
export const scheduleCallAsyncAction = createAction(`${SLICE_NAME}/scheduleCallAsyncAction`);
export const getPatientFilesAsyncAction = createAction(`${SLICE_NAME}/getPatientFilesAsyncAction`);
export const getInsuranceAsyncAction = createAction(`${SLICE_NAME}/getInsuranceAsyncAction`);
export const updatePatientFilesAsyncAction = createAction(`${SLICE_NAME}/updatePatientFilesAsyncAction`);
export const getFinancialAssistanceConfigAsyncAction = createAction(`${SLICE_NAME}/getFinancialAssistanceConfigAsyncAction`);
export const sendFinancialAssistanceAsyncAction = createAction(`${SLICE_NAME}/sendFinancialAssistanceAsyncAction`);
export const getFinancialAssistanceAsyncAction = createAction(`${SLICE_NAME}/getFinancialAssistanceAsyncAction`);
export const getPreviewPhotoAsyncAction = createAction(`${SLICE_NAME}/getPreviewPhotoAsyncAction`);
export const updatePatientFilesAllTypesAsyncAction = createAction(`${SLICE_NAME}/updatePatientFilesAllTypesAsyncAction`);
