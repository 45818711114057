import React, { useState } from "react";
import NewPhone from "./NewPhone";
import VerifyPhone from "./VerifyPhone";
import style from "../../style.scss";
const ChangePhone = ({ setPage }) => {
    const [step, setStep] = useState(1);
    const renderStepHeader = () => (React.createElement("div", { className: style.header },
        React.createElement("div", { className: style.pages }, `${step}/${2}`)));
    const renderStep = () => {
        switch (step) {
            case 1:
                return (React.createElement(NewPhone, { renderPaggination: renderStepHeader(), backAction: () => setPage(1), setStep: setStep }));
            case 2:
                return (React.createElement(VerifyPhone, { setPage: setPage, renderPaggination: renderStepHeader(), backAction: () => setStep(1) }));
            default:
                return null;
        }
    };
    return React.createElement("div", null, renderStep());
};
export default ChangePhone;
