import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { getAnalytics, logEvent } from "@firebase/analytics";
import OK from '../../resources/icons/circle-ok.svg';
import { useDeviceHeight } from "../../hooks";
import s from "./style.scss";
import AddEditReminder from "./components/AddEditReminder";
import { useEffect } from "react";
import { connect } from "react-redux";
import { getRemindersAsyncAction, deleteReminderAsyncAction } from "../../store/medicalReminders/Sagas";
import { allMedicalRemindersSelector } from "../../store/medicalReminders";
import ReminderCard from "./components/ReminderCard";
import { PopUp } from "../../components/Shared/Info";
import Button from "../../components/Shared/Controls/Button";
import { Box } from "../../components";
const MedicationReminders = ({ patientReminders, deleteReminder, getReminders }) => {
    const { t } = useTranslation();
    useEffect(() => {
        logEvent(getAnalytics(), "screen_view", {
            firebase_screen: "MedicationReminders",
            firebase_screen_class: "MedicationReminders"
        });
    }, []);
    useDeviceHeight("remindersWrapper", 120, 185, 170);
    const [openAddReminderPopup, setOpenAddReminderPopup] = useState(false);
    const [editingReminder, setEditingReminder] = useState(null);
    const toggleAddReminderPopup = () => setOpenAddReminderPopup(state => !state);
    useEffect(() => {
        getReminders();
    }, []);
    const renderReminders = (reminders) => {
        return reminders.map((reminder) => {
            return (React.createElement(ReminderCard, { key: reminder.id, reminder: reminder, setEditingReminder: setEditingReminder, showDeletePopUp: showDeletePopUp }));
        });
    };
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
    const [reminderDeleteId, setReminderDeleteId] = useState("");
    const toggleDeletePopup = () => setOpenDeletePopup((open) => !open);
    const showDeletePopUp = (id) => {
        setReminderDeleteId(id);
        setOpenDeletePopup(true);
    };
    const renderDeletePopUp = (React.createElement(PopUp, { isOpen: openDeletePopup },
        React.createElement("div", { className: s.popup },
            React.createElement("div", { className: s.popup__content },
                React.createElement("div", { className: s.subheading_1 }, t("deleteReminder"))),
            React.createElement("div", { className: s.popup__controls },
                React.createElement(Button, { text: t("cancel"), onClick: () => {
                        setReminderDeleteId("");
                        toggleDeletePopup();
                    } }),
                React.createElement(Button, { primary: true, text: t("deleteReminderButton"), onClick: () => {
                        deleteReminder(reminderDeleteId);
                        setReminderDeleteId("");
                        toggleDeletePopup();
                    } })))));
    const renderSuccessfulModal = (React.createElement(PopUp, { style: s.successPopup, contentStyle: s.successContent, isOpen: openSuccessPopup },
        React.createElement("div", { className: s.successPopup__header },
            React.createElement("div", null,
                React.createElement(SVG, { src: OK, className: s.successPopup__header_icon }))),
        React.createElement("div", { className: s.successPopup__content },
            React.createElement("div", null,
                React.createElement("div", { className: s.subheading_1 }, t("reminderBeenAdded")))),
        React.createElement("div", { className: s.successPopup__controls },
            React.createElement(Button, { style: s.button, text: t("Done"), onClick: () => setOpenSuccessPopup(false) }))));
    return (React.createElement(Box, { fullWidth: true },
        React.createElement("div", { id: "remindersWrapper", className: classNames(s.pageBody, s.profileWrapper, s.scrollWrapper) },
            React.createElement("h3", null, t("medicationReminders")),
            React.createElement("div", null,
                React.createElement("div", { className: s.remindersPageWrapper },
                    React.createElement("div", { className: s.remindersWrapper }, patientReminders && renderReminders(patientReminders)),
                    React.createElement(Box, { f1: true, justifyCenter: true, alignCenter: true },
                        React.createElement(Button, { style: s.button, primary: true, iosTextOverflow: true, text: t("addReminder"), onClick: toggleAddReminderPopup })),
                    React.createElement(AddEditReminder, { editingReminder: editingReminder, setEditingReminder: setEditingReminder, isOpen: openAddReminderPopup, togglePopup: toggleAddReminderPopup, onSuccess: () => setOpenSuccessPopup(true) })),
                renderDeletePopUp,
                renderSuccessfulModal))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        patientReminders: allMedicalRemindersSelector(state)
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getReminders: () => dispatch(getRemindersAsyncAction()),
        deleteReminder: (reminderId) => dispatch(deleteReminderAsyncAction({ reminderId })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MedicationReminders);
