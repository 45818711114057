import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import HelpInfo from "../../components/Shared/PopUps/HelpInfo";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import s from "./style.scss";
import StatusDisplay from "../../components/StatusDisplay";
import Banner from "../components/Banner";
import Header from "../components/Header";
import Typography from "../../components/Shared/Typography";
const ResetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedLang, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
    const [step, setStep] = useState(1);
    const renderStepHeader = (backAction) => (React.createElement("div", { className: s.header },
        React.createElement("div", { className: s.pages },
            React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `${step}/3`))));
    const renderResetPasswordSteps = () => {
        switch (step) {
            case 1:
                return (React.createElement(Step1, { setStep: setStep, header: renderStepHeader(() => navigate("/")), previousStep: () => navigate("/") }));
            case 2:
                return (React.createElement(Step2, { setStep: setStep, header: renderStepHeader(() => setStep(1)), previousStep: () => setStep(1) }));
            case 3:
                return (React.createElement(Step3, { header: renderStepHeader(() => setStep(2)), previousStep: () => setStep(1) }));
        }
    };
    const [infoPopUp, showInfoPopUp] = useState(false);
    return (React.createElement("div", null,
        React.createElement(StatusDisplay, null),
        React.createElement(HelpInfo, { isOpen: infoPopUp, setIsOpen: showInfoPopUp }),
        React.createElement(Banner, null),
        React.createElement("div", { className: s.resetPassword },
            React.createElement(Header, null),
            renderResetPasswordSteps())));
};
export default ResetPassword;
