import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import classNames from "classnames";
import { allProvincesSelector, setAddionInfoAction, setProvinceAction } from "../../../../store";
import { getProvincesAsyncAction } from "../../../../store/province/Sagas";
import { Button } from "../../../../components/Shared/Controls";
import Picker from "../../../../components/Shared/Controls/Picker";
import ProvinceDropdown from "../../../../components/Shared/Controls/Inputs/ProvinceDropdown";
import useInput from "../../../../hooks/useInput";
import { allConditionsSelector, getMedicalConditionsAsyncAction } from "../../../../store/medicalConditions";
import { getCheckedConditionsView } from "../../../HealthInformationPage/components/HealthInformation/HealthInformation";
import CheckboxDropdown from "../../../../components/Shared/Controls/CheckboxDropdown";
import sw from '../../../../Pages/SignUp/Steps/InvitationEmail/style.scss';
import Typography from "../../../../components/Shared/Typography";
import { Box } from "../../../../components";
const AlmostDonePage = ({ brandName, userProvince, userGender, userDoctorsName, userMedicalCondition, getProvinces, getMedicalConditionals, setAdditionalInfo, onSumbit, setProvince, prevStep }) => {
    const { t, i18n } = useTranslation();
    const conditions = useSelector(allConditionsSelector);
    const provinces = useSelector(allProvincesSelector);
    const [gender, setGender] = useInput(userGender);
    const [province, setProvinceLocal] = useInput(userProvince);
    const [medicalConditions, setMedicalConditions] = useInput(conditions?.map((condition) => {
        return {
            key: condition.medicalConditionId,
            displayName: condition.medicalConditionDescription,
            isChecked: userMedicalCondition && userMedicalCondition?.find((el) => el.medicalConditionId === condition?.medicalConditionId)
                && true
                || false
        };
    }));
    useEffect(() => {
        getProvinces();
        getMedicalConditionals(brandName);
    }, []);
    const GENDERS = [
        { value: "Male", title: t("male") },
        { value: "Female", title: t("female") },
        { value: "Other", title: t("other") }
    ];
    useEffect(() => {
        conditions && setMedicalConditions(conditions?.map((condition) => {
            return {
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: conditions.length === 1 ? true : userMedicalCondition && userMedicalCondition.find((el) => el.medicalConditionId === condition.medicalConditionId)
                    && true
                    || false
            };
        }));
    }, [conditions]);
    const checkedConditions = getCheckedConditionsView(medicalConditions);
    const allFieldsValid = () => gender && province && checkedConditions && checkedConditions.length > 0;
    const onChangeGender = (value) => {
        setGender(value);
    };
    const submit = () => {
        setAdditionalInfo({
            gender: gender,
            medicalConditions: medicalConditions?.filter((el) => el.isChecked).map((condition) => {
                return {
                    medicalConditionId: condition.key,
                    medicalConditionDescription: condition.displayName
                };
            }),
        });
        setProvince(province);
        onSumbit && onSumbit();
    };
    return React.createElement("div", { className: classNames(sw.contentWrapper) },
        React.createElement("div", { className: classNames(sw.scrollWrapperfrom) },
            React.createElement("div", { className: classNames(sw.formstep, sw.formWrapper) },
                React.createElement("div", { className: classNames(sw.formstepbar) },
                    React.createElement(Box, { f1: true, column: true, mb: 36, gap: 16 },
                        React.createElement(Typography, { type: 'header_4', color: 'neutralDark', bold: true }, t("almostDone")),
                        React.createElement(Typography, { type: 'paragraph', color: 'neutralDark' }, t("weNeedMoreInfoAboutMember"))),
                    React.createElement("div", { className: classNames(sw.formGroup) },
                        React.createElement("label", null, t("selectYourProvince")),
                        React.createElement(ProvinceDropdown, { data: provinces, value: province, setValue: setProvinceLocal })),
                    React.createElement("div", { className: classNames(sw.formGroup) },
                        React.createElement("label", null, t("gender")),
                        React.createElement(Picker, { onChange: onChangeGender, value: gender, data: GENDERS, columns: 3 })),
                    React.createElement(CheckboxDropdown, { items: medicalConditions, setItems: setMedicalConditions, label: t("selectMedicalConditions") }),
                    React.createElement("div", { className: sw.buttonTwopage },
                        React.createElement(Button, { text: t("back"), onClick: prevStep }),
                        React.createElement(Button, { disabled: !allFieldsValid(), primary: true, text: t("saveAndContinue"), onClick: submit }))))));
};
const mapStateToProps = (state) => {
    return {
        userProvince: state.auth.provinceId,
        userGender: state.auth.additionalInfo.gender,
        userDoctorsName: state.auth.additionalInfo.doctorsName,
        userMedicalCondition: state.auth.additionalInfo.medicalConditions,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getProvinces: () => {
            dispatch(getProvincesAsyncAction());
        },
        getMedicalConditionals: (drugBrandCode) => {
            dispatch(getMedicalConditionsAsyncAction(drugBrandCode));
        },
        setProvince: (province) => dispatch(setProvinceAction(province)),
        setAdditionalInfo: (info) => dispatch(setAddionInfoAction(info)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AlmostDonePage);
