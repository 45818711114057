import React, { useEffect, useRef, useState } from "react";
import classNames from 'classnames';
import Chevron from '../../../../resources/icons/chevron_down.svg';
import SVG from 'react-inlinesvg';
import s from './style.scss';
import InfoPopUp from "../../Info/InfoPopUp";
import { useOnClickOutside } from "../../../../hooks";
import Info from "../../../../resources/icons/info";
import colors from "../../../../resources/colors";
import Typography from "../../Typography";
import Checkbox from "../Checkbox/Checkbox";
import { t } from "i18next";
const CheckboxDropdown = ({ style, styleList, items, setItems, placeholder, label, description, defaultSelectedItems, required, isSubmited }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const [selectedCount, setSelectedCount] = useState(0); // Initialize selectedCount
    const toggling = () => setIsOpen(!isOpen);
    // const active = items.find(el => el.isChecked);
    const active = Array.isArray(items) && items.find(el => el.isChecked);
    const listRef = useRef(null);
    useOnClickOutside(listRef, () => {
        setIsOpen(false);
    });
    const getPlaceholder = () => {
        if (placeholder === undefined)
            return null;
        if (typeof placeholder === "string")
            return placeholder;
        return active ? placeholder.active : placeholder.default;
    };
    useEffect(() => {
        if (defaultSelectedItems && defaultSelectedItems.length > 0) {
            setItems([...defaultSelectedItems]);
        }
    }, []);
    useEffect(() => {
        if (!!items) {
            setSelectedCount(items.filter(item => item.isChecked).length);
        }
    }, [items]);
    return (React.createElement("div", null,
        label && React.createElement(Typography, { type: "label", color: "neutralGray", bold: true },
            label,
            " ",
            required && React.createElement("span", { style: { color: "red", marginLeft: "2px" } }, "*")),
        React.createElement("div", { className: s.inputGroup, ref: listRef, onClick: toggling },
            React.createElement("div", { className: classNames(s.input, style), style: { backgroundColor: colors.primaryshades3 } },
                React.createElement("div", { "data-testid": "dropdownValue", className: s.value }, active && items.filter(el => el.isChecked).map(el => el.displayName).join(", "))),
            isOpen && (React.createElement("div", { className: classNames(s.dropdown_list, styleList) }, (items || []).map(({ key, displayName, isChecked }) => (React.createElement(Checkbox, { key: key, value: isChecked, onChange: () => {
                    setItems([
                        ...items.map(el => {
                            if (el.key === key) {
                                el.isChecked = !el.isChecked;
                                return el;
                            }
                            return el;
                        })
                    ]);
                }, label: displayName }))))),
            React.createElement("div", { className: classNames(s.floatingLabel, { [s.valid]: active }) }, getPlaceholder()),
            description && React.createElement(Info, { "data-testid": "info-test", color: colors.icons.primary, style: classNames(s.link, s.info), onClick: (e) => {
                    e.stopPropagation();
                    setDisplayInfo(true);
                } }),
            required && selectedCount === 0 && isSubmited == true && React.createElement("div", { className: s.errorMsg, style: { color: "red" } }, t("pleaseSelect")),
            React.createElement(SVG, { src: Chevron, className: classNames(s.chevron, { [s.chevron_active]: isOpen }) }),
            React.createElement(InfoPopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false) },
                React.createElement("div", null, description)))));
};
export default CheckboxDropdown;
