import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageBody from "../../../../components/Shared/PageBody";
import { useInput, usePrevious } from "../../../../hooks";
import { Button, InputPassword, InputText, } from "../../../../components/Shared/Controls";
import { resetRequestUpdateEmailAction, updateEmailAsyncAction, } from "../../../../store";
import { verifyEmailAsyncAction } from "../../../../store/auth/actions";
import SVG from 'react-inlinesvg';
import s from "./style.scss";
import { PopUp } from "../../../../components/Shared/Info";
import OK from '../../../../resources/icons/circle-ok.svg';
const NewEmail = ({ setStep, updateEmail, userEmail, userPassword, requestUpdateEmailStatus, requestValidateEmailStatus, resetRequestUpdateEmailAction, verifyEmail, backAction, }) => {
    const { t } = useTranslation();
    const prevValidate = usePrevious(requestValidateEmailStatus);
    const prevUpdate = usePrevious(requestUpdateEmailStatus);
    const [confirmPassword, setConfirmPassword, errorConfirmPassword, setErrorConfirmPassword,] = useInput();
    const [email, setEmail, errorEmail, setErrorEmail] = useInput(userEmail);
    const [showPopUp, setPopUp] = useState(false);
    const renderSuccessfulModal = (React.createElement(PopUp, { style: s.popup, contentStyle: s.successContent, toggle: () => {
            setPopUp(false);
            setStep(1);
        }, allowClose: true, isOpen: showPopUp },
        React.createElement("div", { className: s.popup__header },
            React.createElement("div", null,
                React.createElement(SVG, { src: OK, className: s.popup__header_icon }))),
        React.createElement("div", { className: s.popup__content },
            React.createElement("div", null,
                React.createElement("div", null, t("emailUpdate")))),
        React.createElement("div", { className: s.popup__controls },
            React.createElement(Button, { text: t("cancel"), onClick: () => (window.location.href = "/personal-data") }),
            React.createElement(Button, { primary: true, text: t("backToHome"), onClick: () => (window.location.href = "/") }))));
    const onButtonSubmit = () => {
        verifyEmail(email);
    };
    useEffect(() => {
        if (prevUpdate !== "success" && requestUpdateEmailStatus === "success") {
            setPopUp(true);
            resetRequestUpdateEmailAction();
        }
    }, [requestUpdateEmailStatus]);
    useEffect(() => {
        if (prevValidate !== "success" && prevValidate !== undefined && requestValidateEmailStatus === "success") {
            updateEmail(email);
        }
        if (requestValidateEmailStatus === "failure")
            setEmail(userEmail);
    }, [requestValidateEmailStatus]);
    useEffect(() => {
        setEmail(userEmail);
    }, [userEmail]);
    return (React.createElement(PageBody, { backAction: backAction, title: t("changeEmail") },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: s.scrollWrapper },
                renderSuccessfulModal,
                React.createElement("p", { className: s.confirmMessage }, t("enterPasswordAndNewEmail").split('\n').map(el => React.createElement("div", null, el))),
                React.createElement("div", { className: s.pageWrapper },
                    React.createElement("div", null,
                        React.createElement(InputPassword, { name: "confirmPassword", placeholder: t("yourPassword"), pattern: {
                                rule: "[A-Za-z0-9!@#$%^&*()_+=-]{8,}",
                                listOnConditions: [
                                    "(?=.*[a-z])[a-z]",
                                    "(?=.*[A-Z])[A-Z]",
                                    "(?=.*[0-9])[0-9]",
                                    "(?=.*[!@#$%^&*()_+=-])[!@#$%^&*()_+=-]",
                                ],
                                countOfConditions: 3,
                            }, setError: setErrorConfirmPassword, password: confirmPassword, setPassword: setConfirmPassword, error: confirmPassword && errorConfirmPassword, errorMessage: t("incorectPassword") })),
                    React.createElement("div", null,
                        React.createElement(InputText, { name: "email", placeholder: t("newEmail"), value: email, setValue: setEmail, pattern: "^([\\w\\.\\+]{1,})([^\\W])(@)([\\w]{1,})(\\.[\\w]{1,})+$", errorMessage: t("errorIncorrectEmail"), error: errorEmail, setError: setErrorEmail })))),
            React.createElement("div", { className: s.controls },
                React.createElement(Button, { text: t("cancel"), onClick: () => setStep(1) }),
                React.createElement(Button, { primary: true, text: t("save"), disabled: !email ||
                        !confirmPassword ||
                        errorEmail ||
                        errorConfirmPassword ||
                        userPassword !== confirmPassword ||
                        email === userEmail, onClick: onButtonSubmit })))));
};
const mapStateToProps = (state) => {
    return {
        userEmail: state.account.user.email,
        userPassword: state.account.user.password,
        requestUpdateEmailStatus: state.account.requestUpdateEmailStatus,
        requestValidateEmailStatus: state.auth.requestValidateEmailStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        updateEmail: (email) => dispatch(updateEmailAsyncAction(email)),
        verifyEmail: (email) => dispatch(verifyEmailAsyncAction(email)),
        resetRequestUpdateEmailAction: () => dispatch(resetRequestUpdateEmailAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewEmail);
