export const mapBrandSettingsResponse = (response) => {
    return response.data.value;
};
export const mapBrands = (response) => {
    let data = [];
    for (let i = 0; i < response.value.length; i++) {
        data.push({
            ...response.value[i],
            base64Logo: response.value[i].logoLink
        });
    }
    return data;
};
