import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
export const SLICE_NAME = "MEDICATION_REMINDER";
export const reminderAdapter = createEntityAdapter({
    selectId: (reminder) => reminder.id
});
export const reminderSelectors = reminderAdapter.getSelectors();
const initialState = {
    requestGetRemindersError: null,
    requestGetRemindersStatus: "idle",
    requestAddReminderError: null,
    requestAddReminderStatus: "idle",
    requestEditReminderError: null,
    requestEditReminderStatus: "idle",
    requestDeleteReminderError: null,
    requestDeleteReminderStatus: "idle",
};
const reminderSlice = createSlice({
    name: SLICE_NAME,
    initialState: reminderAdapter.getInitialState(initialState),
    reducers: {
        setRemindersAction: (state, action) => {
            reminderAdapter.setAll(state, action.payload);
            state.requestGetRemindersStatus = "success";
            state.requestGetRemindersError = null;
        },
        startRequestGetRemindersAction: (state) => {
            state.requestGetRemindersStatus = "pending";
            state.requestGetRemindersError = null;
        },
        showRequestGetRemindersErrorAction: (state, action) => {
            state.requestGetRemindersStatus = "failure";
            state.requestGetRemindersError = action.payload;
        },
        resetRequestGetRemindersStatusAction: (state) => {
            state.requestGetRemindersStatus = "idle";
            state.requestGetRemindersError = null;
        },
        addReminderAction: (state, action) => {
            reminderAdapter.addOne(state, action.payload);
            state.requestAddReminderStatus = "success";
            state.requestAddReminderError = null;
        },
        startRequestAddReminderAction: (state) => {
            state.requestAddReminderStatus = "pending";
            state.requestAddReminderError = null;
        },
        showRequestAddReminderErrorAction: (state, action) => {
            state.requestAddReminderStatus = "failure";
            state.requestAddReminderError = action.payload;
        },
        resetRequestAddReminderStatusAction: (state) => {
            state.requestAddReminderStatus = "idle";
            state.requestAddReminderError = null;
        },
        editReminderAction: (state, action) => {
            reminderAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { ...action.payload }
            });
            state.requestEditReminderStatus = "success";
            state.requestEditReminderError = null;
        },
        startRequestEditReminderAction: (state) => {
            state.requestEditReminderStatus = "pending";
            state.requestEditReminderError = null;
        },
        showRequestEditReminderErrorAction: (state, action) => {
            state.requestEditReminderStatus = "failure";
            state.requestEditReminderError = action.payload;
        },
        resetRequestEditReminderStatusAction: (state) => {
            state.requestEditReminderStatus = "idle";
            state.requestEditReminderError = null;
        },
        deleteReminderAction: (state, action) => {
            reminderAdapter.removeOne(state, action.payload);
            state.requestDeleteReminderStatus = "success";
            state.requestDeleteReminderError = null;
        },
        startRequestDeleteReminderAction: (state) => {
            state.requestDeleteReminderStatus = "pending";
            state.requestDeleteReminderError = null;
        },
        showRequestDeleteReminderErrorAction: (state, action) => {
            state.requestDeleteReminderStatus = "failure";
            state.requestDeleteReminderError = action.payload;
        },
        resetRequestDeleteReminderStatusAction: (state) => {
            state.requestDeleteReminderStatus = "idle";
            state.requestDeleteReminderError = null;
        },
        resetReminderSliceAction: (state) => {
            state = reminderAdapter.getInitialState(initialState);
        }
    }
});
export default reminderSlice;
