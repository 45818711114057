import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import s from "./style.scss";
import style from "../../style.scss";
import Arrow from "../../../../../../../resources/icons/chevron_down.svg";
import { getCurrentUserSelector, getRegistrationInfoAsyncAction, getPatientBrandsAsyncAction, getBrandByIdSelector, } from "../../../../../../../store";
import { connect } from "react-redux";
import useOnClickOutside from "../../../../../../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const BrandSelect = ({ userBrands, brand, currentUser, getRegistrationInfo, getPatientBrands, requestBrandsStatus, patientId, isAdmin, }) => {
    const brandRef = useRef(null);
    const [openBrand, setOpenBrand] = useState(false);
    const [currentBrand, setCurrentBrand] = useState(brand);
    const [currentLogoLink, setCurrentLogoLink] = useState(brand?.logoLink);
    const { t } = useTranslation();
    useOnClickOutside(brandRef, () => setOpenBrand(false));
    useEffect(() => {
        getPatientBrands();
    }, [patientId]);
    useEffect(() => {
        let logo = currentBrand?.logoLink;
        if (currentBrand && currentBrand.logoLink && i18next.language == "fr") {
            logo = currentBrand?.logoLink.replace("EN.", "FR.");
        }
        setCurrentLogoLink(logo);
    }, [currentBrand, i18next.language]);
    useEffect(() => {
        currentBrand !== brand && setCurrentBrand(brand);
    }, [brand]);
    useEffect(() => {
        requestBrandsStatus === "success" && setCurrentBrand(brand);
    }, [requestBrandsStatus, patientId]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-testid": "brandWrapper", className: classNames(s.headerBrand, {
                [style.linkDiabled]: openBrand,
            }), onClick: () => setOpenBrand(true) },
            React.createElement("img", { src: currentLogoLink, alt: "Brand logo" }),
            isAdmin == false ? React.createElement(SVG, { src: Arrow, className: classNames(s.brandArrow, {
                    [s.brandArrowActive]: openBrand,
                }) }) : null),
        openBrand && isAdmin == false && (React.createElement("div", { "data-testid": "openBrand", ref: brandRef, className: classNames(s.brandMenu, style.openHeaderMenuWrapper) },
            React.createElement("span", null, t("switchBrand")),
            React.createElement("ul", { "data-testid": "brandsList" }, userBrands.map((item) => {
                return (React.createElement("li", { key: item.drugBrandCode, onClick: () => {
                        currentUser?.id &&
                            getRegistrationInfo({
                                member: {
                                    patientId: currentUser.id,
                                    drugBrandCode: item.drugBrandCode,
                                },
                                isRegistrationFlow: false,
                            });
                        setCurrentBrand(item);
                        setOpenBrand(false);
                    } }, t(item.drugBrandCode)));
            }))))));
};
const mapStateToProps = (state) => {
    return {
        brand: getBrandByIdSelector(state),
        userBrands: state.account.patientBrands,
        currentUser: getCurrentUserSelector(state),
        requestBrandsStatus: state.brands.requestGetBrandsStatus,
        patientId: state.account.user.patientId,
        isAdmin: state.account.isAdmin
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPatientBrands: () => dispatch(getPatientBrandsAsyncAction()),
        getRegistrationInfo: (payload) => dispatch(getRegistrationInfoAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandSelect);
