import React, { useState } from "react";
import classNames from 'classnames';
import InfoPopUp from "../../Info/InfoPopUp";
import Info from "../../../../resources/icons/info";
import colors from "../../../../resources/colors";
import s from './style.scss';
const CheckboxList = ({ style, items, setItems, placeholder, description, informationPopUp, onCheckboxChange }) => {
    const [infoIsOpen, setDisplayInfo] = useState(false);
    return (React.createElement("div", { className: s.checkbox_list },
        React.createElement("div", { className: s.placeholder },
            React.createElement("div", { className: s.subheading_2 }, placeholder),
            informationPopUp && description && React.createElement(Info, { "data-testid": "info-test", color: colors.icons.primary, style: classNames(s.link, s.info), onClick: (e) => {
                    e.stopPropagation();
                    setDisplayInfo(true);
                } })),
        !informationPopUp && React.createElement("div", { className: s.paragraph_2 }, description),
        React.createElement("div", { className: classNames(s.dropdown_list) }, items.map(({ key, displayName, isChecked }, index) => (React.createElement("div", { key: key, className: s.dropdown_list_item, onClick: event => {
                event.stopPropagation();
                setItems([
                    ...items.map(el => {
                        if (el.key === key) {
                            el.isChecked = !el.isChecked ?? false;
                            return el;
                        }
                        return el;
                    })
                ]);
            } },
            React.createElement("span", { className: classNames(s.checkmark, { [s.checked]: isChecked }), style: {
                    borderColor: isChecked && colors.controlsGeneralColor,
                    backgroundColor: isChecked && colors.controlsGeneralColor,
                } }),
            React.createElement("div", null, displayName))))),
        React.createElement(InfoPopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false) },
            React.createElement("div", null, description))));
};
export default CheckboxList;
