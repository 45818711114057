import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { brandsBySearchKeySelector, setBrandAction } from "../../../../store";
import SearchInput from "../../../../components/Shared/Controls/Inputs/SearchInput";
import BrandCard from "../../../../components/Shared/BrandCard";
import { Button } from "../../../../components/Shared/Controls";
import LoaderIcon from '../../../../resources/icons/icon-loader.svg';
import SVG from 'react-inlinesvg';
import useBrandSearch from "../../../../hooks/useBrandSearch";
import s from './style.scss';
import ss from '../WelcomePage/style.scss';
import sw from '../../../../Pages/SignUp/Steps/InvitationEmail/style.scss';
import Typography from "../../../../components/Shared/Typography";
const SelectBrandPage = ({ userBrand, isTestBrands, setBrand, brands, step, countOfSteps, previusStep, onSumbit }) => {
    const { t } = useTranslation();
    const [selectedBrand, selectBrand] = useState(userBrand || '');
    const search = useBrandSearch(isTestBrands);
    const onSelect = () => {
        selectedBrand && setBrand(selectedBrand);
        onSumbit && onSumbit();
    };
    const searchBrandRender = () => {
        return React.createElement("div", { className: classNames(s.searchForm) },
            React.createElement(Typography, { type: 'header_1', color: 'neutralDark', bold: true }, t("selectBrand")),
            React.createElement(SearchInput, { style: s.search, value: search.searchTerm, setValue: search.setSearchTerm }));
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(sw.contentWrapper, s.pb5) },
            React.createElement("div", { className: s.pages },
                React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `${step}/${countOfSteps}`)),
            searchBrandRender(),
            React.createElement("div", null,
                React.createElement("div", { className: s.brandList }, brands && brands[0] ? brands.map((el) => (React.createElement(BrandCard, { key: el.drugBrandCode, active: selectedBrand === el.drugBrandCode, url: el.logoLink, onClick: () => selectedBrand != el.drugBrandCode ? selectBrand(el.drugBrandCode) : selectBrand('') }))) : React.createElement("div", { className: s.loading }, search.searchTerm ? React.createElement("div", { className: s.subheading_1 }, t("noResultsFound")) : React.createElement(SVG, { src: LoaderIcon, className: s.loaderIcon }))),
                React.createElement("div", { className: ss.buttonTwopage },
                    React.createElement(Button, { text: t("back"), onClick: previusStep }),
                    React.createElement(Button, { style: s.mb0_button, disabled: !selectedBrand, primary: true, text: t("select"), onClick: onSelect })))));
};
const mapStateToProps = (state) => {
    return {
        userBrand: state.auth.brandId,
        brands: brandsBySearchKeySelector(state, state.brands.searchValue),
        isTestBrands: state.brands.isTestBrands
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setBrand: (brandId) => dispatch(setBrandAction(brandId)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectBrandPage);
