import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { usePrevious, useErrorPopUp } from "../../../../hooks";
import { Button, InputPassword } from "../../../../components/Shared/Controls";
import { Notification, PopUp } from "../../../../components/Shared/Info";
import { signInAsyncAction, signUpAsyncAction } from "../../../../store/auth/actions";
import useInput from "../../../../hooks/useInput";
import OK from '../../../../resources/icons/circle-ok.svg';
import Close from '../../../../resources/icons/close.svg';
import SVG from 'react-inlinesvg';
import s from './style.scss';
import si from "../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import ReactHtmlParser from "react-html-parser";
import { Checkbox } from "../../../../components";
import Typography from "../../../../components/Shared/Typography";
const CreatePasswordPage = ({ signIn, signUp, phoneNumber, requestStatus, requestSignInStatus, requestSignUpError, callAdditionalSteps, onSumbit, prevStep }) => {
    const { t } = useTranslation();
    const [password, setPassword, errorPassword, setErrorPassword] = useInput();
    const [confirmPassword, setConfirmPassword, errorConfirmPassword, setErrorConfirmPassword] = useInput();
    const [agree, setAgree] = useInput(false);
    const [consentAgree, setConsentAgree] = useInput(false);
    const [showPopUp, setPopUp] = useState(false);
    const [termsOfUseOpen, setTermsOfUseOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);
    const [consentOpen, setConsentOpen] = useState(false);
    const ref = usePrevious(requestStatus);
    const [errorPopUpIsOpen, , errorContent, setErrorContent, toggleErrorPopUp] = useErrorPopUp();
    const allFieldsValid = () => password === confirmPassword && password && confirmPassword && agree && consentAgree
        && !errorPassword && !errorConfirmPassword;
    const onCreatingAcoount = () => {
        signUp(password);
    };
    useEffect(() => {
        requestSignInStatus === "success" && setPopUp(true);
    }, [requestSignInStatus]);
    useEffect(() => {
        if (ref !== undefined) {
            if (requestStatus === "success") {
                signIn(phoneNumber, password, '');
            }
            if (requestStatus === "failure") {
                setErrorContent(requestSignUpError?.message);
                toggleErrorPopUp();
            }
        }
    }, [requestStatus]);
    const renderTermsOfUseModal = (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: termsOfUseOpen },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2 }, t("termOfUse"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", null, ReactHtmlParser(t('termOfUserIntent')))),
        React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: () => setTermsOfUseOpen(false) })));
    const renderPrivacyModal = (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: privacyOpen },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2 }, t("privacyPolicy"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", null, ReactHtmlParser(t('privacyPolicyContent')))),
        React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: () => setPrivacyOpen(false) })));
    const renderConsentModal = (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: consentOpen },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2, style: { textAlign: "center" } }, t("userConsent"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", { className: s.intend }, ReactHtmlParser(t('userConsentIntent'))),
            React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: () => setConsentOpen(false) }),
            React.createElement("div", { className: s.buttonBox },
                React.createElement(Button, { style: s.button, text: t("decline"), onClick: () => {
                        setConsentOpen(false);
                        setConsentAgree(false);
                    } }),
                React.createElement(Button, { style: s.button, primary: true, text: t("accept"), onClick: () => {
                        setConsentOpen(false);
                        setConsentAgree(true);
                    } })))));
    const renderSuccessfulModal = (React.createElement(PopUp, { style: s.popup, contentStyle: s.successContent, isOpen: showPopUp },
        React.createElement("div", { className: s.popup__header },
            React.createElement("div", null,
                React.createElement(SVG, { src: OK, className: s.popup__header_icon }))),
        React.createElement("div", { className: s.popup__content },
            React.createElement("div", { className: s.subheading_1 }, t("yourAccountSuccessfullyCreated"))),
        React.createElement("div", { className: s.popup__controls },
            React.createElement(Button, { style: s.button, primary: true, text: t("continueSetUp"), onClick: callAdditionalSteps }))));
    return React.createElement("div", { className: classNames(si.contentWrapper) },
        React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
            React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                React.createElement("div", { className: classNames(si.formstepbar) },
                    renderSuccessfulModal,
                    renderTermsOfUseModal,
                    renderPrivacyModal,
                    renderConsentModal,
                    React.createElement("div", { className: s.aboutStep },
                        React.createElement("div", { className: classNames(s.subheading_1, s.stepTitle) }, t("createYourPassword"))),
                    React.createElement("div", { className: classNames(s.textWrapper, s.paragraph_2) }, t("passwordShouldBe")),
                    React.createElement(InputPassword, { style: s.input, name: 'password', label: t("createPassword"), pattern: {
                            rule: '[A-Za-z0-9!@#$%^&*()_+=\-]{8,}',
                            listOnConditions: [
                                '(?=.*[a-z])[a-z]',
                                '(?=.*[A-Z])[A-Z]',
                                '(?=.*[0-9])[0-9]',
                                '(?=.*[!@#$%^&*()_+=\-])[!@#$%^&*()_+=\-]'
                            ],
                            countOfConditions: 3
                        }, error: password && errorPassword, setError: setErrorPassword, password: password, setPassword: setPassword, errorMessage: t("PasswordFormatError") }),
                    React.createElement(InputPassword, { style: s.input, name: 'confirmPassword', label: t("confirmPassword"), pattern: {
                            rule: '[A-Za-z0-9!@#$%^&*()_+=\-]{8,}',
                            listOnConditions: [
                                '(?=.*[a-z])[a-z]',
                                '(?=.*[A-Z])[A-Z]',
                                '(?=.*[0-9])[0-9]',
                                '(?=.*[!@#$%^&*()_+=\-])[!@#$%^&*()_+=\-]'
                            ],
                            countOfConditions: 3
                        }, error: confirmPassword && (errorConfirmPassword || password !== confirmPassword), setError: setErrorConfirmPassword, errorMessage: t("confirmPasswordError"), password: confirmPassword, setPassword: setConfirmPassword }),
                    React.createElement(Notification, { type: "info" }, t("requirementsToPassword")),
                    React.createElement("div", { className: classNames(s.checkboxlist), onClick: () => setConsentOpen(true) },
                        React.createElement(Checkbox, { value: consentAgree, onChange: () => { } }),
                        React.createElement(Typography, { type: 'paragraph' },
                            t("agreeToConsent"),
                            "\u00A0",
                            React.createElement("a", { href: "#" },
                                React.createElement(Typography, { type: 'paragraph', color: 'primary' }, t("consent"))))),
                    React.createElement("div", { className: classNames(s.checkboxlist) },
                        React.createElement(Checkbox, { value: agree, onChange: () => { setAgree(!agree); } }),
                        React.createElement(Typography, { type: 'paragraph' },
                            t("agreeToTermOfUse"),
                            "\u00A0",
                            React.createElement("a", { href: "#", onClick: () => setTermsOfUseOpen(true) },
                                React.createElement(Typography, { type: 'paragraph', color: 'primary' }, t("termOfUse"))),
                            "\u00A0",
                            t("andHaveReadThe"),
                            React.createElement("a", { href: "#", className: s.link, onClick: () => setPrivacyOpen(true) },
                                React.createElement(Typography, { type: 'paragraph', color: 'primary' }, t("privacyPolicy"))))),
                    React.createElement("div", { className: si.buttonTwopage },
                        React.createElement(Button, { text: t("back"), onClick: prevStep }),
                        React.createElement(Button, { disabled: !allFieldsValid(), primary: true, text: t("completeRegistration"), onClick: onCreatingAcoount }))))));
};
const mapStateToProps = (state) => {
    return {
        phoneNumber: state.auth.phoneNumber,
        requestSignInStatus: state.auth.requestSignInStatus,
        requestStatus: state.auth.requestSignUpStatus,
        requestSignUpError: state.auth.requestSignUpError
    };
};
function mapDispatchToProps(dispatch) {
    return {
        signIn: (phoneNumber, password, twoFactorCode) => dispatch(signInAsyncAction({ identifier: phoneNumber, password, signInType: "PhoneNumber", twoFactorCode })),
        signUp: (pass) => dispatch(signUpAsyncAction(pass)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePasswordPage);
