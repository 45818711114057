import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18next from "i18next";
import Picker from "../../../../components/Shared/Controls/Picker";
import PageBody from "../../../../components/Shared/PageBody";
import { useInput } from "../../../../hooks";
import s from "./style.scss";
import style from "../../style.scss";
import Edit from "../../../../resources/icons/edit";
import colors from "../../../../resources/colors";
import { changeLanguageAction } from "../../../../store/language";
import ApiManager from "../../../../store/api/ApiManager";
const GeneralSettings = ({ setPage, changeLanguage, requestLanguageStatus, impersonatedAccountId }) => {
    const { t } = useTranslation();
    const LANGUAGES = [
        { value: "en", title: t("english") },
        { value: "fr", title: t("french") },
    ];
    const [language, setLanguage] = useInput(i18next.language);
    const onLanguageChange = (value) => {
        changeLanguage(ApiManager.getAcceptLanguage(language));
        i18next.changeLanguage(value);
        setLanguage(value);
    };
    return (React.createElement(PageBody, { title: t("settings") },
        React.createElement("div", { "data-testid": "generalSettingsWrapper", className: style.scrollWrapper },
            React.createElement("div", { className: style.pageWrapper },
                React.createElement("div", { className: s.setting },
                    React.createElement("div", { className: s.inputTitle }, t("language")),
                    React.createElement(Picker, { onChange: onLanguageChange, value: language, data: LANGUAGES, columns: 2 })),
                impersonatedAccountId == "" &&
                    React.createElement("div", { className: `${s.setting} ${s.security}` },
                        React.createElement("div", { className: s.inputTitle }, t("security")),
                        React.createElement("button", { className: s.changePasswordButton, style: { color: colors.controlsGeneralColor }, onClick: () => setPage(2), "data-testid": "buttonNext" },
                            React.createElement(Edit, { color: colors.controlsGeneralColor }),
                            t("changePassword")))))));
};
function mapStateToProps(state) {
    return {
        cultureCode: state.language.language,
        requestLanguageStatus: state.language.requestStatus,
        requestLanguageError: state.language.requestStatusError,
        impersonatedAccountId: state.account.user.impersonatedAccountId
    };
}
function mapDispatchToProps(dispatch) {
    return {
        changeLanguage: (language) => dispatch(changeLanguageAction(language)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
