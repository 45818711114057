import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getPatientBrandsAsyncAction, resetRequestAssignToBrandAction, } from "../../../../store";
import SelectBrandPage from "./components/SelectBrandPage";
import s from "./style.scss";
const ProfileBrands = ({ programPhoneNumber, getPatientBrands, patientBrands, requestAssignToBrandError, resetError, requestAssignToBrandStatus, patientId, requestPatientBrandsStatus, }) => {
    const { t } = useTranslation();
    const [openAddBrandPopup, setOpenAddBrandPopup] = useState(false);
    const toogleAddBrandPopup = () => {
        setOpenAddBrandPopup((open) => !open);
    };
    useEffect(() => {
        getPatientBrands();
    }, [patientId]);
    useEffect(() => {
        requestAssignToBrandStatus === "success" && getPatientBrands();
    }, [requestAssignToBrandStatus]);
    const renderBrands = (brands) => {
        return brands.map((brand) => {
            return (React.createElement("div", { key: brand.logoLink, className: s.brandWrapper },
                React.createElement("img", { className: s.brandLogo, src: brand.logoLink, alt: "brandLogo" }),
                React.createElement("div", { className: s.brandDate }, `${t("effective")} ${moment(brand.effectiveDate)
                    .lang(localStorage.getItem("i18nextLng") || "en")
                    .format("ll")}`)));
        });
    };
    return (React.createElement("div", { className: s.profileBrandsWrapper },
        React.createElement("div", { className: s.profileBrands }, patientBrands && renderBrands(patientBrands)),
        t("beenPrescribedAnotherDrug"),
        " ",
        programPhoneNumber,
        React.createElement(SelectBrandPage, { isOpen: openAddBrandPopup, togglePopUp: toogleAddBrandPopup })));
};
const mapStateToProps = (state) => {
    return {
        brands: state.brands,
        programPhoneNumber: state.account.brandPhoneNumber,
        patientBrands: state.account.patientBrands,
        requestAssignToBrandError: state.account.requestAssignToBrandError,
        requestAssignToBrandStatus: state.account.requestAssignToBrandStatus,
        patientId: state.account.user.patientId,
        requestPatientBrandsStatus: state.account.requestPatientBrandsStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPatientBrands: () => dispatch(getPatientBrandsAsyncAction()),
        resetError: () => dispatch(resetRequestAssignToBrandAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileBrands);
