import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import config from "../../../config.json";
import { Button, LanguagePicker, } from "../../components/Shared/Controls";
import logo from "../../resources/images/logo.svg";
import AppStore from "../../resources/images/appStore.png";
import PhoneMockup from "../../resources/images/phone_mockup.png";
import GooglePlay from "../../resources/images/googlePlay.png";
import TermsOfUse from "../../components/Shared/PopUps/TermsOfUse";
import PrivacyPolicy from "../../components/Shared/PopUps/PrivacyPolicy";
import Accessibility from "../../components/Shared/PopUps/Accessibility";
import s from "./style.scss";
import Banner from "../components/Banner";
const Support = ({}) => {
    const { t } = useTranslation();
    const [selectedLang, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
    const [termsOfUseIsOpen, setTermsOfUseIsOpen] = useState(false);
    const [accessibilityIsOpen, setAccessibilityIsOpen] = useState(false);
    const [isOpenPrivacyPolicy, setPrivacyPolicyOpen] = useState(false);
    const navigate = useNavigate();
    return (React.createElement("div", null,
        React.createElement(TermsOfUse, { isOpen: termsOfUseIsOpen, setIsOpen: setTermsOfUseIsOpen }),
        React.createElement(Accessibility, { isOpen: accessibilityIsOpen, setIsOpen: setAccessibilityIsOpen }),
        React.createElement(PrivacyPolicy, { isOpen: isOpenPrivacyPolicy, setIsOpen: setPrivacyPolicyOpen }),
        React.createElement(Banner, null),
        React.createElement("div", { className: s.splash },
            React.createElement("div", { className: s.header },
                React.createElement(SVG, { src: logo, className: s.logoIcon }),
                React.createElement(LanguagePicker, { style: s.languagePicker, selected: selectedLang, setLanguage: setLanguage })),
            React.createElement("div", { className: s.splashContent },
                React.createElement("div", { className: s.splashSubContent },
                    React.createElement("div", { className: s.helpBlockFirst },
                        React.createElement("p", { className: s.getApp }, t("havingTechnicalIssues")),
                        React.createElement("p", { className: s.helpText }, t("reachOutForAssistance")),
                        React.createElement("a", { className: classNames(s.button, s.primary), href: "mailto:icpspplatformadmin@amgen.com" }, t("contactUs"))),
                    React.createElement("div", { className: s.helpBlock },
                        React.createElement("p", { className: s.getApp }, t("needHelpWithYourCase")),
                        React.createElement("p", { className: s.helpText }, t("signInToContactPsp")),
                        React.createElement(Button, { text: t("signIn"), primary: true, onClick: () => navigate("/signin") }))),
                React.createElement("div", { className: s.splashSubContentSecond },
                    React.createElement("img", { className: s.devices, src: PhoneMockup }),
                    React.createElement("div", { className: s.info },
                        React.createElement("div", { className: s.getApp }, "Get Amgen Entrust\u00AE Patient App for your devices"),
                        React.createElement("div", { className: classNames(s.badges, s.inline, s.fs18) },
                            React.createElement("a", { target: "_blank", href: config.appstore_download_link },
                                React.createElement("img", { src: AppStore, className: s.badge })),
                            React.createElement("a", { target: "_blank", href: config.play_market_download_link },
                                React.createElement("img", { src: GooglePlay, className: s.badge })))))),
            React.createElement("div", { className: s.splashFooter },
                React.createElement("div", { className: s.paragraph_1 }),
                React.createElement("div", { className: s.splashFooter__links },
                    React.createElement("a", { className: s.paragraph_1, onClick: () => setTermsOfUseIsOpen(true) }, t("termOfUse")),
                    React.createElement("a", { target: "_blank", className: s.paragraph_1, onClick: () => setPrivacyPolicyOpen(true) }, t("privacyPolicy")),
                    React.createElement("a", { className: s.paragraph_1, onClick: () => setAccessibilityIsOpen(true) }, t("accessibility")))))));
};
export default Support;
