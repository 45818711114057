import React from 'react';
import SVG from 'react-inlinesvg';
import NotAllowed from '../../../../resources/icons/not-allowed.svg';
import s from './style.scss';
import { PopUp } from '..';
import { Button } from '../../Controls';
import { useTranslation } from 'react-i18next';
const ErrorPopUp = ({ style, isOpen, popUpContent, togglePopUp, title, buttons }) => {
    const { t } = useTranslation();
    const Title = (text, key = "") => {
        return React.createElement("span", { key: key },
            React.createElement("strong", null, text),
            " ",
            React.createElement("br", null));
    };
    const Content = (text, key = "") => {
        return React.createElement("span", { key: key },
            React.createElement("span", null, text),
            " ",
            React.createElement("br", null));
    };
    const HandleNewLine = (text) => {
        const newText = text.split('\\n').map((str, index, i) => (index === 0 ? Title(str, index) : Content(str, index)));
        return newText;
    };
    return (React.createElement(PopUp, { style: s.popup, contentStyle: s.popupContent, isOpen: isOpen, toggle: togglePopUp, allowClose: true, top: true },
        React.createElement("div", { className: s.popup__header },
            React.createElement("div", null,
                React.createElement(SVG, { src: NotAllowed, className: s.popup__header_icon }))),
        React.createElement("div", { className: s.popup__content },
            React.createElement("div", null,
                React.createElement("strong", null, title)),
            React.createElement("div", null, popUpContent)),
        React.createElement("div", { className: s.popup__controls }, buttons ? buttons.map((el) => {
            return React.createElement(Button, { key: el.text, style: s.button, text: el.text, onClick: () => {
                    el.onClick && el.onClick();
                    el.cancel && togglePopUp();
                } });
        }) :
            React.createElement(Button, { style: s.button, text: t("understood"), onClick: togglePopUp }))));
};
export default ErrorPopUp;
