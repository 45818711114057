import React from "react";
import { connect } from "react-redux";
import { Button, InputText } from "../../../../components/Shared/Controls";
import { useInput } from "../../../../hooks";
import { sendMessageSagaAsyncAction } from "../../../../store/chat/Sagas";
import { useTranslation } from "react-i18next";
import s from "./style.scss";
const ChatFooter = ({ patientId, sendMessageStatus, sendMessage, disabled }) => {
    const { t } = useTranslation();
    const [message, setMessage] = useInput("");
    const sendMessageOnClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        message && sendMessage(message);
        setMessage("");
    };
    return (React.createElement("form", { className: s.chatFooter, onSubmit: sendMessageOnClick },
        React.createElement(InputText, { name: "message", placeholder: t("chatInputPlaceHolder"), maxLength: 500, value: message, disabled: disabled, setValue: setMessage }),
        React.createElement(Button, { text: t("send"), type: "submit", disabled: !message || sendMessageStatus === "pending", primary: true })));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        sendMessageStatus: state.chat.requestSendMessageStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        sendMessage: (payload) => dispatch(sendMessageSagaAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatFooter);
