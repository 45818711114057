import { call, put, select, takeLatest } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import PharmacySlice, { SLICE_NAME } from "./slice";
import PharmacyRepository from "../repository/PharmacyRepository";
import { handleApiError } from "../utils";
import { mapPharmacyInfo, mapSingleDataOfPharmacyPersonalInfo } from "./Mapper";
import { userInfoSelector } from "../account";
export const postPatientPharmacyAsyncAction = createAction(`${SLICE_NAME}/createPharmacyInfoAsyncAction`);
export function* createPatientPharmacySaga(action) {
    const { startCreatePharmacyInfoAsyncAction, successCreatePharmacyInfoAsyncAction, showCreatePharmacyInfoErrorAction } = PharmacySlice.actions;
    try {
        const { drugBrandCode, patientId } = yield select(userInfoSelector);
        const data = {
            patientId: patientId,
            drugBrand: drugBrandCode,
            name: action.payload.name,
            address: action.payload.address,
            city: action.payload.city,
            postal: action.payload.postal ?? "",
            country: "CA",
            phone: action.payload.phone,
            note: action.payload.note ?? "",
            brandIdentifier: ""
        };
        yield put(startCreatePharmacyInfoAsyncAction());
        yield call(PharmacyRepository.postPharmacyInfo, data);
        yield put(successCreatePharmacyInfoAsyncAction(data));
    }
    catch (error) {
        yield handleApiError(error, showCreatePharmacyInfoErrorAction);
    }
}
export const getPatientPharmacyAsyncAction = createAction(`${SLICE_NAME}/getPatientPharmacyAsyncAction`);
export function* getPatientPharmacySaga(action) {
    const { startRequestGetPharmacyAction, successRequestGetPharmacyAction, showRequesGetPharmacyErrorAction, } = PharmacySlice.actions;
    try {
        yield put(startRequestGetPharmacyAction());
        let result = yield call(PharmacyRepository.getPatientPharmacyInfo, action.payload);
        const ResponseData = yield mapPharmacyInfo(result);
        yield put(successRequestGetPharmacyAction(ResponseData));
    }
    catch (error) {
        yield handleApiError(error, showRequesGetPharmacyErrorAction);
    }
}
//post
export const postPharmacyInfoAsyncAction = createAction(`${SLICE_NAME}/postPharmacyInfoAsyncAction`);
export function* postPharmacyInfoSaga(action) {
    const { startPostPharmacyInfoAsyncAction, succesPostPharmacyInfoAsyncAction, showPostPharmacyInfoErrorAction } = PharmacySlice.actions;
    try {
        yield put(startPostPharmacyInfoAsyncAction());
        const { drugBrandCode, patientId } = yield select(userInfoSelector);
        const data = {
            patientId: patientId,
            drugBrand: drugBrandCode,
            name: action.payload.name,
            address: action.payload.address,
            city: action.payload.city,
            country: action.payload.country,
            phone: action.payload.phone,
            latitude: action.payload.latitude,
            longitude: action.payload.longitude,
            brandIdentifier: action.payload.brandIdentifier,
        };
        const result = yield call(PharmacyRepository.postPharmacyInfo, data);
        yield put(succesPostPharmacyInfoAsyncAction(data));
    }
    catch (error) {
        yield handleApiError(error, showPostPharmacyInfoErrorAction);
    }
}
//get only pharmacy
export const getPharmaciesAsyncAction = createAction(`${SLICE_NAME}/getPharmaciesAsyncAction`);
export function* getPharmaciesSaga(action) {
    const { startRequestGetOnlyPharmacyAction, successRequestGetOnlyPharmacyAction, showRequesGetOnlyPharmacyErrorAction, storeAllDataOfPharmacyAction } = PharmacySlice.actions;
    try {
        yield put(startRequestGetOnlyPharmacyAction());
        let result = yield call(PharmacyRepository.getPharmaciesInfo, action.payload);
        const ResponseData = yield mapPharmacyInfo(result);
        yield put(successRequestGetOnlyPharmacyAction(ResponseData));
        yield put(storeAllDataOfPharmacyAction(result));
    }
    catch (error) {
        yield handleApiError(error, showRequesGetOnlyPharmacyErrorAction);
    }
}
//delete
export const deletePharmacyAsyncAction = createAction(`${SLICE_NAME}/deletePharmacyAsyncAction`);
export function* deletePharmacySaga(action) {
    const { startRequestDeletePharmacyAction, deletePharmacyAction, showRequestDeletePharmacyErrorAction, } = PharmacySlice.actions;
    try {
        yield put(startRequestDeletePharmacyAction());
        yield call(PharmacyRepository.deletePharmacy, action.payload);
        yield put(deletePharmacyAction(action.payload.PharmacyId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeletePharmacyErrorAction);
    }
}
//put
export const putPatientPharmacyAsyncAction = createAction(`${SLICE_NAME}/PutPharmacyInfoAsyncAction`);
export function* PutPharmacyInfoSaga(action) {
    const { startPutPharmacyInfoAsyncAction, successPutPharmacyInfoAsyncAction, showPutPharmacyInfoErrorAction } = PharmacySlice.actions;
    try {
        const data = {
            id: action.payload.id,
            name: action.payload.name,
            address: action.payload.address,
            city: action.payload.city,
            country: action.payload.country,
            phone: action.payload.phone,
            brandIdentifier: action.payload.brandIdentifier,
            note: action.payload.note,
            postal: action.payload.postal,
            latitude: action.payload.latitude,
            longitude: action.payload.longitude
        };
        yield put(startPutPharmacyInfoAsyncAction());
        yield call(PharmacyRepository.putPharmacy, data);
        yield put(successPutPharmacyInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showPutPharmacyInfoErrorAction);
    }
}
//delete
export const deletePharmaciesAsyncAction = createAction(`${SLICE_NAME}/deletePharmaciesAsyncAction`);
export function* deletePharmaciesSaga(action) {
    const { startRequestDeletePharmaciesAction, deletePharmaciesAction, showRequestDeletePharmaciesErrorAction, } = PharmacySlice.actions;
    try {
        yield put(startRequestDeletePharmaciesAction());
        yield call(PharmacyRepository.deletePharmacies, action.payload);
        yield put(deletePharmaciesAction(action.payload.PharmacyId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeletePharmaciesErrorAction);
    }
}
//post
export const createPharmaciesInfoAsyncAction = createAction(`${SLICE_NAME}/createPharmaciesInfoAsyncAction`);
export function* createPharmaciesInfoSaga(action) {
    const { startCreatePharmaciesInfoAsyncAction, successCreatePharmaciesInfoAsyncAction, showcreatePharmaciesInfoErrorAction } = PharmacySlice.actions;
    try {
        yield put(startCreatePharmaciesInfoAsyncAction());
        yield call(PharmacyRepository.postPharmacies, action.payload);
        yield put(successCreatePharmaciesInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreatePharmaciesInfoErrorAction);
    }
}
//update Pharmacies
export const updatePharmaciesInfoAsyncAction = createAction(`${SLICE_NAME}/updatePatientInfoAsyncAction`);
export function* updatePharmaciesInfoSaga(action) {
    const { startRequestUpdatePharmaciesInfoAction, successRequestUpdatePharmaciesInfoAction, showRequestUpdatePharmaciesInfoErrorAction, } = PharmacySlice.actions;
    try {
        yield put(startRequestUpdatePharmaciesInfoAction());
        yield call(PharmacyRepository.updatePharmaciesInfo, action.payload);
        yield put(successRequestUpdatePharmaciesInfoAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdatePharmaciesInfoErrorAction);
    }
}
//only get one pharmacy
export const getSingleDataOfPharmacyInfoAsyncAction = createAction(`${SLICE_NAME}/getSingleDataOfPharmacyInfoAsyncAction`);
export function* getSingleDataOfPharmacyInfoSaga(action) {
    const { startRequestSingleDataOfPharmacyInfoAction, successRequesSingleDataOfPharmacyInfoAction, showRequesSingleDataOfPharmacyInfoErrorAction, } = PharmacySlice.actions;
    try {
        yield put(startRequestSingleDataOfPharmacyInfoAction());
        let result = yield call(PharmacyRepository.getSingleDataOfPharmacyInfo, action.payload);
        const SingleDataOfPharmacies = yield mapSingleDataOfPharmacyPersonalInfo(result.data);
        yield put(successRequesSingleDataOfPharmacyInfoAction(SingleDataOfPharmacies));
    }
    catch (error) {
        yield handleApiError(error, showRequesSingleDataOfPharmacyInfoErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(postPatientPharmacyAsyncAction, createPatientPharmacySaga);
    yield takeLatest(getPatientPharmacyAsyncAction, getPatientPharmacySaga);
    yield takeLatest(postPharmacyInfoAsyncAction, postPharmacyInfoSaga);
    yield takeLatest(getPharmaciesAsyncAction, getPharmaciesSaga);
    yield takeLatest(deletePharmacyAsyncAction, deletePharmacySaga);
    yield takeLatest(putPatientPharmacyAsyncAction, PutPharmacyInfoSaga);
    yield takeLatest(deletePharmaciesAsyncAction, deletePharmaciesSaga);
    yield takeLatest(getSingleDataOfPharmacyInfoAsyncAction, getSingleDataOfPharmacyInfoSaga);
    yield takeLatest(updatePharmaciesInfoAsyncAction, updatePharmaciesInfoSaga);
    yield takeLatest(createPharmaciesInfoAsyncAction, createPharmaciesInfoSaga);
}
