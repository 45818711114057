import ApiManager from "../api/ApiManager";
import config from "../../../config.json";
const version = "1.2";
const BRAND = `/v${version}/brands`;
const BRAND_SEARCH = `/v${version}/brands/search`;
const BRAND_SETTINGS = `/v${version}/brand-setting`;
class BrandsRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new BrandsRepository();
        }
        return this.instance;
    }
    static getBrands = (isTestBrands = false) => {
        return ApiManager.getInstance().get(BRAND, { IncludeTestBrand: isTestBrands, applicationType: config.applicationType });
    };
    static searchBrands = (query) => {
        return ApiManager.getInstance().get(`${BRAND_SEARCH}/${query}`);
    };
    static getBrandSettings = (drugBrandCode) => {
        return ApiManager.getInstance().get(BRAND_SETTINGS, {
            drugBrandCode: drugBrandCode
        });
    };
}
export default BrandsRepository;
