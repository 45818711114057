import { createAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";
import provincesSlice, { SLICE_NAME } from "./Slice";
import { handleApiError } from "../utils";
import { mapProvincesFromConfig } from "./Mapper";
import config from "../../../config.json";
import { getI18n } from "react-i18next";
export const getProvincesAsyncAction = createAction(`${SLICE_NAME}/provincesAsyncAction`);
function* getProvincesSaga() {
    const { startRequestAction, showErrorAction, successRequestAction } = provincesSlice.actions;
    try {
        const local = getI18n();
        yield put(startRequestAction());
        const result = config.province.map((val) => { return { "key": val, "displayName": local.t(val) }; });
        const provinces = yield mapProvincesFromConfig(result);
        yield put(successRequestAction(provinces));
    }
    catch (error) {
        yield handleApiError(error, showErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getProvincesAsyncAction, getProvincesSaga);
}
