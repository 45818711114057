import { useEffect } from "react";
const useDeviceHeight = (element, desktopHeight, tabletHeight, mobileHeight) => {
    function setContainerHeight() {
        const myElement = document.getElementById(element);
        const viewportHeight = window.innerHeight;
        if (window.innerWidth > 1024) {
            myElement.style.height = `calc(${viewportHeight}px - ${desktopHeight}px)`;
        }
        if (window.innerWidth <= 1024) {
            myElement.style.height = `calc(${viewportHeight}px - ${tabletHeight}px)`;
        }
        if (mobileHeight && window.innerWidth <= 440) {
            myElement.style.height = `calc(${viewportHeight}px - ${mobileHeight}px)`;
        }
    }
    useEffect(() => {
        setContainerHeight();
        window.addEventListener("resize", setContainerHeight);
        return () => window.removeEventListener("resize", setContainerHeight);
    }, []);
};
export default useDeviceHeight;
