import { call, put, takeLatest } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import presciberSlice, { SLICE_NAME } from "./slice";
import PresciberRepository from "../repository/PrescriberRepository";
import { handleApiError } from "../utils";
import { mapDrugBrandCodesDropdownInfo, mapPrescriberPersonalInfo } from "./Mapper";
export const createPrescriberInfoAsyncAction = createAction(`${SLICE_NAME}/createPrescriberInfoAsyncAction`);
export function* createPrescriberInfoSaga(action) {
    const { startCreatePrescriberInfoAsyncAction, succesCreatePrescriberInfoAsyncAction, showcreatePrescriberInfoErrorAction } = presciberSlice.actions;
    try {
        yield put(startCreatePrescriberInfoAsyncAction());
        yield call(PresciberRepository.postPrescriber, action.payload);
        yield put(succesCreatePrescriberInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreatePrescriberInfoErrorAction);
    }
}
export const getPatientPrescriberInfoAsyncAction = createAction(`${SLICE_NAME}/getPrescriberInfoAsyncAction`);
export function* getPatientPrescriberInfoSaga(action) {
    const { startRequestPrescriberInfoAction, successRequesPrescriberInfoAction, showRequesPrescriberInfoErrorAction, } = presciberSlice.actions;
    try {
        yield put(startRequestPrescriberInfoAction());
        let result = yield call(PresciberRepository.getPatientPrescriberInfo, action.payload);
        const presciber = yield mapPrescriberPersonalInfo(result.data);
        yield put(successRequesPrescriberInfoAction(presciber));
    }
    catch (error) {
        yield handleApiError(error, showRequesPrescriberInfoErrorAction);
    }
}
export const getDrugBrandCodesDropdownAsyncAction = createAction(`${SLICE_NAME}/getDrugBrandCodesDropdownAsyncAction`);
export function* getDrugBrandCodesDropdownSaga(page) {
    const { startRequestDrugBrandCodesDropdownAction, successRequestDrugBrandCodesDropdownAction, showRequesDrugBrandCodesDropdownErrorAction, } = presciberSlice.actions;
    try {
        yield put(startRequestDrugBrandCodesDropdownAction());
        let result = yield call(PresciberRepository.getDrugBrandCodesDropdownInfo);
        const DrugBrandCodesDropdown = yield mapDrugBrandCodesDropdownInfo(result.data);
        yield put(successRequestDrugBrandCodesDropdownAction(DrugBrandCodesDropdown));
    }
    catch (error) {
        yield handleApiError(error, showRequesDrugBrandCodesDropdownErrorAction);
    }
}
export const getAllPrescriberInfoAsyncAction = createAction(`${SLICE_NAME}/getAllPrescriberInfoAsyncAction`);
export function* getAllPrescriberInfoSaga(action) {
    const { startRequestgetAllPrescriberAction, successRequestgetAllPrescriberAction, showRequestgetAllPrescriberErrorAction, } = presciberSlice.actions;
    try {
        yield put(startRequestgetAllPrescriberAction());
        let result = yield call(PresciberRepository.getAllPrescriberInfo, action.payload);
        console.log(result);
        yield put(successRequestgetAllPrescriberAction(result.data.value));
    }
    catch (error) {
        yield handleApiError(error, showRequestgetAllPrescriberErrorAction);
    }
}
export const updatePrescriberInfoAsyncAction = createAction(`${SLICE_NAME}/updatePatientInfoAsyncAction`);
export function* updatePrescriberInfoSaga(action) {
    const { startRequestUpdatePrescriberInfoAction, successRequestUpdatePrescriberInfoAction, showRequestUpdatePrescriberInfoErrorAction, } = presciberSlice.actions;
    try {
        yield put(startRequestUpdatePrescriberInfoAction());
        yield call(PresciberRepository.updatePrescriberInfo, action.payload, action.payload.Id);
        yield put(successRequestUpdatePrescriberInfoAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdatePrescriberInfoErrorAction);
    }
}
//delete
export const deletePrescriberAsyncAction = createAction(`${SLICE_NAME}/deletedeletePrescriberAsyncAction`);
export function* deletePrescriberSaga(action) {
    const { startRequestDeletePrescriberAction, deletePrescriberAction, showRequestDeletePrescriberErrorAction, } = presciberSlice.actions;
    try {
        yield put(startRequestDeletePrescriberAction());
        yield call(PresciberRepository.deletePrescriber, action.payload.presciberId);
        yield put(deletePrescriberAction(action.payload.presciberId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeletePrescriberErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(createPrescriberInfoAsyncAction, createPrescriberInfoSaga);
    yield takeLatest(getPatientPrescriberInfoAsyncAction, getPatientPrescriberInfoSaga);
    yield takeLatest(getDrugBrandCodesDropdownAsyncAction, getDrugBrandCodesDropdownSaga);
    yield takeLatest(updatePrescriberInfoAsyncAction, updatePrescriberInfoSaga);
    yield takeLatest(getAllPrescriberInfoAsyncAction, getAllPrescriberInfoSaga);
    yield takeLatest(deletePrescriberAsyncAction, deletePrescriberSaga);
}
