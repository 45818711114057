import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
const MultiSelectionDropdown = ({ options, selectedOptions, setSelectedOptions, placeholder }) => {
    const [inputValue, setInputValue] = useState('');
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };
    const handleInputKeyDown = (event) => {
        if (event.key === 'Enter' && inputValue.trim() !== '') {
            if (!selectedOptions.includes(inputValue)) {
                setSelectedOptions([...selectedOptions, inputValue]);
            }
            setInputValue('');
        }
    };
    const filteredOptions = options ? options.filter((option) => !selectedOptions.includes(option)) : [];
    const combinedOptions = [
        ...selectedOptions,
        ...filteredOptions,
    ];
    return (React.createElement(Autocomplete, { multiple: true, options: combinedOptions, disableCloseOnSelect: true, value: selectedOptions, onChange: (_, newValue) => {
            setSelectedOptions(newValue);
        }, freeSolo: true, getOptionLabel: (option) => option, style: { width: 500 }, renderInput: (params) => (React.createElement(TextField, { ...params, 
            // label="Checkboxes"
            placeholder: placeholder, value: inputValue, onChange: handleInputChange, onKeyDown: handleInputKeyDown })) }));
};
export default MultiSelectionDropdown;
