import React, { useEffect, useState } from "react";
import { Button, InputText } from "../../components/Shared/Controls";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { createPrescriberInfoAsyncAction, getAllPrescriberInfoAsyncAction, getDrugBrandCodesDropdownAsyncAction, updatePrescriberInfoAsyncAction } from "../../store/prescriber/Sagas";
import CheckboxDropdown from "../../components/Shared/Controls/CheckboxDropdown";
import LoadingPopUp from "../../components/Shared/LoadingPopUp";
import SuccessPopUp from '../../components/Shared/Info/SuccessPopUp';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import prescribercss from "./PrescriberStyle.scss";
import golbalcss from "../styles.scss";
import InputTextMultiLine from "../../components/Shared/Controls/Inputs/InputTextMultiLine";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
const PrescriberAdmin = ({ patientId, createPrescriber, updatePrescriber, getPrescriberInfo, getDrugBrandCodesDropdown, drugBrandCodesDropdown, getPrescriberInfoState, requestUpdatePrescriberInfoStatus, requestPrescriberPostStatus, requestPrescriberInfoStatus, requestDrugBrandCodesDropdownStatus }) => {
    const { t } = useTranslation();
    const [openSavePopup, setOpenSavePopup] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [Name, setName] = useState("");
    const [Note, setNote] = useState("");
    const [BrandIdentifier, setBrandIdentifier] = useState("");
    const [successPopupIsOpen, setSuccessPopupIsOpen] = useState(false);
    const toggleSavePopup = () => setOpenSavePopup((open) => !open);
    const [isSubmit, setisSubmit] = useState(false);
    useEffect(() => {
        getDrugBrandCodesDropdown();
        requestPrescriberPostStatus = "idle";
        requestUpdatePrescriberInfoStatus = "idle";
    }, []);
    useEffect(() => {
        setName("");
        setNote("");
        setBrandIdentifier("");
        if (id && id != 'create') {
            getPrescriberInfo({ patientId, id });
        }
    }, [id]);
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode
            };
        })
        : [];
    const [items, setItems] = useState(DrugBrandCode);
    const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
    const handleSetItems = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.displayName);
        setSelectedDisplayNames(selectedNames);
        setItems(newItems);
    };
    useEffect(() => {
        if (id && id != 'create') {
            if (getPrescriberInfoState?.[0] != null) {
                const firstPrescriber = getPrescriberInfoState[0];
                setName(firstPrescriber.name || "");
                setNote(firstPrescriber.notes || "");
                setBrandIdentifier(firstPrescriber.brandIdentifier || "");
                // Set the drugBrandCodes in CheckboxDropdown
                const selectedDrugBrandCodes = firstPrescriber.drugBrandCodes || [];
                const updatedItems = items.map((item) => ({
                    ...item,
                    isChecked: selectedDrugBrandCodes.includes(item.displayName),
                }));
                handleSetItems(updatedItems);
            }
        }
        else {
            setName("");
            setNote("");
            setBrandIdentifier("");
        }
    }, [getPrescriberInfoState]);
    const onsubmitData = () => {
        setisSubmit(true);
        if (Name != "") {
            if (id && id != 'create') {
                updatePrescriber({
                    Id: id,
                    DrugBrandCodes: selectedDisplayNames,
                    Name: Name,
                    Note: Note,
                    BrandIdentifier: BrandIdentifier,
                });
                setisSubmit(false);
            }
            else {
                createPrescriber({
                    DrugBrandCodes: selectedDisplayNames,
                    Name: Name,
                    Note: Note,
                    BrandIdentifier: BrandIdentifier,
                });
                setisSubmit(false);
            }
        }
    };
    const onFormSubmit = (event) => {
        event.preventDefault();
        onsubmitData();
    };
    useEffect(() => {
        if (requestPrescriberPostStatus === "success") {
            setSuccessPopupIsOpen(true);
            setName("");
            setNote("");
            setBrandIdentifier("");
            requestPrescriberPostStatus = "idle";
        }
    }, [requestPrescriberPostStatus]);
    useEffect(() => {
        if (requestUpdatePrescriberInfoStatus === "success") {
            requestUpdatePrescriberInfoStatus = "idle";
            setSuccessPopupIsOpen(true);
        }
    }, [requestUpdatePrescriberInfoStatus]);
    const handleArrowBack = () => {
        navigate('/prescribers');
    };
    return (React.createElement(Box, { className: golbalcss.MuiPaperAllSectionBox, sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { className: prescribercss.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement("div", { className: prescribercss.prescriberModifyanEntry },
                id ? React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: prescribercss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("prescriber"),
                    " ",
                    React.createElement("span", { className: prescribercss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("modifyanentry"),
                    " ") : React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: prescribercss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("prescriber"),
                    " ",
                    React.createElement("span", { className: prescribercss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("createanentry"),
                    " "),
                React.createElement("div", { className: prescribercss.pagebodyBox },
                    React.createElement("div", { className: prescribercss.scrollWrapper },
                        React.createElement(React.Fragment, null,
                            React.createElement("form", { onSubmit: onFormSubmit },
                                React.createElement("div", { className: prescribercss.forminputAll },
                                    React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                        React.createElement("label", null,
                                            t("name"),
                                            " ",
                                            React.createElement("span", { className: golbalcss.errortext }, "*")),
                                        React.createElement(InputText, { name: "Name", value: Name, setValue: setName, maxLength: 100, validationMessage: t("pleaseEnterName"), isSubmitted: isSubmit, required: true })),
                                    React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                        React.createElement("label", null, t("drugbrandcodes")),
                                        React.createElement(CheckboxDropdown, { items: items, setItems: handleSetItems })),
                                    React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                        React.createElement("label", null, t("brandidentifier")),
                                        React.createElement(InputText, { name: "BrandIdentifier", value: BrandIdentifier, setValue: setBrandIdentifier })),
                                    React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                        React.createElement("label", null,
                                            t("note"),
                                            " (Max 256 characters)"),
                                        React.createElement(InputTextMultiLine, { name: "Note", maxLength: 256, value: Note, setValue: setNote, columns: 6, height: 110 }))),
                                React.createElement("div", { className: prescribercss.buttonTwolist },
                                    React.createElement(Button, { text: t("cancel"), onClick: () => handleArrowBack() }),
                                    React.createElement(Button, { primary: true, text: t("savechanges"), type: "submit" }))),
                            React.createElement(SuccessPopUp, { isOpen: successPopupIsOpen, displayMessage: "Your changes have been saved.", header: "success!", name: "Prescribers", togglePopUp: () => { setSuccessPopupIsOpen(false); handleArrowBack(); }, onClose: () => { setSuccessPopupIsOpen(false); handleArrowBack(); } }),
                            requestUpdatePrescriberInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestPrescriberPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestPrescriberInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null)))))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId ?? "",
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown,
        getPrescriberInfoState: state.prescriber.getAllPrescribers.resultItems,
        requestUpdatePrescriberInfoStatus: state.prescriber.requestUpdatePrescriberInfoStatus,
        requestPrescriberPostStatus: state.prescriber.requestPrescriberPostStatus,
        requestPrescriberInfoStatus: state.prescriber.requestgetAllPrescriberStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        createPrescriber: (prescriberInfo) => dispatch(createPrescriberInfoAsyncAction(prescriberInfo)),
        getPrescriberInfo: (filters) => dispatch(getAllPrescriberInfoAsyncAction(filters)),
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
        updatePrescriber: (prescriberInfo) => dispatch(updatePrescriberInfoAsyncAction(prescriberInfo)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriberAdmin);
