import React, { useEffect } from 'react';
import i18next from "i18next";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import ValueDropdown from '../Inputs/ValueDropdown';
import s from './style.scss';
import colors from "../../../../resources/colors";
const languageMap = {
    en: { label: "EN", dir: "ltr", active: true },
    fr: { label: "FR", dir: "ltr", active: false }
};
const LanguagePicker = ({ style, selected, setLanguage }) => {
    const { t } = useTranslation();
    const LANGUAGES = [
        "en",
        "fr"
    ];
    useEffect(() => {
        if (!LANGUAGES.includes(i18next.language)) {
            i18next.changeLanguage('en');
            setLanguage('en');
        }
    }, [i18next.language]);
    const handleChange = (value) => {
        setLanguage(value);
        i18next.changeLanguage(value);
    };
    return (React.createElement("div", { className: s.wrapper },
        React.createElement(ValueDropdown, { "data-testid": "picker-test", color: colors.neutralGray, style: classNames(s.languagePicker, style), data: LANGUAGES, value: selected.toUpperCase(), setValue: handleChange, uppercase: true })));
};
export default LanguagePicker;
