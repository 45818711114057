import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import PageBody from "../../components/Shared/PageBody";
import { PopUp } from "../../components/Shared/Info";
import { Button } from "../../components/Shared/Controls";
import s from "./style.scss";
import { Box } from "../../components";
import CallPreferences from "../SignUp/Steps/InvitationEmail/Components/CallPreferences";
const CallPreferencesPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [youAreSureModal, setYouAreSureModal] = useState(false);
    const toggleYouAreSureModal = () => setYouAreSureModal(!youAreSureModal);
    const [haveChanges, setHaveChanges] = useState(false);
    const backAction = () => {
        !haveChanges
            ? navigate(-1)
            : setYouAreSureModal(true);
    };
    const renderYouAreSureModal = () => {
        return React.createElement(PopUp, { style: s.ySureModal, isOpen: youAreSureModal, toggle: toggleYouAreSureModal },
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("sureExitWithoutSaving")),
            React.createElement("div", { className: s.actions },
                React.createElement(Button, { style: s.button, text: t("yes"), primary: true, onClick: () => {
                        navigate(-1);
                    } }),
                React.createElement(Button, { style: s.button, text: t("no"), onClick: toggleYouAreSureModal })));
    };
    return (React.createElement(Box, { fullWidth: true },
        React.createElement(PageBody, { backAction: backAction, title: t("communicationPreferences") },
            React.createElement(CallPreferences, { isInAdditionalSteps: false }, renderYouAreSureModal()))));
};
export default CallPreferencesPage;
