// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__popheaderwithcloseicon--ov0ly{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:24px;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.style__popheaderwithcloseicon--ov0ly svg{width:24px;height:24px;cursor:pointer}.style__popheaderwithcloseicon--ov0ly svg:is(:hover,:focus){opacity:0.8}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/Info/SuccessPopUp/style.scss"],"names":[],"mappings":"AAAA,sCAAyB,mBAAoB,CAAE,mBAAoB,CAAE,YAAa,CAAE,wBAAyB,CAAE,qBAAsB,CAAE,6BAA8B,CAAE,kBAAmB,CAAE,wBAAa,CAAb,qBAAa,CAAb,kBAAmB,CAAG,0CACtL,UAAW,CAAE,WAAY,CAAE,cAAe,CAAI,4DAC5B,WAAY","sourcesContent":[".popheaderwithcloseicon {display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; margin-bottom: 24px; align-items: center;}\r\n.popheaderwithcloseicon svg{width: 24px; height: 24px; cursor: pointer; }\r\n.popheaderwithcloseicon svg:is(:hover,:focus){opacity: 0.8;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popheaderwithcloseicon": "style__popheaderwithcloseicon--ov0ly"
};
export default ___CSS_LOADER_EXPORT___;
