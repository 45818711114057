import * as React from 'react';
import { useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import { Button, FormControl, OutlinedInput } from '@mui/material';
import { setAdminAzureIdImpersonateAction, setAlldataImpersonate, setExistingImpersonate, setExistingImpersonatedAccountId, setExistingImpersonateInfo, setImpersonatedAccountId, setImpersonateInfo, setisAdminStatus, setPaientAzureIdImpersonateAction, setPatientIdForImpersonate } from '../../store';
import { connect } from 'react-redux';
import { getAllPatientAsyncAction } from '../../store/patient/Sagas';
import PatientFilter from './PatientFilter';
import LoadingPopUp from '../../components/Shared/LoadingPopUp';
import NoImage from '../../resources/images/noimage.png';
import maskIconSVG from '../../resources/icons/Mask.svg';
import SVG from 'react-inlinesvg';
import patientcss from "./PatientStyle.scss";
import { useNavigate } from 'react-router-dom';
import { setPatientdataAction } from '../../store/account/Sagas';
import globaltableStyle from "../tableStyle.scss";
import golbalcss from "../styles.scss";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import classNames from 'classnames';
// @ts-ignore
import { t } from 'i18next';
import ErrorPopUp from "../../components/Shared/Info/ErrorPopUp";
import { useErrorPopUp } from "../../hooks";
const headCells = [
    {
        id: 'LastName',
        numeric: false,
        disablePadding: true,
        disableSort: false,
        label: 'image',
    },
    {
        id: 'FirstName',
        numeric: true,
        disablePadding: false,
        disableSort: false,
        label: 'name',
    },
    {
        id: 'BirthDate',
        numeric: true,
        disablePadding: false,
        disableSort: false,
        label: 'dateofBirth',
    },
    {
        id: 'Province',
        numeric: true,
        disablePadding: false,
        disableSort: true,
        label: 'province',
    },
    {
        id: 'Phone',
        numeric: true,
        disablePadding: false,
        disableSort: true,
        label: 'phone',
    },
    {
        id: 'Relationship',
        numeric: true,
        disablePadding: false,
        disableSort: false,
        label: 'relationship',
    },
    {
        id: 'Email',
        numeric: true,
        disablePadding: false,
        disableSort: true,
        label: 'email',
    },
    {
        id: 'Brand',
        numeric: true,
        disablePadding: false,
        disableSort: false,
        label: 'brand',
    },
    {
        id: 'Linked',
        numeric: true,
        disablePadding: false,
        disableSort: true,
        label: 'linked',
    },
    {
        id: 'Impersonate',
        numeric: true,
        disablePadding: false,
        disableSort: true,
        label: '',
    },
];
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, null, headCells.map((headCell, index) => (React.createElement(TableCell, { key: index, align: headCell.numeric ? 'left' : 'left', padding: headCell.disablePadding ? 'none' : 'normal', sortDirection: orderBy === headCell.id ? order : false }, headCell.disableSort ? React.createElement("span", null, t(headCell.label) || headCell.label) :
            React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: createSortHandler(headCell.id) },
                t(headCell.label) || headCell.label,
                orderBy === headCell.id ? (React.createElement(Box, { component: "span", sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)))))));
}
function EnhancedTableToolbar(props) {
    const { numSelected } = props;
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [holdDataFilter, setholdDataFilter] = useState();
    const [status, setStatus] = useState("New");
    const handleIconClick = () => {
        setPopupOpen(true);
    };
    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const handleSearchInput = (e) => {
        props.setSearch?.(e.target.value);
    };
    const setFields = (patientFilterfieldsObj) => {
        setholdDataFilter(patientFilterfieldsObj);
        props.setField?.(patientFilterfieldsObj);
    };
    const setPatientStatus = (val) => {
        props.setPatientStaus?.(val);
        setStatus(val);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Toolbar, { className: golbalcss.TitleTopBar, sx: {
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            } },
            numSelected > 0 ? (React.createElement(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "subtitle1", component: "div" },
                numSelected,
                " selected")) : (React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" }, t("patients"))),
            React.createElement(FormControl, { sx: { width: '65.55ch' } },
                React.createElement(OutlinedInput, { className: golbalcss.searchInput, onChange: (e) => handleSearchInput(e), placeholder: t("Search by Last Name") })),
            numSelected > 0 ? (React.createElement(Tooltip, { title: t('delete') },
                React.createElement(IconButton, null,
                    React.createElement(DeleteIcon, null)))) : (React.createElement(Tooltip, { className: golbalcss.FilterIconSearch, title: t('filterlist') },
                React.createElement(IconButton, { onClick: handleIconClick },
                    React.createElement(FilterAltIcon, null)))),
            isPopupOpen &&
                React.createElement(PatientFilter, { onClose: handleClosePopup, setFields: setFields, holdDataFilter: holdDataFilter })),
        React.createElement("div", { className: patientcss.tabbutonStyle },
            React.createElement(Button, { className: classNames(patientcss.buttonStyle, status == "All" ? patientcss.buttonStyleActive : null), onClick: () => setPatientStatus("All") },
                t("all"),
                React.createElement("span", { className: patientcss.count },
                    " ",
                    props.getAllPatientsState?.newCount + props.getAllPatientsState?.updatedCount,
                    " ")),
            React.createElement(Button, { className: classNames(patientcss.buttonStyle, status == "New" ? patientcss.buttonStyleActive : null), onClick: () => setPatientStatus("New") },
                t("new"),
                React.createElement("span", { className: patientcss.count },
                    " ",
                    props.getAllPatientsState?.newCount,
                    " ")),
            React.createElement(Button, { className: classNames(patientcss.buttonStyle, status == "Updated" ? patientcss.buttonStyleActive : null), onClick: () => setPatientStatus("Updated") },
                t("updated"),
                React.createElement("span", { className: patientcss.count },
                    " ",
                    props.getAllPatientsState?.updatedCount,
                    " "),
                " "))));
}
const Patients = ({ getAllPatientsInfo, getAllPatientsState, requestPatientStatus, updateStateIsAdmin, setPatientdataFun, setPatientIdForImpersonate, setExistingImpersonate, patientId, setImpersonatedAccountId, setExistingImpersonatedAccountId, impersonatedAccountId, setImpersonateInfoAction, setExistingImpersonateInfoAction, firstnameImpersonate, lastnameImpersonate, phoneNumberImpersonate, IdImpersonate, emailImersonate, setAllDataImpersonateAction, setAdminAzureIdImpersonate, setPatientAzureIdImpersonate }) => {
    const navigate = useNavigate();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('LastName');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [DateOfBirth, setDateOfBirth] = useState("");
    const [PatientName, setPatientName] = useState("");
    const [Province, setProvince] = useState("");
    const [Phone, setPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [status, setStatus] = useState("New");
    const [errorIsOpen, setErrorInOpen, , , togglePopUp] = useErrorPopUp();
    useEffect(() => {
        let formattedDateOfBirth;
        if (DateOfBirth) {
            formattedDateOfBirth = new Date(DateOfBirth).toISOString();
        }
        getAllPatientsInfo(page, rowsPerPage, order, orderBy, LastName, FirstName, formattedDateOfBirth, Province, Phone, Email, status);
    }, [page, rowsPerPage, orderBy, order, LastName, FirstName, DateOfBirth, status, Email, Phone, Province]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            return 0;
        }
        setSelected([]);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;
    const setSearch = (searchValue) => {
        setLastName(searchValue);
    };
    const setPatientStaus = (status) => {
        setStatus(status);
    };
    const setField = (patientFilterfieldsObj) => {
        setLastName(patientFilterfieldsObj?.LastName);
        setFirstName(patientFilterfieldsObj?.FirstName);
        setDateOfBirth(patientFilterfieldsObj?.DateOfBirth);
        setProvince(patientFilterfieldsObj?.Province);
        setPhone(patientFilterfieldsObj?.Phone);
        setEmail(patientFilterfieldsObj?.Email);
    };
    const redirectUserDashboard = (row) => {
        if (row.brandEnrollments[0] == null) {
            setErrorInOpen(true);
            return;
        }
        const LoginAPIResponse = localStorage.getItem('LoginAPIResponse');
        const LoginDatadata = JSON.parse(LoginAPIResponse);
        setAdminAzureIdImpersonate(LoginDatadata?.data?.value?.azureUserInfo?.azureUserId);
        setPatientAzureIdImpersonate(row.azureUserId);
        const holdData = {
            patientId: row.patientId,
            firstName: row.firstName,
            lastName: row.lastName,
        };
        localStorage.setItem('holdDataForReload', JSON.stringify(holdData));
        const data = {
            firstName: row.firstName,
            lastName: row.lastName,
            phoneNumber: row.phoneNumber,
            email: emailImersonate,
            drugBrandCode: row.brandEnrollments[0].drugBrandCode
        };
        const ExistDataForImpersonate = {
            firstName: firstnameImpersonate,
            lastName: lastnameImpersonate,
            phoneNumber: phoneNumberImpersonate,
        };
        setExistingImpersonateInfoAction(ExistDataForImpersonate);
        setImpersonateInfoAction(data);
        setExistingImpersonate(patientId);
        setPatientIdForImpersonate(row.patientId);
        setExistingImpersonatedAccountId(impersonatedAccountId);
        setImpersonatedAccountId(row.accountId);
        navigate('/profile');
        setPatientdataFun({
            patientId: row.patientId,
            firstName: row.firstName,
            lastName: row.lastName
        });
        updateStateIsAdmin(false);
    };
    return (React.createElement(Box, { sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(ErrorPopUp, { isOpen: errorIsOpen, popUpContent: t("invalidPatientConfiguration"), togglePopUp: togglePopUp }),
        React.createElement(Paper, { className: globaltableStyle.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement(EnhancedTableToolbar, { numSelected: selected.length, setSearch: setSearch, setField: setField, getAllPatientsState: getAllPatientsState, setPatientStaus: setPatientStaus }),
            React.createElement(TableContainer, { className: globaltableStyle.borderTopElement },
                React.createElement(Table, { sx: { minWidth: 750 }, "aria-labelledby": "tableTitle", size: dense ? 'small' : 'medium', className: globaltableStyle.tableCustomDesign },
                    React.createElement(EnhancedTableHead, { numSelected: selected.length, order: order, orderBy: orderBy, onSelectAllClick: handleSelectAllClick, onRequestSort: handleRequestSort, rowCount: getAllPatientsState?.rowCount }),
                    " ",
                    getAllPatientsState?.resultItems?.length === 0 ? (React.createElement(TableBody, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 8, align: "center" },
                                " ",
                                t('noRecordFound'),
                                " ")))) : (React.createElement(TableBody, null, getAllPatientsState?.resultItems?.map((row, index) => {
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (React.createElement(TableRow, { hover: true, role: "checkbox", "aria-checked": isItemSelected, tabIndex: -1, key: row.id || index, selected: isItemSelected },
                            React.createElement(TableCell, null,
                                " ",
                                React.createElement("img", { height: "55px", src: NoImage, alt: "" }),
                                " "),
                            React.createElement(TableCell, { align: "left" },
                                row.lastName,
                                " ",
                                row.firstName),
                            React.createElement(TableCell, { align: "left" }, row?.birthDate
                                ? new Date(row?.birthDate).toLocaleDateString('en-GB', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                })
                                : 'Invalid Date'),
                            React.createElement(TableCell, { align: "left" }, row.province),
                            React.createElement(TableCell, { align: "left" }, row.phoneNumber),
                            React.createElement(TableCell, { align: "left" }, row.relationship),
                            React.createElement(TableCell, { align: "left" }, row.email),
                            React.createElement(TableCell, { align: "left" }, row?.brandEnrollments?.map((brand, brandIndex) => (React.createElement("div", { key: brandIndex }, brand?.drugBrandCode)))),
                            React.createElement(TableCell, { align: "left" }, row?.brandEnrollments?.map((brand, brandIndex) => (brand?.LinkedIdentifier ? (React.createElement("div", { key: brandIndex }, brand?.LinkedIdentifier)) : (React.createElement("div", { key: brandIndex }, t('unlinked')))))),
                            React.createElement(TableCell, { align: "left" },
                                React.createElement(SVG, { className: classNames(patientcss.iconButtonLink), height: "24px", onClick: () => redirectUserDashboard(row), src: maskIconSVG }))));
                    }))))),
            React.createElement(TablePagination, { className: globaltableStyle.tableCustomPagination, rowsPerPageOptions: [5, 10, 25], component: "div", count: getAllPatientsState?.rowCount || 0, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelRowsPerPage: t('itemsPerPage') })),
        requestPatientStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        getAllPatientsState: state.patient.allPatientInfo,
        requestPatientStatus: state.patient.requestPatientStatus,
        patientId: state.account.user.patientId,
        impersonatedAccountId: state.account.user.impersonatedAccountId,
        firstnameImpersonate: state.account.patients[0]?.firstName,
        lastnameImpersonate: state.account.patients[0]?.lastName,
        IdImpersonate: state.account.patients[0]?.id,
        phoneNumberImpersonate: state.account.user.phoneNumber,
        emailImersonate: state.account.user.email
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getAllPatientsInfo: (page, rowPerPage, order, orderBy, LastName, FirstName, DateOfBirth, Province, Phone, Email, status) => dispatch(getAllPatientAsyncAction({
            page,
            rowPerPage,
            order,
            orderBy,
            LastName,
            FirstName,
            DateOfBirth,
            Province,
            Phone,
            Email,
            status
        })),
        updateStateIsAdmin: (isAdmin) => dispatch(setisAdminStatus(isAdmin)),
        setPatientdataFun: (data) => dispatch(setPatientdataAction({
            data
        })),
        setPatientIdForImpersonate: (data) => dispatch(setPatientIdForImpersonate(data)),
        setExistingImpersonate: (data) => dispatch(setExistingImpersonate(data)),
        setImpersonatedAccountId: (data) => dispatch(setImpersonatedAccountId(data)),
        setExistingImpersonatedAccountId: (data) => dispatch(setExistingImpersonatedAccountId(data)),
        setImpersonateInfoAction: (data) => dispatch(setImpersonateInfo(data)),
        setExistingImpersonateInfoAction: (data) => dispatch(setExistingImpersonateInfo(data)),
        setAllDataImpersonateAction: (data) => dispatch(setAlldataImpersonate(data)),
        setAdminAzureIdImpersonate: (data) => dispatch(setAdminAzureIdImpersonateAction(data)),
        setPatientAzureIdImpersonate: (data) => dispatch(setPaientAzureIdImpersonateAction(data))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Patients);
