import ApiManager from "../api/ApiManager";
const version = "1.2";
const ADDRESS = `/v${version}/patient/address`;
class PatientAddressRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new PatientAddressRepository();
        }
        return this.instance;
    }
    static getAddresses = (patientId) => {
        return ApiManager.getInstance().get(ADDRESS, { patientId });
    };
    static addAddress = (data) => {
        return ApiManager.getInstance().post(ADDRESS, data);
    };
    static editAddress = (data) => {
        return ApiManager.getInstance().put(ADDRESS, data);
    };
    static deleteAddress = (addressId) => {
        return ApiManager.getInstance().delete(ADDRESS, { addressId });
    };
}
export default PatientAddressRepository;
