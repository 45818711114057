import React from 'react';
import s from './style.scss';
import { editReminderAsyncAction } from '../../../../store/medicalReminders';
import SVG from "react-inlinesvg";
import Notification from "../../../../resources/icons/notification.svg";
import Switch from '../../../../components/Shared/Controls/Inputs/Switch';
import { connect } from 'react-redux';
import moment from 'moment';
import Edit from "../../../../resources/icons/edit";
import Delete from "../../../../resources/icons/trash_full.svg";
import { useTranslation } from 'react-i18next';
import colors from '../../../../resources/colors';
import { Box } from '../../../../components';
import Typography from '../../../../components/Shared/Typography';
const ReminderCard = ({ style, patientId, reminder, showDeletePopUp, editReminder, setEditingReminder, }) => {
    const { t } = useTranslation();
    const toggleReminder = (id) => {
        const newReminder = {
            ...reminder,
            isActive: !reminder.isActive
        };
        editReminder(newReminder);
    };
    const reminderLabel = () => {
        const medicationTimes = " @ " + reminder.medicationTimes?.join(', ');
        switch (reminder.repeatType) {
            case "SpecificDaysOfTheWeek":
                return t("each") + " " + reminder.daysOfTheWeek?.join(', ').toLowerCase() + medicationTimes;
            case "EveryDay":
                return t("daily") + medicationTimes;
            case "RepeatEveryXDays":
                return `${t("every")} ${reminder.repeatIntervalInDays} ${t("days")}` + medicationTimes;
            default: return moment(reminder.startDate).format("MMM/DD") + " @ " + reminder.medicationTimes?.join(', ');
        }
    };
    const computeNextOccurrence = (item) => {
        // Extract information from item
        const { medicationTimes, repeatType, daysOfTheWeek, startDate, endDate, repeatIntervalInDays } = item;
        // Convert dates and times to moment objects
        const medicationMomentTime = moment(medicationTimes[0], 'hh:mm A');
        const startMomentDate = moment(startDate);
        const endMomentDate = moment(endDate);
        const now = moment();
        // Make a copy of start date
        let nextOccurrenceMoment = startMomentDate.clone();
        // Adjust the time component of nextOccurrenceMoment to match medicationTimes[0]
        // to consider cases where start time is less than medication time
        nextOccurrenceMoment.hour(medicationMomentTime.hour()).minute(medicationMomentTime.minute());
        const medicationDates = medicationTimes.map(time => moment(time, "hh:mm A"));
        const nextTimeToday = medicationDates.find(time => time.isAfter(now));
        const firstTime = moment(medicationTimes[0], 'HH:mm');
        // If startDate is in the past, calculate next occurrence based on repeatType
        if (now.isAfter(nextOccurrenceMoment)) {
            switch (repeatType) {
                case "SingleReminder":
                    return "N/A";
                case "EveryDay":
                    if (!!nextTimeToday) {
                        nextOccurrenceMoment = moment().hour(nextTimeToday.hours()).minute(nextTimeToday.minutes());
                    }
                    else {
                        nextOccurrenceMoment = moment().startOf('day').add(1, 'day').add({ hours: medicationDates[0].hours(), minutes: medicationDates[0].minutes() });
                    }
                    break;
                case "RepeatEveryXDays":
                    if (!repeatIntervalInDays)
                        return "Next occurrence calculation error";
                    const daysPassed = now.diff(startMomentDate, 'days');
                    const nextIntervalDay = now.clone().add(daysPassed % repeatIntervalInDays, 'day');
                    nextOccurrenceMoment = nextIntervalDay.clone().hour(firstTime.get('hour')).minute(firstTime.get('minute'));
                    if (nextOccurrenceMoment.isSame(now, 'day') && !!nextTimeToday) {
                        nextOccurrenceMoment = nextIntervalDay.clone().hour(nextTimeToday.get('hour')).minute(nextTimeToday.get('minute'));
                    }
                    else if (nextOccurrenceMoment.isSame(now, 'day')) {
                        const nextNextIntervalDay = nextIntervalDay.clone().add(repeatIntervalInDays, 'day');
                        nextOccurrenceMoment = nextNextIntervalDay.hour(firstTime.hour()).minute(firstTime.minute());
                    }
                    break;
                case "SpecificDaysOfTheWeek":
                    if (!daysOfTheWeek)
                        return "Next occurrence calculation error.";
                    const WEEKDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                    const currentDayIndex = now.day() === 0 ? 6 : now.day() - 1;
                    const daysOfWeekIndexes = daysOfTheWeek.map(day => WEEKDAYS.indexOf(day));
                    const nextDayIndex = daysOfWeekIndexes.find(index => index >= currentDayIndex);
                    if (nextDayIndex === undefined || (nextDayIndex == currentDayIndex && nextTimeToday == null)) {
                        nextOccurrenceMoment = now.clone().startOf('day').add(daysOfWeekIndexes[0] + 7 - currentDayIndex, 'days').hour(firstTime.get('hour')).minute(firstTime.get('minute'));
                    }
                    else if (nextDayIndex == currentDayIndex && nextTimeToday != null) {
                        nextOccurrenceMoment = now.clone().startOf('day').hour(nextTimeToday.get('hour')).minute(nextTimeToday.get('minute'));
                    }
                    else {
                        nextOccurrenceMoment = now.clone().startOf('day').add(nextDayIndex - currentDayIndex, 'days').hour(firstTime.get('hour')).minute(firstTime.get('minute'));
                    }
                    break;
            }
        }
        // If endDate is in the past, or nextOccurrenceMoment is in the past, there is N/A
        if (now.isAfter(endMomentDate.endOf('day')) || nextOccurrenceMoment.isAfter(endMomentDate.endOf('day'))) {
            return "N/A";
        }
        if (nextOccurrenceMoment) {
            // nextOccurrenceMoment.hour(medicationMomentTime.hour());
            // nextOccurrenceMoment.minute(medicationMomentTime.minute());
            if (now.isSame(nextOccurrenceMoment, 'day')) {
                return "Today @ " + nextOccurrenceMoment.format('hh:mm A');
            }
            else {
                return nextOccurrenceMoment.format('MMM/DD @ hh:mm A');
            }
        }
        else {
            return "N/A";
        }
    };
    return (React.createElement("div", { key: reminder.id, className: s.reminderWrapper },
        React.createElement(Box, { f1: true, column: true, ph: 32, pv: 34, gap: 24 },
            React.createElement(Box, { f1: true, row: true, justifyBetween: true, height: 22 },
                React.createElement(Box, { row: true, f2: true, gap: 12, alignCenter: true },
                    React.createElement(Switch, { isSwitched: reminder.isActive, onSwitch: () => toggleReminder(reminder.id) }),
                    React.createElement(Typography, { type: 'header_3', color: 'neutralDark', bold: true }, reminder.title)),
                React.createElement(Box, { f1: true, row: true, gap: 23, justifyEnd: true },
                    React.createElement(Edit, { color: colors.neutralDark, onClick: () => {
                            setEditingReminder(reminder);
                        }, className: s.edit }),
                    React.createElement(SVG, { onClick: () => showDeletePopUp(reminder.id), className: s.delete, src: Delete }))),
            React.createElement(Box, { f1: true, row: true, height: 24 },
                React.createElement(Box, { f1: true, height: 24, width: 24 },
                    React.createElement(SVG, { src: Notification })),
                React.createElement(Typography, { type: 'paragraph', color: 'neutralDark' }, reminderLabel())),
            React.createElement(Box, { f1: true, row: true, height: 24, justifyBetween: true },
                React.createElement(Typography, { type: 'paragraph', color: 'neutralDark', bold: true },
                    t("nextOccurence"),
                    ":  ",
                    computeNextOccurrence(reminder))),
            React.createElement(Box, { f1: true, row: true, height: 24, justifyBetween: true },
                React.createElement(Box, null,
                    React.createElement(Typography, { type: 'paragraph', color: 'neutralGray' },
                        t("type"),
                        ":"),
                    React.createElement("br", null),
                    React.createElement(Typography, { type: 'paragraph' }, t(reminder.reminderType))),
                React.createElement(Box, null,
                    React.createElement(Typography, { type: 'paragraph', color: 'neutralGray' },
                        t("startDate"),
                        ": "),
                    React.createElement("br", null),
                    React.createElement(Typography, { type: 'paragraph' }, moment(reminder.startDate).format("MMM DD, YYYY"))),
                (reminder.repeatType == 'RepeatEveryXDays' || reminder.repeatType == 'EveryDay' || reminder.repeatType == 'SpecificDaysOfTheWeek')
                    && React.createElement(Box, null,
                        React.createElement(Typography, { type: 'paragraph', color: 'neutralGray' },
                            t("endDate"),
                            ":"),
                        React.createElement("br", null),
                        React.createElement(Typography, { type: 'paragraph' }, moment(reminder.endDate).format("MMM DD, YYYY")))))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId
    };
};
function mapDispatchToProps(dispatch) {
    return {
        editReminder: (reminder) => dispatch(editReminderAsyncAction(reminder)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ReminderCard);
