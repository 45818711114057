import { Component } from 'react';
import React from "react";
function SpanIntro() {
    return (React.createElement("span", null,
        React.createElement("a", { href: '#section-introduction' }, "Enter API Key"),
        " to see examples"));
}
class ScriptLoaded extends Component {
    interval;
    constructor(props) {
        super(props);
        this.state = {
            scriptLoaded: !!window.google,
        };
        this.interval = window.setInterval(this.checkIfScriptLoaded, 200);
    }
    setScriptLoadedCallback = () => {
        if (this.state.scriptLoaded) {
            window.clearInterval(this.interval);
        }
    };
    checkIfScriptLoaded = () => {
        if (window.google) {
            this.setState((prevState) => ({
                ...prevState,
                scriptLoaded: true,
            }), this.setScriptLoadedCallback);
        }
    };
    componentWillUnmount() {
        window.clearInterval(this.interval);
    }
    render() {
        if (!this.state.scriptLoaded) {
            return React.createElement(SpanIntro, null);
        }
        const { children } = this.props;
        return typeof children === 'function' ? children() : children;
    }
}
export default ScriptLoaded;
