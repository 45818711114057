import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageBody from "../../../../components/Shared/PageBody";
import { useErrorPopUp, useInput } from "../../../../hooks";
import { Button, InputPassword, } from "../../../../components/Shared/Controls";
import { resetRequestChangePhoneCheckAction, } from "../../../../store";
import { changePhoneRequestAsyncAction } from "../../../../store/auth/actions";
import s from "./style.scss";
import controlCss from "../../../../Components/Shared/Controls/Inputs/styles.scss";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import colors from "../../../../resources/colors";
const NewPhone = ({ setStep, changePhone, changePhoneStatus, changePhoneError, renderPaggination, resetChangePhoneStatus, backAction, }) => {
    const { t } = useTranslation();
    const [confirmPassword, setConfirmPassword, errorConfirmPassword, setErrorConfirmPassword,] = useInput();
    const [isOpen, showPopUp, errorContent, setErrorContent, togglePopUp] = useErrorPopUp();
    const [phone, setPhone] = useState('');
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput();
    const handlePhoneInputChange = (value, info) => {
        setPhone(value);
        setPhoneNumber(info.nationalNumber);
        setErrorPhoneNumber(!matchIsValidTel(value));
        console.log(errorPhoneNumber);
    };
    const onButtonSubmit = () => {
        changePhone(phoneNumber, confirmPassword);
    };
    useEffect(() => {
        changePhoneStatus === "success" && setStep(2);
    }, [changePhoneStatus]);
    useEffect(() => {
        setErrorContent(changePhoneError?.message || "");
        changePhoneError && showPopUp(true);
    }, [changePhoneError]);
    useEffect(() => {
        isOpen === false && resetChangePhoneStatus();
    }, [isOpen]);
    return (React.createElement(PageBody, { backAction: backAction, title: t("changePhoneNumber") },
        React.createElement("div", { className: s.scrollWrapper },
            renderPaggination,
            React.createElement("div", { className: s.confirmMessage }, t("enterPasswordAndNewPhone").split('\n').map((el, index) => React.createElement("p", { key: index }, el))),
            React.createElement("div", { className: s.pageWrapper },
                React.createElement("div", null,
                    React.createElement(InputPassword, { name: "confirmPassword", placeholder: t("yourPassword"), setError: setErrorConfirmPassword, password: confirmPassword, setPassword: setConfirmPassword, error: confirmPassword && errorConfirmPassword, tabIndex: 1 })),
                React.createElement("div", { className: controlCss.inputGroup },
                    React.createElement(MuiTelInput, { name: "phone", value: phone, onChange: handlePhoneInputChange, 
                        // error={errorPhoneNumber}
                        defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 2 }))),
            React.createElement(Button, { style: s.button, disabled: !phoneNumber ||
                    !confirmPassword ||
                    errorPhoneNumber ||
                    errorConfirmPassword, primary: true, text: t("confirmAndProceed"), onClick: onButtonSubmit }))));
};
const mapStateToProps = (state) => {
    return {
        userEmail: state.account.user.email,
        error: state.account.requestUpdateEmailError,
        userId: state.account.user.patientId,
        changePhoneStatus: state.auth.requestChangePhoneCheckStatus,
        changePhoneError: state.auth.requestChangePhoneCheckError,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        changePhone: (phoneNumber, password) => dispatch(changePhoneRequestAsyncAction({ phoneNumber, password })),
        resetChangePhoneStatus: () => dispatch(resetRequestChangePhoneCheckAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewPhone);
