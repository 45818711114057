import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import PageContainer from "../../components/Shared/PageContainer";
import { getNotificationsAsyncAction, markViewedNotificationsAsyncAction, resetRequesNotificationsStatus, } from "../../store";
import { allNotificationsSelector } from "../../store/notifications/selectors";
import Notification from "../../resources/icons/notification.svg";
import s from "./styles.scss";
import { useDeviceHeight } from "../../hooks";
import { useTranslation } from "react-i18next";
const PAGE_SIZE = 20;
const Notifications = ({ getNotifications, notifications, markViewed, patientId, notificationsStatus, resetNotifications, }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [allNotifications, setAllNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useDeviceHeight("notificationList", 120, 120);
    useEffect(() => {
        setPage(1);
        setAllNotifications([]);
        getNotifications(page, PAGE_SIZE);
        return () => {
            markViewed();
        };
    }, [patientId]);
    const loadMore = () => {
        if (!isLoading) {
            const nextPage = page + 1;
            getNotifications(nextPage, PAGE_SIZE);
            setIsLoading(true);
            setPage(nextPage);
        }
    };
    useEffect(() => {
        if (notificationsStatus === "success") {
            setAllNotifications(allNotifications.length > 0
                ? [...allNotifications, ...notifications]
                : notifications);
            setIsLoading(false);
            resetNotifications();
        }
    }, [notificationsStatus]);
    return (React.createElement(PageContainer, null,
        React.createElement("div", { id: "notificationList", className: s.notificationList },
            React.createElement(InfiniteScroll, { dataLength: page * 20, next: loadMore, hasMore: notifications.length >= PAGE_SIZE, loader: React.createElement("div", { className: "loader" }, "Loading ..."), scrollableTarget: "notificationList" }, allNotifications &&
                allNotifications.map((notification) => {
                    return (React.createElement("div", { key: notification.id, className: classNames(s.notificationItem, {
                            [s.unread]: !notification.viewed,
                        }) },
                        React.createElement("div", { className: s.notificationItemIcon },
                            React.createElement(SVG, { src: Notification })),
                        React.createElement("div", { className: s.notificationItemContent },
                            React.createElement("span", null, moment.utc(notification.createdDate)
                                .local()
                                .format("LLL")
                                .toUpperCase()),
                            React.createElement("p", null,
                                notification.text,
                                React.createElement("span", null,
                                    " ",
                                    notification.title)))));
                })),
            allNotifications.length < 1 && (React.createElement("div", { className: s.empty },
                React.createElement("p", null, t("thereIsNoNotifications")),
                React.createElement("p", null, t("weWillNotifyYou")))))));
};
const mapStateToProps = (state) => {
    return {
        notifications: allNotificationsSelector(state),
        patientId: state.account.user.patientId,
        notificationsStatus: state.notifications.requestStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getNotifications: (pageNumber, pageSize) => dispatch(getNotificationsAsyncAction({ pageNumber, pageSize })),
        markViewed: () => dispatch(markViewedNotificationsAsyncAction()),
        resetNotifications: () => dispatch(resetRequesNotificationsStatus()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
