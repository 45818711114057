import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import config from "../../../../config.json";
import colors from '../../../resources/colors';
import images from '../../../resources/images';
import s from './style.scss';
const Banner = () => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.banner, style: {
            background: config.design_system.background.backgroundColorGradient
                ? `linear-gradient(273.04deg, ${colors.backgroundGradient.from} 0%, ${colors.backgroundGradient.to} 99.08%)`
                : undefined
        } },
        React.createElement("div", { className: s.bannerLogo },
            React.createElement("span", { className: s.headline_2 }, t(config.banner_title).split('\n').map((str, index) => React.createElement("div", { key: index }, str))),
            React.createElement("img", { src: i18next.language == "en" ? images.screenImage.en : images.screenImage.fr, className: s.devices }),
            i18next.language === "en"
                ? (React.createElement("img", { src: images.Logox4, className: s.devices }))
                : (React.createElement("img", { src: images.Logox4Fre, className: s.devices })),
            React.createElement("span", { className: s.headline_2 }, t("welcomeSublilte")),
            React.createElement("div", { className: classNames(s.badges, s.inline, s.fs18) }))));
};
export default Banner;
