import React, { useEffect } from "react";
import PageBody from "../../../../../components/Shared/PageBody";
import style from "../../../style.scss";
import { Button, InputPassword, } from "../../../../../components/Shared/Controls";
import { useInput, usePrevious } from "../../../../../hooks";
import { resetRequestValidatePasswordAction, } from "../../../../../store";
import { connect } from "react-redux";
import { validatePasswordAsyncAction } from "../../../../../store/auth/actions";
import { useTranslation } from "react-i18next";
const VerifyPassword = ({ setStep, validatePassword, passwordStatus, passwordError, renderPaggination, resetPasswordStatus, backAction, userPassword, }) => {
    const { t } = useTranslation();
    const passwordStatusPrev = usePrevious(passwordStatus);
    const [confirmPassword, setConfirmPassword, errorConfirmPassword, setErrorConfirmPassword,] = useInput();
    const onSubmit = () => {
        validatePassword(confirmPassword);
    };
    useEffect(() => {
        passwordStatusPrev !== undefined && passwordStatus === "success" && setStep(2);
    }, [passwordStatus]);
    return (React.createElement(PageBody, { backAction: backAction, title: t("changePassword") },
        React.createElement("div", { className: style.scrollWrapper },
            renderPaggination,
            React.createElement("p", { className: style.confirmMessage }, t("enterPasswordToVerify")),
            React.createElement("div", { className: style.pageWrapper },
                React.createElement("div", null,
                    React.createElement(InputPassword, { name: "confirmPassword", placeholder: t("enterYourPassword"), pattern: {
                            rule: "[A-Za-z0-9!@#$%^&*()_+=-]{8,}",
                            listOnConditions: [
                                "(?=.*[a-z])[a-z]",
                                "(?=.*[A-Z])[A-Z]",
                                "(?=.*[0-9])[0-9]",
                                "(?=.*[!@#$%^&*()_+=-])[!@#$%^&*()_+=-]",
                            ],
                            countOfConditions: 3,
                        }, setError: setErrorConfirmPassword, errorMessage: t("requirementsToPassword"), password: confirmPassword, setPassword: setConfirmPassword, error: confirmPassword && errorConfirmPassword }))),
            React.createElement(Button, { style: style.button, disabled: !confirmPassword
                    || errorConfirmPassword, primary: true, text: t("confirmAndProceed"), onClick: onSubmit }))));
};
const mapStateToProps = (state) => {
    return {
        passwordStatus: state.auth.requestValidatePasswordStatus,
        passwordError: state.auth.requestValidatePasswordError,
        userPassword: state.account.user.password,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        validatePassword: (password) => dispatch(validatePasswordAsyncAction(password)),
        resetPasswordStatus: () => dispatch(resetRequestValidatePasswordAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyPassword);
