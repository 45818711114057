import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { InputText } from "../../../../components/Shared/Controls";
import { useInput } from "../../../../hooks";
import { getSettingsInfoAsyncAction, resetRequestChangePasswordAction, resetRequestChangePhoneAction, resetRequestChangePhoneCheckAction, resetRequestSettingsInfoAction, resetRequestUpdateEmailAction, resetRequestValidatePasswordAction, updateEmailAsyncAction, } from "../../../../store";
import s from "./style.scss";
import { verifyEmailAsyncAction } from "../../../../store/auth/actions";
const Account = ({ setPage, updateEmail, userEmail, userPhone, emailError, reset, requestUpdateEmailStatus, requestValidateEmailStatus, getSettings, getSettingsInfoStatus, getSettingsInfoError, verifyEmail, }) => {
    const { t } = useTranslation();
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput(userPhone);
    const [email, setEmail, errorEmail, setErrorEmail] = useInput(userEmail);
    useEffect(() => {
        getSettings();
    }, []);
    useEffect(() => {
        setEmail(userEmail);
        setPhoneNumber(userPhone);
    }, [userEmail, userPhone]);
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: s.title }, t("accountData")),
        React.createElement("div", { className: s.pageWrapper },
            React.createElement(InputText, { name: "phone", label: t("phoneNumber"), value: phoneNumber, setValue: () => { }, pattern: "^[0-9]{9,10}$", errorMessage: t("errorIncorrectPhoneNumber"), error: errorPhoneNumber, setError: setErrorPhoneNumber, length: 10, isNumber: true, withLink: true, clearCross: false, onLinkClick: () => setPage(2), disabled: true, style: s.emailInput }),
            React.createElement(InputText, { name: "email", label: t("email"), errorMessage: t("errorIncorrectEmail"), pattern: "^([\\w\\.\\+]{1,})([^\\W])(@)([\\w]{1,})(\\.[\\w]{1,})+$", value: email, setValue: setEmail, error: email && errorEmail, setError: setErrorEmail, clearCross: false, disabled: true, withLink: true, onLinkClick: () => setPage(3) }))));
};
const mapStateToProps = (state) => {
    return {
        userEmail: state.account.user.email,
        emailError: state.account.requestUpdateEmailError,
        userPhone: state.account.user.phoneNumber,
        requestUpdateEmailStatus: state.account.requestUpdateEmailStatus,
        getSettingsInfoStatus: state.account.requestSettingsInfoStatus,
        getSettingsInfoError: state.account.requestSettingsInfoError,
        requestValidateEmailStatus: state.auth.requestValidateEmailStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        updateEmail: (email) => dispatch(updateEmailAsyncAction(email)),
        getSettings: () => dispatch(getSettingsInfoAsyncAction()),
        verifyEmail: (email) => dispatch(verifyEmailAsyncAction(email)),
        reset: () => {
            dispatch(resetRequestChangePhoneAction());
            dispatch(resetRequestChangePhoneCheckAction());
            dispatch(resetRequestValidatePasswordAction());
            dispatch(resetRequestChangePasswordAction());
            dispatch(resetRequestUpdateEmailAction());
            dispatch(resetRequestSettingsInfoAction());
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);
