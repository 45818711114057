import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import { Button } from "../../../../../../../../components/Shared/Controls";
import { assignToBrandAsyncAction, brandsBySearchKeySelector, getRegistrationInfoAsyncAction, resetRequestAssignToBrandAction, setBrandAction, } from "../../../../../../../../store";
import LoaderIcon from "../../../../../../../../resources/icons/icon-loader.svg";
import s from "./style.scss";
import BrandCard from "../../../../../../../../components/Shared/BrandCard";
import SearchInput from "../../../../../../../../components/Shared/Controls/Inputs/SearchInput";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDeviceHeight } from "../../../../../../../../hooks";
import useBrandSearch from "../../../../../../../../hooks/useBrandSearch";
const Step1 = ({ userId, isTestBrands, setBrand, brands, assignToBrand, getRegistrationInfo, userBrands, requestAssignToBrandStatus, nextPage, reset, page, }) => {
    const { t } = useTranslation();
    const [selectedBrand, selectBrand] = useState("");
    const search = useBrandSearch(isTestBrands);
    useDeviceHeight("brandList", 460, 460, 440);
    useEffect(() => {
        if (userId && requestAssignToBrandStatus === "success") {
            getRegistrationInfo({
                member: {
                    patientId: userId,
                    drugBrandCode: selectedBrand,
                },
                isRegistrationFlow: false,
            });
            reset();
            nextPage();
        }
    }, [requestAssignToBrandStatus]);
    const onSelect = () => {
        selectedBrand && setBrand(selectedBrand) && assignToBrand(selectedBrand);
    };
    const searchBrandRender = () => {
        return (React.createElement("div", { className: classNames(s.searchForm) },
            React.createElement(SearchInput, { style: s.search, value: search.searchTerm, setValue: search.setSearchTerm })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.title },
            t("selectBrand"),
            React.createElement("span", null, `${page} / 5`)),
        React.createElement("div", { className: s.header }, searchBrandRender()),
        React.createElement("div", null,
            React.createElement("div", { id: "brandList", className: s.brandListScroll },
                React.createElement("div", { className: s.brandList }, brands && brands[0] ? (brands
                    .filter((brand) => !userBrands
                    .map((brand) => brand.drugBrandCode)
                    .includes(brand.drugBrandCode))
                    .map((el) => (React.createElement(BrandCard, { key: el.drugBrandCode, active: selectedBrand === el.drugBrandCode, url: el.logoLink, onClick: () => selectedBrand != el.drugBrandCode
                        ? selectBrand(el.drugBrandCode)
                        : selectBrand("") })))) : (React.createElement("div", { className: s.loading }, search.searchTerm ? (React.createElement("div", { className: s.subheading_1 }, t("noResultsFound"))) : (React.createElement(SVG, { src: LoaderIcon, className: s.loaderIcon })))))),
            React.createElement("div", { className: s.buttonsWrapper },
                React.createElement(Button, { style: s.mb0_button, disabled: !selectedBrand, primary: true, text: t("select"), onClick: onSelect })))));
};
const mapStateToProps = (state) => {
    return {
        userId: state.account.user.patientId,
        brands: brandsBySearchKeySelector(state, state.brands.searchValue),
        userBrands: state.account.patientBrands,
        requestAssignToBrandStatus: state.account.requestAssignToBrandStatus,
        requestAssignToBrandError: state.account.requestAssignToBrandError,
        isTestBrands: state.brands.isTestBrands,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setBrand: (brandId) => dispatch(setBrandAction(brandId)),
        assignToBrand: (drugBrandCode) => dispatch(assignToBrandAsyncAction(drugBrandCode)),
        getRegistrationInfo: (payload) => dispatch(getRegistrationInfoAsyncAction(payload)),
        reset: () => dispatch(resetRequestAssignToBrandAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Step1);
