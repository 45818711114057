import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import HelpInfo from "../../../../components/Shared/PopUps/HelpInfo";
import Sign from "../../../../resources/images/sign.svg";
import SignAccent from "../../../../resources/images/signAccent.svg";
import s from "./style.scss";
import StatusDisplay from "../../../../components/StatusDisplay";
import Banner from "../../../components/Banner";
import Header from "../../../components/Header";
import Typography from "../../../../components/Shared/Typography";
import InvitationStep1 from "./Steps/ValidateInviteCredentials";
import InvitationStep2 from "./Steps/InvitationTwoFactorEnrollment";
import Step2 from "../../../ResetPassword/Steps/Step2";
import Step3 from "../../../ResetPassword/Steps/Step3";
import SignIn from "../../../SingIn";
import { resetLengthStepsToComplete } from "../../../../store";
import { connect } from "react-redux";
const additionalSteps = [
    {
        "Step": "AboutYourPage",
        "StepNo": 6
    },
    {
        "Step": "AddInsurer",
        "StepNo": 9
    },
    {
        "Step": "AddHealthCard",
        "StepNo": 11
    },
    {
        "Step": "Prescriber",
        "StepNo": 12
    },
    {
        "Step": "InfusionClinic",
        "StepNo": 13
    },
    {
        "Step": "Pharmacy",
        "StepNo": 14
    },
    {
        "Step": "ScheduleCall",
        "StepNo": 18
    }
];
const InvitationEmail = ({ resetLengthStepsToCompleteAction, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedLang, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
    const [step, setStep] = useState(1);
    const [haveChanges, setHaveChanges] = useState(false);
    const storeStepsToCompleteData = localStorage.getItem('storeStepsToCompleteData');
    const nextStep = (defaultStep) => {
        const stepsToCompleteData = JSON.parse(storeStepsToCompleteData);
        if (stepsToCompleteData?.length > 0) {
            const FilterItems = additionalSteps.filter(step => {
                return stepsToCompleteData.some((item) => item.step === step.Step);
            });
            const FilteredItems = FilterItems.filter(n => n.StepNo >= defaultStep);
            FilterItems.sort((a, b) => a.StepNo - b.StepNo);
            if (FilteredItems.length == 0) {
                navigate('/');
            }
            const MatchingSteps = FilteredItems[0];
            if (!!MatchingSteps) {
                setStep(MatchingSteps.StepNo);
            }
            else {
                setStep(defaultStep);
            }
        }
        // For Invite steps 
        else {
            setStep(defaultStep);
            navigate('/');
        }
    };
    // useEffect(() => {
    //     const stepsToCompleteData=JSON.parse(storeStepsToCompleteData);
    //     if (stepsToCompleteData?.length > 0) {
    //       const FilterItems = additionalSteps.filter(step => {
    //         return stepsToCompleteData.some((item: any) => item.step === step.Step)
    //       });
    //       FilterItems.sort((a: any, b: any) => a.StepNo - b.StepNo);
    //       const MatchingSteps = FilterItems[0];
    //         if (!MatchingSteps || !stepsToCompleteData) {
    //           resetLengthStepsToCompleteAction();
    //           navigate('/');
    //         } else {
    //           setStep(MatchingSteps.StepNo);
    //         }
    //     }
    // }, [])
    const renderSigns = () => (React.createElement(React.Fragment, null,
        React.createElement(SVG, { src: Sign, className: s.signIconN1 }),
        React.createElement(SVG, { src: Sign, className: s.signIconN2 }),
        React.createElement(SVG, { src: Sign, className: s.signIconN3 }),
        React.createElement(SVG, { src: Sign, className: s.signIconN6 }),
        React.createElement(SVG, { src: SignAccent, className: s.signIconN4 }),
        React.createElement(SVG, { src: SignAccent, className: s.signIconN5 })));
    const renderStepHeader = (backAction) => (React.createElement("div", { className: s.header },
        React.createElement("div", { className: s.pages },
            React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `${step}/15`))));
    const renderResetPasswordSteps = () => {
        switch (step) {
            case 1:
                return (React.createElement(InvitationStep1, { setStep: setStep, header: renderStepHeader(() => navigate("/")), previousStep: () => navigate("/") }));
            case 2:
                return (React.createElement(InvitationStep2 // TODO: rename to TwoFactorRegistration
                , { setStep: setStep, header: renderStepHeader(() => setStep(1)), previousStep: () => setStep(1) }));
            case 3:
                return (React.createElement(Step2 //TODO: Rename to PhoneVerification
                , { setStep: setStep, header: renderStepHeader(() => setStep(1)), previousStep: () => setStep(2), isNewSignUp: true }));
            case 4:
                return (React.createElement(Step3 //TODO: Rename to UpdatePassword
                , { setStep: setStep, header: renderStepHeader(() => setStep(2)), previousStep: () => setStep(2), isNewSignUp: true }));
            case 5:
                return (React.createElement(SignIn, { setStep: setStep, isNewSignUp: true }));
            default:
                navigate('/');
                return null;
            // case 6:
            //   return (
            //     <AboutYouPage isNewSignUp={true} setStep={nextStep} prevStep={() => setStep(5)} existAzureUser={false} />
            //   );
            // case 7:
            //   return (
            //     <HealthInformationPage isNewSignUp={true} setStep={nextStep} prevStep={() => setStep(6)} />
            //   );
            // case 8:
            //   return (
            //     <AddressNewSignUp prevStep={() => setStep(7)} isNewSignUp={true} setStep={nextStep} />
            //   );
            // case 9:
            //   return (
            //     <Insurances setHaveChanges={setHaveChanges} prevStep={() => setStep(8)} setStep={nextStep} isNewSignUp={true} >
            //       {
            //         config.design_system.showDescriptionOfFeature && <div className={s.paragraph_2}>{t("uploadPhotosInsurance")}</div>
            //       }
            //     </Insurances>
            //   );
            // case 11:
            //   return (
            //     <HealthCard isNewSignUp={true} setStep={nextStep} setHaveChanges={setHaveChanges} prevStep={() => setStep(9)} />
            //   )
            // case 12:
            //   return (
            //     <AddPrescriber isUserDashboard={false} setStep={nextStep} isNewSignUp={true} prevStep={() => setStep(11)} />
            //   )
            // case 13:
            //   return (
            //     <AddInfusionClinic isNewSignUp={true} setStep={nextStep} previousStep={() => setStep(12)} />
            //   )
            // case 14:
            //   return (
            //     <SelectPharmacy setStep={setStep} previousStep={() => setStep(13)} isNewSignUp={true} />
            //   )
            // case 15:
            //   return (
            //     <AddPharmacyList isNewSignUp={true} setStep={nextStep} previousStep={() => setStep(14)} />
            //   )
            // case 16:
            //   return (
            //     <AddedPharmacy setStep={nextStep} previousStep={() => setStep(14)} isNewSignUp={true} />
            //   )
            // case 17:
            //   return (
            //     <AddPharmacy setStep={nextStep} isNewSignUp={true} previousStep={() => setStep(14)} />
            //   )
            // case 18:
            //   return (
            //       <AddPharmacyManual isNewSignUp={true} setStep={nextStep} previousStep={() => setStep(14)} />
            //   )
            // case 19:
            //   return (
            //     <CallPreferences />
            //   )
        }
    };
    const [infoPopUp, showInfoPopUp] = useState(false);
    return (React.createElement("div", null,
        React.createElement(StatusDisplay, null),
        React.createElement(HelpInfo, { isOpen: infoPopUp, setIsOpen: showInfoPopUp }),
        React.createElement(Banner, null),
        React.createElement("div", { className: s.resetPassword },
            React.createElement(Header, null),
            renderResetPasswordSteps())));
};
const mapStateToProps = (state) => {
    return {
        isAdmin: state.account.isAdmin,
        isAdminAuth: state.auth.isAdmin,
        // stepsToCompleteData:state.auth.stepsToCompleteData
    };
};
function mapDispatchToProps(dispatch) {
    return {
        resetLengthStepsToCompleteAction: () => dispatch(resetLengthStepsToComplete())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(InvitationEmail);
