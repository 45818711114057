import React from "react";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import HelpIcon from "../../../../resources/icons/help_icon.svg";
import PhoneIcon from "../../../../resources/icons/help";
import config from "../../../../../config.json";
import colors from "../../../../resources/colors";
import s from "./style.scss";
const ChatHeader = ({ programLogo }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: s.chatHeader },
        config.design_system.chat.header.showTitle ?
            React.createElement("div", { className: s.companyHelp },
                React.createElement("div", null,
                    React.createElement(SVG, { src: HelpIcon })),
                React.createElement("h3", null,
                    React.createElement("img", { src: programLogo, className: s.programLogo }),
                    config.design_system.chat.header.title))
            : React.createElement("div", { className: s.subheading_2 }, t("wouldYouPreferToSpeak")),
        React.createElement("a", { href: "tel:" + config.design_system.chat.supportPhoneNumber.replace("-", ""), className: s.call, style: {
                color: colors.controlsGeneralColor
            } },
            config.design_system.chat.header.showTitle && React.createElement("p", null, t("wouldYouPreferToSpeak")),
            React.createElement("div", null,
                React.createElement(PhoneIcon, { color: colors.controlsGeneralColor }),
                React.createElement("span", { className: s.phoneNumber, style: {
                        color: colors.controlsGeneralColor
                    } }, config.design_system.chat.supportPhoneNumber),
                React.createElement("span", { className: s.callUs, style: {
                        color: colors.controlsGeneralColor
                    } }, t("callUs"))))));
};
const mapStateToProps = (state) => {
    return {
        programLogo: state.account.currentProgramLogo,
    };
};
export default connect(mapStateToProps)(ChatHeader);
