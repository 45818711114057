import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { resetRequestUpdateEmailAction, resetRequestUpdatePatientInfoAction, setUserInfoAction, updateEmailAsyncAction, updatePatientInfoAsyncAction } from "../../../../store";
import { Button, InputText } from "../../../../components/Shared/Controls";
import { Notification, PopUp } from "../../../../components/Shared/Info";
import InputDate from "../../../../components/Shared/Controls/Inputs/InputDate";
import NotAllowedImg from '../../../../resources/images/stop_sign_icon.png';
import useInput from "../../../../hooks/useInput";
import Typography from "../../../../components/Shared/Typography";
import s from './style.scss';
import si from "../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import classNames from "classnames";
import { verifyEmailAsyncAction } from "../../../../store/auth/actions";
import { usePrevious } from "../../../../hooks";
import config from "../../../../../config.json";
import Picker from "../../../../components/Shared/Controls/Picker";
import { setPatientDetails } from "../../../../store/Invite";
import { getSinglePatientInfoAsyncAction } from "../../../../store/account/Sagas";
import LoadingPopUp from "../../../../components/Shared/LoadingPopUp";
import { stringIsNullOrWhitespace } from "../../../../utils";
const AboutYouPage = ({ onSumbit, existAzureUser, userFirstName, userLastName, userEmail, userDateOfBirth, requestValidateEmailStatus, requestUpdateEmailStatus, setPersonalInfo, verifyEmail, updateEmail, prevStep, isNewSignUp, setStep, InviteLoginStoredData, updatePatientInfo, requestUpdatePatientInfoStatus, resetRequestUpdatePatientInfo, resetUpdateEmail, setPatientDetailsAction, HoldPatientDetails, getPatientInfo, patient, requestSinglePatientInfoStatus }) => {
    const { t } = useTranslation();
    const prev = usePrevious(requestValidateEmailStatus);
    const delegateStep = config.signUp_steps.find((val) => val.name == "AccountType");
    const delegatesEnabled = !!delegateStep ? delegateStep.show : false;
    const [firstName, setFirstName, errorFirstName, setErrorFirstName] = useInput(userFirstName);
    const [lastName, setLastName, errorLastName, setErrorLastName] = useInput(userLastName);
    const [email, setEmail, errorEmail, setErrorEmail] = useInput();
    const [dateOfBirth, setDate, errorDate, setErrorDate] = useInput(userDateOfBirth && moment(userDateOfBirth).toDate() || "");
    const allFieldsValid = () => !errorFirstName && !errorLastName && !errorDate && !errorEmail
        && !stringIsNullOrWhitespace(firstName) && !stringIsNullOrWhitespace(lastName)
        && dateOfBirth && !stringIsNullOrWhitespace(email);
    const maxAbleDate = moment().subtract(18, 'years');
    const [showPopUp, setPopUp] = useState(false);
    const togglePopUp = () => setPopUp(s => !s);
    const [gender, setGender] = useInput();
    const onChangeGender = (value) => {
        setGender(value);
    };
    const GENDERS = [
        { value: "Male", title: t("male") },
        { value: "Female", title: t("female") },
        { value: "Other", title: t("other") }
    ];
    useEffect(() => {
        getPatientInfo();
    }, []);
    useEffect(() => {
        if (userEmail) {
            setEmail(userEmail);
        }
    }, [userEmail]);
    useEffect(() => {
        if (patient) {
            setGender(patient?.gender);
            setLastName(patient?.lastName);
            setFirstName(patient?.firstName);
            setEmail(patient?.email);
            setDate(new Date(patient?.birthDate));
        }
    }, [patient]);
    useEffect(() => {
        if (requestUpdatePatientInfoStatus === "success") {
            const holdData = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                birthdate: moment(dateOfBirth, "YYYY-MM-DD").format("YYYY-MM-DD"),
                gender: gender
            };
            setPatientDetailsAction(holdData);
            resetRequestUpdatePatientInfo();
            if (email != (patient?.email ?? userEmail)) {
                updateEmail(email);
            }
            else {
                setStep(7);
            }
        }
    }, [requestUpdatePatientInfoStatus]);
    useEffect(() => {
        if (requestUpdateEmailStatus === "success") {
            resetRequestUpdateEmailAction();
            setStep(7);
        }
    }, [requestUpdateEmailStatus]);
    const render19 = () => (React.createElement(PopUp, { isOpen: showPopUp, toggle: togglePopUp, allowClose: true },
        React.createElement("div", { className: classNames(s.textleft, s.popup, s.pt0) },
            React.createElement("div", { className: s.popup__header },
                React.createElement("div", null,
                    React.createElement("img", { src: NotAllowedImg, className: classNames(s.popup__header_icon, s.mlauto) }))),
            React.createElement("div", { className: s.popup__controls },
                React.createElement("div", { className: s.popup__header_title },
                    t("verificationProvided"),
                    " "),
                React.createElement("p", null, t("lockedyouraccount").replace("SUPPORT_NUMBER", config.support_phone_number)),
                React.createElement(Button, { style: classNames(s.button, s.m0), text: t("understood"), onClick: togglePopUp })))));
    const minDate = moment().subtract("120", "years").format('YYYY-MM-DD');
    const maxDate = moment().format('YYYY-MM-DD');
    useEffect(() => {
        if (prev !== undefined && requestValidateEmailStatus === "success") {
            setPersonalInfo({
                firstName,
                lastName,
                email,
                dateOfBirth,
            });
            onSumbit && onSumbit();
        }
    }, [requestValidateEmailStatus]);
    // User must be 18 years of age or older.
    const isUser18OrOlder = (birthdate) => {
        const minimumAge = 18;
        const currentDate = moment();
        const userBirthdate = moment(birthdate, 'YYYY-MM-DD');
        const age = currentDate.diff(userBirthdate, 'years');
        return age >= minimumAge;
    };
    const submitAction = () => {
        if (!isUser18OrOlder(dateOfBirth)) {
            togglePopUp();
            return;
        }
        if (!showPopUp && dateOfBirth && maxAbleDate.isSameOrBefore(moment(dateOfBirth, 'YYYY-MM-DD'))) {
            togglePopUp();
            return;
        }
        const formattedDateOfBirth = moment(dateOfBirth, "YYYY-MM-DD").format("YYYY-MM-DD");
        if (isNewSignUp) {
            updatePatientInfo({
                firstName,
                lastName,
                gender,
                birthDate: formattedDateOfBirth,
            });
        }
        else {
            if (!existAzureUser)
                verifyEmail(email);
            else {
                setPersonalInfo({
                    firstName,
                    lastName,
                    email,
                    dateOfBirth: formattedDateOfBirth,
                });
                onSumbit && onSumbit();
            }
        }
    };
    return React.createElement("div", { className: classNames(si.contentWrapper) },
        React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
            React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                React.createElement("div", { className: classNames(si.formstepbar) },
                    isNewSignUp ? React.createElement(React.Fragment, null,
                        React.createElement("div", { className: s.header00 },
                            React.createElement("div", { className: s.pages },
                                React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `5/15`)))) : null,
                    React.createElement("div", { className: (si.stepToptext) },
                        React.createElement("h4", null,
                            " ",
                            isNewSignUp ? t("tellAboutYourself") : t("tellAboutYourselfNew")),
                        React.createElement("div", { className: s.text },
                            React.createElement("p", null, t("indicatesRequiredField"))),
                        delegatesEnabled
                            ? React.createElement("div", { className: s.text },
                                React.createElement("p", null, t("willBeAskedLater")))
                            : null),
                    React.createElement("div", { className: s.contentInput },
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null,
                                t("firstName"),
                                "*"),
                            React.createElement(InputText, { name: "f_name", errorMessage: t('errorIncorrectFirstName'), pattern: '^.{0,35}$', value: firstName, setValue: setFirstName, error: firstName && errorFirstName, setError: setErrorFirstName, tabIndex: 1, onFocus: true })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null,
                                t("lastName"),
                                "*"),
                            React.createElement(InputText, { name: "l_name", errorMessage: t('errorIncorrectLastName'), pattern: '^.{0,35}$', value: lastName, setValue: setLastName, error: lastName && errorLastName, setError: setErrorLastName, tabIndex: 2 })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null,
                                t("email"),
                                "*"),
                            React.createElement(InputText, { name: "email", errorMessage: t('errorIncorrectEmail'), pattern: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$", disabled: existAzureUser && userEmail && true || false, value: email, setValue: setEmail, error: email && errorEmail, setError: setErrorEmail, tabIndex: 3 })),
                        isNewSignUp ?
                            React.createElement(React.Fragment, null,
                                React.createElement("div", { className: classNames(si.formGroup) },
                                    React.createElement(Typography, { type: 'paragraph', color: 'neutralDark', bold: true },
                                        t("gender"),
                                        "*"),
                                    React.createElement(Picker, { onChange: onChangeGender, value: gender, data: GENDERS, columns: 3 }))) : null,
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null,
                                t("dateOfBirth"),
                                "*"),
                            React.createElement(InputDate, { name: "birthday", value: dateOfBirth, setValue: setDate, minDate: minDate, maxDate: maxDate, tabIndex: 4 })),
                        React.createElement("div", null,
                            React.createElement("div", { className: classNames(si.formGroup) }, showPopUp && (React.createElement(Notification, { type: 'info', onClick: togglePopUp }, t("mustBeOlder")))),
                            React.createElement("div", { className: si.buttonTwopage },
                                isNewSignUp ? null : React.createElement(Button, { text: t("back"), onClick: prevStep }),
                                React.createElement(Button, { disabled: !allFieldsValid(), primary: true, iosTextOverflow: true, text: t("saveAndContinue"), onClick: submitAction }))))))),
        requestSinglePatientInfoStatus === "pending" && isNewSignUp ? React.createElement(LoadingPopUp, { isOpen: true }) : null);
};
const mapStateToProps = (state) => {
    return {
        userFirstName: state.auth.user.firstName,
        userLastName: state.auth.user.lastName,
        userEmail: state.auth.user.email,
        userDateOfBirth: state.auth.user.dateOfBirth,
        requestValidateEmailStatus: state.auth.requestValidateEmailStatus,
        requestUpdateEmailStatus: state.account.requestUpdateEmailStatus,
        InviteLoginStoredData: state.auth.InviteLoginStoredData,
        requestUpdatePatientInfoStatus: state.account.requestUpdatePatientInfoStatus,
        HoldPatientDetails: state.invite.HoldPatientDetails,
        patient: state.account.singlePatient,
        requestSinglePatientInfoStatus: state.account.requestSinglePatientInfoStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setPersonalInfo: (user) => {
            dispatch(setUserInfoAction(user));
        },
        verifyEmail: (email) => dispatch(verifyEmailAsyncAction(email)),
        updatePatientInfo: (patientInfo) => dispatch(updatePatientInfoAsyncAction(patientInfo)),
        resetRequestUpdatePatientInfo: () => dispatch(resetRequestUpdatePatientInfoAction()),
        setPatientDetailsAction: (data) => dispatch(setPatientDetails({ data })),
        updateEmail: (email) => dispatch(updateEmailAsyncAction(email)),
        resetUpdateEmail: () => dispatch(resetRequestUpdateEmailAction()),
        getPatientInfo: () => dispatch(getSinglePatientInfoAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AboutYouPage);
