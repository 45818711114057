import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { getPatientAddressesAsyncAction, allPatientAddressesSelector, editPatientAddressAsyncAction, allProvincesSelector, addPatientAddressAsyncAction, } from "../../../../../../store";
import { PopUp } from "../../../../../../components/Shared/Info";
import { useInput } from "../../../../../../hooks";
import { Button, InputText, } from "../../../../../../components/Shared/Controls";
import Close from "../../../../../../resources/icons/close.svg";
import Picker from "../../../../../../components/Shared/Controls/Picker";
import s from "./style.scss";
import ProvinceDropdown from "../../../../../../components/Shared/Controls/Inputs/ProvinceDropdown";
const initialAddress = {
    postalCode: "",
    city: "",
    street: "",
    streetLine2: "",
    province: "",
    addressType: "home",
    isPrimary: false,
};
const AddEditAddress = ({ editingAddress, openAddress, togglePopup, addAddress, setEditingAddress, editAddress, }) => {
    const { t } = useTranslation();
    const ADDRESS_TYPE = [
        { value: "home", title: t("home") },
        { value: "work", title: t("work") },
        { value: "other", title: t("other") },
    ];
    const provinces = useSelector(allProvincesSelector);
    const [postalCode, setPostalCode, errorPostalCode, setErrorPostalCode] = useInput(initialAddress.postalCode);
    const [city, setCity, errorCity, setErrorCity] = useInput(initialAddress.city);
    const [street, setStreet, errorStreet, setErrorStreet] = useInput(initialAddress.street);
    const [streetLine2, setStreetLine2, errorStreetLine2, setErrorStreetLine2] = useInput(initialAddress.streetLine2);
    const [province, setProvinceLocal] = useInput(initialAddress.province);
    const [addressType, setAddressType] = useInput(initialAddress.addressType);
    useEffect(() => {
        if (editingAddress) {
            setPostalCode(editingAddress.postalCode);
            setCity(editingAddress.city);
            setStreet(editingAddress.street);
            setStreetLine2(editingAddress.streetLine2);
            setProvinceLocal(editingAddress.provinceKey);
            setAddressType(editingAddress.type);
        }
        else {
            resetAddressInputs();
        }
    }, [editingAddress]);
    const onAddressType = (value) => {
        setAddressType(value);
    };
    const onPostalCodeChanged = (text) => {
        const formatted = text
            .replace(/\s/g, "")
            .replace(/(.{3})/g, "$1 ")
            .trim();
        setPostalCode(formatted.toUpperCase());
        setErrorPostalCode(false);
    };
    const onAddAddress = () => {
        addAddress({
            street,
            streetLine2,
            city,
            provinceKey: province,
            postalCode,
            isPrimary: false,
            type: addressType,
        });
        resetAddressInputs();
        setEditingAddress(null);
    };
    const onAddressEdit = () => {
        editingAddress &&
            editAddress({
                ...editingAddress,
                street,
                streetLine2,
                city,
                provinceKey: province,
                postalCode,
                type: addressType,
            });
        setEditingAddress(null);
    };
    const resetAddressInputs = () => {
        setPostalCode(initialAddress.postalCode);
        setCity(initialAddress.city);
        setStreet(initialAddress.street);
        setStreetLine2(initialAddress.streetLine2);
        setProvinceLocal(initialAddress.province);
        setAddressType(initialAddress.addressType);
    };
    return (React.createElement(PopUp, { style: s.addressPopup, isOpen: openAddress },
        React.createElement("div", { className: classNames(s.addressPopupHeader) },
            React.createElement("h4", null, editingAddress ? t("editAddress") : t("addAddress")),
            React.createElement(SVG, { src: Close, onClick: () => {
                    togglePopup();
                    setEditingAddress(null);
                } })),
        React.createElement("div", { className: s.scrollWrapper },
            React.createElement(InputText, { name: "street", placeholder: t("street"), pattern: "^.{0,35}$", value: street, setValue: setStreet, error: street && errorStreet, setError: setErrorStreet }),
            React.createElement(InputText, { name: "streetLine2", placeholder: t("streetLine"), pattern: "^.{0,35}$", value: streetLine2, setValue: setStreetLine2, error: streetLine2 && errorStreetLine2, setError: setErrorStreetLine2 }),
            React.createElement(InputText, { name: "city", placeholder: t("city"), pattern: "^.{0,35}$", value: city, setValue: setCity, error: city && errorCity, setError: setErrorCity }),
            React.createElement(ProvinceDropdown, { data: provinces, value: province, setValue: setProvinceLocal, placeholder: t("selectYourProvince") }),
            React.createElement(InputText, { name: "postal_code", placeholder: t("postalCode"), errorMessage: t("postalCodeError"), pattern: "null|^[A-Z]\\d[A-Z][ ]\\d[A-Z]\\d$", value: postalCode, setValue: onPostalCodeChanged, error: postalCode && errorPostalCode, setError: setErrorPostalCode, maxLength: 7 }),
            React.createElement("div", { className: s.pickerWrapper },
                React.createElement("div", { className: s.inputTitle }, t("addressType")),
                React.createElement(Picker, { onChange: onAddressType, value: addressType, data: ADDRESS_TYPE, columns: 3 }))),
        React.createElement("div", { className: s.buttons },
            React.createElement(Button, { style: s.button, text: t("cancel"), onClick: () => {
                    setEditingAddress(null);
                    togglePopup();
                } }),
            React.createElement(Button, { style: s.button, text: t("saveAddress"), primary: true, onClick: () => {
                    editingAddress ? onAddressEdit() : onAddAddress();
                    togglePopup();
                }, disabled: editingAddress
                    ? (editingAddress.postalCode === postalCode &&
                        editingAddress.city === city &&
                        editingAddress.street === street &&
                        editingAddress.streetLine2 === streetLine2 &&
                        editingAddress.provinceKey === province &&
                        editingAddress.type === addressType) ||
                        !province ||
                        errorPostalCode
                    : !province || errorPostalCode }))));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        addresess: allPatientAddressesSelector(state),
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPatientInfo: () => dispatch(getPatientAddressesAsyncAction()),
        editAddress: (address) => dispatch(editPatientAddressAsyncAction(address)),
        addAddress: (address) => dispatch(addPatientAddressAsyncAction(address)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditAddress);
