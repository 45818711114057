import React, { useEffect, useState } from "react";
import { Button } from "../../components/Shared/Controls";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getDrugBrandCodesDropdownAsyncAction } from "../../store/prescriber/Sagas";
import CheckboxDropdown from "../../components/Shared/Controls/CheckboxDropdown";
import LoadingPopUp from "../../components/Shared/LoadingPopUp";
import SuccessPopUp from '../../components/Shared/Info/SuccessPopUp';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import medicalConditioncss from "./MedicalConditionsStyle.scss";
import golbalcss from "../styles.scss";
import InputTextMultiLine from "../../components/Shared/Controls/Inputs/InputTextMultiLine";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { changeStateMedicalConditionPostStatus } from "../../store/medicalConditions";
import { createMedicalConditionsInfoAsyncAction, getmedicalConditionOneDataInfoAsyncAction, updateMedicalConditionInfoAsyncAction } from "../../store/medicalConditions/Sagas";
const MedicalConditions = ({ updateMedicalCondition, getDrugBrandCodesDropdown, drugBrandCodesDropdown, createMedicalConditions, requestUpdateMedicalConditionInfoStatus, requestMedicalConditionPostStatus, getMedicalConditionInfo, getmedicalConditionState, requestmedicalConditionInfoStatus }) => {
    const { t } = useTranslation();
    const [openSavePopup, setOpenSavePopup] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const [Name, setName] = useState("");
    const [Description, setDescription] = useState("");
    const [BrandIdentifier, setBrandIdentifier] = useState("");
    const [successPopupIsOpen, setSuccessPopupIsOpen] = useState(false);
    const toggleSavePopup = () => setOpenSavePopup((open) => !open);
    const [isSubmit, setisSubmit] = useState(false);
    useEffect(() => {
        getDrugBrandCodesDropdown();
        requestUpdateMedicalConditionInfoStatus = "idle";
        requestMedicalConditionPostStatus = "idle";
        changeStateMedicalConditionPostStatus("idle");
    }, []);
    useEffect(() => {
        if (id) {
            setDescription("");
            getMedicalConditionInfo(id);
        }
    }, [id]);
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode
            };
        })
        : [];
    const [items, setItems] = useState(DrugBrandCode);
    const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
    const handleSetItems = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.displayName);
        setSelectedDisplayNames(selectedNames);
        setItems(newItems);
    };
    useEffect(() => {
        if (id) {
            if (getmedicalConditionState != null) {
                const firstPrescriber = getmedicalConditionState.value?.resultItems[0];
                setDescription(firstPrescriber?.medicalConditionDescription || "");
                // Set the drugBrandCodes in CheckboxDropdown
                const selectedDrugBrandCodes = firstPrescriber.drugBrandCodes || [];
                const updatedItems = items.map((item) => ({
                    ...item,
                    isChecked: selectedDrugBrandCodes.includes(item.displayName),
                }));
                handleSetItems(updatedItems);
            }
        }
        else {
            setDescription("");
        }
    }, [getmedicalConditionState]);
    const onsubmitData = () => {
        setisSubmit(true);
        if (Description != "" && selectedDisplayNames.length !== 0) {
            if (id) {
                updateMedicalCondition({
                    Id: id,
                    DrugBrandCodes: selectedDisplayNames,
                    Description: Description,
                });
                setisSubmit(false);
            }
            else {
                createMedicalConditions({
                    DrugBrandCodes: selectedDisplayNames,
                    Description: Description,
                });
                setisSubmit(false);
            }
        }
    };
    const onFormSubmit = (event) => {
        event.preventDefault();
        onsubmitData();
    };
    useEffect(() => {
        if (requestMedicalConditionPostStatus === "success") {
            setSuccessPopupIsOpen(true);
            setDescription("");
            requestMedicalConditionPostStatus = "idle";
            changeStateMedicalConditionPostStatus("idle");
        }
    }, [requestMedicalConditionPostStatus]);
    useEffect(() => {
        if (requestUpdateMedicalConditionInfoStatus === "success") {
            requestUpdateMedicalConditionInfoStatus = "idle";
            setSuccessPopupIsOpen(true);
        }
    }, [requestUpdateMedicalConditionInfoStatus]);
    const handleArrowBack = () => {
        navigate('/medicalConditions');
    };
    return (React.createElement(Box, { className: golbalcss.MuiPaperAllSectionBox, sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { className: medicalConditioncss.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement("div", { className: medicalConditioncss.prescriberModifyanEntry },
                id ? React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: medicalConditioncss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("medicalConditions"),
                    " ",
                    React.createElement("span", { className: medicalConditioncss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("modifyanentry"),
                    " ") : React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: medicalConditioncss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("medicalConditions"),
                    " ",
                    React.createElement("span", { className: medicalConditioncss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("createanentry"),
                    " "),
                React.createElement("div", { className: medicalConditioncss.pagebodyBox },
                    React.createElement("div", { className: medicalConditioncss.scrollWrapper },
                        React.createElement(React.Fragment, null,
                            React.createElement("form", { onSubmit: onFormSubmit },
                                React.createElement("div", { className: medicalConditioncss.forminputAll },
                                    React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                        React.createElement("label", null, t("drugbrandcodes")),
                                        React.createElement(CheckboxDropdown, { items: items, setItems: handleSetItems, required: true, isSubmited: isSubmit })),
                                    React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                        React.createElement("label", null,
                                            t("Description"),
                                            " (Max 256 characters)"),
                                        React.createElement(InputTextMultiLine, { name: "Description", maxLength: 256, value: Description, setValue: setDescription, columns: 6, height: 110, required: true, validationMessage: "Please enter description", isSubmitted: isSubmit }))),
                                React.createElement("div", { className: medicalConditioncss.buttonTwolist },
                                    React.createElement(Button, { text: t("cancel"), onClick: () => handleArrowBack() }),
                                    React.createElement(Button, { primary: true, text: t("savechanges"), type: "submit" }))),
                            React.createElement(SuccessPopUp, { isOpen: successPopupIsOpen, displayMessage: "Your changes have been saved.", header: "success!", name: "Medical Condition", togglePopUp: () => { setSuccessPopupIsOpen(false); handleArrowBack(); } }),
                            requestMedicalConditionPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestUpdateMedicalConditionInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestmedicalConditionInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null)))))));
};
const mapStateToProps = (state) => {
    return {
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown,
        getmedicalConditionState: state.medicalConditions.getmedicalConditionInfo,
        requestUpdateMedicalConditionInfoStatus: state.medicalConditions.requestUpdateMedicalConditionInfoStatus,
        requestMedicalConditionPostStatus: state.medicalConditions.requestMedicalConditionPostStatus,
        requestmedicalConditionInfoStatus: state.medicalConditions.requestmedicalConditionInfoStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        createMedicalConditions: (MedicalConditionsInfo) => dispatch(createMedicalConditionsInfoAsyncAction(MedicalConditionsInfo)),
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
        updateMedicalCondition: (medicalConditionInfo) => dispatch(updateMedicalConditionInfoAsyncAction(medicalConditionInfo)),
        getMedicalConditionInfo: (medicalConditionid) => dispatch(getmedicalConditionOneDataInfoAsyncAction(medicalConditionid)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MedicalConditions);
