import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { resetRequestUpdateHealthInformationAction, updateHealthInformationAsyncAction } from "../../../../../store";
import { Button } from "../../../../../components/Shared/Controls";
import useInput from "../../../../../hooks/useInput";
import { allConditionsSelector, getMedicalConditionsAsyncAction } from "../../../../../store/medicalConditions";
import CheckboxDropdown from "../../../../../components/Shared/Controls/CheckboxDropdown";
import s from './style.scss';
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import Typography from "../../../../../components/Shared/Typography";
import { holdMedicalConditionAction } from "../../../../../store/Invite";
import { getPatientHealthInformationAsyncAction } from "../../../../../store/account/Sagas";
import classNames from "classnames";
const HealthInformationPage = ({ brand, userMedicalCondition, getMedicalConditionals, prevStep, isNewSignUp, setStep, updateHealthInformation, InviteLoginStoredData, requestUpdateHealthInformationState, resetRequestUpdateHealthInformation, setholdMedicalConditionAction, getHealthInformation, healthInformation }) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        getMedicalConditionals(brand);
    }, [brand]);
    useEffect(() => {
        getHealthInformation();
    }, []);
    const conditions = useSelector(allConditionsSelector);
    var medicalConditionData = [];
    if (!!conditions) {
        medicalConditionData = conditions?.map((condition) => {
            return {
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: false
            };
        });
    }
    const [checkedConditions, setCheckedConditions] = useState([]);
    const [medicalConditions, setMedicalConditions] = useInput(medicalConditionData);
    useEffect(() => {
        conditions && setMedicalConditions(conditions?.map((condition) => {
            return {
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: userMedicalCondition && userMedicalCondition.find((el) => el.medicalConditionId === condition.medicalConditionId)
                    && true
                    || false
            };
        }));
    }, [conditions]);
    var medConditions = [];
    if (!!medicalConditions) {
        medConditions = medicalConditions?.filter((el) => el.isChecked).map((condition) => {
            return {
                medicalConditionId: condition.key,
                medicalConditionDescription: condition.displayName
            };
        });
    }
    useEffect(() => {
        if (!!conditions) {
            const updatedMedicalConditions = conditions?.map((condition) => ({
                key: condition.medicalConditionId,
                displayName: condition.medicalConditionDescription,
                isChecked: healthInformation.medicalConditions.some((holdCondition) => holdCondition.medicalConditionId === condition.medicalConditionId),
            }));
            setMedicalConditions(updatedMedicalConditions);
        }
    }, [conditions, healthInformation]);
    const submit = () => {
        const holdData = {
            holdMedicalCondition: checkedConditions
        };
        setholdMedicalConditionAction(holdData);
        if (isNewSignUp) {
            const newHealthInformation = {
                patientId: InviteLoginStoredData?.data?.value?.selectedPatientInfo?.patientId,
                medicalConditions: medicalConditions.filter((el) => el.isChecked).map((condition) => {
                    return {
                        medicalConditionId: condition.key
                    };
                }),
            };
            updateHealthInformation(newHealthInformation);
        }
    };
    useEffect(() => {
        if (requestUpdateHealthInformationState === "success") {
            const holdData = {
                holdMedicalCondition: checkedConditions
            };
            setholdMedicalConditionAction(holdData);
            setStep(8);
            resetRequestUpdateHealthInformation();
        }
    }, [requestUpdateHealthInformationState]);
    const isSaveButtonDisabled = !medicalConditions.some((condition) => condition.isChecked);
    return (React.createElement("div", { className: classNames(si.contentWrapper) },
        React.createElement("div", { className: classNames(isNewSignUp ? si.scrollWrapperfrom : null) },
            React.createElement("div", { className: classNames(isNewSignUp ? si.formstep : null, isNewSignUp ? si.formWrapper : null) },
                React.createElement("div", { className: classNames(si.formstepbar) },
                    isNewSignUp ? React.createElement(React.Fragment, null,
                        React.createElement("div", { className: s.header },
                            React.createElement("div", { className: s.pages },
                                React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `6/15`)))) : null,
                    React.createElement("div", { className: (si.stepToptext) },
                        React.createElement("h4", null, t("healthInformation")),
                        React.createElement("div", { className: s.text },
                            React.createElement("p", null, t("weNeedThisInfo")))),
                    React.createElement(CheckboxDropdown, { items: medicalConditions, setItems: setMedicalConditions, label: t("selectMedicalConditions") }),
                    React.createElement("div", { className: si.buttonTwopage },
                        React.createElement(Button, { text: t("back"), onClick: prevStep }),
                        React.createElement(Button, { disabled: isSaveButtonDisabled, primary: true, text: t("saveAndContinue"), onClick: submit })))))));
};
const mapStateToProps = (state) => {
    return {
        userProvince: state.auth.provinceId,
        userGender: state.auth.additionalInfo.gender,
        userDoctorsName: state.auth.additionalInfo.doctorsName,
        userMedicalCondition: state.auth.additionalInfo.medicalConditions,
        brand: state.account.user.drugBrandCode,
        InviteLoginStoredData: state.auth.InviteLoginStoredData,
        requestUpdateHealthInformationState: state.account.requestUpdateHealthInformationStatus,
        holdMedicalConditionData: state.invite.holdMedicalConditionData,
        healthInformation: state.account.healthInformation
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getMedicalConditionals: (drugBrandCode) => {
            dispatch(getMedicalConditionsAsyncAction(drugBrandCode));
        },
        updateHealthInformation: (healthInformation) => dispatch(updateHealthInformationAsyncAction(healthInformation)),
        resetRequestUpdateHealthInformation: () => dispatch(resetRequestUpdateHealthInformationAction()),
        setholdMedicalConditionAction: (data) => dispatch(holdMedicalConditionAction({ data })),
        getHealthInformation: () => dispatch(getPatientHealthInformationAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HealthInformationPage);
