import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import golbalcss from "../styles.scss";
import { useTranslation } from "react-i18next";
const ComingSoon = ({}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    return (React.createElement(Box, { "data-testid": "NotAuth", className: golbalcss.MuiPaperAllSectionBox, sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { sx: { width: '100%', mb: 2 } },
            React.createElement("p", null, "You are not authorized to view this page. This page is restricted to administrators."),
            React.createElement("p", null, "If you think this is a mistake log out and log in again."),
            React.createElement("p", null, "If you are still having issues, reach out to your program representative."))));
};
const mapStateToProps = (state) => {
    return {};
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);
