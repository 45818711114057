import React from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import Arrow from "../../../../resources/icons/arrow-right";
import s from "./style.scss";
import { ProfileInfoData } from "./consts";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import colors from "../../../../resources/colors";
import { Box } from "../../../../components";
import Typography from "../../../../components/Shared/Typography";
const ProfileInfo = ({}) => {
    const { t } = useTranslation();
    return (React.createElement(React.Fragment, null, ProfileInfoData.map((info) => {
        return (React.createElement("div", { key: info.icon, className: s.profileInfo },
            React.createElement(Box, { f1: true, column: true },
                React.createElement("div", { className: s.icon },
                    React.createElement(SVG, { src: info.icon })),
                React.createElement(Typography, { type: 'header_2', color: 'neutralDark', bold: true }, t(info.title)),
                React.createElement(Typography, { type: 'paragraph', color: 'neutralGray' }, t(info.description)),
                React.createElement(Link, { to: info.url },
                    React.createElement("div", { className: s.iconWrapper },
                        React.createElement("div", { style: { backgroundColor: colors.primary } },
                            React.createElement(Arrow, { color: colors.white, style: s.redirectButton })))))));
    })));
};
const mapStateToProps = (state) => {
    return {};
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileInfo);
