import ApiManager from "../api/ApiManager";
const version = "1.2";
const REMINDERS = `/v${version}/patient/medical-reminder`;
class MedicalRemindersRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new MedicalRemindersRepository();
        }
        return this.instance;
    }
    static getReminders = (patientId) => {
        return ApiManager.getInstance().get(REMINDERS, { patientId });
    };
    static addReminder = (data) => {
        return ApiManager.getInstance().post(REMINDERS, data);
    };
    static editReminder = (data) => {
        return ApiManager.getInstance().put(REMINDERS, data);
    };
    static deleteReminder = (reminderId) => {
        return ApiManager.getInstance().delete(`${REMINDERS}/${reminderId}`);
    };
}
export default MedicalRemindersRepository;
