import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import config from "../../../config.json";
import { Button, InputPassword, InputText, } from "../../components/Shared/Controls";
import { PopUp } from "../../components/Shared/Info";
import useInput from "../../hooks/useInput";
import { signInAsyncAction } from "../../store/auth/actions";
import { hideSignInErrorAction, setNeedsTwoFactorAction } from "../../store";
import { usePrevious, useErrorPopUp } from "../../hooks";
import TermsOfUse from "../../components/Shared/PopUps/TermsOfUse";
import Accessibility from "../../components/Shared/PopUps/Accessibility";
import HelpInfo from "../../components/Shared/PopUps/HelpInfo";
import StatusDisplay from "../../components/StatusDisplay";
import SignUp from "../SignUp";
import Banner from "../components/Banner";
import Header from "../components/Header";
import HelpIcon from "../../resources/icons/help_icon";
import colors from "../../resources/colors";
import si from "../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import { ParseErrorCode } from "../../utils";
import Typography from "../../components/Shared/Typography";
const SignIn = ({ createAccount, requiresTwoFactor, requestStatus, error, signIn, isNewSignUp, setStep, resetSignInErrorAction, setNeedsTwoFactorAction, InviteLoginStoredData }) => {
    const { t } = useTranslation();
    const [selectedLang, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
    const ref = usePrevious(requestStatus);
    const [login, setLogin, errorLogin, setErrorLogin] = useInput();
    const [password, setPassword] = useInput();
    const [errorIsShowing, showError, errorContent, setErrorContent, toggleErrorPopUp] = useErrorPopUp();
    const [infoPopUp, showInfoPopUp] = useState(false);
    const navigate = useNavigate();
    const [termsOfUseIsOpen, setTermsOfUseIsOpen] = useState(false);
    const [accessibilityIsOpen, setAccessibilityIsOpen] = useState(false);
    const [twoFactorCode, setTwoFactorCode, errorTwoFactorCode, setErrorTwoFactorCode] = useInput();
    const [signUpForExistingAzureUser, setSignUpForExistingAzureUser] = useState(false);
    const [errorTitle, setErrorTitle] = useState("");
    const [errorButtons, setErrorButtons] = useState();
    const [termsAndCondition1, setTermsAndCondition1] = useState(false);
    const [termsAndCondition2, setTermsAndCondition2] = useState(false);
    useEffect(() => {
        if (ref !== undefined) {
            if (requestStatus === "failure") {
                setErrorContent(error?.message);
                const { code } = ParseErrorCode(error?.message);
                if (code === "022") {
                    setErrorTitle(t("noAccountErrorTitle"));
                    setErrorButtons(errorButtonsShow());
                }
                else {
                    setErrorTitle(undefined);
                    setErrorButtons(undefined);
                }
                showError(true);
            }
            else if (requestStatus === "success") {
                // if(isNewSignUp){
                // setTermsAndCondition1(true);
                // resetSignInErrorAction();
                // setStepFunction(); // TODO remove this block, we should be using additional steps
                // } else 
                if (createAccount) {
                    setSignUpForExistingAzureUser(true);
                }
                else {
                    navigate('/');
                }
            }
        }
    }, [requestStatus]);
    const allFieldsValid = !errorLogin && login && password;
    const backToSignIn = (event) => {
        setNeedsTwoFactorAction(false);
        requiresTwoFactor = false;
    };
    const signInAction = (event) => {
        const action = signIn(login, password, login.includes('@') ? 'Email' : 'PhoneNumber', requiresTwoFactor ? twoFactorCode : '');
    };
    const signInOnEnterPress = (e) => {
        if (e.key === "Enter")
            signInAction();
    };
    const errorButtonsShow = () => {
        return [
            { text: t("tryAgain"), cancel: true },
            // { text: t("createAccount"), cancel: true, onClick: () => navigate("/signup") },
        ];
    };
    const setNextClickFunction = () => {
        setTermsAndCondition1(false);
        setTermsAndCondition2(true);
    };
    const setStepFunction = () => {
        setTermsAndCondition2(false);
        setStep(6);
    };
    return signUpForExistingAzureUser ? React.createElement(SignUp, { existAzureUser: true }) : (React.createElement("div", null,
        React.createElement(StatusDisplay, { title: errorTitle, errorButtons: errorButtons }),
        React.createElement(TermsOfUse, { isOpen: termsOfUseIsOpen, setIsOpen: setTermsOfUseIsOpen }),
        React.createElement(Accessibility, { isOpen: accessibilityIsOpen, setIsOpen: setAccessibilityIsOpen }),
        isNewSignUp ? null : React.createElement(Banner, null),
        React.createElement(HelpInfo, { isOpen: infoPopUp, setIsOpen: showInfoPopUp }),
        React.createElement("div", { className: isNewSignUp ? null : s.login },
            isNewSignUp ? null : React.createElement(Header, null),
            termsAndCondition1 && isNewSignUp && (React.createElement(PopUp, { isOpen: termsAndCondition1, allowClose: true },
                React.createElement("div", { className: classNames(s.popup, s.popuptext) },
                    React.createElement("div", { className: classNames(s.popup__content, s.shbox) },
                        React.createElement("h4", null, t("termsofUse")),
                        React.createElement("h3", null, t("termsofService")),
                        React.createElement("p", null, t("termdes")),
                        React.createElement("h3", null, t("descriptionofService")),
                        React.createElement("p", null, t("serviceDes")),
                        React.createElement("h3", null, t("copyrightLicenses")),
                        React.createElement("p", null, t("descCopyright")))),
                React.createElement(Button, { text: t("next"), onClick: () => setNextClickFunction() }))),
            termsAndCondition2 && isNewSignUp && (React.createElement(PopUp, { isOpen: termsAndCondition2, allowClose: true },
                React.createElement("div", { className: classNames(s.popup, s.popuptext) },
                    React.createElement("div", { className: classNames(s.popup__content, s.shboxNO) },
                        React.createElement("h4", null, t("validateinformation")),
                        React.createElement("h3", null, t("enterinformation")),
                        React.createElement("p", null, t("informationDesc")))),
                React.createElement(Button, { text: t("next"), onClick: setStepFunction }))),
            requiresTwoFactor ? (
            // Render content for the two-factor step
            React.createElement("div", { className: classNames(s.form) },
                React.createElement("div", { className: s.contentWrapper },
                    React.createElement("div", { className: classNames(s.SingInTitle) },
                        React.createElement(Typography, { type: "subtitle_2", color: "neutralGray" }, t("welcome")),
                        React.createElement("h1", null,
                            React.createElement(Typography, { type: "header_1", bold: true }, t("signInTwoFactor")),
                            React.createElement("div", { onClick: () => showInfoPopUp(true) },
                                React.createElement(HelpIcon, { color: colors.icons.primary, className: s.helpIcon }))),
                        React.createElement("p", null,
                            React.createElement(Typography, { type: "paragraph", color: "neutralGray" }, t("signInTwoFactorIsRequiredOnThisAccount")))),
                    React.createElement("div", { onKeyDown: signInOnEnterPress },
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("verificationCode")),
                            React.createElement(InputText, { name: "verificationCode", value: twoFactorCode, setValue: setTwoFactorCode, tabIndex: 1 }))),
                    React.createElement("span", null, errorTwoFactorCode),
                    React.createElement("div", { className: si.buttonTwopage },
                        React.createElement(Button, { disabled: !allFieldsValid, primary: true, text: t("VerifyAndSignIn"), onClick: () => { signInAction(); } })),
                    React.createElement("div", { className: s.signUptext },
                        React.createElement(Button, { text: t("back"), onClick: backToSignIn }))))) : (React.createElement("div", { className: classNames(s.form) },
                React.createElement("div", { className: s.contentWrapper },
                    React.createElement("div", { className: classNames(s.SingInTitle) },
                        React.createElement(Typography, { type: "subtitle_2", color: "neutralGray" }, t("welcome")),
                        React.createElement("h1", null,
                            React.createElement(Typography, { type: "header_1", bold: true }, t("signIn")),
                            React.createElement("div", { onClick: () => showInfoPopUp(true) },
                                React.createElement(HelpIcon, { color: colors.icons.primary, className: s.helpIcon })))),
                    React.createElement("div", { onKeyDown: signInOnEnterPress },
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("phoneNumberOrEmail")),
                            React.createElement("span", null, t("phoneNumberOrEmailHint")),
                            React.createElement(InputText, { name: "login", value: login, setValue: setLogin, 
                                // pattern='^[0-9]{9,10}$'
                                // errorMessage={t("errorIncorrectPhoneNumber")}
                                // error={login && errorLogin}
                                // setError={setErrorLogin}
                                // length={10}
                                // isNumber
                                tabIndex: 1, onFocus: true })),
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("password")),
                            React.createElement(InputPassword, { name: "password", password: password, setPassword: setPassword, tabIndex: 2 })),
                        React.createElement(Link, { to: "/reset-password", className: s.link },
                            " ",
                            t("forgotPasswordLabel"),
                            " "),
                        React.createElement("div", { className: si.buttonTwopage },
                            React.createElement(Button, { disabled: !allFieldsValid, primary: true, text: t("proceedSecurely"), onClick: () => { signInAction(); }, name: "loginButton" })),
                        config.allowSignUp &&
                            React.createElement("div", { className: s.signUptext },
                                React.createElement("p", null, t("dontHaveAccount")),
                                React.createElement(Link, { to: "/signup", className: s.link },
                                    " ",
                                    t("signUpOnSignInPage"),
                                    " ")))))))));
};
const mapStateToProps = (state) => {
    return {
        createAccount: state.auth.createAccount,
        requiresTwoFactor: state.auth.requiresTwoFactor,
        // requiresTwoFactor: state.navigation.userNeedsTwoFactor,
        requestStatus: state.auth.requestSignInStatus,
        error: state.auth.requestSignInError,
        InviteLoginStoredData: state.auth.InviteLoginStoredData
    };
};
const mapDispatchToProps = (dispatch) => ({
    signIn: (identifier, password, signInType, twoFactorCode) => dispatch(signInAsyncAction({ identifier, password, signInType, twoFactorCode })),
    resetSignInErrorAction: () => dispatch(hideSignInErrorAction()),
    setNeedsTwoFactorAction: () => dispatch(setNeedsTwoFactorAction(false)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
