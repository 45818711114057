import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVG from 'react-inlinesvg';
import { LanguagePicker } from '../../../components/Shared/Controls';
import HelpInfo from '../../../components/Shared/PopUps/HelpInfo';
import images from '../../../resources/images';
import s from './style.scss';
import i18next from "i18next";
import { connect } from 'react-redux';
const Header = ({ InviteLoginStoredData }) => {
    const { t } = useTranslation();
    const [selectedLang, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
    const [infoPopUp, showInfoPopUp] = useState(false);
    const [inviteLogo, setInviteLogo] = useState("");
    useEffect(() => {
        setInviteLogo(InviteLoginStoredData?.data?.value?.selectedPatientInfo?.currentProgramLogo);
    }, [InviteLoginStoredData]);
    return (React.createElement(React.Fragment, null,
        React.createElement(HelpInfo, { isOpen: infoPopUp, setIsOpen: showInfoPopUp }),
        React.createElement("div", { className: s.mainHeader },
            React.createElement("a", { href: "/" }, i18next.language === "en" ? (React.createElement(SVG, { src: images.generalLogoLocal["en"], className: s.logoIcon })) : (
            // <SVG src={images.logoAmeFre} className={s.logoIcon} />
            React.createElement("img", { src: images.Logox4Fre, className: s.logoIcon }))),
            React.createElement("div", null, InviteLoginStoredData != undefined && inviteLogo != null
                ? React.createElement("img", { src: inviteLogo, height: 60, width: 90 }) : null),
            React.createElement("div", null,
                React.createElement(LanguagePicker, { style: s.languagePicker, selected: selectedLang, setLanguage: setLanguage })))));
};
const mapStateToProps = (state) => {
    return {
        InviteLoginStoredData: state.auth.InviteLoginStoredData,
    };
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
