import React from "react";
import { useTranslation } from "react-i18next";
import classNames from 'classnames';
import Search from '../../../../../resources/icons/search.svg';
import SVG from 'react-inlinesvg';
import colors from "../../../../../resources/colors";
import s from './style.scss';
const SearchInput = ({ style, value, setValue }) => {
    const { t } = useTranslation();
    const onChange = (value) => {
        setValue(value);
    };
    return (React.createElement("div", { className: s.inputGroup, style: {
            "--icon-hover-color": colors.controlsGeneralColor,
            "--input-focus-border-color": colors.inputFocusColorBorder,
            "--input-focus-shadow-color": colors.inputFocusShadowBorder,
        } },
        React.createElement("input", { className: classNames(s.input, style), type: 'text', name: 'search', value: value, required: true, placeholder: t('search'), autoComplete: "off", onChange: (e) => {
                onChange(e.target.value);
            } }),
        React.createElement(SVG, { className: s.searchIcon, src: Search })));
};
export default SearchInput;
