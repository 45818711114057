import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { setAddionInfoAction } from "../../../../store";
import { Button, InputText } from "../../../../components/Shared/Controls";
import DinInfoContent from "../../../../components/Shared/PopUps/DinInfoContent";
import useInput from "../../../../hooks/useInput";
import config from "../../../../../config.json";
import s from './style.scss';
const DinPage = ({ userDin, setAdditionalInfo, onSumbit, step }) => {
    const { t } = useTranslation();
    const [dinNumber, setDinNumber, errorDinNumber, setErrorDinNumber] = useInput(userDin);
    const allFieldsValid = () => dinNumber && dinNumber.length === 8 && errorDinNumber === "";
    const submit = () => {
        setAdditionalInfo({
            din: dinNumber
        });
        onSumbit && onSumbit();
    };
    return React.createElement("div", { className: s.contentWrapper },
        React.createElement("h1", { className: classNames(s.subheading_1, s.stepTitle) }, t("din").replace("BRAND_NAME", "")),
        React.createElement("div", { className: s.textWrapper }, t("pleaseEnterBrandDINNumber").replace("BRAND_NAME", "")),
        React.createElement(InputText, { name: "dinNumber", placeholder: t("din").replace("BRAND_NAME", ""), pattern: config.validDINs.map(din => `^${din}$`), value: dinNumber, setValue: setDinNumber, error: dinNumber && (errorDinNumber || dinNumber.length !== 8), setError: setErrorDinNumber, descriptionJSX: React.createElement(DinInfoContent, null), errorMessage: t("pleaseEnterValidDIN"), isNumber: true, length: 8, maxLength: 8, notEveryPattern: true }),
        React.createElement(Button, { style: s.mb0_button, disabled: !allFieldsValid(), primary: true, text: t("saveAndProceed"), onClick: submit }));
};
const mapStateToProps = (state) => {
    return {
        userDin: state.auth.additionalInfo.din,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setAdditionalInfo: (info) => dispatch(setAddionInfoAction(info)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(DinPage);
