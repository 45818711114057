import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import s from "./style.scss";
import Arrow from "../../../resources/icons/short-arrow.svg";
import { useDeviceHeight } from "../../../hooks";
const heightOffset = (height, withTabs, noTitle) => {
    if (withTabs)
        return height + 45;
    if (noTitle)
        return height - 45;
    else
        return height;
};
const PageBody = ({ title, style, children, backAction, hasAdditionalBack, withTabs, noTitle, }) => {
    useDeviceHeight("pageWrapper", heightOffset(170, withTabs, noTitle), heightOffset(230, withTabs, noTitle), heightOffset(210, withTabs, noTitle));
    return (React.createElement("div", { "data-testid": "pagebody", className: classNames(style, s.pageBody, { [s.withArrow]: backAction }) },
        React.createElement("div", { className: classNames(s.contentWrapper, { [s.withTabs]: withTabs }) },
            backAction && (React.createElement(SVG, { src: Arrow, className: classNames(s.arrow, { [s.hide]: hasAdditionalBack }), onClick: backAction })),
            title && (React.createElement("h3", { className: classNames({ [s.withArrow]: backAction }) }, title))),
        React.createElement("div", { id: "pageWrapper", className: classNames(s.pageBodyWrapper) }, children)));
};
export default PageBody;
