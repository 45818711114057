import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "NAVIGATION";
const initialState = {
    splashShown: true,
    menuShown: false,
    userLoggedIn: false,
    userRegistered: true,
    additionalStepsPassed: false,
    currentAdditionalScreen: undefined,
    steps: [],
    userForgotPassword: false,
    skippedSteps: [],
    requestProfileTabsStatus: "idle",
    requestProfileTabsError: null,
    profileTabs: [],
    availableFA: false,
    assignBrandSettings: {
        availableHealthCard: true,
        availableInsurance: true
    }
};
const navigationSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        showSplashAction: (state) => {
            state.splashShown = true;
        },
        hideSplashAction: (state) => {
            state.splashShown = false;
        },
        showMenuAction: (state, action) => {
            state.menuShown = action.payload;
        },
        navigateToSignInAction: (state) => {
            state.userRegistered = true;
            state.userLoggedIn = false;
            state.userForgotPassword = false;
        },
        navigateToResetPassword: (state) => {
            state.userRegistered = true;
            state.userLoggedIn = false;
            state.userForgotPassword = true;
        },
        navigateToSignUpAction: (state) => {
            state.userRegistered = false;
            state.userLoggedIn = false;
            state.userForgotPassword = false;
        },
        navigateToMainScreenAction: (state) => {
            state.userLoggedIn = true;
            state.userRegistered = true;
        },
        setStepsAction: (state, action) => {
            state.steps = action.payload.steps;
            if (action.payload.isRegistrationFlow) {
                const filteredSteps = action.payload.steps.filter((item) => !state.skippedSteps.includes(item.step));
                state.steps = filteredSteps;
                if (filteredSteps[0]) {
                    state.currentAdditionalScreen = filteredSteps[0].step;
                }
                else {
                    state.additionalStepsPassed = true;
                }
            }
            else {
                state.additionalStepsPassed = true;
            }
            state.assignBrandSettings.availableHealthCard = action.payload.steps.some((item) => item.step === "AddHealthCard");
            state.assignBrandSettings.availableInsurance = action.payload.steps.some((item) => item.step === "AddInsurer");
        },
        skipScreenAction: (state, action) => {
            if (state.currentAdditionalScreen) {
                state.skippedSteps = action.payload;
                const index = state.steps.findIndex((item) => item.step === state.currentAdditionalScreen);
                const nextScreen = state.steps[index + 1];
                if (nextScreen) {
                    state.currentAdditionalScreen = nextScreen.step;
                }
                else {
                    state.additionalStepsPassed = true;
                }
            }
        },
        setSkippedStepsAction: (state, action) => {
            state.skippedSteps = action.payload;
        },
        startRequestProfileTabsAction: (state) => {
            state.requestProfileTabsStatus = "pending";
            state.requestProfileTabsError = null;
        },
        successRequestProfileTabsAction: (state, action) => {
            state.requestProfileTabsStatus = "success";
            state.requestProfileTabsError = null;
            state.profileTabs = action.payload.profileTabs;
            state.availableFA = action.payload.isAvailableFA;
        },
        showRequestProfileTabsErrorAction: (state, action) => {
            state.requestProfileTabsStatus = "failure";
            state.requestProfileTabsError = action.payload;
        },
        resetRequestProfileTabsAction: (state) => {
            state.requestProfileTabsStatus = "idle";
            state.requestProfileTabsError = null;
        }
    }
});
export default navigationSlice;
