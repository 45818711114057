import React, { useEffect, useState } from "react";
import { PopUp } from "../../../../components/Shared/Info";
import { Button, InputText } from "../../../../components/Shared/Controls";
import SVG from "react-inlinesvg";
import Close from "../../../../resources/icons/close.svg";
import { connect } from "react-redux";
import { getDrugBrandCodesDropdownAsyncAction } from "../../../../store/prescriber/Sagas";
import CheckboxDropdown from "../../../../components/Shared/Controls/CheckboxDropdown";
import s from "./style.scss";
import classNames from 'classnames';
import MultiSelectionDropdown from "../../../../components/Shared/Controls/MultiSelectionDropdown/MultiSelectionDropdown";
import { useTranslation } from "react-i18next";
const EducationFilter = ({ isAdmin, getDrugBrandCodesDropdown, drugBrandCodesDropdown, onClose, setFields, holdDataFilter, Tags, medicalConditionsData }) => {
    const { t } = useTranslation();
    const [Title, setTitle] = useState("");
    const [Description, setDescription] = useState("");
    const [selectedOptionsTags, setSelectedOptionsTags] = React.useState([]);
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode,
                isChecked: false
            };
        })
        : [];
    const [items, setItems] = useState(DrugBrandCode);
    const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
    const handleSetItems = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.displayName);
        setSelectedDisplayNames(selectedNames);
        setItems(newItems);
    };
    //medical condition
    const MedicalCondition = Array.isArray(medicalConditionsData?.resultItems)
        ? medicalConditionsData.resultItems.map((item) => ({
            key: item?.medicalConditionId,
            displayName: item?.medicalConditionDescription,
        }))
        : [];
    const [itemsMedicalCondition, setItemsMedicalCondition] = useState(MedicalCondition);
    const [selectedDisplayNamesMedicalCondition, setSelectedDisplayNamesMedicalCondition] = useState([]);
    const [selectedKeyMedicalCondition, setSelectedKeyMedicalCondition] = useState([]);
    const handleSetItemsMedicalCondition = (newItems) => {
        const checkedItems = newItems.filter((item) => item?.isChecked);
        const selectedNames = checkedItems.map((item) => item?.displayName);
        const selectedkey = checkedItems.map((item) => item?.key);
        setSelectedKeyMedicalCondition(selectedkey);
        setSelectedDisplayNamesMedicalCondition(selectedNames);
        setItemsMedicalCondition(newItems);
    };
    const FilterFunction = () => {
        const prescriberFilterfieldsObj = {
            drugBrandCode: selectedDisplayNames,
            Conditions: selectedDisplayNamesMedicalCondition,
            Title: Title,
            Description: Description,
            Tags: selectedOptionsTags
        };
        setFields?.(prescriberFilterfieldsObj);
        onClose();
    };
    useEffect(() => {
        setTitle(holdDataFilter?.Title || "");
        setDescription(holdDataFilter?.Description || "");
        const selectedDrugBrandCodes = holdDataFilter?.drugBrandCode || [];
        const updatedItems = items.map((item) => ({
            ...item,
            isChecked: selectedDrugBrandCodes.includes(item.displayName),
        }));
        setSelectedOptionsTags(holdDataFilter?.Tags || []);
        handleSetItems(updatedItems);
        //hold Medical Condition
        const selectedMedicalCondition = holdDataFilter?.Conditions || [];
        const updatedItemsMedicalCondition = itemsMedicalCondition.map((item) => ({
            ...item,
            isChecked: selectedMedicalCondition.includes(item?.displayName),
        }));
        handleSetItemsMedicalCondition(updatedItemsMedicalCondition);
    }, [onClose]);
    const defaultSelectedItems = holdDataFilter?.drugBrandCode?.map((item) => ({
        key: item,
        displayName: item,
        isChecked: true
    }));
    return (React.createElement(PopUp, { isOpen: true },
        React.createElement("div", { className: classNames(s.popUmodalContent, s.popUpmodalSm) },
            React.createElement("div", { className: classNames(s.popUpmodalheader) },
                React.createElement("h4", null, t("filter")),
                React.createElement(SVG, { src: Close, height: 10, width: 10, onClick: onClose })),
            React.createElement("div", { className: s.modalBody },
                !isAdmin && (React.createElement("div", { className: s.formGroup },
                    React.createElement("label", null, t("brand")),
                    React.createElement(CheckboxDropdown, { items: items, setItems: handleSetItems, defaultSelectedItems: defaultSelectedItems }))),
                React.createElement("div", { className: classNames(s.formGroup) },
                    React.createElement("label", null, t("conditions")),
                    React.createElement(CheckboxDropdown, { items: itemsMedicalCondition, setItems: handleSetItemsMedicalCondition })),
                React.createElement("div", { className: classNames(s.formGroup) },
                    React.createElement("label", null, t("title")),
                    React.createElement(InputText, { name: "Title", value: Title, setValue: setTitle, maxLength: 100, required: false })),
                React.createElement("div", { className: classNames(s.formGroup) },
                    React.createElement("label", null, t("description")),
                    React.createElement(InputText, { name: "Description", value: Description, setValue: setDescription, required: false })),
                React.createElement("div", { className: classNames(s.formGroup) },
                    React.createElement("label", null, t("tags")),
                    React.createElement("div", { className: classNames(s.multiSelectionTags) },
                        React.createElement(MultiSelectionDropdown, { options: Tags?.value, selectedOptions: selectedOptionsTags, setSelectedOptions: setSelectedOptionsTags })))),
            React.createElement("div", { className: s.modalFooter },
                React.createElement(Button, { text: t("cancel"), onClick: onClose }),
                React.createElement(Button, { primary: true, text: t("filter"), onClick: FilterFunction })))));
};
const mapStateToProps = (state) => {
    return {
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown,
        medicalConditionsData: state.medicalConditions.MedicalConditions,
        Tags: state.education.Tags,
        isAdmin: state.account.isAdmin
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EducationFilter);
