import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { Button, InputText } from "../../../../components/Shared/Controls";
import InputDate from "../../../../components/Shared/Controls/Inputs/InputDate";
import Picker from "../../../../components/Shared/Controls/Picker";
import PageBody from "../../../../components/Shared/PageBody";
import { useErrorPopUp, useInput } from "../../../../hooks";
import { setPatientInfoAction, } from "../../../../store";
import s from "./style.scss";
import ErrorPopUp from "../../../../components/Shared/Info/ErrorPopUp";
import { stringIsNullOrWhitespace } from "../../../../utils";
const PersonInfo = ({ userDateOfBirthday, patientInfo, addPatientInfo, onSubmit, backAction, }) => {
    const { t } = useTranslation();
    const [firstName, setFirstName, errorFirstName, setErrorFirstName] = useInput(patientInfo.firstName);
    const [lastName, setLastName, errorLastName, setErrorLastName] = useInput(patientInfo.lastName);
    const [date, setDate, errorDate, setErrorDate] = useInput(patientInfo.dateOfBirth);
    const [relationship, setRelationship] = useInput(patientInfo.relationship);
    const [isOpenErrorPopUp, , , , togglePopUp] = useErrorPopUp();
    const allowChild = !date ||
        moment(date, "YYYY-MM-DD") > moment(userDateOfBirthday, "YYYY-MM-DD");
    const minDate = moment().subtract("120", "years").format('YYYY-MM-DD');
    const maxDate = moment().format("YYYY-MM-DD");
    const memberAgeAlow = moment().subtract(1, "year") > moment(date, "YYYY-MM-DD");
    const RELATIONSHIP = [
        { value: "Spouse", title: t("spouse") },
        { value: "Parent", title: t("parent") },
        ...[allowChild && { value: "Child", title: t("child") }],
        { value: "Caregiver", title: t("careGiver") },
    ];
    const allFieldsValid = () => !errorFirstName &&
        !errorLastName &&
        !errorDate &&
        !stringIsNullOrWhitespace(firstName) &&
        !stringIsNullOrWhitespace(lastName) &&
        date &&
        relationship && ((relationship === "Child" && allowChild) || relationship !== "Child");
    const onChangeRelationship = (value) => {
        setRelationship(value);
    };
    const submit = () => {
        if (memberAgeAlow) {
            addPatientInfo({
                firstName: firstName,
                lastName: lastName,
                dateOfBirth: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
                relationship: relationship,
            });
            onSubmit && onSubmit();
        }
        else {
            togglePopUp();
        }
    };
    return (React.createElement(PageBody, { backAction: backAction, title: t("whoWouldYouLikeAdd") },
        React.createElement("div", { className: classNames(s.scrollWrapper, s.bottomBlock) },
            React.createElement("div", { className: s.personInfo },
                React.createElement(InputText, { name: "f_name", placeholder: t("firstName"), errorMessage: t("errorIncorrectFirstName"), pattern: "^.{0,35}$", value: firstName, setValue: setFirstName, error: firstName && errorFirstName, setError: setErrorFirstName }),
                React.createElement(InputText, { name: "l_name", placeholder: t("lastName"), errorMessage: t("errorIncorrectLastName"), pattern: "^.{0,35}$", value: lastName, setValue: setLastName, error: lastName && errorLastName, setError: setErrorLastName }),
                React.createElement(InputDate, { name: "birthday", placeholder: t("dateOfBirth"), value: date, setValue: setDate, minDate: minDate, maxDate: maxDate }),
                React.createElement("div", { className: s.picker },
                    React.createElement("div", { className: classNames(s.yourRelationship) }, t("yourRelationship")),
                    React.createElement(Picker, { onChange: onChangeRelationship, value: relationship, data: RELATIONSHIP.filter((e) => e), columns: window.innerWidth < 500 ? 2 : 4 })),
                React.createElement(Button, { style: classNames(s.mb0_button, s.button), disabled: !allFieldsValid(), primary: true, iosTextOverflow: true, text: t("saveAndProceed"), onClick: submit }),
                React.createElement("div", { className: s.errorPopup },
                    React.createElement(ErrorPopUp, { isOpen: isOpenErrorPopUp, popUpContent: t("mustBeOlderMember"), togglePopUp: togglePopUp }))))));
};
const mapStateToProps = (state) => {
    return {
        userDateOfBirthday: state.account.patient.birthDate,
        patientInfo: state.account.patient,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        addPatientInfo: (patient) => dispatch(setPatientInfoAction(patient)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PersonInfo);
