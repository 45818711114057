import React, { useState, useRef, useEffect } from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import s from "./style.scss";
import style from "../../style.scss";
import Notification from "../../../../../../../resources/icons/notification.svg";
import { useOnClickOutside } from "../../../../../../../hooks";
import { connect } from "react-redux";
import { getNotificationsAsyncAction, markViewedNotificationsAsyncAction, resetRequesNotificationsStatus, } from "../../../../../../../store";
import { allNotificationsSelector, hasUnViewedNotificationSelector, } from "../../../../../../../store/notifications/selectors";
import moment from "moment";
import { useTranslation } from "react-i18next";
const PAGE_SIZE = 20;
const Notifications = ({ getNotifications, notifications, markViewed, hasUnViewed, resetNotifications, notificationsStatus, patientId, }) => {
    const { t } = useTranslation();
    const notificationRef = useRef(null);
    const [allNotifications, setAllNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [showNotifications, setShowNotifications] = useState(false);
    useOnClickOutside(notificationRef, () => {
        setShowNotifications(false);
        markViewed();
    });
    useEffect(() => {
        setPage(1);
        setAllNotifications([]);
        getNotifications(page, PAGE_SIZE);
    }, [patientId]);
    const loadMore = () => {
        if (!isLoading) {
            const nextPage = page + 1;
            getNotifications(nextPage, PAGE_SIZE);
            setIsLoading(true);
            setPage(nextPage);
        }
    };
    useEffect(() => {
        if (notificationsStatus === "success") {
            setAllNotifications(allNotifications.length > 0
                ? [...allNotifications, ...notifications]
                : notifications);
            setIsLoading(false);
            resetNotifications();
        }
    }, [notificationsStatus]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "data-testid": "notificationsWrapper", className: classNames(style.headerItem, {
                [s.notificationActive]: showNotifications,
                [style.linkDiabled]: showNotifications,
            }), onClick: () => setShowNotifications(true) },
            React.createElement(SVG, { src: Notification, className: style.menuIcon }),
            hasUnViewed && React.createElement("div", { className: s.indicator })),
        showNotifications && (React.createElement("div", { "data-testid": "openNotifications", ref: notificationRef, className: classNames(style.openHeaderMenuWrapper, s.notificationList) },
            React.createElement("span", { className: s.notifCounter }, t("notifications")),
            React.createElement("div", { id: "notification", className: s.scrollWrapper, "data-testid": "openNotificationsList" },
                React.createElement(InfiniteScroll, { dataLength: page * 20, next: loadMore, hasMore: notifications.length >= PAGE_SIZE, loader: null, scrollableTarget: "notification" }, allNotifications && allNotifications.length > 0 ? (allNotifications.map((notification) => {
                    return (React.createElement("div", { key: notification.id, className: classNames(s.notificationItem, {
                            [s.unread]: !notification.viewed,
                        }) },
                        React.createElement("div", { className: s.notificationItemIcon },
                            React.createElement(SVG, { src: Notification })),
                        React.createElement("div", { className: s.notificationItemContent },
                            React.createElement("span", { "data-testid": "notificationItemDate" }, moment.utc(notification.createdDate)
                                .local()
                                .format("LLL")
                                .toUpperCase()),
                            React.createElement("p", null,
                                notification.text,
                                React.createElement("span", null,
                                    " ",
                                    notification.title)))));
                })) : (React.createElement("div", { "data-testid": "emptyNotifications", className: s.empty },
                    React.createElement("p", null, t("thereIsNoNotifications")),
                    React.createElement("p", null, t("weWillNotifyYou"))))))))));
};
const mapStateToProps = (state) => {
    return {
        notifications: allNotificationsSelector(state),
        hasUnViewed: hasUnViewedNotificationSelector(state),
        notificationsStatus: state.notifications.requestStatus,
        patientId: state.account.user.patientId,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getNotifications: (pageNumber, pageSize) => dispatch(getNotificationsAsyncAction({ pageNumber, pageSize })),
        markViewed: () => dispatch(markViewedNotificationsAsyncAction()),
        resetNotifications: () => dispatch(resetRequesNotificationsStatus()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
