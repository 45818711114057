import React, { useEffect, useState } from "react";
import { PopUp } from "../../components/Shared/Info";
import { Button, InputText } from "../../components/Shared/Controls";
import SVG from "react-inlinesvg";
import Close from "../../resources/icons/close.svg";
import { connect } from "react-redux";
import CheckboxDropdown from "../../components/Shared/Controls/CheckboxDropdown";
import golbalcss from "../styles.scss";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
import { getDrugBrandCodesDropdownAsyncAction } from "../../store/prescriber/Sagas";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import sw from '../../../../web/src/Pages/SignUp/Steps/InvitationEmail/style.scss';
import colors from "../../resources/colors";
const PharmaciesFilter = ({ getDrugBrandCodesDropdown, drugBrandCodesDropdown, onClose, setFields, holdDataFilter }) => {
    const { t } = useTranslation();
    const [Name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState("");
    const [BrandIdentifier, setBrandIdentifier] = useState("");
    const [errorPhone, setErrorPhone] = useState(null);
    const handlePhoneChange = (phoneNum) => {
        if (phoneNum && !matchIsValidTel(phoneNum)) {
            setErrorPhone('Enter a valid US or Canadian phone number');
        }
        else {
            setErrorPhone(null);
        }
        const formattedNumber = phoneNum.replace(/\D/g, '').substring(1);
        setPhone(formattedNumber);
    };
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode,
                isChecked: false
            };
        })
        : [];
    const [items, setItems] = useState(DrugBrandCode);
    const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
    const handleSetItems = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.displayName);
        setSelectedDisplayNames(selectedNames);
        setItems(newItems);
    };
    const FilterFunction = () => {
        const PharmaciesFilterfieldsObj = {
            name: Name,
            address: address,
            drugBrandCode: selectedDisplayNames,
            brandIdentifier: BrandIdentifier,
            phone: phone
        };
        setFields?.(PharmaciesFilterfieldsObj);
        onClose();
    };
    useEffect(() => {
        setName(holdDataFilter?.name || "");
        setBrandIdentifier(holdDataFilter?.brandIdentifier || "");
        setAddress(holdDataFilter?.address || "");
        setPhone(holdDataFilter?.phone || "");
        const selectedDrugBrandCodes = holdDataFilter?.drugBrandCode || [];
        const updatedItems = items.map((item) => ({
            ...item,
            isChecked: selectedDrugBrandCodes.includes(item.displayName),
        }));
        handleSetItems(updatedItems);
        getDrugBrandCodesDropdown();
    }, [onClose]);
    const defaultSelectedItems = holdDataFilter?.drugBrandCode?.map((item) => ({
        key: item,
        displayName: item,
        isChecked: true
    }));
    return (React.createElement(PopUp, { isOpen: true },
        React.createElement("div", { className: classNames(golbalcss.popUmodalContent, golbalcss.popUpmodalSm) },
            React.createElement("div", { className: classNames(golbalcss.popUpmodalheader) },
                React.createElement("h4", null, t("filter")),
                React.createElement(SVG, { src: Close, onClick: onClose })),
            React.createElement("div", { className: golbalcss.modalBody },
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("pharmacyName")),
                    React.createElement(InputText, { name: "Name", value: Name, setValue: setName, maxLength: 100, required: false })),
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("brandcodes")),
                    React.createElement(CheckboxDropdown, { items: items, setItems: handleSetItems, defaultSelectedItems: defaultSelectedItems })),
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("brandidentifier")),
                    React.createElement(InputText, { name: "BrandIdentifier", value: BrandIdentifier, setValue: setBrandIdentifier, required: false })),
                React.createElement("div", { className: classNames(golbalcss.formGroup) },
                    React.createElement("label", null, t("address")),
                    React.createElement(InputText, { name: "address", value: address, setValue: setAddress, required: false }))),
            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                React.createElement("label", null, t("phone")),
                React.createElement(MuiTelInput, { value: phone, onChange: handlePhoneChange, error: !!errorPhone, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 4 }),
                phone && errorPhone && React.createElement("div", { className: classNames(sw.error) }, errorPhone)),
            React.createElement("div", { className: golbalcss.modalFooter },
                React.createElement(Button, { text: t("cancel"), onClick: onClose }),
                React.createElement(Button, { primary: true, text: t("filter"), disabled: !!phone && !!errorPhone, onClick: FilterFunction })))));
};
const mapStateToProps = (state) => {
    return {
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PharmaciesFilter);
