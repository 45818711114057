import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import UserMenu from "./components/UserMenu/UserMenu";
import Notifications from "./components/Notifications/Notifications";
import HelpInfo from "../../../PopUps/HelpInfo";
import { showMenuAction } from "../../../../../store";
import images from "../../../../../resources/images";
import Hamburger from "../../../../../resources/icons/hamburger.svg";
import Cancel from "../../../../../resources/icons/cancel.svg";
import s from "./style.scss";
import i18next from "i18next";
const PageHeader = ({ hasBrand, isMenuShown }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [infoPopUp, showInfoPopUp] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(isMenuShown);
    useEffect(() => {
        setMenuIsOpen(isMenuShown);
    }, [isMenuShown]);
    const menuState = (state) => {
        dispatch(showMenuAction(state));
        setMenuIsOpen(state);
    };
    return (React.createElement("header", { className: classNames(s.pageHeader, { [s.noBrand]: !hasBrand }) },
        menuIsOpen
            ? React.createElement(SVG, { src: Cancel, className: s.menuIcon, onClick: () => menuState(false) })
            : React.createElement(SVG, { src: Hamburger, className: s.menuIcon, onClick: () => menuState(true) }),
        React.createElement(HelpInfo, { isOpen: infoPopUp, setIsOpen: showInfoPopUp }),
        React.createElement(SVG, { src: images.generalLogoLocal[i18next.language == "en" ? "en" : "fr"], className: s.logoIcon }),
        React.createElement("div", { className: s.headerItems },
            React.createElement(Notifications, null),
            React.createElement(UserMenu, null))));
};
const mapStateToProps = (state) => {
    return {
        isMenuShown: state.navigation.menuShown,
        hasBrand: state.account.user.drugBrandCode,
    };
};
export default connect(mapStateToProps)(PageHeader);
