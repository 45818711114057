import ApiManager from "../api/ApiManager";
const version = "1.2";
const NOTIFICATIONS = `/v${version}/notifications`;
const MARK_VIEWED = `/v${version}/notifications/mark-viewed`;
class NotificationsRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new NotificationsRepository();
        }
        return this.instance;
    }
    static getNotifications = (payload) => {
        return ApiManager.getInstance().get(NOTIFICATIONS, {
            PatientId: payload.patientId,
            PageNumber: payload.pageNumber,
            PageSize: payload.pageSize
        });
    };
    static markViewed = (payload) => {
        return ApiManager.getInstance().put(MARK_VIEWED, payload, undefined, false);
    };
}
export default NotificationsRepository;
