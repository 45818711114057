import React from "react";
import { useTranslation } from "react-i18next";
import Picker from "../../../../../components/Shared/Controls/Picker";
import s from "../style.scss";
const GenderPicker = ({ onChangeGender, gender }) => {
    const { t } = useTranslation();
    const GENDERS = [
        { value: "Male", title: t("male") },
        { value: "Female", title: t("female") },
        { value: "Other", title: t("other") },
    ];
    return (React.createElement("div", { className: s.genderWrapper },
        React.createElement("div", { className: s.inputTitle }, t("gender")),
        React.createElement(Picker, { onChange: onChangeGender, value: gender, data: GENDERS, columns: 3 })));
};
export default GenderPicker;
