import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "ERROR";
const initialState = {
    requestError: null
};
const ErrorSlice = createSlice({
    name: SLICE_NAME,
    initialState: initialState,
    reducers: {
        showUserErrorAction: (state, action) => {
            state.requestError = action.payload;
        },
        hideErrorAction: (state) => {
            state.requestError = null;
        }
    }
});
export default ErrorSlice;
