import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageBody from "../../components/Shared/PageBody";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Arrow from "../../resources/icons/short-arrow.svg";
import SVG from "react-inlinesvg";
import HealthCard from "../../components/MedicalDocuments/HealthCard";
import Insurances from "../../components/MedicalDocuments/Insurances";
import { PopUp } from "../../components/Shared/Info";
import { Button } from "../../components/Shared/Controls";
import colors from "../../resources/colors";
import config from "../../../config.json";
import s from "./style.scss";
import { Box } from "../../components";
import InsuranceLetters from "../../components/MedicalDocuments/InsuranceLetters";
const MedicalDocuments = () => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState({
        key: "insurance",
    });
    const [haveChanges, setHaveChanges] = useState(false);
    const [youAreSureExitModal, setYouAreSureExitModal] = useState(false);
    const [youAreSureModal, setYouAreSureModal] = useState({ show: false });
    const toggleYouAreSureModal = () => setYouAreSureModal((sure) => {
        return { show: !sure.show };
    });
    const clickOnTab = (key) => {
        !haveChanges
            ? setCurrentTab({ key })
            : setYouAreSureModal({ show: true, key: key });
    };
    const tabs = [
        {
            key: "insurance",
            title: t("insurance"),
        },
        {
            key: "health_card",
            title: t("provincialHealthCard"),
        },
        {
            key: "letters",
            title: t("insuranceLetters"),
        }
    ];
    const renderTab = () => {
        switch (currentTab.key) {
            case "letters":
                return React.createElement(InsuranceLetters, { setHaveChanges: setHaveChanges, hasSidebar: true });
            case "health_card":
                return React.createElement(HealthCard, { setHaveChanges: setHaveChanges, hasSidebar: true },
                    renderYouAreSureModal(),
                    config.design_system.showDescriptionOfFeature && React.createElement("div", { className: s.paragraph_2 }, t("uploadImagesHealthCard")));
            case "insurance":
                return React.createElement(Insurances, { setHaveChanges: setHaveChanges, hasSidebar: true },
                    renderYouAreSureModal(),
                    config.design_system.showDescriptionOfFeature && React.createElement("div", { className: s.paragraph_2 }, t("uploadPhotosInsurance")));
            default:
                return React.createElement("div", null);
        }
    };
    const navigate = useNavigate();
    const renderYouAreSureModal = () => {
        return React.createElement(PopUp, { style: s.removeModal, isOpen: youAreSureModal.show, toggle: toggleYouAreSureModal },
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("sureExitWithoutSaving")),
            React.createElement("div", { className: s.actions },
                React.createElement(Button, { style: s.button, text: t("yes"), primary: true, onClick: () => {
                        setCurrentTab({ key: youAreSureModal.key });
                        toggleYouAreSureModal();
                        if (youAreSureModal.key == undefined)
                            navigate("/profile");
                    } }),
                React.createElement(Button, { style: s.button, text: t("no"), onClick: toggleYouAreSureModal })));
    };
    const renderYouAreSureExitModal = () => {
        return React.createElement(PopUp, { style: s.removeModal, isOpen: youAreSureExitModal, toggle: () => setYouAreSureExitModal(s => !s) },
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("sureExitWithoutSaving")),
            React.createElement("div", { className: s.actions },
                React.createElement(Button, { style: s.button, text: t("yes"), primary: true, onClick: () => {
                        navigate(-1);
                    } }),
                React.createElement(Button, { style: s.button, text: t("no"), onClick: () => setYouAreSureExitModal(s => !s) })));
    };
    const backAction = () => {
        !haveChanges
            ? navigate(-1)
            : setYouAreSureExitModal(true);
    };
    return (React.createElement(Box, { fullWidth: true },
        React.createElement("div", null,
            renderYouAreSureModal(),
            renderYouAreSureExitModal(),
            React.createElement("div", { className: s.titleButton },
                React.createElement("a", { onClick: backAction },
                    React.createElement(SVG, { className: s.arrow, src: Arrow })),
                React.createElement("div", { className: s.title }, t("medicalDocuments"))),
            React.createElement("div", { className: s.navBar }, tabs.map((tab) => (React.createElement("div", { key: tab.key, className: classNames({ [s.active]: tab.key === currentTab.key }), style: {
                    borderColor: colors.tabBorderColor
                }, title: tab.title, onClick: () => clickOnTab(tab.key) }, tab.title)))),
            React.createElement(PageBody, { withTabs: true, style: s.pageBody }, renderTab()))));
};
export default MedicalDocuments;
