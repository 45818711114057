import React from 'react';
import Buttonstyle from "../../../Shared/Controls/Button";
import classNames from 'classnames';
import s from './style.scss';
// import golbalcss from "../../../../styles.scss";
import { PopUp } from '..';
// import { Button } from '../../Controls';
import { useTranslation } from 'react-i18next';
// import stopIcon from '../../resources/images/stop_sign_icon.png';
import stopIcon from '../../../../resources/images/stop_sign_icon.png';
const DeletePopUp = ({ style, isOpen, children, togglePopUp, onClick, header }) => {
    const { t } = useTranslation();
    return (React.createElement(PopUp, { style: s.popup, contentStyle: s.popUpContent, isOpen: isOpen, toggle: togglePopUp },
        React.createElement("div", { className: classNames(s.popUpmodalheader, s.deletepopupimg) },
            React.createElement("h4", null, header),
            React.createElement("img", { src: stopIcon, alt: "Delete Icon" })),
        React.createElement("div", { className: s.popup__content },
            " ",
            children,
            " "),
        React.createElement("div", { className: classNames(s.modalFooter, s.modalFooterbutton) },
            React.createElement(Buttonstyle, { text: t("cancel"), onClick: togglePopUp }),
            React.createElement(Buttonstyle, { text: t("delete"), onClick: onClick, deleted: true }))));
};
export default DeletePopUp;
