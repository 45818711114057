import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import ProfileInfo from "./components/ProfileInfo/ProfileInfo";
import ProfileBrands from "./components/ProfileBrands/ProfileBrands";
import config from "../../../config.json";
import s from "./style.scss";
const Profile = () => {
    const { t } = useTranslation();
    // useDeviceHeight("profileWrapper", 120, 185, 170);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "profileWrapper", className: classNames(s.profileWrapper, s.scrollWrapper) },
            React.createElement("h1", null, t("myProfile")),
            React.createElement("div", { className: s.profileInfoLinks },
                React.createElement(ProfileInfo, null)),
            !config.appSingleBrand &&
                React.createElement(React.Fragment, null,
                    React.createElement("h3", null, t("myBrands")),
                    React.createElement("div", { className: s.profileInfoBrands },
                        React.createElement(ProfileBrands, null))))));
};
export default Profile;
