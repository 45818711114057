import config from "../../config.json";
import Logo from "./images/logo.svg";
import Logox4 from "./images/logo.png";
import Logox4Fre from "./images/logoFr.png";
import MyselfIcon from './images/hand_05.png';
import SomeoneIcon from './images/hand_06.png';
//import UserCard from './images/patient_profile.png';
import UserCard from './images/patient profile_cropped.png';
import FinancialAssistance from './images/financial-assistance.png';
import Prescriptions from './images/prescriptions.png';
import Receipts from './images/receipts.png';
import Education from './images/education.png';
import Medicine from './images/medicine.png';
import Pharmacy from './images/pharmacy.png';
import EducationBook from './images/education2.png';
import WelcomeBG from "./images/welcome-bg.png";
import QuestionBG from "./images/question.png";
import BlankLogo from "./images/RepathaLogo.png";
import Device from './images/device.png';
let brandLogo = null;
let brandLogo2 = null;
switch (config.applicationType) {
    default:
        brandLogo = BlankLogo;
        brandLogo2 = BlankLogo;
}
export default {
    generalLogo: Logo,
    generalLogoLocal: {
        en: Logo,
        fr: Logo
    },
    brandImage: brandLogo,
    brandImage1: brandLogo2,
    whiteGeneralLogo: null,
    whiteLogo: {
        en: null,
        fr: null
    },
    partnersLogo: {},
    partnersCard: {},
    partnersSidebar: [],
    screenImage: {
        en: Device,
        fr: Device
    },
    Logox4: Logox4,
    Logox4Fre: Logox4Fre,
    MyselfIcon: MyselfIcon,
    SomeoneIcon: SomeoneIcon,
    UserCard: UserCard,
    FinancialAssistance: FinancialAssistance,
    Prescriptions: Prescriptions,
    Receipts: Receipts,
    Education: Education,
    EducationBook: EducationBook,
    Medicine: Medicine,
    Pharmacy: Pharmacy,
    WelcomeBG: WelcomeBG,
    QuestionBG: QuestionBG,
};
