import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
export const SLICE_NAME = "EDUCATION_LIBRARY";
export const patientEducationAdapter = createEntityAdapter({
    selectId: (library) => library.educationId
});
export const patientEducationSelectors = patientEducationAdapter.getSelectors();
const initialState = {
    requestStatus: "idle",
    error: null,
    requestMarkAsReadStatus: "idle",
    errorMarkAsRead: null,
    uniqueTags: [],
    educationList: null
};
const patientEducationSlice = createSlice({
    name: SLICE_NAME,
    initialState: patientEducationAdapter.getInitialState(initialState),
    reducers: {
        startGetPatientEducationRequestAction: (state) => {
            state.requestStatus = "pending";
            state.error = null;
        },
        successGetPatientEducationRequestAction: (state, action) => {
            state.educationList = action.payload.pagedResult.resultItems;
            patientEducationAdapter.setAll(state, action.payload.pagedResult.resultItems);
            if (action.payload.uniqueTags)
                state.uniqueTags = action.payload.uniqueTags;
            state.requestStatus = "success";
            state.error = null;
        },
        showGetPatientEducationErrorAction: (state, action) => {
            state.requestStatus = "failure";
            state.error = action.payload;
        },
        hideGetPatientEducationErrorAction: (state) => {
            state.requestStatus = "idle";
            state.error = null;
        },
        startMarkAsReadRequestAction: (state) => {
            state.requestMarkAsReadStatus = "pending";
            state.errorMarkAsRead = null;
        },
        successMarkAsReadRequestAction: (state, action) => {
            state.requestMarkAsReadStatus = "success";
            state.errorMarkAsRead = null;
            const currentEducationLibrary = patientEducationSelectors.selectById(state, action.payload);
            if (currentEducationLibrary) {
                patientEducationAdapter.upsertOne(state, {
                    ...currentEducationLibrary,
                    educationId: action.payload,
                    isItemViewed: true
                });
            }
        },
        showMarkAsReadErrorAction: (state, action) => {
            state.requestMarkAsReadStatus = "failure";
            state.errorMarkAsRead = action.payload;
        },
        hideMarkAsReadErrorAction: (state) => {
            state.requestMarkAsReadStatus = "idle";
            state.errorMarkAsRead = null;
        },
        resetSliceAction: (state) => {
            state = patientEducationAdapter.getInitialState(initialState);
        }
    }
});
export default patientEducationSlice;
