import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';
import { Button } from '../Controls';
import ErrorPopUp from '../Info/ErrorPopUp';
import { useErrorPopUp } from '../../../hooks';
import PlusIcon from '../../../resources/icons/plus_circle';
import colors from '../../../resources/colors';
import s from './style.scss';
const AddImageCard = ({ style, shortTitle, side, titleStyle, addFile }) => {
    const { t } = useTranslation();
    const [errorIsOpen, setErrorInOpen, , , togglePopUp] = useErrorPopUp();
    const onDrop = (accepted, rejected) => {
        let files = [];
        if (Object.keys(rejected).length !== 0) {
        }
        else {
            Promise.all(accepted.map((element) => {
                return new Promise((resolve, reject) => {
                    const reader = new window.FileReader();
                    reader.readAsDataURL(element);
                    reader.onloadend = () => {
                        const base64data = reader.result;
                        resolve({ base64data });
                    };
                }).then((value) => {
                    accepted.length === 1 ? addFile && addFile(element.name, `${value.base64data}`)
                        : files.push({ name: element.name, uri: `${value.base64data}` });
                });
            })).then(() => {
                files.length > 1 && addFile && addFile(undefined, undefined, undefined, files);
            });
        }
    };
    const AllowedFileFormats = { photo: [
            ".png",
            ".jpg",
            ".jpeg",
            ".webp",
            ".heic",
            ".heif",
            ".pdf"
        ] };
    return React.createElement(React.Fragment, null,
        React.createElement(ErrorPopUp, { isOpen: errorIsOpen, popUpContent: t("invalidFileTypeOrSize"), togglePopUp: togglePopUp }),
        React.createElement(Dropzone, { multiple: true, maxSize: 8192000, accept: AllowedFileFormats, onDrop: (accepted, rejected) => onDrop(accepted, rejected), onDropRejected: () => setErrorInOpen(true) }, ({ isDragReject, getRootProps, getInputProps }) => {
            return (React.createElement("div", { className: classNames(style, s.card), style: {
                    backgroundColor: colors.patientDocuments.backgroundColor,
                    borderColor: colors.patientDocuments.borderColor
                }, ...getRootProps() },
                React.createElement("input", { ...getInputProps() }),
                React.createElement(React.Fragment, null,
                    React.createElement(PlusIcon, { color: colors.patientDocuments.textcolor }),
                    React.createElement("div", { className: classNames(s.cardText, { [s.hasSide]: side }), style: {
                            color: colors.patientDocuments.textcolor,
                        } }, window.innerWidth > 768 ? `${t("dragImage")}${side ? (` (${t(side)})`) : ''} ${t("or")}` : shortTitle),
                    React.createElement(Button, { style: s.browseButton, jsStyle: {
                            backgroundColor: colors.patientDocuments.backgroundColor,
                            color: colors.patientDocuments.textcolor,
                            borderColor: colors.patientDocuments.insideBorderColor
                        }, text: t("browseImage") }))));
        }));
};
export default AddImageCard;
