import React from "react";
import { useTranslation } from "react-i18next";
import { getCurrentUserSelector, setImpersonateInfo, setImpersonatedAccountId, setPatientIdForImpersonate } from "../../../store";
import { getRegistrationInfoAsyncAction, setPatientdataAction, updateIsAdminAction } from "../../../store/account/Sagas";
import { connect } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import style from "../PageContainer/style.scss";
const HeaderNote = ({ InviteLoginStoredData, currentUser, selectedpatientState, setPatientdataFun, updateStateIsAdmin, exitingPatientId, setPatientIdForImpersonate, exitingImpersonatedAccountId, setImpersonatedAccountId, setImpersonateInfoAction, firstnameImpersonate, lastnameImpersonate, phoneNumberImpersonate, existingEmailImersonate, getRegistrationInfo, adminBrandCode }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    let firstName;
    let lastName;
    if (selectedpatientState !== null || selectedpatientState !== undefined) {
        firstName = selectedpatientState?.firstName;
        lastName = selectedpatientState?.lastName;
    }
    //yellow line back button
    const RemovePatientData = () => {
        setPatientIdForImpersonate(exitingPatientId);
        setImpersonatedAccountId(exitingImpersonatedAccountId);
        const data = {
            firstName: firstnameImpersonate,
            lastName: lastnameImpersonate,
            phoneNumber: phoneNumberImpersonate,
            email: existingEmailImersonate
        };
        setImpersonateInfoAction(data);
        getRegistrationInfo({
            member: {
                patientId: exitingPatientId,
                //TODO: Fix below, grab from user/patient
                drugBrandCode: adminBrandCode,
            },
            isRegistrationFlow: false,
        });
        navigate('/patients');
        setPatientdataFun({
            patientId: null,
            firstName: null,
            lastName: null
        });
        updateStateIsAdmin(true);
    };
    return (React.createElement("div", { className: style.impersonationHeader },
        React.createElement("span", null,
            React.createElement(ArrowBackIcon, { onClick: RemovePatientData, style: { marginRight: "20px", marginLeft: "20px", marginTop: "8px" } })),
        React.createElement("span", { style: { float: "left" } }, t("returntoAdmin")),
        React.createElement("span", { style: { margin: "0 auto" } },
            t("viewing"),
            " ",
            currentUser?.firstName,
            " ",
            currentUser?.lastName),
        React.createElement("span", { style: { float: "right", marginRight: "46px" } }, `${firstnameImpersonate} ${lastnameImpersonate}`)));
};
const mapStateToProps = (state) => {
    return {
        InviteLoginStoredData: state.auth.InviteLoginStoredData,
        selectedpatientState: state.account.selectedpatient,
        exitingPatientId: state.account.exitingPatientId,
        currentUser: getCurrentUserSelector(state),
        exitingImpersonatedAccountId: state.account.exitingImpersonatedAccountId,
        firstnameImpersonate: state.account.ExistingfirstName,
        lastnameImpersonate: state.account.ExistinglastName,
        phoneNumberImpersonate: state.account.user.phoneNumber,
        existingEmailImersonate: state.account.existingEmailImersonate,
        adminBrandCode: state.auth.adminBrandCode
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setPatientdataFun: (data) => dispatch(setPatientdataAction({
            data
        })),
        updateStateIsAdmin: (isAdmin) => dispatch(updateIsAdminAction(isAdmin)),
        setPatientIdForImpersonate: (data) => dispatch(setPatientIdForImpersonate(data)),
        setImpersonatedAccountId: (data) => dispatch(setImpersonatedAccountId(data)),
        setImpersonateInfoAction: (data) => dispatch(setImpersonateInfo(data)),
        getRegistrationInfo: (payload) => dispatch(getRegistrationInfoAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNote);
