import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { PopUp } from '../../Info';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './style.scss';
const TermsOfUse = ({ style, isOpen, setIsOpen }) => {
    const { t } = useTranslation();
    const termsContent = (React.createElement(React.Fragment, null, ReactHtmlParser(t('termOfUserIntent'))));
    return (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: isOpen, allowClose: true, toggle: () => setIsOpen(false) },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2 }, t("termOfUse"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", { className: s.intend }, termsContent))));
};
export default TermsOfUse;
