import React from 'react';
import classNames from 'classnames';
import Close from '../../../../resources/icons/close.svg';
import SVG from 'react-inlinesvg';
import s from './style.scss';
const PopUp = ({ style, contentStyle, isOpen, toggle, allowClose, top, children }) => {
    return (React.createElement("div", { "data-testid": "popup-test", className: classNames(style, s.popup, s.popUpModalDialog, { [s.open]: isOpen }, { [s.top]: top }), onMouseDown: toggle },
        React.createElement("div", { className: classNames(contentStyle, s.content), onMouseDown: (e) => e.stopPropagation() },
            children,
            allowClose && React.createElement(SVG, { "data-testid": "close-test", src: Close, className: s.closeIcon, onClick: toggle }))));
};
export default PopUp;
