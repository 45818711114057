import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import PageBody from "../../components/Shared/PageBody";
import FinansialAssistance from "../../components/FinansialAssistance";
import { checkAvailabilityOfFeature } from "../../store";
const FinansialAssistancePage = ({ checkFeature }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    // useEffect(() => {
    //   !checkFeature("FinancialAssistance") && navigate("");
    // }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageBody, { title: t("financialAssistance") },
            React.createElement(FinansialAssistance, { hasSidebar: true }))));
};
const mapStateToProps = (state) => {
    return {
        checkFeature: (key) => checkAvailabilityOfFeature(state, key),
    };
};
export default connect(mapStateToProps)(FinansialAssistancePage);
