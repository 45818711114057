import React, { useState } from "react";
import classNames from "classnames";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import config from "../../../../../config.json";
import Tag from "../../../../components/Shared/Controls/Tag";
import ItemPreview from "../../../EducationPage/components/ItemPreview";
import { connect, useSelector } from "react-redux";
s;
import { getPatientEducationAsyncAction, patientEducationByMimeTypeSelector } from "../../../../store/patientEducation";
import SVG from "react-inlinesvg";
import Education from "../../../../resources/images/education.svg";
import colors from "../../../../resources/colors";
import s from "./style.scss";
import { getEducationAsyncAction } from "../../../../store/education";
const EducationTab = ({ brandName, patientId, education, getEducationItems, getPatientEducationItems, tags, type, setEducationItem, requestStatus, educationFilterObj, searchData, isAdmin, isImpersonating, cultureCode }) => {
    const { t } = useTranslation();
    const [currentSearchTag, setCurrentSearchTag] = useState(null);
    const items = isAdmin
        ? education : useSelector(patientEducationByMimeTypeSelector(type));
    useEffect(() => setCurrentSearchTag(null), [type]);
    useEffect(() => {
        if (isAdmin && !isImpersonating)
            getEducationItems({
                mimeType: type,
                pageNumber: 1,
                pageSize: 99,
                drugBrandCode: educationFilterObj?.drugBrandCode,
                title: searchData ? searchData : educationFilterObj?.Title,
                description: searchData ? searchData : educationFilterObj?.Description,
                // tags: [currentSearchTag].filter(el => el),
                medicalConditionIds: educationFilterObj?.Conditions,
                orderByAttribute: "",
                orderByDescending: false
            });
        else
            getPatientEducationItems({
                patientId: patientId,
                mimeType: type,
                pageNumber: 1,
                pageSize: 99,
                drugBrandCode: educationFilterObj?.drugBrandCode,
                title: searchData ? searchData : educationFilterObj?.Title,
                description: searchData ? searchData : educationFilterObj?.Description,
                cultureCode: cultureCode,
                tags: currentSearchTag ? [currentSearchTag] : [],
                // medicalConditionIds: educationFilterObj?.Conditions
                orderByAttribute: "",
                orderByDescending: false
            });
    }, [type, currentSearchTag, brandName, patientId, educationFilterObj, searchData]);
    const renderSearchTags = tags && (React.createElement("div", { className: s.mb20 },
        React.createElement("div", { className: classNames(s.searchLabel, s.mb12) }, "Search by tags"),
        React.createElement("div", { className: s.tags },
            React.createElement(Tag, { text: "All", onClick: () => setCurrentSearchTag(null), active: null === currentSearchTag }),
            tags.map((tag) => (React.createElement(Tag, { key: tag, text: tag, onClick: () => setCurrentSearchTag(tag), active: tag === currentSearchTag }))))));
    const drugBrandEducationItems = items?.filter(el => el.showInProgramBucket === true);
    const medicalConditionsItems = items?.filter(el => !el.showInProgramBucket);
    return items?.length > 0 ? (React.createElement("div", { className: classNames(s.scrollWrapperNo, { [s.web]: type === "Web" }) },
        type !== "Web" && medicalConditionsItems.length > 0 &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: s.headline_1, style: { color: colors.education.sectorTitleColor } }, t("learningAboutCondition")),
                React.createElement("div", { className: s.videos_preview }, medicalConditionsItems.map((item) => {
                    return React.createElement(ItemPreview, { key: item.educationId, type: type, item: item, onClick: () => setEducationItem(item) });
                }))),
        type !== "Web" && medicalConditionsItems.length > 0 && drugBrandEducationItems.length > 0 &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: s.line })),
        type !== "Web" && drugBrandEducationItems.length > 0 &&
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: s.headline_1, style: { color: colors.education.sectorTitleColor } }, t("learningAboutDrugBrand")),
                React.createElement("div", { className: s.videos_preview }, drugBrandEducationItems && drugBrandEducationItems.map((item) => {
                    return React.createElement(ItemPreview, { key: item.educationId, type: type, item: item, onClick: () => setEducationItem(item) });
                }))),
        type === "Web" && items.length > 0 &&
            (config.design_system.educationOnlineResourcesInCards &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: s.videos_preview }, items && items.map((item) => {
                        return React.createElement(ItemPreview, { key: item.educationId, type: type, item: item, onClick: () => setEducationItem(item) });
                    }))) ||
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: s.educationOnlineResources },
                        React.createElement("div", { className: s.headline_1, style: { color: colors.education.sectorTitleColor } },
                            " ",
                            t("educationOnlineResourcesSubHeading"),
                            " "),
                        React.createElement("div", { className: classNames(s.educationOnlineResourcesinner, s.videos_preview) }, items && items.map((item) => {
                            return React.createElement(ItemPreview, { key: item.educationId, type: type, item: item, onClick: () => setEducationItem(item) });
                        }))))))) : (React.createElement("div", { className: s.scrollWrapper },
        React.createElement("div", { className: s.emptyLibrary },
            React.createElement(SVG, { src: Education }),
            requestStatus === "pending" ?
                React.createElement("div", { className: s.headline_1 }, t("loading")) :
                React.createElement("div", { className: s.headline_1 }, t("comingSoon")))));
};
const mapStateToProps = (state) => {
    return {
        education: state.education.items,
        tags: state.patientEducation.uniqueTags,
        brandName: state.account.user.drugBrandCode,
        patientId: state.account.user.patientId,
        requestStatus: state.patientEducation.requestStatus,
        isAdmin: state.account.isAdmin,
        cultureCode: state.language.language,
        isImpersonating: state.account.user.impersonatedAccountId != ''
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPatientEducationItems: (payload) => dispatch(getPatientEducationAsyncAction(payload)),
        getEducationItems: (payload) => dispatch(getEducationAsyncAction(payload))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EducationTab);
