import moment from "moment";
import "moment/locale/fr";
import "moment/locale/en-ca";
import { getI18n } from "react-i18next";
export const mapFilesPayload = (response, filesType) => {
    const files = [];
    for (let i = 0; i < response.value.length; i++) {
        const { id, link, description, extension, parentFileId, textValue, sizeType, uploadDate } = response.value[i];
        files.push({
            id,
            name: description,
            uri: link,
            description,
            extension,
            filesType,
            type: filesType,
            sizeType,
            parentFileId,
            textValue
        });
    }
    return files;
};
export const mapScheduleCallTimes = (response) => {
    const locals = getI18n();
    const data = {
        currentDay: "",
        currentDayTimes: [],
        nextDay: "",
        nextDayTimes: []
    };
    response.value.forEach((day, index) => {
        if (index === 0) {
            data.currentDay = moment(day.dates[0]).format("dddd");
            day.dates.reverse().forEach((time, index) => {
                switch (index) {
                    case 0:
                        // data.currentDayTimes.push({
                        //   type: "evening",
                        //   title: "5PM - 8PM",
                        //   value: time
                        // });
                        break;
                    case 1:
                        data.currentDayTimes.push({
                            type: "afternoon",
                            title: locals.t("12to5"),
                            value: time
                        });
                        break;
                    case 2:
                        data.currentDayTimes.push({
                            type: "morning",
                            title: locals.t("9to12"),
                            value: time
                        });
                        break;
                }
            });
        }
        else {
            data.nextDay = moment(day.dates[0]).format("dddd");
            day.dates.reverse().forEach((time, index) => {
                switch (index) {
                    case 0:
                        // data.nextDayTimes.push({
                        //   type: "evening",
                        //   title: "5PM - 8PM",
                        //   value: time
                        // });
                        break;
                    case 1:
                        data.nextDayTimes.push({
                            type: "afternoon",
                            title: locals.t("12to5"),
                            value: time
                        });
                        break;
                    case 2:
                        data.nextDayTimes.push({
                            type: "morning",
                            title: locals.t("9to12"),
                            value: time
                        });
                        break;
                }
            });
        }
    });
    return {
        ...data,
        currentDayTimes: data.currentDayTimes.reverse(),
        nextDayTimes: data.nextDayTimes.reverse()
    };
};
export const mapFinancialAssistanceConfig = (response) => response.value;
export const mapFinancialAssistanceInfo = (response) => response.value;
export const mapPreviewLink = (response) => response.value.link;
