import classNames from "classnames";
import { range } from "lodash";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import ValueDropdown from "../Inputs/ValueDropdown";
import { useTranslation } from "react-i18next";
import s from "./style.scss";
const CustomDatePicker = ({ style, date, setDate, minDate, maxDate, tabIndex }) => {
    const { t, i18n } = useTranslation();
    const getYear = (date) => moment(date, "MM/DD/YYYY").year();
    const getMonth = (date) => date == null ? 0 : moment(date, "MM/DD/YYYY").month();
    const years = range(minDate && new Date(minDate).getFullYear() || 1900, (maxDate && new Date(maxDate).getFullYear() || moment(new Date()).year()) + 1, 1).reverse();
    const months = {
        en: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        fr: [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre"
        ],
    };
    return (React.createElement(DatePicker, { isClearable: !!date, tabIndex: tabIndex, wrapperClassName: classNames(style, s.input), customInput: React.createElement("div", { className: classNames(style, s.input) }), renderCustomHeader: ({ date, changeYear, changeMonth, }) => {
            return (React.createElement("div", { style: {
                    marginTop: 10,
                    marginBottom: 10,
                    display: "flex",
                    justifyContent: "center"
                } },
                React.createElement(ValueDropdown, { style: s.yearSelect, listStyle: s.yearList, data: years, value: `${getYear(date)}`, setValue: (value) => changeYear(+value) }),
                React.createElement(ValueDropdown, { style: s.monthSelect, listStyle: s.monthList, data: months[i18n.language || 'en'] || months['en'], value: months[i18n.language || 'en'] == null ? months['en'][0] : months[i18n.language || 'en'][getMonth(date) || 0], setValue: (value) => changeMonth(months[i18n.language || 'en']?.indexOf(value) || months['en'].indexOf(value)) })));
        }, selected: date && moment(date, "MM/DD/YYYY").toDate() || new Date(), onChange: date => {
            if (date) {
                setDate(moment(date, 'MM/DD/YYYY'));
            }
            else {
                setDate(null);
            }
        }, minDate: minDate && new Date(minDate) || new Date(), maxDate: maxDate && new Date(maxDate) || new Date() }));
};
export default CustomDatePicker;
