import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getProvincesAsyncAction, } from "../../store";
import PatientData from "./components/PatientData";
import ChangePhone from "./components/ChangePhone";
import ChangeEmail from "./components/ChangeEmail";
import s from "./style.scss";
import { Box } from "../../components";
const PersonalData = ({ getProvinces, }) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    useEffect(() => {
        getProvinces();
    }, []);
    const renderPersonalData = () => {
        switch (page) {
            case 1:
                return (React.createElement("div", { className: s.scrollWrapper },
                    React.createElement(PatientData, { setPage: setPage })));
            case 2:
                return (React.createElement(ChangePhone, { setPage: setPage }));
            case 3:
                return (React.createElement(ChangeEmail, { setPage: setPage }));
            default:
                return null;
        }
    };
    return (React.createElement(Box, { fullWidth: true }, renderPersonalData()));
};
function mapDispatchToProps(dispatch) {
    return {
        getProvinces: () => {
            dispatch(getProvincesAsyncAction());
        },
    };
}
export default connect(null, mapDispatchToProps)(PersonalData);
