import ApiManager from "../api/ApiManager";
import config from "../../../config.json";
const version = "1.2";
const EDUCATION = `/v${version}/education`;
const EDUCATION_INFO = `/v${version}/Education`;
const EDUCATION_DELETE = `/v${version}/Education`;
const TAGS = `/v${version}/Education/unique-tags`;
class EducationRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new EducationRepository();
        }
        return this.instance;
    }
    static getEducationItems = (payload) => {
        return ApiManager.getInstance().get(EDUCATION, {
            drugBrandCode: payload.drugBrandCode,
            title: payload.title,
            description: payload.description,
            conditions: payload.medicalConditionIds,
            mimeType: payload.mimeType,
            cultureCode: payload.cultureCode,
            pageNumber: 1,
            pageSize: 99,
        });
    };
    static updateEducationInfo = (payload) => {
        return ApiManager.getInstance().put(EDUCATION_INFO, payload);
    };
    //delete req
    static deleteEducationData = (educationId) => {
        return ApiManager.getInstance().delete(`${EDUCATION_DELETE}?educationId=${educationId}`);
    };
    static getTags = (drugBrandCode) => {
        return ApiManager.getInstance().get(TAGS, {
            drugBrandCode: drugBrandCode,
            applicationType: config.applicationType
        });
    };
    static postEducation = (data) => {
        return ApiManager.getInstance().post(EDUCATION_INFO, data);
    };
}
export default EducationRepository;
