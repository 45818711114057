import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from ".";
import { doSearchAction, getBrandsAsyncAction, getTestBrandsAsyncAction } from "../store";
export default function useBrandSearch(isTestBrands) {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getBrandsAsyncAction());
    }, []);
    useEffect(() => {
        if (debouncedSearchTerm === "!!!TEST!!!") {
            dispatch(doSearchAction(""));
            dispatch(getTestBrandsAsyncAction());
        }
        else {
            isTestBrands && dispatch(getBrandsAsyncAction());
            dispatch(doSearchAction(debouncedSearchTerm));
        }
    }, [debouncedSearchTerm]);
    return { searchTerm, setSearchTerm };
}
