import ApiManager from "../api/ApiManager";
import config from "../../../config.json";
const version = "1.2";
const CONDITIONS = `/v${version}/Medical-Conditions`;
class MedicalConditionsRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new MedicalConditionsRepository();
        }
        return this.instance;
    }
    //get perticular brandcode data
    static getConditions = (drugBrandCode) => {
        const storedDrugBrandCode = drugBrandCode;
        return ApiManager.getInstance().get(CONDITIONS, {
            drugBrandCode: storedDrugBrandCode,
            applicationType: config.applicationType
        });
    };
    //get all brandcode data
    static getAllmedicalConditionInfo = (page, rowsPerPage, order, orderBy, DrugBrandCode, Description) => {
        let orderCopy = false;
        let filterItems = '';
        if (order == 'asc') {
            orderCopy = false;
        }
        else {
            orderCopy = true;
        }
        if (DrugBrandCode?.length > 0) {
            filterItems += `&DrugBrandCode=${DrugBrandCode}`;
        }
        if (Description) {
            filterItems += `&Description=${Description}`;
        }
        return ApiManager.getInstance().get(`${CONDITIONS}?OrderByAttribute=${orderBy}&OrderByDescending=${orderCopy}&PageNumber=${page + 1}&PageSize=${rowsPerPage}${filterItems}`);
    };
    //post request
    static postMedicalCondition = (data) => {
        return ApiManager.getInstance().post(CONDITIONS, data);
    };
    //put request
    static updateMedicalConditionInfo = (medicalConditions) => {
        return ApiManager.getInstance().put(CONDITIONS, {
            ...medicalConditions,
        });
    };
    //delete req
    static deleteMedicalCondition = (medicalConditionId) => {
        return ApiManager.getInstance().delete(`${CONDITIONS}?id=${medicalConditionId}`);
    };
    //get one request
    static getmedicalConditionInfo = (medicalConditionId) => {
        return ApiManager.getInstance().get(CONDITIONS, {
            Id: medicalConditionId
        });
    };
}
export default MedicalConditionsRepository;
