import React, { useEffect, useRef, useState } from "react";
import { Button, InputText } from "../../components/Shared/Controls";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getDrugBrandCodesDropdownAsyncAction } from "../../store/prescriber/Sagas";
import CheckboxDropdown from "../../components/Shared/Controls/CheckboxDropdown";
import LoadingPopUp from "../../components/Shared/LoadingPopUp";
import SuccessPopUp from '../../components/Shared/Info/SuccessPopUp';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfusionCliniccss from "./InfusionClinicAdminStyle.scss";
import golbalcss from "../styles.scss";
import InputTextMultiLine from "../../components/Shared/Controls/Inputs/InputTextMultiLine";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Grid from '@mui/material/Grid';
import { createInfusionClinicInfoAsyncAction, getSingleDataOfInfusionClinicInfoAsyncAction, updateInfusionClinicInfoAsyncAction } from "../../store/InfusionClinic/Sagas";
import { useInput } from "../../hooks";
import MapContainerMultiple from "../../components/MapContainerMultiple";
import ScriptLoaded from "../../components/ScriptLoaded";
import { StandaloneSearchBox } from "@react-google-maps/api";
import colors from "../../resources/colors";
import s from "../../components/Shared/Controls/Inputs/InputText/style.scss";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Checkbox } from "../../components";
const InfusionClinicAdmin = ({ createInfusionClinic, updateInfusionClinic, getInfusionClinicInfo, getDrugBrandCodesDropdown, drugBrandCodesDropdown, getSingleDataOfInfusionClinicInfo, requestUpdateInfusionClinicInfoStatus, requestInfusionClinicPostStatus, requestSingleDataOfInfusionClinicInfoStatus, requestDrugBrandCodesDropdownStatus }) => {
    const { t } = useTranslation();
    const [openSavePopup, setOpenSavePopup] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [Name, setName] = useState("");
    const [Note, setNote] = useState("");
    const [BrandIdentifier, setBrandIdentifier] = useState("");
    const [successPopupIsOpen, setSuccessPopupIsOpen] = useState(false);
    const [city, setCity] = useState("");
    const [country, setcountry] = useState("CA");
    const [postalCode, setPostalCode] = useState("");
    const [address, setAddress] = useState("");
    const [phone, setPhone] = useState('');
    const [isPreferred, setIsPreferred] = useState(false);
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput();
    const [location, setLocation] = useState("");
    const [longitude, setLongitude, errorLongitude, setErrorLongitude] = useInput();
    const [latitude, setLatitude, errorLatitude, setErrorLatitude] = useInput();
    const [tmpLocationArray, setTemLocationArray] = useState([]);
    const toggleSavePopup = () => setOpenSavePopup((open) => !open);
    const [isSubmit, setisSubmit] = useState(false);
    useEffect(() => {
        getDrugBrandCodesDropdown();
        requestInfusionClinicPostStatus = "idle";
        requestUpdateInfusionClinicInfoStatus = "idle";
    }, []);
    useEffect(() => {
        if (id) {
            setName("");
            setNote("");
            setBrandIdentifier("");
            getInfusionClinicInfo(id);
        }
    }, [id]);
    const handlePhoneInputChange = (value, info) => {
        setPhone(value);
        setPhoneNumber(info.nationalNumber);
        setErrorPhoneNumber(!matchIsValidTel(value));
    };
    const DrugBrandCode = Array.isArray(drugBrandCodesDropdown)
        ? drugBrandCodesDropdown.map((item) => {
            return {
                key: item.drugBrandCode,
                displayName: item.drugBrandCode,
                isChecked: false
            };
        })
        : [];
    const countries = [
        {
            key: "CA",
            displayName: "Canada",
            isChecked: true
        }
    ];
    const handleSetCountries = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.key);
        setcountry(selectedNames[0]);
    };
    const [items, setItems] = useState(DrugBrandCode);
    const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
    const handleSetItems = (newItems) => {
        const checkedItems = newItems.filter((item) => item.isChecked);
        const selectedNames = checkedItems.map((item) => item.displayName);
        setSelectedDisplayNames(selectedNames);
        setItems(newItems);
    };
    useEffect(() => {
        if (id) {
            if (getSingleDataOfInfusionClinicInfo != null && getSingleDataOfInfusionClinicInfo != undefined) {
                const InfusionClinicData = getSingleDataOfInfusionClinicInfo;
                setName(InfusionClinicData.name || "");
                setNote(InfusionClinicData.note || "");
                setAddress(InfusionClinicData.address || "");
                setcountry(InfusionClinicData.country || "");
                setPostalCode(InfusionClinicData.postal || "");
                setCity(InfusionClinicData.city || "");
                setPhone(InfusionClinicData.phone || "");
                setLongitude(InfusionClinicData?.longitude || "");
                setLatitude(InfusionClinicData?.latitude || "");
                setIsPreferred(InfusionClinicData.isPreferred);
                setBrandIdentifier(InfusionClinicData.brandIdentifier || "");
                // Set the drugBrandCodes in CheckboxDropdown
                const selectedDrugBrandCodes = InfusionClinicData.drugBrandCodes || [];
                const updatedItems = items.map((item) => ({
                    ...item,
                    isChecked: selectedDrugBrandCodes.includes(item.displayName),
                }));
                handleSetItems(updatedItems);
            }
        }
        else {
            setName("");
            setNote("");
            setBrandIdentifier("");
        }
    }, [getSingleDataOfInfusionClinicInfo]);
    const onsubmitData = () => {
        setisSubmit(true);
        if (Name != "" && address != "" && city != "" && postalCode != "" && country != "" && phoneNumber != "" && BrandIdentifier != "" && longitude != null && longitude != undefined && latitude != null && latitude != undefined && selectedDisplayNames.length > 0) {
            if (id) {
                updateInfusionClinic({
                    Id: id,
                    name: Name,
                    drugBrandCodes: selectedDisplayNames,
                    address: address,
                    city: city,
                    postal: postalCode,
                    country: country,
                    phone: phoneNumber,
                    note: Note,
                    brandIdentifier: BrandIdentifier,
                    isPreferred: isPreferred,
                    longitute: longitude,
                    latitude: latitude
                });
                setisSubmit(false);
            }
            else {
                createInfusionClinic({
                    name: Name,
                    drugBrandCodes: selectedDisplayNames,
                    address: address,
                    city: city,
                    postal: postalCode,
                    country: country,
                    phone: phoneNumber,
                    note: Note,
                    brandIdentifier: BrandIdentifier,
                    isPreferred: isPreferred,
                    longitute: longitude,
                    latitude: latitude
                });
                setisSubmit(false);
            }
        }
    };
    const onFormSubmit = (event) => {
        event.preventDefault();
        onsubmitData();
    };
    useEffect(() => {
        if (requestInfusionClinicPostStatus === "success") {
            setSuccessPopupIsOpen(true);
            setName("");
            setNote("");
            setBrandIdentifier("");
            requestInfusionClinicPostStatus = "idle";
        }
    }, [requestInfusionClinicPostStatus]);
    useEffect(() => {
        if (requestUpdateInfusionClinicInfoStatus === "success") {
            requestUpdateInfusionClinicInfoStatus = "idle";
            setSuccessPopupIsOpen(true);
        }
    }, [requestUpdateInfusionClinicInfoStatus]);
    const handleArrowBack = () => {
        navigate('/InfusionClinic');
    };
    useEffect(() => {
        if (!!latitude && !!longitude) {
            const locationItem = [
                {
                    id: null,
                    lat: Number(latitude),
                    lng: Number(longitude)
                }
            ];
            setLocation("");
            setTemLocationArray(locationItem);
        }
    }, [latitude, longitude]);
    const searchBoxRef = useRef(null);
    const onLoad = (ref) => {
        if (ref) {
            searchBoxRef.current = ref;
        }
    };
    const onPlacesChanged = () => {
        if (!searchBoxRef.current)
            return;
        let places = searchBoxRef.current.getPlaces();
        let locations = [];
        for (let i = 0; i < places.length || i < 1; i++) {
            locations.push({
                id: null,
                lat: places[i].geometry.location.lat(),
                lng: places[i].geometry.location.lng()
            });
            setLatitude(places[i].geometry.location.lat());
            setLongitude(places[i].geometry.location.lng());
        }
        setLocation("");
        setTemLocationArray(locations);
    };
    return (React.createElement(Box, { className: golbalcss.MuiPaperAllSectionBox, sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { className: InfusionCliniccss.MuiPaperAllSection, sx: { width: '100%', mb: 2 } },
            React.createElement("div", { className: InfusionCliniccss.prescriberModifyanEntry },
                id ? React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: InfusionCliniccss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("infusionClinic"),
                    " ",
                    React.createElement("span", { className: InfusionCliniccss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("modifyanentry"),
                    " ") : React.createElement(Typography, { sx: { flex: '1 1 100%' }, variant: "h1", className: 'pageTitle', id: "tableTitle", component: "h1" },
                    React.createElement(ArrowBackIcon, { className: InfusionCliniccss.backarrow, onClick: handleArrowBack }),
                    " ",
                    t("infusionClinic"),
                    " ",
                    React.createElement("span", { className: InfusionCliniccss.ArrowForwardIosDesign },
                        React.createElement(ArrowForwardIosIcon, null),
                        " "),
                    " ",
                    t("createanentry"),
                    " "),
                React.createElement("div", { className: InfusionCliniccss.pagebodyBox },
                    React.createElement("div", { className: InfusionCliniccss.scrollWrapper },
                        React.createElement(React.Fragment, null,
                            React.createElement("form", { onSubmit: onFormSubmit },
                                React.createElement(Box, { sx: { width: '100%' } },
                                    React.createElement(Grid, { container: true, rowSpacing: 1, columnSpacing: { xs: 1, sm: 2, md: 3 } },
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("clinic"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "Name", value: Name, setValue: setName, maxLength: 100, validationMessage: t("pleaseEnterName"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("brandcodes"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(CheckboxDropdown, { items: items, setItems: handleSetItems, required: true, isSubmited: isSubmit })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("brandidentifier"),
                                                    " ",
                                                    React.createElement("span", null,
                                                        "(",
                                                        t('optionalIDFromYourLinkedSystem'),
                                                        ")")),
                                                React.createElement(InputText, { name: "BrandIdentifier", value: BrandIdentifier, setValue: setBrandIdentifier, validationMessage: t("pleaseenterBrandIdentifier"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup, s.checkboxlist) },
                                                React.createElement("label", null,
                                                    t("note"),
                                                    " ",
                                                    React.createElement("span", null, "(Max 256 characters)")),
                                                React.createElement(InputTextMultiLine, { name: "Note", maxLength: 256, value: Note, setValue: setNote, columns: 6, height: 156 })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup, InfusionCliniccss.checkboxlist) },
                                                React.createElement(Checkbox, { value: isPreferred, onChange: () => { setIsPreferred(!isPreferred); } }),
                                                React.createElement("label", { className: InfusionCliniccss.isPreferred }, t("isPreferred")))),
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("address"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "address", value: address, setValue: setAddress, maxLength: 100, validationMessage: t("pleaseEnterAddress"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("city"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "city", value: city, setValue: setCity, maxLength: 100, validationMessage: t("pleaseEnterCity"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("postalCode"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "postalCode", value: postalCode, setValue: setPostalCode, maxLength: 6, validationMessage: t("pleaseEnterPostalCode"), isSubmitted: isSubmit, required: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("country"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(CheckboxDropdown, { items: countries, setItems: handleSetCountries, required: true, isSubmited: isSubmit })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("phone"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(MuiTelInput, { name: "phone", value: phone, onChange: handlePhoneInputChange, error: errorPhoneNumber, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 9 }))),
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("location"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement("p", null, t("searchForalocation")),
                                                React.createElement(ScriptLoaded, null,
                                                    React.createElement(StandaloneSearchBox, { onLoad: onLoad, onPlacesChanged: onPlacesChanged },
                                                        React.createElement("input", { type: "text", placeholder: t('searchLocation'), style: { backgroundColor: colors.primaryshades3 }, className: classNames(s.input) })))),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("div", { className: classNames(InfusionCliniccss.orbg) },
                                                    React.createElement("p", null,
                                                        "------- ",
                                                        t('OR'),
                                                        " -------"))),
                                            React.createElement("p", null, t("enterThemManually")),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("longitude"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "longitude", value: longitude, setValue: setLongitude, maxLength: 10, validationMessage: t("pleaseenterlongitude"), isSubmitted: isSubmit, required: true, errorMessage: t("errorLongitudeIncorrectFormat"), error: errorLongitude, setError: setErrorLongitude, isLongitude: true })),
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("label", null,
                                                    t("latitude"),
                                                    " ",
                                                    React.createElement("span", { className: golbalcss.errortext }, "*")),
                                                React.createElement(InputText, { name: "latitude", value: latitude, setValue: setLatitude, maxLength: 10, validationMessage: t("pleaseenterlatitude"), isSubmitted: isSubmit, required: true, errorMessage: t("errorLongitudeIncorrectFormat"), error: errorLatitude, setError: setErrorLatitude, isLatitude: true }))),
                                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                                            React.createElement("div", { className: classNames(golbalcss.formGroup) },
                                                React.createElement("div", { className: classNames(InfusionCliniccss.map) },
                                                    React.createElement(MapContainerMultiple, { locations: tmpLocationArray, setIsLoaded: setIsLoaded, setCurrentLocation: setCurrentLocation }))),
                                            React.createElement("div", { className: InfusionCliniccss.buttonTwoTop },
                                                React.createElement("div", { className: InfusionCliniccss.buttonTwolist },
                                                    React.createElement(Button, { text: t("cancel"), onClick: () => handleArrowBack() }),
                                                    React.createElement(Button, { primary: true, text: t("savechanges"), type: "submit" }))))))),
                            React.createElement(SuccessPopUp, { isOpen: successPopupIsOpen, displayMessage: "Your changes have been saved.", header: "success!", name: "Infusion Clinic", togglePopUp: () => { setSuccessPopupIsOpen(false); handleArrowBack(); } }),
                            requestUpdateInfusionClinicInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestInfusionClinicPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null,
                            requestSingleDataOfInfusionClinicInfoStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null)))))));
};
const mapStateToProps = (state) => {
    return {
        drugBrandCodesDropdown: state.prescriber.DrugBrandCodesDropdown,
        getSingleDataOfInfusionClinicInfo: state.InfusionClinic.getSingleDataOfInfusionClinicInfo,
        requestUpdateInfusionClinicInfoStatus: state.InfusionClinic.requestUpdateInfusionClinicInfoStatus,
        requestInfusionClinicPostStatus: state.InfusionClinic.requestInfusionClinicPostStatus,
        requestSingleDataOfInfusionClinicInfoStatus: state.InfusionClinic.requestSingleDataOfInfusionClinicInfoStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getDrugBrandCodesDropdown: () => dispatch(getDrugBrandCodesDropdownAsyncAction()),
        createInfusionClinic: (InfusionClinicInfo) => dispatch(createInfusionClinicInfoAsyncAction(InfusionClinicInfo)),
        getInfusionClinicInfo: (Id) => dispatch(getSingleDataOfInfusionClinicInfoAsyncAction(Id)),
        updateInfusionClinic: (InfusionClinicInfo) => dispatch(updateInfusionClinicInfoAsyncAction(InfusionClinicInfo)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(InfusionClinicAdmin);
