import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import Button from "../../../../../components/Shared/Controls/Button";
import classNames from "classnames";
import { Checkbox, FormControl, FormControlLabel, FormGroup } from "@material-ui/core";
import { resetLengthStepsToComplete, resetUpdateBrandEnrollmentAction, updateBrandEnrollmentAsyncAction } from "../../../../../store";
import { connect } from "react-redux";
import { createCallPreferenceAsyncAction, getCallPreferencesAsyncAction } from "../../../../../store/Invite/Sagas";
import LoadingPopUp from "../../../../../components/Shared/LoadingPopUp";
import { useNavigate } from "react-router";
import { resetCallPreferenceAction } from "../../../../../store/Invite";
import Dropdown from "../../../../../components/Shared/Controls/Inputs/Dropdown";
const CallPreferences = ({ postCallPreferenceAsyncAction, updateBrandEnrollmentAsyncAction, requestCallPreferencePostStatus, requestBrandEnrollmentPostStatus, resetCallPreferenceAction, resetUpdateBrandEnrollmentAction, resetLengthStepsToCompleteAction, callPreferences, isInAdditionalSteps, getCallPreferences, accountId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [communicationPreference, setCommunicationPreference] = useState("");
    const CommunicationPreferences = [
        {
            key: "Sms",
            displayName: t("Sms")
        },
        {
            key: "Email",
            displayName: t("Email")
        },
        {
            key: "Both",
            displayName: t("Both")
        }
    ];
    const [state, setState] = React.useState({
        Sunday: callPreferences?.Sunday || false,
        Monday: callPreferences?.Monday || false,
        Tuesday: callPreferences?.Tuesday || false,
        Wednesday: callPreferences?.Wednesday || false,
        Thursday: callPreferences?.Thursday || false,
        Friday: callPreferences?.Friday || false,
        Saturday: callPreferences?.Saturday || false,
        Morning_8_12: callPreferences?.Morning_8_12 || false,
        Afternoon_12_4: callPreferences?.Afternoon_12_4 || false,
        Evening_4_8: callPreferences?.Evening_4_8 || false,
    });
    const { Sunday, Monday, Tuesday, Wednesday, Thursday, Saturday, Friday, Morning_8_12, Afternoon_12_4, Evening_4_8 } = state;
    const error = [Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Morning_8_12, Afternoon_12_4, Evening_4_8].filter((v) => v).length !== 2;
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };
    const Submit = () => {
        const data = {
            impersonatedUserId: accountId,
            communicationPreference: communicationPreference,
            callPreferenceMonday: state.Monday,
            callPreferenceTuesday: state.Tuesday,
            callPreferenceWednesday: state.Wednesday,
            callPreferenceThursday: state.Thursday,
            callPreferenceFriday: state.Friday,
            callPreferenceSaturday: state.Saturday,
            callPreferenceSunday: state.Sunday,
            callPreferenceTimeMorning: state.Morning_8_12,
            callPreferenceTimeAfternoon: state.Afternoon_12_4,
            callPreferenceTimeEvening: state.Evening_4_8
        };
        postCallPreferenceAsyncAction(data);
    };
    useEffect(() => {
        getCallPreferences();
    }, []);
    useEffect(() => {
        if (callPreferences) {
            setCommunicationPreference(callPreferences.communicationPreference),
                setState(prevState => ({
                    ...prevState,
                    Sunday: callPreferences.callPreferenceSunday,
                    Monday: callPreferences.callPreferenceMonday,
                    Tuesday: callPreferences.callPreferenceTuesday,
                    Wednesday: callPreferences.callPreferenceWednesday,
                    Thursday: callPreferences.callPreferenceThursday,
                    Friday: callPreferences.callPreferenceFriday,
                    Saturday: callPreferences.callPreferenceSaturday,
                    Morning_8_12: callPreferences.callPreferenceTimeMorning,
                    Afternoon_12_4: callPreferences.callPreferenceTimeAfternoon,
                    Evening_4_8: callPreferences.callPreferenceTimeEvening
                }));
        }
    }, [callPreferences]);
    useEffect(() => {
        console.log("requestCallPreferencePostStatus: ", requestCallPreferencePostStatus);
        if (requestCallPreferencePostStatus === "success") {
            resetCallPreferenceAction();
            if (isInAdditionalSteps) {
                resetLengthStepsToCompleteAction();
                // set in backend that user has finished flow
                updateBrandEnrollmentAsyncAction({});
            }
            else {
            }
        }
    }, [requestCallPreferencePostStatus]);
    // once saved to the backend, the user has finished enrollment, navigate to dashboard
    useEffect(() => {
        console.log("requestBrandEnrollmentPostStatus: ", requestBrandEnrollmentPostStatus);
        if (requestBrandEnrollmentPostStatus === "success") {
            resetUpdateBrandEnrollmentAction();
            // setisnavigate(true);
            navigate('/');
        }
    }, [requestBrandEnrollmentPostStatus]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: "callPreferencesScroll", className: classNames(si.contentWrapper) },
            React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
                React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                    React.createElement("div", { className: classNames(si.formstepbar) },
                        React.createElement("div", { className: (si.stepToptext) },
                            React.createElement("h4", null, t('callPreferences'))),
                        React.createElement(Dropdown, { style: s.select, data: CommunicationPreferences, value: communicationPreference, setValue: setCommunicationPreference, label: t("CommunicationPreference") }),
                        React.createElement("p", null, t('callPreferencesSubHeader')),
                        React.createElement("div", { className: classNames(si.contentInput) },
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("p", null, t("bestaystoContact")),
                                React.createElement(FormControl, { component: "fieldset", variant: "standard", className: classNames(s.YourPreferences) },
                                    React.createElement(FormGroup, { className: classNames(s.YourPreferencesList) },
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Monday, onChange: handleChange, name: "Monday" }), label: t('monday') }),
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Tuesday, onChange: handleChange, name: "Tuesday" }), label: t('tuesday') }),
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Wednesday, onChange: handleChange, name: "Wednesday" }), label: t('wednesday') }),
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Thursday, onChange: handleChange, name: "Thursday" }), label: t('thursday') }),
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Friday, onChange: handleChange, name: "Friday" }), label: t('friday') })))),
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("p", null, t("bestTimetoGetinContact")),
                                React.createElement(FormControl, { component: "fieldset", variant: "standard", className: classNames(s.YourPreferences) },
                                    React.createElement(FormGroup, { className: classNames(s.YourPreferencesList) },
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Morning_8_12, onChange: handleChange, name: "Morning_8_12" }), 
                                            // label="Morning (8-12)"
                                            label: `${t('morning')} (8-12)` }),
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Afternoon_12_4, onChange: handleChange, name: "Afternoon_12_4" }), label: `${t('afternoon')} (12-4)` }),
                                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { disableRipple: true, checked: Evening_4_8, onChange: handleChange, name: "Evening_4_8" }), label: `${t('evening')} (4-8)` }))))),
                        React.createElement("div", { className: si.buttonTwopage }, isInAdditionalSteps
                            ? React.createElement(Button, { style: s.mb0_button, primary: true, text: t("finish"), onClick: Submit })
                            : React.createElement(React.Fragment, null,
                                React.createElement(Button, { style: s.mb0_button, primary: true, text: t("save"), onClick: Submit }))))))),
        requestCallPreferencePostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        requestCallPreferencePostStatus: state.invite.requestCallPreferencePostStatus,
        requestBrandEnrollmentPostStatus: state.account.requestUpdateBrandEnrollmentStatus,
        accountId: !!state.account.user.impersonatedAccountId
            ? state.account.user?.impersonatedAccountId : state.auth.InviteLoginStoredData?.azureUserInfo?.azureUserId,
        callPreferences: state.invite.callPreferences
    };
};
function mapDispatchToProps(dispatch) {
    return {
        postCallPreferenceAsyncAction: (data) => dispatch(createCallPreferenceAsyncAction(data)),
        updateBrandEnrollmentAsyncAction: (data) => dispatch(updateBrandEnrollmentAsyncAction(data)),
        resetCallPreferenceAction: () => dispatch(resetCallPreferenceAction()),
        resetUpdateBrandEnrollmentAction: () => dispatch(resetUpdateBrandEnrollmentAction()),
        getCallPreferences: () => dispatch(getCallPreferencesAsyncAction()),
        resetLengthStepsToCompleteAction: () => dispatch(resetLengthStepsToComplete())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CallPreferences);
