import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { PopUp } from "../../Info";
import YellowHelp from "../../../../resources/icons/yellow_help.svg";
import s from "./style.scss";
import colors from "../../../../resources/colors";
import config from "../../../../../config.json";
const HelpInfo = ({ style, isOpen, setIsOpen }) => {
    const { t } = useTranslation();
    return (React.createElement(PopUp, { style: s.infoPopUp, isOpen: isOpen, allowClose: true, toggle: () => setIsOpen((open) => !open) },
        React.createElement("div", { className: s.infoPopUp__body },
            React.createElement(SVG, { src: YellowHelp }),
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("need_help")),
            React.createElement("div", { className: s.inline },
                React.createElement("div", null,
                    t("call"),
                    "\u00A0"),
                React.createElement("div", { style: { color: colors.controlsGeneralColor } }, config.help_phone_number)))));
};
export default HelpInfo;
