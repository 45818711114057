import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, createTransform, } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import AuthSlice from "./auth/Slice";
import BrandsSlice from "./brands/Slice";
import NavigationSlice from "./navigation/Slice";
import ProvincesSlice from "./province/Slice";
import ForgotPasswordSlice from "./forgotPassword/Slice";
import PatientAddress from "./patientAddress/Slice";
import AccountSlice from "./account/Slice";
import NotificationsSlice from "./notifications/Slice";
import patientFilesSlice from "./patientFiles/Slice";
import educationSlice from "./education/Slice";
import patientEducationSlice from "./patientEducation/Slice";
import ConditionsSlice from "./medicalConditions/Slice";
import chatSlice from "./chat/Slice";
import ErrorSlice from "./error/Slice";
import RemindersSlice from "./medicalReminders/Slice";
import LanguageSlice from "./language/Slice";
import rootSaga from "./rootSaga";
import prescriberSlice from './prescriber/slice';
import patientSlice from './patient/slice';
import InviteSlice from './Invite/Slice';
import PharmacySlice from './pharmacy/slice';
import PatientBrandPrescriber from './PatientBrandPrescriber/slice';
import InfusionClinic from './InfusionClinic/slice';
import { logOutAsyncAction } from "./auth/actions";
const accountBlacklist = createTransform((state, key) => {
    return state;
}, (state, key) => {
    const newState = { ...state };
    newState.requestHomeStatus = "idle";
    newState.requestHomeError = null;
    newState.requestRegistrationInfoStatus = "idle";
    newState.requestRegistrationInfoError = null;
    newState.requestSettingsInfoStatus = "idle";
    newState.requestSettingsInfoError = null;
    newState.requestPatientInfoStatus = "idle";
    newState.requestPatientInfoError = null;
    newState.requestUpdatePatientInfoStatus = "idle";
    newState.requestUpdatePatientInfoError = null;
    newState.requestPatientBrandsStatus = "idle";
    newState.requestPatientBrandsError = null;
    newState.requestAssignToBrandStatus = "idle";
    newState.requestAssignToBrandError = null;
    newState.requestUpdateEmailStatus = "idle";
    newState.requestUpdateEmailError = null;
    newState.requestUpdatePrescriberNameStatus = "idle";
    newState.requestUpdatePrescriberNameError = null;
    newState.requestEnrollByOwnerStatus = "idle";
    newState.requestEnrollByOwnerError = null;
    return newState;
}, { whitelist: ['account'] });
const patientFilesBlacklist = createTransform((state, key) => {
    return state;
}, (state, key) => {
    const newState = { ...state };
    newState.requestStatus = "idle";
    newState.requestError = null;
    newState.requestScheduleCallStatus = "idle";
    newState.requestScheduleCallError = null;
    newState.requestGetFilesStatus = "idle";
    newState.requestGetFilesError = null;
    newState.requestDeleteFilesStatus = "idle";
    newState.requestDeleteFilesError = null;
    newState.requestUpdateFilesStatus = "idle";
    newState.requestUpdateFilesError = null;
    newState.requestGetTimesStatus = "idle";
    newState.requestGetTimesError = null;
    newState.requestGetFinancialConfigStatus = "idle";
    newState.requestGetFinancialConfigError = null;
    newState.requestGetFinancialAssistanceStatus = "idle";
    newState.requestGetFinancialAssistanceError = null;
    newState.requestSendFinancialAssistanceStatus = "idle";
    newState.requestSendFinancialAssistanceError = null;
    newState.requestGetPreviewPhotoStatus = "idle";
    newState.requestGetPreviewPhotoError = null;
    newState.requestInsurancesUpdateStatus = "idle";
    newState.requestInsurancesUpdateError = null;
    newState.requestHealthCardUpdateStatus = "idle";
    newState.requestHealthCardUpdateError = null;
    newState.requestGetInsurancesFilesStatus = "idle";
    newState.requestGetInsurancesFilesError = null;
    newState.requestGetHealthCardFilesStatus = "idle";
    newState.requestGetHealthCardFilesError = null;
    return newState;
}, { whitelist: ['patientFiles'] });
const appReducer = combineReducers({
    auth: AuthSlice.reducer,
    forgotPassword: ForgotPasswordSlice.reducer,
    navigation: NavigationSlice.reducer,
    brands: BrandsSlice.reducer,
    provinces: ProvincesSlice.reducer,
    medicalConditions: ConditionsSlice.reducer,
    patientFiles: patientFilesSlice.reducer,
    patientAddress: PatientAddress.reducer,
    account: AccountSlice.reducer,
    notifications: NotificationsSlice.reducer,
    education: educationSlice.reducer,
    patientEducation: patientEducationSlice.reducer,
    chat: chatSlice.reducer,
    userError: ErrorSlice.reducer,
    prescriber: prescriberSlice.reducer,
    patient: patientSlice.reducer,
    reminders: RemindersSlice.reducer,
    language: LanguageSlice.reducer,
    invite: InviteSlice.reducer,
    pharmacy: PharmacySlice.reducer,
    PatientBrandPrescriber: PatientBrandPrescriber.reducer,
    InfusionClinic: InfusionClinic.reducer
});
const rootReducer = (state, action) => {
    if (action.type === logOutAsyncAction.type) {
        state = undefined;
        storage.removeItem('persist:root');
    }
    return appReducer(state, action);
};
const expireTime = 8 * 60 * 60 * 1000; // expire in 8h
const expirationKey = "expirationKey";
const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: ["auth", "notifications", "patientFiles", "patientEducation", "patientAddress", "chat", "userError"],
    transforms: [accountBlacklist, patientFilesBlacklist],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const store = configureStore({
    reducer: persistedReducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== 'production',
});
export const persistor = persistStore(store);
sagaMiddleware.run(rootSaga);
export default store;
