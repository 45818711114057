import ApiManager from "../api/ApiManager";
const version = "1.2";
const GET_PATIENT_InfusionClinic = `/v${version}/Patient/Brand/Infusion-Clinic`;
const PUT_PATIENT_InfusionClinic = `/v${version}/Patient/Brand/Infusion-Clinic`;
const POST_PATIENT_InfusionClinic = `/v${version}/Patient/Brand/Infusion-Clinic`;
const DELETE_InfusionClinic = `/v${version}/Patient/Brand/Infusion-Clinic`;
const InfusionClinic_API = `/v${version}/Infusion-Clinic`;
class InfusionClinicRepository {
    constructor() { }
    //get request
    static getPatientInfusionClinicInfo = (payload) => {
        let filters = "";
        if (!!payload.patientId) {
            filters += `&patientId=${payload.patientId}`;
        }
        if (!!payload.id) {
            filters += `&id=${payload.id}`;
        }
        return ApiManager.getInstance().get(`${GET_PATIENT_InfusionClinic}?${filters}`);
    };
    //put request
    static putInfusionClinicInfo = (data) => {
        return ApiManager.getInstance().put(PUT_PATIENT_InfusionClinic, data);
    };
    //post request
    static postInfusionClinicInfo = (data) => {
        return ApiManager.getInstance().post(POST_PATIENT_InfusionClinic, data);
    };
    //get only InfusionClinicInfo
    static getInfusionClinicInfo = (action) => {
        let searchItems = '';
        let orderCopy = false;
        orderCopy = action?.order != 'asc';
        if (action?.address != '' && action?.address != undefined) {
            searchItems += `&Address=${action?.address}`;
        }
        if (action?.name != '' && action?.name != undefined) {
            searchItems += `&Name=${action?.name}`;
        }
        if (action?.orderBy != '' && action?.orderBy != undefined) {
            searchItems += `&OrderByAttribute=${action?.orderBy}`;
        }
        if (orderCopy != null && orderCopy != undefined) {
            searchItems += `&OrderByDescending=${orderCopy}`;
        }
        if (!!action?.page) {
            searchItems += `&PageNumber=${action?.page + 1}`;
        }
        else {
            searchItems += `&PageNumber=1`;
        }
        if (!!action?.rowsPerPage) {
            searchItems += `&PageSize=${action?.rowsPerPage}`;
        }
        else {
            searchItems += `&PageSize=50`;
        }
        if (action?.phone != '' && action?.phone != undefined) {
            searchItems += `&Phone=${action?.phone}`;
        }
        if (action?.drugBrandCode && Array.isArray(action?.drugBrandCode) && action?.drugBrandCode.length > 0) {
            action.drugBrandCode.map((code, index) => {
                searchItems += `&DrugBrandCode[${index}]=${code}`;
            });
        }
        if (action?.brandIdentifier != '' && action?.brandIdentifier != undefined) {
            searchItems += `&BrandIdentifier=${action?.brandIdentifier}`;
        }
        if (!!action?.latitude && !!action?.latitude) {
            searchItems += `&Geography.Distance=${action?.distance ?? 5000}&Geography.CurrentLongitude=${action?.longitude}&Geography.CurrentLatitude=${action?.latitude}`;
        }
        return ApiManager.getInstance().get(`${InfusionClinic_API}?${searchItems}`);
    };
    //delete 
    static deleteInfusionClinicBrand = (Id) => {
        return ApiManager.getInstance().delete(`${DELETE_InfusionClinic}?infusionClinicId=${Id}`);
    };
    //here
    static deleteInfusionClinic = (Id) => {
        return ApiManager.getInstance().delete(`${InfusionClinic_API}?infusionClinicId=${Id}`);
    };
    //post request
    static postInfusionClinic = (data) => {
        return ApiManager.getInstance().post(InfusionClinic_API, data);
    };
    //put request
    static updateInfusionClinicInfo = (InfusionClinic) => {
        return ApiManager.getInstance().put(InfusionClinic_API, {
            ...InfusionClinic
        });
    };
    //get request
    static getSingleDataOfInfusionClinicInfo = (InfusionClinicId) => {
        return ApiManager.getInstance().get(InfusionClinic_API, {
            Id: InfusionClinicId
        });
    };
}
export default InfusionClinicRepository;
