import React from "react";
import s from "./style.scss";
import { useTranslation } from "react-i18next";
import colors from "../../../../resources/colors";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
const Hoverable = styled.div `
  &:hover {
    border-bottom: 3px solid ${colors.primary}
  }
`;
const ItemPreview = ({ isAdmin, item, type, onClick, }) => {
    const { t } = useTranslation();
    const handleClick = (item) => {
        window.open(item, '_blank');
    };
    return item && (
    // <Hoverable>
    React.createElement("div", { className: s.itemPreview },
        React.createElement("div", { className: s.card },
            React.createElement("div", { className: s.cardImg },
                isAdmin != true && item.itemMimeType == "Web" ?
                    React.createElement(React.Fragment, null,
                        " ",
                        React.createElement("img", { src: item.thumbnailUrl, onClick: () => handleClick(item.url) }),
                        " ")
                    :
                        React.createElement(React.Fragment, null,
                            " ",
                            React.createElement("img", { onClick: onClick, src: item.thumbnailUrl }),
                            " "),
                React.createElement("div", { className: s.tagslistimg },
                    React.createElement("button", null, item.drugBrandCode),
                    item?.medicalConditions?.map((item, key) => (key < 2 ? (React.createElement("button", { key: key }, item?.medicalConditionDescription ? item?.medicalConditionDescription : item)) : null)),
                    item?.medicalConditions?.length > 2 ? (React.createElement("button", null, "More...")) : null),
                React.createElement("div", { className: s.VideoWatchedBox }, type !== "Web" && item.isItemViewed &&
                    React.createElement("div", { className: s.VideoWatched },
                        React.createElement("p", null, t("Viewed"))))),
            React.createElement("div", { className: s.cardBody },
                React.createElement("h2", { onClick: onClick, className: s.cardTitle }, item?.title),
                React.createElement("p", null,
                    " ",
                    item?.description.slice(0, 100),
                    item?.description.length > 100 ? '...' : '',
                    " "),
                React.createElement("div", { className: s.tagslist }, item.tags?.map((tag, index) => (React.createElement(Button, { key: `${tag}-${index}`, variant: "outlined", size: "small" }, tag)))))))) || React.createElement(React.Fragment, null);
};
const mapStateToProps = (state) => {
    return {
        isAdmin: state.account.isAdmin,
    };
};
export default connect(mapStateToProps)(ItemPreview);
