import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import golbalcss from "../styles.scss";
import { useTranslation } from "react-i18next";
const ComingSoon = ({}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    return (React.createElement(Box, { className: golbalcss.MuiPaperAllSectionBox, sx: { width: '100%', m: 4, overflow: "auto" } },
        React.createElement(Paper, { sx: { width: '100%', mb: 2 } },
            React.createElement("div", null, "Coming Soon"))));
};
const mapStateToProps = (state) => {
    return {};
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon);
