import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import HelpIcon from "../../../../resources/icons/help_icon";
import UserIcon from "../../../../resources/icons/user";
import LoaderIcon from '../../../../resources/icons/icon-loader.svg';
import { useDeviceHeight, usePrevious } from "../../../../hooks";
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import s from "./style.scss";
import Cookies from "js-cookie";
import { connect, useDispatch, useSelector } from "react-redux";
import { addIncomingMessageAction, getChatMessagesAsyncAction } from "../../../../store/chat";
import { allChatMessagesSelector } from "../../../../store/chat/selectors";
import { ErrorSvg } from "../../../../resources/icons/error";
import { PROD_BASE_URL, STAGE_BASE_URL, DEV_BASE_URL } from "../../../../store/api/ApiManager";
import colors from "../../../../resources/colors";
import { ToLocalTimeZone } from "../../../../utils";
const ChatBody = ({ patientId, status, currentPage, pageCount, pageSize, getMessages }) => {
    const chatDiv = window.document.getElementById("chatBody");
    const prevPage = usePrevious(currentPage);
    const scrollAction = () => {
        chat.current?.scrollTop === 0 && pageCount > currentPage && getMessages({
            patientId: patientId,
            pageNumber: ++currentPage,
            pageSize: 20
        });
    };
    const chat = useRef(null);
    const chatMessages = useSelector(allChatMessagesSelector);
    const [messages, setMessages] = useState([]);
    const [hubConnection, setHubConnection] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (chatDiv && (prevPage === currentPage || currentPage === 1)) {
            chatDiv.scrollTop = chatDiv.scrollHeight;
        }
        chat.current?.addEventListener('scroll', scrollAction);
        return () => {
            chat.current?.removeEventListener('scroll', scrollAction);
        };
    }, [messages]);
    useEffect(() => {
        setMessages(chatMessages);
    }, [chatMessages]);
    useEffect(() => {
        getMessages({
            patientId: patientId,
            pageNumber: 1,
            pageSize: 20
        });
        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.NODE_ENV === 'production' ? PROD_BASE_URL : process.env.NODE_ENV === 'staging' ? STAGE_BASE_URL : DEV_BASE_URL}/chatHub?patientId=${patientId}`, {
            withCredentials: true,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => Cookies.get("token") || ""
        })
            .build();
        hubConnection
            .start()
            .then(() => hubConnection.invoke("MarkAsReadConversationMessages", patientId))
            .catch(err => console.log('Error while establishing connection :(' + err));
        hubConnection.on('IncomingMessage', (receivedMessage) => {
            dispatch(addIncomingMessageAction(receivedMessage));
            hubConnection.invoke("MarkAsReadIncomingMessage", patientId, receivedMessage.conversationMessageId);
        });
        setHubConnection(hubConnection);
        return () => {
            hubConnection.stop();
        };
    }, []);
    useDeviceHeight("chatBody", 495, 540, 505);
    return (React.createElement("div", { id: "chatBody", ref: chat, className: classNames(s.chatBody, s.scrollWrapper) }, messages
        .reverse()
        // .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
        .map((message, index) => {
        return (React.createElement("div", { key: message.conversationMessageId },
            index > 0 &&
                moment(message.date).format('LL') == moment(messages[index - 1].date).format('LL')
                ? null
                :
                    React.createElement("span", null, moment(ToLocalTimeZone(message.date)).utcOffset("-1000")
                        .format("MMM D, h:mm a")
                        .toUpperCase()),
            React.createElement("div", { className: classNames(s.message, {
                    [s.fromProgram]: !message.yours,
                }), key: message.conversationMessageId },
                React.createElement("div", { className: s.messageInfo },
                    React.createElement("div", null,
                        message.status === "pending" ? React.createElement(SVG, { src: LoaderIcon, className: s.loaderIcon })
                            : message.status === "failure" && React.createElement(ErrorSvg, { color1: "#F44336", color2: "#2F5285", style: s.errorIcon }),
                        React.createElement("p", { className: classNames({ [s.error]: message.status === "failure" }), style: {
                                backgroundColor: message.yours && colors.chat.backgroundMessageColor
                            } }, message.textMessage))),
                React.createElement("div", { className: classNames(s.sender, { [s.you]: message.yours }), style: {
                        background: !message.yours
                            && `linear-gradient(273.04deg, ${colors.chat.supportIconGradient.from} 0%, ${colors.chat.supportIconGradient.to} 99.08%)`
                            || colors.chat.userIcon.backgroundColor
                    } }, message.yours ? (React.createElement(UserIcon, { color: colors.chat.userIcon.figureColor })) : (React.createElement(HelpIcon, { color: colors.white }))))));
    })));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        status: state.chat.requestStatus,
        currentPage: state.chat.currentPage,
        pageCount: state.chat.pageCount,
        pageSize: state.chat.pageSize,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getMessages: (payload) => dispatch(getChatMessagesAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChatBody);
