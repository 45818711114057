import ApiManager from "../api/ApiManager";
const version = "1.2";
const INVITEVALIDATE = `/v${version}/Invite/Validate`;
const INVITE_CHANGE_PHONE_NUMBER = `/v${version}/Invite/Change-Phone-Number-request`;
const INVITE_PHONE_NUMBER_VARIFICATION = `/v${version}/Invite/Change-Phone-Number`;
const INVITE_CHANGE_PASSWORD = `/v${version}/Invite/Change-Password`;
const CALLPREFERENCE_INFO_POST = `/v${version}/CallPreference`;
class InviteRepository {
    constructor() { }
    //post request
    static postInviteValidate = (data) => {
        return ApiManager.getInstance().post(INVITEVALIDATE, data);
    };
    static getCallPreferences = (accountId) => {
        return ApiManager.getInstance().get(CALLPREFERENCE_INFO_POST, { accountId: accountId });
    };
    // postInviteChangePhoneNumberRequest
    static postInviteChangePhoneNumberRequest = (data) => {
        return ApiManager.getInstance().post(INVITE_CHANGE_PHONE_NUMBER, data);
    };
    //postInvitePhoneNumberVarification
    static postInvitePhoneNumberVarification = (data) => {
        return ApiManager.getInstance().post(INVITE_PHONE_NUMBER_VARIFICATION, data);
    };
    //postInviteChangePassword
    static postInviteChangePassword = (data) => {
        return ApiManager.getInstance().post(INVITE_CHANGE_PASSWORD, data);
    };
    static postCallPreference = (data) => {
        return ApiManager.getInstance().post(CALLPREFERENCE_INFO_POST, data);
    };
}
export default InviteRepository;
