import React from "react";
import Box from "../../Box";
import Typography from "../../Typography";
import s from "./style.scss";
import CheckMark from '../../../../resources/icons/checkMark3';
const Checkbox = (props) => {
    return (React.createElement(Box, { f1: true, row: true, gap: 10, mb: 12, mt: 12 },
        React.createElement("div", { className: props.value ? s.checked : s.wrapper, onClick: props.onChange },
            React.createElement("label", { className: props.value ? s.checkmark : s.unchecked },
                React.createElement(CheckMark, null))),
        props.label && React.createElement(Typography, { type: "paragraph" }, props.label)));
};
export default Checkbox;
