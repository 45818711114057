import React from "react";
const Reminder = ({ color }) => {
    return (React.createElement("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M11.7 1.8H6.3V3.15C6.3 3.39853 6.09853 3.6 5.85 3.6H3.15C2.90147 3.6 2.7 3.39853 2.7 3.15V1.8H0.9C0.402944 1.8 0 2.20294 0 2.7V4.5H18V2.7C18 2.20294 17.5971 1.8 17.1 1.8H15.3V3.15C15.3 3.39853 15.0985 3.6 14.85 3.6H12.15C11.9015 3.6 11.7 3.39853 11.7 3.15V1.8Z", fill: color }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.6 2.7V0.9C3.6 0.402944 4.00294 -2.1727e-08 4.5 0C4.99706 2.1727e-08 5.4 0.402944 5.4 0.9V2.7H3.6Z", fill: color }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.6 2.7V0.9C12.6 0.402944 13.0029 -2.1727e-08 13.5 0C13.9971 2.1727e-08 14.4 0.402944 14.4 0.9V2.7H12.6Z", fill: color }),
        React.createElement("path", { d: "M9.27297 9.42743L8.31838 10.382L10.2276 12.2912L11.1822 11.3366C11.7094 10.8094 11.7094 9.95464 11.1822 9.42743C10.655 8.90023 9.80018 8.90023 9.27297 9.42743Z", fill: color }),
        React.createElement("path", { d: "M6.72739 11.973L7.68198 11.0184L9.59117 12.9276L8.63657 13.8822C8.10937 14.4094 7.25459 14.4094 6.72739 13.8822C6.20018 13.355 6.20018 12.5002 6.72739 11.973Z", fill: color }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M18 5.4H0V17.1C0 17.5971 0.402944 18 0.9 18H17.1C17.5971 18 18 17.5971 18 17.1V5.4ZM6.09099 11.3366C5.21231 12.2153 5.21231 13.6399 6.09099 14.5186C6.96967 15.3973 8.39429 15.3973 9.27297 14.5186L11.8186 11.973C12.6972 11.0943 12.6972 9.66972 11.8186 8.79104C10.9399 7.91236 9.51525 7.91236 8.63657 8.79104L6.09099 11.3366Z", fill: color })));
};
export default Reminder;
