import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button, InputText } from "../../../../components/Shared/Controls";
import InputDate from "../../../../components/Shared/Controls/Inputs/InputDate";
import { useInput } from "../../../../hooks";
import { getPatientInfoAsyncAction, updatePatientInfoAsyncAction, } from "../../../../store";
import GenderPicker from "./components/GenderPicker";
import { PopUp } from "../../../../components/Shared/Info";
import ErrorPopUp from "../../../../components/Shared/Info/ErrorPopUp";
import s from "./style.scss";
import PageBody from "../../../../components/Shared/PageBody";
import Account from "../Account";
import PatientAddress from "../PatientAddress";
const minDate = moment().subtract("120", "years").format('YYYY-MM-DD');
const maxDate = moment().format("YYYY-MM-DD");
const maxAbleDate = moment().subtract(1, "years");
const PatientData = ({ setPage, getPatientInfo, patientId, patient, requestPatientInfoStatus, updatePatientInfo, requestUpdatePatientInfoStatus, }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [firstName, setFirstName, errorFirstName, setErrorFirstName] = useInput("");
    const [lastName, setLastName, errorLastName, setErrorLastName] = useInput("");
    const [dateOfBirth, setDate, errorDate, setErrorDate] = useInput("");
    const [gender, setGender] = useInput("");
    const [openSavePopup, setOpenSavePopup] = useState(false);
    const [showErrorPopUp, setPopUp] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [update, setUpdate] = useState(false);
    const toggleErrorPopUp = () => setPopUp((s) => !s);
    const toggleSavePopup = () => setOpenSavePopup((open) => !open);
    useEffect(() => {
        getPatientInfo();
    }, [patientId]);
    useEffect(() => {
        if (requestPatientInfoStatus === "success" && patient) {
            setFirstName(patient.firstName);
            setLastName(patient.lastName);
            setDate(moment(patient.birthDate));
            setGender(patient.gender);
        }
    }, [requestPatientInfoStatus]);
    const onChangeGender = (value) => {
        setGender(value);
    };
    const onUpdate = () => {
        if (!showErrorPopUp &&
            dateOfBirth &&
            maxAbleDate.isSameOrBefore(moment(dateOfBirth, "YYYY/MM/DD"))) {
            toggleErrorPopUp();
            return;
        }
        const formattedDateOfBirth = dateOfBirth.toISOString();
        updatePatientInfo({
            firstName,
            lastName,
            gender,
            birthDate: formattedDateOfBirth,
        });
    };
    useEffect(() => {
        if (patient.firstName === firstName &&
            patient.lastName === lastName &&
            patient.gender === gender &&
            moment(patient.birthDate).isSame(moment(dateOfBirth))) {
            setHasChanges(false);
        }
        else
            setHasChanges(true);
    }, [
        firstName,
        lastName,
        gender,
        dateOfBirth,
        requestUpdatePatientInfoStatus,
    ]);
    useEffect(() => {
        if (requestUpdatePatientInfoStatus === "success" && update) {
            setUpdate(false);
            setHasChanges(false);
            navigate("/profile");
        }
        requestUpdatePatientInfoStatus === "failure" && setUpdate(false);
    }, [requestUpdatePatientInfoStatus]);
    return (React.createElement(PageBody, { backAction: () => { hasChanges ? toggleSavePopup() : navigate(-1); }, title: t("personalData") },
        React.createElement(React.Fragment, null,
            React.createElement("p", { className: s.title }, t("personalData")),
            React.createElement(ErrorPopUp, { isOpen: showErrorPopUp, popUpContent: t("mustBeOlderMember"), togglePopUp: toggleErrorPopUp }),
            React.createElement("div", { className: s.pageWrapper },
                React.createElement(InputText, { name: "f_name", label: t("firstName"), errorMessage: t("errorIncorrectFirstName"), pattern: "^.{0,35}$", value: firstName, setValue: setFirstName, error: firstName && errorFirstName, setError: setErrorFirstName }),
                React.createElement(InputText, { name: "l_name", label: t("lastName"), errorMessage: t("errorIncorrectLastName"), pattern: "^.{0,35}$", value: lastName, setValue: setLastName, error: lastName && errorLastName, setError: setErrorLastName }),
                React.createElement(GenderPicker, { onChangeGender: onChangeGender, gender: gender }),
                React.createElement(InputDate, { name: "birthday", label: t("dateOfBirth"), value: dateOfBirth, setValue: setDate, minDate: minDate, maxDate: maxDate })),
            React.createElement("div", { className: s.divider }),
            React.createElement(Account, { setPage: setPage }),
            React.createElement("div", { className: s.divider }),
            React.createElement(PatientAddress, null),
            React.createElement(Button, { style: s.button, disabled: (patient.firstName === firstName &&
                    patient.lastName === lastName &&
                    patient.gender === gender &&
                    moment(patient.birthDate).isSame(moment(dateOfBirth))) ||
                    !firstName ||
                    !lastName ||
                    !gender ||
                    !dateOfBirth, primary: true, text: t("update"), onClick: onUpdate }),
            React.createElement(PopUp, { isOpen: openSavePopup },
                React.createElement("div", { className: s.popup },
                    React.createElement("div", { className: s.popup__content },
                        React.createElement("div", null, t("doYouWantToSave"))),
                    React.createElement("div", { className: s.popup__controls },
                        React.createElement(Button, { text: t("cancel"), onClick: () => {
                                toggleSavePopup();
                                navigate("/profile");
                            } }),
                        React.createElement(Button, { primary: true, text: t("save"), onClick: () => {
                                onUpdate();
                                toggleSavePopup();
                                setUpdate(true);
                            } })))))));
};
const mapStateToProps = (state) => {
    return {
        patient: state.account.patient,
        patientId: state.account.user.patientId,
        requestPatientInfoStatus: state.account.requestPatientInfoStatus,
        requestUpdatePatientInfoStatus: state.account.requestUpdatePatientInfoStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPatientInfo: () => dispatch(getPatientInfoAsyncAction()),
        updatePatientInfo: (patientInfo) => dispatch(updatePatientInfoAsyncAction(patientInfo)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientData);
