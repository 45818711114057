import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import classNames from "classnames";
import { Button, InputText } from "../../../../../components/Shared/Controls";
import { useInput } from "../../../../../hooks";
import config from "../../../../../../../config.json";
import NotAllowedImg from '../../../../../resources/images/stop_sign_icon.png';
import s from "../style.scss";
import { resetInviteLoginStoredData } from "../../../../../store";
import moment from "moment";
import InputDate from "../../../../../components/Shared/Controls/Inputs/InputDate";
import { PopUp } from "../../../../../components/Shared/Info";
import { createInviteValidatesInfoAsyncAction } from "../../../../../store/Invite/Sagas";
import { setResponseOfInviteValidateFalse, setUserNameAndDOB } from "../../../../../store/Invite";
import LoadingPopUp from "../../../../../components/Shared/LoadingPopUp";
import { useLocation, useParams } from "react-router-dom";
import Typography from "../../../../../components/Shared/Typography";
const ValidateInviteCredentials = ({ header, setStep, InviteValidate, requestInviteValidatePostStatus, resetResponseOfInviteValidateFalse, setUserNameAndDOBAction, userNameAndDOBState, resetInviteLoginStoredData }) => {
    const { accountid, code } = useParams();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const email = params.get('email');
    const ph = params.get('ph');
    const name = params.get('name');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [dateOfBirth, setDate, errorDate, setErrorDate] = useInput();
    const minDate = moment().subtract("120", "years").format('YYYY-MM-DD');
    const maxDate = moment().format('YYYY-MM-DD');
    const [lastName, setLastName, errorLastName, setErrorLastName] = useInput(userNameAndDOBState?.data?.lastName);
    const allFieldsValid = () => !errorLastName && !errorDate &&
        lastName && dateOfBirth;
    const maxAbleDate = moment().subtract(18, 'years');
    const [showPopUp, setPopUp] = useState(false);
    const [showInvalidUserPopUp, setInvalidUserPopUp] = useState(false);
    const togglePopUp = () => setPopUp(s => !s);
    const toggleInvalidUserPopUp = () => setInvalidUserPopUp(a => !a);
    useEffect(() => {
        if (!!userNameAndDOBState?.data?.birthdate) {
            setDate(moment(userNameAndDOBState?.data?.birthdate));
        }
    }, [userNameAndDOBState]);
    const onSubmit = async () => {
        if (!showPopUp && dateOfBirth && maxAbleDate.isSameOrBefore(moment(dateOfBirth, 'YYYY-MM-DD'))) {
            togglePopUp();
            return;
        }
        else {
            const data = {
                accountId: accountid,
                inviteCode: code,
                lastName: lastName,
                birthdate: moment(dateOfBirth).format('YYYY-MM-DD')
            };
            await InviteValidate(data);
            if (!showInvalidUserPopUp && requestInviteValidatePostStatus === "failure") {
                toggleInvalidUserPopUp();
            }
        }
    };
    useEffect(() => {
        if (requestInviteValidatePostStatus === "success") {
            const holdData = {
                lastName: lastName,
                birthdate: dateOfBirth
            };
            setUserNameAndDOBAction(holdData);
            resetResponseOfInviteValidateFalse();
            setStep(2);
        }
    }, [requestInviteValidatePostStatus]);
    useEffect(() => {
        const holdData = {
            lastName: '',
            birthdate: ''
        };
        setUserNameAndDOBAction(holdData);
        resetInviteLoginStoredData();
    }, []);
    const render19 = () => (React.createElement(PopUp, { isOpen: showPopUp, toggle: togglePopUp, allowClose: true },
        React.createElement("div", { className: classNames(s.textleft, s.popup) },
            React.createElement("div", { className: s.popup__header },
                React.createElement("div", null,
                    React.createElement("img", { src: NotAllowedImg, className: classNames(s.popup__header_icon, s.mlauto) })),
                React.createElement("div", { className: s.popup__header_title }, t("ageModalTitle")),
                React.createElement("p", null, t("ageModalDescription").replace("SUPPORT_NUMBER", config.support_phone_number))),
            React.createElement("div", { className: s.popup__controls },
                React.createElement(Button, { style: classNames(s.button, s.m0), text: t("understood"), onClick: togglePopUp })))));
    return (React.createElement(React.Fragment, null,
        render19(),
        React.createElement("div", { className: classNames(s.contentWrapperBelowHeader) },
            React.createElement("div", { className: classNames(s.scrollWrapperfrom) },
                React.createElement("div", { className: classNames(s.formstep, s.formWrapper) },
                    React.createElement("div", { className: classNames(s.formstepbar) },
                        React.createElement("div", { className: (s.stepToptext) },
                            header,
                            React.createElement("h4", null, t("inviteHeader")),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null,
                                    t("inviteMessage"),
                                    React.createElement("br", null),
                                    React.createElement("br", null),
                                    t("haveQuestions"),
                                    " ",
                                    t("contactInvitor"),
                                    " ",
                                    React.createElement(Typography, { type: 'paragraph', bold: true }, name),
                                    " ",
                                    t("contactInvitorByPhone"),
                                    " ",
                                    React.createElement("a", { href: `tel:${ph}` }, ph),
                                    " ",
                                    t("contactInvitorByEmail"),
                                    " ",
                                    React.createElement("a", { href: `mailto:${email}` }, email)),
                                React.createElement("p", null, t("pleaseverify")))),
                        React.createElement("div", { className: s.contentInput },
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null, t("lastName")),
                                React.createElement(InputText, { name: "l_name", errorMessage: t('errorIncorrectLastName'), pattern: '^.{0,35}$', value: lastName, setValue: setLastName, error: lastName && errorLastName, setError: setErrorLastName, onFocus: true, tabIndex: 1 })),
                            React.createElement("div", { className: classNames(s.formGroup) },
                                React.createElement("label", null, t("dateOfBirth")),
                                React.createElement(InputDate, { name: "birthday", value: dateOfBirth, setValue: setDate, minDate: minDate, maxDate: maxDate, tabIndex: 2 })),
                            React.createElement("div", { className: s.buttonTwopage },
                                React.createElement(Button, { text: t("back") }),
                                React.createElement(Button, { primary: true, disabled: !allFieldsValid(), text: t("continue"), onClick: onSubmit }))))))),
        requestInviteValidatePostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        requestInviteValidatePostStatus: state.invite.requestInviteValidatePostStatus,
        userNameAndDOBState: state.invite.userNameAndDOB
    };
};
function mapDispatchToProps(dispatch) {
    return {
        InviteValidate: (data) => dispatch(createInviteValidatesInfoAsyncAction({ data })),
        resetResponseOfInviteValidateFalse: () => dispatch(setResponseOfInviteValidateFalse()),
        setUserNameAndDOBAction: (data) => dispatch(setUserNameAndDOB({ data })),
        resetInviteLoginStoredData: () => dispatch(resetInviteLoginStoredData())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ValidateInviteCredentials);
