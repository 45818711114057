import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import config from "../../../config.json";
import { useLocation } from "react-router-dom";
import { LanguagePicker, } from "../../components/Shared/Controls";
import logo from "../../resources/images/logo.svg";
import AppStore from "../../resources/images/appStore.png";
import PhoneMockup from "../../resources/images/phone_mockup.png";
import GooglePlay from "../../resources/images/googlePlay.png";
import TermsOfUse from "../../components/Shared/PopUps/TermsOfUse";
import PrivacyPolicy from "../../components/Shared/PopUps/PrivacyPolicy";
import Accessibility from "../../components/Shared/PopUps/Accessibility";
import s from "./style.scss";
import Banner from "../components/Banner";
const InviteRedirect = ({}) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const url = query.get('url');
    const name = query.get('name');
    const phone = query.get('ph');
    const email = query.get("email");
    const [appUrl, setAppUrl] = useState("");
    const [webUrl, setWebUrl] = useState("");
    useEffect(() => {
        for (let [key, value] of query) {
            console.log(`${key}=${value}`);
        }
        try {
            let urlParts = url?.split("?");
            let appPath = urlParts?.[0];
            let search = urlParts?.[1];
            let appPathParts = appPath?.split("://");
            let scheme = appPathParts?.[0];
            let path = appPathParts?.[1];
            let builtUrl = `${path}?${search}`;
            if (!!name) {
                builtUrl += `&name=${name}`;
            }
            if (!!email) {
                builtUrl += `&email=${email}`;
            }
            if (!!phone) {
                builtUrl += `&ph=${phone}`;
            }
            console.log(`${scheme}://${builtUrl}`);
            console.log(`https://${window.location.hostname}/${builtUrl}`);
            setAppUrl(`${scheme}://${builtUrl}`);
            if (window.location.hostname == "localhost")
                setWebUrl(`http://localhost:8080/${builtUrl}`);
            else
                setWebUrl(`https://${window.location.hostname}/${builtUrl}`);
            window.location.href = `${scheme}://${builtUrl}`;
        }
        catch (e) {
            console.log(e);
        }
    }, [url]);
    const { t } = useTranslation();
    const [selectedLang, setLanguage] = useState(localStorage.getItem("i18nextLng") || "en");
    const [termsOfUseIsOpen, setTermsOfUseIsOpen] = useState(false);
    const [accessibilityIsOpen, setAccessibilityIsOpen] = useState(false);
    const [isOpenPrivacyPolicy, setPrivacyPolicyOpen] = useState(false);
    const navigate = useNavigate();
    return (React.createElement("div", null,
        React.createElement(TermsOfUse, { isOpen: termsOfUseIsOpen, setIsOpen: setTermsOfUseIsOpen }),
        React.createElement(Accessibility, { isOpen: accessibilityIsOpen, setIsOpen: setAccessibilityIsOpen }),
        React.createElement(PrivacyPolicy, { isOpen: isOpenPrivacyPolicy, setIsOpen: setPrivacyPolicyOpen }),
        React.createElement(Banner, null),
        React.createElement("div", { className: s.splash },
            React.createElement("div", { className: s.header },
                React.createElement(SVG, { src: logo, className: s.logoIcon }),
                React.createElement(LanguagePicker, { style: s.languagePicker, selected: selectedLang, setLanguage: setLanguage })),
            React.createElement("div", { className: s.splashContent },
                React.createElement("div", { className: s.splashSubContent },
                    React.createElement("div", { className: s.helpBlockFirst },
                        React.createElement("p", { className: s.getApp }, t("redirectingToApp ")),
                        React.createElement("p", { className: s.helpText }, t("notOpening")),
                        React.createElement("a", { className: classNames(s.button, s.primary), href: appUrl }, t("openInApp")),
                        React.createElement("a", { className: classNames(s.button, s.primary), href: webUrl }, t("continueOnWeb")))),
                React.createElement("div", { className: s.splashSubContentSecond },
                    React.createElement("img", { className: s.devices, src: PhoneMockup }),
                    React.createElement("div", { className: s.info },
                        React.createElement("div", { className: s.getApp }, "Get Amgen Entrust\u00AE Patient App for your devices"),
                        React.createElement("div", { className: classNames(s.badges, s.inline, s.fs18) },
                            React.createElement("a", { target: "_blank", href: config.appstore_download_link },
                                React.createElement("img", { src: AppStore, className: s.badge })),
                            React.createElement("a", { target: "_blank", href: config.play_market_download_link },
                                React.createElement("img", { src: GooglePlay, className: s.badge })))))),
            React.createElement("div", { className: s.splashFooter },
                React.createElement("div", { className: s.paragraph_1 }),
                React.createElement("div", { className: s.splashFooter__links },
                    React.createElement("a", { className: s.paragraph_1, onClick: () => setTermsOfUseIsOpen(true) }, t("termOfUse")),
                    React.createElement("a", { target: "_blank", className: s.paragraph_1, onClick: () => setPrivacyPolicyOpen(true) }, t("privacyPolicy")),
                    React.createElement("a", { className: s.paragraph_1, onClick: () => setAccessibilityIsOpen(true) }, t("accessibility")))))));
};
export default InviteRedirect;
