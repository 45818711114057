import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import classNames from "classnames";
import { Button } from "../../../components/Shared/Controls";
import { useInput, usePrevious } from "../../../hooks";
import { requestSmsCodeForResetPassAsyncAction, resetRequestSmsCodeForResetPassStatusAction, } from "../../../store";
import s from "../style.scss";
import { Box } from "../../../components";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import colors from "../../../resources/colors";
import sw from "../../SignUp/Steps/InvitationEmail/style.scss";
const Step1 = ({ header, setStep, requestCode, requestSmsCodeStatus, resetStatus, previousStep }) => {
    const { t } = useTranslation();
    const onChangePhoneNumber = (phoneNum) => {
        if (phoneNum && !matchIsValidTel(phoneNum)) {
            setErrorPhoneNumber('Enter a valid US or Canadian phone number');
        }
        else {
            setErrorPhoneNumber(null);
        }
        setPhoneNumber(phoneNum);
    };
    const requestSmsCodeStatusPrevious = usePrevious(requestSmsCodeStatus);
    const [phoneNumber, setPhoneNumber, errorPhoneNumber, setErrorPhoneNumber] = useInput();
    const formatPhoneNumber = (phoneNum) => {
        const phoneRegex = /^\+1\s?/;
        return phoneNum.replace(phoneRegex, '').replace(/\s/g, '');
    };
    const getCode = () => {
        resetStatus();
        requestCode(formatPhoneNumber(phoneNumber));
    };
    useEffect(() => {
        if (requestSmsCodeStatusPrevious !== "success" && requestSmsCodeStatus === "success") {
            resetStatus();
            setStep(2);
        }
    }, [requestSmsCodeStatus]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(s.form, s.formWrapper) },
            React.createElement("div", { className: classNames(s.contentWrapper, s.scrollWrapper) },
                React.createElement("div", null,
                    header,
                    React.createElement("h1", null, t("resetPassword")),
                    React.createElement("div", { className: s.text }, t("enterPhoneNumberLabel"))),
                React.createElement("div", { className: s.contentInput },
                    React.createElement("label", null, t("phoneNumber")),
                    React.createElement(MuiTelInput, { value: phoneNumber, onChange: onChangePhoneNumber, error: !!errorPhoneNumber, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 4 }),
                    errorPhoneNumber && React.createElement("div", { className: classNames(sw.error) }, errorPhoneNumber),
                    React.createElement(Box, { f1: true, row: true, gap: 16 },
                        React.createElement(Button, { text: t("back"), onClick: previousStep }),
                        React.createElement(Button, { style: s.mb0_button, disabled: !phoneNumber || errorPhoneNumber, primary: true, text: t("continue"), onClick: getCode })))))));
};
const mapStateToProps = (state) => {
    return {
        requestSmsCodeStatus: state.forgotPassword.requestSmsCodeForResetPassStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        requestCode: (phoneNumber) => dispatch(requestSmsCodeForResetPassAsyncAction(phoneNumber)),
        resetStatus: () => dispatch(resetRequestSmsCodeForResetPassStatusAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Step1);
