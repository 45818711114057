import React from 'react';
import s from './style.scss';
import classNames from 'classnames';
import { Button } from '../../Controls';
import { useTranslation } from 'react-i18next';
import { PopUp } from '../../Info';
const AreYouSure = ({ style, isOpen, setIsOpen, toggle, positiveAction, negativeAction, children }) => {
    const { t } = useTranslation();
    return (React.createElement(PopUp, { style: s.ySureModal, isOpen: isOpen, toggle: toggle },
        React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, children),
        React.createElement("div", { className: s.actions },
            React.createElement(Button, { style: s.button, text: t("continue"), primary: true, onClick: () => {
                    positiveAction && positiveAction();
                    toggle();
                } }),
            React.createElement(Button, { style: s.button, text: t("cancel"), onClick: () => {
                    negativeAction && negativeAction();
                    toggle();
                } }))));
};
export default AreYouSure;
