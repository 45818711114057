import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { ForgotPasswordRepository } from "../repository";
import { handleApiError } from "../utils";
import slice, { SLICE_NAME } from "./Slice";
import { forgotPassCodeSelector, forgotPassNumberSelector } from "./selectors";
import _ from "lodash";
export const requestSmsCodeForResetPassAsyncAction = createAction(`${SLICE_NAME}/requestSmsCodeForResetPassAsyncAction`);
export const validateSmsCodeForResetPassAsyncAction = createAction(`${SLICE_NAME}/validateSmsCodeForResetPassAsyncAction`);
export const resetPasswordAsyncAction = createAction(`${SLICE_NAME}/resetPasswordAsyncAction`);
export function* requestSmsCodeForResetPassSaga(action) {
    const { startRequestSmsCodeForResetPassAction, showRequestSmsCodeForResetPassErrorAction, savePhoneNumberAction, } = slice.actions;
    try {
        yield put(startRequestSmsCodeForResetPassAction());
        const numberFromStore = yield select(forgotPassNumberSelector);
        const number = _.isNil(action.payload) ? numberFromStore : action.payload;
        yield call(ForgotPasswordRepository.requestCode, number);
        yield put(savePhoneNumberAction(number));
    }
    catch (error) {
        yield handleApiError(error, showRequestSmsCodeForResetPassErrorAction);
    }
}
export function* validateSmsCodeForResetPassSaga(action) {
    const { startRequestValidateCodeForResetPassAction, saveSmsCodeAction, showRequestValidateCodeForResetPassErrorAction, } = slice.actions;
    try {
        yield put(startRequestValidateCodeForResetPassAction());
        const number = yield select(forgotPassNumberSelector);
        if (number) {
            yield call(ForgotPasswordRepository.validateCode, number, action.payload);
        }
        yield put(saveSmsCodeAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestValidateCodeForResetPassErrorAction);
    }
}
export function* resetPasswrodSaga(action) {
    const { startRequestResetPassAction, showRequestResetPassErrorAction, successRequestResetPassAction, } = slice.actions;
    try {
        yield put(startRequestResetPassAction());
        const number = yield select(forgotPassNumberSelector);
        const code = yield select(forgotPassCodeSelector);
        if (number && code) {
            yield call(ForgotPasswordRepository.resetPassword, number, code, action.payload.newPassword, action.payload.newPasswordConfirmation);
        }
        yield put(successRequestResetPassAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestResetPassErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(requestSmsCodeForResetPassAsyncAction, requestSmsCodeForResetPassSaga);
    yield takeLatest(validateSmsCodeForResetPassAsyncAction, validateSmsCodeForResetPassSaga);
    yield takeLatest(resetPasswordAsyncAction, resetPasswrodSaga);
}
