import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { NavLink as Link } from "react-router-dom";
import Dollar from "../../resources/icons/dollar";
import { connect, useDispatch } from "react-redux";
import { checkAvailabilityOfFeature, getNotificationsAsyncAction, getProfileTabsAsyncAction, showMenuAction } from "../../store";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import colors from "../../resources/colors";
import Prescription from "../../resources/icons/prescription";
import Receipts from "../../resources/icons/receipts";
import Home from "../../resources/icons/home";
import Education from "../../resources/icons/education";
import ChatIcon from "../../resources/icons/chat";
import config from "../../../config.json";
import HealthCard from "../../resources/icons/health-card";
import Profile from "../../resources/icons/userSidebar";
import PersonalData from "../../resources/icons/personal_data";
import HealthInformation from "../../resources/icons/health_info";
import MedicationRemindersIcon from "../../resources/icons/reminder";
import s from "./style.scss";
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { DEV_BASE_URL, PROD_BASE_URL, STAGE_BASE_URL } from "../../store/api/ApiManager";
import Cookies from "js-cookie";
import images from "../../resources/images";
import Typography from "../Shared/Typography";
import BrandSelect from "../Shared/PageContainer/components/PageHeader/components/BrandSelect/BrandSelect";
import File from "../../resources/icons/file.svg";
import TermsOfUse from "../Shared/PopUps/TermsOfUse";
import PrivacyPolicy from "../Shared/PopUps/PrivacyPolicy";
import Accessibility from "../Shared/PopUps/Accessibility";
import { getMessaging as getMessagingForeground, onMessage } from "firebase/messaging";
const Sidebar = ({ isMenuShown, checkFeature, patientId, isAvailableFA, registrationUnfinished, programLogo, programUrl, isAdmin, impersonatedAccountId, getProfileTabs, getNotifications }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [termsOfUseIsOpen, setTermsOfUseIsOpen] = useState(false);
    const [privacyPolicyIsOpen, setPrivacyPolicyIsOpen] = useState(false);
    const [accessibilityIsOpen, setAccessibilityIsOpen] = useState(false);
    const HomeMenu = {
        title: "homepage",
        titleTo: "/",
        icon: React.createElement(Home, { color: colors.icons.sidebar.primary, activeColor: colors.icons.sidebar.active }),
        items: [
            {
                name: t("profile"),
                url: "/profile",
                icon: React.createElement(Profile, { color: colors.icons.sidebar.primary }),
                show: true
            },
            {
                name: "financialAssistance",
                url: "/financial-assistance",
                icon: React.createElement(Dollar, { color: colors.icons.sidebar.primary }),
                show: false // isAvailableFA
            },
            {
                name: t("prescriber"),
                url: "/prescriber",
                icon: React.createElement(Prescription, { color: colors.icons.sidebar.primary }),
                show: checkFeature("Prescriber")
            },
            {
                name: "medicationReminders",
                url: "/medication-reminders",
                icon: React.createElement(MedicationRemindersIcon, { color: colors.icons.sidebar.primary }),
                show: checkFeature("Reminders")
            },
            {
                name: "education",
                url: "/education", icon: React.createElement(Education, { color: colors.icons.sidebar.primary, activeColor: colors.icons.sidebar.active }),
                show: checkFeature("Education")
            },
            {
                name: t("pharmacy"),
                url: "/pharmacy",
                icon: React.createElement(MedicationRemindersIcon, { color: colors.icons.sidebar.primary }),
                show: checkFeature("Pharmacy")
            },
            {
                name: t("infusionClinic"),
                url: "/infusion-Clinic",
                icon: React.createElement(MedicationRemindersIcon, { color: colors.icons.sidebar.primary }),
                show: checkFeature("InfusionClinic")
            },
            {
                name: "prescriptions",
                url: "/prescriptions",
                icon: React.createElement(Prescription, { color: colors.icons.sidebar.primary }),
                show: checkFeature("Prescriptions")
            },
            {
                name: "dispenses",
                url: "/receipts",
                icon: React.createElement(Receipts, { color: colors.icons.sidebar.primary }),
                show: checkFeature("Receipts")
            },
            {
                name: "chatWithProgram",
                url: "/help-chat",
                icon: React.createElement(ChatIcon, { color: colors.icons.sidebar.primary }),
                show: checkFeature("Chat")
            },
        ],
        routes: ["/", "/financial-assistance", "/prescriptions", "/receipts", "/education"],
    };
    const HomeAdminMenu = {
        title: t("patients"),
        titleTo: "/patients",
        icon: React.createElement(Dollar, { color: colors.icons.sidebar.primary, activeColor: colors.icons.sidebar.active }),
        items: [
            // {
            //   name: t("patients"),
            //   url: "/patients",
            //   icon: <Dollar color={colors.icons.sidebar.primary} />,
            //   show: true
            // },
            {
                name: t("drugBrands"),
                url: "/brands",
                icon: React.createElement(Prescription, { color: colors.icons.sidebar.primary }),
                show: true
            },
            {
                name: "education",
                url: "/education/admin", icon: React.createElement(Education, { color: colors.icons.sidebar.primary, activeColor: colors.icons.sidebar.active }),
                show: true
            },
            {
                name: t("medicalConditions"),
                url: "/MedicalConditions", icon: React.createElement(Education, { color: colors.icons.sidebar.primary, activeColor: colors.icons.sidebar.active }),
                show: true
            },
            {
                name: t("prescribers"),
                url: "/prescribers",
                icon: React.createElement(Prescription, { color: colors.icons.sidebar.primary }),
                show: true
            },
            {
                name: t("pharmacies"),
                url: "/pharmacies",
                icon: React.createElement(Prescription, { color: colors.icons.sidebar.primary }),
                show: true
            },
            {
                name: t("infusionClinics"),
                url: "/infusionclinic",
                icon: React.createElement(Prescription, { color: colors.icons.sidebar.primary }),
                show: true
            },
            {
                name: t("Webhooks"),
                url: "/webhooks",
                icon: React.createElement(Prescription, { color: colors.icons.sidebar.primary }),
                show: true
            },
            {
                name: t("apiKeys"),
                url: "/apikeys",
                icon: React.createElement(Education, { color: colors.icons.sidebar.primary }),
                show: true
            }
        ],
        routes: ["/patients", "/brands", "/education", "/MedicalConditions", "/prescriber", "/pharmacies", "/clinics", "/webhooks", "/apikeys"],
    };
    // <List>
    //       {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
    //         <ListItem key={text} disablePadding>
    //           <ListItemButton>
    //             <ListItemIcon>
    //               {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
    //             </ListItemIcon>
    //             <ListItemText primary={text} />
    //           </ListItemButton>
    //         </ListItem>
    //       ))}
    //     </List>
    if (window.location.pathname !== "/prescriber" && !window.location.pathname.startsWith("/prescriber/")) {
        localStorage.removeItem('prescriberObjectLocalStorage');
    }
    const ProfileMenu = {
        title: "profile",
        titleTo: "/profile",
        icon: React.createElement(Profile, { color: colors.icons.sidebar.primary }),
        items: [
            { name: "personalData", url: "/personal-data", icon: React.createElement(PersonalData, { color: colors.icons.sidebar.primary }), show: true },
            { name: "medicalDocuments", url: "/medical-documents", icon: React.createElement(HealthCard, { color: colors.icons.sidebar.primary }), show: true },
            { name: "healthInformation", url: "/health-information", icon: React.createElement(HealthInformation, { color: colors.icons.sidebar.primary }), show: true },
        ],
        routes: ["/profile", "/personal-data", "/medical-documents", "/health-information"],
    };
    useEffect(() => {
        if (!!impersonatedAccountId) {
            getProfileTabs();
        }
    }, [impersonatedAccountId]);
    const [hubConnection, setHubConnection] = useState(null);
    const [countOfUnreadMessages, setCountOfUnreadMessages] = useState(0);
    useEffect(() => {
        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.NODE_ENV === 'production' ? PROD_BASE_URL : process.env.NODE_ENV === 'staging' ? STAGE_BASE_URL : DEV_BASE_URL}/chatHub?patientId=${patientId}`, {
            withCredentials: true,
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: () => Cookies.get("token") || ""
        })
            .build();
        hubConnection
            .start()
            .then(() => {
            hubConnection.invoke("RequestCountUnreadConversationMessages", patientId);
        })
            .catch(err => console.log('Error while establishing connection ' + err));
        hubConnection.on('ReceiveCountUnreadMessagesInConversation', (countUnreadMessages) => {
            setCountOfUnreadMessages(countUnreadMessages);
        });
        setHubConnection(hubConnection);
    }, []);
    const [hoveredItem, setHoveredItem] = useState(null);
    const messaging = getMessagingForeground();
    onMessage(messaging, (remoteMessage) => {
        console.log('Message received. ', remoteMessage);
        if (remoteMessage.notification) {
            new Notification(remoteMessage.notification?.title ?? "", {
                body: remoteMessage.notification?.body || "",
                silent: false
            });
            getNotifications({
                patientId: patientId, pageNumber: 1, pageSize: 20
            });
        }
    });
    const renderMenuTitle = (title, url, icon) => {
        return !registrationUnfinished ? (React.createElement(Link, { to: url, onClick: () => menuState(false), onMouseEnter: () => setHoveredItem(title), onMouseLeave: () => setHoveredItem(null), className: ({ isActive }) => classNames(s.menuListTitle, { [s.menuListTitleHovered]: isActive || hoveredItem === title }) },
            icon,
            React.createElement(Typography, { type: 'paragraph' }, t(title)))) : (React.createElement("a", { className: s.menuListTitle },
            icon,
            t(title)));
    };
    const renderMenuItems = (items, hovered) => {
        return items.filter((item) => {
            return item.show;
        }).map((item) => {
            return !registrationUnfinished ? (React.createElement(Link, { key: item.url, to: item.url, onClick: () => menuState(false), onMouseEnter: () => setHoveredItem(item.name), onMouseLeave: () => setHoveredItem(null), className: classNames(s.menuLink, { [s.menuLinkHovered]: hovered }), style: ({ isActive }) => ({
                    color: hoveredItem === item.name || isActive ? colors.primary : "#454865",
                }) },
                item.icon,
                t(item.name),
                item.name === "chatWithProgram" && countOfUnreadMessages > 0 && React.createElement("div", { className: s.counter }, countOfUnreadMessages))) :
                React.createElement("a", { className: classNames(s.menuLink, { [s.menuLinkHovered]: hovered }) },
                    item.icon,
                    t(item.name));
        });
    };
    const menuState = (state) => {
        dispatch(showMenuAction(state));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TermsOfUse, { isOpen: termsOfUseIsOpen, setIsOpen: setTermsOfUseIsOpen }),
        React.createElement(PrivacyPolicy, { isOpen: privacyPolicyIsOpen, setIsOpen: setPrivacyPolicyIsOpen }),
        React.createElement(Accessibility, { isOpen: accessibilityIsOpen, setIsOpen: setAccessibilityIsOpen }),
        isMenuShown && React.createElement("div", { className: s.overlay, onClick: () => menuState(false) }),
        React.createElement("div", { className: classNames(s.sidebar, { [s.isHidden]: !isMenuShown }), style: {
                background: colors.white
            } },
            isAdmin == true ? React.createElement("br", null) : (config.appSingleBrand &&
                React.createElement("img", { src: images.brandImage1, className: s.brandLogo }) || React.createElement(BrandSelect, null)),
            React.createElement("div", { className: s.menu }, isAdmin == true ?
                React.createElement(React.Fragment, null, [HomeAdminMenu].map((menu) => {
                    return (React.createElement("div", { key: menu.titleTo, className: s.menuList },
                        React.createElement("div", { className: s.menuListWrapper },
                            renderMenuTitle(menu.title, menu.titleTo, menu.icon),
                            menu.items && renderMenuItems(menu.items))));
                }))
                : React.createElement(React.Fragment, null, [HomeMenu].map((menu) => {
                    return (React.createElement("div", { key: menu.titleTo, className: s.menuList },
                        React.createElement("div", { className: s.menuListWrapper },
                            renderMenuTitle(menu.title, menu.titleTo, menu.icon),
                            menu.items && renderMenuItems(menu.items))));
                }))),
            React.createElement("div", { className: s.legalDocumentsWrapper },
                React.createElement("div", { key: "LegalDocuments", className: classNames(s.legalDocumentsLink, s.menuList) },
                    React.createElement("div", { className: s.menuListWrapper },
                        React.createElement("a", { className: classNames(s.menuLink) },
                            React.createElement(SVG, { src: File, className: s.menuIcon }),
                            t("legalDocuments")))),
                React.createElement("div", { className: s.legalDocumentsMenu },
                    React.createElement("div", { key: "termOfUse" },
                        React.createElement("a", { className: classNames(s.menuLink), onClick: () => setTermsOfUseIsOpen(true) },
                            React.createElement(SVG, { src: File, className: s.menuIcon }),
                            " ",
                            t("termOfUse"))),
                    React.createElement("div", { key: "privacyPolicy" },
                        React.createElement("a", { className: classNames(s.menuLink), onClick: () => setPrivacyPolicyIsOpen(true) },
                            React.createElement(SVG, { src: File, className: s.menuIcon }),
                            " ",
                            t("privacyPolicy"))),
                    React.createElement("div", { key: "accessibility" },
                        React.createElement("a", { className: classNames(s.menuLink), onClick: () => setAccessibilityIsOpen(true) },
                            React.createElement(SVG, { src: File, className: s.menuIcon }),
                            " ",
                            t("accessibility"))))))));
};
function mapDispatchToProps(dispatch) {
    return {
        getProfileTabs: (data) => dispatch(getProfileTabsAsyncAction(data)),
        getNotifications: (payload) => dispatch(getNotificationsAsyncAction(payload))
    };
}
const mapStateToProps = (state) => {
    return {
        isMenuShown: state.navigation.menuShown,
        checkFeature: (key) => checkAvailabilityOfFeature(state, key),
        patientId: state.account.user.patientId,
        isAvailableFA: state.navigation.availableFA,
        programLogo: state.account.currentProgramLogo,
        programUrl: state.account.currentProgramWebsite,
        registrationUnfinished: state.account.registrationUnfinished,
        isAdmin: state.account.isAdmin,
        impersonatedAccountId: state.account.user.impersonatedAccountId
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
