export const mapMessages = (response) => {
    return response.value.resultItems.map((item) => {
        return {
            conversationMessageId: item.conversationMessageId,
            textMessage: item.textMessage,
            date: item.created,
            yours: item.direction === "TO_APP",
        };
    });
};
export const mapMeta = (response) => {
    return response.value;
};
