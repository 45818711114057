import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getPatientAddressesAsyncAction, allPatientAddressesSelector, editPatientAddressAsyncAction, deletePatientAddressAsyncAction, } from "../../../../store";
import MakePrimary from "../../../../resources/icons/check-square";
import Primary from "../../../../resources/icons/done";
import Edit from "../../../../resources/icons/edit.svg";
import Delete from "../../../../resources/icons/trash_full.svg";
import Plus from "../../../../resources/icons/plus_circle";
import AddEditAddress from "./components/AddEditAddress";
import { PopUp } from "../../../../components/Shared/Info";
import Button from "../../../../components/Shared/Controls/Button";
import { useErrorPopUp } from "../../../../hooks";
import ErrorPopUp from "../../../../components/Shared/Info/ErrorPopUp";
import s from "./style.scss";
import colors from "../../../../resources/colors";
const PatientAddress = ({ patientId, getPatientInfo, addresess, editAddress, deleteAddress, }) => {
    const { t } = useTranslation();
    const [isOpen, showPopUp, errorContent, setErrorContent, toggleErrorPopUp] = useErrorPopUp();
    const [openAddress, setOpenAddress] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [addressDelete, setAddressDelete] = useState("");
    const [editingAddress, setEditingAddress] = useState(null);
    useEffect(() => {
        getPatientInfo();
    }, [patientId]);
    const makePrimary = (address) => {
        editAddress(address);
    };
    const togglePopup = () => setOpenAddress((open) => !open);
    const toggleDeletePopup = () => setOpenDeletePopup((open) => !open);
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: s.title }, t("yourAddress")),
        React.createElement("div", { className: s.addressesWrapper }, addresess &&
            addresess.map((address) => {
                return (React.createElement("div", { key: address.id, className: classNames(s.address, {
                        [s.primaryAddress]: address.isPrimary,
                    }), style: {
                        borderColor: address.isPrimary && colors.addressBorderColor || undefined,
                        boxShadow: `0 0 0 3px ${address.isPrimary && colors.addressShadowColor}`
                    } },
                    React.createElement("div", { className: classNames(s.addressHeader, {
                            [s.primaryHeader]: address.isPrimary,
                        }) },
                        address.isPrimary ? React.createElement(Primary, { color: colors.addressTextColorActive })
                            : React.createElement(MakePrimary, { color: colors.addressTextColor }),
                        React.createElement("span", { style: {
                                color: address.isPrimary ? colors.addressTextColorActive : colors.addressTextColor
                            }, onClick: () => makePrimary({ ...address, isPrimary: true }) }, address.isPrimary ? t("primary") : t("makePrimary")),
                        React.createElement(SVG, { onClick: () => {
                                setEditingAddress(address);
                                togglePopup();
                            }, className: s.edit, src: Edit }),
                        React.createElement(SVG, { onClick: () => {
                                if (address.isPrimary) {
                                    setErrorContent(t("cantDeleteAddress"));
                                    showPopUp(true);
                                }
                                else {
                                    toggleDeletePopup();
                                    setAddressDelete(address.id);
                                }
                            }, className: s.delete, src: Delete })),
                    address.street && React.createElement("p", null, address.street),
                    address.city && React.createElement("p", null, address.city),
                    address.provinceName && (React.createElement("p", null,
                        address.provinceName,
                        " ",
                        address.postalCode && `- ${address.postalCode}`))));
            })),
        React.createElement("button", { onClick: togglePopup, className: s.addButton, style: { color: colors.icons.primary } },
            React.createElement(Plus, { color: colors.icons.primary }),
            t("addAddress")),
        React.createElement(AddEditAddress, { openAddress: openAddress, editingAddress: editingAddress, togglePopup: togglePopup, setEditingAddress: setEditingAddress }),
        React.createElement(PopUp, { isOpen: openDeletePopup },
            React.createElement("div", { className: s.popup },
                React.createElement("div", { className: s.popup__content },
                    React.createElement("div", null, t("sureDeleteAddress"))),
                React.createElement("div", { className: s.popup__controls },
                    React.createElement(Button, { text: t("cancel"), onClick: () => {
                            setAddressDelete("");
                            toggleDeletePopup();
                        } }),
                    React.createElement(Button, { primary: true, text: t("delete"), onClick: () => {
                            deleteAddress(addressDelete);
                            setAddressDelete("");
                            toggleDeletePopup();
                        } })))),
        React.createElement(ErrorPopUp, { isOpen: isOpen, popUpContent: errorContent, togglePopUp: toggleErrorPopUp })));
};
const mapStateToProps = (state) => {
    return {
        patientId: state.account.user.patientId,
        addresess: allPatientAddressesSelector(state),
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPatientInfo: () => dispatch(getPatientAddressesAsyncAction()),
        editAddress: (address) => dispatch(editPatientAddressAsyncAction(address)),
        deleteAddress: (addressId) => dispatch(deletePatientAddressAsyncAction({ addressId })),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientAddress);
