import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import Arrow from "resources/icons/arrow.svg";
import PageBody from "../../components/Shared/PageBody";
import Prescriptions from "../../components/MedicalDocuments/Prescriptions";
import Receipts from "../../components/MedicalDocuments/Receipts";
import HealthCard from "../../components/MedicalDocuments/HealthCard";
import Insurances from "../../components/MedicalDocuments/Insurances";
import { resetLengthStepsToComplete } from "../../store";
import AdditionalStepsWrapper from "./AdditionalStepsWrapper";
import AdditionFA from "./FinansialAssistance";
import LoadingPopUp from "../../components/Shared/LoadingPopUp";
import { PopUp } from "../../components/Shared/Info";
import { Button } from "../../components/Shared/Controls";
import s from "./style.scss";
import AddPrescriber from "../SignUp/Steps/InvitationEmail/Components/AddPrescriberManual";
import AddInfusionClinic from "../SignUp/Steps/InvitationEmail/Components/AddInfusionClinic";
import SelectPharmacy from "../SignUp/Steps/InvitationEmail/Components/SelectPharmacy";
import AboutYouPage from "../SignUp/Steps/AboutYouPage/AboutYouPage";
import HealthInformationPage from "../SignUp/Steps/InvitationEmail/Components/HealthInformationPage";
import AddressNewSignUp from "../SignUp/Steps/InvitationEmail/Components/AddressNewSignUp";
import CallPreferences from "../SignUp/Steps/InvitationEmail/Components/CallPreferences";
import AddedPharmacy from "../SignUp/Steps/InvitationEmail/Components/AddedPharmacy";
import AddPharmacy from "../SignUp/Steps/InvitationEmail/Components/AddPharmacy";
import AddPharmacyManual from "../SignUp/Steps/InvitationEmail/Components/AddPharmacyManual";
const AdditionalStepsPage = ({ haveSideBar, brand, steps, backAction, resetLengthStepsToCompleteAction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentStep, setStep] = useState(steps && steps[0] || { step: "Demographics", mandatory: false });
    const [haveChanges, setHaveChanges] = useState(false);
    const [youAreSureModal, setYouAreSureModal] = useState({ show: false });
    const toggleYouAreSureModal = () => {
        setYouAreSureModal((sure) => {
            return { show: !sure.show };
        });
    };
    const extraPharmacySteps = [
        { step: "PharmacyList", mandatory: false },
        { step: "SpecialtyPharmacyAdded", mandatory: false },
        { step: "PharmacyManual", mandatory: false },
    ];
    const nextStep = (isSubmit = false) => {
        let step = steps.find(el => el.step === currentStep.step);
        if (step == undefined && extraPharmacySteps.find(el => el.step === currentStep.step)) {
            step = steps.find(el => el.step === "Pharmacy");
        }
        let index = steps.indexOf(step);
        if (!haveChanges || isSubmit) {
            if (steps[index + 1])
                setStep(steps[++index]);
            else {
                resetLengthStepsToCompleteAction();
                navigate('/');
            }
        }
        else
            setYouAreSureModal({ show: true, key: steps[++index] });
    };
    const previusStep = () => {
        let step = steps.find(el => el.step === currentStep.step);
        let index = steps.indexOf(step);
        if (!haveChanges) {
            index > 0 ? setStep(steps[--index]) : backAction ? backAction() : navigate('/');
        }
        else
            setYouAreSureModal({ show: true, key: steps[--index] });
    };
    useEffect(() => {
        steps.length === 0 ? navigate('/') : setStep(steps[0]);
    }, [steps]);
    const renderYouAreSureModal = () => {
        return React.createElement(PopUp, { style: s.ySureModal, isOpen: youAreSureModal.show, toggle: toggleYouAreSureModal },
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("sureExitWithoutSaving")),
            React.createElement("div", { className: s.actions },
                React.createElement(Button, { style: s.button, text: t("yes"), primary: true, onClick: () => {
                        if (youAreSureModal.key) {
                            setStep(youAreSureModal.key);
                            setHaveChanges(false);
                            toggleYouAreSureModal();
                        }
                        else
                            navigate('/');
                    } }),
                React.createElement(Button, { style: s.button, text: t("no"), onClick: toggleYouAreSureModal })));
    };
    const renderStepHeader = (backAction) => (React.createElement("div", { className: s.header }, steps.length > 1 && steps[0].step !== currentStep.step && (React.createElement("div", { className: s.back, onClick: backAction },
        React.createElement(SVG, { src: Arrow, className: s.arrow }),
        React.createElement("span", null, t("back"))))));
    const renderStep = () => {
        switch (currentStep.step) {
            case "Demographics": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(AboutYouPage, { isNewSignUp: true, setStep: nextStep, prevStep: previusStep, existAzureUser: false })));
            case "HealthInformation": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(HealthInformationPage, { isNewSignUp: true, setStep: nextStep, prevStep: previusStep })));
            case "Address": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(AddressNewSignUp, { prevStep: previusStep, isNewSignUp: true, setStep: nextStep })));
            case "AddInsurer": return (React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(Insurances, { isNewSignUp: true, skipAction: nextStep, prevStep: previusStep, setHaveChanges: setHaveChanges })));
            case "AddHealthCard": return (React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(HealthCard, { isNewSignUp: true, skipAction: nextStep, prevStep: previusStep, setHaveChanges: setHaveChanges },
                    React.createElement("div", { className: s.subheading_1 }, t("healthCardTitle")),
                    React.createElement("div", { className: s.paragraph_2 }, t("uploadImagesHealthCard")))));
            case "Prescriber": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(AddPrescriber, { isUserDashboard: false, setStep: nextStep, isNewSignUp: true, prevStep: previusStep })));
            case "InfusionClinic": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(AddInfusionClinic, { isNewSignUp: true, setStep: nextStep, previousStep: previusStep })));
            case "Pharmacy": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(SelectPharmacy, { setStep: setStep, nextStep: nextStep, previousStep: previusStep, isNewSignUp: true })));
            case "PharmacyList": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(AddPharmacy, { setStep: setStep, nextStep: nextStep, isNewSignUp: true, previousStep: () => setStep({ step: "Pharmacy", mandatory: false }) })));
            case "PharmacyManual": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(AddPharmacyManual, { isNewSignUp: true, setStep: nextStep, previousStep: () => setStep({ step: "PharmacyList", mandatory: false }) })));
            case "SpecialtyPharmacyAdded": return componentWithHeader(React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(AddedPharmacy, { setStep: nextStep, previousStep: () => setStep({ step: "Pharmacy", mandatory: false }), isNewSignUp: true })));
            case "AddPrescription": return (React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement("dd", null),
                React.createElement(Prescriptions, { skipAction: nextStep, setHaveChanges: setHaveChanges, prevStep: previusStep },
                    React.createElement("div", { className: s.subheading_1 }, t("addPrescription")),
                    React.createElement("div", { className: s.paragraph_2 }, `${t("ifYouHave")} ${brand}${t("ifYouHavePrescription")}`))));
            case "AddReceipt": return (React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(Receipts, { skipAction: nextStep, setHaveChanges: setHaveChanges, prevStep: previusStep },
                    React.createElement("div", { className: s.subheading_1 }, t("dispenses")),
                    React.createElement("div", { className: s.paragraph_2 }, t("uploadReceiptPicture")))));
            case "FinancialAssistance": return (React.createElement(AdditionFA, { skipAction: nextStep, backAction: previusStep, mandatory: currentStep.mandatory, hasTitle: true }));
            case "ScheduleCall": return (React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(CallPreferences, { isInAdditionalSteps: true })));
            default: return (React.createElement(AdditionalStepsWrapper, { backAction: previusStep },
                React.createElement(LoadingPopUp, { isOpen: true })));
        }
    };
    const componentWithHeader = (component) => (React.createElement("div", null, component));
    const renderMemberStep = () => {
        switch (currentStep.step) {
            case "AddPrescription": return componentWithHeader(React.createElement(PageBody, { title: t("addPrescription"), backAction: previusStep },
                React.createElement(Prescriptions, { skipAction: nextStep, setHaveChanges: setHaveChanges, prevStep: previusStep, hasSidebar: true },
                    React.createElement("div", { className: s.paragraph_2 }, `${t("ifYouHave")} ${brand}${t("ifYouHavePrescription")}`))));
            case "AddReceipt": return componentWithHeader(React.createElement(PageBody, { title: t("addDispenses"), backAction: previusStep },
                React.createElement(Receipts, { skipAction: nextStep, setHaveChanges: setHaveChanges, hasSidebar: true },
                    React.createElement("div", { className: s.paragraph_2 }, `${t("ifYouHaveCopy")} ${brand}${t("ifYouHaveCopyPrescription")}`))));
            case "AddHealthCard": return componentWithHeader(React.createElement(PageBody, { title: t("provincialHealthCard"), backAction: previusStep },
                React.createElement(HealthCard, { skipAction: nextStep, setHaveChanges: setHaveChanges, prevStep: previusStep, hasSidebar: true },
                    React.createElement("div", { className: s.paragraph_2 }, t("uploadFrontAndBack")))));
            case "AddInsurer": return componentWithHeader(React.createElement(PageBody, { title: t("addInsurer"), backAction: previusStep },
                React.createElement(Insurances, { skipAction: nextStep, setHaveChanges: setHaveChanges, prevStep: previusStep, hasSidebar: true },
                    React.createElement("div", { className: s.paragraph_2 }, t("uploadPhotosInsurance")))));
            case "FinancialAssistance": return componentWithHeader(React.createElement(AdditionFA, { backAction: previusStep, skipAction: nextStep, mandatory: currentStep.mandatory, hasSidebar: true }));
            case "Pharmacy": return componentWithHeader(React.createElement(SelectPharmacy, { setStep: setStep, previousStep: previusStep, isNewSignUp: true }));
            case "InfusionClinic": return componentWithHeader(React.createElement(AddInfusionClinic, { isNewSignUp: true, setStep: setStep, previousStep: previusStep }));
            case "Prescriber": return componentWithHeader(React.createElement(AddPrescriber, { isUserDashboard: false, setStep: nextStep, isNewSignUp: true, prevStep: previusStep }));
            case "Demographics": return componentWithHeader(React.createElement(AboutYouPage, { isNewSignUp: true, setStep: setStep, prevStep: previusStep, existAzureUser: false }));
            case "Address": return componentWithHeader(React.createElement(AddressNewSignUp, { prevStep: previusStep, isNewSignUp: true, setStep: setStep }));
            case "HealthInformation": return componentWithHeader(React.createElement(HealthInformationPage, { isNewSignUp: true, setStep: setStep, prevStep: previusStep }));
            default: navigate('/');
        }
    };
    return React.createElement("div", null,
        renderYouAreSureModal(),
        haveSideBar ? renderMemberStep() : renderStep());
};
const mapStateToProps = (state) => {
    return {
        steps: state.navigation.steps,
        patientId: state.account.user.patientId,
        brand: state.account.user.drugBrandCode,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        resetLengthStepsToCompleteAction: () => dispatch(resetLengthStepsToComplete())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalStepsPage);
