import React from 'react';
import s from './style.scss';
import { PopUp } from '..';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import successIcon from '../../../../resources/images/success_sign_icon.png';
import SVG from "react-inlinesvg";
import Close from "../../../../resources/icons/close.svg";
const SuccessPopUp = ({ style, isOpen, displayMessage, togglePopUp, name, header, onClose }) => {
    const { t } = useTranslation();
    return (React.createElement(PopUp, { contentStyle: s.popUpContent, isOpen: isOpen, toggle: togglePopUp },
        React.createElement("div", { className: s.popheaderwithcloseicon },
            React.createElement("img", { src: successIcon, alt: "Image", style: { width: "100px", height: "100px" } }),
            React.createElement(SVG, { src: Close, height: 10, width: 10, onClick: onClose ? onClose : undefined })),
        React.createElement("h2", null, header),
        React.createElement("div", null,
            React.createElement("h2", null, displayMessage)),
        React.createElement("div", null,
            React.createElement(Button, { className: s.Button, variant: "contained", style: { color: "white", backgroundColor: "0063C3", height: "64.42px", width: "400px" }, onClick: togglePopUp },
                " Back to ",
                name))));
};
export default SuccessPopUp;
