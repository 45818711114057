import React, { useState } from 'react';
import SVG from "react-inlinesvg";
import classNames from 'classnames';
import Picker from '../Shared/Controls/Picker';
import { InputText } from '../Shared/Controls';
import ImageCard from '../Shared/ImageCard';
import { useTranslation } from 'react-i18next';
import AddImageCard from '../Shared/AddImageCard';
import TrashCan from "../../resources/icons/trash_full.svg";
import AreYouSure from '../Shared/PopUps/AreYouSure';
import s from './style.scss';
import Box from '../Shared/Box';
import si from "../../Pages/SignUp/Steps/InvitationEmail/style.scss";
const AddInsuranceInputs = ({ style, fullLine, cardStyle, title, fileType, frontImage, setFrontImage, backImage, setBackImage, nameOfInsurer, setNameOfInsurer, carrier, setCarrier, primaryChoice, setPrimaryChoice, setModalIsOpen, setModalFile, contract, setContract, certificate, setCertificate, removeExistFile, hasSidebar = false, haveChanges = true, clearAllFields, isNewSignUp }) => {
    const { t } = useTranslation();
    const variants = [
        { value: true, title: t("yes") },
        { value: false, title: t("no") },
        { value: null, title: t("notSure") }
    ];
    const removeFile = (setImage) => {
        setImage(null);
    };
    const insuranceCard = (side, fileType, image, setImage) => {
        return React.createElement("div", { className: classNames(s.cardCell) },
            React.createElement("div", { className: classNames(s.subheading_3, s.mb16) }, t(side.includes('FRONT') ? "front" : "backCard")),
            !!image?.uri ? React.createElement(ImageCard, { key: image.name, style: classNames(cardStyle, s.card), url: image.uri, extension: image.extension, name: image.name, removeFile: () => (image.parentFileId || image.id) && removeExistFile(image.parentFileId || image.id) || removeFile(setImage), onClick: () => {
                    setModalFile({ image, setImage });
                    setModalIsOpen(true);
                } }) : !!image?.textValue ?
                React.createElement(AddImageCard, { style: classNames(cardStyle, s.card), shortTitle: t("addImage") + ` (${side.includes('FRONT') ? t("front") : t("backCard")})`, side: side.includes('FRONT') ? t("front") : t("backCard"), addFile: (name, uri, blob, userFiles) => {
                        console.log("Text file id: ", image?.id);
                        userFiles ? setImage({
                            id: image?.id,
                            name: userFiles[0].name,
                            extension: userFiles[0].extension,
                            uri: userFiles[0].uri,
                            filesType: side.includes('FRONT') ? fileType : `${fileType}Back`,
                            description: side
                        }) :
                            setImage({
                                id: image?.id,
                                name: name,
                                extension: "." + name.split('.').pop(),
                                uri: uri,
                                filesType: side.includes('FRONT') ? fileType : `${fileType}Back`,
                                description: side
                            });
                    } }) :
                React.createElement(AddImageCard, { style: classNames(cardStyle, s.card), shortTitle: t("addImage") + ` (${side.includes('FRONT') ? t("front") : t("backCard")})`, side: side.includes('FRONT') ? t("front") : t("backCard"), addFile: (name, uri, blob, userFiles) => {
                        userFiles ? setImage({
                            name: userFiles[0].name,
                            extension: userFiles[0].extension,
                            uri: userFiles[0].uri,
                            filesType: side.includes('FRONT') ? fileType : `${fileType}Back`,
                            description: side
                        }) :
                            setImage({
                                name: name,
                                extension: "." + name.split('.').pop(),
                                uri: uri,
                                filesType: side.includes('FRONT') ? fileType : `${fileType}Back`,
                                description: side
                            });
                    } }));
    };
    const [areYouSureModalIsOpen, setAreYouSureModalIsOpen] = useState(false);
    const toggleAreYouSureModal = () => {
        setAreYouSureModalIsOpen((isOpen) => !isOpen);
    };
    return (React.createElement("div", { className: classNames(style, fullLine) },
        React.createElement("div", { className: classNames(s.insuranceTitle, fullLine) },
            React.createElement("div", { className: classNames(s.subheading_2, s.mb16) }, title),
            clearAllFields && haveChanges && React.createElement("div", { onClick: toggleAreYouSureModal, className: s.clearTextButton },
                fileType.includes('Primary') && t("clearPrimary")
                    || t("clearSecondary"),
                " ",
                React.createElement(SVG, { src: TrashCan }))),
        React.createElement(AreYouSure, { isOpen: areYouSureModalIsOpen, setIsOpen: setAreYouSureModalIsOpen, toggle: toggleAreYouSureModal, positiveAction: clearAllFields }, fileType.includes('Primary') && t("deleteInsurancePrimary") || t("deleteInsuranceSecondary")),
        React.createElement("div", { className: classNames(s.cardList, fullLine) },
            insuranceCard("FRONT", fileType, frontImage, setFrontImage),
            insuranceCard("BACK", fileType, backImage, setBackImage)),
        React.createElement("div", { className: s.inputs },
            isNewSignUp ?
                React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { marginBottom: "34px", marginTop: "20px" }, className: classNames(s.InsurerNameBox, si.stepToptext) },
                        React.createElement("h4", null,
                            t('insurerName'),
                            "-",
                            nameOfInsurer),
                        React.createElement("ul", null,
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    t('groupNumber'),
                                    ":",
                                    contract)),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    t('certNumber'),
                                    ":",
                                    certificate,
                                    " ")),
                            React.createElement("li", null,
                                React.createElement("p", null,
                                    t('memberNumber'),
                                    ":",
                                    carrier)))))
                : null,
            React.createElement(Box, { f1: true, column: true },
                React.createElement(Box, { f1: true, row: true, gap: 20 },
                    React.createElement(Box, { fullWidth: true },
                        React.createElement(InputText, { styleGroup: classNames({ [s.cardCellMedical]: hasSidebar }), name: "nameOfInsurer", label: t("insuranceName"), value: nameOfInsurer, setValue: setNameOfInsurer, autoComplete: "one-time-code" })),
                    React.createElement(Box, { fullWidth: true },
                        React.createElement(InputText, { styleGroup: classNames({ [s.cardCellMedical]: hasSidebar }), name: "carrier", label: t("carrierInput"), value: carrier, setValue: setCarrier, autoComplete: "one-time-code" }))),
                React.createElement(Box, { f1: true, row: true, gap: 20 },
                    React.createElement(Box, { fullWidth: true },
                        React.createElement(InputText, { styleGroup: classNames({ [s.cardCellMedical]: hasSidebar }), name: "contract", label: t("groupPolicyContact"), value: contract, setValue: setContract, autoComplete: "one-time-code" })),
                    React.createElement(Box, { fullWidth: true },
                        React.createElement(InputText, { styleGroup: classNames({ [s.cardCellMedical]: hasSidebar }), name: "certificate", label: t("certificateID"), value: certificate, setValue: setCertificate, autoComplete: "one-time-code" }))),
                React.createElement("div", { className: classNames({ [s.cardCellMedical]: hasSidebar }) },
                    React.createElement("div", { className: classNames(s.paragraph_2, s.mb20) }, t("primaryCardHolder")),
                    React.createElement(Picker, { onChange: value => setPrimaryChoice(value), value: primaryChoice, data: variants, columns: 3 }))))));
};
export default AddInsuranceInputs;
