import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest, takeEvery } from "redux-saga/effects";
import { handleApiError } from "../utils";
import chatSlice, { SLICE_NAME } from "./Slice";
import { mapMessages, mapMeta } from "./Mapper";
import { userInfoSelector } from "../account/selectors";
import ChatRepository from "../repository/ChatRepository";
export const getChatMessagesAsyncAction = createAction(`${SLICE_NAME}/getChatMessagesAsyncAction`);
export function* getChatMessagesSaga(action) {
    const { startRequestAction, showErrorAction, successRequestAction } = chatSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestAction());
        const result = yield call(ChatRepository.getChatMessages, {
            ...action.payload,
            patientId,
        });
        const messages = result.data.value.resultItems ? yield mapMessages(result.data) : [];
        const meta = yield mapMeta(result.data);
        yield put(successRequestAction({ messages: messages || [], meta: meta }));
    }
    catch (error) {
        yield handleApiError(error, showErrorAction);
    }
}
export const sendMessageSagaAsyncAction = createAction(`${SLICE_NAME}/sendMessageSagaAsyncAction`);
export function* sendMessageSaga(action) {
    const { startSendMessageAction, successSendMessageAction, markErrorSendMessageAction, showErrorSendMessageAction } = chatSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    const id = Math.random().toString(36).substr(2, 9);
    const date = new Date().toJSON();
    try {
        yield put(startSendMessageAction({
            conversationMessageId: id,
            textMessage: action.payload,
            date: date
        }));
        yield call(ChatRepository.sendMessage, {
            patientId,
            textMessage: {
                text: action.payload
            }
        });
        yield put(successSendMessageAction({
            conversationMessageId: id,
            textMessage: action.payload,
            date: date
        }));
    }
    catch (error) {
        yield put(markErrorSendMessageAction({
            conversationMessageId: id,
            textMessage: action.payload,
            date: date
        }));
        yield handleApiError(error, showErrorSendMessageAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getChatMessagesAsyncAction, getChatMessagesSaga);
    yield takeEvery(sendMessageSagaAsyncAction, sendMessageSaga);
}
