// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__notification--Tk2z1{display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;width:100%;margin-bottom:16px;background:#d8e9ff;border-radius:4px;position:relative}.style__notification--Tk2z1 .style__content--XDp8Y{width:100%;line-height:22px;padding:10px 10px 10px 0}.style__pointer--SQqcz{cursor:pointer}.style__icon--rX1xm{margin:10px 10px 0 14px;height:20px;width:20px}\n", "",{"version":3,"sources":["webpack://./src/components/Shared/Info/Notification/style.scss"],"names":[],"mappings":"AAAA,4BACE,0BAAoB,CAApB,0BAAoB,CAApB,mBAAoB,CACpB,UAAW,CACX,kBAAmB,CACnB,kBAAmB,CACnB,iBAAkB,CAClB,iBAAkB,CANpB,mDASI,UAAW,CACX,gBAAiB,CACjB,wBAAyB,CAC1B,uBAID,cAAe,CAChB,oBAGC,uBAAwB,CACxB,WAAY,CACZ,UAAW","sourcesContent":[".notification {\r\n  display: inline-flex;\r\n  width: 100%;\r\n  margin-bottom: 16px;\r\n  background: #d8e9ff;\r\n  border-radius: 4px;\r\n  position: relative;\r\n\r\n  .content {\r\n    width: 100%;\r\n    line-height: 22px;\r\n    padding: 10px 10px 10px 0;\r\n  }\r\n}\r\n\r\n.pointer {\r\n  cursor: pointer;\r\n}\r\n\r\n.icon {\r\n  margin: 10px 10px 0 14px;\r\n  height: 20px;\r\n  width: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "style__notification--Tk2z1",
	"content": "style__content--XDp8Y",
	"pointer": "style__pointer--SQqcz",
	"icon": "style__icon--rX1xm"
};
export default ___CSS_LOADER_EXPORT___;
