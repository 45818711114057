import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SelectBrand from "./components/SelectBrand";
import s from "./style.scss";
import PersonInfo from "./components/PersonInfo";
import AlmostDonePage from "./components/AlmostDonePage";
import AdditionalSteps from "../AdditionalSteps";
import config from "../../../config.json";
const AddMember = ({ caregiver }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    useEffect(() => {
        !config.addMemberFlow && navigate("");
    }, []);
    const countOfSteps = caregiver ? 2 : 3;
    const prevStep = () => step > 1 && setStep((i) => --i);
    const nextStep = () => countOfSteps + 1 > step && setStep((i) => ++i);
    const renderStep = () => {
        switch (step) {
            case 1:
                return (React.createElement(SelectBrand, { onSubmit: caregiver ? () => setStep((step) => step + 2) : nextStep, 
                    // renderLoadingPopUp={renderLoadingPopUp}
                    backAction: caregiver ? caregiver : undefined }));
            case 2:
                return React.createElement(PersonInfo, { onSubmit: nextStep, backAction: prevStep });
            case 3:
                return (React.createElement(AlmostDonePage, { onSubmit: nextStep, 
                    // renderLoadingPopUp={renderLoadingPopUp}
                    backAction: caregiver ? () => setStep((step) => step - 2) : prevStep, caregiver: caregiver && true }));
            default:
                return;
        }
    };
    const renderStepHeader = () => (React.createElement("div", { className: s.header },
        React.createElement("div", { className: s.pages }, `${caregiver && step > 1 ? 2 : step}/${countOfSteps}`)));
    return (React.createElement(React.Fragment, null, step === 4 ? (React.createElement(AdditionalSteps, { haveSideBar: true })) : (React.createElement("div", { className: s.addMemberWrapper },
        renderStepHeader(),
        renderStep()))));
};
export default AddMember;
