import React, { useState } from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import InfoPopUp from "../../../Info/InfoPopUp";
import Close from "../../../../../resources/icons/close.svg";
import Edit from "../../../../../resources/icons/edit";
import Save from "../../../../../resources/icons/done";
import Info from '../../../../../resources/icons/info';
import colors from "../../../../../resources/colors";
import s from "./style.scss";
import Typography from "../../../Typography";
const InputTextMultiLine = ({ style, styleGroup, name, value, setValue, placeholder, description, descriptionJSX, pattern, errorMessage, onChangeText, onValidation, error, setError, label, length, maxLength, isNumber, clearCross = true, children, withLink = false, onLinkClick, disabled, save, onSave, autoComplete, onCancel, tabIndex, notEveryPattern, required = true, columns, height, validationMessage, isSubmitted }) => {
    const [infoIsOpen, setDisplayInfo] = useState(false);
    const handleValidation = (value) => {
        if (!pattern)
            return true;
        // string pattern, one validation rule
        if (typeof pattern === "string") {
            const condition = new RegExp(pattern, "g");
            return condition.test(value);
        }
        // array patterns, multiple validation rules
        if (typeof pattern === "object") {
            const conditions = pattern.map((rule) => new RegExp(rule, "g"));
            return !notEveryPattern ? conditions
                .map((condition) => condition.test(value))
                .every((e) => e) :
                conditions
                    .find((condition) => condition.test(value));
        }
    };
    const onChange = (value) => {
        if (isNumber)
            value = value.replace(/[^0-9]/g, "");
        if (length && value.length > length)
            value = value.slice(0, length);
        setValue(value);
        const isValid = handleValidation(value);
        if (setError)
            value && !isValid ? setError(errorMessage) : setError("");
        onValidation && onValidation(isValid);
    };
    const clear = () => {
        setValue("");
        setError && setError("");
    };
    return (React.createElement("div", null,
        label && React.createElement(Typography, { type: "label", color: "neutralGray", bold: true }, label),
        React.createElement("div", { "data-testid": "component-test", className: classNames(styleGroup, s.inputGroup), style: {
                "--icon-hover-color": colors.controlsGeneralColor,
                "--input-focus-border-color": colors.inputFocusColorBorder,
                "--input-focus-shadow-color": colors.inputFocusShadowBorder,
            } },
            React.createElement("textarea", { tabIndex: tabIndex, "data-testid": "input-test", style: {
                    backgroundColor: colors.primaryshades3,
                    columns: columns,
                    width: '100%',
                    height: height
                }, className: classNames(s.input, style, {
                    [s.errorInput]: error,
                    [s.withPlaceholder]: placeholder,
                }), name: name, value: value, 
                // required={required}
                disabled: disabled, autoComplete: autoComplete || 'off', maxLength: maxLength, onChange: (e) => {
                    onChange(e.target.value);
                    onChangeText && onChangeText(e);
                } }),
            placeholder && React.createElement("div", { "data-testid": "placeholder-test", className: classNames(s.floatingLabel) }, placeholder),
            clearCross && React.createElement(SVG, { "data-testid": "clear-button", src: Close, className: s.close, onClick: clear }),
            withLink && React.createElement("a", { className: s.link, onClick: onLinkClick },
                React.createElement(Edit, { color: colors.controlsGeneralColor })),
            (description || descriptionJSX) && React.createElement(Info, { "data-testid": "info-test", color: colors.icons.primary, style: classNames(s.link, s.info), onClick: () => setDisplayInfo(true) }),
            isSubmitted && required && value == "" && React.createElement("span", { "data-testid": "error-test", className: s.error }, validationMessage),
            save && (React.createElement(React.Fragment, null,
                !error && React.createElement("a", { className: classNames(s.link, s.save), onClick: () => !error && value && onSave() },
                    React.createElement(Save, { color: colors.controlsGeneralColor })),
                React.createElement(SVG, { src: Close, className: classNames(s.link, s.closeIcon), onClick: onCancel }))),
            React.createElement(InfoPopUp, { isOpen: infoIsOpen, togglePopUp: () => setDisplayInfo(false) }, descriptionJSX && descriptionJSX || React.createElement("div", null, description)),
            children)));
};
export default InputTextMultiLine;
