import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "PATIENT_FILES";
export const filesAdapter = createEntityAdapter({
    selectId: (file) => file.id
});
const initialState = {
    requestStatus: "idle",
    requestError: null,
    requestScheduleCallStatus: "idle",
    requestScheduleCallError: null,
    requestGetFilesStatus: "idle",
    requestGetFilesError: null,
    requestDeleteFilesStatus: "idle",
    requestDeleteFilesError: null,
    requestUpdateFilesStatus: "idle",
    requestUpdateFilesError: null,
    requestGetTimesStatus: "idle",
    requestGetTimesError: null,
    scheduleCallTimes: {
        currentDay: "",
        nextDay: "",
        currentDayTimes: [],
        nextDayTimes: []
    },
    financialAssistanceConfig: {
        offerAssistance: false,
        askHouse: false,
        askDependents: false,
        electronic: false,
        phoneNumber: ""
    },
    financialAssistanceInfo: {
        effectiveDate: "",
        carrier: "",
        group: "",
        patientCode: "",
        certificate: "",
        patientName: "",
        programName: "",
        programPhoneNumber: "",
        relationship: {
            key: "",
            displayName: ""
        },
        status: {
            key: "",
            displayName: undefined
        }
    },
    previewLink: "",
    requestGetFinancialConfigStatus: "idle",
    requestGetFinancialConfigError: null,
    requestGetFinancialAssistanceStatus: "idle",
    requestGetFinancialAssistanceError: null,
    requestSendFinancialAssistanceStatus: "idle",
    requestSendFinancialAssistanceError: null,
    requestGetPreviewPhotoStatus: "idle",
    requestGetPreviewPhotoError: null,
    requestInsurancesUpdateStatus: "idle",
    requestInsurancesUpdateError: null,
    requestHealthCardUpdateStatus: "idle",
    requestHealthCardUpdateError: null,
    requestGetInsurancesFilesStatus: "idle",
    requestGetInsurancesFilesError: null,
    requestGetHealthCardFilesStatus: "idle",
    requestGetHealthCardFilesError: null,
};
const patientFilesSlice = createSlice({
    name: SLICE_NAME,
    initialState: filesAdapter.getInitialState(initialState),
    reducers: {
        startRequestAddFilesAction: (state) => {
            state.requestStatus = "pending";
            state.requestError = null;
        },
        successRequestAddFilesAction: (state) => {
            state.requestStatus = "success";
            state.requestError = null;
        },
        showRequestErrorAction: (state, action) => {
            state.requestStatus = "failure";
            state.requestError = action.payload;
        },
        resetRequestAddFilesAction: (state) => {
            state.requestStatus = "idle";
            state.requestError = null;
        },
        startRequestScheduleCallAction: (state) => {
            state.requestScheduleCallStatus = "pending";
            state.requestScheduleCallError = null;
        },
        successRequestScheduleCallAction: (state) => {
            state.requestScheduleCallStatus = "success";
            state.requestScheduleCallError = null;
        },
        showRequestScheduleCallErrorAction: (state, action) => {
            state.requestScheduleCallStatus = "failure";
            state.requestScheduleCallError = action.payload;
        },
        resetRequestScheduleCall: (state) => {
            state.requestScheduleCallStatus = "idle";
            state.requestScheduleCallError = null;
        },
        resetAdditionalStepsSliceAction: (state) => {
            state = filesAdapter.getInitialState(initialState);
        },
        startRequestGetFilesAction: (state) => {
            state.requestGetFilesStatus = "pending";
            state.requestGetFilesError = null;
        },
        successRequestGetFilesAction: (state, action) => {
            filesAdapter.setAll(state, action.payload);
            state.requestGetFilesStatus = "success";
            state.requestGetFilesError = null;
        },
        showRequestGetFilesErrorAction: (state, action) => {
            state.requestGetFilesStatus = "failure";
            state.requestGetFilesError = action.payload;
        },
        resetRequestGetFilesAction: (state) => {
            state.requestGetFilesStatus = "idle";
            state.requestGetFilesError = null;
        },
        upsertPatientsFilesAction: (state, action) => {
            filesAdapter.upsertMany(state, action.payload);
        },
        startRequestGetInsurancesFilesAction: (state) => {
            state.requestGetInsurancesFilesStatus = "pending";
            state.requestGetInsurancesFilesError = null;
        },
        successRequestGetInsurancesFilesAction: (state, action) => {
            filesAdapter.upsertMany(state, action.payload);
            state.requestGetInsurancesFilesStatus = "success";
            state.requestGetInsurancesFilesError = null;
        },
        showRequestGetInsurancesFilesErrorAction: (state, action) => {
            state.requestGetInsurancesFilesStatus = "failure";
            state.requestGetInsurancesFilesError = action.payload;
        },
        resetRequestGetInsurancesFilesAction: (state) => {
            state.requestGetInsurancesFilesStatus = "idle";
            state.requestGetInsurancesFilesError = null;
        },
        startRequestGetHealthCardFilesAction: (state) => {
            state.requestGetHealthCardFilesStatus = "pending";
            state.requestGetHealthCardFilesError = null;
        },
        successRequestGetHealthCardFilesAction: (state) => {
            state.requestGetHealthCardFilesStatus = "success";
            state.requestGetHealthCardFilesError = null;
        },
        showRequestGetHealthCardFilesErrorAction: (state, action) => {
            state.requestGetHealthCardFilesStatus = "failure";
            state.requestGetHealthCardFilesError = action.payload;
        },
        resetRequestGetHealthCardFilesAction: (state) => {
            state.requestGetHealthCardFilesStatus = "idle";
            state.requestGetHealthCardFilesError = null;
        },
        startRequestDeleteFilesAction: (state) => {
            state.requestDeleteFilesStatus = "pending";
            state.requestDeleteFilesError = null;
        },
        successRequestDeleteFilesAction: (state, action) => {
            filesAdapter.removeMany(state, action.payload);
            state.requestDeleteFilesStatus = "success";
            state.requestDeleteFilesError = null;
        },
        showRequestDeleteFilesErrorAction: (state, action) => {
            state.requestDeleteFilesStatus = "failure";
            state.requestDeleteFilesError = action.payload;
        },
        resetRequestDeleteFilesAction: (state) => {
            state.requestDeleteFilesStatus = "idle";
            state.requestDeleteFilesError = null;
        },
        startRequestUpdateFilesAction: (state) => {
            state.requestUpdateFilesStatus = "pending";
            state.requestUpdateFilesError = null;
        },
        successRequestUpdateFilesAction: (state) => {
            state.requestUpdateFilesStatus = "success";
            state.requestUpdateFilesError = null;
        },
        showRequestUpdateFilesErrorAction: (state, action) => {
            state.requestUpdateFilesStatus = "failure";
            state.requestUpdateFilesError = action.payload;
        },
        resetRequestUpdateFilesAction: (state) => {
            state.requestUpdateFilesStatus = "idle";
            state.requestUpdateFilesError = null;
            // clear upload too
            state.requestStatus = "idle";
            state.requestError = null;
        },
        startRequestGetTimesAction: (state) => {
            state.requestGetTimesStatus = "pending";
            state.requestGetTimesError = null;
        },
        successRequestGetTimesAction: (state, action) => {
            state.requestGetTimesStatus = "success";
            state.requestGetTimesError = null;
            state.scheduleCallTimes = action.payload;
        },
        showRequestGetTimesErrorAction: (state, action) => {
            state.requestGetTimesStatus = "failure";
            state.requestGetTimesError = action.payload;
        },
        resetRequestGetTimesAction: (state) => {
            state.requestGetTimesStatus = "idle";
            state.requestGetTimesError = null;
        },
        startRequestGetFinancialConfigAction: (state) => {
            state.requestGetFinancialConfigStatus = "pending";
            state.requestGetFinancialConfigError = null;
        },
        successRequestGetFinancialConfigAction: (state, action) => {
            state.requestGetFinancialConfigStatus = "success";
            state.requestGetFinancialConfigError = null;
            state.financialAssistanceConfig = action.payload;
        },
        showRequestGetFinancialConfigErrorAction: (state, action) => {
            state.requestGetFinancialConfigStatus = "failure";
            state.requestGetFinancialConfigError = action.payload;
        },
        resetRequestGetFinancialConfigAction: (state) => {
            state.requestGetFinancialConfigStatus = "idle";
            state.requestGetFinancialConfigError = null;
        },
        startRequestGetFinancialAssistanceAction: (state) => {
            state.requestGetFinancialAssistanceStatus = "pending";
            state.requestGetFinancialAssistanceError = null;
        },
        successRequestGetFinancialAssistanceAction: (state, action) => {
            state.requestGetFinancialAssistanceStatus = "success";
            state.requestGetFinancialAssistanceError = null;
            if (action.payload) {
                state.financialAssistanceInfo = action.payload;
            }
            else {
                state.financialAssistanceInfo = initialState.financialAssistanceInfo;
            }
        },
        showRequestGetFinancialAssistanceErrorAction: (state, action) => {
            state.requestGetFinancialAssistanceStatus = "failure";
            state.requestGetFinancialAssistanceError = action.payload;
            state.financialAssistanceInfo = initialState.financialAssistanceInfo;
        },
        resetRequestGetFinancialAssistanceAction: (state) => {
            state.requestGetFinancialAssistanceStatus = "idle";
            state.requestGetFinancialAssistanceError = null;
        },
        startRequestSendFinancialAssistanceAction: (state) => {
            state.requestSendFinancialAssistanceStatus = "pending";
            state.requestSendFinancialAssistanceError = null;
        },
        successRequestSendFinancialAssistanceAction: (state) => {
            state.requestSendFinancialAssistanceStatus = "success";
            state.requestSendFinancialAssistanceError = null;
        },
        showRequestSendFinancialAssistanceErrorAction: (state, action) => {
            state.requestSendFinancialAssistanceStatus = "failure";
            state.requestSendFinancialAssistanceError = action.payload;
        },
        resetRequestSendFinancialAssistanceAction: (state) => {
            state.requestSendFinancialAssistanceStatus = "idle";
            state.requestSendFinancialAssistanceError = null;
        },
        clearFilesAction: (state) => {
            filesAdapter.removeAll(state);
            state.financialAssistanceInfo = initialState.financialAssistanceInfo;
        },
        startRequestGetPreviewAction: (state) => {
            state.requestGetPreviewPhotoStatus = "pending";
            state.requestGetPreviewPhotoError = null;
        },
        successRequestGetPreviewAction: (state, action) => {
            state.requestGetPreviewPhotoStatus = "success";
            state.requestGetPreviewPhotoError = null;
            state.previewLink = action.payload;
        },
        showRequestGetPreviewErrorAction: (state, action) => {
            state.requestGetPreviewPhotoStatus = "failure";
            state.requestGetPreviewPhotoError = action.payload;
        },
        resetRequestGetPreviewAction: (state) => {
            state.requestGetPreviewPhotoStatus = "idle";
            state.requestGetPreviewPhotoError = null;
            state.previewLink = "";
        },
        updatePatientFiles: (state, action) => {
            filesAdapter.removeAll(state);
            filesAdapter.removeMany(state, action.payload.deleted.map(d => d.id));
            filesAdapter.upsertMany(state, action.payload.files);
        },
        startRequestInsurancesUploadAction: (state) => {
            state.requestInsurancesUpdateStatus = "pending";
            state.requestInsurancesUpdateError = null;
        },
        successRequestInsurancesUploadAction: (state) => {
            state.requestInsurancesUpdateStatus = "success";
            state.requestInsurancesUpdateError = null;
        },
        showRequestInsurancesUploadErrorAction: (state, action) => {
            state.requestInsurancesUpdateStatus = "failure";
            state.requestInsurancesUpdateError = action.payload;
        },
        hideRequestInsurancesUploadErrorAction: (state) => {
            state.requestInsurancesUpdateStatus = "idle";
            state.requestInsurancesUpdateError = null;
        },
        startRequestHealthCardUploadAction: (state) => {
            state.requestHealthCardUpdateStatus = "pending";
            state.requestHealthCardUpdateError = null;
        },
        successRequestHealthCardUploadAction: (state) => {
            state.requestHealthCardUpdateStatus = "success";
            state.requestHealthCardUpdateError = null;
        },
        showRequestHealthCardUploadErrorAction: (state, action) => {
            state.requestHealthCardUpdateStatus = "failure";
            state.requestHealthCardUpdateError = action.payload;
        },
        hideRequestHealthCardUploadErrorAction: (state) => {
            state.requestHealthCardUpdateStatus = "idle";
            state.requestHealthCardUpdateError = null;
        }
    }
});
export default patientFilesSlice;
