import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ConditionsSlice, { SLICE_NAME } from "./Slice";
import { handleApiError } from "../utils";
import { MedicalConditionsRepository } from "../repository";
import { mapConditions } from "./Mapper";
//get one brand code data
export const getMedicalConditionsAsyncAction = createAction(`${SLICE_NAME}/medicalConditionsAsyncAction`);
function* getMedicalConditionsSaga(action) {
    const { startRequestAction, showErrorAction, successRequestAction } = ConditionsSlice.actions;
    try {
        yield put(startRequestAction());
        const result = yield call(MedicalConditionsRepository.getConditions, action.payload);
        const conditions = yield mapConditions(result.data);
        yield put(successRequestAction(conditions));
    }
    catch (error) {
        yield handleApiError(error, showErrorAction);
    }
}
//get all brand code data
export const getAllmedicalConditionInfoAsyncAction = createAction(`${SLICE_NAME}/getAllmedicalConditionInfoAsyncAction`);
export function* getAllmedicalConditionInfoSaga(action) {
    const { startRequestgetAllmedicalConditionAction, successRequestgetAllmedicalConditionAction, showRequestgetAllmedicalConditionErrorAction, } = ConditionsSlice.actions;
    try {
        yield put(startRequestgetAllmedicalConditionAction());
        let result = yield call(MedicalConditionsRepository.getAllmedicalConditionInfo, action.payload.page, action.payload.rowPerPage, action.payload.order, action.payload.orderBy, action.payload.DrugBrandCode, action.payload.Description);
        yield put(successRequestgetAllmedicalConditionAction(result.data.value));
    }
    catch (error) {
        yield handleApiError(error, showRequestgetAllmedicalConditionErrorAction);
    }
}
//create
export const createMedicalConditionsInfoAsyncAction = createAction(`${SLICE_NAME}/createMedicalConditionsInfoAsyncAction`);
export function* createMedicalConditionsInfoSaga(action) {
    const { startCreateMedicalConditionsInfoAsyncAction, succesCreateMedicalConditionsInfoAsyncAction, showcreateMedicalConditionsInfoErrorAction } = ConditionsSlice.actions;
    try {
        yield put(startCreateMedicalConditionsInfoAsyncAction());
        yield call(MedicalConditionsRepository.postMedicalCondition, action.payload);
        yield put(succesCreateMedicalConditionsInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateMedicalConditionsInfoErrorAction);
    }
}
//update
export const updateMedicalConditionInfoAsyncAction = createAction(`${SLICE_NAME}/updateMedicalConditionInfoAsyncAction`);
export function* updateMedicalConditionInfoSaga(action) {
    const { startRequestUpdateMedicalConditionInfoAction, successRequestUpdateMedicalConditionInfoAction, showRequestUpdateMedicalConditionInfoErrorAction, } = ConditionsSlice.actions;
    try {
        yield put(startRequestUpdateMedicalConditionInfoAction());
        yield call(MedicalConditionsRepository.updateMedicalConditionInfo, action.payload);
        yield put(successRequestUpdateMedicalConditionInfoAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdateMedicalConditionInfoErrorAction);
    }
}
//delete
export const deleteMedicalConditionAsyncAction = createAction(`${SLICE_NAME}/deletedeleteMedicalConditionAsyncAction`);
export function* deleteMedicalConditionSaga(action) {
    const { startRequestDeleteMedicalConditionAction, deleteMedicalConditionAction, showRequestDeleteMedicalConditionErrorAction, } = ConditionsSlice.actions;
    try {
        yield put(startRequestDeleteMedicalConditionAction());
        yield call(MedicalConditionsRepository.deleteMedicalCondition, action.payload.medicalConditionId);
        yield put(deleteMedicalConditionAction(action.payload.medicalConditionId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeleteMedicalConditionErrorAction);
    }
}
//get one data 
export const getmedicalConditionOneDataInfoAsyncAction = createAction(`${SLICE_NAME}/getmedicalConditionOneDataInfoAsyncAction`);
export function* getmedicalConditionOneDataInfoSaga(action) {
    const { startRequestmedicalConditionOneDataInfoAction, successRequesmedicalConditionOneDataInfoAction, showRequesmedicalConditionOneDataInfoErrorAction, } = ConditionsSlice.actions;
    try {
        yield put(startRequestmedicalConditionOneDataInfoAction());
        let result = yield call(MedicalConditionsRepository.getmedicalConditionInfo, action.payload);
        yield put(successRequesmedicalConditionOneDataInfoAction(result.data));
    }
    catch (error) {
        yield handleApiError(error, showRequesmedicalConditionOneDataInfoErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getMedicalConditionsAsyncAction, getMedicalConditionsSaga);
    yield takeLatest(createMedicalConditionsInfoAsyncAction, createMedicalConditionsInfoSaga);
    yield takeLatest(deleteMedicalConditionAsyncAction, deleteMedicalConditionSaga);
    yield takeLatest(updateMedicalConditionInfoAsyncAction, updateMedicalConditionInfoSaga);
    yield takeLatest(getmedicalConditionOneDataInfoAsyncAction, getmedicalConditionOneDataInfoSaga);
    yield takeLatest(getAllmedicalConditionInfoAsyncAction, getAllmedicalConditionInfoSaga);
}
