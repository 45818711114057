import ApiManager from "../api/ApiManager";
const version = "1.2";
const SIGN_IN = `/v${version}/auth/sign-in`;
const SIGN_OUT = `/v${version}/auth/sign-out`;
const REFRESH_TOKEN = `/v${version}/auth/refresh-token`;
const GET_STARTED = `/v${version}/registration/verify-phone`;
const REQUEST_VERIFICATION_CODE = `/v${version}/registration/request-verification-code`;
const VALIDATE_VERIFICATION_CODE = `/v${version}/registration/validate-verification-code`;
const SUMMARY = `/v${version}/registration/register-new-user`;
const CHANGE_PHONE_REQUEST = `/v${version}/auth/change-phone-request`;
const CHANGE_PHONE = `/v${version}/auth/change-phone`;
const CHANGE_PASSWORD_REQUEST = `/v${version}/auth/change-password-request`;
const CHANGE_PASSWORD = `/v${version}/auth/change-password`;
const PATIENT_INFO = `/v${version}/patient/`;
const VERIFY_EMAIL = `/v${version}/registration/verify-email`;
const SUBSCRIBE_DEVICE = `/v${version}/account/subscribe-device`;
const COMPLETE_REGISTRATION = `/v${version}/account`;
class AuthRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new AuthRepository();
        }
        return this.instance;
    }
    static signIn = (identifier, password, signInType, twoFactorCode) => {
        return ApiManager.getInstance().post(SIGN_IN, {
            password,
            identifier,
            signInType,
            twoFactorCode
        }, true);
    };
    static signOut = (deviceRegistrationToken) => {
        return ApiManager.getInstance().post(SIGN_OUT, {
            deviceRegistrationToken
        }, true);
    };
    static refreshToken = () => {
        return ApiManager.getInstance().post(REFRESH_TOKEN, null, true);
    };
    static isRegisteredUser = (phoneNumber) => {
        return ApiManager.getInstance().get(`${GET_STARTED}?PhoneNumber=${phoneNumber}`);
    };
    static sendVerificationCode = (phoneNumber, applicationType) => {
        return ApiManager.getInstance().get(REQUEST_VERIFICATION_CODE, {
            phoneNumber,
            applicationType
        });
    };
    static validateVerificationCode = (code, phoneNumber) => {
        return ApiManager.getInstance().post(VALIDATE_VERIFICATION_CODE, {
            code,
            phoneNumber
        });
    };
    static signUp = (payload) => {
        return ApiManager.getInstance().post(SUMMARY, payload, true);
    };
    static signUpForExistingAzureUser = (payload) => {
        return ApiManager.getInstance().post(COMPLETE_REGISTRATION, payload, true);
    };
    static changePhoneRequest = (payload) => {
        return ApiManager.getInstance().post(CHANGE_PHONE_REQUEST, payload);
    };
    static changePhoneNumber = (payload) => {
        return ApiManager.getInstance().post(CHANGE_PHONE, payload);
    };
    static validatePassword = (password) => {
        return ApiManager.getInstance().post(CHANGE_PASSWORD_REQUEST, {
            password
        });
    };
    static changePassword = (payload) => {
        return ApiManager.getInstance().post(CHANGE_PASSWORD, payload);
    };
    static addMember = (payload) => {
        return ApiManager.getInstance().post(PATIENT_INFO, payload);
    };
    static verifyEmail = (email) => {
        return ApiManager.getInstance().get(`${VERIFY_EMAIL}?Email=${email}`);
    };
    static subscribeDevice = (deviceRegistrationToken) => {
        return ApiManager.getInstance().post(SUBSCRIBE_DEVICE, {
            deviceRegistrationToken
        });
    };
}
export default AuthRepository;
