import React, { useEffect, useState } from "react";
import { PopUp } from "../../components/Shared/Info";
import { Button, InputText } from "../../components/Shared/Controls";
import SVG from "react-inlinesvg";
import Close from "../../resources/icons/close.svg";
import { connect } from "react-redux";
import InputDate from "../../components/Shared/Controls/Inputs/InputDate";
import moment from "moment";
import golbalcss from "../styles.scss";
import classNames from 'classnames';
import { useTranslation } from "react-i18next";
const PatientFilter = ({ onClose, setFields, holdDataFilter }) => {
    const [DateOfBirth, setDateOfBirth] = useState("");
    const minDate = moment().subtract("120", "years").format('YYYY-MM-DD');
    const maxDate = moment().format("YYYY-MM-DD");
    const { t } = useTranslation();
    const [Province, setProvince] = useState("");
    const [Phone, setPhone] = useState("");
    const [Email, setEmail] = useState("");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    useEffect(() => {
        setDateOfBirth(holdDataFilter?.DateOfBirth || "");
        setProvince(holdDataFilter?.Province || "");
        setPhone(holdDataFilter?.Phone || "");
        setEmail(holdDataFilter?.Email || "");
        setFirstName(holdDataFilter?.FirstName || "");
        setLastName(holdDataFilter?.LastName || "");
    }, [onClose]);
    const FilterFunction = () => {
        const patientFilterfieldsObj = {
            FirstName: FirstName,
            LastName: LastName,
            DateOfBirth: DateOfBirth,
            Province: Province,
            Phone: Phone,
            Email: Email,
        };
        setFields?.(patientFilterfieldsObj);
        onClose();
    };
    return (React.createElement(PopUp, { isOpen: true },
        React.createElement("div", { className: classNames(golbalcss.popUmodalContent, golbalcss.popUpmodalSm) },
            React.createElement("div", { className: classNames(golbalcss.popUpmodalheader) },
                React.createElement("h4", null, t("filter")),
                React.createElement(SVG, { src: Close, onClick: onClose })),
            React.createElement("div", { className: golbalcss.modalBody },
                React.createElement(InputText, { key: "firstName", name: "FirstName", label: t("firstName"), value: FirstName, setValue: setFirstName, maxLength: 100 }),
                React.createElement(InputText, { key: "lastName", name: "LastName", label: t("lastName"), value: LastName, setValue: setLastName, maxLength: 100 }),
                React.createElement(InputDate, { name: "DateOfBirth", label: t("dateofBirth"), value: DateOfBirth, setValue: setDateOfBirth, minDate: minDate, maxDate: maxDate }),
                React.createElement(InputText, { name: "Province", label: t("province"), value: Province, setValue: setProvince, required: false }),
                React.createElement(InputText, { name: "Phone", label: t("phone"), value: Phone, isNumber: true, setValue: setPhone, required: false }),
                React.createElement(InputText, { name: "Email", label: t("email"), value: Email, setValue: setEmail, required: false })),
            React.createElement("div", { className: golbalcss.modalFooter },
                React.createElement(Button, { text: t("cancel"), onClick: onClose }),
                React.createElement(Button, { text: t("filter"), onClick: FilterFunction, primary: true })))));
};
const mapStateToProps = (state) => {
    return {};
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientFilter);
