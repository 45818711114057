import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { Button, InputPassword } from "../../../components/Shared/Controls";
import { useInput } from "../../../hooks";
import { Notification, PopUp } from "../../../components/Shared/Info";
import s from "../style.scss";
import si from "../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import SuccessSignIcon from "../../../resources/images/success_sign_icon.png";
import { useNavigate } from "react-router";
import { resetPasswordAsyncAction, resetRequestResetPassStatusAction, } from "../../../store";
import { connect } from "react-redux";
import ErrorPopUp from "../../../components/Shared/Info/ErrorPopUp";
import useErrorPopUp from "../../../hooks/useErrorPopUp";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { createInviteChangePasswordInfoAsyncAction } from "../../../store/Invite/Sagas";
import { resetChangePasswordAction } from "../../../store/Invite";
import LoadingPopUp from "../../../components/Shared/LoadingPopUp";
import { Checkbox } from "../../../components";
import Typography from "../../../components/Shared/Typography";
import ReactHtmlParser from "react-html-parser";
import Close from "../../../resources/icons/close.svg";
const Step3 = ({ header, resetPassword, requestResetPassError, requestResetPassStatus, resetRequestResetPassStatus, previousStep, isNewSignUp, setStep, setInfo, InviteChangePasswordAction, requestInviteChangePasswordPostStatus, resetChangePasswordAction }) => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword, errorNewPassword, setErrorNewPassword] = useInput();
    const [confirmPassword, setConfirmPassword, errorConfirmPassword, setErrorConfirmPassword,] = useInput();
    const [sent, setSent] = useState(false);
    const [isOpen, showPopUp, errorContent, setErrorContent, togglePopUp] = useErrorPopUp();
    const [agree, setAgree] = useInput(false);
    const [consentAgree, setConsentAgree] = useInput(false);
    const [termsOfUseOpen, setTermsOfUseOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);
    const [consentOpen, setConsentOpen] = useState(false);
    const navigate = useNavigate();
    const onResetPassword = () => {
        if (isNewSignUp) {
            if (newPassword !== confirmPassword) {
                setErrorContent(t("passwordsDoNotMatch"));
                showPopUp(true);
                return;
            }
            else {
                const data = {
                    accountId: setInfo.accountId,
                    inviteCode: setInfo.inviteCode,
                    lastName: setInfo.lastName,
                    birthdate: moment(setInfo.birthdate).format('YYYY-MM-DD'),
                    password: newPassword
                };
                InviteChangePasswordAction(data);
            }
        }
        else {
            resetRequestResetPassStatus();
            if (newPassword !== confirmPassword) {
                setErrorContent(t("passwordsDoNotMatch"));
                showPopUp(true);
                return;
            }
            resetPassword(newPassword, confirmPassword);
        }
    };
    useEffect(() => {
        if (requestResetPassStatus === "success") {
            resetRequestResetPassStatus();
            setSent(true);
        }
    }, [requestResetPassStatus]);
    const RedirectFunction = () => {
        if (isNewSignUp) {
            setStep(5);
        }
        else {
            navigate("/");
        }
    };
    useEffect(() => {
        if (requestInviteChangePasswordPostStatus === "success") {
            resetChangePasswordAction();
            setSent(true);
        }
    }, [requestInviteChangePasswordPostStatus]);
    const renderTermsOfUseModal = (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: termsOfUseOpen },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2 }, t("termOfUse"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", null, ReactHtmlParser(t('termOfUserIntent')))),
        React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: () => setTermsOfUseOpen(false) })));
    const renderPrivacyModal = (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: privacyOpen },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2 }, t("privacyPolicy"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", null, ReactHtmlParser(t('privacyPolicyContent')))),
        React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: () => setPrivacyOpen(false) })));
    const renderConsentModal = (React.createElement(PopUp, { style: s.terms, contentStyle: s.termsContent, isOpen: consentOpen },
        React.createElement("div", { className: s.terms__header },
            React.createElement("div", { className: s.headline_2, style: { textAlign: "center" } }, t("userConsent"))),
        React.createElement("div", { className: classNames(s.terms__content, s.scrollWrapper) },
            React.createElement("div", { className: s.intend }, ReactHtmlParser(t('userConsentIntent'))),
            React.createElement(SVG, { src: Close, className: s.closeIcon, onClick: () => setConsentOpen(false) }),
            React.createElement("div", { className: s.buttonBox },
                React.createElement(Button, { style: s.button, text: t("decline"), onClick: () => {
                        setConsentOpen(false);
                        setConsentAgree(false);
                    } }),
                React.createElement(Button, { style: s.button, primary: true, text: t("accept"), onClick: () => {
                        setConsentOpen(false);
                        setConsentAgree(true);
                    } })))));
    return (React.createElement(React.Fragment, null,
        renderTermsOfUseModal,
        renderPrivacyModal,
        renderConsentModal,
        React.createElement(ErrorPopUp, { isOpen: isOpen, popUpContent: errorContent, togglePopUp: togglePopUp }),
        sent && (React.createElement(PopUp, { isOpen: sent },
            React.createElement("div", { className: s.popup },
                React.createElement("div", null,
                    React.createElement("img", { src: SuccessSignIcon, className: classNames(s.popup__header_icon, s.mlauto) })),
                React.createElement("div", { className: s.popup__content },
                    React.createElement("div", { className: s.popup_contenttext },
                        React.createElement("h4", null, t("successtext")),
                        React.createElement("p", null, t("Updatedtext")))),
                React.createElement("div", { className: s.popup__controls },
                    React.createElement(Button, { text: t("signIn"), onClick: () => RedirectFunction() }))))),
        React.createElement("div", { className: classNames(isNewSignUp ? si.contentWrapperBelowHeader : null) },
            React.createElement("div", { className: classNames(isNewSignUp ? si.scrollWrapperfrom : null) },
                React.createElement("div", { className: classNames(isNewSignUp ? si.formstep : s.form, isNewSignUp ? si.formWrapper : si.formWrapper) },
                    React.createElement("div", { className: classNames(isNewSignUp ? si.formstepbar : s.contentWrapper, isNewSignUp ? null : s.scrollWrapper) },
                        React.createElement("div", { className: (si.stepToptext) },
                            header,
                            isNewSignUp ? React.createElement("h4", null, t("inviteHeader")) : React.createElement("h1", null, t("resetPassword")),
                            React.createElement("div", { className: s.text },
                                React.createElement("p", null, t("passwordShouldBe")))),
                        React.createElement("div", { className: s.contentInput },
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, t("createPassword")),
                                React.createElement(InputPassword, { name: "newPassword", pattern: {
                                        rule: "[A-Za-z0-9!@#$%^&*()_+=-]{8,}",
                                        listOnConditions: [
                                            "(?=.*[a-z])[a-z]",
                                            "(?=.*[A-Z])[A-Z]",
                                            "(?=.*[0-9])[0-9]",
                                            "(?=.*[!@#$%^&*()_+=-])[!@#$%^&*()_+=-]",
                                        ],
                                        countOfConditions: 3,
                                    }, setError: setErrorNewPassword, errorMessage: t("requirementsToPassword"), password: newPassword, setPassword: setNewPassword, error: newPassword && errorNewPassword, onFocus: true, tabIndex: 1 })),
                            React.createElement("div", { className: classNames(si.formGroup) },
                                React.createElement("label", null, t("confirmPassword")),
                                React.createElement(InputPassword, { name: "confirmPassword", pattern: {
                                        rule: "[A-Za-z0-9!@#$%^&*()_+=-]{8,}",
                                        listOnConditions: [
                                            "(?=.*[a-z])[a-z]",
                                            "(?=.*[A-Z])[A-Z]",
                                            "(?=.*[0-9])[0-9]",
                                            "(?=.*[!@#$%^&*()_+=-])[!@#$%^&*()_+=-]",
                                        ],
                                        countOfConditions: 3,
                                    }, setError: setErrorConfirmPassword, errorMessage: t("confirmPasswordError"), password: confirmPassword, setPassword: setConfirmPassword, error: confirmPassword && errorConfirmPassword, tabIndex: 2 })),
                            React.createElement("div", { className: s.notification },
                                React.createElement(Notification, null,
                                    React.createElement("div", null, t("requirementsToPassword")))),
                            isNewSignUp &&
                                React.createElement("div", { className: classNames(s.checkboxlist), onClick: () => setConsentOpen(true) },
                                    React.createElement(Checkbox, { value: consentAgree, onChange: () => {
                                        } }),
                                    React.createElement(Typography, { type: 'paragraph' },
                                        t("agreeToConsent"),
                                        "\u00A0",
                                        React.createElement("a", { href: "#" },
                                            React.createElement(Typography, { type: 'paragraph', color: 'primary' }, t("consent"))))),
                            isNewSignUp &&
                                React.createElement("div", { className: classNames(s.checkboxlist) },
                                    React.createElement(Checkbox, { value: agree, onChange: () => {
                                            setAgree(!agree);
                                        } }),
                                    React.createElement(Typography, { type: 'paragraph' },
                                        t("agreeToTermOfUse"),
                                        "\u00A0",
                                        React.createElement("a", { href: "#", onClick: () => setTermsOfUseOpen(true) },
                                            React.createElement(Typography, { type: 'paragraph', color: 'primary' }, t("termOfUse"))),
                                        "\u00A0",
                                        t("andHaveReadThe"),
                                        React.createElement("a", { href: "#", className: s.link, onClick: () => setPrivacyOpen(true) },
                                            React.createElement(Typography, { type: 'paragraph', color: 'primary' }, t("privacyPolicy"))))),
                            React.createElement("div", { className: si.buttonTwopage },
                                React.createElement(Button, { text: t("back"), onClick: previousStep }),
                                React.createElement(Button, { style: s.mb0_button, disabled: !newPassword ||
                                        errorNewPassword ||
                                        !confirmPassword ||
                                        errorConfirmPassword ||
                                        newPassword !== confirmPassword
                                        || (isNewSignUp && (!agree || !consentAgree)), primary: true, text: t("resetPassword"), onClick: onResetPassword }))))))),
        requestInviteChangePasswordPostStatus === "pending" ? React.createElement(LoadingPopUp, { isOpen: true }) : null));
};
const mapStateToProps = (state) => {
    return {
        requestResetPassError: state.forgotPassword.requestResetPassError,
        requestResetPassStatus: state.forgotPassword.requestResetPassStatus,
        setInfo: state.invite.setInfo,
        requestInviteChangePasswordPostStatus: state.invite.requestInviteChangePasswordPostStatus,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (newPassword, newPasswordConfirmation) => dispatch(resetPasswordAsyncAction({ newPassword, newPasswordConfirmation })),
        resetRequestResetPassStatus: () => dispatch(resetRequestResetPassStatusAction()),
        InviteChangePasswordAction: (data) => dispatch(createInviteChangePasswordInfoAsyncAction({ data })),
        resetChangePasswordAction: () => dispatch(resetChangePasswordAction()),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Step3);
