import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import { setPatientInfoAction } from "../../../../store";
import { Button, InputText } from "../../../../components/Shared/Controls";
import InputDate from "../../../../components/Shared/Controls/Inputs/InputDate";
import Picker from "../../../../components/Shared/Controls/Picker";
import useInput from "../../../../hooks/useInput";
import s from './style.scss';
import useErrorPopUp from "../../../../hooks/useErrorPopUp";
import ErrorPopUp from "../../../../components/Shared/Info/ErrorPopUp";
import Typography from "../../../../components/Shared/Typography";
import { Box } from "../../../../components";
const PatientPage = ({ userDateOfBirthday, patientInfo, allowCaregiver = true, header, previusStep, addPatientInfo, onSumbit }) => {
    const { t } = useTranslation();
    const minDate = moment().subtract("120", "years").format('YYYY-MM-DD');
    const [firstName, setFirstName, errorFirstName, setErrorFirstName] = useInput(patientInfo.firstName);
    const [lastName, setLastName, errorLastName, setErrorLastName] = useInput(patientInfo.lastName);
    const [date, setDate, errorDate, setErrorDate] = useInput(patientInfo.dateOfBirth);
    const [relationship, setRelationship] = useInput(patientInfo.relationship);
    const [isOpenErrorPopUp, , , , togglePopUp] = useErrorPopUp();
    const allowChild = !date || moment(date, 'YYYY-MM-DD') > moment(userDateOfBirthday, 'YYYY-MM-DD');
    const maxDate = moment().format('YYYY-MM-DD');
    const memberAgeAlow = moment().subtract(1, "year") > moment(date, 'YYYY-MM-DD');
    const RELATIONSHIP = allowCaregiver
        ? [
            { value: "Spouse", title: t("spouse") },
            { value: "Parent", title: t("parent") },
            ...[allowChild && { value: "Child", title: t("child") }],
            { value: "Caregiver", title: t("careGiver") }
        ]
        : [
            { value: "Spouse", title: t("spouse") },
            { value: "Parent", title: t("parent") },
            ...[allowChild && { value: "Child", title: t("child") }]
        ];
    const allFieldsValid = () => !errorFirstName && !errorLastName && !errorDate &&
        firstName && lastName && date && relationship &&
        ((relationship === "Child" && allowChild) || relationship !== "Child");
    const onChangeRelationship = (value) => {
        setRelationship(value);
    };
    const submit = () => {
        if (memberAgeAlow) {
            addPatientInfo({
                firstName: firstName,
                lastName: lastName,
                dateOfBirth: date,
                relationship: relationship
            });
            onSumbit && onSumbit();
        }
        else {
            togglePopUp();
        }
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.form },
            React.createElement("div", { className: s.contentWrapper },
                React.createElement(Box, { mb: 36 },
                    React.createElement(Typography, { type: 'header_4', color: 'neutralDark', bold: true }, t("tellAboutPatient"))),
                React.createElement(InputText, { name: "f_name", label: t("firstName"), errorMessage: t('errorIncorrectFirstName'), pattern: '^.{0,35}$', value: firstName, setValue: setFirstName, error: firstName && errorFirstName, setError: setErrorFirstName }),
                React.createElement(InputText, { name: "l_name", label: t("lastName"), errorMessage: t('errorIncorrectLastName'), pattern: '^.{0,35}$', value: lastName, setValue: setLastName, error: lastName && errorLastName, setError: setErrorLastName }),
                React.createElement(InputDate, { name: "birthday", label: t("dateOfBirth"), value: date, setValue: setDate, minDate: minDate, maxDate: maxDate }),
                React.createElement(Typography, { type: 'paragraph', color: 'neutralDark', bold: true }, t("yourRelationship")),
                React.createElement(Picker, { onChange: onChangeRelationship, value: relationship, data: RELATIONSHIP.filter((e) => e), columns: allowCaregiver ? 2 : 3 }),
                React.createElement(Box, { f1: true, row: true, gap: 16 },
                    React.createElement(Button, { text: t("back"), onClick: previusStep }),
                    React.createElement(Button, { style: s.mb0_button, disabled: !allFieldsValid(), primary: true, text: t("saveAndProceed"), onClick: submit }))),
            React.createElement(ErrorPopUp, { isOpen: isOpenErrorPopUp, popUpContent: t("mustBeOlderMember"), togglePopUp: togglePopUp })));
};
const mapStateToProps = (state) => {
    return {
        userDateOfBirthday: state.auth.user.dateOfBirth,
        patientInfo: state.auth.patient
    };
};
function mapDispatchToProps(dispatch) {
    return {
        addPatientInfo: (patient) => dispatch(setPatientInfoAction(patient))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientPage);
