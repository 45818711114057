import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";

import App from "./App.jsx";
import "./i18nextInit";

import { store, persistor } from "./store";
// import { init as initApm } from '@elastic/apm-rum'
// var apm = initApm({
//   serviceName: 'OkRx-Patient-Website',
//   serverUrl: 'https://aa43589aa7c5465e8f5fa607bb53cc0d.apm.northamerica-northeast1.gcp.elastic-cloud.com:443',
//   environment: 'development'
// })

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
