import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Button } from "../Shared/Controls";
import { getAvailableTimesAsyncAction, scheduleCallAsyncAction } from "../../store/patientFiles/Actions";
import Picker from "../Shared/Controls/Picker";
import { useInput } from "../../hooks";
import s from './style.scss';
import Box from "../Shared/Box";
const SchedulePhoneCall = ({ actionsStyle, haveSideBar, scheduleCallTimes, requestGetTimesStatus, requestScheduleCallStatus, getAvailableTimes, finishAction, scheduleCall, brandFlow }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [day, setDay] = useInput();
    const [time, setTime] = useInput();
    const [showPopUp, setPopUp] = useState(false);
    const DAYS = [
        { value: scheduleCallTimes.currentDay, title: t(`${scheduleCallTimes.currentDay.toLowerCase()}`) },
        { value: scheduleCallTimes.nextDay, title: t(`${scheduleCallTimes.nextDay.toLowerCase()}`) },
    ];
    useEffect(() => {
        getAvailableTimes();
    }, []);
    useEffect(() => {
        if (requestScheduleCallStatus === "success") {
            setPopUp(true);
        }
    }, [requestScheduleCallStatus]);
    const sunbmitIsDisabled = !day || !time;
    const onFinish = () => {
        day && time && scheduleCall({ userLocalDateTime: time });
        brandFlow && finishAction ? finishAction() : navigate('/');
    };
    //This code is commented out if we need in future
    // const renderSuccessfulModal = (
    //     <PopUp style={s.popup} contentStyle={s.successContent} isOpen={showPopUp}>
    //         <div className={s.popup__header}>
    //             <div>
    //                 <SVG src={OK} className={s.popup__header_icon} />
    //             </div>
    //         </div>
    //         <div className={s.popup__content}>
    //             <div>
    //                 <div className={s.subheading_1}> {t("scheduleCallReceived")}</div>
    //             </div>
    //         </div>
    //         <div className={s.popup__controls}>
    //             <Button style={s.button} text={t("finish")} onClick={() => brandFlow && finishAction ? finishAction() : navigate('/')} />
    //         </div>
    //     </PopUp>
    // );
    return React.createElement(React.Fragment, null,
        React.createElement("div", { id: "scheduleScroll", className: classNames(s.scrollWrapper, { [s.is]: haveSideBar }) },
            React.createElement("div", { className: s.contentWrapper },
                React.createElement("div", null,
                    React.createElement("div", { className: s.mb24 },
                        React.createElement("div", { className: s.subheading_1 }, t("scheduleFirstCall")),
                        t("letUsNowAboutTime").split('\n').map(text => React.createElement("div", { key: text, className: s.paragraph_2 }, text))),
                    scheduleCallTimes.currentDay && React.createElement(Picker, { value: day, data: DAYS, columns: 2, onChange: (value) => setDay(value) }),
                    day && React.createElement(Picker, { value: time, data: day === scheduleCallTimes.currentDay
                            ? scheduleCallTimes.currentDayTimes
                            : scheduleCallTimes.nextDayTimes, onChange: (value) => setTime(value), columns: 3 }))),
            React.createElement(Box, { f1: true, justifyCenter: true, alignCenter: true, mt: 16 },
                React.createElement(Button, { style: s.button, disabled: sunbmitIsDisabled, primary: true, text: t("saveAndContinue"), onClick: onFinish }))));
};
const mapStateToProps = (state) => {
    return {
        requestScheduleCallStatus: state.patientFiles.requestScheduleCallStatus,
        requestGetTimesStatus: state.patientFiles.requestGetTimesStatus,
        scheduleCallTimes: state.patientFiles.scheduleCallTimes,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getAvailableTimes: () => dispatch(getAvailableTimesAsyncAction()),
        scheduleCall: (payload) => dispatch(scheduleCallAsyncAction(payload)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SchedulePhoneCall);
