import { createAction } from "@reduxjs/toolkit";
import { put, call, takeLatest } from "redux-saga/effects";
import languageSlice, { SLICE_NAME } from "./Slice";
import AccountRepository from "../repository/AccountRepository";
import ApiManager from "../api/ApiManager";
export const changeLanguageAction = createAction(`${SLICE_NAME}/ChangeCultureCode`);
export function* changeLocale() {
    const { changeLanguage, requestLanguageChange, requestLanguageError, startRequestLanguageChange } = languageSlice.actions;
    try {
        yield put(startRequestLanguageChange());
        yield call(AccountRepository.changeLanguage);
        yield put(changeLanguage(ApiManager.getAcceptLanguage(localStorage.getItem("i18nextLng") || "en")));
        yield put(requestLanguageChange());
    }
    catch (error) { }
}
export default function* rootSaga() {
    yield takeLatest(changeLanguageAction, changeLocale);
}
