import { all } from "redux-saga/effects";
import auth from "./auth/Sagas";
import navigation from "./navigation/Sagas";
import brand from "./brands/Sagas";
import provinces from "./province/Sagas";
import account from "./account/Sagas";
import forgotPassword from "./forgotPassword/Sagas";
import patientFiles from "./patientFiles/Sagas";
import notifications from "./notifications/Sagas";
import patientAddress from "./patientAddress/Sagas";
import education from "./education/Sagas";
import patientEducation from "./patientEducation/Sagas";
import medicalConditions from "./medicalConditions/Sagas";
import chat from "./chat/Sagas";
import medicalReminders from "./medicalReminders/Sagas";
import prescriber from "./prescriber/Sagas";
import language from "./language/Sagas";
import patient from "./patient/Sagas";
import Invite from "./Invite/Sagas";
import Pharmacy from "./pharmacy/Sagas";
import PatientBrandPrescriber from "./PatientBrandPrescriber/Sagas";
import InfusionClinic from "./InfusionClinic/Sagas";
function* rootSaga() {
    yield all([
        brand(),
        provinces(),
        auth(),
        navigation(),
        account(),
        forgotPassword(),
        patientFiles(),
        notifications(),
        patientAddress(),
        education(),
        patientEducation(),
        medicalConditions(),
        chat(),
        medicalReminders(),
        language(),
        prescriber(),
        patient(),
        Invite(),
        Pharmacy(),
        PatientBrandPrescriber(),
        InfusionClinic(),
    ]);
}
export default rootSaga;
