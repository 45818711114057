import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from '../../../../components/Shared/Controls';
import classNames from "classnames";
import { AuthRepository } from "../../../../store/repository";
import { setPhoneNumberAction } from "../../../../store";
import { connect } from "react-redux";
import { isRegisteredUserAsyncAction } from "../../../../store/auth/actions";
import useErrorPopUp from "../../../../hooks/useErrorPopUp";
import ErrorPopUp from "../../../../components/Shared/Info/ErrorPopUp";
import HelpIcon from "../../../../resources/icons/help_icon";
import colors from "../../../../resources/colors";
import HelpInfo from "../../../../components/Shared/PopUps/HelpInfo";
import s from './style.scss';
import sw from '../../../../Pages/SignUp/Steps/InvitationEmail/style.scss';
import Typography from "../../../../components/Shared/Typography";
import { Box } from "../../../../components";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
const WelcomePage = ({ userPhoneNumber, setUserPhoneNumber, error, requestStatus, checkIsRegisteredUser, handleChange, onSumbit }) => {
    const { t } = useTranslation();
    const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber || '');
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null);
    const allFieldsValid = !errorPhoneNumber && phoneNumber && (phoneNumber.length === 15 || userPhoneNumber);
    const [infoPopUp, showInfoPopUp] = useState(false);
    const [isOpen, showPopUp, errorContent, setErrorContent, togglePopUp] = useErrorPopUp();
    const onChangePhoneNumber = (phoneNum) => {
        if (phoneNum && !matchIsValidTel(phoneNum)) {
            setErrorPhoneNumber('Enter a valid US or Canadian phone number');
        }
        else {
            setErrorPhoneNumber(null);
        }
        setPhoneNumber(phoneNum);
    };
    const submitAction = () => {
        const phoneRegex = /^\+1\s?/;
        const strippedPhoneNum = phoneNumber.replace(phoneRegex, '').replace(/\s/g, '');
        AuthRepository.isRegisteredUser(phoneNumber).then(res => {
            if (!error) {
                onSumbit();
                if (strippedPhoneNum) {
                    setUserPhoneNumber(strippedPhoneNum);
                }
            }
        }).catch(error => {
            if (error !== undefined) {
                if (error.data.title) {
                    setErrorContent(error.data.title);
                }
                else {
                    setErrorContent(error.data.messages[0]);
                }
            }
            showPopUp(true);
        });
    };
    return React.createElement("div", { className: s.contentWrapper },
        React.createElement(Box, { mb: 36 },
            React.createElement(HelpInfo, { isOpen: infoPopUp, setIsOpen: showInfoPopUp }),
            React.createElement(Box, { mb: 36 },
                React.createElement(Typography, { type: "subtitle_2", color: "neutralGray" }, t("welcome")),
                React.createElement("h1", null,
                    React.createElement(Typography, { type: "header_1", color: "neutralDark", bold: true }, t("signUp")),
                    React.createElement("div", { onClick: () => showInfoPopUp(true) },
                        React.createElement(HelpIcon, { color: colors.icons.primary, className: s.helpIcon })))),
            React.createElement(Typography, { type: "paragraph", color: 'neutralDark' }, t("sendSMSMessage"))),
        React.createElement("div", { className: classNames(sw.contentWrapper) },
            React.createElement("div", { className: classNames(sw.formGroup) },
                React.createElement("label", null, t("phoneNumber")),
                React.createElement(MuiTelInput, { value: phoneNumber, onChange: onChangePhoneNumber, error: !!errorPhoneNumber, defaultCountry: "CA", onlyCountries: ['CA', 'US'], langOfCountryName: "en", forceCallingCode: true, focusOnSelectCountry: true, style: { backgroundColor: colors.primaryshades3, width: "100%" }, tabIndex: 4 }),
                errorPhoneNumber && React.createElement("div", { className: classNames(sw.error) }, errorPhoneNumber)),
            React.createElement("div", { className: s.buttonTwopage },
                React.createElement(Button, { disabled: !allFieldsValid, primary: true, text: t("proceedSecurely"), onClick: submitAction })),
            React.createElement("div", { className: s.signUptext },
                React.createElement("p", null,
                    " ",
                    React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, t("haveAccount")),
                    " "),
                React.createElement("a", { href: '/', className: s.link },
                    t("signInV2"),
                    " "))),
        React.createElement(ErrorPopUp, { isOpen: isOpen, popUpContent: errorContent, togglePopUp: togglePopUp }));
};
const mapStateToProps = (state) => {
    return {
        error: state.auth.requestIsRegisteredError,
        requestStatus: state.auth.requestIsRegisteredStatus,
        userPhoneNumber: state.auth.phoneNumber
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setUserPhoneNumber: (phoneNumber) => {
            dispatch(setPhoneNumberAction(phoneNumber));
        },
        checkIsRegisteredUser: (phoneNumber) => dispatch(isRegisteredUserAsyncAction(phoneNumber)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage);
