import ApiManager from "../api/ApiManager";
const version = "1.2";
const PATIENT_EDUCATION = `/v${version}/patient/education`;
class PatientEducationRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new PatientEducationRepository();
        }
        return this.instance;
    }
    static getEducationLibrary = (payload) => {
        return ApiManager.getInstance().get(PATIENT_EDUCATION, {
            patientId: payload.patientId,
            drugBrandCode: payload.drugBrandCode,
            mimeType: payload.mimeType,
            cultureCode: payload.cultureCode,
            pageNumber: 1,
            pageSize: 99,
            title: payload.title,
            description: payload.description,
            tags: payload.tags,
            orderByAttribute: null,
            orderByDescending: false
        });
    };
    static markAsRead = (payload) => {
        return ApiManager.getInstance().post(PATIENT_EDUCATION, payload);
    };
}
export default PatientEducationRepository;
