import React from "react";
import colors from "../../../resources/colors";
import PageHeader from "./components/PageHeader";
import s from "./style.scss";
import HeaderNote from "../HeaderNote";
import { connect } from "react-redux";
const PageContainer = ({ children, selectedpatientState, isAdmin }) => {
    return (React.createElement(React.Fragment, null,
        selectedpatientState?.patientId != null && isAdmin == false ? React.createElement(HeaderNote, null) : null,
        React.createElement("div", { className: s.pageContainer, style: { backgroundColor: colors.backgroundColorWithSidebar } },
            React.createElement(PageHeader, null),
            children)));
};
const mapStateToProps = (state) => {
    return {
        selectedpatientState: state.account.selectedpatient,
        isAdmin: state.account.isAdmin,
    };
};
function mapDispatchToProps(dispatch) {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
