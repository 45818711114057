import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Receipts from "../../../../../../../../components/MedicalDocuments/Receipts";
import { Button } from "../../../../../../../../components/Shared/Controls";
import { PopUp } from "../../../../../../../../components/Shared/Info";
import { useDeviceHeight } from "../../../../../../../../hooks";
import s from "./style.scss";
const Step3 = ({ nextPage, page }) => {
    const { t } = useTranslation();
    useDeviceHeight("receiptsScroll", 460, 460, 350);
    const [haveChanges, setHaveChanges] = useState(false);
    const [youAreSureModal, setYouAreSureModal] = useState(false);
    const toggleYouAreSureModal = () => setYouAreSureModal(!youAreSureModal);
    const renderYouAreSureModal = () => {
        return (React.createElement(PopUp, { style: s.removeModal, isOpen: youAreSureModal, toggle: toggleYouAreSureModal },
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("sureExitWithoutSaving")),
            React.createElement("div", { className: s.actions },
                React.createElement(Button, { style: s.button, text: t("yes"), primary: true, onClick: () => {
                        nextPage();
                    } }),
                React.createElement(Button, { style: s.button, text: t("no"), onClick: toggleYouAreSureModal }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.title },
            t("addDispenses"),
            React.createElement("span", null, `${page} / 5`)),
        React.createElement("div", { className: s.receiptsWrapper },
            React.createElement(Receipts, { setHaveChanges: setHaveChanges, skipAction: (isSubmit) => !isSubmit && haveChanges ? toggleYouAreSureModal() : nextPage() },
                renderYouAreSureModal(),
                React.createElement("div", { className: classNames(s.fullLine, s.mb12) },
                    React.createElement("div", { className: s.paragraph_2 }, t("uploadReceiptPicture")))))));
};
export default Step3;
