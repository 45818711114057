import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageBody from "../../components/Shared/PageBody";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Receipts from "../../components/MedicalDocuments/Receipts";
import { checkAvailabilityOfFeature } from "../../store";
import { PopUp } from "../../components/Shared/Info";
import { Button } from "../../components/Shared/Controls";
import config from "../../../config.json";
import s from "./style.scss";
import { Box } from "../../components";
const ReceiptsPage = ({ checkFeature }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [haveChanges, setHaveChanges] = useState(false);
    useEffect(() => {
        !checkFeature("Receipts") && navigate("/");
    }, []);
    const [youAreSureModal, setYouAreSureModal] = useState(false);
    const toggleYouAreSureModal = () => setYouAreSureModal(!youAreSureModal);
    const backAction = () => {
        !haveChanges
            ? navigate(-1)
            : setYouAreSureModal(true);
    };
    const renderYouAreSureModal = () => {
        return React.createElement(PopUp, { style: s.removeModal, isOpen: youAreSureModal, toggle: toggleYouAreSureModal },
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("sureExitWithoutSaving")),
            React.createElement("div", { className: s.actions },
                React.createElement(Button, { style: s.button, text: t("yes"), primary: true, onClick: () => {
                        navigate(-1);
                    } }),
                React.createElement(Button, { style: s.button, text: t("no"), onClick: toggleYouAreSureModal })));
    };
    return (React.createElement(Box, { fullWidth: true },
        React.createElement(PageBody, { backAction: backAction, title: t("dispenses") },
            React.createElement(Receipts, { setHaveChanges: setHaveChanges, hasSidebar: true },
                renderYouAreSureModal(),
                config.design_system.showDescriptionOfFeature
                    && React.createElement("div", { className: classNames(s.fullLine, s.mb12) },
                        React.createElement("div", { className: s.paragraph_2 }, t("uploadReceiptPicture")))))));
};
const mapStateToProps = (state) => {
    return {
        checkFeature: (key) => checkAvailabilityOfFeature(state, key),
    };
};
export default connect(mapStateToProps)(ReceiptsPage);
