import ApiManager from "../api/ApiManager";
import config from "../../../config.json";
const version = "1.2";
const HOME = `/v${version}/home`;
const SETTINGS_INFO = `/v${version}/account/settings`;
const CHANGE_LANGUAGE = `/v${version}/account/update-last-language`;
const PATIENT_INFO = `/v${version}/patient`;
const REGISTRATION_INFO = `/v${version}/patient/registration-info`;
const ENROLL_BY_OWNER = `/v${version}/patient/complete-enroll-by-owner`;
const PATIENT_BRANDS = `/v${version}/patient/brand`;
const ASSIGN_TO_BRAND = `/v${version}/patient/brand/assign-to-brand`;
const BRAND_INFO = `/v${version}/patient/brand/edit-patient-brand-information`;
const PROFILE_TABS = `/v${version}/brand-setting/profile-tabs`;
const HEALTH_INFORMATION = `/v${version}/patient/medical-conditions`;
class AccountRepository {
    static instance;
    constructor() { }
    static getInstance() {
        if (!this.instance) {
            this.instance = new AccountRepository();
        }
        return this.instance;
    }
    static getHome = (impersonatedAccountId) => {
        return ApiManager.getInstance().get(HOME, { impersonatedAccountId: impersonatedAccountId, applicationType: config.applicationType }, true);
    };
    static getRegistrationInfo = (payload) => {
        return ApiManager.getInstance().get(REGISTRATION_INFO, {
            PatientId: payload.patientId,
            DrugBrandCode: payload.drugBrandCode
        });
    };
    static getSettingsInfo = (Id) => {
        return ApiManager.getInstance().get(SETTINGS_INFO, { impersonatedAccountId: Id });
    };
    static getPatientInfo = (patientId) => {
        return ApiManager.getInstance().get(PATIENT_INFO, {
            Id: patientId
        });
    };
    static getSinglePatientInfo = (patientId) => {
        return ApiManager.getInstance().get(PATIENT_INFO, {
            Id: patientId
        });
    };
    static getPatientHealthInformation = (patientId, drugBrandCode) => {
        return ApiManager.getInstance().get(HEALTH_INFORMATION, {
            PatientId: patientId,
            DrugBrandCode: drugBrandCode
        });
    };
    static updatePatientHealthInformation = (healthInformation) => {
        return ApiManager.getInstance().put(HEALTH_INFORMATION, healthInformation);
    };
    static updatePatientInfo = (patient, patientId) => {
        return ApiManager.getInstance().put(PATIENT_INFO, {
            ...patient,
            patientId,
        });
    };
    static getPatientBrands = (patientId) => {
        return ApiManager.getInstance().get(PATIENT_BRANDS, { PatientId: patientId, applicationType: config.applicationType });
    };
    static assignToBrand = (user) => {
        return ApiManager.getInstance().post(ASSIGN_TO_BRAND, user);
    };
    static enrollByOwner = (ownerInfo) => {
        return ApiManager.getInstance().post(ENROLL_BY_OWNER, ownerInfo);
    };
    static getProfileTabs = (payload) => {
        return ApiManager.getInstance().get(PROFILE_TABS, {
            drugBrandCode: payload.drugBrandCode,
            Province: payload.province,
        });
    };
    static updateEmail = (payload) => {
        return ApiManager.getInstance().post(SETTINGS_INFO, {
            email: payload,
        });
    };
    static updateBrandEnrollment = (payload) => {
        return ApiManager.getInstance().put(BRAND_INFO, payload);
    };
    static changeLanguage = () => {
        return ApiManager.getInstance().get(CHANGE_LANGUAGE);
    };
}
export default AccountRepository;
