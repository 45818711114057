import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Eye from "../../../../../resources/icons/eye.svg";
import EyeCrossed from "../../../../../resources/icons/eye-crossed.svg";
import Close from "../../../../../resources/icons/close.svg";
import SVG from "react-inlinesvg";
import s from "./style.scss";
import colors from "../../../../../resources/colors";
import Typography from "../../../Typography";
const InputPassword = ({ style, name, placeholder, label, pattern, errorMessage, password, setPassword, error, setError, maxLength = 35, tabIndex, onFocus }) => {
    const [typePassword, setTypePassword] = useState(true);
    const handleValidation = (value) => {
        if (!pattern)
            return true;
        // array patterns, multiple validation rules
        if (pattern.listOnConditions && pattern.rule) {
            const condition = new RegExp(pattern.rule, "g");
            const conditions = pattern.listOnConditions.map((rule) => new RegExp(rule, "g"));
            return (conditions.map((condition) => condition.test(value)).filter((t) => t)
                .length >=
                (pattern.countOfConditions || pattern.listOnConditions.length) &&
                condition.test(value));
        }
        // string pattern, one validation rule
        if (typeof pattern.rule === "string") {
            const condition = new RegExp(pattern.rule, "g");
            return condition.test(value);
        }
    };
    const onChange = (value) => {
        setPassword && setPassword(value);
        if (pattern && setError) {
            const isValid = handleValidation(value);
            !isValid && value ? setError(true) : setError(false);
        }
    };
    const clearPassword = () => setPassword("");
    const showPassword = () => setTypePassword((t) => !t);
    const inputRef = useRef(null);
    useEffect(() => {
        if (onFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, [onFocus]);
    return (React.createElement("div", null,
        label && React.createElement(Typography, { type: "label", color: "neutralGray", bold: true }, label),
        React.createElement("div", { className: s.inputGroup, style: {
                "--icon-hover-color": colors.controlsGeneralColor,
                "--input-focus-border-color": colors.inputFocusColorBorder,
                "--input-focus-shadow-color": colors.inputFocusShadowBorder,
            } },
            React.createElement("input", { tabIndex: tabIndex, ref: inputRef, "data-testid": "input-test", value: password, name: name, style: { backgroundColor: colors.primaryshades3 }, className: classNames(s.input, style, {
                    [s.errorInput]: error && password,
                    [s.hiddenPassword]: typePassword,
                    [s.withPlaceholder]: placeholder,
                }), type: typePassword ? "password" : "text", required: true, maxLength: maxLength, onChange: (e) => onChange(e.target.value) }),
            placeholder && React.createElement("div", { "data-testid": "placeholder-test", className: s.floatingLabel }, placeholder),
            React.createElement(SVG, { "data-testid": "close-test", src: Close, className: s.close, onClick: clearPassword }),
            React.createElement("div", { className: s.showPassword, onClick: showPassword }, typePassword ? (React.createElement(SVG, { src: Eye, className: s.eye })) : (React.createElement(SVG, { src: EyeCrossed, className: s.eye }))),
            error && errorMessage && React.createElement("span", { "data-testid": "error-test", className: s.error }, errorMessage))));
};
export default InputPassword;
