import { call, put, takeLatest } from "redux-saga/effects";
import { createAction } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./slice";
import { handleApiError } from "../utils";
import patientSlice from "./slice";
import PatientRepository from "../repository/PatientRepository";
import { mapPatientInfo } from "./Mapper";
//getAllpatientdata
export const getAllPatientAsyncAction = createAction(`${SLICE_NAME}/getAllPatientAsyncAction`);
export function* getAllpatientSaga(action) {
    const { startRequestPatientAction, successRequestPatientAction, showRequestPatientErrorAction, } = patientSlice.actions;
    try {
        yield put(startRequestPatientAction());
        let result = yield call(PatientRepository.getAllPatientInfo, action.payload.page, action.payload.rowPerPage, action.payload.order, action.payload.orderBy, action.payload.LastName, action.payload.FirstName, action.payload.DateOfBirth, action.payload.Province, action.payload.Phone, action.payload.Email, action.payload.status);
        const Patient = yield mapPatientInfo(result.data);
        yield put(successRequestPatientAction(Patient));
    }
    catch (error) {
        yield handleApiError(error, showRequestPatientErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(getAllPatientAsyncAction, getAllpatientSaga);
}
