import React from 'react';
import { PopUp } from '../Info/index';
import LoaderIcon from '../../../resources/icons/icon-loader';
import s from './style.scss';
import colors from '../../../resources/colors';
const LoadingPopUp = ({ style, isOpen }) => {
    // return null
    return isOpen ? (React.createElement(PopUp, { style: s.loadingPopUp, contentStyle: s.tranpatentPopUp, isOpen: isOpen, allowClose: false, top: true },
        React.createElement(LoaderIcon, { color1: colors.primary, color2: colors.secondary, className: s.loaderIcon }))) : null;
};
export default LoadingPopUp;
