export const mapPatients = (respose) => respose.value.patients;
export const mapAccountInfo = (response) => {
    return {
        user: {
            patientId: response.value.patientId,
            drugBrandCode: response.value.currentDrugBrandCode,
            province: response.value.patientProvince,
        },
        drugBrandsCodes: response.value.drugBrandsCodes,
        brandPhoneNumber: response.value.currentBrandPhoneNumber,
        brandEmail: response.value.currentBrandEmail,
        registrationUnfinished: response.value.registrationUnfinished,
        currentProgramLogo: response.value.currentProgramLogo,
        currentProgramWebsite: response.value.currentProgramWebsite,
        currentDrugBrandPrescriberName: response.value.currentDrugBrandPrescriberName,
    };
};
export const mapEmail = (response) => response.value.email;
export const mapPatientPersonalInfo = (response) => {
    return response.value.resultItems[0];
};
export const mapPatientHealthInformation = (response) => {
    return response.value;
};
export const mapPatientBrands = (response) => {
    let data = [];
    response.value.forEach((item) => {
        data.push({
            // base64Logo: `data:image/jpeg;base64,${item.base64Logo}`,
            logoLink: item.logoLink,
            effectiveDate: item.effectiveDate,
            drugBrandCode: item.drugBrandCode,
            linkedIdentifier: item.linkedIdentifier,
            programPhoneNumber: item.programPhoneNumber,
            programEmail: item.programEmail
        });
    });
    return data;
};
