import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import Button from "../../../../../components/Shared/Controls/Button";
import classNames from "classnames";
import Typography from "../../../../../components/Shared/Typography";
import { resetCreatePharmacyInfoAction, resetPutPharmacyInfoAction } from "../../../../../store/pharmacy";
import { connect } from "react-redux";
const AddedPharmacy = ({ isNewSignUp, setStep, previousStep, resetCreatePharmacyAction, resetPutPharmacyAction }) => {
    const { t } = useTranslation();
    const Submit = () => {
        setStep(true);
    };
    useEffect(() => {
        resetCreatePharmacyAction();
        resetPutPharmacyAction();
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(si.contentWrapper) },
            React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
                React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                    React.createElement("div", { className: classNames(si.formstepbar) },
                        React.createElement("div", { className: (si.stepToptext) },
                            isNewSignUp ?
                                React.createElement("div", { className: s.pages },
                                    React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `13/15`)) : null,
                            React.createElement("h4", null, t('addedPharmacy')),
                            React.createElement("p", null, t('textThankYouForPharmacy'))),
                        React.createElement("div", { className: si.buttonTwopage },
                            React.createElement(Button, { text: t("back"), onClick: previousStep }),
                            React.createElement(Button, { style: s.mb0_button, primary: true, text: t("next"), onClick: Submit }))))))));
};
function mapDispatchToProps(dispatch) {
    return {
        resetCreatePharmacyAction: () => dispatch(resetCreatePharmacyInfoAction()),
        resetPutPharmacyAction: () => dispatch(resetPutPharmacyInfoAction())
    };
}
export default connect(null, mapDispatchToProps)(AddedPharmacy);
