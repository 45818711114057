import ApiManager from "../api/ApiManager";
const version = "1.2";
const PRESCRIBER_INFO_POST = `/v${version}/prescriber`;
const PATIENT_PRESCRIBER_INFO_GET = `/v${version}/Patient/Brand/Prescriber`;
const DRUG_BRAND_CODE_DROPDOWN_GET = `/v${version}/Brands`;
const PRESCRIBER_INFO_PUT = `/v${version}/Prescriber`;
const PRESCRIBER_GET_ALL = `/v${version}/Prescriber`;
const PRESCRIBER_DELETE = `/v${version}/Prescriber`;
class PresciberRepository {
    constructor() { }
    //post request
    static postPrescriber = (data) => {
        return ApiManager.getInstance().post(PRESCRIBER_INFO_POST, data);
    };
    //get request
    static getPatientPrescriberInfo = (payload) => {
        let filters = "";
        if (!!payload.patientId) {
            filters += `&patientId=${payload.patientId}`;
        }
        if (!!payload.id) {
            filters += `&id=${payload.id}`;
        }
        return ApiManager.getInstance().get(`${PATIENT_PRESCRIBER_INFO_GET}?${filters}`);
    };
    //get request
    static getDrugBrandCodesDropdownInfo = () => {
        return ApiManager.getInstance().get(DRUG_BRAND_CODE_DROPDOWN_GET);
    };
    //get all
    static getAllPrescriberInfo = (payload) => {
        let orderCopy = false;
        let filterItems = '';
        if (payload.order == 'asc') {
            orderCopy = false;
        }
        else {
            orderCopy = true;
        }
        if (payload.id) {
            filterItems += `&Id=${payload.id}`;
        }
        if (payload.name) {
            filterItems += `&Name=${payload.name}`;
        }
        if (payload.drugBrandCode && payload.drugBrandCode.length > 0) {
            payload.drugBrandCode.map((code, index) => {
                filterItems += `&drugBrandCode[${index}]=${code}`;
            });
        }
        if (payload.brandIdentifier) {
            filterItems += `&BrandIdentifier=${payload.brandIdentifier}`;
        }
        return ApiManager.getInstance().get(`${PRESCRIBER_GET_ALL}?OrderByAttribute=${payload.orderBy}&OrderByDescending=${orderCopy}&PageNumber=${(payload.page ?? 0) + 1}&PageSize=${payload.rowsPerPage ?? 10}${filterItems}`);
    };
    //put request
    static updatePrescriberInfo = (Prescriber, PrescriberId) => {
        return ApiManager.getInstance().put(PRESCRIBER_INFO_PUT, {
            ...Prescriber
        });
    };
    //delete req
    static deletePrescriber = (presciberId) => {
        return ApiManager.getInstance().delete(`${PRESCRIBER_DELETE}?prescriberId=${presciberId}`);
    };
}
export default PresciberRepository;
