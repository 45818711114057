import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SVG from 'react-inlinesvg';
import classNames from "classnames";
import { resetRequestSmsCodeStatusAction, resetRequestValidateCodeStatusAction, setPhoneNumberAction } from "../../../../store";
import { requestSmsCodeAsyncAction, validateSmsCodeAsyncAction } from "../../../../store/auth/actions";
import { Button, InputText } from "../../../../components/Shared/Controls";
import { Notification } from "../../../../components/Shared/Info";
import useInput from "../../../../hooks/useInput";
import Close from '../../../../resources/icons/close.svg';
import { doFormat } from "../../../../utils";
import s from './style.scss';
import si from "../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import useErrorPopUp from "../../../../hooks/useErrorPopUp";
import { usePrevious } from "../../../../hooks";
import colors from "../../../../resources/colors";
import Typography from "../../../../components/Shared/Typography";
import { Box } from "../../../../components";
const ConfirmPage = ({ userPhoneNumber, requestSmsCodeError, requestValidateCodeError, requestValidateCodeStatus, requestSmsCode, validateSmsCode, setUserPhoneNumber, requestSmsCodeStatus, onSumbit, registrationAction, prevStep, }) => {
    const { t } = useTranslation();
    const [verificationCode, setVerificationCode, errorVerificationCode, setErrorVerificationCode] = useInput();
    const [phoneNumber, setNumber, error, setError] = useInput(userPhoneNumber);
    const [isPhoneNumberChanging, changePhoneNumber] = useState(false);
    const allFieldsValid = verificationCode && verificationCode.length === 4 && !isPhoneNumberChanging;
    const [isOpen, showPopUp, errorContent, setErrorContent, togglePopUp] = useErrorPopUp();
    const ref = usePrevious(requestValidateCodeStatus);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [resent, setResent] = useState(false);
    useEffect(() => {
        setErrorContent(requestSmsCodeError?.message || '');
        requestSmsCodeError && showPopUp(true);
    }, [requestSmsCodeError]);
    useEffect(() => {
        setErrorContent(requestValidateCodeError?.message || '');
        requestValidateCodeError && showPopUp(true);
    }, [requestValidateCodeError]);
    useEffect(() => {
        if (ref !== undefined && requestValidateCodeStatus === "success" && verificationCode && !errorVerificationCode) {
            onSumbit && onSumbit();
            registrationAction && registrationAction();
        }
    }, [requestValidateCodeStatus]);
    useEffect(() => {
        requestSmsCode();
    }, []);
    useEffect(() => {
        requestSmsCodeStatus === "success" && setResent(true);
    }, [requestSmsCodeStatus]);
    const clear = () => setNumber('');
    const phoneNumberIsValid = phoneNumber.length === 10 || phoneNumber.length === 9;
    const onChangeNumber = () => {
        if (phoneNumberIsValid) {
            changePhoneNumber(false);
            setUserPhoneNumber(phoneNumber);
            requestSmsCode();
        }
    };
    const verifySmsCode = () => {
        validateSmsCode(verificationCode);
    };
    const resendAction = () => {
        setResent(false);
        requestSmsCode();
        setResendDisabled(true);
        setTimeout(() => setResendDisabled(false), 30000);
    };
    return React.createElement("div", { className: s.contentWrapper },
        React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
            React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                React.createElement("div", { className: classNames(si.formstepbar) },
                    React.createElement(Box, { f1: true, column: true, gap: 16, mb: 36 },
                        React.createElement(Typography, { type: 'header_4', color: 'neutralDark', bold: true }, t("confirmPhoneNumber")),
                        React.createElement(Typography, { type: 'paragraph', color: 'neutralDark' }, t("enterSmsCodeLabel"))),
                    React.createElement("div", { className: classNames(si.formGroup) },
                        React.createElement("label", null, t("smsCode")),
                        React.createElement(InputText, { name: "smsCode", value: verificationCode, setValue: setVerificationCode, error: errorVerificationCode, setError: setErrorVerificationCode, length: 4, isNumber: true })),
                    React.createElement("div", { className: classNames(si.formGroup, si.buttonTwopage) },
                        React.createElement(Button, { style: classNames({ [s.mb12]: resendDisabled }), text: t("resend"), disabled: resendDisabled, onClick: resendAction })),
                    resent && resendDisabled && React.createElement("div", { className: classNames(s.paragraph_2, s.mb20) }, t("resendMessage")),
                    React.createElement(Box, { f1: true, mb: 36 }, isPhoneNumberChanging ? React.createElement(React.Fragment, null,
                        React.createElement("div", { className: classNames(si.formGroup) },
                            React.createElement("label", null, t("phoneNumber")),
                            React.createElement(InputText, { style: s.input, name: 'phone', errorMessage: t("errorIncorrectPhoneNumber"), value: phoneNumber, setValue: setNumber, error: error, setError: setError, pattern: '^[0-9]{9,10}$', length: 10, isNumber: true, clearCross: false }, phoneNumber &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: s.clear, onClick: clear },
                                        React.createElement(SVG, { src: Close, className: classNames(s.close, { [s.frClose]: localStorage.getItem("i18nextLng") === "fr" }) })),
                                    React.createElement("div", { className: s.save, style: { color: colors.controlsGeneralColor }, onClick: onChangeNumber }, t("save"))))))
                        :
                            React.createElement(Notification, { type: "info" },
                                React.createElement("div", { className: s.numberNotif },
                                    React.createElement("div", null, doFormat(phoneNumber, '*** *** ****')),
                                    React.createElement("a", { className: s.change, style: { color: colors.primary }, onClick: () => changePhoneNumber(true) }, t("change"))))),
                    React.createElement("div", { className: si.buttonTwopage },
                        React.createElement(Button, { text: t("back"), onClick: prevStep }),
                        React.createElement(Button, { disabled: !allFieldsValid, primary: true, text: !registrationAction ? t("verify") : t("completeRegistration"), onClick: verifySmsCode }))))));
};
const mapStateToProps = (state) => {
    return {
        userPhoneNumber: state.auth.phoneNumber,
        requestSmsCodeError: state.auth.requestSmsCodeError,
        requestSmsCodeStatus: state.auth.requestSmsCodeStatus,
        requestValidateCodeError: state.auth.requestValidateCodeError,
        requestValidateCodeStatus: state.auth.requestValidateCodeStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        setUserPhoneNumber: (phoneNumber) => {
            dispatch(setPhoneNumberAction(phoneNumber));
        },
        requestSmsCode: () => dispatch(requestSmsCodeAsyncAction()),
        resetRequestSmsCodeStatus: () => dispatch(resetRequestSmsCodeStatusAction()),
        validateSmsCode: (code) => dispatch(validateSmsCodeAsyncAction(code)),
        resetRequestValidateSmsCodeStatus: () => dispatch(resetRequestValidateCodeStatusAction())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPage);
