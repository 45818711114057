export const mapToken = (response) => {
    return response.value.token;
};
export const mapSignInResponse = (response) => {
    var convertObject = response.value.selectedPatientInfo;
    if (!!convertObject)
        convertObject.stepsToComplete = response.value.selectedPatientInfo?.stepsToComplete;
    return convertObject;
};
export const mapAzureInfo = (response) => {
    return {
        ...response.value.azureUserInfo,
        createAccount: response.value.createAccount
    };
};
export const mapSignUpResponse = (response) => {
    return response.value;
};
export const mapMemberId = (response) => response.value;
