import { Typography } from "@material-ui/core";
import classNames from "classnames";
import golbalcss from "../../Pages/styles.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const SubHeadingwithback = ({ item, backAction }) => {
    const navigate = useNavigate();
    return (React.createElement("div", { className: classNames(golbalcss.sectionHeader, golbalcss.editTitle) },
        React.createElement(Typography, { variant: "h1", className: classNames(golbalcss.pageTitle), component: "h1" },
            React.createElement(ArrowBackIcon, { className: golbalcss.backarrow, onClick: () => !backAction && navigate(-1) || backAction() }),
            item)));
};
export default connect(null, null)(SubHeadingwithback);
