import { createAction } from "@reduxjs/toolkit";
import { SLICE_NAME } from "./Slice";
export const signInAsyncAction = createAction(`${SLICE_NAME}/signInAsyncAction`);
export const logOutAsyncAction = createAction(`${SLICE_NAME}/logOutAsyncAction`);
export const refreshTokenAsyncAction = createAction(`${SLICE_NAME}/refreshTokenAsyncAction`);
export const isRegisteredUserAsyncAction = createAction(`${SLICE_NAME}/isRegisteredUserAsyncAction`);
export const requestSmsCodeAsyncAction = createAction(`${SLICE_NAME}/requestSmsCodeAsyncAction`);
export const validateSmsCodeAsyncAction = createAction(`${SLICE_NAME}/validateSmsCodeAsyncAction`);
export const signUpAsyncAction = createAction(`${SLICE_NAME}/signUpAsyncAction`);
export const signUpForExistingUserAsyncAction = createAction(`${SLICE_NAME}/signUpForExistingUserAsyncAction`);
export const changePhoneRequestAsyncAction = createAction(`${SLICE_NAME}/changePhoneRequestAsyncAction`);
export const changePhoneNumberAsyncAction = createAction(`${SLICE_NAME}/changePhoneNumberAsyncAction`);
export const validatePasswordAsyncAction = createAction(`${SLICE_NAME}/validatePasswordAsyncAction`);
export const changePasswordAsyncAction = createAction(`${SLICE_NAME}/changePasswordAsyncAction`);
export const addMemberAsyncAction = createAction(`${SLICE_NAME}/addMemberAsyncAction`);
export const signInByBiometryAsyncAction = createAction(`${SLICE_NAME}/signInByBiometryAsyncAction`);
export const verifyEmailAsyncAction = createAction(`${SLICE_NAME}/verifyEmailAsyncAction`);
