import HealthInformation from "../../../../resources/icons/health_info.svg";
import PersonalData from "../../../../resources/icons/personal_data.svg";
import MedicalDocuments from "../../../../resources/icons/medicalDocuments.svg";
import Email from "../../../../resources/icons/email.svg";
export const ProfileInfoData = [
    {
        icon: PersonalData,
        title: "personalData",
        description: "setupPersonalData",
        url: "/personal-data",
    },
    {
        icon: Email,
        title: "communicationPreferences",
        description: "callPreferences",
        url: "/communication-preferences",
    },
    {
        icon: MedicalDocuments,
        title: "medicalDocuments",
        description: "medicalDocumentsDescription",
        url: "/medical-documents",
    },
    {
        icon: HealthInformation,
        title: "healthInformation",
        description: "healthInformationDescription",
        url: "/health-information",
    },
];
