import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
export const SLICE_NAME = "PATIENT_ADDRESS";
export const addressAdapter = createEntityAdapter({
    selectId: (address) => address.id
});
export const addressSelectors = addressAdapter.getSelectors();
const initialState = {
    requestGetAddressesError: null,
    requestGetAddressesStatus: "idle",
    requestAddAddressError: null,
    requestAddAddressStatus: "idle",
    requestEditAddressError: null,
    requestEditAddressStatus: "idle",
    requestDeleteAddressError: null,
    requestDeleteAddressStatus: "idle",
    requestAddProvinceToAddressError: null,
    requestAddProvinceToAddressStatus: "idle",
    holdAddressId: undefined,
    setAddressData: null
};
const addressSlice = createSlice({
    name: SLICE_NAME,
    initialState: addressAdapter.getInitialState(initialState),
    reducers: {
        setAddressesAction: (state, action) => {
            state.setAddressData = action.payload[0];
            for (var i = 0; i < action.payload.length; i++)
                if (action.payload[i].isPrimary) {
                    state.setAddressData = action.payload[i];
                    break;
                }
            addressAdapter.setAll(state, action.payload);
            state.requestGetAddressesStatus = "success";
            state.requestGetAddressesError = null;
        },
        startRequestGetAddressesAction: (state) => {
            state.requestGetAddressesStatus = "pending";
            state.requestGetAddressesError = null;
        },
        showRequestGetAddressesErrorAction: (state, action) => {
            state.requestGetAddressesStatus = "failure";
            state.requestGetAddressesError = action.payload;
        },
        resetRequestGetAddressesStatusAction: (state) => {
            state.requestGetAddressesStatus = "idle";
            state.requestGetAddressesError = null;
        },
        addAddressAction: (state, action) => {
            addressAdapter.addOne(state, action.payload);
            state.requestAddAddressStatus = "success";
            state.requestAddAddressError = null;
        },
        startRequestAddAddressAction: (state) => {
            state.requestAddAddressStatus = "pending";
            state.requestAddAddressError = null;
        },
        showRequestAddAddressErrorAction: (state, action) => {
            state.requestAddAddressStatus = "failure";
            state.requestAddAddressError = action.payload;
        },
        resetRequestAddAddressStatusAction: (state) => {
            state.requestAddAddressStatus = "idle";
            state.requestAddAddressError = null;
        },
        editAddressAction: (state, action) => {
            addressAdapter.updateOne(state, {
                id: action.payload.id,
                changes: { ...action.payload }
            });
            state.requestEditAddressStatus = "success";
            state.requestEditAddressError = null;
        },
        startRequestEditAddressAction: (state) => {
            state.requestEditAddressStatus = "pending";
            state.requestEditAddressError = null;
        },
        showRequestEditAddressErrorAction: (state, action) => {
            state.requestEditAddressStatus = "failure";
            state.requestEditAddressError = action.payload;
        },
        resetRequestEditAddressStatusAction: (state) => {
            state.requestEditAddressStatus = "idle";
            state.requestEditAddressError = null;
        },
        deleteAddressAction: (state, action) => {
            addressAdapter.removeOne(state, action.payload);
            state.requestDeleteAddressStatus = "success";
            state.requestDeleteAddressError = null;
        },
        startRequestDeleteAddressAction: (state) => {
            state.requestDeleteAddressStatus = "pending";
            state.requestDeleteAddressError = null;
        },
        showRequestDeleteAddressErrorAction: (state, action) => {
            state.requestDeleteAddressStatus = "failure";
            state.requestDeleteAddressError = action.payload;
        },
        resetRequestDeleteAddressStatusAction: (state) => {
            state.requestDeleteAddressStatus = "idle";
            state.requestDeleteAddressError = null;
        },
        startRequestAddProvinceAction: (state) => {
            state.requestAddProvinceToAddressStatus = "pending";
            state.requestAddProvinceToAddressError = null;
        },
        successRequestAddProvinceAction: (state) => {
            state.requestAddProvinceToAddressStatus = "success";
            state.requestAddProvinceToAddressError = null;
        },
        showRequestAddProvinceErrorAction: (state, action) => {
            state.requestAddProvinceToAddressStatus = "failure";
            state.requestAddProvinceToAddressError = action.payload;
        },
        resetRequestAddProvinceAction: (state) => {
            state.requestAddProvinceToAddressStatus = "idle";
            state.requestAddProvinceToAddressError = null;
        },
        resetAddressSliceAction: (state) => {
            state = addressAdapter.getInitialState(initialState);
        },
        holdAddressIdAction: (state, action) => {
            state.holdAddressId = action.payload;
        },
    }
});
export default addressSlice;
