import { createSlice } from "@reduxjs/toolkit";
import config from "../../../config.json";
export const SLICE_NAME = "AUTH";
const initialState = {
    phoneNumber: undefined,
    brandId: config.appSingleBrand ? config.brand : "",
    user: {
        firstName: "",
        lastName: "",
        email: undefined,
        dateOfBirth: "",
    },
    patient: {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        relationship: undefined
    },
    provinceId: "",
    additionalInfo: {
        gender: undefined,
        doctorsName: "",
        din: "",
        medicalConditions: []
    },
    verificationCode: "",
    password: "",
    accountType: undefined,
    createAccount: false,
    requestIsRegisteredStatus: "idle",
    requestIsRegisteredError: null,
    requestSmsCodeStatus: "idle",
    requestSmsCodeError: null,
    requestValidateCodeStatus: "idle",
    requestValidateCodeError: null,
    requestValidateEmailStatus: "idle",
    requestValidateEmailError: null,
    requestSignUpStatus: "idle",
    requestSignUpError: null,
    requestSignInStatus: "idle",
    requestSignInError: null,
    requestLogOutStatus: "idle",
    requestLogOutError: null,
    requestRefreshTokenStatus: "idle",
    requestRefreshTokenError: null,
    requestChangePhoneCheckStatus: "idle",
    requestChangePhoneCheckError: null,
    requestChangePhoneStatus: "idle",
    requestChangePhoneError: null,
    requestValidatePasswordStatus: "idle",
    requestValidatePasswordError: null,
    requestChangePasswordStatus: "idle",
    requestChangePasswordError: null,
    requestAddMemberStatus: "idle",
    requestAddMemberError: null,
    InviteLoginStoredData: undefined,
    LengthStepsToComplete: null,
    isAdmin: null,
    requiresTwoFactor: false,
    stepsToCompleteData: [],
    adminBrandCode: "",
    azureUserInfo: null
};
const authSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        successRequestSignInAction: (state, action) => {
            state.requestSignInStatus = "success";
            state.requestSignInError = null;
            state.azureUserInfo = action.payload;
            const userNameAndSurname = action.payload.name?.split(" ");
            state.phoneNumber = action.payload.userPhoneNumber || undefined;
            state.user.email = action.payload.userEmail || undefined;
            state.user.firstName = userNameAndSurname?.[0] || "";
            state.user.lastName = userNameAndSurname?.[1] || "";
            state.password = action.payload.password || ""; // TODO: remove this, shouldn't store any longer than required
            state.createAccount = action.payload.createAccount;
        },
        hideSignInErrorAction: (state) => {
            state.requestSignInStatus = "idle";
            state.requestSignInError = null;
        },
        startRequestSignInAction: (state) => {
            state.requestSignInStatus = "pending";
            state.requestSignUpError = null;
        },
        showErrorSignInAction: (state, action) => {
            state.requestSignInStatus = "failure";
            state.requestSignInError = action.payload;
        },
        setPhoneNumberAction: (state, action) => {
            state.phoneNumber = action.payload;
        },
        setBrandAction: (state, action) => {
            state.brandId = action.payload;
        },
        setUserInfoAction: (state, action) => {
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
            state.user.email = action.payload.email;
            state.user.dateOfBirth = action.payload.dateOfBirth;
        },
        setPatientInfoAction: (state, action) => {
            state.patient.firstName = action.payload.firstName;
            state.patient.lastName = action.payload.lastName;
            state.patient.dateOfBirth = action.payload.dateOfBirth;
            state.patient.relationship = action.payload.relationship;
        },
        setProvinceAction: (state, action) => {
            state.provinceId = action.payload;
        },
        setAddionInfoAction: (state, action) => {
            if (action.payload.gender !== undefined)
                state.additionalInfo.gender = action.payload.gender;
            if (action.payload.doctorsName !== undefined)
                state.additionalInfo.doctorsName = action.payload.doctorsName;
            if (action.payload.din !== undefined)
                state.additionalInfo.din = action.payload.din;
            if (action.payload.medicalConditions !== undefined)
                state.additionalInfo.medicalConditions = action.payload.medicalConditions;
        },
        setPasswordAction: (state, action) => {
            state.password = action.payload;
        },
        setAccountTypeAction: (state, action) => {
            state.accountType = action.payload;
        },
        startRequestIsRegisteredAction: (state) => {
            state.requestIsRegisteredStatus = "pending";
            state.requestIsRegisteredError = null;
        },
        successRequestIsRegisteredAction: (state, action) => {
            state.phoneNumber = action.payload;
            state.requestIsRegisteredStatus = "success";
            state.requestIsRegisteredError = null;
        },
        showRequestIsRegisteredErrorAction: (state, action) => {
            state.requestIsRegisteredStatus = "failure";
            state.requestIsRegisteredError = action.payload;
        },
        resetRequestIsRegisteredStatusAction: (state) => {
            state.requestIsRegisteredStatus = "idle";
            state.requestIsRegisteredError = null;
        },
        startRequestSmsCodeAction: (state) => {
            state.requestSmsCodeStatus = "pending";
            state.requestSmsCodeError = null;
        },
        successRequestSmsCodeAction: (state) => {
            state.requestSmsCodeStatus = "success";
            state.requestSmsCodeError = null;
        },
        showRequestSmsCodeErrorAction: (state, action) => {
            state.requestSmsCodeStatus = "failure";
            state.requestSmsCodeError = action.payload;
        },
        resetRequestSmsCodeStatusAction: (state) => {
            state.requestSmsCodeStatus = "idle";
            state.requestSmsCodeError = null;
        },
        startRequestValidateCodeAction: (state) => {
            state.requestValidateCodeStatus = "pending";
            state.requestValidateCodeError = null;
        },
        successRequestValidateCodeAction: (state, action) => {
            state.verificationCode = action.payload;
            state.requestValidateCodeStatus = "success";
            state.requestValidateCodeError = null;
        },
        showRequestValidateCodeErrorAction: (state, action) => {
            state.requestValidateCodeStatus = "failure";
            state.requestValidateCodeError = action.payload;
        },
        resetRequestValidateCodeStatusAction: (state) => {
            state.requestValidateCodeStatus = "idle";
            state.requestValidateCodeError = null;
        },
        startRequestValidateEmailAction: (state) => {
            state.requestValidateEmailStatus = "pending";
            state.requestValidateEmailError = null;
        },
        successRequestValidateEmailAction: (state) => {
            state.requestValidateEmailStatus = "success";
            state.requestValidateEmailError = null;
        },
        showRequestValidateEmailErrorAction: (state, action) => {
            state.requestValidateEmailStatus = "failure";
            state.requestValidateEmailError = action.payload;
        },
        resetRequestValidateEmailAction: (state) => {
            state.requestValidateEmailStatus = "idle";
            state.requestValidateEmailError = null;
        },
        startRequestSignUpAction: (state) => {
            state.requestSignUpStatus = "pending";
            state.requestSignUpError = null;
        },
        successRequestSignUpAction: (state) => {
            state.requestSignUpStatus = "success";
            state.requestSignUpError = null;
            state.additionalInfo = initialState.additionalInfo;
            state.provinceId = "";
        },
        resetRequestSignInAction: (state) => {
            state.requestSignInStatus = "idle";
            state.requestSignInError = null;
        },
        showRequestSignUpErrorAction: (state, action) => {
            state.requestSignUpStatus = "failure";
            state.requestSignUpError = action.payload;
        },
        resetRequestSignUpStatusAction: (state) => {
            state.requestSignUpStatus = "idle";
            state.requestSignUpError = null;
        },
        successRequestLogOutAction: (state) => {
            // state = initialState;
            state.requestLogOutStatus = "success";
            state.requestLogOutError = null;
        },
        resetRequestLogOutAction: (state) => {
            state.requestLogOutStatus = "idle";
            state.requestLogOutError = null;
        },
        startRequestLogOutAction: (state) => {
            state.requestLogOutStatus = "pending";
            state.requestLogOutError = null;
        },
        showLogOutErrorAction: (state, action) => {
            state.requestLogOutStatus = "failure";
            state.requestLogOutError = action.payload;
        },
        successRequestRefreshTokenAction: (state) => {
            state.requestRefreshTokenStatus = "success";
            state.requestRefreshTokenError = null;
        },
        resetRequestRefreshTokenAction: (state) => {
            state.requestRefreshTokenStatus = "idle";
            state.requestRefreshTokenError = null;
        },
        startRequestRefreshTokenAction: (state) => {
            state.requestRefreshTokenStatus = "pending";
            state.requestRefreshTokenError = null;
        },
        showRefreshTokenErrorAction: (state, action) => {
            state.requestRefreshTokenStatus = "failure";
            state.requestRefreshTokenError = action.payload;
        },
        startRequestChangePhoneCheckAction: (state) => {
            state.requestChangePhoneCheckStatus = "pending";
            state.requestChangePhoneCheckError = null;
        },
        successRequestChangePhoneCheckAction: (state, action) => {
            state.requestChangePhoneCheckStatus = "success";
            state.requestChangePhoneCheckError = null;
            state.phoneNumber = action.payload.phoneNumber;
            state.password = action.payload.password;
        },
        showChangePhoneCheckErrorAction: (state, action) => {
            state.requestChangePhoneCheckStatus = "failure";
            state.requestChangePhoneCheckError = action.payload;
        },
        resetRequestChangePhoneCheckAction: (state) => {
            state.requestChangePhoneCheckStatus = "idle";
            state.requestChangePhoneCheckError = null;
        },
        startRequestChangePhoneAction: (state) => {
            state.requestChangePhoneStatus = "pending";
            state.requestChangePhoneError = null;
        },
        successRequestChangePhoneAction: (state) => {
            state.requestChangePhoneStatus = "success";
            state.requestChangePhoneError = null;
            state.phoneNumber = "";
        },
        showChangePhoneErrorAction: (state, action) => {
            state.requestChangePhoneStatus = "failure";
            state.requestChangePhoneError = action.payload;
        },
        resetRequestChangePhoneAction: (state) => {
            state.requestChangePhoneStatus = "idle";
            state.requestChangePhoneError = null;
        },
        startRequestValidatePasswordAction: (state) => {
            state.requestValidatePasswordStatus = "pending";
            state.requestValidatePasswordError = null;
        },
        successRequestValidatePasswordAction: (state, action) => {
            state.requestValidatePasswordStatus = "success";
            state.requestValidatePasswordError = null;
            state.password = action.payload;
        },
        showValidatePasswordErrorAction: (state, action) => {
            state.requestValidatePasswordStatus = "failure";
            state.requestValidatePasswordError = action.payload;
        },
        resetRequestValidatePasswordAction: (state) => {
            state.requestValidatePasswordStatus = "idle";
            state.requestValidatePasswordError = null;
        },
        startRequestChangePasswordAction: (state) => {
            state.requestChangePasswordStatus = "pending";
            state.requestChangePasswordError = null;
        },
        successRequestChangePasswordAction: (state) => {
            state.requestChangePasswordStatus = "success";
            state.requestChangePasswordError = null;
            state.password = "";
        },
        showChangePasswordErrorAction: (state, action) => {
            state.requestChangePasswordStatus = "failure";
            state.requestChangePasswordError = action.payload;
        },
        resetRequestChangePasswordAction: (state) => {
            state.requestChangePasswordStatus = "idle";
            state.requestChangePasswordError = null;
        },
        startRequestAddMemberAction: (state) => {
            state.requestAddMemberStatus = "pending";
            state.requestAddMemberError = null;
        },
        successRequestAddMemberAction: (state) => {
            state.requestAddMemberStatus = "success";
            state.requestAddMemberError = null;
            state.patient = initialState.patient;
            state.provinceId = initialState.provinceId;
            state.additionalInfo = initialState.additionalInfo;
        },
        showRequestAddMemberErrorAction: (state, action) => {
            state.requestAddMemberStatus = "failure";
            state.requestAddMemberError = action.payload;
        },
        resetRequestAddMemberAction: (state) => {
            state.requestAddMemberStatus = "idle";
            state.requestAddMemberError = null;
        },
        storeDataLoginResponseActions: (state, action) => {
            state.isAdmin = action.payload?.data?.value?.homePageInfo?.isAdmin; //get signin API admin response //correct // pass all places
            const tempObj = JSON.stringify(action.payload);
            localStorage.setItem("LoginAPIResponse", tempObj);
            state.InviteLoginStoredData = action.payload;
            state.adminBrandCode = action.payload?.data?.value?.selectedPatientInfo?.currentDrugBrandCode;
        },
        storeDataForStepToComplete: (state, action) => {
            const tempObj = JSON.stringify(action.payload);
            localStorage.setItem("storeStepsToCompleteData", tempObj);
            if (action.payload?.length > 0) {
                localStorage.setItem("LengthStepsToComplete", "true");
                state.LengthStepsToComplete = true;
            }
            else {
                localStorage.setItem("LengthStepsToComplete", "false");
                state.LengthStepsToComplete = false;
            }
        },
        setDataOnReloadPage: (state, action) => {
            state.InviteLoginStoredData = action.payload;
        },
        resetInviteLoginStoredData: (state) => {
            state.InviteLoginStoredData = null;
        },
        resetLengthStepsToComplete: (state) => {
            localStorage.setItem("LengthStepsToComplete", "false");
            state.LengthStepsToComplete = false;
        },
        setLengthStepsToComplete: (state, action) => {
            state.LengthStepsToComplete = action.payload;
        },
        setNeedsTwoFactorAction: (state, action) => {
            state.requestSignInStatus = "idle";
            state.requiresTwoFactor = action.payload;
        },
    }
});
export default authSlice;
