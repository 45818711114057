import React, { useEffect, useState } from "react";
import si from "../../../../../Pages/SignUp/Steps/InvitationEmail/style.scss";
import s from "./style.scss";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@material-ui/core";
import hand from "../../../../../resources/images/hand.png";
import medicine from "../../../../../resources/images/medicine.png";
import { Button as CustomButton, } from "../../../../../components/Shared/Controls";
import classNames from "classnames";
import Typography from "../../../../../components/Shared/Typography";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { postPatientPharmacyAsyncAction, getPatientPharmacyAsyncAction, putPatientPharmacyAsyncAction } from "../../../../../store/pharmacy/Sagas";
const SelectPharmacy = ({ patientId, setStep, nextStep, previousStep, isNewSignUp, isDashboard, getPharmacyData, getPharmacyAction, updatePatientPharmacyAction, createPatientPharmacyAction, resetUpdatePatientPharmacyAction, resetCreatePatientPharmacyAction, requestUpdatePatientPharmacyStatus, requestCreatePatientPharmacyStatus, drugBrand }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedPage, setSelectedPage] = useState("");
    const [skipStep, setSkipStep] = useState();
    const handlePageEvent = (val) => {
        setSelectedPage(val);
    };
    const storeStepsToCompleteData = localStorage.getItem('storeStepsToCompleteData');
    useEffect(() => {
        const stepsToCompleteData = JSON.parse(storeStepsToCompleteData);
        if (stepsToCompleteData.length > 0) {
            const DataSkipStep = stepsToCompleteData;
            for (let data of DataSkipStep) {
                if (data?.step == "Pharmacy" && data?.mandatory == true) {
                    setSkipStep(true);
                }
                else {
                    setSkipStep(false);
                }
            }
        }
    }, [storeStepsToCompleteData]);
    useEffect(() => {
        console.log(selectedPage);
        if (selectedPage == "Use") {
            if (isNewSignUp) {
                setStep?.({ step: "PharmacyList", mandatory: false });
            }
            if (isDashboard) {
                navigate('/pharmacy/add-pharmacy');
            }
        }
        if (selectedPage == "Select") {
            if (isNewSignUp) {
                setStep?.({ step: "SpecialtyPharmacyAdded", mandatory: false });
            }
            else {
                navigate('/pharmacy/added-pharmacy');
            }
        }
    }, [selectedPage]);
    useEffect(() => {
        getPharmacyAction({ patientId });
    }, []);
    useEffect(() => {
        if (requestCreatePatientPharmacyStatus == "success") {
            handlePageEvent("Select");
        }
    }, [requestCreatePatientPharmacyStatus]);
    useEffect(() => {
        if (requestUpdatePatientPharmacyStatus == "success") {
            handlePageEvent("Select");
        }
    }, [requestUpdatePatientPharmacyStatus]);
    const addSpecialtyPharmacy = () => {
        if (getPharmacyData?.length > 0 && isDashboard) {
            updatePatientPharmacyAction({
                id: getPharmacyData?.[0]?.patientBrandPharmacyId,
                name: "Specialty Pharmacy Network",
                address: "N/A",
                city: "N/A",
                country: "CA",
                postal: "N/A",
                phone: "",
                note: "Specialty Pharmacy Network",
                brandIdentifier: "SPN",
                latitude: "0",
                longitude: "0"
            });
        }
        else {
            createPatientPharmacyAction({
                name: "Specialty Pharmacy Network",
                address: "N/A",
                city: "N/A",
                country: "CA",
                postal: "N/A",
                phone: "",
                note: "",
                brandIdentifier: "SPN",
                latitude: "0",
                longitude: "0"
            });
        }
    };
    const NavigateFunction = () => {
        if (isDashboard) {
            navigate('/pharmacy');
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(si.contentWrapper) },
            React.createElement("div", { className: classNames(si.scrollWrapperfrom) },
                React.createElement("div", { className: classNames(si.formstep, si.formWrapper) },
                    React.createElement("div", { className: classNames(si.formstepbar) },
                        React.createElement("div", { className: (si.stepToptext) },
                            React.createElement("div", { className: s.header },
                                React.createElement("div", { className: s.pages }, isNewSignUp
                                    ? React.createElement(Typography, { type: 'subtitle_2', color: 'neutralGray' }, `12/15`)
                                    : null)),
                            React.createElement("h4", null, t("addPharmacy")),
                            React.createElement("p", null, t("addPharmacyMessage")),
                            React.createElement("h4", null, t("whatAreTheBenefits")),
                            React.createElement("p", null, t("benefitsMsg").replace(/<insert drug name>/g, t(drugBrand)))),
                        React.createElement(Grid, { container: true, spacing: 2 },
                            React.createElement(Grid, { item: true, xs: 12, md: 6, className: classNames(si.ptpb0, s.boxh) },
                                React.createElement("div", { className: s.profileCardWrapper },
                                    React.createElement(Button, { disableRipple: true, onClick: () => { addSpecialtyPharmacy(); } },
                                        React.createElement("img", { src: hand }),
                                        React.createElement("p", null, t("iWantSelectPharmacy"))))),
                            React.createElement(Grid, { item: true, xs: 12, md: 6, className: classNames(si.ptpb0, s.boxh) },
                                React.createElement("div", { className: s.profileCardWrapper },
                                    React.createElement(Button, { disableRipple: true, onClick: () => { handlePageEvent("Use"); } },
                                        React.createElement("img", { src: medicine }),
                                        React.createElement("p", null, t("iWantUsePharmacy")))))),
                        React.createElement("div", null, isNewSignUp && !skipStep ?
                            React.createElement("div", { className: classNames(si.textCenter, si.mt20) },
                                React.createElement("h3", { onClick: () => nextStep?.(true), className: si.linktwo },
                                    " ",
                                    t("Skipthisstep"),
                                    " ")) : null),
                        React.createElement("div", { className: si.buttonTwopage },
                            React.createElement(CustomButton, { onClick: isNewSignUp ? previousStep : NavigateFunction, text: t("back") }))))))));
};
const mapStateToProps = (state) => {
    return {
        drugBrand: state.account.user.drugBrandCode,
        patientId: state.account.user.patientId ?? "",
        getPharmacyData: state.pharmacy.getPharmacyData,
        requestUpdatePatientPharmacyStatus: state.pharmacy.requestPutPharmacyStatus,
        requestCreatePatientPharmacyStatus: state.pharmacy.requestPharmacyPostStatus
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getPharmacyAction: (data) => dispatch(getPatientPharmacyAsyncAction(data)),
        createPatientPharmacyAction: (data) => dispatch(postPatientPharmacyAsyncAction(data)),
        updatePatientPharmacyAction: (data) => dispatch(putPatientPharmacyAsyncAction(data))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectPharmacy);
