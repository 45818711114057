import React from 'react';
import { connect } from 'react-redux';
import LoadingPopUp from '../Shared/LoadingPopUp';
import { useErrorPopUp } from '../../hooks';
import ErrorPopUp from '../Shared/Info/ErrorPopUp';
import { hideErrorAction } from '../../store/error';
const StatusDisplay = ({ requestBrandConfigStatus, requestBrandsStatus, 
//account
requestHomeStatus, requestRegistrationInfoStatus, requestSettingsInfoStatus, requestPatientInfoStatus, requestUpdatePatientInfoStatus, requestPatientBrandsStatus, requestAssignToBrandStatus, requestUpdateEmailStatus, requestUpdatePrescriberNameStatus, requestEnrollByOwnerStatus, requestPatientHealthInformationStatus, requestUpdateHealthInformationStatus, 
//address
requestGetAddressesStatus, requestAddAddressStatus, requestEditAddressStatus, requestDeleteAddressStatus, requestAddProvinceToAddressStatus, 
// resetAddress,
//auth
requestIsRegisteredStatus, requestSmsCodeStatus, requestValidateCodeStatus, requestValidateEmailStatus, requestSignUpStatus, requestSignInStatus, requestLogOutStatus, requestChangePhoneCheckStatus, requestChangePhoneStatus, requestValidatePasswordStatus, requestChangePasswordStatus, requestAddMemberStatus, 
//education
requestEducationStatus, 
//forgotPassword
requestSmsCodeForResetPassStatus, requestValidateSmsCodeStatus, requestResetPassStatus, 
//medicalConditions
requestMedicalConditions, 
//navigations
requestProfileTabsStatus, 
//notifications
requestNotificationsStatus, requestNotificationsMarkViewedStatus, 
//patientFiles
requestPatientFilesStatus, requestScheduleCallStatus, requestGetFilesStatus, requestDeleteFilesStatus, requestUpdateFilesStatus, requestGetTimesStatus, requestGetFinancialConfigStatus, requestGetFinancialAssistanceStatus, requestSendFinancialAssistanceStatus, requestGetPreviewPhotoStatus, requestInsurancesUpdateStatus, requestHealthCardUpdateStatus, requestGetInsurancesFilesStatus, requestGetHealthCardFilesStatus, 
//province
requestProvinces, 
//chat
requestChatStatus, 
//medicationReminders
requestGetRemindersStatus, requestAddReminderStatus, requestEditReminderStatus, requestDeleteReminderStatus, error, hideError, title, errorButtons, }) => {
    const isLoading = [
        requestBrandConfigStatus,
        requestBrandsStatus,
        requestRegistrationInfoStatus,
        requestHomeStatus,
        requestSettingsInfoStatus,
        requestPatientInfoStatus,
        requestUpdatePatientInfoStatus,
        requestPatientBrandsStatus,
        requestAssignToBrandStatus,
        requestUpdateEmailStatus,
        requestUpdatePrescriberNameStatus,
        requestEnrollByOwnerStatus,
        requestPatientHealthInformationStatus,
        requestUpdateHealthInformationStatus,
        requestGetAddressesStatus,
        requestAddAddressStatus,
        requestEditAddressStatus,
        requestDeleteAddressStatus,
        requestAddProvinceToAddressStatus,
        requestIsRegisteredStatus,
        requestSmsCodeStatus,
        requestValidateCodeStatus,
        requestValidateEmailStatus,
        requestSignUpStatus,
        requestSignInStatus,
        requestLogOutStatus,
        requestChangePhoneCheckStatus,
        requestChangePhoneStatus,
        requestValidatePasswordStatus,
        requestChangePasswordStatus,
        requestAddMemberStatus,
        //education
        requestEducationStatus,
        //forgotPassword
        requestSmsCodeForResetPassStatus,
        requestValidateSmsCodeStatus,
        requestResetPassStatus,
        //medicalConditions
        requestMedicalConditions,
        //navigations
        // requestProfileTabsStatus,
        //notifications
        requestNotificationsStatus,
        requestNotificationsMarkViewedStatus,
        //patientFiles
        requestPatientFilesStatus,
        requestScheduleCallStatus,
        requestGetFilesStatus,
        requestDeleteFilesStatus,
        requestUpdateFilesStatus,
        requestGetTimesStatus,
        requestGetFinancialConfigStatus,
        requestGetFinancialAssistanceStatus,
        // requestSendFinancialAssistanceStatus,
        requestGetPreviewPhotoStatus,
        requestInsurancesUpdateStatus,
        requestHealthCardUpdateStatus,
        requestGetInsurancesFilesStatus,
        requestGetHealthCardFilesStatus,
        //province
        requestProvinces,
        //chat
        requestChatStatus,
        requestGetRemindersStatus,
        requestAddReminderStatus,
        requestEditReminderStatus,
        requestDeleteReminderStatus,
    ].some(status => status === "pending");
    const [errorIsShowing, , errorContent, , , , hidePopUp] = useErrorPopUp(error);
    const hidePopUpWithClearingStatuses = () => {
        hideError();
        hidePopUp();
    };
    return (React.createElement("div", null,
        React.createElement(LoadingPopUp, { isOpen: isLoading }),
        React.createElement(ErrorPopUp, { buttons: errorButtons, title: title, isOpen: errorIsShowing, popUpContent: errorContent, togglePopUp: hidePopUpWithClearingStatuses })));
};
const mapStateToProps = (state) => {
    return {
        //brands
        requestBrandConfigStatus: state.brands.requestStatus,
        requestBrandsStatus: state.brands.requestGetBrandsStatus,
        //account
        requestRegistrationInfoStatus: state.account.requestRegistrationInfoStatus,
        requestHomeStatus: state.account.requestHomeStatus,
        requestSettingsInfoStatus: state.account.requestSettingsInfoStatus,
        requestPatientInfoStatus: state.account.requestPatientInfoStatus,
        requestUpdatePatientInfoStatus: state.account.requestUpdatePatientInfoStatus,
        requestPatientBrandsStatus: state.account.requestPatientBrandsStatus,
        requestAssignToBrandStatus: state.account.requestAssignToBrandStatus,
        requestUpdateEmailStatus: state.account.requestUpdateEmailStatus,
        requestUpdatePrescriberNameStatus: state.account.requestUpdateBrandEnrollmentStatus,
        requestEnrollByOwnerStatus: state.account.requestEnrollByOwnerStatus,
        requestPatientHealthInformationStatus: state.account.requestPatientHealthInformationStatus,
        requestUpdateHealthInformationStatus: state.account.requestUpdateHealthInformationStatus,
        //address
        requestGetAddressesStatus: state.patientAddress.requestGetAddressesStatus,
        requestAddAddressStatus: state.patientAddress.requestAddAddressStatus,
        requestEditAddressStatus: state.patientAddress.requestEditAddressStatus,
        requestDeleteAddressStatus: state.patientAddress.requestDeleteAddressStatus,
        requestAddProvinceToAddressStatus: state.patientAddress.requestAddProvinceToAddressStatus,
        //auth
        requestIsRegisteredStatus: state.auth.requestIsRegisteredStatus,
        requestSmsCodeStatus: state.auth.requestSmsCodeStatus,
        requestValidateCodeStatus: state.auth.requestValidateCodeStatus,
        requestValidateEmailStatus: state.auth.requestValidateEmailStatus,
        requestSignUpStatus: state.auth.requestSignUpStatus,
        requestSignInStatus: state.auth.requestSignInStatus,
        requestLogOutStatus: state.auth.requestLogOutStatus,
        requestChangePhoneCheckStatus: state.auth.requestChangePhoneCheckStatus,
        requestChangePhoneStatus: state.auth.requestChangePhoneStatus,
        requestValidatePasswordStatus: state.auth.requestValidatePasswordStatus,
        requestChangePasswordStatus: state.auth.requestChangePasswordStatus,
        requestAddMemberStatus: state.auth.requestAddMemberStatus,
        //education
        requestEducationStatus: state.patientEducation.requestStatus,
        //forgotPassword
        requestSmsCodeForResetPassStatus: state.forgotPassword.requestSmsCodeForResetPassStatus,
        requestValidateSmsCodeStatus: state.forgotPassword.requestValidateSmsCodeStatus,
        requestResetPassStatus: state.forgotPassword.requestResetPassStatus,
        //medicalConditions
        requestMedicalConditions: state.medicalConditions.requestStatus,
        //navigations
        requestProfileTabsStatus: state.navigation.requestProfileTabsStatus,
        //notifications
        requestNotificationsStatus: state.notifications.requestStatus,
        requestNotificationsMarkViewedStatus: state.notifications.requestMarkViewedStatus,
        //patientFiles
        requestPatientFilesStatus: state.patientFiles.requestStatus,
        requestScheduleCallStatus: state.patientFiles.requestScheduleCallStatus,
        requestGetFilesStatus: state.patientFiles.requestGetFilesStatus,
        requestDeleteFilesStatus: state.patientFiles.requestDeleteFilesStatus,
        requestUpdateFilesStatus: state.patientFiles.requestUpdateFilesStatus,
        requestGetTimesStatus: state.patientFiles.requestGetTimesStatus,
        requestGetFinancialConfigStatus: state.patientFiles.requestGetFinancialConfigStatus,
        requestGetFinancialAssistanceStatus: state.patientFiles.requestGetFinancialAssistanceStatus,
        requestSendFinancialAssistanceStatus: state.patientFiles.requestSendFinancialAssistanceStatus,
        requestGetPreviewPhotoStatus: state.patientFiles.requestGetPreviewPhotoStatus,
        requestInsurancesUpdateStatus: state.patientFiles.requestInsurancesUpdateStatus,
        requestHealthCardUpdateStatus: state.patientFiles.requestHealthCardUpdateStatus,
        requestGetInsurancesFilesStatus: state.patientFiles.requestGetInsurancesFilesStatus,
        requestGetHealthCardFilesStatus: state.patientFiles.requestGetHealthCardFilesStatus,
        //province
        requestProvinces: state.provinces.requestStatus,
        //chat
        requestChatStatus: state.chat.requestStatus,
        //medicationReminders
        requestGetRemindersStatus: state.reminders.requestGetRemindersStatus,
        requestAddReminderStatus: state.reminders.requestAddReminderStatus,
        requestEditReminderStatus: state.reminders.requestEditReminderStatus,
        requestDeleteReminderStatus: state.reminders.requestDeleteReminderStatus,
        error: state.userError.requestError,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        hideError: () => {
            dispatch(hideErrorAction());
        }
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(StatusDisplay);
