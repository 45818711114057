import React from 'react';
import s from './style.scss';
// import dinEn from "../../../../resources/images/din-en.png"
// import dinFr from "../../../../resources/images/din-fr.png"
import { useTranslation, getI18n } from 'react-i18next';
const DinInfoContent = ({ style }) => {
    const { t } = useTranslation();
    const locals = getI18n();
    return (React.createElement("div", { className: s.dinInfo },
        React.createElement("div", { className: s.dinInfo__image_row }),
        React.createElement("div", { className: s.subheading_1 }, t("youDIN")),
        React.createElement("div", null, t("dinHelp").replace("BRAND_NAME", ""))));
};
export default DinInfoContent;
