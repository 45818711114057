import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Prescriptions from "../../../../../../../../components/MedicalDocuments/Prescriptions";
import { Button } from "../../../../../../../../components/Shared/Controls";
import { PopUp } from "../../../../../../../../components/Shared/Info";
import { useDeviceHeight } from "../../../../../../../../hooks";
import s from "./style.scss";
const Step2 = ({ nextPage, brand, page }) => {
    const { t } = useTranslation();
    useDeviceHeight("prescriptionsScroll", 460, 460, 350);
    const [haveChanges, setHaveChanges] = useState(false);
    const [youAreSureModal, setYouAreSureModal] = useState(false);
    const toggleYouAreSureModal = () => setYouAreSureModal(!youAreSureModal);
    const renderYouAreSureModal = () => {
        return (React.createElement(PopUp, { style: s.removeModal, isOpen: youAreSureModal, toggle: toggleYouAreSureModal },
            React.createElement("div", { className: classNames(s.subheading_1, s.mb16) }, t("sureExitWithoutSaving")),
            React.createElement("div", { className: s.actions },
                React.createElement(Button, { style: s.button, text: t("yes"), primary: true, onClick: () => {
                        nextPage();
                    } }),
                React.createElement(Button, { style: s.button, text: t("no"), onClick: toggleYouAreSureModal }))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.title },
            t("addPrescription"),
            React.createElement("span", null, `${page} / 5`)),
        React.createElement("div", { className: s.prescriptionsWrapper },
            React.createElement(Prescriptions, { setHaveChanges: setHaveChanges, skipAction: (isSubmit) => {
                    !isSubmit && haveChanges ? toggleYouAreSureModal() : nextPage();
                } },
                renderYouAreSureModal(),
                React.createElement("div", { className: s.paragraph_2 }, `${t("ifYouHaveCopy")} ${brand}${t("pleaseUploadIt")} `)))));
};
const mapStateToProps = (state) => {
    return {
        brand: state.auth.brandId,
    };
};
export default connect(mapStateToProps)(Step2);
