import React from "react";
const HelpIcon = ({ color1, color2, className }) => {
    return (React.createElement("svg", { className: className, "data-test-id": "loadingSpinner", width: "64", height: "64", viewBox: "0 0 64 64", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("ellipse", { cx: "32.0011", cy: "32.0001", rx: "28.5714", ry: "28.5714", stroke: "#EFF1F3", strokeWidth: "4" }),
        React.createElement("path", { d: "M32 3.42871C47.7796 3.42871 60.5714 16.2206 60.5714 32.0001C60.5714 40.9304 56.4744 48.9037 50.0574 54.143", stroke: "url(#paint0_linear)", strokeWidth: "4", strokeLinecap: "round" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear", x1: "32", y1: "-0.499861", x2: "32", y2: "62.0001", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: color1 }),
                React.createElement("stop", { offset: "0.990781", stopColor: color2 })))));
};
export default HelpIcon;
