import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "INVITE";
const initialState = {
    requestInviteValidatePostStatus: "idle",
    requestInviteValidatePostError: null,
    requestInviteChangePhoneNumberPostStatus: "idle",
    requestInviteChangePhoneNumberPostError: null,
    requestInvitePhoneNumberVarificationPostStatus: "idle",
    requestInvitePhoneNumberVarificationPostError: null,
    requestInviteChangePasswordPostStatus: "idle",
    requestInviteChangePasswordPostError: null,
    requestInviteAddressUpdatePutStatus: "idle",
    requestInviteAddressUpdatePutError: null,
    responseOfInviteValidate: false,
    responseOfInviteChangePhoneNumber: false,
    responseOfInvitePhoneNumberVarification: null,
    setInfo: undefined,
    userNameAndDOB: undefined,
    mobileNumber: undefined,
    varificationCode: undefined,
    passwords: undefined,
    HoldPatientDetails: undefined,
    holdMedicalConditionData: undefined,
    holdAddressData: undefined,
    requestCallPreferenceGetStatus: "idle",
    requestCallPreferenceGetError: null,
    requestCallPreferencePostStatus: "idle",
    requestCallPreferencePostError: null,
    responseOfInvitePhoneNumber: null,
    errorGetCallPreferences: null,
    getCallPreferencesStatus: 'idle',
    callPreferences: null
};
const InviteSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        showGetCallPreferencesErrorAction: (state, action) => {
            state.errorGetCallPreferences = action.payload;
            state.getCallPreferencesStatus = "failure";
        },
        getCallPreferencesAction: (state) => {
            state.requestCallPreferenceGetStatus = "pending";
        },
        setCallPreferencesAction: (state, action) => {
            state.callPreferences = action.payload;
            state.requestCallPreferenceGetStatus = "success";
        },
        resetCallPreferencesAction: (state, action) => {
            state.callPreferences = action.payload;
            state.requestCallPreferenceGetStatus = "idle";
        },
        setResponseOfInviteValidateFalse: (state) => {
            state.requestInviteValidatePostStatus = "idle";
        },
        setResponseOfInviteChangePhoneNumberFalse: (state) => {
            state.requestInviteChangePhoneNumberPostStatus = "idle";
        },
        resetphoneNumberVarificationAction: (state) => {
            state.requestInvitePhoneNumberVarificationPostStatus = "idle";
        },
        resetChangePasswordAction: (state) => {
            state.requestInviteChangePasswordPostStatus = "idle";
        },
        setUserNameAndDOB: (state, action) => {
            state.userNameAndDOB = action.payload;
        },
        setMobileNumber: (state, action) => {
            state.mobileNumber = action.payload;
        },
        setVarificationCode: (state, action) => {
            state.varificationCode = action.payload;
        },
        setPasswords: (state, action) => {
            state.passwords = action.payload;
        },
        startCreateInviteValidateInfoAsyncAction: (state) => {
            state.responseOfInviteValidate = false;
            state.requestInviteValidatePostStatus = "pending";
            state.requestInviteValidatePostError = null;
        },
        succesCreateInviteValidateInfoAsyncAction: (state, action) => {
            if (action.payload) {
                state.setInfo = action.payload.data;
            }
            state.requestInviteValidatePostStatus = "success";
            state.requestInviteValidatePostError = null;
        },
        showcreateInviteValidateInfoErrorAction: (state, action) => {
            state.responseOfInviteValidate = false;
            state.requestInviteValidatePostStatus = "failure";
            state.requestInviteValidatePostError = action.payload;
        },
        successResponseInviteValidate: (state, action) => {
            state.responseOfInvitePhoneNumber = action.payload?.data?.value?.phoneNumber;
        },
        startCreateInviteChangePhoneNumberRequestInfoAsyncAction: (state) => {
            state.responseOfInviteValidate = false;
            state.requestInviteChangePhoneNumberPostStatus = "pending";
            state.requestInviteChangePhoneNumberPostError = null;
        },
        succesCreateInviteChangePhoneNumberRequestInfoAsyncAction: (state, action) => {
            if (action.payload) {
                state.setInfo = action.payload.data;
            }
            state.requestInviteChangePhoneNumberPostStatus = "success";
            state.requestInviteChangePhoneNumberPostError = null;
        },
        showcreateInviteChangePhoneNumberRequestInfoErrorAction: (state, action) => {
            state.responseOfInviteValidate = false;
            state.requestInviteChangePhoneNumberPostStatus = "failure";
            state.requestInviteChangePhoneNumberPostError = action.payload;
        },
        startCreateInvitePhoneNumberVarificationInfoAsyncAction: (state) => {
            state.requestInvitePhoneNumberVarificationPostStatus = "pending";
            state.requestInvitePhoneNumberVarificationPostError = null;
        },
        succesCreateInvitePhoneNumberVarificationInfoAsyncAction: (state, action) => {
            state.requestInvitePhoneNumberVarificationPostStatus = "success";
            state.requestInvitePhoneNumberVarificationPostError = null;
        },
        showcreateInvitePhoneNumberVarificationInfoErrorAction: (state, action) => {
            state.requestInvitePhoneNumberVarificationPostStatus = "failure";
            state.requestInvitePhoneNumberVarificationPostError = action.payload;
        },
        startCreateInviteChangePasswordInfoAsyncAction: (state) => {
            state.requestInviteChangePasswordPostStatus = "pending";
            state.requestInviteChangePasswordPostError = null;
        },
        succesCreateInviteChangePasswordInfoAsyncAction: (state, action) => {
            state.requestInviteChangePasswordPostStatus = "success";
            state.requestInviteChangePasswordPostError = null;
        },
        showcreateInviteChangePasswordInfoErrorAction: (state, action) => {
            state.requestInviteChangePasswordPostStatus = "failure";
            state.requestInviteChangePasswordPostError = action.payload;
        },
        setPatientDetails: (state, action) => {
            state.HoldPatientDetails = action.payload;
        },
        holdMedicalConditionAction: (state, action) => {
            state.holdMedicalConditionData = action.payload;
        },
        holdDataAddressAction: (state, action) => {
            state.holdAddressData = action.payload;
        },
        startCreateCallPreferenceAsyncAction: (state) => {
            state.requestCallPreferencePostStatus = "pending";
            state.requestCallPreferencePostError = null;
        },
        succesCreateCallPreferenceAsyncAction: (state, action) => {
            state.requestCallPreferencePostStatus = "success";
            state.requestCallPreferencePostError = null;
        },
        showcreateCallPreferenceErrorAction: (state, action) => {
            state.requestCallPreferencePostStatus = "failure";
            state.requestCallPreferencePostError = action.payload;
        },
        resetCallPreferenceAction: (state) => {
            state.requestCallPreferencePostStatus = "idle";
        },
    }
});
export default InviteSlice;
