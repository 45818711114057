import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { EducationRepository } from "../repository";
import { handleApiError } from "../utils";
import educationSlice, { SLICE_NAME } from "./Slice";
import { mapEducationItems } from "./Mapper";
import { getEducationAsyncAction } from ".";
export function* getEducationItemsSaga(action) {
    const { startGetEducationItemsAction, successGetEducationItemsAction, showGetEducationItemsErrorAction, setEducationItemsAction, setTagsAction } = educationSlice.actions;
    try {
        yield put(startGetEducationItemsAction());
        const result = yield call(EducationRepository.getEducationItems, action.payload);
        const items = yield mapEducationItems(result.data);
        yield put(setEducationItemsAction(items));
        yield put(successGetEducationItemsAction());
    }
    catch (error) {
        yield handleApiError(error, showGetEducationItemsErrorAction);
    }
}
export const createEducationInfoAsyncAction = createAction(`${SLICE_NAME}/createEducationInfoAsyncAction`);
export function* createPrescriberInfoSaga(action) {
    const { startCreateEducationInfoAsyncAction, succesCreateEducationInfoAsyncAction, showcreateEducationInfoErrorAction } = educationSlice.actions;
    try {
        yield put(startCreateEducationInfoAsyncAction());
        yield call(EducationRepository.postEducation, action.payload);
        yield put(succesCreateEducationInfoAsyncAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showcreateEducationInfoErrorAction);
    }
}
export const updateEducationInfoAsyncAction = createAction(`${SLICE_NAME}/updateEducationInfoAsyncAction`);
export function* updateEducationInfoSaga(action) {
    const { startRequestUpdateEducationInfoAction, successRequestUpdateEducationInfoAction, showRequestUpdateEducationInfoErrorAction } = educationSlice.actions;
    try {
        yield put(startRequestUpdateEducationInfoAction());
        yield call(EducationRepository.updateEducationInfo, action.payload);
        yield put(successRequestUpdateEducationInfoAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdateEducationInfoErrorAction);
    }
}
export const getTagsAsyncAction = createAction(`${SLICE_NAME}/getTagsAsyncAction`);
function* getTagsSaga(action) {
    const { startTagsRequestAction, showTagsErrorAction, successTagsRequestAction } = educationSlice.actions;
    try {
        yield put(startTagsRequestAction());
        const result = yield call(EducationRepository.getTags, action.payload);
        // const conditions: Array<MedicalConditionView> = yield mapConditions(result.data);
        yield put(successTagsRequestAction(result.data));
    }
    catch (error) {
        yield handleApiError(error, showTagsErrorAction);
    }
}
//delete
export const deleteEducationDataAsyncAction = createAction(`${SLICE_NAME}/deleteEducationDataAsyncAction`);
export function* deleteEducationDataSaga(action) {
    const { startRequestDeleteEducationDataAction, deleteEducationDataAction, showRequestDeleteEducationDataErrorAction, } = educationSlice.actions;
    try {
        yield put(startRequestDeleteEducationDataAction());
        yield call(EducationRepository.deleteEducationData, action.payload.educationId);
        yield put(deleteEducationDataAction(action.payload.educationId));
    }
    catch (error) {
        yield handleApiError(error, showRequestDeleteEducationDataErrorAction);
    }
}
export default function* rootSaga() {
    yield takeEvery(getEducationAsyncAction, getEducationItemsSaga);
    yield takeEvery(updateEducationInfoAsyncAction, updateEducationInfoSaga);
    yield takeEvery(deleteEducationDataAsyncAction, deleteEducationDataSaga);
    yield takeEvery(createEducationInfoAsyncAction, createPrescriberInfoSaga);
    yield takeEvery(getTagsAsyncAction, getTagsSaga);
}
