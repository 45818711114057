import { createAction } from "@reduxjs/toolkit";
import { put, call, select, takeLatest } from "redux-saga/effects";
import { handleApiError } from "../utils";
import accountSlice, { SLICE_NAME } from "./Slice";
import AccountRepository from "../repository/AccountRepository";
import { mapAccountInfo, mapEmail, mapPatientPersonalInfo, mapPatients, mapPatientBrands, mapPatientHealthInformation, } from "./Mapper";
import navigationSlice from "../navigation/Slice";
import { getMemberInfoSelector, userInfoSelector } from "./selectors";
import { mapSteps } from "../navigation/Mapper";
import _ from "lodash";
import { getBrandByIdSelector } from ".";
import { getProfileTabsSaga } from "../navigation";
import config from '../../../config.json';
export const getHomeAsyncAction = createAction(`${SLICE_NAME}/getHomeAsyncAction`);
export function* getHomeSaga() {
    const { startRequestHomeAction, successRequestHomeAction, showErrorRequestHomeAction, isAdminAction, checkAdminStatus } = accountSlice.actions;
    try {
        yield put(startRequestHomeAction());
        const { impersonatedAccountId } = yield select(userInfoSelector);
        const result = yield call(AccountRepository.getHome, impersonatedAccountId);
        const isAdmin = result.data.value.isAdmin;
        const mapped = yield mapPatients(result.data);
        yield put(isAdminAction(isAdmin));
        yield put(checkAdminStatus()); //reload issue
        yield put(successRequestHomeAction(mapped));
    }
    catch (error) {
        yield handleApiError(error, showErrorRequestHomeAction);
    }
}
export const updateIsAdminAction = createAction(`${SLICE_NAME}/updateIsAdminAction`);
export function* updateIsAdminSaga(action) {
    const { isAdminAction } = accountSlice.actions;
    yield put(isAdminAction(action.payload));
}
export const setPatientdataAction = createAction(`${SLICE_NAME}/setPatientdataAction`);
export function* setPatientdataActionSaga(action) {
    const { setPatientData } = accountSlice.actions;
    yield put(setPatientData(action.payload?.data));
}
export const getRegistrationInfoAsyncAction = createAction(`${SLICE_NAME}/getRegistrationInfoAsyncAction`);
export function* getRegistrationInfoSaga(action) {
    const { setStepsAction } = navigationSlice.actions;
    const { startRequestRegistrationInfoAction, successRequestRegistrationInfoAction, showRequestRegistrationInfoErrorAction, setUserAction, } = accountSlice.actions;
    const { drugBrandCode, patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestRegistrationInfoAction());
        let result = yield call(AccountRepository.getRegistrationInfo, {
            patientId: _.isNil(action.payload.member?.patientId)
                ? patientId
                : action.payload.member?.patientId,
            drugBrandCode: _.isNil(action.payload.member?.drugBrandCode)
                ? drugBrandCode
                : action.payload.member?.drugBrandCode,
        });
        let steps = yield mapSteps(result.data);
        // steps = steps.filter((step,index)=>{
        //   return step.mandatory ? true : config.additional_steps[index]?.show;
        // })  backend does the filtering now
        const accountInfo = yield mapAccountInfo(result.data);
        yield put(setStepsAction({
            steps,
            isRegistrationFlow: action.payload.isRegistrationFlow,
        }));
        yield put(setUserAction(accountInfo));
        if (!accountInfo.registrationUnfinished) {
            yield getProfileTabsSaga({
                payload: action.payload.member?.drugBrandCode,
                type: "NAVIGATION/getProfileTabsAsyncAction"
            });
        }
        yield put(successRequestRegistrationInfoAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestRegistrationInfoErrorAction);
    }
}
export const getSettingsInfoAsyncAction = createAction(`${SLICE_NAME}/getSettingsInfoAsyncAction`);
export function* getSettingsInfoSaga() {
    const { startRequestSettingsInfoAction, successRequestSettingsInfoAction, showRequestSettingsInfoErrorAction, } = accountSlice.actions;
    try {
        yield put(startRequestSettingsInfoAction());
        const { impersonatedAccountId } = yield select(userInfoSelector);
        let result = yield call(AccountRepository.getSettingsInfo, impersonatedAccountId);
        const email = yield mapEmail(result.data);
        yield put(successRequestSettingsInfoAction(email));
    }
    catch (error) {
        yield handleApiError(error, showRequestSettingsInfoErrorAction);
    }
}
export const getPatientInfoAsyncAction = createAction(`${SLICE_NAME}/getPatientInfoAsyncAction`);
export function* getPatientInfoSaga() {
    const { startRequestPatientInfoAction, successRequestPatientInfoAction, showRequestPatientInfoErrorAction, } = accountSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestPatientInfoAction());
        let result = yield call(AccountRepository.getPatientInfo, patientId);
        const patient = yield mapPatientPersonalInfo(result.data);
        yield put(successRequestPatientInfoAction(patient));
    }
    catch (error) {
        yield handleApiError(error, showRequestPatientInfoErrorAction);
    }
}
//Single record get
export const getSinglePatientInfoAsyncAction = createAction(`${SLICE_NAME}/getSinglePatientInfoAsyncAction`);
export function* getSinglePatientInfoSaga() {
    const { startRequestSinglePatientInfoAction, successRequestSinglePatientInfoAction, showRequestSinglePatientInfoErrorAction, } = accountSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestSinglePatientInfoAction());
        let result = yield call(AccountRepository.getSinglePatientInfo, patientId);
        const patient = yield mapPatientPersonalInfo(result.data);
        yield put(successRequestSinglePatientInfoAction(patient));
    }
    catch (error) {
        yield handleApiError(error, showRequestSinglePatientInfoErrorAction);
    }
}
//update
export const updatePatientInfoAsyncAction = createAction(`${SLICE_NAME}/updatePatientInfoAsyncAction`);
export function* updatePatientInfoSaga(action) {
    const { startRequestUpdatePatientInfoAction, successRequestUpdatePatientInfoAction, showRequestUpdatePatientInfoErrorAction, } = accountSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestUpdatePatientInfoAction());
        yield call(AccountRepository.updatePatientInfo, action.payload, patientId);
        yield getHomeSaga();
        yield put(successRequestUpdatePatientInfoAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdatePatientInfoErrorAction);
    }
}
export const getPatientBrandsAsyncAction = createAction(`${SLICE_NAME}/getPatientBrandsAsyncAction`);
export function* getPatientBrandsSaga() {
    const { startRequestPatientBrandsAction, successRequestPatientBrandsAction, showRequestPatientBrandsErrorAction, } = accountSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestPatientBrandsAction());
        const result = yield call(AccountRepository.getPatientBrands, patientId);
        const brands = yield mapPatientBrands(result.data);
        yield put(successRequestPatientBrandsAction(brands));
    }
    catch (error) {
        yield handleApiError(error, showRequestPatientBrandsErrorAction);
    }
}
export const assignToBrandAsyncAction = createAction(`${SLICE_NAME}/assignToBrandAsyncAction`);
export function* assignToBrandSaga(action) {
    const { startRequestAssignToBrandAction, successRequestAssignToBrandAction, showRequestAssignToBrandErrorAction, } = accountSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    try {
        yield put(startRequestAssignToBrandAction());
        yield call(AccountRepository.assignToBrand, {
            patientId,
            drugBrandCode: action.payload,
        });
        yield getRegistrationInfoSaga({
            payload: {
                member: undefined,
                isRegistrationFlow: false,
            },
            type: "ACCOUNT/getRegistrationInfoAsyncAction",
        });
        yield put(successRequestAssignToBrandAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestAssignToBrandErrorAction);
    }
}
export const enrollByOwnerAsyncAction = createAction(`${SLICE_NAME}/enrollByOwnerAsyncAction`);
export function* enrollByOwnerSaga(action) {
    const { startRequestEnrollByOwnerAction, successRequestEnrollByOwnerAction, showRequestEnrollByOwnerErrorAction, } = accountSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    const { drugBrandCode } = yield select(getMemberInfoSelector);
    try {
        yield put(startRequestEnrollByOwnerAction());
        yield call(AccountRepository.enrollByOwner, {
            assignPatientToBrandDto: {
                patientId,
                drugBrandCode,
                showOnboardValidation: true
            },
            almostDoneDto: {
                province: action.payload.province,
                gender: action.payload.gender,
                din: action.payload.din,
                medicalConditions: action.payload.medicalConditions.map(condition => {
                    return {
                        ...condition, drugBrand: drugBrandCode
                    };
                })
            },
        });
        yield getHomeSaga();
        yield getRegistrationInfoSaga({
            payload: {
                member: {
                    patientId,
                    drugBrandCode,
                },
                isRegistrationFlow: true,
            },
            type: "ACCOUNT/getRegistrationInfoAsyncAction",
        });
        yield put(successRequestEnrollByOwnerAction());
    }
    catch (error) {
        yield handleApiError(error, showRequestEnrollByOwnerErrorAction);
    }
}
export const updateEmailAsyncAction = createAction(`${SLICE_NAME}/updateEmailAsyncAction`);
export function* updateEmailSaga(action) {
    const { startRequestUpdateEmailAction, successRequestUpdateEmailAction, showRequestUpdateEmailErrorAction, } = accountSlice.actions;
    try {
        yield put(startRequestUpdateEmailAction());
        yield call(AccountRepository.updateEmail, action.payload);
        yield put(successRequestUpdateEmailAction(action.payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdateEmailErrorAction);
    }
}
export const updateBrandEnrollmentAsyncAction = createAction(`${SLICE_NAME}/updateBrandEnrollmentAsyncAction`);
export function* updateBrandEnrollmentSaga(action) {
    const { startRequestUpdateBrandEnrollment, successRequestUpdateBrandEnrollment, errorRequestUpdateBrandEnrollment, } = accountSlice.actions;
    const { patientId, drugBrandCode } = yield select(userInfoSelector);
    try {
        yield put(startRequestUpdateBrandEnrollment());
        yield call(AccountRepository.updateBrandEnrollment, {
            drugBrandCode,
            patientId,
            showOnboardValidation: false,
        });
        yield put(successRequestUpdateBrandEnrollment(action.payload));
    }
    catch (error) {
        yield handleApiError(error, errorRequestUpdateBrandEnrollment);
    }
}
export const getPatientHealthInformationAsyncAction = createAction(`${SLICE_NAME}/getPatientHealthInformationAsyncAction`);
export function* getPatientHealthInformationSaga() {
    const { startRequestPatientHealthInformation, successRequestPatientHealthInformation, errorRequestPatientHealthInformation, } = accountSlice.actions;
    const { patientId } = yield select(userInfoSelector);
    let { drugBrandCode } = yield select(getBrandByIdSelector);
    try {
        yield put(startRequestPatientHealthInformation());
        const result = yield call(AccountRepository.getPatientHealthInformation, patientId, drugBrandCode);
        const healthInformation = yield mapPatientHealthInformation(result.data);
        yield put(successRequestPatientHealthInformation(healthInformation));
    }
    catch (error) {
        yield handleApiError(error, errorRequestPatientHealthInformation);
    }
}
export const updateHealthInformationAsyncAction = createAction(`${SLICE_NAME}/updateHealthInformationAsyncAction`);
export function* updateHealthInformationSaga(action) {
    const { startRequestUpdateHealthInformationAction, successRequestUpdateHealthInformationAction, showRequestUpdateHealthInformationErrorAction, } = accountSlice.actions;
    const { drugBrandCode } = yield select(getBrandByIdSelector);
    try {
        yield put(startRequestUpdateHealthInformationAction());
        const payload = {
            ...action.payload,
            drugBrandCode: drugBrandCode ?? config.brand,
            medicalConditions: action.payload.medicalConditions.map(condition => {
                return {
                    ...condition, drugBrand: drugBrandCode
                };
            })
        };
        yield call(AccountRepository.updatePatientHealthInformation, payload);
        yield getHomeSaga();
        yield put(successRequestUpdateHealthInformationAction(payload));
    }
    catch (error) {
        yield handleApiError(error, showRequestUpdateHealthInformationErrorAction);
    }
}
export default function* rootSaga() {
    yield takeLatest(updateEmailAsyncAction, updateEmailSaga);
    yield takeLatest(getSettingsInfoAsyncAction, getSettingsInfoSaga);
    yield takeLatest(getHomeAsyncAction, getHomeSaga);
    yield takeLatest(getRegistrationInfoAsyncAction, getRegistrationInfoSaga);
    yield takeLatest(getPatientBrandsAsyncAction, getPatientBrandsSaga);
    yield takeLatest(getPatientInfoAsyncAction, getPatientInfoSaga);
    yield takeLatest(getPatientHealthInformationAsyncAction, getPatientHealthInformationSaga);
    yield takeLatest(updateHealthInformationAsyncAction, updateHealthInformationSaga);
    yield takeLatest(updatePatientInfoAsyncAction, updatePatientInfoSaga);
    yield takeLatest(assignToBrandAsyncAction, assignToBrandSaga);
    yield takeLatest(updateBrandEnrollmentAsyncAction, updateBrandEnrollmentSaga);
    yield takeLatest(enrollByOwnerAsyncAction, enrollByOwnerSaga);
    yield takeLatest(updateIsAdminAction, updateIsAdminSaga);
    yield takeLatest(setPatientdataAction, setPatientdataActionSaga);
    yield takeLatest(getSinglePatientInfoAsyncAction, getSinglePatientInfoSaga);
}
