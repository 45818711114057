import React from "react";
import { useTranslation } from "react-i18next";
import AdditionalStepsWrapper from "../AdditionalSteps/AdditionalStepsWrapper";
import SchedulePhoneCall from "../../components/SchedulePhoneCall";
import { useNavigate } from "react-router-dom";
const SchedulePhoneCallPage = ({ backAction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return React.createElement(AdditionalStepsWrapper, { backAction: () => backAction ? backAction() : navigate('/') },
        React.createElement(SchedulePhoneCall, null));
};
export default SchedulePhoneCallPage;
