import { createSlice } from "@reduxjs/toolkit";
export const SLICE_NAME = "PATIENTS";
const initialState = {
    requestPatientStatus: "idle",
    requestPatientError: null,
    allPatientInfo: undefined
};
const patientSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        //get api Patient
        startRequestPatientAction: (state) => {
            state.requestPatientStatus = "pending";
            state.requestPatientError = null;
        },
        successRequestPatientAction: (state, action) => {
            state.requestPatientStatus = "success";
            state.requestPatientError = null;
            state.allPatientInfo = action.payload;
        },
        showRequestPatientErrorAction: (state, action) => {
            state.requestPatientStatus = "failure";
            state.requestPatientError = action.payload;
        },
    },
});
export default patientSlice;
